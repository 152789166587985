import styled from "styled-components";
import { theme } from "../../utils/theme";
import { AppText, AppSelect, NewAppSelect, NewAppButton, NewAppInput, AppErrorText, SkeletonBlock } from "../UI";
import * as React from "react";
import { Formik, FormikProps, ErrorMessage } from "formik";
import { useState, useEffect, useMemo, useContext, useRef } from "react";
import * as Yup from "yup";
import { PHONE_REGEX } from "../../utils/regex";
import { useFlags } from "launchdarkly-react-client-sdk";
import { extractNumber, extractNumberWithCountry } from "../../utils/format";
import { useHistory } from "react-router-dom";
import { debounce, values } from "lodash";
import { useMutation, useQuery, gql, ApolloQueryResult, useLazyQuery } from "@apollo/client";
import { CORE_LEAD_CARD_DATA } from "../../apollo/fragments";
import { appToast, errorToast } from "../../utils/toast";
import { StateDropdown } from "../Segments/LeadCard/StateDropdown";
import { InputField, FormSelectField, FormMultiSelectField, NewInputField } from "../Field";
import {
  findCountryWithCode,
  findStateCode,
  getExtraCountryCodes,
  listBrazil,
  listMexico,
  sortedCountryCodesData,
  statesUSA,
} from "../../static/countries";
import { isValidEmail } from "../../utils/format";
import { SYSTEM_FIELD_CHAR_LIMIT, SYSTEM_FIELD_EMAIL_CHAR_LIMIT } from "../../utils/format";

import { NewAppCircleCheckBox } from "../UI";

import { BsPerson } from "react-icons/bs";

import { AiOutlineStar, AiFillStar } from "react-icons/ai";

import { SHOW_LEAD_SOURCE_TO_USER } from "../../apollo/query";

import { loggedInUser } from "./../../apollo/cache";

import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

import { ModalContext } from "../../context";

import { AppSidebarCard, FlexDiv, DarkDiv } from "../UI";

import * as Sentry from "@sentry/react";

import { CallContext } from "../../context/CallContext";
import { MixpanelActions } from "../../services/mixpanel";
import { getLocalStorage } from "src/utils/misc";

interface IfetchLead {
  fetchLead?: {
    id?: string;
    first_name?: string;
    last_name?: string;
    full_name?: string;
    primary_phone_number?: string;
    business_name?: string;
    favorited?: boolean;
    rep_id?: string;
    city?: string;
    state?: string;
    industry?: string;
    sub_industry?: string;
    brand_id?: string;
  };
}
const FETCH_LEAD_BASIC_INFO = gql`
  query fetchLead($id: String!) {
    fetchLead(id: $id) {
      id
      first_name
      last_name
      full_name
      primary_phone_number
      business_name
      favorited
      rep_id
      city
      state
      industry
      sub_industry
      brand_id
    }
  }
`;

const FETCH_ASSOCIATED_LEADS = gql`
  query FetchAssociateContact($primaryLeadId: String!) {
    fetchAssociateContact(primary_lead_id: $primaryLeadId) {
      id
    }
  }
`;

const ADD_ASSOCIATED_LEAD = gql`
  mutation AddAssociateLead($primary_lead_id: String!, $lead_input: addAssociateLeadInput!) {
    addAssociateLead(primary_lead_id: $primary_lead_id, lead_input: $lead_input) {
      message
      status
      status_boolean
    }
  }
`;

const FAVORITE_LEAD = gql`
  mutation favoriteLead($lead_id: String!) {
    favoriteLead(lead_id: $lead_id) {
      id
      favorited
    }
  }
`;

const GET_POSSIBLE_LEAD_SOURCES_DATA = gql`
  query fetchLeadSourceOptions {
    fetchLeadSourceOptions {
      id
      label
    }
  }
`;

const GET_POSSIBLE_INDUSTRIES_DATA = gql`
  query fetchIndustryOptions {
    fetchIndustryOptions {
      id
      label
      sub_industries
    }
  }
`;

const UNFAVORITE_LEAD = gql`
  mutation unfavoriteLead($lead_id: String!) {
    unfavoriteLead(lead_id: $lead_id) {
      id
      favorited
    }
  }
`;

interface MyFormikProps {
  first_name?: string;
  last_name?: string;
  business_name: string;
  phone_number: string;
  email: string;
  lead_source: string;
  industry: string;
  sub_industry: string;
  source_type: string;
  city: string;
  state: string;
  country_code: { label: string; value: { iso2: string; code: string } };
  make_primary: boolean;
}

interface associatedContactProps {
  setShowAddAssociatedContact: (value: boolean) => void;
  width?: number;
}

const AddAssociatedContact = ({ setShowAddAssociatedContact, width }: associatedContactProps) => {
  // primary lead id
  const { primaryLeadToAddAssociateTo, setPrimaryLeadToAddAssociateTo } = useContext(ModalContext);
  const { nextActionOverride, resetNextDial } = useContext(CallContext);

  const { data: showLeadSourceToUser } = useQuery(SHOW_LEAD_SOURCE_TO_USER);

  // fetch primary lead data

  const { data: primary_lead_data, loading: primary_lead_loading } = useQuery<IfetchLead>(FETCH_LEAD_BASIC_INFO, {
    variables: {
      id: primaryLeadToAddAssociateTo,
    },

    onCompleted({ fetchLead }) {
      setFavoriteToggled(primary_lead_data?.fetchLead?.favorited);
    },
  });

  // fetch number of associated leads
  const { data: associated_lead_data, loading: associated_lead_loading } = useQuery(FETCH_ASSOCIATED_LEADS, {
    variables: {
      primaryLeadId: primaryLeadToAddAssociateTo,
    },
    skip: !primaryLeadToAddAssociateTo,
  });

  useEffect(() => {
    // scroll up to top of form
    const element = document?.getElementById("add_associated_lead");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    console.log("primaryLeadToAddAssociateTo", primaryLeadToAddAssociateTo);
    console.log("primary_lead_data?.fetchLead", primary_lead_data?.fetchLead);
  }, [primary_lead_data]);

  const [favoriteToggled, setFavoriteToggled] = useState(primary_lead_data?.fetchLead?.favorited);

  const [favoriteLead, { loading: favoriteLoading, error: favoriteError }] = useMutation(FAVORITE_LEAD, {
    variables: {
      lead_id: primary_lead_data?.fetchLead?.id,
    },
    async onCompleted({ favoriteLead }) {
      if (!favoriteLead) {
        appToast("Error favoriting lead. Something went wrong.");
        return;
      }
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `Error favoriting lead GraphQL Error: ${message}`,
      });
      console.log("Error favoriting lead: ", message);
    },
    optimisticResponse: {
      favoriteLead: {
        id: primary_lead_data?.fetchLead?.id,
        favorited: true,
        __typename: "Lead",
      },
    },
    refetchQueries: ["fetchFavoritedLeads"],
  });
  const [unfavoriteLead, { loading: unfavoriteLoading, error: unfavoriteError }] = useMutation(UNFAVORITE_LEAD, {
    variables: {
      lead_id: primary_lead_data?.fetchLead?.id,
    },
    async onCompleted({ unfavoriteLead }) {
      if (!unfavoriteLead) {
        appToast("Error unfavoriting lead. Something went wrong.");
        return;
      }
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `Error favoriting lead GraphQL Error: ${message}`,
      });
      console.log("Error favoriting lead: ", message);
    },
    optimisticResponse: {
      unfavoriteLead: {
        id: primary_lead_data?.fetchLead?.id,
        favorited: false,
        __typename: "Lead",
      },
    },
    refetchQueries: ["fetchFavoritedLeads"],
  });
  const FavoriteButton = () => (
    <>
      {favoriteToggled ? (
        <AiFillStar
          color={theme.TERTIARY500}
          size={16}
          onClick={() => {
            unfavoriteLead();
            setFavoriteToggled(false);
          }}
        />
      ) : (
        <AiOutlineStar
          color={theme.NEUTRAL400}
          size={16}
          onClick={() => {
            if (primary_lead_data?.fetchLead?.rep_id === loggedInUser()?.id) {
              favoriteLead();

              setFavoriteToggled(true);
            } else {
              appToast("Leads not owned or assigned to you cannot be favorited.");
            }
          }}
        />
      )}
    </>
  );

  const CHECK_LEAD_PHONE_NUMBER = gql`
    query checkLeadPhoneNumber($phone_number: String!, $country_code: String!, $brand_id: String) {
      checkLeadPhoneNumber(phone_number: $phone_number, country_code: $country_code, brand_id: $brand_id)
    }
  `;

  const [phoneNumber, setPhoneNumber] = useState("");
  const phone_number_without_chars = extractNumberWithCountry(phoneNumber);

  //set to 1 for US coutntry code (matches formik default)
  const [countryCode, setCountryCode] = useState("1");

  const [oldPhoneValue, setOldPhoneValue] = useState("");
  const [phoneNumberVariant, setPhoneNumberVariant] = useState<"error" | "primary" | "success" | "warning" | undefined>(
    undefined,
  );

  const formikRef = useRef() as any;

  const debouncedChange = useMemo(
    () =>
      debounce((value) => {
        setPhoneNumber(value);
      }, 500),
    [],
  );

  const newLeadSchema = Yup.object().shape({
    first_name: Yup.string()
      .notRequired()
      .trim()
      .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
    last_name: Yup.string()
      .notRequired()
      .trim()
      .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
    business_name: Yup.string()
      .required("Field is required")
      .trim()
      .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
    phone_number: Yup.string()
      // this would get stuck as failing despite having a value
      // need to troubleshoot
      // .required("Field is required")
      .max(15, "Must be valid phone number")
      .min(7, "Must be valid phone number")
      .matches(PHONE_REGEX, "Must be a valid phone number")
      .when("country_code", {
        is: (country_code:any) => country_code?.value?.iso2 === "US",
        then: Yup.string().length(10, "US phone numbers must be 10 digits"),
      })
      .test("phone_number", "", async (raw_phone_number: any) => {
        const phone_number = extractNumberWithCountry(raw_phone_number);
        if (!!phone_number && phone_number !== oldPhoneValue) {
          setOldPhoneValue(phone_number);
          checkLeadPhoneNumber({
            variables: {
              phone_number: phone_number.toString() || "",
              country_code: countryCode,
              brand_id: primary_lead_data?.fetchLead?.brand_id,
            },
          });
          // return isPhoneValid.data.validatePhoneNumber;
        }
        return true;
      }),

    email: Yup.string()
      .notRequired()
      .email()
      .trim()
      .max(SYSTEM_FIELD_EMAIL_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_EMAIL_CHAR_LIMIT} characters or less`)
      .test("email", "Must be a valid email", (value) => {
        if (!!value) {
          return isValidEmail(value) ?? true;
        }
        return true;
      }),
    lead_source: Yup.string().notRequired(),
    source_type: Yup.string().required(),
    industry: Yup.string().notRequired(),
    sub_industry: Yup.string().notRequired(),
    city: Yup.string().notRequired().trim(),
    state: Yup.string().required().trim(),
    country_code: Yup.object()
      .required()
      .shape({
        label: Yup.string(),
        value: Yup.object().shape({ iso2: Yup.string(), code: Yup.string() }),
      }),

    make_primary: Yup.boolean().required(),
  });

  const [
    checkLeadPhoneNumber,
    { data: leadPhoneNumber, loading: leadPhoneNumberLoading, error: leadPhoneNumberError },
  ] = useLazyQuery(CHECK_LEAD_PHONE_NUMBER, {
    fetchPolicy: "network-only",
    variables: {
      phone_number: phone_number_without_chars,
      country_code: countryCode,
      brand_id: primary_lead_data?.fetchLead?.brand_id,
    },
    onCompleted({ checkLeadPhoneNumber }) {
      switch (checkLeadPhoneNumber?.type) {
        case "NOT_IN_SYSTEM":
          return setPhoneNumberVariant("success");
        case "IN_SYSTEM_CLAIMABLE":
          return setPhoneNumberVariant("warning");
        case "IN_SYSTEM_TAKEN":
          return setPhoneNumberVariant("error");
        default:
          return setPhoneNumberVariant(undefined);
      }
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const [addAssociatedLead, { loading: associatedLeadLoading, error: associatedLeadError }] = useMutation(
    ADD_ASSOCIATED_LEAD,
    {
      async onCompleted(response) {
        console.log("addAssociatedLead: ", response);
        if (!response?.addAssociateLead?.status_boolean) {
          errorToast("Error adding associate contact");
          return;
        }

        appToast("Associate contact added!");

        setShowAddAssociatedContact(false);

        // if the the next dial override is set to this lead and we swapped the primary lead, we need to reset the next dial.
        if (
          formikRef?.current?.values?.make_primary &&
          nextActionOverride?.intent_object?.lead?.id === primary_lead_data?.fetchLead?.id
        ) {
          resetNextDial();
          console.log("resetting next dial override due to a new primary lead");
        }

        MixpanelActions.track("Contacts Associated");
      },
      onError({ message }) {
        appToast(message);
      },
      refetchQueries: [
        "fetchAssociateContactHistory",
        "FetchAssociateContact",
        "fetchAssociateParent",
        "fetchNextDial",
      ],
    },
  );

  const { data: leadsourceData, loading: leadsourceLoading, error: leadsourceError } = useQuery(
    GET_POSSIBLE_LEAD_SOURCES_DATA,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: industriesData, loading: industriesLoading, error: industriesError } = useQuery(
    GET_POSSIBLE_INDUSTRIES_DATA,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },

      onCompleted({ fetchIndustryOptions }) {
        setIndustryOptions(
          fetchIndustryOptions?.map((ls: any) => ({
            label: ls?.label,
            value: ls?.label,
          })) || [],
        );
      },
    },
  );

  const returnSubIndustryOptions = (industry: string) => {
    if (industriesLoading) {
      return optionsLoading;
    }
    if (!!industry) {
      return (
        industriesData?.fetchIndustryOptions
          ?.slice()
          ?.filter((item: any) => item?.label === industry)[0]
          ?.sub_industries?.map((ls: string) => ({ label: ls, value: ls }))
          ?.sort((a: any, b: any) => (a?.label < b?.label ? -1 : 1)) || []
      );
    } else {
      return [{ label: "Select Primary Industry", value: "" }];
    }
  };

  const optionsLoading = [
    {
      label: "Loading...",
      value: "",
    },
  ];

  const source_type_options = [
    {
      label: "Inbound",
      value: "Inbound",
    },
    {
      label: "Outbound",
      value: "Outbound",
    },
  ];

  useEffect(() => {
    return () => {
      setPrimaryLeadToAddAssociateTo("");
    };
  }, []);

  const [industryOptions, setIndustryOptions] = useState([]);

  const error = associatedLeadError || leadPhoneNumberError || industriesError || leadsourceError;

  const loading = associatedLeadLoading || industriesLoading || leadsourceLoading;

  const orgCountryCodeOptions = getLocalStorage("country_code_options", []);

  const defaultPlusExtraCountryOptions = [
    ...sortedCountryCodesData,
    ...getExtraCountryCodes(orgCountryCodeOptions),
  ].sort((a, b) => {
    if (a?.value?.iso2 === "US") return -1;
    if (b?.value?.iso2 === "US") return 1;
    return a?.label?.localeCompare(b?.label);
  });

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        first_name: "",
        last_name: "",
        business_name: associated_lead_data?.length > 2 ? primary_lead_data?.fetchLead?.business_name ?? "" : "",
        phone_number: "",
        email: "",
        lead_source: "",
        source_type: "",
        industry: primary_lead_data?.fetchLead?.industry || "",
        sub_industry: primary_lead_data?.fetchLead?.sub_industry || "",
        city: primary_lead_data?.fetchLead?.city || "",
        state: primary_lead_data?.fetchLead?.state || "",
        country_code: { label: "United States (1)", value: { iso2: "US", code: "1" } },
        make_primary: false,
      }}
      validationSchema={newLeadSchema}
      validateOnMount
      onSubmit={async (values) => {
        console.log("Sent data: ", values);

        await addAssociatedLead({
          variables: {
            primary_lead_id: primaryLeadToAddAssociateTo,
            lead_input: {
              first_name: values.first_name,
              last_name: values.last_name,
              business_name: values.business_name,
              phone_number: extractNumberWithCountry(values.phone_number, values.country_code?.value?.iso2),
              email: values.email,
              lead_source: values.lead_source,
              industry: values.industry,
              sub_industry: values.sub_industry,
              city: values.city,
              state: values.state ?? undefined,
              country: values.country_code?.value?.iso2 ?? undefined,
              country_code: values.country_code?.value?.code ?? undefined,
              make_primary: values.make_primary,
            },
          },
        });
      }}
    >
      {({
        errors,
        submitForm,
        values,
        setFieldValue,
        isSubmitting,
        isValid,
        touched,
        validateForm,
      }: FormikProps<MyFormikProps>) => {
        console.log("Formik values for addAssociatedContact: ", {
          errors: errors,
          values: values,
          touched: touched,
          isValid: isValid,
        });

        if (error) {
          return (
            <div>
              <AppErrorText>Something went wrong.</AppErrorText>
            </div>
          );
        }

        if (loading) {
          return <SkeletonBlock height="90vh" width="100%" borderRadius={6} />;
        }

        return (
          <div id="add_associated_lead">
            <DarkDiv />

            <AppSidebarCard
              title="Add Associated Contact"
              stretchHeight
              width={width}
              style={{ position: "relative", zIndex: 899, border: "none" }}
            >
              <ModalContentContainer>
                <AddAssociatedContactContainer>
                  <div
                    style={{
                      paddingBottom: "16px",
                      borderBottom: `1px solid ${theme.NEUTRAL200}`,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: 0,
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowAddAssociatedContact(false)}
                    >
                      <AiOutlineLeft size={12} color={theme.PRIMARY500} />
                      <AppText fontSize={12} fontWeight={600} style={{ color: theme.PRIMARY500, marginLeft: "8px" }}>
                        BACK
                      </AppText>
                    </div>

                    {/*header is split into 3 sections using grid */}
                    <CardContainerHeader>
                      <CardHeaderLeft>
                        {primary_lead_data?.fetchLead?.rep_id === loggedInUser()?.id && <FavoriteButton />}
                      </CardHeaderLeft>

                      <CardHeaderMiddle>
                        <CallbackPersonCompany
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            window.open(`/lead-detail/${primary_lead_data?.fetchLead?.id}`, "_blank", "noreferrer")
                          }
                        >
                          {primary_lead_data?.fetchLead?.business_name || "No Name"}
                        </CallbackPersonCompany>
                      </CardHeaderMiddle>

                      <CardHeaderRight></CardHeaderRight>
                    </CardContainerHeader>
                    <CardContainerMain>
                      <LeadTypePill color={theme.PRIMARY500}>
                        <BsPerson color={theme.WHITE_COLOR} size={12} style={{ marginRight: 4 }} />
                        <AppText fontSize={8} fontWeight={600} style={{ color: theme.WHITE_COLOR }}>
                          Primary
                        </AppText>
                      </LeadTypePill>

                      <CardContainerMainRight>
                        <CardGridMiddle>
                          <CallbackPersonName fontWeight={400} fontSize={12}>
                            {primary_lead_data?.fetchLead?.first_name && primary_lead_data?.fetchLead?.last_name
                              ? `${primary_lead_data?.fetchLead?.last_name}, ${primary_lead_data?.fetchLead?.first_name}`
                              : primary_lead_data?.fetchLead?.full_name || "No Name"}
                          </CallbackPersonName>
                        </CardGridMiddle>
                      </CardContainerMainRight>
                    </CardContainerMain>
                  </div>

                  <AppText
                    fontWeight={400}
                    fontSize={12}
                    style={{
                      textAlign: "center",
                      marginTop: "16px",
                    }}
                  >
                    Enter your lead's primary phone number and country first to determine if they are in Sellfire.
                  </AppText>
                  <ScrollingDiv>
                    <PopupInputLabel>
                      Phone Number <span style={{ color: "red" }}>*</span>
                    </PopupInputLabel>
                    <NumberExtensionDiv>
                      {!!values.country_code?.value?.code && (
                        <ExtensionText>+{values.country_code?.value.code}</ExtensionText>
                      )}
                      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <PhoneNumberInput
                          value={values.phone_number}
                          variant={
                            !!leadPhoneNumberError || (!!errors.phone_number && touched.phone_number)
                              ? "error"
                              : phoneNumberVariant
                          }
                          onBlur={() => {
                            if (values.phone_number !== values?.phone_number?.match(/\d/g)?.join("")) {
                              const cleanPhoneNumber = values?.phone_number?.match(/\d/g)?.join("");
                              setFieldValue("phone_number", cleanPhoneNumber);
                            }
                            // validateForm();
                          }}
                          name="phone_number"
                          type="tel"
                          placeholder="Phone Number"
                          onChange={(e: any) => {
                            touched.phone_number = true;
                            // const cleanPhoneNumber = e.target.value.match(/\d/g)?.join("");
                            setFieldValue("phone_number", e.target.value);
                            // validateForm();
                          }}
                        />
                        <div style={{ marginTop: -20 }}>
                          <AppText fontSize={12} variant={!!errors.phone_number ? "error" : phoneNumberVariant}>
                            {leadPhoneNumber?.checkLeadPhoneNumber?.message}
                          </AppText>
                        </div>
                      </div>
                    </NumberExtensionDiv>

                    <PopupInputLabel>
                      Country <span style={{ color: "red" }}>*</span>
                    </PopupInputLabel>
                    <NewAppSelect
                      name="country_code"
                      options={defaultPlusExtraCountryOptions}
                      isClearable
                      placeholder={values.country_code.label}
                      allowSelectPlaceholder={true}
                      defaultValue={{
                        label: values.country_code.label,
                        value: values.country_code.value as any,
                      }}
                      onChange={(e: any) => {
                        setCountryCode(e?.value.code);
                        setFieldValue("country_code", !!e?.value ? { label: e?.label, value: e?.value } : {});
                        setFieldValue("state", "");
                      }}
                    />
                    <Divider />
                    {
                      <FilterDiv
                        disabled={!!errors.phone_number || phoneNumberVariant !== "success" || !values.phone_number}
                      >
                        <PopupInputLabel>Contact First Name</PopupInputLabel>
                        <LeadInput name="first_name" />
                        <PopupInputLabel>Contact Last Name</PopupInputLabel>
                        <LeadInput name="last_name" />
                        <PopupInputLabel>
                          Business Name <span style={{ color: "red" }}>*</span>
                        </PopupInputLabel>
                        <LeadInput
                          name="business_name"
                          variant={!!errors.business_name && touched.business_name ? "error" : "primary"}
                        />
                        <PopupInputLabel>Primary Email</PopupInputLabel>
                        <LeadInput name="email" />
                        {showLeadSourceToUser?.showLeadSourceToUser && (
                          <>
                            <PopupInputLabel>Lead Source</PopupInputLabel>

                            <NewAppSelect
                              name="lead_source"
                              isClearable
                              options={
                                leadsourceLoading
                                  ? optionsLoading
                                  : leadsourceData.fetchLeadSourceOptions?.map((ls: any) => ({
                                      label: ls.label,
                                      value: ls.label,
                                    }))
                              }
                              onChange={(e: any) => {
                                setFieldValue(`lead_source`, e?.value);
                              }}
                            />
                          </>
                        )}
                        <PopupInputLabel>
                          Source Type <span style={{ color: "red" }}>*</span>
                        </PopupInputLabel>

                        <NewAppSelect
                          name="source_type"
                          isClearable
                          options={source_type_options}
                          onChange={(e: any) => {
                            setFieldValue("source_type", e?.value);
                          }}
                        />
                        <PopupInputLabel>Industry</PopupInputLabel>

                        <NewAppSelect
                          name="industry"
                          isClearable
                          options={industryOptions}
                          onChange={(e: any) => {
                            setFieldValue(`industry`, !!e?.value ? e?.value : "");
                          }}
                          value={values.industry}
                        />
                        <PopupInputLabel>Sub-Industry</PopupInputLabel>
                        <NewAppSelect
                          name="sub_industry"
                          isClearable
                          disabled={!values.industry}
                          options={returnSubIndustryOptions(values.industry)}
                          onChange={(e: any) => {
                            setFieldValue(`sub_industry`, !!e?.value ? e?.value : "");
                          }}
                          value={values.sub_industry}
                        />
                        <PopupInputLabel>City</PopupInputLabel>
                        <LeadInput name="city" />
                        <PopupInputLabel>
                          State/Province <span style={{ color: "red" }}>*</span>
                        </PopupInputLabel>
                        <StateDropdown
                          callback={setFieldValue}
                          country_code={values.country_code?.value?.iso2}
                          current_value={values.state}
                        />
                        <PopupInputLabel>Make Primary</PopupInputLabel>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <NewAppCircleCheckBox
                            width="75px"
                            textMarginLeft={10}
                            titleTextLocation="right"
                            title="Yes"
                            checked={values.make_primary}
                            onClick={(e: any) => {
                              setFieldValue("make_primary", true);
                            }}
                          />
                          <NewAppCircleCheckBox
                            width="75px"
                            titleTextLocation="right"
                            textMarginLeft={10}
                            title="No"
                            checked={!values.make_primary}
                            onClick={(e: any) => {
                              setFieldValue("make_primary", false);
                            }}
                          />
                        </div>
                      </FilterDiv>
                    }

                    <div
                      style={{
                        width: "240px",
                        height: "48px",
                      }}
                    >
                      <NewAppButton variant="primary" onClick={submitForm} disabled={isSubmitting || !isValid}>
                        CREATE ASSOCIATED CONTACT
                      </NewAppButton>
                    </div>
                  </ScrollingDiv>
                </AddAssociatedContactContainer>
              </ModalContentContainer>
            </AppSidebarCard>
          </div>
        );
      }}
    </Formik>
  );
};

export { AddAssociatedContact };

const ModalContentContainer = styled.div`
  padding: 16px 24px;
`;

const CardGridMiddle = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  flex-grow: 2;
  gap: 4px;
  display: flex;
  align-items: center;
`;

const AddAssociatedContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  width: 100%;
`;

const ScrollingDiv = styled.div`
  /* overflow-y: scroll; */
  padding-top: 16px;
`;

const PopupInputLabel = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 13px;
  margin-left: 5px;
`;

const NumberExtensionDiv = styled.div`
  display: flex;
`;
const ExtensionText = styled(AppText)`
  margin: 15px;
  margin-top: 10px;
  height: 40px;
  font-size: 13px;
`;

const LeadInput = styled(NewInputField)`
  margin: 0px;
`;

const PhoneNumberInput = styled(NewAppInput)`
  margin: 0px;
`;

const Divider = styled.div`
  width: 100%;
  margin: 22px 0px 32px 0px;
  background-color: ${theme.NEUTRAL200};
  height: 1px;
`;
type FilterDivProps = {
  disabled: boolean;
};

const FilterDiv = styled.div.attrs((props: FilterDivProps) => ({
  disabled: props.disabled,
}))`
  ${(props) => {
    if (props.disabled) {
      return `filter: blur(5px);
      pointer-events: none;`;
    }
  }}
`;

const CardContainerMainRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const CardHeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const CardHeaderLeft = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2px;
`;

const CardContainerMain = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 16px;
`;

// expanded prop for CardContainerHeader
const CardContainerHeader = styled.div`
  display: grid;
  /* grid-template-columns: 20px 1fr 1fr;
   */

  grid-template-columns: 20px 1fr 1fr;
  min-height: 24px;
  height: fit-content;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;

const CardHeaderMiddle = styled.div`
  display: flex;
`;

const CallbackPersonCompany = styled(AppText)`
  border-bottom: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  padding: 0px 4px 0px 0px;
  max-width: 240px;
  white-space: pre-wrap;
  word-wrap: break-word;
  :hover {
    border-bottom: 1px solid ${theme.PRIMARY500};
    margin-bottom: -1px;
  }
`;

interface LeadTypePillProps {
  color: string;
}

const LeadTypePill = styled.div<LeadTypePillProps>`
  width: 73px;
  height: 24px;
  background-color: ${(props) => props.color || theme.PRIMARY500};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CallbackPersonName = styled(AppText)`
  /* font-size: 12px; */
  white-space: pre-wrap;
  word-wrap: break-word;
`;
