import React, { useState, useEffect } from "react";
import { components } from "react-select";
import { AppText, FlexDiv } from "src/Components/UI";
import { theme } from "src/utils/theme";
import styled from "styled-components";

export const CustomMultiValue = ({ containerClassName, ...props }: any) => {
  const [visibleCount, setVisibleCount] = useState<number>(2);
  const selectedOptions = props.selectProps.value;

  const calculateVisibleItems = () => {
    if (selectedOptions.length > 0) {
      const container = document.querySelector(`.${containerClassName}`);
      if (!container) return;

      const containerWidth = container.clientWidth - 16; // Account for container padding
      const measuredItems = container.querySelectorAll(".option-value");
      if (!measuredItems.length) return;

      let totalWidth = 0;
      let count = 0;

      // First pass: measure all items
      const itemWidths = Array.from(measuredItems).map((item) => {
        const width = (item as HTMLElement).offsetWidth;
        return width + 8; // Add gap between items
      });

      // Second pass: calculate how many can fit
      for (let i = 0; i < itemWidths.length; i++) {
        const currentWidth = itemWidths[i];
        const isLastItem = i === itemWidths.length - 1;

        // Only add badge buffer if this isn't the last item
        const badgeBuffer = !isLastItem ? 40 : 0;

        if (totalWidth + currentWidth + badgeBuffer <= containerWidth) {
          totalWidth += currentWidth;
          count++;
        } else {
          break;
        }
      }

      // Always show at least one item, but try to show two if possible
      setVisibleCount(Math.max(1, count));
    }
  };

  useEffect(() => {
    setTimeout(calculateVisibleItems, 0);
  }, [selectedOptions]);

  if (props.index === visibleCount && selectedOptions.length > visibleCount) {
    return (
      <TagWrapper>
        <AppText color={theme.text.neutral.inverse} fontSize={10}>
          +{selectedOptions.length - visibleCount}
        </AppText>
      </TagWrapper>
    );
  }

  return props.index < visibleCount ? (
    <div className="option-value">
      <components.MultiValue {...props} />
    </div>
  ) : null;
};

const TagWrapper = styled(FlexDiv)`
  align-items: center;
  justify-content: center;
  background-color: ${theme.text.brand.primary};
  border-radius: 4px;
  padding: 4px 8px;
`;
