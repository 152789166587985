import styled from "styled-components";
import { client, restAPI } from "../../apollo";
import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { NewAppButton, AppText, Wrapper, AppButton } from "../UI";
import { theme } from "../../utils/theme";
import { isLoggedInVar, loggedInUser } from "../../apollo/cache";
import { AiOutlineLeft } from "react-icons/ai";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  StepAdmins,
  StepUsersV2,
  StepFollowup,
  StepProducts,
  StepRevenue,
  StepCustomFields,
  StepIntegrationV2,
  StepRestingRules,
  StepLeadsV2,
  StepTimesV2,
} from "../Segments/";
import iconPhoneCallback from "../../images/ic_phone_callback.png";
import * as Sentry from "@sentry/react";
import ReactTooltip from "react-tooltip";
import { useFlags } from "launchdarkly-react-client-sdk";
import { CallContext } from "src/context/CallContext";
import { NotificationsContext } from "src/context/NotificationsContext";
import { toTitleCase } from "../../utils/format";
import { PhoenixAppButton } from "../UI/Phoenix";

const formatTwilioWarning = (warning: string) => {
  switch (warning) {
    case "high-rtt":
      return "High RTT";
    case "low-mos":
      return "Low MOS";
    case "high-jitter":
      return "High jitter";
    case "high-packet-loss":
      return "High packet loss";
    case "high-packets-lost-fraction":
      return "Very high packet loss rate";
    case "low-bytes-received":
      return "Low bytes received";
    case "low-bytes-sent":
      return "Low bytes sent";
    case "ice-connectivity-lost":
      return "Connection lost";
    default:
      return "None";
  }
};

/**
 * Performs a CPU-bound task with an adaptive workload based on the given CPU availability.
 * It returns a Promise that resolves with the total processing time in milliseconds.
 *
 * @param cpuAvailability - A number between 0 and 1 indicating CPU availability.
 *                          For a busy CPU, pass a lower number (e.g., 0.3),
 *                          for a free CPU, higher (e.g., 1.0).
 * @param baseIterations - The baseline number of iterations for full CPU capacity.
 *                         Defaults to 10,000,000.
 * @param chunkSize - The number of iterations to process per chunk.
 *                    Defaults to 1,000.
 * @returns A Promise that resolves with the time (in ms) it took to complete processing.
 */
const adaptiveCpuTask = (
  cpuAvailability: number = 1,
  baseIterations: number = 10_000_000,
  chunkSize: number = 1_000,
): number => {
  // Adjust the workload based on CPU availability.
  const iterations = Math.floor(baseIterations * cpuAvailability);
  let result = 0;
  let i = 0;
  const startTime = performance.now();

  function processChunk(deadline?: IdleDeadline) {
    // Process a chunk of work.
    const end = Math.min(i + chunkSize, iterations);
    for (; i < end; i++) {
      // This computation simulates a CPU-intensive task.
      result += Math.sqrt(i);
    }
    // If there is more work to do, yield control back to the browser.
    if (i < iterations) {
      if (typeof window !== "undefined" && window.requestIdleCallback) {
        window.requestIdleCallback(processChunk);
      } else {
        setTimeout(processChunk, 0);
      }
    } else {
      const finishTime = performance.now();
      const timeTaken = finishTime - startTime;
      console.log("timeTaken: ", timeTaken);
      return timeTaken;
    }
  }
  const finalResult = processChunk();
  return finalResult || 0;
};

const AppHealthCheck: React.FC = () => {
  const { networkQuality, setNetworkQuality } = useContext(NotificationsContext);
  const { currentTwilioWarning } = useContext(CallContext);
  const [networkValues, setNetworkValues] = useState({
    effectiveType: "",
    downlink: 0,
    rtt: 0,
  });
  const [timeTaken, setTimeTaken] = useState<number | null>(null);
  const [busyTime, setBusyTime] = useState<number | null>(null);
  const [eventLoopDelay, setEventLoopDelay] = useState<number | null>(null);
  const [isRunningTests, setIsRunningTests] = useState(true);

  const runTask = () => {
    // Determine the CPU availability factor.
    // For instance: 1 means full availability,
    // whereas 0.5 means the CPU is under moderate pressure.
    // const cpuAvailability = 0.5;
    const elapsedTime = adaptiveCpuTask();
    setTimeTaken(elapsedTime);
    console.log("elapsedTime: ", elapsedTime);
  };

  const updateNetworkQuality = () => {
    const connection = navigator?.connection || navigator?.mozConnection || navigator?.webkitConnection;

    if (!connection) return;
    const { effectiveType, downlink, rtt } = connection;

    setNetworkValues({ effectiveType, downlink, rtt });

    console.log("internet properties:: ", "effectiveType: ", effectiveType, ", downlink: ", downlink, ", rtt: ", rtt);

    if (effectiveType === "4g" && downlink > 2 && rtt <= 500) {
      setNetworkQuality("good");
    } else if ((effectiveType === "4g" && (downlink <= 2 || rtt > 500)) || (effectiveType === "3g" && rtt <= 750)) {
      setNetworkQuality("poor");
    } else {
      setNetworkQuality("very poor");
    }
  };

  const measureAverageEventLoopLag = (interval: number = 50, sampleCount: number = 20) => {
    let lastTime = performance.now();
    const samples: number[] = [];
    let averageLag = 0;

    const timer = setInterval(() => {
      const currentTime = performance.now();
      // Calculate the lag: actual delay - expected interval.
      const lag = currentTime - lastTime - interval;
      samples.push(lag);
      console.log("lag: ", lag);
      lastTime = currentTime;
      if (samples.length >= sampleCount) {
        clearInterval(timer);
        averageLag = samples.reduce((sum, sample) => sum + sample, 0) / samples.length;
        // resolve(averageLag);
      }
    }, interval);

    // setEventLoopDelay(averageLag);

    const checkForCompletion = () => {
      if (samples.length >= sampleCount) {
        // Recalculate the average lag in case it updated in the latest tick.
        const finalAverageLag = samples.reduce((sum, lag) => sum + lag, 0) / samples.length;
        setEventLoopDelay(finalAverageLag);
        // setIsRunningTests(false);

        console.log("Event loop lag measurement completed:", finalAverageLag);
        return finalAverageLag;
      } else {
        requestAnimationFrame(checkForCompletion);
      }
    };
    checkForCompletion();
  };

  // const runEventLoopDelayTask = (interval: number = 50) => {
  //   let lastTime = performance.now();
  //   setInterval(() => {
  //     const currentTime = performance.now();
  //     const lag = currentTime - lastTime - interval;
  //     setEventLoopDelay(lag);
  //     // callback(lag);
  //     lastTime = currentTime;
  //   }, interval);
  // };

  const runCPUBusyTimeTask = () => {
    const iterations = 100_000;
    const chunkSize = 1_000;
    let result = 0;
    let i = 0;
    let busyTime = 0; // Sum of actual computation time

    function processChunk(deadline?: IdleDeadline) {
      const chunkStart = performance.now();
      const end = Math.min(i + chunkSize, iterations);
      for (; i < end; i++) {
        // This computation simulates a CPU-intensive task.
        result += Math.sqrt(i);
        console.log("i2: ", i);
        console.log("result2: ", result);
      }
      const chunkEnd = performance.now();
      // Only add the time spent actively computing
      busyTime += chunkEnd - chunkStart;

      if (i < iterations) {
        if (typeof window !== "undefined" && window.requestIdleCallback) {
          window.requestIdleCallback(processChunk);
        } else {
          setTimeout(processChunk, 0);
        }
      } else {
        console.log("CPU busy time: ", busyTime);
        setBusyTime(busyTime);
        setIsRunningTests(false);
      }
    }
    processChunk();
  };

  const runCPUTask = () => {
    const iterations = 100_000;
    const chunkSize = 1_000;
    let result = 0;
    let i = 0;

    function processChunk() {
      const startTime = performance.now();
      // Process a chunk of work.
      const end = Math.min(i + chunkSize, iterations);
      for (; i < end; i++) {
        // This computation simulates a CPU-intensive task.
        result += Math.sqrt(i);
        console.log("i1: ", i);
        console.log("result1: ", result);
      }
      // If there is more work to do, yield control back to the browser.
      if (i < iterations) {
        console.log("i: ", i);
        if (typeof window !== "undefined" && window.requestIdleCallback) {
          window.requestIdleCallback(processChunk);
        } else {
          setTimeout(processChunk, 0);
        }
      } else {
        const finishTime = performance.now();
        const timeTaken = finishTime - startTime;
        console.log("timeTaken: ", timeTaken);
        setTimeTaken(timeTaken);
        return timeTaken;
      }
    }
    const finalResult = processChunk();
    return finalResult || 0;
  };

  useEffect(() => {
    const runTests = async () => {
      // setIsRunningTests(true);
      await updateNetworkQuality();
      await runCPUTask();
      await runCPUBusyTimeTask();
      await measureAverageEventLoopLag();
      // await runEventLoopDelayTask();
      // setIsRunningTests(false);
    };
    runTests();
  }, []);

  useEffect(() => {
    updateNetworkQuality();
  }, [networkQuality]);

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
      <StyledTooltip
        multiline
        place="bottom"
        effect="solid"
        css={{
          maxWidth: 600,
          lineHeight: 1.4,
          textAlign: "center",
          fontFamily: theme.PRIMARY_FONT,
        }}
        backgroundColor={theme.PRIMARY800}
        getContent={(dataTip) => (
          <span
            style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 600, fontSize: "10px", lineHeight: "14px" }}
          >
            {dataTip}
          </span>
        )}
      />
      <>
        <>
          <Main>
            <OnboardingWrapper>
              <SectionTitle>Overall</SectionTitle>
              <StatsGrid>
                <StatBoxContainer>
                  <StatBoxLabel>Network Quality</StatBoxLabel>
                  <StatBoxValue warn={networkQuality.includes("poor")}>{toTitleCase(networkQuality)}</StatBoxValue>
                </StatBoxContainer>
                <StatBoxContainer>
                  <StatBoxLabel>Twilio Warning</StatBoxLabel>
                  <StatBoxValue warn={currentTwilioWarning !== ""}>
                    {formatTwilioWarning(currentTwilioWarning)}
                  </StatBoxValue>
                </StatBoxContainer>
              </StatsGrid>
              <SectionTitle>Network</SectionTitle>
              <StatsGrid>
                <StatBoxContainer>
                  <StatBoxLabel>Effective Type</StatBoxLabel>
                  <StatBoxValue>{networkValues.effectiveType.toUpperCase() || "?"}</StatBoxValue>
                </StatBoxContainer>
                <StatBoxContainer>
                  <StatBoxLabel>Downlink</StatBoxLabel>
                  <StatBoxValue warn={networkValues.downlink < 2}>
                    {networkValues.downlink.toFixed(2)} Mbps
                  </StatBoxValue>
                </StatBoxContainer>
                <StatBoxContainer>
                  <StatBoxLabel>RTT</StatBoxLabel>
                  <StatBoxValue warn={networkValues.rtt > 500}>{Math.round(networkValues.rtt)} ms</StatBoxValue>
                </StatBoxContainer>
              </StatsGrid>
              <SectionTitle>CPU</SectionTitle>
              <StatsGrid>
                <StatBoxContainer>
                  <StatBoxLabel>CPU Time Taken</StatBoxLabel>
                  <StatBoxValue>{timeTaken?.toFixed(2) ?? "?"} ms</StatBoxValue>
                </StatBoxContainer>
                <StatBoxContainer>
                  <StatBoxLabel>CPU Busy Time</StatBoxLabel>
                  <StatBoxValue>{busyTime?.toFixed(2) ?? "?"} ms</StatBoxValue>
                </StatBoxContainer>
                <StatBoxContainer>
                  <StatBoxLabel>Event Loop Delay</StatBoxLabel>
                  <StatBoxValue>{eventLoopDelay?.toFixed(2) ?? "?"} ms</StatBoxValue>
                </StatBoxContainer>
              </StatsGrid>
              <TestButton
                variant="brand"
                disabled={isRunningTests}
                onClick={async () => {
                  setIsRunningTests(true);
                  updateNetworkQuality();
                  await runCPUTask();
                  await runCPUBusyTimeTask();
                  await measureAverageEventLoopLag();
                  // await runEventLoopDelayTask();
                  setIsRunningTests(false);
                  // runCPUBusyTimeTask();
                  // runEventLoopDelayTask();
                  // measureAverageEventLoopLag();
                }}
              >
                {isRunningTests ? "Running..." : "Run Tests"}
              </TestButton>
              {isRunningTests && (
                <AppText>We are running tests in the background. Please wait for them to complete.</AppText>
              )}
            </OnboardingWrapper>
          </Main>
        </>
      </>
    </Sentry.ErrorBoundary>
  );
};

const TestButton = styled(PhoenixAppButton)`
  margin-top: 40px;
  width: 150px;
  margin-bottom: 20px;
`;

const SectionTitle = styled(AppText)`
  font-size: 24px;
  font-weight: bold;
  color: ${theme.PRIMARY800};
  margin-bottom: 20px;
  margin-top: 40px;
`;

const StatsGrid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
  align-items: center;
`;

const StatBoxLabel = styled(AppText)`
  font-size: 16px;
  color: ${theme.PRIMARY800};
  font-weight: 500;
  margin-bottom: 8px;
`;

const StatBoxValue = styled(AppText)<{ warn?: boolean }>`
  font-size: 32px;
  font-weight: bold;
  color: ${(props) => (props.warn ? theme.PILL_DARK_RED : theme.PRIMARY500)};
`;

const StatBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${theme.NEUTRAL100};
  padding: 20px;
  border-radius: 8px;
  min-width: 200px;
`;

const StyledTooltip = styled(ReactTooltip)`
  text-transform: none;
  max-width: 400px;
`;

const OnboardingColorWrapper = styled.div`
  background-color: ${theme.NEUTRAL100};
  min-width: 1100px;
  min-height: inherit;
  padding-left: calc((100vw - 1100px) / 2);
  width: 100vw;
`;

interface ModalOnProps {
  blinds?: boolean;
}

const PopupContainerDiv = styled.div<ModalOnProps>`
  position: fixed;
  display: ${(props) => (props.blinds ? "block" : "none")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
  z-index: 5;
`;

const OnboardingWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
`;

const ThisWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
  margin-right: 0;
  margin-left: 0;
`;

const StepLocationText = styled(AppText)`
  font-size: 15px;
  font-weight: 500;
`;

const QuestionMarkMoreInfo = styled(AppText)`
  display: inline-block;
  line-height: 20px;
  margin: auto;
  font-size: 14px;
  padding-left: 7px;
  margin-left: 14px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${theme.NEUTRAL100};
  color: ${theme.PRIMARY500};
  cursor: help;
`;

const SimpleFlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

interface IFontSize {
  fontSize?: number;
  fontWeight?: number;
  marginBottom?: number;
  marginTop?: number;
}

const SuccessScreenText = styled(AppText)<IFontSize>`
  color: ${theme.WHITE_COLOR};
  font-size: ${(props) => props.fontSize || 16}px;
  font-weight: ${(props) => props.fontWeight || 500};
  margin-bottom: ${(props) => props.marginBottom || 25}px;
  margin-top: ${(props) => props.marginTop || 0}px;
`;

const SuccessScreen = styled.div`
  width: 100%;
  height: 100%;
  z-index: 3;
  background: ${theme.PRIMARY500};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ProgressProps {
  step: number;
  circle: number;
  width?: number;
}

const DisappearingDiv = styled.div<ProgressProps>`
  display: ${(props) => (props.step === props.circle ? "block" : "none")};
  width: ${(props) => `${props.width}px` || "100%"};
  height: 100%;
  align-items: center;
`;

const ContentContainer = styled.div`
  margin-top: 50px;
  width: auto;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: inherit;
  width: 100%;
`;

const ProgressCircle = styled.div<ProgressProps>`
  position: absolute;
  top: -9px;
  left: ${(props) => (props.circle - 1) * 100}px;
  height: 18px;
  width: 18px;
  border: 2px solid
    ${(props) =>
      props.step === props.circle ? theme.PRIMARY500 : props.step > props.circle ? theme.PRIMARY500 : theme.NEUTRAL100};
  border-radius: 50%;
  background: ${(props) =>
    props.step === props.circle ? theme.WHITE_COLOR : props.step > props.circle ? theme.PRIMARY500 : theme.NEUTRAL100};
`;

const ProgressTrail = styled.div`
  position: relative;
  min-width: 810px;
  width: 810px;
  height: 1px;
  background: ${theme.NEUTRAL100};
  border: 2px solid ${theme.NEUTRAL100};
  margin-left: 60px;
  /* margin-right: 50px; */
`;

const BackArrow = styled.div`
  position: absolute;
  left: 0;
  top: -20px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: ${theme.BLACK_COLOR};
  color: ${theme.WHITE_COLOR};
  cursor: pointer;
`;

const ProgressDiv = styled.div`
  margin-top: 50px;
  position: relative;
  display: flex;
`;

const Main = styled.div<ModalOnProps>`
  position: ${(props) => (props.blinds ? "fixed" : "relative")};
  /* min-width: calc(100vw - 72px);
  min-height: 100vh; */
  height: auto;
  /* width: max-content;
  max-height: max-content; */
  padding-top: 50px;
  padding-bottom: 50px;
  max-width: inherit;
  background-color: ${theme.surface.neutral.tertiary};
  width: 100%;
  min-height: inherit;
`;

export { AppHealthCheck };
