import React, { useContext } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ModalContext } from "../../../context/ModalContext";
import { theme } from "../../../utils/theme";
import { PhoenixExpandButton } from "../Phoenix/PhoenixNavigation/PhoenixExpandButton";

export const SequenceNav: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const { isSequenceNavExpanded, setIsSequenceNavExpanded } = useContext(ModalContext);

  const isCurPath = (path: string) =>
    !!matchPath(location.pathname, {
      path: path,
      exact: true,
      strict: false,
    });

  return (
    <>
      <PhoenixExpandButton
        expandedState={isSequenceNavExpanded}
        moveDirection="right"
        moveDistance={224}
        top={24}
        left={72}
        onClick={() => setIsSequenceNavExpanded(!isSequenceNavExpanded)}
      />
      <Container expanded={isSequenceNavExpanded}>
        <NavigationItem
          active={isCurPath("/sequences")}
          onClick={() => !isCurPath("/sequences") && history.push("/sequences/")}
        >
          Sequences Home
        </NavigationItem>

        <NavigationItem
          active={isCurPath("/sequences/reporting")}
          onClick={() => !isCurPath("/sequences/reporting") && history.push("/sequences/reporting/")}
        >
          Performance
        </NavigationItem>

        <NavigationItem
          active={isCurPath("/system-config/email-templates")}
          onClick={() =>
            !isCurPath("/system-config/email-templates") && history.push("/system-config/email-templates/")
          }
        >
          Email Templates
        </NavigationItem>
        <NavigationItem
          active={isCurPath("/system-config/sms-templates")}
          onClick={() => !isCurPath("/system-config/sms-templates") && history.push("/system-config/sms-templates/")}
        >
          SMS Templates
        </NavigationItem>
        <NavigationItem
          active={isCurPath("/sequences/settings")}
          onClick={() => !isCurPath("/sequences/settings") && history.push("/sequences/settings/")}
        >
          Settings
        </NavigationItem>
      </Container>
    </>
  );
};

interface ContainerProps {
  expanded: boolean;
}

const Container = styled.div<ContainerProps>`
  position: absolute;
  left: ${(props) => (props.expanded ? "72px" : "-158px")};
  transition: left 0.2s ease-in-out;
  z-index: 1;

  height: 100%;
  width: max-content;
  overflow-y: auto;
  overflow-x: visible;
  background-color: ${theme.WHITE_COLOR};
`;

interface NavigationItemProps {
  active: boolean;
}

const NavigationItem = styled.div<NavigationItemProps>`
  display: flex;
  align-items: center;

  width: 224px;
  height: 64px;
  padding-left: 24px;

  border-right: ${(props) => (props.active ? `4px solid ${theme.PRIMARY500}` : `1px solid ${theme.NEUTRAL250}`)};
  border-bottom: 1px solid ${theme.NEUTRAL200};
  cursor: pointer;

  font-size: 12px;
  font-weight: ${(props) => (props.active ? "600" : "400")};
  color: ${(props) => (props.active ? theme.PRIMARY500 : theme.BLACK_COLOR)};
  :hover {
    color: ${theme.PRIMARY500};
  }
`;
