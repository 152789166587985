import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { AppText, FlexDiv, Loading } from "src/Components/UI";
import { appToast } from "src/utils/toast";
import { chevron_left, refresh } from "src/images/NewDesign";
import { DATE_RANGES_WITHOUT_SPACE, METRIC_TYPES_WITHOUT_SPACE } from "src/utils/format";
import { Formik, FormikProps } from "formik";
import { PhoenixAppButton, PhoenixIcon } from "src/Components/UI/Phoenix";
import { PhoenixMultiSelect, PhoenixRadio } from "src/Components/UI/Phoenix";
import { returnIndividualDataFromArrayBasedOnID, arraysAreEqual } from "src/utils/misc";
import { theme } from "src/utils/theme";
import { useHistory } from "react-router-dom";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { LabelT, OptionItem } from "src/types";
import { LabelSelect } from "src/Components/Smart";
import RankSelectedRepsTable from "./routing-rules/RankSelectedRepsTable";
import "src/Components/Segments/Onboarding/transfer-tab/TransferRulesTable.css";
import { PhoenixInputField } from "src/Components/Field/Phoenix";

interface IRep {
  id: string;
  rep_id: string;
  full_name: string;
  close_rate: string;
  priority: number;
  userLabels?: {
    label: {
      color: string;
      id: string;
      name: string;
    };
  }[];
}

interface MyFormikProps {
  name: string;
  active: boolean;
  distribution_method: string;
  reps: IRep[];
  transfer_type: string;
  backup_rule: string;
  rank_sort_type: string;
  metric: string;
  date_range: string;
  labels: LabelT[];
  rep_ids: string[];
  inbound_call_entry_numbers_list: string[];
}

interface CreateOrUpdateInboundCallRoutingRuleExpectedResponse {
  createOrUpdateInboundCallRoutingRule?: {
    id?: string;
    priority?: number;
    active?: boolean;
    rep_selection_method?: string;
    distribution_method?: string;
    updated_at?: string;
    conditions?: {
      id?: string;
    }[];
    routings?: {
      rep_id?: string;
      rule_id?: string;
    }[];
  };
}

interface RepOrderExpectedResponse {
  fetchRepOrder?: { id?: string; full_name?: string }[];
}

const FETCH_REP_ORDER = gql`
  query fetchRepOrder($user_ids: [String!]!) {
    fetchRepOrder(user_ids: $user_ids) {
      id
      full_name
    }
  }
`;

const CREATE_OR_UPDATE_CUSTOM_RULE = gql`
  mutation createOrUpdateInboundCallRoutingRule($rule_id: String, $data: InboundCallRoutingInput!) {
    createOrUpdateInboundCallRoutingRule(rule_id: $rule_id, data: $data) {
      id
      priority
      active
      rep_selection_method
      distribution_method
      updated_at
      inbound_call_entry_numbers_list
      routings {
        rep_id
        rule_id
        __typename
      }
    }
  }
`;

const FETCH_ONE_CUSTOM_RULE = gql`
  query fetchOneCustomTransferRule($rule_id: String!) {
    fetchOneCustomTransferRule(rule_id: $rule_id) {
      id
      rule_type
      name
      priority
      active
      updated_at
      rep_selection_method
      distribution_method
      rank_sort_type
      date_range
      metric
      inbound_call_entry_numbers_list
      routings {
        rep_id
        close_rate
      }
      labels {
        label_id
        label {
          id
          name
          color
          description
          reps {
            user_id
            user {
              id
              full_name
            }
          }
        }
      }
    }
  }
`;

const GET_ORG_INFO = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      Teams {
        id
        name
      }
      can_edit_sdr_transfer_rules
      Reps {
        id
        full_name
        userLabels {
          label {
            color
            id
            name
          }
        }
      }
      Sites {
        id
        name
      }
    }
  }
`;

const FETCH_TWILIO_ACTIVE_NUMBERS = gql`
  query fetchTwilioActiveNumbers {
    fetchTwilioActiveNumbers
  }
`;

const getUserLabels = (userId: string, labels: LabelT[]) => {
  const userLabels = labels.filter((label) => label.reps?.some((rep) => rep.user_id === userId));

  if (userLabels.length === 0) return [];
  return userLabels.map((label) => label.id);
};

const yupValidationSchema = Yup.object().shape({
  name: Yup.string().required("Field is required"),
  labels: Yup.array().notRequired().nullable(),
  rep_ids: Yup.array().of(Yup.string()),
  inbound_call_entry_numbers_list: Yup.array().of(Yup.string()).required("Please enter at least one number"),
});

const AddOrEditInboundCallFlow = ({ rule_id }: { rule_id?: string }) => {
  const history = useHistory();

  const formikRef = useRef() as any;
  const isNew = rule_id === "new";

  const ruleType = "InboundCall";

  const { data: dataSelectedRule, loading: loadingSelectedRule, refetch: refetchSelectedRule } = useQuery(
    FETCH_ONE_CUSTOM_RULE,
    {
      fetchPolicy: "network-only",
      skip: !rule_id,
      variables: {
        rule_id: rule_id,
      },
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: dataOrgInfo, error: errorOrgInfo, loading: loadingOrgInfo } = useQuery(GET_ORG_INFO, {
    fetchPolicy: "network-only",
    onError() {
      appToast("Error loading org info.");
    },
  });

  const { data: dataTwilioActiveNumbers } = useQuery(FETCH_TWILIO_ACTIVE_NUMBERS, {
    fetchPolicy: "cache-and-network",
  });

  const [fetchRepOrder, { data: dataOrder }] = useLazyQuery<RepOrderExpectedResponse>(FETCH_REP_ORDER, {
    fetchPolicy: "network-only",
  });

  const [
    createOrUpdateInboundCallRoutingRule,
    { loading: loadingUpdate },
  ] = useMutation<CreateOrUpdateInboundCallRoutingRuleExpectedResponse>(CREATE_OR_UPDATE_CUSTOM_RULE, {
    onCompleted({ createOrUpdateInboundCallRoutingRule }) {
      if (!createOrUpdateInboundCallRoutingRule) {
        return;
      }

      appToast("Rules Saved");
      history.push("/system-config/inbound-call-flow");
    },
    onError({ message }) {
      appToast(message);
    },
    refetchQueries: ["fetchAllCustomTransferRule", "FetchAllCustomTransferRule"],
  });

  const numberOptions = (dataTwilioActiveNumbers?.fetchTwilioActiveNumbers ?? [])?.map((item: any) => ({
    label: item,
    value: item,
  }));

  useEffect(() => {
    if (
      !!dataOrder &&
      !!dataOrder.fetchRepOrder &&
      !!dataOrder.fetchRepOrder.length &&
      !!formikRef.current &&
      !!formikRef.current.setFieldValue &&
      !!formikRef.current.values
    ) {
      const sortedIDs = dataOrder.fetchRepOrder?.map((item: any) => item.id);
      if (arraysAreEqual(sortedIDs, formikRef.current.values.rep_ids)) {
        formikRef.current.setFieldValue("rep_ids", sortedIDs);
      }
    }
  }, [dataOrder]);

  const loading = loadingSelectedRule || loadingOrgInfo;

  const DEFAULT_RULE = {
    id: "",
    name: "",
    active: true,
    distribution_method: "RoundRobin",
    reps: [],
    transfer_type: "Auto",
    backup_rule: "ScheduleMeeting",
    rank_sort_type: "Manually",
    metric: "CloseRate",
    date_range: "Today",
  } as const;

  const isRepValid = (repId: string) => {
    const repIsValid =
      !!dataOrgInfo?.fetchOrganization?.Reps &&
      dataOrgInfo?.fetchOrganization?.Reps.slice().filter((item: IRep) => repId === item.id);
    return repIsValid;
  };

  if (loading) return <Loading />;

  return (
    <Page>
      <Container editState={true}>
        <Header>
          <FlexDiv direction="column" gap={4}>
            <FlexDiv
              gap={4}
              align="center"
              style={{ cursor: "pointer", marginBottom: "8px" }}
              onClick={() => history.replace("/system-config/inbound-call-flow")}
            >
              <PhoenixIcon svg={chevron_left} size={16} pointer />
              <AppText
                fontSize={10}
                fontWeight={600}
                lineHeight={16}
                uppercase
                letterSpacing={1}
                color={theme.buttontext.brand_outline.default}
              >
                Back
              </AppText>
            </FlexDiv>

            <AppText fontSize={22} fontWeight={500} lineHeight={28}>
              {isNew ? "Add" : "Edit"} Inbound Call Rule
            </AppText>
          </FlexDiv>

          <FlexDiv align="center" gap={8}>
            {isNew ? (
              <PhoenixAppButton
                onClick={() => history.replace("/system-config/inbound-call-flow")}
                variant="danger-outline"
                buttonType="secondary"
              >
                Cancel
              </PhoenixAppButton>
            ) : null}

            <PhoenixAppButton
              buttonType="secondary"
              onClick={() => formikRef.current?.handleSubmit()}
              type="button"
              disabled={loadingUpdate}
              variant="brand"
            >
              Save Rule
            </PhoenixAppButton>
          </FlexDiv>
        </Header>

        <Body>
          <Formik
            enableReinitialize={true}
            innerRef={formikRef}
            initialValues={{
              name: dataSelectedRule?.fetchOneCustomTransferRule?.name || DEFAULT_RULE.name,
              active: dataSelectedRule?.fetchOneCustomTransferRule?.active ?? DEFAULT_RULE.active,
              distribution_method:
                dataSelectedRule?.fetchOneCustomTransferRule?.distribution_method || DEFAULT_RULE.distribution_method,
              reps: dataSelectedRule?.fetchOneCustomTransferRule?.routings
                ? dataSelectedRule?.fetchOneCustomTransferRule?.routings?.map((rep: IRep, index: number) => ({
                    rep_id: rep.rep_id,
                    close_rate: rep.close_rate,
                    priority: rep.priority ?? index + 1,
                  }))
                : DEFAULT_RULE.reps,
              rank_sort_type:
                dataSelectedRule?.fetchOneCustomTransferRule?.rank_sort_type || DEFAULT_RULE.rank_sort_type,
              transfer_type: dataSelectedRule?.fetchOneCustomTransferRule?.transfer_type || DEFAULT_RULE.transfer_type,
              backup_rule: dataSelectedRule?.fetchOneCustomTransferRule?.backup_rule || DEFAULT_RULE.backup_rule,
              metric: dataSelectedRule?.fetchOneCustomTransferRule?.metric || DEFAULT_RULE.metric,
              date_range: dataSelectedRule?.fetchOneCustomTransferRule?.date_range || DEFAULT_RULE.date_range,
              labels: dataSelectedRule?.fetchOneCustomTransferRule?.labels?.map((v: any) => v.label) ?? [],
              rep_ids: (
                dataSelectedRule?.fetchOneCustomTransferRule?.routings?.map((el: any) => el.rep_id) || []
              ).filter((id: string) => isRepValid(id)),
              inbound_call_entry_numbers_list:
                dataSelectedRule?.fetchOneCustomTransferRule?.inbound_call_entry_numbers_list ?? [],
            }}
            validationSchema={yupValidationSchema}
            onSubmit={async (values) => {
              const reps = values.rep_ids.map((id: string) => ({
                rep_id: id,
                label_ids: getUserLabels(id, values.labels),
              }));

              await createOrUpdateInboundCallRoutingRule({
                variables: {
                  data: {
                    name: values.name,
                    rule_type: dataSelectedRule?.fetchOneCustomTransferRule?.rule_type ?? ruleType,
                    distribution_method: values.distribution_method,
                    date_range: values.date_range,
                    metric: values.metric,
                    rank_sort_type: values.rank_sort_type,
                    inbound_call_entry_numbers_list: values.inbound_call_entry_numbers_list,
                    reps,
                    labels_id: values.labels.map((item: any) => item.id),
                  },
                  rule_id: !isNew ? rule_id ?? undefined : undefined,
                },
              });
            }}
          >
            {({ errors, values, setFieldValue }: FormikProps<MyFormikProps>) => {
              const selectedLabelRepIDs = values.labels?.reduce(
                (acc, label) => [...acc, ...(label.reps?.map((r) => r.user_id) || [])],
                [] as string[],
              );

              const repOptions = loadingOrgInfo
                ? [{ label: "Loading...", disabled: true, value: "" }]
                : (dataOrgInfo?.fetchOrganization?.Reps ?? [])
                    .filter((item: IRep) => !selectedLabelRepIDs?.includes(item.id!))
                    .map((item: IRep) => ({
                      label: item.full_name ?? "",
                      value: item.id!,
                    }))
                    .sort((a: any, b: any) => a.label.localeCompare(b.label));

              const reorderReps = (list: string[], startIndex: number, endIndex: number) => {
                const result = Array.from(list);
                const [removed] = result.splice(startIndex, 1);
                result.splice(endIndex, 0, removed);

                setFieldValue("rep_ids", result);
              };

              const onDragEndReps = (result: any) => {
                if (!result.destination) {
                  return;
                }

                reorderReps(values.rep_ids, result.source.index, result.destination.index);
              };

              const handleDateRangeChange = ({
                dateRange,
                setFieldValue,
              }: {
                dateRange: string;
                setFieldValue: any;
              }) => {
                setFieldValue("date_range", dateRange);
              };

              return (
                <>
                  <PhoenixInputField name="name" titleText="Inbound Call Rule Name" titleTextSpacing={8} width={376} />
                  <SpacerDiv />
                  <ContainerBoldText>
                    How would you like to prioritize lead distribution for this rule?
                  </ContainerBoldText>
                  <RadioSection>
                    <RadioDiv>
                      <PhoenixRadio
                        selected={values.distribution_method === "RoundRobin"}
                        onClick={() => {
                          setFieldValue("distribution_method", "RoundRobin");
                        }}
                      />
                      <AppText>Round Robin (even distribution) </AppText>
                    </RadioDiv>
                    <RadioDiv>
                      <PhoenixRadio
                        selected={values.distribution_method === "Ranking"}
                        onClick={async () => {
                          setFieldValue("distribution_method", "Ranking");
                        }}
                      />
                      <AppText>By Rank (always prioritize leads to your top ranked Reps)</AppText>
                    </RadioDiv>
                  </RadioSection>
                  <SpacerDiv
                    style={{
                      borderBottom: `solid 1px ${theme.NEUTRAL200}`,
                      marginTop: "16px",
                      marginBottom: "16px",
                    }}
                  />
                  <ConditionContainer>
                    <FlexDiv justify="space-between" align="center" style={{ marginBottom: "24px" }}>
                      <AppText fontSize={20} fontWeight={500} lineHeight={26}>
                        Set the phone number(s) for the rule
                      </AppText>
                    </FlexDiv>

                    <CriteriaDiv>
                      <FlexDiv direction="column" grow={1}>
                        <PhoenixMultiSelect
                          isMulti={true}
                          marginBottom={false}
                          placeholder="Select one"
                          name={`inbound_call_entry_numbers_list`}
                          options={numberOptions}
                          value={numberOptions.filter((item: any) =>
                            values.inbound_call_entry_numbers_list.includes(item.value),
                          )}
                          error={errors.inbound_call_entry_numbers_list}
                          onChange={(v: any) => {
                            setFieldValue(
                              "inbound_call_entry_numbers_list",
                              v.map((item: any) => item.value),
                            );
                          }}
                        />
                      </FlexDiv>
                    </CriteriaDiv>
                  </ConditionContainer>

                  <SpacerDiv />

                  <ConditionContainer>
                    <AppText fontSize={20} fontWeight={500} lineHeight={26} style={{ marginBottom: "24px" }}>
                      ...Then Distribute to Reps According to These Rules
                    </AppText>

                    <RepSelectionContainer>
                      <div style={{ width: "100%" }}>
                        <FlexDiv direction="column" gap={16} style={{ padding: "16px" }}>
                          <FlexDiv align="center" justify="space-between" style={{ marginBottom: "16px" }}>
                            <AppText
                              fontSize={10}
                              fontWeight={600}
                              letterSpacing={1}
                              uppercase
                              color={theme.text.neutral.secondary}
                            >
                              Select reps to include in this rule
                            </AppText>

                            <PhoenixAppButton
                              onClick={() => {
                                setFieldValue("labels", []);
                                setFieldValue("rep_ids", []);
                              }}
                              variant="danger-outline"
                              buttonType="ghost-small"
                            >
                              Clear Selection
                            </PhoenixAppButton>
                          </FlexDiv>

                          <FlexDiv direction="column" gap={16} style={{ maxWidth: 380 }}>
                            <LabelSelect
                              titleText="Reps With This Label"
                              titleTextSpacing={8}
                              value={values.labels?.map((x) => x.id)}
                              includeReps
                              onChange={(arr) => {
                                const newLabelRepIDs = arr.reduce(
                                  (acc, o) => [...acc, ...o.data.reps.map((r) => r.user_id)],
                                  [] as string[],
                                );

                                const oldLabelRepIDs = values.labels.reduce(
                                  (acc, label) => [...acc, ...label.reps.map((r) => r.user_id)],
                                  [] as string[],
                                );

                                const repIDsToAdd = newLabelRepIDs.filter((id) => !oldLabelRepIDs.includes(id));

                                const repIDsToRemove = oldLabelRepIDs.filter((id) => !newLabelRepIDs.includes(id));

                                setFieldValue(
                                  "labels",
                                  arr.map((o) => o.data),
                                );

                                setFieldValue("rep_ids", [
                                  ...values.rep_ids.filter((id) => !repIDsToRemove.includes(id)),
                                  ...repIDsToAdd,
                                ]);
                              }}
                            />

                            <PhoenixMultiSelect
                              marginBottom={false}
                              hideErrorMessage
                              isClearable
                              name="reps"
                              isMulti
                              titleText="Add Rep Individually"
                              isOptionDisabled={(option) => option.disabled}
                              titleTextSpacing={8}
                              options={repOptions}
                              value={values.rep_ids.map((item: any) =>
                                repOptions.find((option: OptionItem) => option.value === item),
                              )}
                              onChange={(v: any) => {
                                const newSelectedRepIDs = v.map((item: any) => item.value);

                                const labelAssociatedRepIDs = values.labels.reduce(
                                  (acc, label) => [...acc, ...(label.reps?.map((r) => r.user_id) || [])],
                                  [] as string[],
                                );

                                const currentIndividualRepIDs = values.rep_ids.filter(
                                  (id) => !labelAssociatedRepIDs.includes(id),
                                );

                                const repIDsToAdd = newSelectedRepIDs.filter(
                                  (id: any) => !currentIndividualRepIDs.includes(id),
                                );
                                const repIDsToRemove = currentIndividualRepIDs.filter(
                                  (id: any) => !newSelectedRepIDs.includes(id),
                                );

                                const updatedRepIDs = [
                                  ...labelAssociatedRepIDs,
                                  ...currentIndividualRepIDs.filter((id) => !repIDsToRemove.includes(id)),
                                  ...repIDsToAdd,
                                ];

                                const uniqueUpdatedRepIDs = [...new Set(updatedRepIDs)];
                                setFieldValue("rep_ids", uniqueUpdatedRepIDs);
                              }}
                            />
                          </FlexDiv>
                        </FlexDiv>
                      </div>

                      <RankSelectedRepsContainer>
                        <FlexDiv align="center" justify="space-between">
                          <AppText
                            fontSize={10}
                            fontWeight={600}
                            letterSpacing={1}
                            uppercase
                            color={theme.text.neutral.secondary}
                            style={{ marginBottom: "16px" }}
                          >
                            Rank Selected Reps
                          </AppText>

                          {values?.rep_ids?.length > 1 && (
                            <FlexDiv
                              onClick={async () => {
                                await fetchRepOrder({
                                  variables: {
                                    user_ids: values.rep_ids,
                                  },
                                });
                              }}
                              align="center"
                              gap={4}
                              style={{ cursor: "pointer" }}
                            >
                              <PhoenixIcon
                                svg={refresh}
                                color={theme.icon.brand.default}
                                hoverColor={theme.icon.brand.default}
                                pointer
                                size={12}
                              />
                              <AppText fontSize={10} fontWeight={600} lineHeight={14} color={theme.text.brand.primary}>
                                Sellfire Suggested Ranking
                              </AppText>
                            </FlexDiv>
                          )}
                        </FlexDiv>

                        <RankSelectedRepsTable
                          data={
                            values.rep_ids.map((item: any) =>
                              returnIndividualDataFromArrayBasedOnID({
                                id: item,
                                array: dataOrgInfo?.fetchOrganization?.Reps || ([] as any),
                              }),
                            ) ?? []
                          }
                          selectedLabels={values.labels ?? []}
                          isLoading={loadingOrgInfo}
                          isError={errorOrgInfo}
                          onDragEnd={onDragEndReps}
                          onDelete={(itemID: any) =>
                            setFieldValue(
                              "rep_ids",
                              values.rep_ids.filter((id: string) => id !== itemID),
                            )
                          }
                        />
                      </RankSelectedRepsContainer>
                    </RepSelectionContainer>

                    <AppText
                      color={theme.PRIMARY500}
                      fontSize={10}
                      fontWeight={600}
                      style={{ margin: "16px 0" }}
                      uppercase
                    >
                      Priority Order Of Reps
                    </AppText>
                    <InputLabel>
                      How would you like to determine the order in which the above reps are prioritized?
                    </InputLabel>
                    <RadioLabel style={{ marginBottom: "16px" }}>
                      <PhoenixRadio
                        size={16}
                        selected={values.rank_sort_type === "Manually"}
                        onClick={async () => {
                          setFieldValue("rank_sort_type", "Manually");
                        }}
                      />
                      <AppText>Manually (by priority number)</AppText>
                    </RadioLabel>
                    <RadioLabel style={{ marginBottom: "16px" }}>
                      <PhoenixRadio
                        size={16}
                        selected={values.rank_sort_type === "Dynamically"}
                        onClick={async () => {
                          setFieldValue("rank_sort_type", "Dynamically");
                        }}
                      />
                      <AppText>Dynamically</AppText>
                    </RadioLabel>

                    {values.rank_sort_type === "Dynamically" && (
                      <FlexDiv direction="column" gap={16} style={{ width: "396px" }}>
                        <PhoenixMultiSelect
                          name="metric-select"
                          titleText="Metric"
                          isMulti={false}
                          isClearable={false}
                          marginBottom={false}
                          options={METRIC_TYPES_WITHOUT_SPACE}
                          value={METRIC_TYPES_WITHOUT_SPACE?.find((item: OptionItem) => item.value === values.metric)}
                          onChange={(option: any) => {
                            setFieldValue(`metric`, option?.value);
                          }}
                        />

                        <PhoenixMultiSelect
                          name="date-range-select"
                          titleText="Date Range"
                          isMulti={false}
                          isClearable={false}
                          options={DATE_RANGES_WITHOUT_SPACE}
                          value={DATE_RANGES_WITHOUT_SPACE.find((item: OptionItem) => item.value === values.date_range)}
                          onChange={(option: any) => {
                            handleDateRangeChange({
                              dateRange: option?.value,
                              setFieldValue: setFieldValue,
                            });
                          }}
                        />
                      </FlexDiv>
                    )}
                  </ConditionContainer>
                </>
              );
            }}
          </Formik>
        </Body>
      </Container>
    </Page>
  );
};

export default AddOrEditInboundCallFlow;

const Page = styled.div`
  width: 100%;
  height: 100vh;
  padding: 24px;
`;

const Container = styled.div<{ editState: boolean }>`
  width: 100%;
  height: 100%;

  min-width: 1200px;

  background-color: ${({ editState }) => (editState ? theme.surface.brand.secondary : theme.fill.neutral.primary)};
  border-radius: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 24px 40px;

  background-color: ${theme.fill.neutral.primary};
  border-radius: 16px 16px 0 0;
  border-bottom: 1px solid ${theme.border.neutral.secondary};
`;

const Body = styled.div`
  padding: 40px;
  max-height: 82vh;
  overflow-y: auto;
`;

const ValueDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

const CriteriaDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

const RadioDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SpacerDiv = styled.div`
  height: 16px;
  min-height: 16px;
`;

const RadioSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const ContainerBoldText = styled(AppText)`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const DateTimePickerWrapper = styled.div`
  .react-datetime-picker {
    height: 40px;
    * {
      /* color: ${theme.PRIMARY600} !important; */
    }
    border: none;
  }
`;

const InputLabel = styled(AppText)`
  margin-bottom: 8px;
  margin-top: 8px;
  font-weight: 500;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;

  input {
    accent-color: ${theme.PRIMARY500};
  }
`;

const RankSelectedRepsContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 16px 24px;

  border-left: 1px solid ${theme.border.neutral.secondary};
`;

const RepSelectionContainer = styled.div`
  display: flex;
  /* flex-direction: column; */

  width: 100%;

  border: 1px solid ${theme.border.neutral.secondary};
  border-radius: 8px;
`;

const ConditionContainer = styled.div`
  min-width: 1075px;
  padding: 24px;
  margin-top: 24px;

  border: 1px solid ${theme.border.neutral.primary};
  border-radius: 8px;
  background-color: ${theme.fill.neutral.primary};
`;

const ConditionBlock = styled.div`
  position: relative;

  display: flex;
  gap: 64px;
  position: relative;

  width: 100%;
  min-width: 1025px;
  padding: 16px;
  margin-bottom: 16px;

  border: 1px solid ${theme.border.neutral.secondary};
  border-radius: 8px;
`;

const ConditionBody = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
`;

export { AddOrEditInboundCallFlow };
