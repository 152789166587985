import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { NodeContainer } from "./NodeContainer";
import { Handle, Position } from "react-flow-renderer";
import { PhoenixIcon } from "../../Phoenix";
import { bar_chart, clock, edit, more_vertical, trash, users } from "../../../../images/NewDesign";
import { AppText } from "../../AppText";
import { parseStepNodeData } from "../../../../utils/sequences";
import { FlexDiv } from "../../FlexDiv";
import { useClickOutside } from "../../../../utils/hooks";
import { ModalContext } from "../../../../context";
import { useFlags } from "launchdarkly-react-client-sdk";

interface SequenceStepProps {
  data: any;
}

export const RepViewSequenceStep: React.FC<SequenceStepProps> = ({ data }) => {
  const nodeData = parseStepNodeData(data);
  const [showSequenceActionMenu, setShowSequenceActionMenu] = useState<boolean>(false);
  const [nodeIsFocus, setNodeIsFocus] = useState<boolean>(false);
  const { setSelectedSequenceStepId } = useContext(ModalContext);

  const { showRepViewSequenceModal } = useContext(ModalContext);

  const currentStepID = showRepViewSequenceModal?.sequence_step_id ?? "";

  useEffect(() => {
    const curNode = document.getElementById(data.id);
    const parentNode = curNode?.parentElement;
    if (!!parentNode) {
      parentNode.style.zIndex = nodeIsFocus ? "10" : "0";
    }
  }, [nodeIsFocus]);

  return (
    <div id={data.id}>
      {nodeData.delay && (
        <DelayContainer>
          <PhoenixIcon svg={clock} color={theme.NEUTRAL300} hoverColor={theme.NEUTRAL300} size="small" />
          <AppText fontSize={12} color={theme.WHITE_COLOR}>
            Delay for <span style={{ fontWeight: 600 }}>{nodeData.delay?.label}</span>
          </AppText>
        </DelayContainer>
      )}

      <NodeContainer
        padding="0px"
        style={{
          overflow: "unset",
          borderRadius: !!nodeData.delay ? "0px 0px 8px 8px" : "8px",
          borderColor: currentStepID === data?.id ? theme.PRIMARY500 : theme.NEUTRAL300,
          borderWidth: currentStepID === data?.id ? "8px" : !!nodeData.delay ? "0px 1px 1px 1px" : "1px",
        }}
        className="nodrag"
      >
        <FlexDiv align="center" style={{ padding: "16px 8px 16px 0px" }}>
          <RankContainer>{data?.rank}</RankContainer>
          <IconCircle>
            <PhoenixIcon svg={nodeData.icon} size="small" variant="neutral" hoverColor={theme.NEUTRAL300} />
          </IconCircle>
          <AppText fontSize={12} fontWeight={500}>
            {nodeData.label}
          </AppText>
        </FlexDiv>

        <MetricsContainer>
          <StandardMetrics>
            <PhoenixIcon svg={users} hoverColor={theme.PRIMARY500} size="small" />
            <AppText fontSize={12} fontWeight={600}>
              {data?.lead_count}
            </AppText>
          </StandardMetrics>
          {nodeData.metrics && (
            <>
              <MetricCTR>
                <AppText fontSize={12} fontWeight={600}>
                  {nodeData?.metrics?.ctr}%
                </AppText>
                <AppText fontSize={12} fontWeight={400}>
                  CTR
                </AppText>
              </MetricCTR>
              <StandardMetrics>
                <AppText fontSize={12} fontWeight={600}>
                  {nodeData?.metrics?.opened}%
                </AppText>
                <AppText fontSize={12} fontWeight={400}>
                  Opened
                </AppText>
              </StandardMetrics>
            </>
          )}
        </MetricsContainer>

        <Handle type="source" position={Position.Bottom} isConnectable={false} />
        {data?.origin_id && <Handle type="target" position={Position.Top} isConnectable={false} />}
      </NodeContainer>
    </div>
  );
};

const DelayContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 100%;
  height: 32px;

  background-color: ${theme.NEUTRAL450};
  border: solid ${theme.NEUTRAL300};
  border-width: 1px 1px 0px 1px;
  border-radius: 8px 8px 0px 0px;
`;

const IconCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;
  margin-right: 8px;

  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 50%;
  cursor: default;
`;

const MetricsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 34px;
  padding: 16px 9px;
  background-color: ${theme.NEUTRAL100};
  overflow: hidden;
  border-radius: 0px 0px 8px 8px;
`;

const StandardMetrics = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const MetricCTR = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0px 16px 0px 16px;
  padding: 0px 16px 0px 16px;
  border: 0px solid ${theme.NEUTRAL200};
  border-left-width: 1px;
  border-right-width: 1px;
`;

const RankContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;
  margin-right: 8px;

  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: ${theme.WHITE_COLOR};

  background-color: ${theme.PRIMARY900};
  border-radius: 0px 8px 8px 0px;
`;

const PopupContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 16px;

  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 4px;

  animation: ${theme.popup} 0.2s ease;
  overflow: hidden;
`;

interface MenuItemProps {
  active?: boolean;
}

const MenuItem = styled.div<MenuItemProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 152px;
  height: 32px;
  padding: 8px;

  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  color: ${theme.BLACK_COLOR};

  transition: background-color 0.15s ease, color 0.15s ease;
  z-index: 1;

  :hover {
    background-color: ${theme.PRIMARY100};
    color: ${(props) => (!props?.active ? theme.PRIMARY500 : "black")};
  }
`;
