import moment from "moment";
import React, { useContext } from "react";
import styled from "styled-components";
import { message_incoming, message_outgoing, message_square, sequences } from "../../../images/NewDesign";
import { CurrentLeadType } from "../../../types";
import { formatPhoneNumber } from "../../../utils/format";
import { theme } from "../../../utils/theme";
import { LeadContactLabel } from "../../Segments/DashboardSideBarSegments/LeadCardV2";
import { ProfileImageWithText } from "../../Segments/SettingSegments/ProfileImageWithText";
import { AppText, FlexDiv } from "../../UI";
import { PhoenixIcon } from "../../UI/Phoenix";
import { formatSMSTypeLabel } from "../../../utils/format";

interface SMSMessageActivityProps {
  isFromRep: boolean;
  id: string;
  text: string;
  sent_at: string;
  lead_number_used: string;
  primary_lead_id: string;
  associate_lead_ids: string[];
  contact_id: string;
  isAssociatedLead: boolean;
  type: string;
  sequence_name?: string;
  lead_activity: {
    id?: string;
    rep_phone_number?: string;
    user?: {
      id?: string;
      first_name?: string;
      last_name?: string;
      profile_image?: string;
      phone_number?: string;
    };
    lead?: {
      id?: string;
      first_name?: string;
      last_name?: string;
      associated_parent_id?: string;
    };
  };
  // if the user still owns the lead
  //(lead activity could be an old message and the lead has moved to a differnt rep since that time.)
  userStillOwnsLead: boolean;
}

const SMSMessageActivityV2: React.FC<SMSMessageActivityProps> = ({
  isFromRep,
  id,
  text,
  sent_at,
  lead_number_used,
  lead_activity,
  primary_lead_id,
  associate_lead_ids,
  contact_id,
  type,
  isAssociatedLead,
  sequence_name,
  userStillOwnsLead,
}) => {
  if (isFromRep) {
    const smsRepPhoneNumber = lead_activity?.rep_phone_number || lead_activity?.user?.phone_number;
    return (
      <HistoryItemDiv key={id}>
        <SMSHeader>
          <SMSHeaderLeft>
            <SMSHeaderCell>
              <HeaderCellTop>
                <FlexDiv gap={8} align="center">
                  <ProfileImageWithText
                    width={24}
                    height={24}
                    first_name={lead_activity?.user?.first_name}
                    last_name={lead_activity?.user?.last_name}
                    profile_image={lead_activity?.user?.profile_image}
                  />

                  <AppText fontSize={12} fontWeight={600} style={{ paddingBottom: "4px" }}>
                    {lead_activity?.user?.first_name} {lead_activity?.user?.last_name}
                  </AppText>
                </FlexDiv>
              </HeaderCellTop>
            </SMSHeaderCell>
            <SMSHeaderCell>
              <HeaderCellTop>
                <AppText
                  fontSize={12}
                  fontWeight={600}
                  style={{
                    paddingTop: "2px",
                  }}
                >
                  {formatSMSTypeLabel(type)}
                </AppText>
              </HeaderCellTop>

              <FlexDiv gap={8} align="center">
                <PhoenixIcon svg={message_outgoing} size={16} variant="brand" />
                <AppText fontSize={12} fontWeight={400}>
                  {smsRepPhoneNumber ? formatPhoneNumber(smsRepPhoneNumber) : "NA"}
                </AppText>
              </FlexDiv>
            </SMSHeaderCell>
            <SMSHeaderCell>
              <HeaderCellTop>
                <LeadContactLabel
                  fontWeightOverride={600}
                  contact={
                    {
                      id: lead_activity?.lead?.id ?? "",
                      first_name: lead_activity?.lead?.first_name || "",
                      last_name: lead_activity?.lead?.last_name || "",
                    } as CurrentLeadType
                  }
                  contactType={isAssociatedLead ? "associate" : "primary"}
                />
              </HeaderCellTop>
              <FlexDiv gap={8} align="center">
                <PhoenixIcon svg={message_incoming} size={16} variant="brand" />
                <AppText fontSize={12} fontWeight={400}>
                  {lead_number_used ? formatPhoneNumber(lead_number_used) : "NA"}
                </AppText>
              </FlexDiv>
            </SMSHeaderCell>
          </SMSHeaderLeft>
          <SMSHeaderRight>
            <SMSHeaderCell
              style={{
                height: "44px",
                // to prevent left side from squishing text
                width: "300px",
              }}
            >
              <HeaderCellTop>
                <FlexDiv gap={4} align="center">
                  <AppText fontSize={12} fontWeight={600} color={theme.text.neutral.primary}>
                    {moment(sent_at).format("MM/DD/YYYY")}
                  </AppText>
                  <AppText fontSize={12} fontWeight={600} color={theme.text.neutral.secondary}>
                    {moment(sent_at).format("h:mm A")}
                  </AppText>
                </FlexDiv>
              </HeaderCellTop>
              {sequence_name && (
                <FlexDiv
                  gap={8}
                  align="center"
                  style={{
                    backgroundColor: theme.fill.warning.secondary,
                    padding: "4px 8px",
                    borderRadius: "4px",
                  }}
                >
                  <PhoenixIcon svg={sequences} size={16} variant="alert" hoverColor={theme.WARNING500} />
                  <AppText fontSize={12} fontWeight={600} color={theme.text.warning.primary}>
                    {sequence_name || "Sequence"}
                  </AppText>
                </FlexDiv>
              )}
            </SMSHeaderCell>
          </SMSHeaderRight>
        </SMSHeader>
        <SMSBody>
          <AppText
            fontSize={12}
            fontWeight={400}
            color={theme.text.neutral.quaternary}
            style={{
              // respect new lines and other formatting
              whiteSpace: "pre-line",
            }}
          >
            {text}
          </AppText>
        </SMSBody>
      </HistoryItemDiv>
    );
  } else {
    return (
      <HistoryItemDiv key={id}>
        <SMSHeader>
          <SMSHeaderLeft>
            <SMSHeaderCell>
              <FlexDiv gap={8} align="center">
                <LeadContactLabel
                  fontWeightOverride={600}
                  contact={
                    {
                      id: lead_activity?.lead?.id ?? "",
                      first_name: lead_activity?.lead?.first_name || "",
                      last_name: lead_activity?.lead?.last_name || "",
                    } as CurrentLeadType
                  }
                  contactType={isAssociatedLead ? "associate" : "primary"}
                />
              </FlexDiv>
            </SMSHeaderCell>
            <SMSHeaderCell>
              <FlexDiv height={24} align="center">
                <AppText fontSize={12} fontWeight={600} style={{ paddingTop: "2px" }}>
                  {formatSMSTypeLabel(type)}
                </AppText>
              </FlexDiv>
              <FlexDiv gap={8} align="center">
                <PhoenixIcon svg={message_outgoing} size={16} variant="brand" />
                <AppText fontSize={12} fontWeight={400}>
                  {lead_number_used ? formatPhoneNumber(lead_number_used) : "NA"}
                </AppText>
              </FlexDiv>
            </SMSHeaderCell>
            <SMSHeaderCell>
              <FlexDiv gap={8} align="center">
                <ProfileImageWithText
                  width={24}
                  height={24}
                  first_name={lead_activity?.user?.first_name}
                  last_name={lead_activity?.user?.last_name}
                  profile_image={lead_activity?.user?.profile_image}
                />
                <AppText
                  fontSize={12}
                  fontWeight={600}
                  style={{
                    paddingBottom: "4px",
                  }}
                >
                  {lead_activity?.user?.first_name} {lead_activity?.user?.last_name}
                </AppText>
              </FlexDiv>
              <FlexDiv gap={8} align="center">
                <PhoenixIcon svg={message_incoming} size={16} variant="brand" />
                <AppText fontSize={12} fontWeight={400}>
                  {lead_number_used ? formatPhoneNumber(lead_number_used) : "NA"}
                </AppText>
              </FlexDiv>
            </SMSHeaderCell>
          </SMSHeaderLeft>
          <SMSHeaderRight>
            <SMSHeaderCell
              style={{
                height: "44px",
                // to prevent left side from squishing text
                width: "300px",
              }}
            >
              <FlexDiv gap={4} align="center">
                <AppText fontSize={12} fontWeight={600} color={theme.text.neutral.primary}>
                  {moment(sent_at).format("MM/DD/YYYY")}
                </AppText>
                <AppText fontSize={12} fontWeight={600} color={theme.text.neutral.secondary}>
                  {moment(sent_at).format("h:mm A")}
                </AppText>
              </FlexDiv>
            </SMSHeaderCell>
          </SMSHeaderRight>
        </SMSHeader>
        <SMSBody>
          <AppText
            fontSize={12}
            fontWeight={400}
            color={theme.text.neutral.quaternary}
            style={{
              // respect new lines and other formatting
              whiteSpace: "pre-line",
            }}
          >
            {text}
          </AppText>
        </SMSBody>
      </HistoryItemDiv>
    );
  }
};

const HistoryItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${theme.WHITE_COLOR};
  padding: 16px;
  border-bottom: 1px solid ${theme.border.neutral.primary};
  overflow: auto;
`;

const SMSHeaderCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 48px;
  max-width: 165px;
  width: "max-content";
  min-width: 100px;
`;

const HeaderCellTop = styled.div`
  display: flex;
  align-items: flex-start;
`;

const SMSHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.border.neutral.secondary};
  overflow: auto;
`;

const SMSBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 16px;
`;

const SMSHeaderLeft = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
`;

const SMSHeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
`;

interface RepAndResultIcon {
  background?: string;
  color?: string;
}
const RepAndResultIcon = styled.div<RepAndResultIcon>`
  // font
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 14px;
  text-transform: uppercase;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  margin-right: 4px;

  background-color: ${(props) => {
    return props.background;
  }};

  color: ${(props) => {
    return props.color;
  }};
  // never wrap
  white-space: nowrap;
`;

const IconAndTitleDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  color: ${theme.PRIMARY500};
`;

const CallNotesDivNewV2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: none;
  height: 100%;
  width: 100%;
  gap: 12px;
`;

const CommunicationTitleText = styled(AppText)`
  font-family: 400;
  font-size: 12px;
  color: ${theme.PRIMARY500};
  line-height: 18px;
`;

export { SMSMessageActivityV2 };
