import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { useDebounce } from "../../../utils/hooks";
import { AppText, FlexDiv, StatCard } from "../../UI";
import { check, xIcon, info } from "../../../images/NewDesign";
import { PhoenixIcon } from "../../UI/Phoenix";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import Switch from "react-switch";
import ReactTooltip from "react-tooltip";
interface ToggleCommunicationsBarProps {
  repIsAdmin: boolean;
  toggled: boolean;
  type: "email" | "sms";
  toggle: () => void;
  leadOptedOut?: boolean;
  repOwnsLead?: boolean;
  tooltipText: string;
  loading?: boolean;
}

const canToggleSubStatus = ({
  toggledOn,
  repOwnsLead,
  repIsAdmin,
  leadOptedOut,
  type,
}: {
  toggledOn: boolean;
  repOwnsLead: boolean;
  repIsAdmin: boolean;
  leadOptedOut: boolean;
  type: "email" | "sms";
}): boolean => {
  const leadOptedOutOfSMS = leadOptedOut && type === "sms";

  const canToggleOff = repOwnsLead || repIsAdmin;

  const canToggleOn = repIsAdmin && !leadOptedOutOfSMS;

  if (toggledOn) {
    return canToggleOff;
  }

  if (!toggledOn) {
    return canToggleOn;
  }

  console.error("Something went wrong with the toggle helper function");
  return false;
};

const CommunicationToggle = ({
  repIsAdmin = false,
  toggled = false,
  type = "email",
  toggle = () => {},
  repOwnsLead = false,
  leadOptedOut = false,
  tooltipText = "",
  loading = false,
}: ToggleCommunicationsBarProps) => {
  const canToggle = canToggleSubStatus({ toggledOn: toggled, repOwnsLead, repIsAdmin, leadOptedOut, type });
  const [localToggled, setLocalToggled] = useState<boolean | undefined>(undefined);

  const REP_TOGGLE_WARNING_TOOLTIP = repOwnsLead
    ? `Toggling off will stop all automated and manual ${type} communications.`
    : `Only an enablement user or the owner of this lead can toggle ${type} communications.`;

  useEffect(() => {
    setLocalToggled(toggled);
  }, [toggled]);

  useDebounce(
    () => {
      const initalLoad = localToggled === undefined;
      const toggledChanged = localToggled !== toggled;

      if (!initalLoad && toggledChanged) {
        toggle();
      }
    },
    [localToggled],
    700,
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [localToggled, repIsAdmin, repOwnsLead, loading]);

  return (
    <>
      <PhoenixStyledTooltip id="bar-tooltip" />
      <Bar toggled={!!localToggled} type={type} leadOptedOut={leadOptedOut}>
        <FlexDiv align="center" gap={8}>
          <div
            data-for={localToggled && !repIsAdmin ? "bar-tooltip" : undefined}
            data-tip={localToggled && !repIsAdmin ? REP_TOGGLE_WARNING_TOOLTIP : undefined}
          >
            <Switch
              onChange={(checked: boolean) => {
                if (canToggle) {
                  setLocalToggled(checked);
                }
              }}
              checked={!!localToggled}
              onColor={theme.fill.success.primary}
              offColor={leadOptedOut ? theme.NEUTRAL200 : theme.fill.danger.secondary}
              height={16}
              width={32}
              handleDiameter={12}
              checkedIcon={
                <PhoenixIcon
                  svg={check}
                  size={12}
                  variant="white"
                  hoverColor={theme.WHITE_COLOR}
                  pointer
                  style={{ padding: "2px 0px 0px 4px" }}
                />
              }
              uncheckedIcon={
                <PhoenixIcon
                  svg={xIcon}
                  size={12}
                  variant="white"
                  hoverColor={theme.WHITE_COLOR}
                  pointer
                  style={{ padding: "2px 0px 0px 4px" }}
                />
              }
              disabled={!canToggle || loading}
            />
          </div>
          <AppText
            fontSize={10}
            fontWeight={500}
            color={
              leadOptedOut ? theme.NEUTRAL250 : localToggled ? theme.text.success.primary : theme.text.danger.primary
            }
          >
            {type === "email" ? "Email" : "SMS"} Communications
          </AppText>
          <PhoenixIcon
            svg={info}
            size={12}
            variant="neutral"
            color={leadOptedOut ? theme.NEUTRAL200 : undefined}
            data-tip={tooltipText}
            data-for="bar-tooltip"
            style={{ justifySelf: "flex-end" }}
          />
        </FlexDiv>
      </Bar>
    </>
  );
};

const Bar = styled.div<{ toggled: boolean; type: string; leadOptedOut: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 24px;
  padding: 3px;

  background-color: ${(props) =>
    props.leadOptedOut ? theme.NEUTRAL300 : props.toggled ? theme.fill.success.tertiary : theme.fill.danger.tertiary};
  border-radius: 40px;
  border: 1px solid
    ${(props) =>
      props.leadOptedOut
        ? theme.NEUTRAL400
        : props.toggled
        ? theme.fill.success.secondary
        : theme.border.danger.secondary};
`;

export { CommunicationToggle };
