import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FlexDiv, Loading } from "src/Components/UI";
import { PhoenixIcon, PhoenixInput, PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import { LeadCardContext, LeadFilterContext, ModalContext } from "src/context";
import { filter, search } from "src/images/NewDesign";
import { MixpanelActions } from "src/services/mixpanel";
import { OptionItem } from "src/types";
import { QUEUE_SORT_OPTIONS } from "src/utils/misc";
import { theme } from "src/utils/theme";
import styled, { keyframes } from "styled-components";
import { CompactLeadCard } from "./";
import InfiniteScroll from "react-infinite-scroll-component";
import { loggedInUser } from "src/apollo/cache";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { errorToast } from "src/utils/toast";
import { UpdateLeadDataComponent } from "src/Components/modal";
import { cloneDeep } from "lodash";
import { ACTIVE_QUEUE_TAKE, QUEUE_LIMIT } from "src/utils/variables";

const CUSTOM_TASK_ACK = gql`
  mutation customTaskAck($customTaskAckInput: customTaskAckInput!) {
    customTaskAck(customTaskAckInput: $customTaskAckInput)
  }
`;

interface UpcomingLeadIntent {
  id: string;
  lead: any; // TODO: define lead shape
  lead_id: string;
  type: string;
}

interface IQueue {
  selectedQueueIndex: number;
  setSelectedQueueIndex: Dispatch<SetStateAction<number>>;
}

export const Queue: FC<IQueue> = ({ selectedQueueIndex, setSelectedQueueIndex }) => {
  const {
    sortOptionDialQueue,
    setSortOptionDialQueue,
    selectedTabDialQueue,
    setSelectedTabDialQueue,
    upcomingDials,
    setUpcomingDials,
    activeQueueSkipRef,
    activeQueueSkip,
    activeQueueHasMore,
    fetchActiveQueue,
  } = useContext(LeadCardContext);
  const {
    showFiltersModal,
    setShowFiltersModal,
    editModal,
    setEditModal,
    editModalLeadId,
    setEditModalLeadId,
  } = useContext(ModalContext);
  const { filterNum, leadFilter } = useContext(LeadFilterContext);

  const titleIsBusinessName = loggedInUser()?.organization?.title_is_business_name;

  const maxCardContentHeight = useRef<number | undefined>(undefined);

  const selectedIntentId = useMemo(() => upcomingDials[selectedQueueIndex]?.id, [selectedQueueIndex, upcomingDials]);

  useEffect(() => {
    fetchActiveQueue({
      variables: {
        skip: 0,
        take: ACTIVE_QUEUE_TAKE,
        lead_filter: leadFilter,
        sort_option: sortOptionDialQueue,
      },
    });
  }, []);

  const handleShowMore = useCallback(() => {
    // TODO: add condition for custom queue
    activeQueueSkipRef.current = activeQueueSkip + ACTIVE_QUEUE_TAKE;
    fetchActiveQueue({
      variables: {
        skip: activeQueueSkip + ACTIVE_QUEUE_TAKE,
        take: ACTIVE_QUEUE_TAKE,
        lead_filter: leadFilter,
        sort_option: sortOptionDialQueue,
      },
    });
  }, [fetchActiveQueue, activeQueueSkipRef.current, leadFilter, sortOptionDialQueue]);

  const [customTaskAck] = useMutation(CUSTOM_TASK_ACK, {
    onCompleted({ customTaskAck: leadIntentId }) {
      // remove from active queue
      const filteredActive = upcomingDials.slice().filter((item: any) => item?.id !== leadIntentId);

      setUpcomingDials(filteredActive);

      upcomingDials.length < QUEUE_LIMIT - 2 &&
        fetchActiveQueue({
          variables: {
            skip: activeQueueSkip === 0 ? ACTIVE_QUEUE_TAKE : activeQueueSkip,
            take: 4,
            lead_filter: leadFilter,
            sort_option: sortOptionDialQueue,
          },
        });
    },
    onError({ message }) {
      errorToast(message);
      console.log("Error marking custom task: ", message);
    },
  });

  /**
   * Find's the lead inside of upcoming dials and manually updates state to include new edited data.
   *
   * This is to circumvent refetching the queue just to receive the new edited data.
   */
  const editLeadCallback = useCallback(
    (newLeadData: any) => {
      const newUpcomingDials = cloneDeep(upcomingDials);
      newUpcomingDials.map((item: any) => {
        if (item.lead.id !== newLeadData.id) return item;
        Object.keys(newLeadData).forEach((key) => {
          item.lead[key] = newLeadData[key];
        });
        return item;
      });
      setUpcomingDials(newUpcomingDials);
    },
    [upcomingDials],
  );

  return (
    <>
      {editModal && !!editModalLeadId && (
        <DarkDiv2>
          <SlideInDiv>
            <UpdateLeadDataComponent
              visible={editModal}
              close={() => {
                setEditModal(false);
                setEditModalLeadId("");
              }}
              lead_id={editModalLeadId}
              refetchList={[]}
              callback={editLeadCallback}
            />
          </SlideInDiv>
        </DarkDiv2>
      )}

      <QueueContainer>
        <SearchLeads
          placeholder="Search Leads"
          height={32}
          displayNoContextText
          insideLeftElementOverride={
            <PhoenixIcon
              svg={search}
              size={16}
              color={theme.icon.brand.default}
              hoverColor={theme.icon.brand.default}
              style={{ paddingLeft: "20px", zIndex: 1, animation: "searchLeadStartup 0.5s ease-in-out forwards" }}
            />
          }
        />

        <QueueMenu>
          <FlexDiv style={{ marginTop: "auto" }}>
            <QueueMenuButton
              selected={selectedTabDialQueue === "active"}
              onClick={() => setSelectedTabDialQueue("active")}
            >
              Suggested
            </QueueMenuButton>
            <QueueMenuButton
              selected={selectedTabDialQueue === "custom"}
              onClick={() => setSelectedTabDialQueue("custom")}
            >
              Custom
            </QueueMenuButton>
          </FlexDiv>

          <QueueMenuFill />

          <FlexDiv
            align="center"
            gap={16}
            padding="0px 0px 8px 0px"
            style={{ borderBottom: `1px solid ${theme.PILL_DARK_GRAY}` }}
          >
            <PhoenixMultiSelect
              isMulti={false}
              name="queue_sort"
              options={QUEUE_SORT_OPTIONS}
              placeholder="Sort"
              onChange={(e: OptionItem) => {
                setSortOptionDialQueue(String(e.value));
              }}
              value={{
                label: "Sort",
                value: sortOptionDialQueue,
              }}
              isClearable={false}
              marginBottom={false}
              error={false}
              ghostSelect
              ghostSelectAppThemeOverride={"Dark"}
              width={50}
              optionsContainerWidth={186}
              style={{ overflow: "hidden" }}
              lineHeight="10px"
              pointer
              menuInset="40px 0px 0px -130px"
              menuBorder
              removeMenuPadding
            />
            <QueueFilterButton
              onClick={() => {
                MixpanelActions.track("Queue Event", {
                  type: "filter",
                  tab: `${selectedTabDialQueue}`,
                  view: "Dashboard",
                });
                setShowFiltersModal(!showFiltersModal);
              }}
            >
              {!!filterNum && <NotificationsNumber />}
              <PhoenixIcon
                svg={filter}
                color={theme.icon.neutral.secondary}
                hoverColor={theme.icon.neutral.secondary}
                size={16}
                pointer
              />
            </QueueFilterButton>
          </FlexDiv>
        </QueueMenu>

        <IntentContainer id="upcoming-scroll-div">
          <InfiniteScroll
            dataLength={upcomingDials.length}
            next={() => handleShowMore()}
            hasMore={activeQueueHasMore}
            loader={<Loading />}
            scrollableTarget="upcoming-scroll-div"
          >
            {upcomingDials?.map((leadIntent: UpcomingLeadIntent, i: number) => (
              <CompactLeadCard
                index={i}
                intentData={leadIntent}
                titleIsBusinessName={titleIsBusinessName}
                key={leadIntent.id}
                selectedQueueIndex={selectedQueueIndex}
                setSelectedQueueIndex={setSelectedQueueIndex}
                selectedIntentId={selectedIntentId}
                maxCardContentHeight={maxCardContentHeight}
                disableAnimations={upcomingDials.length >= 40}
                customTaskAck={customTaskAck}
              />
            ))}
          </InfiniteScroll>
        </IntentContainer>
      </QueueContainer>
    </>
  );
};

const QueueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 472px;
  min-width: 472px;
  max-height: 100vh;
  min-height: 100vh;

  padding-top: 16px;

  overflow-y: auto;

  background: linear-gradient(180deg, #1a2a6b 0%, #3252cf 100%);
  background-size: 100% 300%;

  animation: queueStartup 0.5s ease-in-out forwards;

  @keyframes queueStartup {
    0% {
      width: 300px;
      min-width: 300px;
      opacity: 0.8;
      background-size: 100% 300%;
    }
    100% {
      width: 472px;
      min-width: 472px;
      opacity: 1;
      background-size: 100% 100%;
    }
  }
`;

const SearchLeads = styled(PhoenixInput)`
  margin: 0px 24px;

  animation: searchLeadStartup 0.5s ease-in-out forwards;

  @keyframes searchLeadStartup {
    0% {
      opacity: 0.8;
      margin-top: 16px;
    }
    100% {
      opacity: 1;
      margin-top: 0px;
    }
  }
`;

const QueueMenu = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 0px 24px;

  animation: ${theme.heavyPopup} 0.5s ease-in-out;
`;

const QueueMenuButton = styled.button<{ selected: boolean }>`
  width: 102px;
  height: 32px;

  padding: 8px 16px;

  color: ${({ selected }) => (selected ? theme.text.neutral.inverse : theme.text.neutral.secondary)};
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;

  border: none;
  border-bottom: ${({ selected }) => (selected ? "2px" : "1px")} solid
    ${({ selected }) => (selected ? theme.border.brand.primary : theme.PILL_DARK_GRAY)};

  background-color: transparent;

  :hover {
    color: ${theme.text.neutral.inverse};
    cursor: pointer;
  }

  transition: border 0.2s ease-in-out, color 0.1s ease-in-out;
`;

const QueueMenuFill = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.PILL_DARK_GRAY};
`;

const NotificationsNumber = styled.div`
  z-index: 2;
  position: absolute;
  top: 4px;
  right: 2px;

  width: 10px;
  height: 10px;

  cursor: pointer;
  border-radius: 50%;
  background: ${theme.ATTENTION700};
`;

const QueueFilterButton = styled.button`
  position: relative;

  width: 32px;
  height: 32px;

  padding: 8px;

  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: ${theme.buttonfill.brand.default};

  :hover {
    background-color: ${theme.buttonfill.brand.hover};
  }

  transition: background-color 0.1s ease-in-out;
`;

const IntentContainer = styled.div`
  width: 100%;

  padding: 0px 24px;

  overflow-y: auto;

  .infinite-scroll-component {
    overflow: hidden !important;
  }
`;

// TODO: cleanup the modal wrapper styled components. Currently taken from dash V1
const slideInAnimation = keyframes`
  0% { margin-left: 0px; width: 0px; }
  100% { margin-left: 0px; width: 552px; }
`;
const DarkDiv2 = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: -72px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;
const SlideInDiv = styled.div`
  position: absolute;
  left: 72px;
  width: calc(478px + 72px);
  min-height: 100vh;
  overflow: hidden;
  animation-name: ${slideInAnimation};
  animation-duration: 700ms;
  z-index: 8;
  background-color: ${theme.WHITE_COLOR};
`;
