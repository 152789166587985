import { LeadFilterOperator } from "src/context";
import { CommunicationStates } from "../utils/format";

export interface SelectedContactType {
  id?: string;
  lead_id: string;
  category?: string;
  phone_number?: string;
  local_phone_number?: string;
  country_code?: string;
  country?: string;
  email?: string;
  title?: string;
  type?: string;
  channel?: "Email" | "Phone" | "Conference";
  card_type?: string;
  conference_number?: string;
  has_conference_number?: boolean;
  alternate_phone_mapping_option?: number;
  alternate_phone_mapping_option_label?: string;
}

export interface AddSelectedContactType {
  lead_id: string;
  id?: string;
  country_code: string;
  country?: string;
}

export interface VisibleCustomFieldsType {
  id: string;
  key: string;
  value: string;
  visible: string;
  boo_value: string;
  num_value: string;
  type: string;
  lead_id: string;
  options: string;
  computed_value: string;
}
export interface AlternateContactType {
  id: string;
  title: string;
  channel: "Email" | "Phone" | "Conference";
  type: "Work" | "Home" | "Mobile" | "Other";
  value: string;
  country_code: string;
  contact_label: string;
  mapping_order: number;
}

export interface CustomFieldsType {
  id: string;
  key: string;
  computed_value: string;
  type: string;
  value: string;
  boo_value: boolean;
  num_value: number;
  list_value: string[];
  date_value: string;
  options: string[];
  allow_reps_to_edit?: boolean;
}

export interface NextScheduledEvent {
  id: string;
  type: string;
  lead_id: string;
  anytime_before: number;
  anytime_after: number;
  anytime_day: string;
  anytime_day_upperbound: string;
  anytime_tz: string;
  general_time_start_date: string;
  general__time_end_date: string;
  timezone: string;
}

interface ScheduleItem {
  id?: string;
  start_time?: Date;
  end_time?: Date;
}
export interface NextIntent {
  id?: string;
  event_type_label?: string;
  current_sequence_step?: string;
  dial_num_in_sales_cycle?: string;
  anytime_before?: Date;
  anytime_after?: Date;
  anytime_day?: Date;
  anytime_day_upperbound?: Date;
  anytime_tz?: Date;
  general_time_start_date?: Date;
  general_time_end_date?: Date;
  schedule_item?: ScheduleItem;
  replacement_lead_intent_id?: string | null | undefined;
  type?: string;
}

interface IRequiredLeadData extends Partial<CurrentLeadType> {
  id: string;
  primary_phone_number: string;
}

export interface INextDialOverrideData extends NextIntent {
  lead: IRequiredLeadData;
}

export interface CallNotesType {
  id: string;
  notes: string;
  created_at: string;
}

export interface OrgBrands {
  created_at: string;
  deleted_at: string;
  name: string;
  id: string;
  is_onboarding_complete: boolean;
  is_organization_tokens_complete: boolean;
  is_email_settings_complete: boolean;
  is_sms_settings_complete: boolean;
  is_domain_registration_complete: boolean;
}

export interface CurrentLeadType {
  id: string;
  lead_id: string;
  current_lead_type: string;
  next_scheduled_event_time: string;
  full_name: string;
  first_name?: string;
  last_name?: string;
  last_disposition_label?: string;
  revenue_value?: number;
  business_name: string;
  primary_phone_number: string;
  primary_phone_number_type: string;
  primary_phone_number_title: string;
  local_primary_phone_number: string;
  primary_phone_number_country_code: string;
  primary_email: string;
  primary_email_type: string;
  primary_email_title: string;
  industry: string;
  sub_industry: string;
  city: string;
  state: string;
  country: string;
  channel?: string;
  timezone_by_state: string;
  lead_source: string;
  favorited?: boolean;
  visible_custom_fields?: VisibleCustomFieldsType[];
  alternate_contacts?: AlternateContactType[];
  next_scheduled_event: NextScheduledEvent;
  next_intent_scheduled_or_unscheduled?: NextIntent;
  call_notes: CallNotesType[];
  lead_value: {
    value: number;
  };
  lead_ownership_status: string;
  rep_id: string;
  current_close_date: string;
  computed_mrr: number;
  sms_sub_status?: CommunicationStates;
  email_sub_status?: CommunicationStates;
  conference_number?: string;
  sequence_id?: string;
  sequence_step_id?: string;
  sequence_step: {
    actions?: {
      id: string;
      task: string;
      custom_task_note: string;
      scheduled_event_behavior: string;
    }[];
  };
  next_lead_intent?: ILeadIntentItem;
  pipeline_state_label?: string;
  address?: string;
  zip?: string;
  brand_name?: string;
  brand_id?: string;
  can_record_without_permission?: boolean;
}

export interface UserBrand {
  phone_number?: string;
  brand: {
    id: string;
    name: string;
  };
  can_record_without_permission?: boolean;
}

export interface LoggedInUserType {
  id?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  session_token?: string;
  profile_image?: string;
  role?: "AE" | "SDR" | "ADMIN" | "SM" | "PS";
  status?: string;
  organization_id?: string;
  team_id?: string;
  team_name?: string;
  manager_id?: string;
  manager?: {
    id: string;
    first_name?: string;
    last_name?: string;
  };
  user_brands?: UserBrand[];
  phone_number?: string;
  timezone?: string;
  joinMe_connected?: boolean;
  organization?: {
    id: string;
    name?: string;
    onboardComplete?: boolean;
    international?: boolean;
    mrr_label?: string;
    edge_servers?: string[];
    showLeadSourceToUser?: boolean;
    show_nocontact_button?: boolean;
    show_calendar_options?: boolean;
    auto_dial?: boolean;
    rep_level_call_transfer?: boolean;
    enable_rep_to_connect_customers?: boolean;
    allow_external_transfer?: boolean;
    country_options?: string[];
    show_historical_events?: boolean;
    specific_time_callbacks_slot_behaviour?: "BLOCK" | "ALLOW";
    title_is_business_name?: boolean;
    enable_csv_link?: boolean;
    enable_bad_number_feature?: boolean;
    allow_snooze_step_action?: boolean;
    Brands?: OrgBrands[];
    is_multi_brand?: boolean;
  };
  // OPS-6945 override the org setting for showing calendar integrations on FE to a specific user
  always_show_calendar_options?: boolean;
  created_at?: string;
  timekit_id?: string;
  timekit_type?: any;
  panda_doc_access?: boolean;
  dashboard_2_access?: boolean;
  visible_all_leads_sm?: boolean;
  // Transfer type moved to rep level
  transfer_type: "Manual" | "Auto";
  booking_transfer_type: "Manual" | "Auto";
  defaultLeadPageSort: string;
  visible_my_leads_columns_computed: string[];
  google_meet_enabled?: boolean;
  zoom_enabled?: boolean;
  checkUserCalendarStatus?: {
    google_status: string;
    zoom_status: string;
  };
  allow_external_transfer?: boolean;
}

export type ParentType =
  | "activeQueueDials"
  | "customQueueDials"
  | "favoriteDials"
  | "recommendedDials"
  | "leadDetails"
  | "currentLead"
  | "nextAction"
  | "recentDials"
  | "searchLead"
  | "eventDetails"
  | "suggestedAction"
  | "recentActivities";

export interface IScheduleItem {
  id: string;
  start_time?: Date;
  end_time?: Date;
  deleted_at?: string;
  __typename?: string;
}

export interface ILeadIntentItem {
  id: string;
  type: string;
  event_type_label?: string;
  current_sequence_step?: string;
  dial_num_in_sales_cycle?: number;
  anytime_day?: string | null;
  schedule_item?: IScheduleItem;
  replacement_lead_intent_id?: string | null;
}

export enum IIntegrations {
  // BE uses Sales F orce <-- not Salesforce we should change this but it will be a breaking change
  Salesforce = "SalesForce",
  HubSpot = "HubSpot",
  PandaDoc = "PandaDoc",
  Zoom = "Zoom",
  GoogleMeet = "Google",
  GoogleCalendar = "Google Calendar",
  Outlook = "Outlook",
  Gmail = "Gmail",
  JoinMe = "JoinMe",
}

export interface IntegrationItem {
  name: IIntegrations;
  url?: string;
  learnMoreUrl?: string;
  logo: any;
  description: string;
  disabled?: boolean;
  options?: IntegrationOptions;
}

export interface IntegrationOptions {
  fieldMapping: boolean;
  settings: boolean;
  manageTemplates: boolean;
  importMissingLeads: boolean;
  integrationReports: boolean;
}

export interface TokenForFE {
  fallback: string;
  token: string;
  token_formatted: string;
}

export interface AllTokens {
  [key: string]: TokenForFE[];
}
export interface SystemConfigNavShape {
  drawer_name: string;
  items: {
    title: string;
    path: string;
    tab_name: string;
    description: string;
  }[];
  path?: string;
  tab_name?: string;
  title?: string;
  description?: string;
}

export interface DispositionReason {
  id: string;
  reason: string;
  sub_reasons_options: string[]
  is_other: boolean;
  disposition_group?: string;
  organization_id?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

export interface IDispositionOption {
  id: string;
  label: string;
  person_spoke_to: string;
  phase: string;
  description?: string;
  next_phase?: string;
  resting_day?: number;
  disposition_reasons?: DispositionReason[];
  type?: string;
}

export interface IAvailableAction {
  id: string;
  label: string;
  event_type: string;
  description: string;
}

export interface ICustomField {
  key: string;
  type:
    | "Boolean"
    | "Date"
    | "DateTime"
    | "Dropdown"
    | "MultiDropdown"
    | "MultiText"
    | "Number"
    | "Percentage"
    | "Rate"
    | "Text"
    | "Reference";
  allow_reps_to_edit: boolean;
  num_value: number | null | undefined;
  boo_value: boolean | null | undefined;
  date_value: string | null | undefined;
  options: string[] | undefined;
  value: string | null | undefined;
  lead_id: string;
  visible: boolean;
  list_value?: string[];
}

export type CallType =
  | "Transfer Demo"
  | "Schedule Follow Up Demo/Schedule Callback"
  | "Schedule Follow Up Demo"
  | "Schedule Demo"
  | "Schedule Decision Call"
  | "Schedule Callback"
  | "Reschedule Demo/Schedule Callback"
  | "Reschedule Demo"
  | "Make Sale";

export type SortOption = "created_at" | "media_type" | "rep_name" | "business_name" | "call_result" | "duration";

export const SORTABLE_ALL_CALLS_TABLE_COLUMNS = [
  "created_at",
  "media_type",
  "rep_name",
  "business_name",
  "call_result",
  "duration",
];

export type MeetingType = "ZOOM" | "GOOGLE_MEET" | "PHONE";

export type OTFMeetingType = "Zoom" | "Google" | undefined;

export type CoachNote = {
  id?: string;
  text: string;
  start: string;
  end: string;
};

export interface TokenObject {
  [key: string]: {
    token: string;
    token_formatted: string;
    fallback: string;
  }[];
}

export interface AlternateContactsType {
  id: string;
  title: string;
  channel: "Phone" | "Email" | "Conference";
  type: string;
  value: string;
  country_code: string;
  contact_label: string;
  mapping_order: number;
}
