import styled from "styled-components";
import * as React from "react";
import { useContext, useState } from "react";
import Switch from "react-switch";
import { AppButton, AppText, Loading, AppErrorText, NewAppButton } from "../../UI";
import { theme } from "../../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { FieldArray, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import { FiArrowLeft } from "react-icons/fi";
import { formatUSD, formatTypeName, formatUSDRaw } from "../../../utils/format";
import { CallContext, CartContext } from "../../../context/";
import { FormAreaInputField, FormSelectField, InputField } from "../../Field";
import { contractOptions, terms } from "../../../static";
import { IoMdClose } from "react-icons/io";
import { FiTrash2 } from "react-icons/fi";
import { selectQuantityOptions } from "../../../utils/misc";
import { restAPI } from "../../../apollo";
import { BACKEND_URL } from "../../../utils/variables";
import { loggedInUser } from "../../../apollo/cache";
import { appToast, errorToast } from "../../../utils/toast";
import { CHECK_INTEGRATION_STATUS } from "..";
import { MRR_LABEL } from "../../../apollo/query";

interface DisappearingDivProps {
  setCallOptions: any;
  callOptions: any;
  lead_id: string;
  /**
   * id of the lead intent
   */
  intent_id?: string;
  dispositionType?: string;
  isPandaDocEnabled: boolean;
}

const FETCH_PRODUCTS = gql`
  query fetchProducts($lead_id: String) {
    fetchProducts(lead_id: $lead_id) {
      id
      product_title
      pricing
      available_concessions {
        id
        label
        amount
      }
      quantity_lock
      show_override_price_option
    }
  }
`;

const FETCH_ORG_OPTIONS = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      show_payment_terms_sales
      show_contract_duration_sales
      prepayment
      default_prepayment_option
      default_payment_terms_option
      default_contract_duration
      prepayment_options
      payment_terms_options
      contract_duration_options
      require_sale_notes
      show_sale_flow_custom_fields
      mrr_required_on_sale
      mrr
    }
  }
`;

const START_CLOSING_SCRIPT = gql`
  mutation startClosingScript {
    startClosingScript {
      id
    }
  }
`;

const START_SALE_SCRIPT = gql`
  mutation startSale(
    $lead_id: String!
    $contract_duration: String
    $person_spoke_to: CALLRESULT!
    $products: [ProductInputType!]!
  ) {
    startSale(
      lead_id: $lead_id
      contract_duration: $contract_duration
      person_spoke_to: $person_spoke_to
      products: $products
    )
  }
`;

const productsSchema = (mrrRequired: boolean) =>
  Yup.object().shape({
    lead_id: Yup.string().required(),
    person_spoke_to: Yup.string().required("This field is required."),
    products: Yup.array()
      .min(1)
      .of(
        Yup.object().shape({
          product_id: Yup.string().required("This field is required."),
          quantity: Yup.number()
            .required("This field is required.")
            .min(1, "Quantity must be at least 1")
            .max(999, "Quantity must be under 999")
            .typeError("Quantity must be a number")
            .integer("Quantity must be a whole number"),
          price_override: Yup.string().notRequired(),
          concession_id: Yup.string().notRequired(),
          use_custom_price: Yup.boolean().notRequired(),
        }),
      ),
    mrr: mrrRequired ? Yup.number().required("Lead MRR is required") : Yup.number().nullable().notRequired(),
  });

interface MyFormikProps {
  lead_id: string;
  intent_id?: string;
  person_spoke_to: string;
  products: any;
  sale_notes: string;
  contract_duration: string;
  payment_terms: string;
  prepayment?: number;
  mrr?: number;
}

const MakeSaleComponentV2: React.FC<DisappearingDivProps> = ({
  setCallOptions,
  callOptions,
  lead_id,
  intent_id,
  isPandaDocEnabled,
}) => {
  const { data: dataProducts, loading: loadingProducts, error: errorProducts } = useQuery(FETCH_PRODUCTS, {
    variables: {
      lead_id: lead_id || undefined,
    },
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: orgData, loading: orgLoading, error: orgError } = useQuery(FETCH_ORG_OPTIONS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: mrrLabel, loading: mrrLoading, error: mrrError } = useQuery(MRR_LABEL);

  const [startClosingScript, { loading, error }] = useMutation(START_CLOSING_SCRIPT, {
    async onCompleted() {
      if (isPandaDocEnabled) {
        // setCallOptions("create-pandadoc");
        callOptionStackPush("create-pandadoc");
      } else {
        // setCallOptions("closing-script");
        if (orgData?.fetchOrganization?.show_sale_flow_custom_fields) {
          callOptionStackPush("confirm-details-custom");
        } else {
          callOptionStackPush("closing-script");
        }
      }
    },
  });

  const [startSale, { loading: loadingSale, error: errorSale }] = useMutation(START_SALE_SCRIPT, {
    async onCompleted({ startSale }) {
      if (!startSale) {
        console.log("Error in startSale.");
        return;
      }
      console.log("startSale mutation ran successfully");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `startSale GraphQL Error: ${message}`,
      });
      console.log("Error in startSale: ", message);
    },
  });

  const { handleRevertClosingScript, personSpokeTo, callOptionStackPush } = useContext(CallContext);
  const { submitClosingScript, products, sale_notes } = useContext(CartContext);

  if (loadingProducts || orgLoading) return <Loading />;
  if (errorProducts) return <AppErrorText>Error fetching products!</AppErrorText>;

  const productTitleOptions = dataProducts.fetchProducts?.map((item: any) => {
    return {
      label: `${item?.product_title} ($${item?.pricing})`,
      value: item?.id,
      price: item?.price,
      quantity_lock: item?.quantity_lock,
    };
  });

  const returnItemPrice = (id: string) => {
    if (!dataProducts.fetchProducts.length) {
      return 0;
    }
    return dataProducts.fetchProducts.filter((item: any) => item.id === id)[0].pricing;
  };

  const returnItemDiscount = (discountID: string, item_id: string) => {
    if (discountID === "0") return 0;
    return dataProducts.fetchProducts
      .filter((item: any) => item.id === item_id)[0]
      .available_concessions.filter((item: any) => item.id === discountID)[0].amount;
  };

  const createDiscountOptions = (data: any, id: string) => {
    const product = data.filter((item: any) => item.id === id);
    if (!!!product[0]) {
      return [];
    }
    return product[0].available_concessions?.map((item: any) => {
      return {
        label: item.label,
        value: item.id,
      };
    });
  };

  const reduceTotalCost = (prev: any, curr: any) => {
    const current_price = curr.use_custom_price ? curr.price_override || 0 : curr.item_price ? curr.item_price : 0;
    const current_quantity = curr.quantity ? curr.quantity : 0;
    const current_discount = curr.discount_amount ? curr.discount_amount : 0;
    return prev + current_price * current_quantity - current_discount;
  };

  const calculateTotalCost = (products: any) => {
    return formatUSDRaw(Math.max(products.reduce(reduceTotalCost, 0), 0));
  };

  const resumeRecording = async () => {
    const api_call = await restAPI.request({ method: "POST", baseURL: `${BACKEND_URL}/twilio/resumeRecording` });
    return api_call;
  };

  return (
    <Sentry.ErrorBoundary fallback={"Error occurred in Make Sale component"}>
      <FormContainer>
        <Formik
          validateOnMount
          // Check context for values. This helps cart persist is rep needs to go back/forward. It's cleared when makeSale fires.
          initialValues={{
            lead_id: lead_id,
            intent_id: intent_id,
            person_spoke_to: personSpokeTo ? personSpokeTo : "",
            products: products
              ? products
              : [
                  {
                    product_id: undefined,
                    quantity: 1,
                    concession_id: undefined,
                    use_custom_price: false,
                    price_override: 0,
                  },
                ],
            contract_duration: orgData?.fetchOrganization?.default_contract_duration
              ? orgData?.fetchOrganization?.default_contract_duration
              : "0",
            prepayment: orgData?.fetchOrganization?.default_prepayment_option
              ? orgData?.fetchOrganization?.default_prepayment_option
              : undefined,
            payment_terms: orgData?.fetchOrganization?.default_payment_terms_option
              ? orgData?.fetchOrganization?.default_payment_terms_option
              : undefined,
            sale_notes: sale_notes ? sale_notes : "",
            mrr: undefined,
          }}
          validationSchema={productsSchema(orgData?.fetchOrganization?.mrr_required_on_sale)}
          initialStatus={{ generalError: "" }}
          onSubmit={async (values, { setStatus, setFieldError }) => {
            console.log("makeSale submit values: ", values);
            // check payment terms and contract duration
            if (
              orgData?.fetchOrganization?.show_payment_terms_sales &&
              (!values.payment_terms || values.payment_terms === "0")
            ) {
              setFieldError("payment_terms", "Payment terms are required");
              errorToast("Payment terms are required");
              return;
            }
            if (
              orgData?.fetchOrganization?.show_contract_duration_sales &&
              (!values.contract_duration || values.contract_duration === "0")
            ) {
              setFieldError("contract_duration", "Contract duration is required");
              errorToast("Contract duration is required");
              return;
            }
            if (orgData?.fetchOrganization?.require_sale_notes && !values.sale_notes) {
              setFieldError("sale_notes", "Sale notes are required");
              errorToast("Sale notes are required");
              return;
            }
            startSale({
              variables: {
                lead_id: values.lead_id,
                contract_duration: values.contract_duration,
                person_spoke_to: values.person_spoke_to,
                products: values.products?.map((item: any) => ({
                  quantity: Number(item.quantity),
                  product_id: item.product_id,
                  concession_id: item.concession_id,
                  price_override: item.use_custom_price ? Number(item.price_override) : undefined,
                })),
              },
            });

            setStatus({ generalError: "" });
            if (values.products.length) {
              setStatus({ generalError: "You must select at least one product" });
            }
            console.log("values on makeSale: ", values);
            submitClosingScript(values);
            handleRevertClosingScript(true, true);
            resumeRecording();
            startClosingScript();
            setStatus({ generalError: "" });
          }}
        >
          {({
            submitForm,
            values,
            errors,
            setFieldValue,
            isSubmitting,
            isValid,
            dirty,
            handleChange,
            status,
          }: FormikProps<MyFormikProps>) => {
            return (
              <>
                <HeaderContainer>
                  <AppText fontSize={16}>Make Sale</AppText>
                </HeaderContainer>
                <StopRecordingWarning>
                  Make sure to stop recording if the lead is providing payment details
                </StopRecordingWarning>
                <FormContainerDiv>
                  <DropdownDiv>
                    {orgData?.fetchOrganization?.show_contract_duration_sales && (
                      <div>
                        <PopupInputLabel>
                          Contract Duration<span style={{ color: "red" }}>*</span>
                        </PopupInputLabel>
                        <FieldSelect
                          fullWidth
                          id="contract-duration"
                          name="contract_duration"
                          // placeholder="Contract Duration"
                          // allowSelectPlaceholder
                          options={orgData?.fetchOrganization?.contract_duration_options || []}
                          customArrowLocation={18}
                        />
                      </div>
                    )}
                    {orgData?.fetchOrganization?.show_payment_terms_sales && (
                      <div>
                        <PopupInputLabel>
                          Payment Terms<span style={{ color: "red" }}>*</span>
                        </PopupInputLabel>
                        <FieldSelect
                          fullWidth
                          id="payment_terms"
                          name="payment_terms"
                          // placeholder="Payment Terms"
                          // allowSelectPlaceholder
                          options={orgData?.fetchOrganization?.payment_terms_options || []}
                          customArrowLocation={18}
                        />
                      </div>
                    )}
                    {orgData?.fetchOrganization?.prepayment && (
                      <div>
                        <PopupInputLabel>
                          Prepayment<span style={{ color: "red" }}>*</span>
                        </PopupInputLabel>
                        <FieldSelect
                          fullWidth
                          id="prepayment"
                          name="prepayment"
                          options={orgData?.fetchOrganization?.prepayment_options || []}
                          customArrowLocation={18}
                        />
                      </div>
                    )}
                  </DropdownDiv>
                  {/* Field Array handles adding/removing products section*/}
                  <FieldArray name="products">
                    {({ remove, push }) => (
                      <>
                        <AddProductDiv>
                          <AppText>Products</AppText>
                          <NewAppButton
                            size={"sm"}
                            variant={"secondary"}
                            onClick={() =>
                              push({
                                product_id: undefined,
                                quantity: 1,
                                concession_id: undefined,
                              })
                            }
                          >
                            + Add Product
                          </NewAppButton>
                        </AddProductDiv>
                        <ProductListDiv>
                          {values.products &&
                            values.products.length > 0 &&
                            values.products?.map((product: any, index: any) => (
                              <ProductItemContainer key={index}>
                                <ProductContainer>
                                  <ProductSelectDiv>
                                    <PopupInputLabel>
                                      Label<span style={{ color: "red" }}>*</span>
                                    </PopupInputLabel>
                                    <FieldSelect
                                      fullWidth
                                      name={`products[${index}].product_id`}
                                      placeholder="Select Product"
                                      options={productTitleOptions}
                                      textAlign={"left"}
                                      customArrowLocation={17}
                                      // When product changes the discount and quantity is set back to zero.
                                      onChange={(e) => {
                                        handleChange(e);
                                        setFieldValue(`products[${index}.concession_id]`, "0");
                                        setFieldValue(`products[${index}].discount_amount`, 0);
                                        setFieldValue(`products[${index}.item_price]`, returnItemPrice(e.target.value));
                                        setFieldValue(`products[${index}.use_custom_price]`, false);
                                        setFieldValue(`products[${index}.price_override]`, 0);

                                        const curProduct = dataProducts?.fetchProducts?.find(
                                          (product: any) => product?.id === e.target.value,
                                        );
                                        if (!!curProduct?.quantity_lock) {
                                          setFieldValue(`products[${index}.quantity]`, curProduct?.quantity_lock);
                                        } else {
                                          setFieldValue(`products[${index}.quantity]`, 1);
                                        }
                                      }}
                                    />
                                    <QuantityDiscountDiv>
                                      <div>
                                        <PopupInputLabel>
                                          Quantity<span style={{ color: "red" }}>*</span>
                                        </PopupInputLabel>
                                        <InputField
                                          style={{ flexGrow: 1 }}
                                          width={175}
                                          name={`products[${index}].quantity`}
                                          placeholder="Qty."
                                          fullWidth={true}
                                          textAlign={"left"}
                                          disabled={
                                            values.products[index]?.product_id &&
                                            dataProducts?.fetchProducts?.find(
                                              (product: any) => product?.id === values.products[index]?.product_id,
                                            )?.quantity_lock === null
                                              ? false
                                              : true
                                          }
                                          type="number"
                                        />
                                      </div>
                                      <div>
                                        <PopupInputLabel>Discount</PopupInputLabel>
                                        <FieldSelect
                                          style={{ flexGrow: 1, marginTop: "19px" }}
                                          name={`products[${index}.concession_id`}
                                          placeholder="No Discount"
                                          options={createDiscountOptions(
                                            dataProducts.fetchProducts,
                                            values.products[index].product_id,
                                          )}
                                          width={175}
                                          disabled={values.products[index].product_id ? false : true}
                                          // fullWidth={true}
                                          textAlign={"left"}
                                          customArrowLocation={17}
                                          allowSelectPlaceholder
                                          onChange={(e) => {
                                            handleChange(e);
                                            setFieldValue(
                                              `products[${index}].discount_amount`,
                                              returnItemDiscount(e.target.value, values.products[index].product_id),
                                            );
                                          }}
                                        />
                                      </div>
                                    </QuantityDiscountDiv>
                                    {dataProducts?.fetchProducts?.find(
                                      (product: any) => product?.id === values.products[index]?.product_id,
                                    )?.show_override_price_option &&
                                      (!!product?.use_custom_price ? (
                                        <CustomPriceInputDiv>
                                          <PopupInputLabel>
                                            Override Price<span style={{ color: "red" }}>*</span>
                                          </PopupInputLabel>

                                          <InputField
                                            name={`products[${index}].price_override`}
                                            // placeholder="Custom Price"
                                            noTopMargin
                                            displayNoContext
                                            disabled={!product.use_custom_price}
                                            width={175}
                                            type="number"
                                          />
                                        </CustomPriceInputDiv>
                                      ) : (
                                        <CustomPriceInputDiv />
                                      ))}
                                    <Divider />
                                    <Horizontal>
                                      {dataProducts?.fetchProducts?.find(
                                        (product: any) => product?.id === values.products[index]?.product_id,
                                      )?.show_override_price_option && (
                                        <Horizontal>
                                          <Switch
                                            onChange={(checked: any) =>
                                              setFieldValue(`products[${index}].use_custom_price`, checked)
                                            }
                                            checked={product.use_custom_price}
                                            onColor={theme.PRIMARY500}
                                            offColor={theme.NEUTRAL200}
                                            height={32}
                                            width={54}
                                            handleDiameter={24}
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                          />
                                          <CustomText>Override Product Price?</CustomText>
                                        </Horizontal>
                                      )}
                                      <CloseButton onClick={() => remove(index)}>
                                        <FiTrash2 size={22} color={theme.PRIMARY500} />
                                      </CloseButton>
                                    </Horizontal>
                                  </ProductSelectDiv>
                                </ProductContainer>
                              </ProductItemContainer>
                            ))}
                        </ProductListDiv>
                      </>
                    )}
                  </FieldArray>
                  {/* Reducer for total. */}
                  <TotalCostDiv>
                    <div>Total Cost:</div>
                    <div style={{ marginRight: "10px" }}>{calculateTotalCost(values.products)}</div>{" "}
                  </TotalCostDiv>
                  {orgData?.fetchOrganization?.mrr_required_on_sale && (
                    <>
                      <PopupInputLabel>
                        {mrrLabel?.getMrrLabel}
                        <span style={{ color: "red" }}>*</span>
                      </PopupInputLabel>
                      <InputField name="mrr" type="number" noTopMargin />
                    </>
                  )}
                  {(loggedInUser().organization_id === "1236a64a-c7cc-48e5-9282-aa049373e1b9" ||
                    process.env.REACT_APP_ENVIRONMENT === "Development") && (
                    <ExternalPaymentDiv>
                      <a
                        style={{ border: "none", textDecoration: "none" }}
                        href="https://www.surefireplatform.com/paynow"
                        target="_blank"
                      >
                        <ExternalPaymentButton>Payment App</ExternalPaymentButton>
                      </a>
                    </ExternalPaymentDiv>
                  )}
                  <MakeSaleText>Sale Notes</MakeSaleText>
                  <FormAreaInputField fontSize={13} name="sale_notes" />
                </FormContainerDiv>
                <NewAppButton
                  disabled={!!loading || !!loadingSale || !isValid}
                  variant={"primary"}
                  size={"lg"}
                  onClick={submitForm}
                >
                  {isPandaDocEnabled ? "Continue" : "Begin Closing Script"}
                </NewAppButton>
                {status && status.generalError && <AppErrorText>{status.generalError}</AppErrorText>}
                <AppText>{`Lead ID: ${lead_id}`}</AppText>
              </>
            );
          }}
        </Formik>
      </FormContainer>
    </Sentry.ErrorBoundary>
  );
};

const FormContainer = styled.div`
  text-align: center;
`;

const DropdownDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: solid 1px ${theme.NEUTRAL200};
  margin-bottom: 16px;
`;

const ExternalPaymentButton = styled.div`
  height: 40px;
  border-radius: 1.8px;
  background-color: ${theme.BLACK_COLOR};
  font-size: 11px;
  color: ${theme.WHITE_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  padding: 0px 14px;
`;
const ExternalPaymentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 26px;
`;

const FieldSelect = styled(FormSelectField)`
  /* height: 25px; */
  font-size: 12px;
  border-radius: 4.4px;
  text-align: center;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin-bottom: 15px;
  margin-top: 40px;
`;

const MakeSaleText = styled(AppText)`
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
`;

const PopupInputLabel = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
`;

const CustomText = styled(AppText)`
  margin-left: 16px;
  font-size: 10px;
  font-weight: bold;
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border: solid 1px ${theme.NEUTRAL200};
  padding: 16px;
  /* padding-bottom: 20px; */
`;

const ProductItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

interface TransferDemoSecondButtonProps {
  transferDemoOptions: string;
  option: string;
}

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.div`
  /* position: relative;
  left: 50%;
  top: -15px; */
  height: 28px;
  width: 28px;
  background: ${theme.NEUTRAL100};
  border-radius: 50%;
  cursor: pointer;
  padding: 3px;
`;

const ProductSelectDiv = styled.div`
  width: 100%;
`;

const ProductListDiv = styled.div`
  min-height: 200px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const QuantityDiscountDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const FormContainerDiv = styled.div`
  height: 608px;
  overflow-y: scroll;
  margin-bottom: 48px;
`;
const TopContainer = styled.div`
  max-height: 228px;
`;
const TotalCostDiv = styled.div`
  height: 84px;
  font-size: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px ${theme.NEUTRAL200};
  margin-bottom: 16px;
`;

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const SpacerDiv = styled.div`
  width: 25px;
  height: 25px;
`;

const StopRecordingWarning = styled.div`
  width: 100%;
  color: ${theme.ATTENTION700};
  font-size: 11px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 21px;
`;

const CustomPriceInputDiv = styled.div`
  height: 65px;
`;

const AddProductDiv = styled.div`
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const Divider = styled.div`
  width: 100%;
  background-color: ${theme.NEUTRAL200};
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export { MakeSaleComponentV2 };
