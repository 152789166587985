import React from "react";
import { components } from "react-select";
import { ProfileImageWithText } from "src/Components/Segments/SettingSegments/ProfileImageWithText";
import { FlexDiv } from "src/Components/UI";
import { PhoenixCheckbox, PhoenixMultiSelect } from "src/Components/UI/Phoenix";

import { OptionItem } from "src/types";
import { CustomMultiValue } from "./shared";

type RepFilterProps = {
  onChange: (value: OptionItem[]) => void;
  options: OptionItem[];
  value: OptionItem[];
};

const Option = (props: any) => {
  const [first_name, last_name] = props?.data?.label?.split(" ");

  return (
    <components.Option {...props}>
      <FlexDiv align="center" gap={8}>
        <PhoenixCheckbox checked={props.isSelected} />
        <ProfileImageWithText
          first_name={first_name}
          height={24}
          last_name={last_name}
          profile_image={props?.data?.profile_image}
          width={24}
          textSize={8}
          hideTooltip
          border={false}
        />
        {props?.data?.label}
      </FlexDiv>
    </components.Option>
  );
};

const MultiValue = (props: any) => {
  return <CustomMultiValue {...props} containerClassName="rep-vc" />;
};

const ValueContainer = (props: any) => {
  return <components.ValueContainer className="rep-vc" {...props} />;
};

const RepFilter = ({ options, value, onChange }: RepFilterProps) => {
  return (
    <PhoenixMultiSelect
      closeMenuOnSelect={false}
      components={{ Option, MultiValue, ValueContainer }}
      hideSelectedOptions={false}
      isClearable={false}
      marginBottom={false}
      maxHeight={40}
      name="Reps"
      onChange={(e: OptionItem[]) => onChange(e)}
      options={options}
      placeholder="Filter by Rep"
      titleText="Reps"
      optionStyles={{ height: "auto", backgroundColor: "transparent", color: "black" }}
      value={value}
      width={320}
    />
  );
};

export default RepFilter;
