import React from "react";
import moment from "moment";
import { cloudinary } from "src/services/cloudinary";

import { LiveUserStatusItem } from "src/context";
import { DEFAULT_CLOUDINARY_IMAGE } from "src/utils/variables";
import { AppText, FlexDiv } from "src/Components/UI";
import { call_type_other } from "src/images/NewDesign";
import { MixpanelActions } from "src/services/mixpanel";

import { TimeInterval } from "../TimeInterval";
import styled from "styled-components";
import { theme } from "src/utils/theme";
import { NavLink } from "react-router-dom";
import { AdvancedImage } from "@cloudinary/react";
import { CALL_TYPE_COLOR_MAP, CALL_TYPE_ICON_MAP } from "src/utils/variables";
import { PhoenixStyledTooltip } from "src/Components/Dumb/PhoenixStyledTooltip";

export const CallerCardV2 = ({ item, isCurrentCall }: { item: LiveUserStatusItem; isCurrentCall: boolean }) => {
  const callType = item.status === "IDLE" ? "IDLE" : item.status === "CALL" ? item.activity_type : "OFFLINE";

  const callTypeBackground = CALL_TYPE_COLOR_MAP[callType] ?? "#C8E5FF";
  const callTypeIcon = CALL_TYPE_ICON_MAP[callType] ?? call_type_other;

  const updatedTime = moment(item?.status_last_updated_at);

  const firstName = item?.user?.first_name || "";
  const lastName = item?.user?.last_name || "";

  const profileImage = cloudinary.image(item?.user?.profile_image ? item.user.profile_image : DEFAULT_CLOUDINARY_IMAGE);
  //conditional instead of isActive prop on NavLink since pointerEvents: none would only disable link through click only

  return (
    <CallerCardWrapper
      isCurrentCall={isCurrentCall}
      to={`/call-report/${item.conference_id}`}
      onClick={() => {
        if (item.conference_id) {
          MixpanelActions.track("Call Report Viewed", {
            report_id: item.conference_id,
            trigger_source: "Active Calls",
          });
        }
      }}
    >
      <PhoenixStyledTooltip id="caller-card-tooltip" />
      <NameSection>
        <ImageWrapper>
          <AdvancedImageStyle style={{ filter: `blur(0)` }} cldImg={profileImage} />
        </ImageWrapper>
        <AppText
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "100%",
          }}
          fontSize={14}
          fontWeight={500}
          textAlign="center"
        >
          {`${firstName} ${lastName.length > 5 ? lastName[0] + `.` : lastName}`}
        </AppText>
      </NameSection>

      <CallDurationGroup>
        <CallDurationIconWrapper style={{ backgroundColor: callTypeBackground }}>
          <img src={callTypeIcon} alt={callType} data-for="caller-card-tooltip" data-tip={callType} />
        </CallDurationIconWrapper>
        <AppText fontSize={14} fontWeight={400}>
          <TimeInterval time={updatedTime} />
        </AppText>
      </CallDurationGroup>
    </CallerCardWrapper>
  );
};

const NameSection = styled(FlexDiv)`
  direction: row;
  gap: 8px;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid ${theme.border.neutral.secondary};
  width: 100%;
`;

const CallerCardWrapper = styled(NavLink)<{ isCurrentCall: boolean }>`
  align-items: center;
  background: ${theme.text.neutral.inverse};
  border-radius: 8px;
  border: ${({ isCurrentCall }) =>
    `1px solid ${isCurrentCall ? theme.text.brand.primary : theme.border.neutral.secondary}`};
  display: flex;
  flex-direction: column;
  font-size: 12px;
  min-width: 220px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:not(:last-child) {
    margin-right: 24px;
  }

  &:hover {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  }
`;

const CallDurationGroup = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

const CallDurationIconWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 32px;
  justify-content: center;
  width: 40px;
  flex-shrink: 0;
  border-bottom-left-radius: 8px;
`;

const ImageWrapper = styled.div`
  height: 24px;
  width: 24px;
  position: relative;
`;

const AdvancedImageStyle = styled(AdvancedImage)`
  border-radius: 50%;
  height: 24px;
  object-fit: cover;
  width: 24px;
`;
