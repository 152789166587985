import React, { useState, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { theme } from "src/utils/theme";
import { AppText, FlexDiv } from "../../UI";
import { useQuery, gql } from "@apollo/client";
import { loggedInUser } from "src/apollo/cache";

import { LiveUserStatusContext } from "src/context";
import { PhoenixIcon, PhoenixMultiSelect } from "../../UI/Phoenix";
import { chevron_down, chevron_up, phone_call, refresh } from "src/images/NewDesign";
import { OptionItem } from "src/types";
import { CallerCard } from "./CallerCard";
import CallTypeFilter from "./CallTypeFilter";
import RepFilter from "./RepFilter";
import TeamFilter from "./TeamFilter";

const FETCH_TEAMS_REPS_FROM_ORG = gql`
  query fetchOrganization {
    fetchOrganization {
      Reps {
        id
        first_name
        last_name
        profile_image
      }
      Teams {
        id
        name
      }
    }
  }
`;

const TRANSITION_TIME = 0.25;

export const extractConferenceId = (pathname: string): string | undefined => {
  const match = pathname.match(/^\/call-report\/([^\/]+)$/);
  if (!match) return undefined;

  const conferenceId = match[1];
  // Return undefined for null/empty values
  return conferenceId && conferenceId !== "null" ? conferenceId : undefined;
};

const CallMonitorV2Component: React.FC = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const currentConferenceId = extractConferenceId(pathname);

  const [showCallMonitor, setShowCallMonitor] = useState(false);

  const { callMonitorFilter, setCallMonitorFilter, liveUserStatus } = useContext(LiveUserStatusContext);

  const { data: dataOrg } = useQuery(FETCH_TEAMS_REPS_FROM_ORG, {
    variables: {
      org_id: loggedInUser().organization_id,
    },
    fetchPolicy: "network-only",
  });

  const repList = useMemo(
    () =>
      dataOrg?.fetchOrganization?.Reps?.map((rep: any) => {
        return { label: `${rep.first_name} ${rep.last_name}`, value: rep.id, profile_image: rep.profile_image };
      }).sort((a: any, b: any) => a?.label.localeCompare(b?.label)) ?? [],
    [dataOrg],
  );

  const teamList = useMemo(
    () =>
      dataOrg?.fetchOrganization?.Teams?.map((team: any) => {
        return { label: team?.name, value: team?.id };
      }).sort((a: any, b: any) => a?.label.localeCompare(b?.label)) ?? [],
    [dataOrg],
  );

  const handleChange = (type: string, values: any[]) => {
    setCallMonitorFilter({ ...callMonitorFilter, [type]: values });
  };

  const toggleCallMonitor = () => {
    setShowCallMonitor(!showCallMonitor);
  };

  const resetFilters = () => {
    setCallMonitorFilter({
      statuses: [],
      team_ids: [],
      user_ids: [],
    });
  };

  const canResetFilters =
    callMonitorFilter.statuses.length > 0 ||
    callMonitorFilter.team_ids.length > 0 ||
    callMonitorFilter.user_ids.length > 0;

  return (
    <div style={{ position: "relative" }}>
      <ActiveCallToggle onClick={toggleCallMonitor} isModalOpen={showCallMonitor}>
        <AppText
          fontSize={10}
          fontWeight={600}
          variant="white"
          style={{ letterSpacing: "1px", textTransform: "uppercase", marginTop: "1px" }}
        >
          Rep Call Status
        </AppText>
        {showCallMonitor ? (
          <PhoenixIcon svg={chevron_down} variant="white" pointer />
        ) : (
          <PhoenixIcon svg={chevron_up} variant="white" pointer />
        )}
      </ActiveCallToggle>
      <FullBottomSection isModalOpen={showCallMonitor}>
        <CallMonitorWrapper isModalOpen={showCallMonitor}>
          <CallMonitorDiv isModalOpen={showCallMonitor}>
            <DropdownFiltersDiv>
              <CallTypeFilter
                value={callMonitorFilter.statuses}
                handleChange={(key: string, value: any) => handleChange(key, value)}
              />

              <TeamFilter
                onChange={(e: OptionItem[]) => handleChange("team_ids", e)}
                options={teamList}
                value={callMonitorFilter.team_ids}
              />

              <RepFilter
                onChange={(e: OptionItem[]) => handleChange("user_ids", e)}
                options={repList}
                value={callMonitorFilter.user_ids}
              />

              <FlexDiv
                align="center"
                gap={4}
                onClick={resetFilters}
                style={{
                  cursor: canResetFilters ? "pointer" : "not-allowed",
                  marginTop: "16px",
                  opacity: canResetFilters ? 1 : 0.5,
                }}
              >
                <PhoenixIcon pointer={true} size={12} svg={refresh} />
                <AppText color={theme.text.brand.primary} fontSize={10} fontWeight={600} lineHeight={14}>
                  Reset Filters
                </AppText>
              </FlexDiv>
            </DropdownFiltersDiv>

            <CallMonitorScrollDiv id="call-monitor-div">
              {liveUserStatus?.length ? (
                <HorizontalScrollDiv>
                  {liveUserStatus.map((item) => {
                    return (
                      <CallerCard
                        key={item.user_id}
                        item={item}
                        isCurrentCall={item.conference_id === currentConferenceId}
                      />
                    );
                  })}
                </HorizontalScrollDiv>
              ) : (
                <FlexDiv direction="column" gap={24} justify="center" align="center" style={{ height: "160px" }}>
                  <PhoenixIcon svg={phone_call} size={40} variant="neutral" />

                  <AppText fontSize={14} color={theme.NEUTRAL400}>
                    No calls match your filters.
                  </AppText>
                </FlexDiv>
              )}
            </CallMonitorScrollDiv>
          </CallMonitorDiv>
        </CallMonitorWrapper>
      </FullBottomSection>
    </div>
  );
};

const FullBottomSection = styled.div<{ isModalOpen: boolean }>`
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: transform ${TRANSITION_TIME}s ease-in-out;
  transform: ${({ isModalOpen }) => (isModalOpen ? "translateY(0)" : "translateY(0%)")};
  z-index: 1000;
  overflow: hidden;
`;

const HorizontalScrollDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: overlay;
  padding: 4px 0;
`;

const CallMonitorWrapper = styled.div<{ isModalOpen: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: ${({ isModalOpen }) => (isModalOpen ? "1000px" : "0")};
  overflow: hidden;
  transition: max-height ${TRANSITION_TIME}s ease-in-out;
`;

const ActiveCallToggle = styled.button<{ isModalOpen: boolean }>`
  position: absolute;
  top: -48px;
  right: 24px;
  align-items: center;
  background-color: ${theme.PRIMARY500};
  border-radius: 8px 8px 0px 0px;
  border: 1px solid ${theme.PRIMARY500};
  color: ${theme.PRIMARY200};
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: space-between;
  padding: 16px;
  width: 180px;

  :hover {
    background-color: ${theme.PRIMARY600};
  }
  :focus {
    outline: 1px solid ${theme.PRIMARY500};
    outline-offset: 1px;
  }
`;

const CallMonitorDiv = styled.div<{ isModalOpen: boolean }>`
  background-color: ${theme.PRIMARY100};
  border-top: 1px solid ${theme.NEUTRAL200};
  display: flex;
  flex-direction: column;
  gap: 36px;
  height: max-content;
  padding: 24px;
  position: relative;
`;

const DropdownFiltersDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
`;

const CallMonitorScrollDiv = styled.div`
  height: 100%;
  overflow: overlay;
`;

export { CallMonitorV2Component };
