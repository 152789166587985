import React, { useContext, useEffect, useRef, useState } from "react";
import { theme } from "../../../../utils/theme";
import { AppText, FlexDiv, SkeletonBlock } from "../../../UI";

import { gql, useMutation, useQuery } from "@apollo/client";
import { useFlags } from "launchdarkly-react-client-sdk";
import { loggedInUser } from "src/apollo/cache";
import { PhoenixStyledTooltip } from "src/Components/Dumb/PhoenixStyledTooltip";
import { ModalContext } from "src/context";
import { CallContext, OTFVideoMeeting } from "src/context/CallContext";
import {
  caret_down,
  copy,
  google_meet_logo,
  mail,
  message_square,
  trash,
  video,
  zoom_logo,
} from "src/images/NewDesign";
import { appToast, errorToast, successToast } from "src/utils/toast";
import styled from "styled-components";
import { useClickOutside } from "../../../../utils/hooks";
import { PhoenixAppButton, PhoenixIcon } from "../../../UI/Phoenix";
import ReactTooltip from "react-tooltip";
import useCreateOTFMeeting from "src/query-hooks/useCreateOTFMeeting";
import useFetchCachedMeeting from "src/query-hooks/useFetchCachedMeeting";
import useDeleteOTFMeeting from "src/query-hooks/useDeleteOTFMeeting";

const SEND_SMS = gql`
  mutation SendSMS(
    $lead_id: String!
    $text: String!
    $phone_number: String!
    $template_id: String
    $step_action_id: String
  ) {
    sendSMS(
      lead_id: $lead_id
      text: $text
      phone_number: $phone_number
      template_id: $template_id
      step_action_id: $step_action_id
    ) {
      id
    }
  }
`;

const SEND_EMAIL_TO_LEAD = gql`
  mutation sendEmailToLead(
    $lead_id: String!
    $subject: String
    $html: String!
    $email: String
    $template_id: String
    $email_thread_id: String
    $step_action_id: String
  ) {
    sendEmailToLead(
      lead_id: $lead_id
      subject: $subject
      html: $html
      email: $email
      template_id: $template_id
      email_thread_id: $email_thread_id
      step_action_id: $step_action_id
    ) {
      id
    }
  }
`;
interface FetchLeadResponse {
  fetchLead: {
    id: string;
    primary_phone_number: string;
    primary_email: string;
    brand_id: string;
  };
}

const FETCH_LEAD = gql`
  query fetchLead($id: String!) {
    fetchLead(id: $id) {
      id
      primary_phone_number
      primary_email
      brand_id
    }
  }
`;

interface OTFMeetingSelectProps {
  leadId: string;
  disabled?: boolean;
  height?: number;
  width?: number;
  tooltipText?: string;
}

type OTFMeetingType = "Zoom" | "Google" | undefined;

const OTFMeetingMenu = ({
  setShowOptionsMenu,
  handleMeetingTypeChange,
}: {
  setShowOptionsMenu: (showOptionsMenu: boolean) => void;
  handleMeetingTypeChange: (value: OTFMeetingType) => void;
}) => {
  const containerRef = useRef(null);

  useClickOutside(containerRef, () => setShowOptionsMenu(false));

  const { zoomIntegration, googleMeetIntegration } = useFlags();

  const zoomIsEnabledOnUser = loggedInUser().checkUserCalendarStatus?.zoom_status === "active";
  const googleMeetIsEnabledOnUser = loggedInUser().checkUserCalendarStatus?.google_status === "active";

  const hasZoomMeeting = zoomIntegration && zoomIsEnabledOnUser;
  const hasGoogleMeeting = googleMeetIntegration && googleMeetIsEnabledOnUser;

  return (
    <OptionsDiv ref={containerRef}>
      {hasZoomMeeting && (
        <Option
          onClick={() => handleMeetingTypeChange("Zoom")}
          width={"180px"}
          gap={4}
          borderRadius={hasGoogleMeeting ? "4px 4px 0px 0px" : undefined}
        >
          <img src={zoom_logo} alt="Zoom" height="21px" width="auto" />
          Create Meeting
        </Option>
      )}
      {hasGoogleMeeting && (
        <Option
          onClick={() => handleMeetingTypeChange("Google")}
          width={"180px"}
          gap={4}
          borderRadius={hasZoomMeeting ? "0px 0px 4px 4px" : undefined}
        >
          <img src={google_meet_logo} alt="Google" height="21px" width="auto" />
          Create Meeting
        </Option>
      )}
    </OptionsDiv>
  );
};

const OTFMeetingMenuLiveMeeting = ({
  setShowOptionsMenu,
  createdOTFVideoMeetingType,
  existingLeadOTFVideoMeeting,
  leadId,
}: {
  setShowOptionsMenu: (showOptionsMenu: boolean) => void;
  createdOTFVideoMeetingType: OTFMeetingType;
  existingLeadOTFVideoMeeting: OTFVideoMeeting | null | undefined;
  leadId: string;
}) => {
  const { onDeleteMeeting } = useDeleteOTFMeeting();

  const { goToCall, removeOTFVideoMeeting } = useContext(CallContext);
  const {
    setJoinGoogleMeetModalData,
    setShowJoinGoogleMeetModal,
    setJoinZoomModalData,
    setShowJoinZoomModal,
  } = useContext(ModalContext);

  const { data: leadData, loading: leadLoading, error: leadError } = useQuery<FetchLeadResponse>(FETCH_LEAD, {
    variables: {
      id: leadId,
    },
    fetchPolicy: "cache-and-network",
  });

  const [sendSMS, { loading: sendSMSLoading, error: sendSMSError }] = useMutation(SEND_SMS, {
    onCompleted: (data) => {
      successToast("SMS sent successfully.");
    },
    onError: (error) => {
      errorToast(error.message);
    },
  });

  const [sendEmailToLead, { loading: sendEmailToLeadLoading, error: sendEmailToLeadError }] = useMutation(
    SEND_EMAIL_TO_LEAD,
    {
      onCompleted: (data) => {
        successToast("Email sent successfully.");
      },
      onError: (error) => {
        errorToast(error.message);
      },
    },
  );

  const handleJoinZoomMeeting = ({
    url,
    zoom_meeting_sellfire_id,
    leadId,
    meeting_id,
  }: {
    url?: string;
    zoom_meeting_sellfire_id?: string;
    leadId?: string;
    meeting_id: string;
  }) => {
    if (!url) {
      appToast("Unable to find a valid Zoom URL.");
      return;
    }

    console.log("meeting_id", meeting_id);

    setJoinZoomModalData({
      meetingLink: url,
      dialInToMeeting: ({ newPhoneNumber, newParticipantId }: { newPhoneNumber: string; newParticipantId: string }) => {
        appToast("Dialing into Zoom...");
        goToCall({
          lead_id: leadId,
          lead_brand_id: leadData?.fetchLead?.brand_id ?? "",
          phoneNumber: newPhoneNumber,
          presetDigitsToSend: [`${meeting_id ?? ""}`, "#", `${newParticipantId ?? ""}`, "#"],
          zoom_meeting_sellfire_id,
        });
      },
    });
    setShowJoinZoomModal(true);
  };

  const handleJoinGoogleMeeting = ({
    url,
    sellfire_meeting_id,
    leadId,
  }: {
    url?: string;
    sellfire_meeting_id?: string;
    leadId: string;
  }) => {
    if (!url) {
      appToast("Unable to find a valid Google Meet URL.");
      return;
    }

    setJoinGoogleMeetModalData({
      meetingLink: url,
      dialInToMeeting: ({ newPhoneNumber, newPin }: { newPhoneNumber: string; newPin: string }) => {
        appToast("Dialing into Google Meet...");
        goToCall({
          lead_id: leadId,
          phoneNumber: newPhoneNumber,
          presetDigitsToSend: [`${newPin ?? ""}`, "#"],
          google_space_sellfire_id: sellfire_meeting_id,
          lead_brand_id: leadData?.fetchLead?.brand_id ?? "",
        });
      },
    });
    setShowJoinGoogleMeetModal(true);
  };

  const handleJoinMeeting = ({
    leadId,
    existingLeadOTFVideoMeeting,
    createdOTFVideoMeetingType,
  }: {
    leadId: string;
    existingLeadOTFVideoMeeting: OTFVideoMeeting | null | undefined;
    createdOTFVideoMeetingType: OTFMeetingType;
  }) => {
    if (createdOTFVideoMeetingType === "Zoom") {
      handleJoinZoomMeeting({
        url: existingLeadOTFVideoMeeting?.meeting_url,
        zoom_meeting_sellfire_id: existingLeadOTFVideoMeeting?.sellfire_meeting_id,
        leadId,
        meeting_id: existingLeadOTFVideoMeeting?.meeting_id ?? "",
      });
    } else if (createdOTFVideoMeetingType === "Google") {
      handleJoinGoogleMeeting({
        url: existingLeadOTFVideoMeeting?.meeting_url,
        sellfire_meeting_id: existingLeadOTFVideoMeeting?.sellfire_meeting_id,
        leadId,
      });
    }

    setShowOptionsMenu(false);
  };

  const handleSendSMS = (phoneNumber: string | undefined) => {
    if (!phoneNumber) {
      appToast("Please add a primary phone number to this contact in order to send the meeting link via SMS.");
      return;
    }

    appToast("Sending meeting link via SMS...");

    sendSMS({
      variables: {
        lead_id: leadId,
        text: `You have a meeting in progress. Join here: ${existingLeadOTFVideoMeeting?.meeting_url}`,
        phone_number: phoneNumber,
      },
    });

    setShowOptionsMenu(false);
  };

  const handleSendEmail = (email: string | undefined) => {
    if (!email) {
      appToast("Please add a primary email to this contact in order to send the meeting link via email.");
      return;
    }

    appToast("Sending meeting link via email...");

    sendEmailToLead({
      variables: {
        lead_id: leadId,
        html: `You have a meeting in progress. Join here: ${existingLeadOTFVideoMeeting?.meeting_url}`,
        email: email,
        subject: `Google Meeting Invite`,
      },
    });
    setShowOptionsMenu(false);
  };
  const handleCopyLink = () => {
    if (!existingLeadOTFVideoMeeting?.meeting_url) {
      appToast("Unable to find a valid meeting link.");
      return;
    }

    navigator.clipboard.writeText(existingLeadOTFVideoMeeting?.meeting_url);

    appToast("Meeting link copied to clipboard.");

    setShowOptionsMenu(false);
  };

  const handleDeleteMeeting = () => {
    onDeleteMeeting({
      meetingType: createdOTFVideoMeetingType ?? "",
      meetingId: existingLeadOTFVideoMeeting?.sellfire_meeting_id ?? "",
    });
    setShowOptionsMenu(false);

    removeOTFVideoMeeting(leadId);
  };

  // defaults to primary phone number
  const numberToSendMeetingLinkTo = leadData?.fetchLead?.primary_phone_number;
  // defaults to primary email
  const emailToSendMeetingLinkTo = leadData?.fetchLead?.primary_email;

  return (
    <OptionsDiv>
      <PhoenixStyledTooltip id="otf-meeting-button-tooltip" />
      <Option onClick={() => handleJoinMeeting({ leadId, existingLeadOTFVideoMeeting, createdOTFVideoMeetingType })}>
        <PhoenixIcon svg={video} color={theme.PRIMARY500} size={16} pointer />
        <FlexDiv direction="column">
          <AppText color={theme.text.brand.primary} fontSize={12} fontWeight={600}>
            Join {createdOTFVideoMeetingType} Meeting
          </AppText>
          <AppText
            color={theme.text.neutral.tertiary}
            fontSize={10}
            fontWeight={400}
            style={{ wordBreak: "break-all" }}
          >
            ID: {existingLeadOTFVideoMeeting?.meeting_id || existingLeadOTFVideoMeeting?.meeting_code || "N/A"}
          </AppText>
        </FlexDiv>
      </Option>
      <Option onClick={() => handleSendSMS(numberToSendMeetingLinkTo)} disabled={!numberToSendMeetingLinkTo}>
        <PhoenixIcon svg={message_square} color={theme.PRIMARY500} size={16} pointer />
        <FlexDiv direction="column">
          Send Via SMS
          <AppText
            color={theme.text.neutral.tertiary}
            fontSize={10}
            fontWeight={400}
            style={{ wordBreak: "break-all" }}
          >
            {numberToSendMeetingLinkTo || "N/A"}
          </AppText>
        </FlexDiv>
      </Option>
      <Option onClick={() => handleSendEmail(emailToSendMeetingLinkTo)} disabled={!emailToSendMeetingLinkTo}>
        <PhoenixIcon svg={mail} color={theme.PRIMARY500} size={16} pointer />
        <FlexDiv direction="column">
          Send Via Email
          <AppText
            color={theme.text.neutral.tertiary}
            fontSize={10}
            fontWeight={400}
            style={{ wordBreak: "break-all" }}
          >
            {emailToSendMeetingLinkTo || "N/A"}
          </AppText>
        </FlexDiv>
      </Option>
      <Option
        borderRadius="0px"
        onClick={handleCopyLink}
        style={{
          borderBottom: `1px solid ${theme.border.neutral.primary}`,
        }}
      >
        <PhoenixIcon svg={copy} color={theme.PRIMARY500} size={16} pointer />
        Copy Meeting Link
      </Option>
      <Option onClick={handleDeleteMeeting}>
        <PhoenixIcon svg={trash} color={theme.DANGER600} size={16} pointer />
        Delete {createdOTFVideoMeetingType} Meeting
      </Option>
    </OptionsDiv>
  );
};

export const OTFMeetingButton = ({ leadId, disabled, height, width, tooltipText }: OTFMeetingSelectProps) => {
  const { data: existingLeadOTFVideoMeeting } = useFetchCachedMeeting({ leadId });

  const createdOTFVideoMeetingType = existingLeadOTFVideoMeeting?.meeting_type ?? undefined;

  const [showOptionsMenu, setShowOptionsMenu] = useState(false);

  const {
    createInstantMeeting,
    error: createInstantMeetingError,
    loading: createInstantMeetingLoading,
  } = useCreateOTFMeeting({
    onCompleted: (data) => {
      const isZoomMeeting = data.createInstantMeeting.zoom_meeting_sellfire_id;
      const isGoogleMeeting = data.createInstantMeeting.google_space_sellfire_id;

      if (isZoomMeeting || isGoogleMeeting) {
        return;
      }

      appToast("Unable to create video meeting for this lead.");
      console.log("unable to find valid meeting provider");

      return;
    },
  });

  const handleMeetingTypeChange = (value: OTFMeetingType) => {
    if (value === "Zoom" || value === "Google") {
      const BEValueForMeetingType = value === "Zoom" ? "ZOOM" : value === "Google" ? "GOOGLE_MEET" : "N/A";
      createInstantMeeting({
        variables: {
          meetingType: BEValueForMeetingType,
          lead_id: leadId,
        },
      });
      setShowOptionsMenu(false);
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [createdOTFVideoMeetingType, existingLeadOTFVideoMeeting, tooltipText]);

  if (createInstantMeetingError) {
    return <AppText>Error creating meeting</AppText>;
  }

  return (
    <FlexDiv align="center" style={{ position: "relative" }}>
      {tooltipText && createdOTFVideoMeetingType === undefined && (
        <PhoenixStyledTooltip
          id={createdOTFVideoMeetingType === undefined ? `otf-meeting-button-tooltip-${leadId}` : undefined}
        />
      )}
      {createInstantMeetingLoading ? (
        <SkeletonBlock height={height ?? 26} width={width ?? 46} borderRadius={8} />
      ) : (
        <PhoenixAppButton
          disabled={disabled}
          variant="brand"
          buttonType="ghost-small"
          selected={createdOTFVideoMeetingType !== undefined}
          onClick={() => {
            setShowOptionsMenu(!showOptionsMenu);
          }}
          height={height}
          width={width}
          padding={createdOTFVideoMeetingType === undefined ? "0px 4px" : undefined}
          data-tip={createdOTFVideoMeetingType === undefined ? tooltipText : undefined}
          data-for={
            createdOTFVideoMeetingType === undefined && tooltipText ? `otf-meeting-button-tooltip-${leadId}` : undefined
          }
        >
          <PhoenixIcon
            svg={video}
            color={createdOTFVideoMeetingType === undefined ? theme.PRIMARY500 : theme.WHITE_COLOR}
            size={16}
            hoverColor={createdOTFVideoMeetingType === undefined ? theme.PRIMARY500 : theme.WHITE_COLOR}
            pointer
          />
          <PhoenixIcon
            svg={caret_down}
            color={createdOTFVideoMeetingType === undefined ? theme.PRIMARY500 : theme.WHITE_COLOR}
            hoverColor={createdOTFVideoMeetingType === undefined ? theme.PRIMARY500 : theme.WHITE_COLOR}
            size={16}
            pointer
          />
        </PhoenixAppButton>
      )}
      {showOptionsMenu &&
        (!existingLeadOTFVideoMeeting ? (
          <OTFMeetingMenu setShowOptionsMenu={setShowOptionsMenu} handleMeetingTypeChange={handleMeetingTypeChange} />
        ) : (
          <OTFMeetingMenuLiveMeeting
            leadId={leadId}
            setShowOptionsMenu={setShowOptionsMenu}
            createdOTFVideoMeetingType={createdOTFVideoMeetingType}
            existingLeadOTFVideoMeeting={existingLeadOTFVideoMeeting}
          />
        ))}
    </FlexDiv>
  );
};

interface OptionsDivProps {
  top?: number;
  right?: number;
}
const OptionsDiv = styled.div<OptionsDivProps>`
  position: absolute;
  top: ${(props) => (props.top ? `${props.top}px` : "34px")};
  right: ${(props) => (props.right ? `${props.right}px` : "0px")};
  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 4px;
  overflow: visible;
  animation: ${theme.popup} 0.2s ease;
  z-index: 1;
`;

interface OptionProps {
  width?: string;
  height?: number;
  gap?: number;
  borderRadius?: string;
  disabled?: boolean;
}

const Option = styled.div<OptionProps>`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.gap ? `${props.gap}px` : "8px")};

  width: ${(props) => (props.width ? props.width : "190px")};
  height: ${(props) => (props.height ? `${props.height}px` : "max-content")};
  padding: 8px;

  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  color: ${theme.BLACK_COLOR};

  transition: background-color 0.15s ease, color 0.15s ease;

  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "4px")};

  :hover {
    background-color: ${theme.PRIMARY100};
    color: ${theme.PRIMARY500};
  }

  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;
