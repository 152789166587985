import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { reorder, edit } from "src/images/NewDesign";
import { PhoenixIcon } from "src/Components/UI/Phoenix";
import Switch from "react-switch";
import { theme } from "src/utils/theme";
import { useQuery, gql, useMutation } from "@apollo/client";
import moment from "moment";
import { appToast } from "src/utils/toast";
import { SkeletonBlock } from "src/Components/UI/SkeletonBlock";
import styled from "styled-components";
import { trash } from "src/images/NewDesign";
import "./InboundCallFlowTable.css";

const FETCH_CUSTOM_RULES_FOR_TABLE = gql`
  query fetchRules($ruleType: RuleType) {
    fetchRules(rule_type: $ruleType) {
      id
      active
      created_at
      updated_at
      priority
      entity_type
      name
      rule_type
    }
  }
`;

const UPDATE_TRANSFER_RULE_STATUS = gql`
  mutation updateTranferRuleStatus($rule_id: String!, $active: Boolean!) {
    updateTranferRuleStatus(rule_id: $rule_id, active: $active) {
      id
      active
    }
  }
`;

const DELETE_RULE = gql`
  mutation removeRoutingRule($rule_id: String!) {
    removeRoutingRule(rule_id: $rule_id)
  }
`;

const UPDATE_RULE_ORDER = gql`
  mutation updateRuleOrder($rule_ids: [String!]!) {
    updateRuleOrder(rule_ids: $rule_ids)
  }
`;

const handleRowDragEnd = ({
  event,
  currentRowData,
  setRowData,
  updateRuleOrder,
}: {
  event: any;
  currentRowData: any[];
  setRowData: (data: any) => void;
  updateRuleOrder: (data: any) => void;
}) => {
  const { overIndex, node } = event;
  const draggedRowIds = event.nodes?.map((node: any) => node.data.ruleId);
  const updatedRowData = [...currentRowData];

  // Reorder the dragged rows
  const draggedRows = updatedRowData.filter((row) => draggedRowIds.includes(row.ruleId));
  const remainingRows = updatedRowData.filter((row) => !draggedRowIds.includes(row.ruleId));
  const reorderedRows = [...remainingRows.slice(0, overIndex), ...draggedRows, ...remainingRows.slice(overIndex)];

  // Update the priorities in the reordered rows
  const updatedData = reorderedRows?.map((row, index) => ({
    ...row,
    priority: index + 1,
  }));

  setRowData(updatedData);

  const updatedRuleIds = updatedData?.map((row) => row.ruleId);
  updateRuleOrder({ variables: { rule_ids: updatedRuleIds } });
};

const InboundCallFlowTable = ({ onEditRule }: { onEditRule: (rule_id: string) => void }) => {
  const [rowData, setRowData] = useState([] as any[]);

  const { data: allRules, loading: loadingAllRules, error: errorAllRules, refetch: refetchAllRules } = useQuery(
    FETCH_CUSTOM_RULES_FOR_TABLE,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        ruleType: "InboundCall",
      },
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
      onCompleted({}) {
        const sortedDataByRow = allRules?.fetchRules?.map((rule: any) => {
          return {
            priority: rule.priority + 1,
            ruleName: rule.name,
            lastModified: rule.updated_at
              ? moment(rule.updated_at)?.format("MM/DD/YYYY")
              : rule.created_at
              ? moment(rule.created_at)?.format("MM/DD/YYYY")
              : "N/A",
            ruleType: rule?.entity_type === "User" ? "Rep Based" : "Lead Based",
            active: !!rule.active,
            ruleId: rule.id,
          };
        });
        setRowData(sortedDataByRow.sort((a: any, b: any) => a?.priority - b?.priority));
      },
    },
  );

  const [updateTranferRuleStatus, { data: dataTransferRuleStatus, loading: loadingTransferRuleStatus }] = useMutation(
    UPDATE_TRANSFER_RULE_STATUS,
    {
      onCompleted({ updateTranferRuleStatus }) {
        if (!updateTranferRuleStatus) {
          return;
        }
        appToast("Success! Updated rules");
      },
      onError({ message }) {
        console.log("Error in createOrUpdateCustomTransferRule: ", message);
        appToast(message);
      },
      refetchQueries: ["fetchRules"],
    },
  );

  const [updateRuleOrder] = useMutation(UPDATE_RULE_ORDER, {
    onCompleted({ updateRuleOrder }) {
      if (!updateRuleOrder) {
        return;
      }
      appToast("Updated order");
    },
    onError({ message }) {
      console.log("Error in updateRuleOrder: ", message);
      appToast(message);
    },
    refetchQueries: ["fetchRules"],
  });

  const [deleteRule, { data: deleteRuleData, loading: loadingDeleteRule, error: errorDeleteRule }] = useMutation(
    DELETE_RULE,
    {
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
        appToast("Error removing transfer rule");
      },
      onCompleted({ removeRoutingRule }) {
        if (removeRoutingRule) {
          appToast("Transfer rule removed");
          refetchAllRules();
        }
      },
    },
  );

  // ---------------------------- //
  // AG GRID Cell Components
  // ---------------------------- //

  const PriorityCellRenderer = (props: any) => {
    return (
      <GridCell>
        <PhoenixIcon svg={reorder} size={16} />
        <div style={{ marginLeft: "8px" }}>{props.value}</div>
      </GridCell>
    );
  };

  const ActiveCellRenderer = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center", height: "40px" }}>
        <Switch
          onChange={(checked) => {
            const updatedRowData = rowData?.map((row) => {
              if (row.ruleId === props.data.ruleId) {
                return {
                  ...row,
                  active: checked,
                };
              } else {
                return row;
              }
            });
            setRowData(updatedRowData);

            // update the BE

            updateTranferRuleStatus({
              variables: {
                rule_id: props.data.ruleId,
                active: checked,
              },
            });
          }}
          onColor={theme.PRIMARY500}
          offColor={theme.NEUTRAL200}
          checked={!!props.value}
          height={16}
          width={32}
          handleDiameter={12}
          checkedIcon={false}
          uncheckedIcon={false}
        />
      </div>
    );
  };

  const ManageCellRenderer = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center", height: "40px", gap: "8px" }}>
        <PhoenixIcon
          svg={edit}
          size={16}
          onClick={() => {
            onEditRule(props.data.ruleId);
          }}
        />

        <PhoenixIcon
          svg={trash}
          variant="danger"
          size={16}
          disabled={loadingDeleteRule}
          onClick={() => {
            deleteRule({
              variables: {
                rule_id: props.data.ruleId,
              },
            });
          }}
        />
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: "Priority",
      field: "priority",
      width: 75,
      headerClass: "ag-inbound-call-flow-header",
      rowDrag: true,
      cellRendererFramework: PriorityCellRenderer,
    },
    {
      headerName: "Rule Name",
      field: "ruleName",
      headerClass: "ag-inbound-call-flow-header",
      minWidth: 300,
      flex: 1,
      resizable: true,
    },
    {
      headerName: "Last Modified",
      field: "lastModified",
      minWidth: 100,
      headerClass: "ag-inbound-call-flow-header",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Active",
      field: "active",
      width: 75,
      headerClass: "ag-inbound-call-flow-header",
      resizable: true,
      cellRendererFramework: ActiveCellRenderer,
    },
    {
      headerName: "Manage",
      field: "ruleId",
      width: 75,
      headerClass: "ag-inbound-call-flow-header",
      cellRendererFramework: ManageCellRenderer,
      resizable: true,
    },
  ];

  // we don't want the loading state to show on further updates. because it causes the whole grid to re-render

  const loading = rowData.length === 0 && (loadingAllRules || loadingTransferRuleStatus);

  const error = errorAllRules;

  if (error) {
    return <div>Error! Something went wrong.</div>;
  }

  if (loading) {
    return (
      <SkeletonBlock
        height={200}
        width={"100%"}
        borderRadius={4}
        lightPulseStateColor={theme.NEUTRAL100}
        darkPulseStateColor={theme.NEUTRAL200}
      />
    );
  }
  return (
    <div
      className={"ag-theme-inbound-call-flow-list"}
      style={{
        minHeight: "200px",
        width: "100%",
      }}
    >
      <AgGridReact
        rowData={rowData}
        rowDragEntireRow={true}
        columnDefs={columnDefs}
        suppressMovableColumns={true}
        suppressDragLeaveHidesColumns={true}
        tooltipMouseTrack={true}
        rowDragManaged
        animateRows={true}
        onRowDragEnd={(event) => {
          handleRowDragEnd({
            event: event,
            currentRowData: rowData,
            setRowData: setRowData,
            updateRuleOrder: updateRuleOrder,
          });
        }}
        enableCellTextSelection={true}
        domLayout="autoHeight"
      />
    </div>
  );
};

const GridCell = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  height: 100%;
`;

export default InboundCallFlowTable;
