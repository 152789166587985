import React, { useCallback, useMemo, useRef, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import styled from "styled-components";
import { AppText, FlexDiv } from "src/Components/UI";
import { PhoenixAppButton } from "src/Components/UI/Phoenix";
import { theme } from "src/utils/theme";
import { appToast, errorToast } from "src/utils/toast";
import HotLeadsTab, { HotLeadsTabRef } from "./HotLeadsTab";
import CustomNotificationsTab, { CustomNotificationsTabRef } from "./CustomNotificationsTab";

enum TABS {
  HOT_LEADS = "hot-leads",
  CUSTOM_NOTIFICATIONS = "custom-notifications",
}

const UPDATE_USER_NOTIFICATION_FREQUENCY = gql`
  mutation UpdateUserNotificationFrequency(
    $email_opened: NotificationFrequency!
    $email_link_clicked: NotificationFrequency!
  ) {
    updateUserNotificationFrequency(email_opened: $email_opened, email_link_clicked: $email_link_clicked)
  }
`;

const UPSERT_CUSTOM_NOTIFICATIONS = gql`
  mutation upsertCustomNotification($notifications: [CustomNotificationInput!]!) {
    upsertCustomNotification(custom_notifications: $notifications) {
      id
    }
  }
`;

const NotificationSettings = () => {
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<TABS>(TABS.HOT_LEADS);
  const hotLeadsTabRef = useRef<HotLeadsTabRef>(null);
  const customNotificationsTabRef = useRef<CustomNotificationsTabRef>(null);

  const [
    updateUserNotificationFrequency,
    { loading: updatingHotLeadConfig },
  ] = useMutation(UPDATE_USER_NOTIFICATION_FREQUENCY, { refetchQueries: ["fetchHotLeadConfig"] });

  const [upsertCustomNotification, { loading: upsertingCustomNotification }] = useMutation(
    UPSERT_CUSTOM_NOTIFICATIONS,
    { refetchQueries: ["fetchCustomNotifications"] },
  );

  const renderTab = useMemo(() => {
    if (selectedTab === TABS.HOT_LEADS) {
      return <HotLeadsTab ref={hotLeadsTabRef} setHasChanges={setHasChanges} />;
    }

    if (selectedTab === TABS.CUSTOM_NOTIFICATIONS) {
      return <CustomNotificationsTab ref={customNotificationsTabRef} setHasChanges={setHasChanges} />;
    }

    return null;
  }, [selectedTab]);

  const changeTab = useCallback((tab: TABS) => {
    setSelectedTab(tab);
    setHasChanges(false);
  }, []);

  const handleUpdateHotLeadConfig = async () => {
    try {
      const values = hotLeadsTabRef.current?.getValues();
      await updateUserNotificationFrequency({
        variables: {
          email_opened: values?.email_opened_notifications_frequency,
          email_link_clicked: values?.email_link_clicked_notification_frequency,
        },
      });
      appToast("Updated hot lead configuration");
    } catch (e) {
      errorToast("Failed to update hot lead configuration");
    }
  };

  const handleUpdateCustomNotifications = async () => {
    try {
      const { values = [], isValid } = (await customNotificationsTabRef.current?.submitForm()) ?? {
        values: [],
        isValid: false,
      };

      if (!isValid) {
        errorToast("Please fill in all required fields");
        return;
      }
      await upsertCustomNotification({ variables: { notifications: values } });
      appToast("Updated custom notifications");
    } catch (e) {
      errorToast("Failed to update custom notifications");
    }
  };

  const handleSaveChanges = useCallback(() => {
    if (selectedTab === TABS.HOT_LEADS) {
      handleUpdateHotLeadConfig();
    }

    if (selectedTab === TABS.CUSTOM_NOTIFICATIONS) {
      handleUpdateCustomNotifications();
    }
  }, [selectedTab]);

  return (
    <Page>
      <TopAlignDiv>
        <FlexDiv direction="column" width="100%" gap={24}>
          <FlexDiv justify="space-between" align="center" gap={24} width="100%">
            <FlexDiv direction="column" gap={4}>
              <AppText fontSize={22} fontWeight={500} noWrap>
                Notification Settings
              </AppText>
              <AppText color={theme.text.neutral.secondary}>Customize the frequency of notifications</AppText>
            </FlexDiv>

            <FlexDiv>
              <PhoenixAppButton
                buttonType="primary"
                onClick={handleSaveChanges}
                disabled={updatingHotLeadConfig || upsertingCustomNotification || !hasChanges}
              >
                Save Changes
              </PhoenixAppButton>
            </FlexDiv>
          </FlexDiv>

          <Tabs>
            <Tab selected={selectedTab === TABS.HOT_LEADS} onClick={() => changeTab(TABS.HOT_LEADS)}>
              Hot Leads
            </Tab>
            <Tab
              selected={selectedTab === TABS.CUSTOM_NOTIFICATIONS}
              onClick={() => changeTab(TABS.CUSTOM_NOTIFICATIONS)}
            >
              Custom Notifications
            </Tab>
          </Tabs>
        </FlexDiv>
      </TopAlignDiv>
      <Main>{renderTab}</Main>
    </Page>
  );
};

const Page = styled.div`
  border: 1px solid ${theme.NEUTRAL100};
  flex: 1;
  background: ${theme.text.neutral.inverse};
  margin: 24px;
  border-radius: 8px;
  padding-top: 36px;
`;

const TopAlignDiv = styled.div`
  display: flex;
  justify-items: center;
  padding: 0px 24px;
  width: 100%;
`;

const Tabs = styled.div`
  align-items: center;
  display: flex;
`;

const Tab = styled(AppText)<{ selected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  border-bottom: solid 2px ${(props) => (props.selected ? theme.BLACK_COLOR : "transparent")};
  padding-bottom: 6px;
  margin-top: 2px;
  color: ${(props) => (props.selected ? theme.BLACK_COLOR : theme.NEUTRAL300)};
  text-transform: uppercase;
`;

const Main = styled.div`
  align-items: flex-start;
  background-color: ${theme.text.neutral.inverse};
  border-top: 1px solid ${theme.NEUTRAL200};
  display: flex;
  gap: 16px;
  padding: 40px 24px;
  width: 100%;
`;

export { NotificationSettings };
