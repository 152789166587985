import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as React from "react";
import * as Sentry from "@sentry/react";

import styled from "styled-components";

import { NewSelectField, NewInputField } from "../../Field";
import { AppErrorText, AppText, Loading, NewAppButton, NewAppSelect } from "../../UI";
import * as Yup from "yup";
import { CallContext, CartContext } from "../../../context";
import { restAPI } from "../../../apollo";
import { BACKEND_URL } from "../../../utils/variables";
import { appToast } from "../../../utils/toast";
import { useState } from "react";
import { getExtraCountryCodes, sortedCountryCodesData } from "../../../static/countries";
import { PHONE_REGEX } from "../../../utils/regex";
import { convertDate, extractNumberWithCountry, validateZipCode } from "../../../utils/format";
import { StateDropdown } from "../LeadCard/StateDropdown";
import { SHOW_LEAD_SOURCE_TO_USER } from "../../../apollo/query";
import { getLocalStorage, recursiveObjectTrim } from "../../../utils/misc";
import { cloneDeep } from "lodash";
import { loggedInUser } from "src/apollo/cache";

const FETCH_LEAD = gql`
  query fetchLead($id: String) {
    fetchLead(id: $id) {
      id
      first_name
      computed_mrr
      current_close_date
      last_name
      business_name
      primary_phone_number
      primary_email
      address
      city
      state
      country
      local_primary_phone_number
      primary_phone_number_country_code
      timezone_by_state
      zip
      sale_flow_custom_fields {
        id
        computed_value
        key
        value
        list_value
        visible
        boo_value
        num_value
        date_value
        type
        lead_id
        options
      }
      industry
      sub_industry
      lead_source
    }
  }
`;

const FETCH_CUSTOM_FIELD_DROPDOWN_OPTIONS = gql`
  query getCustomFieldOptions($key: String!) {
    getCustomFieldOptions(key: $key)
  }
`;

export const VALIDATE_PHONE_NUMBER = gql`
  mutation validatePhoneNumber($phone_number: String!, $lead_id: String!) {
    validatePhoneNumber(phone_number: $phone_number, lead_id: $lead_id)
  }
`;
const FETCH_ORGANIZATION = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      make_sale_first_name_required
      make_sale_last_name_required
      make_sale_business_name_required
      make_sale_primary_phone_number_required
      make_sale_primary_email_required
      make_sale_country_required
      make_sale_address_required
      make_sale_city_required
      make_sale_state_required
      make_sale_zip_required
      make_sale_industry_required
      make_sale_sub_industry_required
      make_sale_lead_source_required
    }
  }
`;

const GET_POSSIBLE_INDUSTRIES_DATA = gql`
  query fetchIndustryOptions {
    fetchIndustryOptions {
      id
      label
      sub_industries
    }
  }
`;

const GET_POSSIBLE_LEAD_SOURCES_DATA = gql`
  query fetchLeadSourceOptions {
    fetchLeadSourceOptions {
      id
      label
    }
  }
`;

export const UPDATE_LEAD = gql`
  mutation updateLead(
    $id: String!
    $first_name: String
    $last_name: String
    $business_name: String
    $primary_phone_number: String
    $primary_email: String
    $industry: String
    $sub_industry: String
    $lead_source: String
    $channel: CHANNEL
    $status: String
    $title: String
    $content: String
    $city: String
    $state: String
    $country: String
    $address: String
    $zip: String
    $country_code: String
    $custom_fields: [CustomFieldInput!] # $mrr: Float # $current_close_date: DateTime
  ) {
    updateLead(
      id: $id
      first_name: $first_name
      last_name: $last_name
      business_name: $business_name
      primary_phone_number: $primary_phone_number
      primary_email: $primary_email
      industry: $industry
      sub_industry: $sub_industry
      lead_source: $lead_source
      channel: $channel
      status: $status
      title: $title
      content: $content
      city: $city
      state: $state
      country: $country
      address: $address
      zip: $zip
      country_code: $country_code
      custom_fields: $custom_fields # mrr: $mrr # current_close_date: $current_close_date
    ) {
      id
      first_name
      last_name
      full_name
      business_name
      primary_phone_number
      local_primary_phone_number
      primary_phone_number_country_code
      primary_email
      industry
      sub_industry
      lead_source
      channel
      status
      title
      content
      city
      state
      country
      timezone_by_state
      address
      zip
      sale_flow_custom_fields {
        id
        computed_value
        key
        value
        list_value
        visible
        boo_value
        num_value
        date_value
        type
        lead_id
        options
      }
    }
  }
`;

interface CustomField {
  id: string;
  value: string | number | boolean;
  num_value?: number;
  list_value?: string[];
  boo_value?: boolean;
  date_value?: string;
  type: string;
  key: string;
  options: string[];
}

interface MyFormikProps {
  first_name?: string;
  last_name?: string;
  business_name: string;
  phone_number: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country_code: { label: string; value: { iso2: string; code: string } };
  industry: string;
  sub_industry: string;
  lead_source: string;
  customFields: CustomField[];
  // mrr: string;
  // current_close_date: string;
}

interface ConfirmLeadDetailsProps {
  setCallOptions: (state: string) => void;
  leadID: string;
}

const ConfirmLeadDetails: React.FC<ConfirmLeadDetailsProps> = (props) => {
  const { data: showLeadSourceToUser } = useQuery(SHOW_LEAD_SOURCE_TO_USER);
  const { data: organizationData, loading: organizationLoading, error: organizationError } = useQuery(
    FETCH_ORGANIZATION,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
      onCompleted: (data) => {
        if (data) {
          console.log("organization required fields: ", data);
        }
      },
    },
  );
  const { data, loading, error, refetch } = useQuery(FETCH_LEAD, {
    fetchPolicy: "network-only",
    variables: { id: props.leadID },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: industryOptions, loading: industryLoading, error: industryError } = useQuery(
    GET_POSSIBLE_INDUSTRIES_DATA,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: leadSourceOptions, loading: leadSourceLoading, error: leadSourceError } = useQuery(
    GET_POSSIBLE_LEAD_SOURCES_DATA,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const [updateLead, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_LEAD, {
    async onCompleted({ updateLead }) {
      if (!updateLead) {
        appToast("Error confirming lead. Something went wrong.");
        return;
      }
      props.setCallOptions("make sale");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `updateLead GraphQL Error: ${message}`,
      });
    },
  });

  const [validatePhoneNumber, { loading: phoneLoading, data: phoneData, error: phoneError }] = useMutation(
    VALIDATE_PHONE_NUMBER,
  );

  const { handleRevertClosingScript, recording } = React.useContext(CallContext);
  const { clearCartContext } = React.useContext(CartContext);

  const [oldPhoneValue, setOldPhoneValue] = useState("");

  const returnSubIndustryOptions = (industry: string, selected_sub_industry: string) => {
    const hasSubIndustryArray =
      !!industryOptions?.fetchIndustryOptions.find((item: any) => item?.label === industry)?.sub_industries &&
      !!industryOptions?.fetchIndustryOptions.find((item: any) => item?.label === industry)?.sub_industries?.length;

    if (!!industry && !!industryOptions?.fetchIndustryOptions) {
      if (hasSubIndustryArray) {
        return industryOptions.fetchIndustryOptions
          .filter((item: any) => item.label === industry)[0]
          .sub_industries?.map((ls: string) => ({ label: ls, value: ls }))
          ?.sort((a: any, b: any) => (a?.label < b?.label ? -1 : 1))
          ?.concat([{ label: "Select Sub Industry", value: "" }]);
      } else {
        //handles edge case where sub_industry is set but has been deleted from the industry options
        if (!!selected_sub_industry) return [{ label: selected_sub_industry, value: selected_sub_industry }];
        else return [{ label: "No Sub Industries Available", value: "" }];
      }
    } else {
      return [{ label: "Select Primary Industry", value: "" }];
    }
  };

  //react dates
  const [focused, setFocused] = useState(null as any);

  const numberYupValidation = () => ({
    is: "Rate" || "Percentage" || "Number",
    then: Yup.number().typeError("Must be a number").nullable(),
  });

  const textYupValidation = () => ({
    is: "Text",
    then: Yup.string(),
  });

  const booleanYupValidation = () => ({
    is: "Boolean",
    then: Yup.boolean().notRequired(),
  });

  const createValidationSchema = () => {
    const requiredFields = organizationData?.fetchOrganization
      ? organizationData.fetchOrganization
      : {
          make_sale_first_name_required: false,
          make_sale_last_name_required: false,
          make_sale_business_name_require: false,
          make_sale_primary_phone_number_required: false,
          make_sale_primary_email_required: false,
          make_sale_country_required: false,
          make_sale_address_required: false,
          make_sale_city_required: false,
          make_sale_state_required: false,
          make_sale_zip_required: false,
          make_sale_field_required_industry: false,
          make_sale_field_required_sub_industry: false,
          make_sale_field_required_lead_source: false,
        };

    return Yup.object().shape({
      first_name: Yup.lazy(() => {
        if (requiredFields && requiredFields["make_sale_first_name_required"]) {
          return Yup.string().required().trim();
        }
        return Yup.string().trim();
      }),
      last_name: Yup.lazy(() => {
        if (requiredFields && requiredFields["make_sale_last_name_required"]) {
          return Yup.string().required().trim();
        }
        return Yup.string().trim();
      }),
      business_name: Yup.lazy(() => {
        if (requiredFields && requiredFields["make_sale_business_name_required"]) {
          return Yup.string().required().trim();
        }
        return Yup.string().trim();
      }),
      email: Yup.lazy(() => {
        if (requiredFields && requiredFields["make_sale_primary_email_required"]) {
          return Yup.string().email().trim().required();
        }
        return Yup.string().email().trim();
      }),
      phone_number: Yup.lazy(() => {
        if (requiredFields && requiredFields["make_sale_primary_phone_number_required"]) {
          return Yup.string()
            .required()
            .matches(PHONE_REGEX, "Must be a valid phone number")
            .max(15, "Must be valid phone number")
            .min(7, "Must be valid phone number")
            .trim()
            .test("phone_number", "This number is already in use", async (value: any) => {
              if (!!value && value.length === 10 && value !== oldPhoneValue) {
                if (value !== oldPhoneValue) {
                  setOldPhoneValue(value);
                }
                const isPhoneValid = await validatePhoneNumber({
                  variables: { phone_number: value || "", lead_id: "" },
                });
                return isPhoneValid.data.validatePhoneNumber;
              }
              return true;
            });
        }
        return Yup.string()
          .matches(PHONE_REGEX, "Must be a valid phone number")
          .max(15, "Must be valid phone number")
          .min(7, "Must be valid phone number")
          .trim()
          .test("phone_number", "This number is already in use", async (value: any) => {
            if (!!value && value.length === 10 && value !== oldPhoneValue) {
              if (value !== oldPhoneValue) {
                setOldPhoneValue(value);
              }
              const isPhoneValid = await validatePhoneNumber({
                variables: { phone_number: value || "", lead_id: "" },
              });
              return isPhoneValid.data.validatePhoneNumber;
            }
            return true;
          });
      }),
      address: Yup.lazy(() => {
        if (requiredFields && requiredFields["make_sale_address_required"]) {
          return Yup.string().required().trim();
        }
        return Yup.string().trim();
      }),
      city: Yup.lazy(() => {
        if (requiredFields && requiredFields["make_sale_city_required"]) {
          return Yup.string().required().trim();
        }
        return Yup.string().trim();
      }),
      state: Yup.lazy(() => {
        if (requiredFields && requiredFields["make_sale_state_required"]) {
          return Yup.string().required().trim();
        }
        return Yup.string().trim();
      }),
      zip: Yup.lazy(() => {
        if (requiredFields && requiredFields["make_sale_zip_required"]) {
          return Yup.string()
            .test("zip", "Not a valid Zip code for this country", function (value: any) {
              //can't be an arrow function so it can properly call this.resolve
              const country = Yup.ref("country_code");
              return validateZipCode(this.resolve(country)?.value?.iso2).test(value);
            })
            .min(3, "Zip code must be at least 3 characters.")
            .test("max", "Zip code must be at most 9 characters.", (value: any) => {
              return value?.replace(" ", "")?.replace("-", "")?.length <= 9;
            })
            .trim()
            .nullable();
        }
        return Yup.string()
          .test("zip", "Not a valid Zip code for this country", function (value: any) {
            if (!value) {
              return true;
            }
            //can't be an arrow function so it can properly call this.resolve
            const country = Yup.ref("country_code");
            return validateZipCode(this.resolve(country)?.value?.iso2).test(value);
          })
          .notRequired()
          .min(3, "Zip code must be at least 3 characters.")
          .test("max", "Zip code must be at most 9 characters.", (value: any) => {
            if (!value) {
              return true;
            }
            return value?.replace(" ", "")?.replace("-", "")?.length <= 9;
          })
          .trim()
          .nullable();
      }),
      country_code: Yup.lazy(() => {
        if (requiredFields && requiredFields["make_sale_country_required"]) {
          return Yup.object()
            .required()
            .shape({
              label: Yup.string(),
              value: Yup.object().shape({ iso2: Yup.string(), code: Yup.string() }),
            });
        }
        return Yup.object().shape({
          label: Yup.string(),
          value: Yup.object().shape({ iso2: Yup.string(), code: Yup.string() }),
        });
      }),
      industry: Yup.lazy(() => {
        if (requiredFields && requiredFields["make_sale_industry_required"]) {
          return Yup.string().required().trim();
        }
        return Yup.string().trim();
      }),
      sub_industry: Yup.lazy(() => {
        if (requiredFields && requiredFields["make_sale_sub_industry_required"]) {
          return Yup.string().required().trim();
        }
        return Yup.string().trim();
      }),
      lead_source: Yup.lazy(() => {
        if (
          showLeadSourceToUser?.showLeadSourceToUser &&
          requiredFields &&
          requiredFields["make_sale_lead_source_required"]
        ) {
          return Yup.string().required().trim();
        }
        return Yup.string().trim();
      }),

      customFields: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          type: Yup.string().notRequired(),
          value: Yup.string().when("type", textYupValidation()).nullable(),
          boo_value: Yup.boolean().when("type", booleanYupValidation()).nullable(),
          num_value: Yup.number().typeError("Must be a number").when("type", numberYupValidation()).nullable(),
          // date_value: Yup.string().typeError("Must be a string").when("type", textYupValidation()).nullable(),
        }),
      ),
    });
  };

  const resumeRecording = async () => {
    const api_call = await restAPI.request({ method: "POST", baseURL: `${BACKEND_URL}/twilio/resumeRecording` });
    return api_call;
  };

  const handleRestartRecording = () => {
    if (!recording) resumeRecording();
  };

  if (loading) return <Loading />;
  if (error) return <AppErrorText>Error fetching lead details.</AppErrorText>;

  const {
    id,
    first_name,
    last_name,
    business_name,
    primary_phone_number,
    primary_email,
    address,
    city,
    state,
    zip,
    country,
    industry,
    sub_industry,
    lead_source,
    primary_phone_number_country_code,
    local_primary_phone_number,
    sale_flow_custom_fields,
    current_close_date,
    computed_mrr,
  } = data.fetchLead;

  const industryIsInOptions = !!industryOptions?.fetchIndustryOptions?.find((ls: any) => ls.label === industry);

  const computedIndustryOptions = industryIsInOptions
    ? industryOptions?.fetchIndustryOptions
      ? industryOptions.fetchIndustryOptions
          ?.map((ls: any) => ({
            label: ls.label,
            value: ls.label,
          }))
          .concat({ label: "Select Industry", value: "" })
      : []
    : industryOptions?.fetchIndustryOptions
    ? industryOptions.fetchIndustryOptions
        ?.map((ls: any) => ({
          label: ls.label,
          value: ls.label,
        }))
        .concat({ label: "Select Industry", value: "" })
        .concat({ label: industry, value: industry })
    : [];

  const orgCountryCodeOptions = getLocalStorage("country_code_options", []);

  const defaultPlusExtraCountryOptions = [
    ...sortedCountryCodesData,
    ...getExtraCountryCodes(orgCountryCodeOptions),
  ].sort((a, b) => {
    if (a?.value?.iso2 === "US") return -1;
    if (b?.value?.iso2 === "US") return 1;
    return a?.label?.localeCompare(b?.label);
  });

  return (
    <FormContainer>
      <HeaderContainer>
        <AppText fontSize={16}>Confirm Account Details</AppText>
      </HeaderContainer>
      <Formik
        initialValues={{
          first_name: first_name ? first_name : "",
          last_name: last_name ? last_name : "",
          business_name: business_name ? business_name : "",
          phone_number: local_primary_phone_number ? local_primary_phone_number : "",
          email: primary_email ? primary_email : "",
          address: address ? address : "",
          city: city ? city : "",
          zip: zip ? zip : "",
          // mrr: computed_mrr ? computed_mrr : "",
          // current_close_date: current_close_date ? current_close_date : "",
          country_code:
            defaultPlusExtraCountryOptions.find((countryCode) => countryCode.value.iso2 === country) ??
            defaultPlusExtraCountryOptions[0],
          state: state ? state : "",
          industry: industry ? industry : "",
          sub_industry: sub_industry ? sub_industry : "",
          lead_source: lead_source ? lead_source : "",
          customFields: (sale_flow_custom_fields || [])?.map((customField: CustomField) => {
            let valueToUse = {
              id: customField.id,
              value: "",
              list_value: [],
              type: customField.type,
              key: customField.key,
              options: [],
            } as any;
            let optionsToUse = [] as string[];
            switch (customField.type) {
              case "Dropdown":
                valueToUse.value = customField.value;
                valueToUse.options = customField.options;
                break;
              case "MultiDropdown":
                valueToUse.list_value = (customField.list_value || [])?.map((k) => ({ label: k, value: k }));
                valueToUse.options = customField.options;
                break;
              case "DateTime":
                valueToUse.date_value = customField.date_value ? convertDate(customField.date_value) : undefined;
                break;
              case "Date":
                valueToUse.value = customField.value;
                break;
              case "Number":
                valueToUse.num_value = customField.num_value;
                break;
              case "Text":
                valueToUse.value = customField.value;
                break;
              case "Boolean":
                valueToUse.boo_value = customField.boo_value;
                break;
              case "Rate":
                valueToUse.num_value = customField.num_value;
                break;
              case "Percentage":
                valueToUse.num_value = customField.num_value;
                break;
              default:
                valueToUse.value = "";
            }
            return valueToUse;
          }),
        }}
        validationSchema={createValidationSchema}
        validateOnMount={true}
        onSubmit={async (values) => {
          const final_custom_fields = values.customFields?.map((customField: CustomField) => {
            switch (customField.type) {
              case "Dropdown":
                return {
                  id: customField.id,
                  value: customField.value,
                };
              case "MultiDropdown":
                return {
                  id: customField.id,
                  // @ts-ignore
                  list_value: customField.list_value?.map((k) => k.value ?? k),
                };
              case "DateTime":
                return {
                  id: customField.id,
                  date_value: customField.date_value,
                };
              case "Date":
                return {
                  id: customField.id,
                  value: customField.value,
                };
              case "Number":
                return {
                  id: customField.id,
                  num_value: parseFloat(customField.num_value as any),
                };
              case "Text":
                return {
                  id: customField.id,
                  value: customField.value,
                };
              case "Boolean":
                return {
                  id: customField.id,
                  boo_value: customField.boo_value,
                };
              case "Rate":
                return {
                  id: customField.id,
                  num_value: parseFloat(customField.num_value as any),
                };
              case "Percentage":
                return {
                  id: customField.id,
                  num_value: parseFloat(customField.num_value as any),
                };
              default:
                return {
                  id: customField.id,
                  value: customField.value,
                };
            }
          });
          console.log("final_custom_fields: ", final_custom_fields);

          const val = cloneDeep({ ...values });
          recursiveObjectTrim(val);

          await updateLead({
            variables: {
              id,
              first_name: val.first_name ?? undefined,
              last_name: val.last_name ?? undefined,
              business_name: val.business_name ?? undefined,
              primary_phone_number: `+${val.country_code.value.code}${extractNumberWithCountry(
                val.phone_number,
                val.country_code?.value?.iso2,
              )}`,
              primary_email: val.email ?? undefined,
              address: val.address ?? undefined,
              city: val.city ?? undefined,
              state: val.state ?? undefined,
              zip: val.zip ?? undefined,
              country: val.country_code.value.iso2 ?? undefined,
              country_code: val.country_code.value.code ?? undefined,
              custom_fields: final_custom_fields ?? undefined,
              industry: val.industry ?? undefined,
              sub_industry: val.sub_industry ?? undefined,
              lead_source: val.lead_source ?? undefined,
              // mrr: values.mrr
              //   ? parseFloat(Number.isInteger(values.mrr) ? values.mrr + ".0" : values.mrr.toString())
              //   : 0,
              // current_close_date: values.current_close_date ? values.current_close_date : undefined,
            },
          });
        }}
      >
        {({ submitForm, values, setFieldValue, isSubmitting, isValid }: FormikProps<MyFormikProps>) => {
          console.log("values: ", values);
          console.log("isValid: ", isValid);
          console.log("isSubmitting: ", isSubmitting);

          const requiredFields = organizationData?.fetchOrganization
            ? organizationData.fetchOrganization
            : {
                make_sale_first_name_required: false,
                make_sale_last_name_required: false,
                make_sale_business_name_require: false,
                make_sale_primary_phone_number_required: false,
                make_sale_primary_email_required: false,
                make_sale_country_required: false,
                make_sale_address_required: false,
                make_sale_city_required: false,
                make_sale_state_required: false,
                make_sale_zip_required: false,
              };

          const RequiredSpan = () => {
            return <span style={{ color: "red" }}>*</span>;
          };

          return (
            <>
              <FormContainerDiv>
                <div>
                  <PopupInputLabel>
                    Business Name{" "}
                    {requiredFields && requiredFields["make_sale_business_name_required"] ? <RequiredSpan /> : null}
                  </PopupInputLabel>

                  <NewInputField name="business_name" />
                </div>
                <div>
                  <PopupInputLabel>
                    First Name{" "}
                    {requiredFields && requiredFields["make_sale_first_name_required"] ? <RequiredSpan /> : null}{" "}
                  </PopupInputLabel>

                  <NewInputField name="first_name" />
                </div>
                <div>
                  <PopupInputLabel>
                    Last Name{" "}
                    {requiredFields && requiredFields["make_sale_last_name_required"] ? <RequiredSpan /> : null}{" "}
                  </PopupInputLabel>

                  <NewInputField name="last_name" />
                </div>
                <div>
                  <PopupInputLabel>
                    Primary Phone Number{" "}
                    {requiredFields && requiredFields["make_sale_primary_phone_number_required"] ? (
                      <RequiredSpan />
                    ) : null}{" "}
                  </PopupInputLabel>{" "}
                  <NumberExtensionDiv>
                    {!!values.country_code?.value?.code && (
                      <ExtensionText>+{values.country_code?.value.code}</ExtensionText>
                    )}

                    <NewInputField name="phone_number" type="tel" />
                  </NumberExtensionDiv>
                </div>
                <div>
                  <PopupInputLabel>
                    Primary Email{" "}
                    {requiredFields && requiredFields["make_sale_primary_email_required"] ? <RequiredSpan /> : null}{" "}
                  </PopupInputLabel>

                  <NewInputField name="email" />
                </div>
                <div>
                  <PopupInputLabel>
                    Country {requiredFields && requiredFields["make_sale_country_required"] ? <RequiredSpan /> : null}{" "}
                  </PopupInputLabel>
                  <NewSelectField
                    name="country_code"
                    options={defaultPlusExtraCountryOptions.map((country) => {
                      return {
                        label: country.label,
                        value: country,
                      };
                    })}
                  />
                </div>
                <div>
                  <PopupInputLabel>
                    Address {requiredFields && requiredFields["make_sale_address_required"] ? <RequiredSpan /> : null}{" "}
                  </PopupInputLabel>

                  <NewInputField name="address" />
                </div>
                <div>
                  <PopupInputLabel>
                    City {requiredFields && requiredFields["make_sale_city_required"] ? <RequiredSpan /> : null}{" "}
                  </PopupInputLabel>

                  <NewInputField name="city" />
                </div>
                <div>
                  <PopupInputLabel>
                    State/Province{" "}
                    {requiredFields && requiredFields["make_sale_state_required"] ? <RequiredSpan /> : null}{" "}
                  </PopupInputLabel>
                  <StateDropdown
                    callback={setFieldValue}
                    country_code={values.country_code?.value?.iso2}
                    current_value={values.state}
                  />
                </div>
                <div>
                  <PopupInputLabel>
                    Zip Code {requiredFields && requiredFields["make_sale_zip_required"] ? <RequiredSpan /> : null}{" "}
                  </PopupInputLabel>
                  <NewInputField name="zip" />
                </div>
                <div>
                  <PopupInputLabel>
                    Industry {requiredFields && requiredFields["make_sale_industry_required"] ? <RequiredSpan /> : null}{" "}
                  </PopupInputLabel>

                  <NewAppSelect
                    value={values.industry}
                    options={
                      // check to see if the current value is in the list of options
                      computedIndustryOptions
                    }
                    onChange={(e: any) => {
                      setFieldValue(`industry`, !!e?.value ? e?.value : "");
                      setFieldValue(`sub_industry`, "");
                    }}
                    isClearable
                  />
                </div>
                <div>
                  <PopupInputLabel>
                    Sub Industry{" "}
                    {requiredFields && requiredFields["make_sale_sub_industry_required"] ? <RequiredSpan /> : null}{" "}
                  </PopupInputLabel>
                  <NewSelectField
                    name="sub_industry"
                    options={returnSubIndustryOptions(values.industry, values.sub_industry)}
                    isClearable
                    placeholder="Select Sub Industry"
                  />
                </div>
                {showLeadSourceToUser?.showLeadSourceToUser && (
                  <div>
                    <PopupInputLabel>
                      Lead Source{" "}
                      {requiredFields && requiredFields["make_sale_lead_source_required"] ? <RequiredSpan /> : null}{" "}
                    </PopupInputLabel>
                    <NewSelectField
                      name="lead_source"
                      options={
                        leadSourceOptions?.fetchLeadSourceOptions
                          ? leadSourceOptions.fetchLeadSourceOptions
                              ?.map((ls: any) => {
                                return {
                                  label: ls.label,
                                  value: ls.label,
                                };
                              })
                              .concat({ label: "Select Lead Source", value: "" })
                          : []
                      }
                      value={
                        !!values.lead_source
                          ? {
                              label: values.lead_source,
                              value: values.lead_source,
                            }
                          : null
                      }
                      placeholder="Select Lead Source"
                      isClearable
                    />
                  </div>
                )}
              </FormContainerDiv>
              {isSubmitting ? (
                <Loading />
              ) : (
                <NewAppButton type="submit" size={"lg"} variant={"primary"} onClick={submitForm} disabled={!isValid}>
                  Confirm Details
                </NewAppButton>
              )}
            </>
          );
        }}
      </Formik>
    </FormContainer>
  );
};

const ExtensionText = styled(AppText)`
  margin: 15px;
  margin-top: 5px;
  height: 25px;
  font-size: 13px;
`;

const NumberExtensionDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormContainerDiv = styled.div`
  width: 100%;
  /* overflow-y: scroll; */
  /* justify-content: center; */
  margin-bottom: 48px;
  /* flex-grow: 2; */
  padding: 10px;
`;

const PopupInputLabel = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-left: 5px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin-bottom: 15px;
  margin-top: 40px;
`;

export { ConfirmLeadDetails };
