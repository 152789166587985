import styled from "styled-components";
import * as React from "react";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { AppText } from "../UI";
import { Modal } from "./Modal";
import { theme } from "../../utils/theme";
import { useMutation, useLazyQuery, gql, useQuery } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { appToast, errorToast, successToast } from "../../utils/toast";
import { PhoenixInputField } from "../Field/Phoenix";
import { PhoenixAppButton } from "../UI/Phoenix";
import { MixpanelActions } from "src/services/mixpanel";
import { useModalContext } from "src/utils/hooks";
import { updateLocalOrg, updateLocalUser } from "../Smart/DataWrapper";
import { loggedInUser } from "src/apollo/cache";
import { SkeletonBlock } from "../UI/SkeletonBlock";

interface MyFormikProps {
  brand_name: string;
  brand_id: string | undefined;
}

const FETCH_BRAND_INFO = gql`
  query FetchBrand($fetchBrandId: String!) {
    fetchBrand(id: $fetchBrandId) {
      id
      name
    }
  }
`;

const ADD_OR_EDIT_BRAND = gql`
  mutation CreateOrUpdateBrand($name: String!, $createOrUpdateBrandId: String) {
    createOrUpdateBrand(name: $name, id: $createOrUpdateBrandId) {
      created_at
      id
      name
    }
  }
`;

const brandNameSchema = Yup.object().shape({
  brand_name: Yup.string().required("Brand name is required").trim(),
  brand_id: Yup.string().nullable(),
});

const AddOrEditBrandModal = () => {
  const { showAddOrEditBrandModal, setShowAddOrEditBrandModal, addOrEditBrandModalData } = useModalContext();

  const { data: brandData, loading: fetchBrandLoading } = useQuery(FETCH_BRAND_INFO, {
    fetchPolicy: "network-only",
    skip: !addOrEditBrandModalData?.brand_id,
    variables: {
      fetchBrandId: addOrEditBrandModalData?.brand_id,
    },
    onError: (error) => {
      console.error("Error fetching brand", error);
      Sentry.captureException(error);
    },
  });

  const [createOrUpdateBrand, { loading: createOrUpdateBrandLoading }] = useMutation(ADD_OR_EDIT_BRAND, {
    onCompleted: (data) => {
      // update cache of loggedInUser with new brand name
      const updatedUser = loggedInUser();
      const updatedOrCreatedBrand = data.createOrUpdateBrand;
      const brandWasCreated = !addOrEditBrandModalData?.brand_id;

      if (updatedUser?.organization?.Brands) {
        let updatedBrands;

        if (brandWasCreated) {
          // For brand creation, add the new brand to the array
          updatedBrands = [...updatedUser.organization.Brands, updatedOrCreatedBrand];
        } else {
          // For brand update, replace the existing brand with updated data
          updatedBrands = updatedUser.organization.Brands.map((brand) =>
            brand.id === updatedOrCreatedBrand.id ? { ...brand, name: updatedOrCreatedBrand.name } : brand,
          );
        }

        // Update the local user cache with the new brands array
        updateLocalUser({
          ...updatedUser,
          organization: {
            ...updatedUser.organization,
            Brands: updatedBrands,
          },
        });
      }

      successToast(addOrEditBrandModalData?.brand_id ? "Brand updated successfully" : "Brand created successfully");
      setShowAddOrEditBrandModal(false);

      // Track event in Mixpanel
      MixpanelActions.track(addOrEditBrandModalData?.brand_id ? "Brand Edited" : "Brand Added");
    },
    onError: (error) => {
      console.error("Error creating or updating brand", error);
      Sentry.captureException(error);
      errorToast("Failed to save brand");
    },
  });

  const handleSubmit = (values: MyFormikProps) => {
    createOrUpdateBrand({
      variables: {
        name: values.brand_name,
        createOrUpdateBrandId: values.brand_id,
      },
    });
  };

  const isLoading = fetchBrandLoading && addOrEditBrandModalData?.brand_id;

  return (
    <Modal open={showAddOrEditBrandModal} onClose={() => setShowAddOrEditBrandModal(false)} popupAnimation>
      <Formik
        enableReinitialize
        initialValues={{
          brand_name: brandData?.fetchBrand?.name || "",
          brand_id: addOrEditBrandModalData?.brand_id,
        }}
        validationSchema={brandNameSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, handleSubmit }: FormikProps<MyFormikProps>) => {
          return (
            <>
              <ContentDiv>
                <PopupText1 style={{ marginBottom: "34px" }}>
                  {isLoading ? (
                    <SkeletonBlock width={120} height={22} borderRadius={4} />
                  ) : (
                    `${!!addOrEditBrandModalData?.brand_id ? "Edit Brand" : "Add Brand"}`
                  )}
                </PopupText1>

                {isLoading ? (
                  <>
                    <div style={{ alignSelf: "flex-start", marginBottom: "8px", marginLeft: "48px" }}>
                      <SkeletonBlock width={80} height={16} borderRadius={4} delayNumber={1} />
                    </div>
                    <SkeletonBlock width={380} height={40} borderRadius={4} delayNumber={2} />
                  </>
                ) : (
                  <PhoenixInputField
                    name="brand_name"
                    placeholder="Enter brand name"
                    value={values.brand_name}
                    onChange={(e: any) => setFieldValue("brand_name", e.target.value)}
                    displayNoContextText
                    width={380}
                    titleText="Brand Name"
                    titleTextSpacing={8}
                  />
                )}
              </ContentDiv>
              <ButtonDiv>
                {isLoading ? (
                  <>
                    <SkeletonBlock
                      width={100}
                      height={36}
                      borderRadius={4}
                      delayNumber={3}
                      lightPulseStateColor={theme.NEUTRAL100}
                      darkPulseStateColor={theme.NEUTRAL200}
                    />
                    <SkeletonBlock width={100} height={36} borderRadius={4} delayNumber={4} />
                  </>
                ) : (
                  <>
                    <PhoenixAppButton
                      buttonType="secondary"
                      variant="danger-outline"
                      onClick={() => setShowAddOrEditBrandModal(false)}
                      uppercase
                      buttonTextFontSize={10}
                      style={{ letterSpacing: "1px" }}
                    >
                      Cancel
                    </PhoenixAppButton>
                    <PhoenixAppButton
                      buttonType="secondary"
                      uppercase
                      buttonTextFontSize={10}
                      style={{ letterSpacing: "1px" }}
                      onClick={() => handleSubmit()}
                      disabled={createOrUpdateBrandLoading || fetchBrandLoading}
                    >
                      Continue
                    </PhoenixAppButton>
                  </>
                )}
              </ButtonDiv>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

const PopupText1 = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 476px;
  height: 240px;
  padding: 40px;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.border.neutral.secondary};
`;

export { AddOrEditBrandModal };
