import React from "react";
import styled from "styled-components";
import { FlexDiv, SkeletonBlock } from "../../../UI";
import { theme } from "../../../../utils/theme";

export const SMSSettingsTabSkeleton: React.FC = () => {
  return (
    <Container>
      {/* Header skeleton */}
      <HeaderContainer>
        <SkeletonBlock width={150} height={32} borderRadius={4} />
        <ButtonsContainer>
          <SkeletonBlock width={80} height={36} borderRadius={4} delayNumber={1} />
          <SkeletonBlock width={80} height={36} borderRadius={4} delayNumber={2} />
        </ButtonsContainer>
      </HeaderContainer>

      <PageContainer>
        {/* Title and description skeleton */}
        <SubTextDiv>
          <SkeletonBlock width={180} height={28} borderRadius={4} delayNumber={3} />
          <SkeletonBlock width={400} height={18} borderRadius={4} delayNumber={4} />
        </SubTextDiv>

        {/* Opt-In Message Section */}
        <SettingsSection>
          <SkeletonBlock width={200} height={16} borderRadius={4} delayNumber={5} />
          {/* Radio options */}
          {[...Array(7)].map((_, index) => (
            <RadioRowSkeleton key={`opt-in-${index}`} delayNumber={6 + index} />
          ))}
        </SettingsSection>

        {/* Opt-Out Message Section */}
        <SettingsSection>
          <SkeletonBlock width={200} height={16} borderRadius={4} delayNumber={13} />
          {/* Radio options */}
          {[...Array(7)].map((_, index) => (
            <RadioRowSkeleton key={`opt-out-${index}`} delayNumber={14 + index} />
          ))}
        </SettingsSection>

        {/* Order of Messaging Section */}
        <SettingsSection>
          <SkeletonBlock width={220} height={16} borderRadius={4} delayNumber={21} />
          {/* Draggable items */}
          {[...Array(3)].map((_, index) => (
            <DraggableRowSkeleton key={`order-${index}`} delayNumber={22 + index} />
          ))}
        </SettingsSection>
      </PageContainer>
    </Container>
  );
};

const RadioRowSkeleton: React.FC<{ delayNumber: number }> = ({ delayNumber }) => {
  return (
    <RadioRow>
      <SkeletonBlock width={20} height={20} borderRadius={10} delayNumber={delayNumber} />
      <SkeletonBlock width={300} height={16} borderRadius={4} delayNumber={delayNumber + 0.5} />
    </RadioRow>
  );
};

const DraggableRowSkeleton: React.FC<{ delayNumber: number }> = ({ delayNumber }) => {
  return (
    <DraggableDiv>
      <SkeletonBlock width={16} height={16} borderRadius={4} delayNumber={delayNumber} />
      <SkeletonBlock width={150} height={16} borderRadius={4} delayNumber={delayNumber + 0.5} />
    </DraggableDiv>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 24px;
`;

const SubTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SettingsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: auto;
  margin-bottom: 16px;
`;

const RadioRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DraggableDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;
