import { gql, useQuery } from "@apollo/client";
import { loggedInUser } from "src/apollo/cache";
import { VoicemailDrop } from "src/types/VoicemailTypes";
import { appToast } from "src/utils/toast";

export interface FetchVoicemailDropsExpectedResponse {
  fetchVoicemailDrops: VoicemailDrop[];
}

const FETCH_SAVED_VOICEMAIL_DROPS = gql`
  query FetchVoicemailDrops($userId: String) {
    fetchVoicemailDrops(user_id: $userId) {
      id
      bucket_key
      audio_url
      duration_seconds
      script_id
      organization_id
      user_id
      return_call_rate
      times_played
      created_at
      updated_at
      deleted_at
      title
      description
    }
  }
`;

const useFetchVoicemailDrops = () => {
  const { data, loading, error } = useQuery<FetchVoicemailDropsExpectedResponse>(FETCH_SAVED_VOICEMAIL_DROPS, {
    fetchPolicy: "no-cache",
    variables: {
      userId: loggedInUser().id,
    },
    onCompleted: (data) => {},
    onError: (error) => {
      console.log("error: ", error);
      appToast("Unable to fetch voicemail drops");
    },
  });

  return {
    data,
    loading,
    error,
  };
};

export default useFetchVoicemailDrops;
