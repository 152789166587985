import { gql, useMutation } from "@apollo/client";
import { FieldArray, Formik, FormikProps } from "formik";
import * as React from "react";
import { useContext } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { ModalContext } from "../../context/ModalContext";
import { SYSTEM_FIELD_CHAR_LIMIT } from "../../utils/format";
import { theme } from "../../utils/theme";
import { appToast, successToast } from "../../utils/toast";
import { PhoenixInputField } from "../Field/Phoenix";
import { AppText } from "../UI";
import { PhoenixAppButton, PhoenixIcon } from "../UI/Phoenix";
import { Modal } from "./Modal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { iconNewMoveRow, iconNewPlus } from "src/images";
import { trash } from "src/images/NewDesign";
import { InputField } from "../Field/InputField";

interface DisappearingDivProps {
  blinds: boolean;
  close: () => void;
}

const ADD_DISPOSITION_REASON_FIELD = gql`
  mutation createDispositionReason($disposition_type: DispositionGroup!, $reason: String!, $sub_reasons_options: [String]) {
    createDispositionReason(disposition_type: $disposition_type, reason: $reason, sub_reasons_options: $sub_reasons_options) {
      id
    }
  }
`;

const CreateDispositionReasonModal: React.FC<DisappearingDivProps> = ({ blinds, close }) => {
  interface MyFormikProps {
    disposition_type: "Not Interested" | "Not Qualified";
    reason: string;
    sub_reasons_options: string[];
  }

  const { dispositionReasonModalData } = useContext(ModalContext);

  const DispositionReasonSchema = Yup.object().shape({
    reason: Yup.string()
      .required("Field required!")
      .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
    disposition_type: Yup.string().required("Field required!"),
  });

  const [addDispositionReason, { loading: addLoading, error: addError }] = useMutation(ADD_DISPOSITION_REASON_FIELD, {
    onCompleted({ addDispositionReason }) {
      console.log("Add disposition reason: ", addDispositionReason);
      successToast(`Reason added`);
      close();
    },
    onError({ message }) {
      appToast(message);
      console.log("Error in addDispositionReason: ", message);
    },
    fetchPolicy: "no-cache",
    refetchQueries: ["fetchDispositionReasons"],
  });

  const mapDispositionToExpectedBEType = (dispositionType: string) => {
    switch (dispositionType) {
      case "Not Interested":
        return "NOT_INTERESTED";
      case "Not Qualified":
        return "NOT_QUALIFIED";
      default:
        return dispositionType;
    }
  };

  const onDragEnd = (result: any, setFieldValue: any, values: any) => {
    console.log("Result: ", result);
    if (!result.destination) {
      return;
    }

    const items = Array.from(values.options);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFieldValue("options", items);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        disposition_type: dispositionReasonModalData.disposition_type,
        reason: "",
        sub_reasons_options: [],
      }}
      validationSchema={DispositionReasonSchema}
      onSubmit={async (values) => {
        await addDispositionReason({
          variables: {
            disposition_type: mapDispositionToExpectedBEType(values.disposition_type),
            reason: values.reason,
            sub_reasons_options: values?.sub_reasons_options,
          },
        });
      }}
    >
      {({ submitForm, isSubmitting, isValid, dirty, errors, values, setFieldValue }: FormikProps<MyFormikProps>) => {
        return (
          <Modal open={blinds} onClose={close}>
            <ModalContent>
              <HeaderDiv>
                <AppText fontSize={16} fontWeight={600}>
                  Add {dispositionReasonModalData.disposition_type} Reason
                </AppText>
              </HeaderDiv>
              <MainDiv>
                <PopupInputLabel>
                  {dispositionReasonModalData.disposition_type} Reason<span style={{ color: "red" }}>*</span>
                </PopupInputLabel>
                <PhoenixInputField name="reason" />
                <DragDropContext onDragEnd={(result) => onDragEnd(result, setFieldValue, values)}>
                  <Droppable droppableId="droppable-sub-reasons">
                    {(provided, snapshot) => (
                      <FieldArray
                        name="sub_reasons_options"
                        render={({ push, remove }) => (
                          <div ref={provided.innerRef} {...provided.droppableProps}>
                            {values.sub_reasons_options?.map((option, index) => (
                              <Draggable key={index} draggableId={index.toString()} index={index}>
                                {(provided) => (
                                  <DropdownOptionContainer
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      position: "inherit",
                                    }}
                                  >
                                    <OptionTaskContainer>
                                      <PhoenixIcon svg={iconNewMoveRow} size={12} color={theme.NEUTRAL200} />
                                    </OptionTaskContainer>
                                    <PhoenixInputField name={`sub_reasons_options.${index}`} />
                                    <OptionTaskContainer>
                                      <PhoenixIcon
                                        variant="danger"
                                        svg={trash}
                                        size={16}
                                        pointer
                                        onClick={() => remove(index)}
                                      />
                                    </OptionTaskContainer>
                                  </DropdownOptionContainer>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}

                            <CenterDiv
                              style={{ cursor: "pointer", height: "45px", paddingBottom: "15px", maxWidth: "385px" }}
                              onClick={() => push("")}
                            >
                              <PhoenixIcon svg={iconNewPlus} size={16} color={theme.PRIMARY600} />
                              <AppText variant="primary" uppercase>
                                Add Sub-Reason Option
                              </AppText>
                            </CenterDiv>
                          </div>
                        )}
                      />
                    )}
                  </Droppable>
                </DragDropContext>
              </MainDiv>
              <SubmitDiv>
                <PhoenixAppButton type="button" onClick={close} buttonType="secondary" variant="danger-outline">
                  Cancel
                </PhoenixAppButton>
                <PhoenixAppButton
                  type="submit"
                  onClick={submitForm}
                  buttonType="secondary"
                  variant="brand"
                  disabled={addLoading || !isValid || isSubmitting}
                >
                  Save
                </PhoenixAppButton>
              </SubmitDiv>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};

// ... existing code ...

const ModalContent = styled.div`
  width: 456px;
  min-height: 398px;
`;

const HeaderDiv = styled.div`
  height: 56px;
  border-bottom: solid 1px ${theme.NEUTRAL200};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainDiv = styled.div`
  position: relative;
  padding: 40px;
  overflow: auto;
  width: 100%;
  min-height: 200px;
  max-height: 536px;
  overflow-y: auto;
  overflow-x: visible;
  z-index: 1;
`;

const SubmitDiv = styled.div`
  bottom: 0px;
  height: 72px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: solid 1px ${theme.NEUTRAL200};
`;

// ... existing code ...

const PopupInputLabel = styled(AppText)`
  margin-bottom: 8px;
`;

const OptionTaskContainer = styled.div`
  height: 40px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const DropdownOptionContainer = styled.div`
  z-index: 3;
  width: 200px;
  overflow: visible;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
`;
const CenterDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
`;

export { CreateDispositionReasonModal };
