import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { CallContext } from "src/context/CallContext";
import { AutoDialContext, NotificationsContext } from "src/context";
import { connectionToast } from "src/utils/toast";
import styled from "styled-components";
import { AppText } from "../UI";
import { theme } from "src/utils/theme";
import { wifi_alert, xIcon } from "src/images/NewDesign";
import { PhoenixIcon } from "../UI/Phoenix";
import { call_quality_good, call_quality_fair, call_quality_poor } from "src/images/NewDesign";
import { useFlags } from "launchdarkly-react-client-sdk";

// Add this interface at the top of the file
interface NetworkInformation extends EventTarget {
  readonly downlink: number;
  readonly effectiveType: string;
  readonly rtt: number;
  readonly saveData: boolean;
  readonly type: string;
  onchange: EventListener;
}

// Instead of extending Navigator directly, use declaration merging
declare global {
  interface Navigator {
    connection?: NetworkInformation | null;
    mozConnection?: NetworkInformation | null;
    webkitConnection?: NetworkInformation | null;
  }
}

const callColors = {
  good: theme.SUCCESS500,
  poor: theme.WARNING500,
  bad: theme.PILL_DARK_RED,
};

const networkWarningTooltips = {
  "high-rtt":
    "High round trip time (RTT) detected, which may cause audio delays. This can be caused by your or your prospect's poor connection.",
  "low-mos": "Overall network connection is poor, which may affect your call quality.",
  "high-jitter":
    "High jitter detected, which may cause audio crackling and choppy audio. This can be caused by your or your prospect's poor connection.",
  "high-packet-loss":
    "High packet loss detected, which may cause choppy audio or a dropped call. This can be caused by your or your prospect's poor connection.",
  "high-packets-lost-fraction":
    "Very high packet loss detected, which may cause choppy audio or a dropped call. This can be caused by your or your prospect's poor connection.",
  "low-bytes-received":
    "No data received for 3 seconds, which can affect call quality. This can be caused by your or your prospect's poor connection.",
  "low-bytes-sent":
    "No data received for 3 seconds, which can affect call quality. This can be caused by your or your prospect's poor connection.",
  "ice-connectivity-lost": "Connection was lost.",
  "": "Connection appears stable.",
};

const returnIcon = (currentTwilioWarning: string) => {
  switch (currentTwilioWarning) {
    case "high-packets-lost-fraction":
    case "ice-connectivity-lost":
      return call_quality_poor;
    case "high-rtt":
    case "low-mos":
    case "high-jitter":
    case "high-packet-loss":
    case "low-bytes-received":
    case "low-bytes-sent":
      return call_quality_fair;
    default:
      return call_quality_good;
  }
};

const ConnectionBanner = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const { networkQuality, setNetworkQuality } = useContext(NotificationsContext);
  const [pauseNotification, setPauseNotification] = useState(false);

  const { currentTwilioWarning, setCurrentTwilioWarning } = useContext(CallContext);
  const { dialTimer } = useContext(AutoDialContext);

  const { connectionWarnings } = useFlags();
  // let currentTwilioWarning = "high-packets-lost-fraction";
  // let currentTwilioWarning = "low-mos";

  useEffect(() => {
    // Network monitoring
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => {
      setIsOnline(false);
      setNetworkQuality("offline");
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Network Quality Monitoring
    const connection = navigator?.connection || navigator?.mozConnection || navigator?.webkitConnection;

    const updateNetworkQuality = () => {
      if (!connection) return;
      const { effectiveType, downlink, rtt } = connection;

      console.log("internet properties:: ", "effectiveType: ", effectiveType, ", downlink: ", downlink, ", rtt: ", rtt);

      if (effectiveType === "4g" && downlink > 2 && rtt <= 500) {
        setNetworkQuality("good");
      } else if ((effectiveType === "4g" && (downlink <= 2 || rtt > 500)) || (effectiveType === "3g" && rtt <= 750)) {
        setNetworkQuality("poor");
      } else {
        setNetworkQuality("very poor");
      }
    };

    if (connection) {
      connection.addEventListener("change", updateNetworkQuality);
      updateNetworkQuality();
    }

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      if (connection) connection.removeEventListener("change", updateNetworkQuality);
    };
  }, []);

  if (!connectionWarnings) {
    return <></>;
  }

  return (
    <>
      {connectionWarnings && (
        <>
          {!pauseNotification && ((!!networkQuality && networkQuality !== "good") || !!currentTwilioWarning) && (
            <>
              <TimerCountdownDiv
                dialTimer={dialTimer}
                warning={
                  currentTwilioWarning === "high-packets-lost-fraction" ||
                  currentTwilioWarning === "ice-connectivity-lost" ||
                  networkQuality === "very poor"
                }
              >
                <div />
                <div>
                  {!!currentTwilioWarning ? (
                    <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                      <div style={{ width: 48, height: 1 }} />
                      <img
                        src={returnIcon(currentTwilioWarning)}
                        alt="Call network quality indicator"
                        style={{ width: 20, height: 20, cursor: "info" }}
                      />
                      <TimerText>
                        {networkWarningTooltips[currentTwilioWarning as keyof typeof networkWarningTooltips]} If on your
                        side, click{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://support.sellfire.com/all-network-requirements-recommendations-troubleshooting#:~:text=Troubleshooting%20connection%20issues`}
                        >
                          here
                        </a>{" "}
                        for troubleshooting steps.
                      </TimerText>
                    </div>
                  ) : (
                    <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                      <div style={{ width: 48, height: 1 }} />
                      <PhoenixIcon
                        svg={wifi_alert}
                        size={20}
                        color={networkQuality === "very poor" ? theme.DANGER500 : theme.WARNING500}
                      />
                      <TimerText>
                        Your internet connection is {networkQuality}, which may affect your call quality and data
                        loading. Click{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://support.sellfire.com/all-network-requirements-recommendations-troubleshooting#:~:text=Troubleshooting%20connection%20issues`}
                        >
                          here
                        </a>{" "}
                        for troubleshooting steps.
                      </TimerText>
                    </div>
                  )}
                </div>
                <PhoenixIcon
                  pointer={true}
                  hoverColor={theme.PILL_DARK_RED}
                  variant="white"
                  size={20}
                  color={theme.BLACK_COLOR}
                  svg={xIcon}
                  onClick={() => {
                    setPauseNotification(true);
                    // Pause for 15 minutes
                    // setTimeout(() => {
                    //   setPauseNotification(false);
                    // }, 15 * 60 * 1000);
                  }}
                />
              </TimerCountdownDiv>
              {/* Top padding for when the banner is open */}
              <div style={{ height: "34px" }} />
            </>
          )}
          {pauseNotification && ((!!networkQuality && networkQuality !== "good") || !!currentTwilioWarning) && (
            <>
              <TimerCountdownDivSmall
                dialTimer={dialTimer}
                warning={
                  currentTwilioWarning === "high-packets-lost-fraction" ||
                  currentTwilioWarning === "ice-connectivity-lost" ||
                  networkQuality === "very poor"
                }
                onClick={() => {
                  setPauseNotification(false);
                }}
              >
                {!!currentTwilioWarning ? (
                  <img
                    src={returnIcon(currentTwilioWarning)}
                    alt="Call network quality indicator"
                    style={{ width: 20, height: 20, cursor: "pointer" }}
                  />
                ) : (
                  <PhoenixIcon
                    pointer={true}
                    svg={wifi_alert}
                    size={16}
                    color={networkQuality === "very poor" ? theme.DANGER500 : theme.WARNING500}
                  />
                )}
              </TimerCountdownDivSmall>
            </>
          )}
        </>
      )}
    </>
  );
};

const TimerText = styled(AppText)`
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.2px;
  text-align: center;
  color: ${theme.BLACK_COLOR};
  transition: color 300ms ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100vw - 180px);
  max-height: 21px;
`;

interface TimerCountdownDivProps {
  warning: boolean;
  dialTimer: number;
}

const TimerCountdownDiv = styled.div<TimerCountdownDivProps>`
  position: absolute;
  left: 0px;
  right: 0px;
  top: ${({ dialTimer }) => (dialTimer ? "48px" : "0px")};
  width: 100%;
  max-width: 100%;
  height: 32px;
  max-height: 32px;
  min-height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  background: ${({ warning }) => (warning ? theme.DANGER200 : theme.WARNING200)};
  transition: all 300ms ease-in-out;
  padding: 0px 24px 0px 24px;
`;

const TimerCountdownDivSmall = styled.div<TimerCountdownDivProps>`
  position: absolute;
  right: 0px;
  top: ${({ dialTimer }) => (dialTimer ? "52px" : "4px")};
  width: 32px;
  max-width: 32px;
  height: 32px;
  max-height: 32px;
  min-height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  background: ${({ warning }) => (warning ? theme.DANGER200 : theme.WARNING200)};
  transition: all 300ms ease-in-out;
  padding: 4px;
  padding-left: 8px;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  cursor: pointer;
  opacity: 0.8;
`;

export default ConnectionBanner;
