import * as React from "react";

import { phone_white } from "../../../../images/NewDesign";

import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useContext } from "react";
import { ModalContext } from "src/context/ModalContext";
import styled from "styled-components";
import { CallContext, LeadCardContext } from "../../../../context";
import { CurrentLeadType } from "../../../../types";
import {
  EMAIL_UNSUBSCRIBED_REP_TOOLTIP,
  getMeetingIdFromZoomUrl,
  SMS_UNSUBSCRIBED_REP_TOOLTIP,
} from "../../../../utils/format";
import { ON_DIAL_WARNING_TOOLTIP } from "../../../../utils/misc";
import { appToast, errorToast } from "../../../../utils/toast";
import { AppText } from "../../../UI";
import { PhoenixAppButton } from "../../../UI/Phoenix";
import { CUSTOM_TASK_ACK } from "./LeadCardV2";
interface LeadCardCTAButtonProps {
  inCustomSequence: boolean;
  scheduledEventIsWithAssociatedLead: boolean;
  dataLead: {
    fetchLead: CurrentLeadType;
  };
  isOwnedByRep: boolean;
  inCallableStatus: boolean;
  dataAssociatedLeads: {
    fetchAssociateContact: CurrentLeadType[];
  };
  computedLeadData: { [key: string]: any };
  goToCallObjectForPrimary: any;
  activeCall: boolean;
  closeAllModals: () => void;
  scheduledEventLeadId: string;
  scheduledEventAssociateLeadIntentId: string;
  isNextDial?: boolean;
  dialAsNumber?: string;
  setSelfSource: (source: boolean) => void;
  expectedAction: string;
  leadUnsubscribedFromEmail: boolean;
  showEmailEditorModal: boolean;
  openEmailEditorModal: ({
    lead_id,
    full_name,
    email,
    intent,
    isManualEmailSequenceStep,
    sequenceStepId,
  }: {
    lead_id: string;
    full_name: string;
    email: string;
    intent: any;
    isManualEmailSequenceStep: boolean;
    sequenceStepId: string;
  }) => void;
  showSMSChat: boolean;
  openSMSChat: ({ lead_id, phone_number, intent }: { lead_id: string; phone_number: string; intent: any }) => void;
  leadUnsubscribedFromSMS: boolean;
  intentData: {
    schedule_item: {
      google_space_sellfire_id: string;
      google_space_info: {
        id: string;
        meeting_url: string;
        meeting_code: string;
      };
      zoom_meeting_sellfire_id: string;
      zoom_meeting_info: {
        id: string;
        meeting_url: string;
        meeting_id: string;
      };
    };
    [key: string]: any;
  };
  sequenceStep: { [key: string]: any };
}
const LeadCardCTAButton = ({
  inCustomSequence = false,
  scheduledEventIsWithAssociatedLead,
  dataLead,
  isOwnedByRep,
  inCallableStatus,
  dataAssociatedLeads,
  computedLeadData,
  goToCallObjectForPrimary,
  activeCall,
  closeAllModals,
  scheduledEventLeadId,
  scheduledEventAssociateLeadIntentId,
  isNextDial,
  dialAsNumber,
  setSelfSource,
  expectedAction = "manualCall",
  leadUnsubscribedFromEmail,
  showEmailEditorModal,
  openEmailEditorModal,
  showSMSChat,
  openSMSChat,
  leadUnsubscribedFromSMS,
  intentData,
  sequenceStep,
}: LeadCardCTAButtonProps) => {
  console.log("intentData: ", intentData);
  const { callState, goToCall, resetNextDial, sendDigit, addOTFVideoMeeting } = useContext(CallContext);
  const { leadInNextDial, upcomingDials, setUpcomingDials } = useContext(LeadCardContext);

  const {
    setJoinGoogleMeetModalData,
    setShowJoinGoogleMeetModal,
    setJoinZoomModalData,
    setShowJoinZoomModal,
  } = useContext(ModalContext);

  console.log("LeadCardCTAButton: ", {
    expectedAction,
    computedLeadData,
    inCustomSequence,
    sequenceStep,
  });

  const [customTaskAck, { loading: loadingCompleteTask }] = useMutation(CUSTOM_TASK_ACK, {
    onCompleted({ customTaskAck }) {
      // remove from active queue

      const filteredActive = upcomingDials.slice().filter((item: any) => item?.id !== intentData?.id);

      setUpcomingDials(filteredActive);
    },
    onError({ message }) {
      errorToast(message);
      Sentry.captureEvent({
        message: `Error marking custom task. GraphQL Error: ${message}`,
      });
      console.log("Error marking custom task: ", message);
    },
    refetchQueries: ["fetchFilteredLeadsInQueueV2"],
  });

  const convertZoomMeetingURLToBrowserURL = (zoomMeetingID: string) => {
    // example: https://app.zoom.us/wc/93532118069/start?fromPWA=1
    return `https://app.zoom.us/wc/${zoomMeetingID}/start?fromPWA=1`;
  };

  if (expectedAction === "zoomMeeting") {
    return (
      <CTATooltipWrapperDiv>
        <PhoenixAppButton
          variant="brand"
          uppercase
          buttonType="primary"
          height={40}
          onClick={() => {
            setJoinZoomModalData({
              meetingLink: intentData?.schedule_item?.zoom_meeting_info?.meeting_url,
              dialInToMeeting: ({
                newPhoneNumber,
                newParticipantId,
              }: {
                newPhoneNumber: string;
                newParticipantId: string;
              }) => {
                appToast("Dialing into Zoom...");
                goToCall({
                  ...goToCallObjectForPrimary,
                  phoneNumber: newPhoneNumber,
                  presetDigitsToSend: [
                    getMeetingIdFromZoomUrl(intentData?.schedule_item?.zoom_meeting_info?.meeting_url) ?? "",
                    "#",
                    newParticipantId,
                    "#",
                  ],
                  zoom_meeting_sellfire_id: intentData?.schedule_item?.zoom_meeting_sellfire_id,
                });

                // add the OTF video meeting to the lead so that the OTF meeting button can be shown on the lead card
                addOTFVideoMeeting({
                  leadId: dataLead?.fetchLead?.id,
                  OTFVideoMeeting: {
                    meeting_type: "Zoom",
                    meeting_id: getMeetingIdFromZoomUrl(intentData?.schedule_item?.zoom_meeting_info?.meeting_url),
                    sellfire_meeting_id: intentData?.schedule_item?.zoom_meeting_sellfire_id,
                    meeting_url: convertZoomMeetingURLToBrowserURL(
                      intentData?.schedule_item?.zoom_meeting_info?.meeting_id,
                    ),
                    created_at: new Date().toISOString(),
                  },
                });
              },
            });
            setShowJoinZoomModal(true);
          }}
        >
          Start Scheduled Meeting
        </PhoenixAppButton>
      </CTATooltipWrapperDiv>
    );
  }

  if (expectedAction === "googleMeetMeeting") {
    return (
      <CTATooltipWrapperDiv>
        <PhoenixAppButton
          variant="brand"
          uppercase
          buttonType="primary"
          height={40}
          onClick={() => {
            console.log("intentData: ", intentData?.schedule_item?.google_space_info);
            setJoinGoogleMeetModalData({
              meetingLink: intentData?.schedule_item?.google_space_info?.meeting_url,
              dialInToMeeting: ({ newPhoneNumber, newPin }: { newPhoneNumber: string; newPin: string }) => {
                appToast("Dialing into Google Meet...");
                goToCall({
                  ...goToCallObjectForPrimary,
                  phoneNumber: newPhoneNumber,
                  presetDigitsToSend: [newPin, "#"],
                  google_space_sellfire_id: intentData?.schedule_item?.google_space_sellfire_id,
                });

                // add the OTF video meeting to the lead so that the OTF meeting button can be shown on the lead card
                addOTFVideoMeeting({
                  leadId: dataLead?.fetchLead?.id,
                  OTFVideoMeeting: {
                    meeting_type: "Google",
                    meeting_code: intentData?.schedule_item?.google_space_info?.meeting_code,
                    sellfire_meeting_id: intentData?.schedule_item?.google_space_sellfire_id,
                    meeting_url: intentData?.schedule_item?.google_space_info?.meeting_url,
                    created_at: new Date().toISOString(),
                  },
                });
              },
            });
            setShowJoinGoogleMeetModal(true);
          }}
        >
          Start Scheduled Meeting
        </PhoenixAppButton>
      </CTATooltipWrapperDiv>
    );
  }

  if (!inCustomSequence || expectedAction === "manualCall") {
    return (
      <>
        {scheduledEventIsWithAssociatedLead ? (
          <TooltipWrapperDiv
            data-tip={!!callState ? ON_DIAL_WARNING_TOOLTIP : ""}
            data-for={!!callState ? "lead-card-tooltip" : ""}
          >
            <PhoenixAppButton
              variant="brand"
              uppercase
              buttonType="primary"
              height={40}
              disabled={
                !dataLead?.fetchLead?.primary_phone_number ||
                !isOwnedByRep ||
                !inCallableStatus ||
                !!callState ||
                !!activeCall
              }
              onClick={() => {
                closeAllModals();

                goToCall({
                  phoneNumber:
                    dataAssociatedLeads?.fetchAssociateContact?.find(
                      (lead: CurrentLeadType) => lead?.id === scheduledEventLeadId,
                    )?.primary_phone_number ?? "",
                  // we need to pass in the associated lead id info here instead of the normal goToCallObject
                  // if the normal goToCallObject is passed in it will call correctly but the call will not be associated with the associated lead
                  lead_id: scheduledEventLeadId,
                  intentId:
                    scheduledEventAssociateLeadIntentId ||
                    computedLeadData?.next_intent_scheduled_or_unscheduled?.id ||
                    undefined,
                  dialAsNumber: isNextDial ? dialAsNumber : undefined,
                });
                setSelfSource(false);
                console.log("goToCall: ", {
                  phoneNumber: dataAssociatedLeads?.fetchAssociateContact?.find(
                    (lead: CurrentLeadType) => lead.id === scheduledEventLeadId,
                  )?.primary_phone_number,
                  // we need to pass in the associated lead id info here instead of the normal goToCallObject
                  // if the normal goToCallObject is passed in it will call correctly but the call will not be associated with the associated lead
                  lead_id: scheduledEventLeadId,
                  intentId:
                    scheduledEventAssociateLeadIntentId ||
                    computedLeadData?.next_intent_scheduled_or_unscheduled?.id ||
                    undefined,
                  dialAsNumber: isNextDial ? dialAsNumber : undefined,
                });
                resetNextDial();
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "10px",
                textTransform: "uppercase",
              }}
            >
              <img src={phone_white} style={{ width: 13, height: 13, marginRight: 8 }} />
              CALL{" "}
              {
                dataAssociatedLeads?.fetchAssociateContact?.find(
                  (lead: CurrentLeadType) => lead.id === scheduledEventLeadId,
                )?.full_name
              }
            </PhoenixAppButton>
          </TooltipWrapperDiv>
        ) : (
          <TooltipWrapperDiv
            data-tip={!!callState ? ON_DIAL_WARNING_TOOLTIP : ""}
            data-for={!!callState ? "lead-card-tooltip" : ""}
          >
            <PhoenixAppButton
              variant="brand"
              uppercase
              buttonType="primary"
              height={40}
              buttonTextFontSize={10}
              disabled={
                !dataLead?.fetchLead?.primary_phone_number ||
                !isOwnedByRep ||
                !inCallableStatus ||
                !!callState ||
                !!activeCall
              }
              onClick={() => {
                closeAllModals();

                goToCall({
                  phoneNumber: dataLead?.fetchLead?.primary_phone_number,
                  ...goToCallObjectForPrimary,
                });
                setSelfSource(false);
                console.log("goToCall: ", {
                  phoneNumber: dataLead?.fetchLead?.primary_phone_number,
                  ...goToCallObjectForPrimary,
                });

                resetNextDial();
              }}
            >
              CALL PRIMARY
            </PhoenixAppButton>
          </TooltipWrapperDiv>
        )}
      </>
    );
  }

  // custom sequence checks

  if (expectedAction === "manualSms") {
    return (
      <>
        <CTATooltipWrapperDiv
          data-tip={leadUnsubscribedFromSMS ? SMS_UNSUBSCRIBED_REP_TOOLTIP : ""}
          data-for={"lead-card-tooltip"}
        >
          <PhoenixAppButton
            variant="brand"
            uppercase
            buttonType="primary"
            height={40}
            buttonTextFontSize={10}
            disabled={leadUnsubscribedFromSMS}
            onClick={() => {
              if (showSMSChat) {
                appToast("There is currently a chat open. Please close the existing chat before opening.");

                return;
              }

              openSMSChat({
                lead_id: dataLead?.fetchLead?.id ?? "",
                phone_number: dataLead?.fetchLead?.primary_phone_number ?? "",
                intent: intentData,
              });
            }}
          >
            Compose SMS
          </PhoenixAppButton>
        </CTATooltipWrapperDiv>
      </>
    );
  }

  if (expectedAction === "manualEmail") {
    return (
      <>
        <CTATooltipWrapperDiv
          data-tip={leadUnsubscribedFromEmail ? EMAIL_UNSUBSCRIBED_REP_TOOLTIP : ""}
          data-for={"lead-card-tooltip"}
        >
          <PhoenixAppButton
            variant="brand"
            uppercase
            buttonType="primary"
            height={40}
            disabled={leadUnsubscribedFromEmail}
            onClick={() => {
              !showEmailEditorModal &&
                openEmailEditorModal({
                  lead_id: dataLead?.fetchLead?.id ?? "",
                  full_name: dataLead?.fetchLead?.full_name,
                  email: dataLead?.fetchLead?.primary_email,
                  intent: intentData,
                  isManualEmailSequenceStep: dataLead?.fetchLead?.sequence_step?.actions?.[0]?.task === "manualEmail",
                  sequenceStepId: dataLead?.fetchLead?.sequence_step?.actions?.[0]?.id ?? "",
                });
            }}
          >
            Compose Email
          </PhoenixAppButton>
        </CTATooltipWrapperDiv>
      </>
    );
  }

  if (expectedAction === "customTask" || expectedAction === "requestManagerFeedback") {
    return (
      <CTATooltipWrapperDiv>
        <PhoenixAppButton
          variant="brand"
          uppercase
          buttonType="primary"
          height={40}
          disabled={!!loadingCompleteTask}
          onClick={() => {
            console.log("acknowledging suggested action was performed...");
            customTaskAck({
              variables: {
                customTaskAckInput: {
                  action_completed: true,
                  lead_intent_id: intentData?.id,
                  nextstep: false,
                  remove: false,
                  snooze: false,
                  step_action_id: dataLead?.fetchLead?.sequence_step?.actions?.[0]?.id,
                },
              },
            });
            if (isNextDial) {
              resetNextDial();
            }
          }}
        >
          {expectedAction === "customTask" ? "Mark Complete" : "Request Manager Feedback"}
        </PhoenixAppButton>
      </CTATooltipWrapperDiv>
    );
  }

  return (
    <AppText>Unable to find the suggested action for this sequence lead. Please contact this lead manually.</AppText>
  );
};

const TooltipWrapperDiv = styled.div`
  width: 100%;
`;

const CTATooltipWrapperDiv = styled.div`
  width: 100%;
`;

export { LeadCardCTAButton };
