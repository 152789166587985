import React, { useState, useRef, useEffect } from "react";
import moment, { Moment } from "moment";
import styled from "styled-components";
import { DayPickerRangeController, DayPickerRangeControllerShape } from "react-dates";
import { Portal } from "src/Components/Dumb";
import { PhoenixAppButton, PhoenixIcon, PhoenixInput } from "src/Components/UI/Phoenix";
import { theme } from "src/utils/theme";
import { useClickOutside } from "src/utils/hooks";
import PhoenixDateInput from "./PhoenixDateInput";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { chevron_left, chevron_right } from "src/images/NewDesign";

type PhoenixDateRangeProps = Partial<DayPickerRangeControllerShape> & {
  minWidth?: number;
  minHeight?: number;
  disabled?: boolean;
  lowerbound_date?: Moment | null;
  upperbound_date?: Moment | null;
  onDateChange?: (dates: { startDate: Moment | null; endDate: Moment | null }) => void;
  onClose?: (dates: { startDate: Moment | null; endDate: Moment | null }) => void;
  keepOpenOnDateSelect?: boolean;
  style?: any;
  min_date?: Moment | null;
  max_date?: Moment | null;
};

export const PhoenixDateRange: React.FC<PhoenixDateRangeProps> = ({
  lowerbound_date = null,
  upperbound_date = null,
  onDateChange,
  onClose,
  disabled,
  keepOpenOnDateSelect,
  min_date,
  max_date,
  minWidth = 196,
  minHeight = 40,
  style,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [dates, setDates] = useState<{ startDate: Moment | null; endDate: Moment | null }>({
    startDate: lowerbound_date,
    endDate: upperbound_date,
  });
  const [focusedInput, setFocusedInput] = useState<"startDate" | "endDate" | null>("startDate");
  const [tempDates, setTempDates] = useState<{
    startDate: string;
    endDate: string;
    isStartValid: boolean;
    isEndValid: boolean;
  }>({
    startDate: "",
    endDate: "",
    isStartValid: false,
    isEndValid: false,
  });
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const calendarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDates((prev) => {
      const startDateChanged = lowerbound_date?.format("YYYY-MM-DD") !== prev.startDate?.format("YYYY-MM-DD");
      const endDateChanged = upperbound_date?.format("YYYY-MM-DD") !== prev.endDate?.format("YYYY-MM-DD");

      if (!startDateChanged && !endDateChanged) return prev;

      return {
        startDate: startDateChanged ? lowerbound_date : prev.startDate,
        endDate: endDateChanged ? upperbound_date : prev.endDate,
      };
    });
  }, [lowerbound_date, upperbound_date]);

  useEffect(() => {
    if (isOpen && wrapperRef.current) {
      const inputRect = wrapperRef.current.getBoundingClientRect();
      const calendarWidth = 700; // Approximate width of the calendar
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Start with input position, but ensure it's not negative
      let left = Math.max(0, inputRect.left);

      // If calendar would overflow right edge, align to right
      if (left + calendarWidth > viewportWidth) {
        left = Math.max(0, viewportWidth - calendarWidth - 16); // 16px padding from edge
      }

      // Calculate top position, ensuring it's not negative
      let top = Math.max(0, inputRect.bottom + window.scrollY + 8);

      // If calendar would overflow bottom, position above input
      if (top + 500 > viewportHeight + window.scrollY) {
        // 500 is approximate calendar height
        top = Math.max(0, inputRect.top + window.scrollY - 500 - 8);
      }

      setPosition({ top, left });
    }
  }, [isOpen]);

  useClickOutside([wrapperRef, calendarRef], () => {
    setIsOpen(false);
    onClose?.(dates);
  });

  const handleInputClick = () => {
    if (!disabled) {
      setIsOpen(true);
      setFocusedInput("startDate");
    }
  };

  const isDayBlocked = (date: Moment) => {
    if (max_date) {
      return date.unix() > max_date.unix();
    }
    if (min_date) {
      return date.unix() < min_date.unix();
    }
    return false;
  };

  const handleApply = () => {
    if (tempDates.isStartValid && tempDates.isEndValid) {
      const computedDates = {
        startDate: moment(tempDates?.startDate, "MM/DD/YYYY"),
        endDate: moment(tempDates?.endDate, "MM/DD/YYYY"),
      };
      setDates(computedDates);
      onDateChange?.(computedDates);
      onClose?.(computedDates);
      setIsOpen(false);
    }
  };

  const handleGoToToday = () => {
    const today = moment();
    setDates({ startDate: today, endDate: today });
    setCurrentMonth(today);
  };

  const displayValue =
    dates?.startDate && dates?.endDate
      ? `${dates?.startDate?.format("MMM D, YYYY")} — ${dates?.endDate?.format("MMM D, YYYY")}`
      : "";

  const validateEndDate = (startDate: string, endDate: string): boolean => {
    if (!startDate || !endDate) return true;
    const start = moment(startDate, "MM/DD/YYYY", true);
    const end = moment(endDate, "MM/DD/YYYY", true);
    return start.isValid() && end.isValid() && end.isSameOrAfter(start);
  };

  const handleStartDateChange = (value: string, isValid: boolean) => {
    setTempDates((prev) => {
      const newState = {
        ...prev,
        startDate: value,
        isStartValid: isValid,
      };
      if (prev.endDate && isValid) {
        newState.isEndValid = validateEndDate(value, prev.endDate);
      }
      return newState;
    });
  };

  const handleEndDateChange = (value: string, isValid: boolean) => {
    setTempDates((prev) => ({
      ...prev,
      endDate: value,
      isEndValid: isValid && validateEndDate(prev.startDate, value),
    }));
  };

  return (
    <Wrapper ref={wrapperRef} className="date-picker-wrapper" minWidth={minWidth} minHeight={minHeight} style={style}>
      <PhoenixInput
        value={displayValue}
        onClick={handleInputClick}
        readOnly
        placeholder="Select date range"
        className="date-picker-input"
        disabled={disabled}
      />
      {isOpen && (
        <Portal>
          <CalendarContainer
            className="date-picker-portal"
            ref={calendarRef}
            style={{
              position: "absolute",
              top: position.top,
              left: position.left,
            }}
          >
            <DayPickerRangeController
              startDate={dates?.startDate}
              endDate={dates?.endDate}
              onDatesChange={({ startDate, endDate }) => {
                const computedDates = { startDate: startDate, endDate: endDate ?? startDate };
                setDates(computedDates);
                onDateChange?.(computedDates);
                if (keepOpenOnDateSelect === false && startDate && endDate) {
                  setIsOpen(false);
                }
              }}
              focusedInput={focusedInput}
              onFocusChange={(newFocusedInput) => setFocusedInput(newFocusedInput || "startDate")}
              numberOfMonths={2}
              hideKeyboardShortcutsPanel
              noBorder
              isOutsideRange={isDayBlocked}
              daySize={36}
              initialVisibleMonth={() => currentMonth}
              minimumNights={0}
              navNext={<PhoenixIcon svg={chevron_right} />}
              navPrev={<PhoenixIcon svg={chevron_left} />}
              isDayBlocked={isDayBlocked}
              {...props}
            />
            <BottomBar>
              <PhoenixAppButton buttonType="ghost-small" onClick={handleGoToToday}>
                GO TO TODAY
              </PhoenixAppButton>
              <DateRange>
                <PhoenixDateInput
                  value={dates?.startDate ? dates?.startDate.format("MM/DD/YYYY") : ""}
                  onChange={handleStartDateChange}
                />
                <span>—</span>
                <PhoenixDateInput
                  value={dates?.endDate ? dates?.endDate.format("MM/DD/YYYY") : ""}
                  onChange={handleEndDateChange}
                />
                <PhoenixAppButton
                  height={40}
                  buttonType="primary"
                  variant="brand"
                  onClick={handleApply}
                  disabled={!tempDates?.isStartValid || !tempDates?.isEndValid}
                >
                  APPLY
                </PhoenixAppButton>
              </DateRange>
            </BottomBar>
          </CalendarContainer>
        </Portal>
      )}
    </Wrapper>
  );
};

export default PhoenixDateRange;

const Wrapper = styled.div<{ minWidth: number; minHeight: number }>`
  min-height: ${({ minHeight }) => minHeight}px;
  min-width: ${({ minWidth }) => minWidth}px;
  position: relative;
`;

const CalendarContainer = styled.div`
  animation: ${theme.popup} 0.25s ease-in-out forwards;
  background: ${theme.WHITE_COLOR};
  border-radius: 8px;
  border: 1px solid ${theme.NEUTRAL300};
  overflow: hidden;
  width: fit-content;
  z-index: 1000;

  .DayPicker {
    margin: 0;
    position: relative;
  }

  .DateRangePicker,
  .DateRangePickerInput {
    display: none;
  }

  .DayPicker__hidden {
    visibility: visible;
  }

  .DayPicker__horizontal {
    margin: 0;
    box-shadow: none;
    background: ${theme.WHITE_COLOR};
  }

  .DayPicker_portal {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .DayPicker_weekHeader {
    color: ${theme.NEUTRAL400};
    top: 50px;
  }

  .CalendarMonth {
    padding: 0 20px;
  }

  .CalendarMonth_caption {
    padding-top: 20px;
    padding-bottom: 36px;
    font-size: 16px;
    font-weight: 600;
    color: ${theme.text.neutral.primary};
    text-align: center;

    strong {
      font-weight: 400;
    }
  }

  .DayPicker_weekHeader_li {
    font-size: 12px;
    font-weight: 500;
    padding: 0;
    color: ${theme.NEUTRAL400};
  }

  .CalendarDay {
    font-size: 14px;
    border: none;
    vertical-align: middle;
    color: ${theme.text.neutral.primary};
    padding: 0;
    border-radius: 4px;
    border: 2px solid transparent;
  }

  .CalendarDay__default:hover {
    background: ${theme.PRIMARY100};
    border-radius: 4px;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${theme.PRIMARY500};
    border-radius: 4px;
    color: white;
  }

  .CalendarDay__selected_span {
    background: ${theme.PRIMARY100};
    color: ${theme.PRIMARY500};
    border-radius: 4px;
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: #c8e5ff;
    color: ${theme.text.neutral.primary};
    border-radius: 4px;
  }

  .DayPickerNavigation_button {
    border: none;
    top: 17px;

    svg {
      height: 24px;
      width: 24px;
    }
  }

  .DayPickerNavigation_button__horizontal {
    position: absolute;
    &:first-of-type {
      left: 22px;
    }
    &:last-of-type {
      right: 22px;
    }
  }

  .DateRangePickerInput_clearDates {
    display: none;
  }

  .DayPickerKeyboardShortcuts_buttonReset {
    display: none;
  }

  .CalendarMonthGrid {
    background: none;
  }

  .CalendarMonth_table {
    margin-top: 0;
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span {
    background: ${theme.PRIMARY100};
    color: ${theme.PRIMARY500};
    border-radius: 4px;

    &:hover {
      background: ${theme.PRIMARY100};
      color: ${theme.PRIMARY500};
    }
  }

  .CalendarDay__selected {
    background: ${theme.PRIMARY500};
    border-radius: 4px;
    color: white;

    &:hover {
      background: ${theme.PRIMARY500};
      color: white;
    }
  }
`;

const BottomBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background: ${theme.PRIMARY100};
  border-top: 1px solid ${theme.NEUTRAL200};
`;

const DateRange = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    color: ${theme.NEUTRAL300};
  }
`;
