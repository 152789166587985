import styled from "styled-components";
import * as React from "react";
import { Dispatch } from "react";
import { SetStateAction } from "react";
import { flipNameFormat } from "../../utils/format";
import { BsArrowRightShort } from "react-icons/bs";
import { theme } from "../../utils/theme";

interface LeadPoolSearchItemProps {
  full_name: string;
  business_name: string;
  dataItem: any;
  drillDownFunction: (item: any) => void;
  setSearchState: Dispatch<SetStateAction<boolean>>;
  inputRef: any;
}

const LeadPoolSearchItem: React.FC<LeadPoolSearchItemProps> = ({
  full_name,
  business_name,
  drillDownFunction,
  dataItem,
  setSearchState,
  inputRef,
}) => {
  const handleViewClick = (item: any) => {
    drillDownFunction(item);
    setSearchState(false);
    if (inputRef) inputRef.current?.blur();
  };

  // Map lead item to match table values.
  const newDataItem = {
    ...dataItem,
    most_recent_disposition: {
      __typename: "LeadActivity",
      related_disposition: {
        __typename: "Disposition",
        label: dataItem?.last_disposition_label ?? "",
      },
    },
    user: {
      __typename: "User",
      full_name: dataItem?.rep?.full_name ?? "",
    },
  };

  return (
    <LeadItem
      onMouseDown={(event) => event?.preventDefault()}
      onClick={() => {
        handleViewClick(newDataItem);
      }}
    >
      <NameDiv>
        <LeadName>{flipNameFormat(full_name)}</LeadName>
        <BusinessName>{business_name}</BusinessName>
      </NameDiv>
      <ArrowIcon>
        <BsArrowRightShort color={theme.PRIMARY500} height={10} />
      </ArrowIcon>
    </LeadItem>
  );
};

const NameDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeadItem = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  height: 48px;
  padding: 11px 12px 7px 11px;
  cursor: pointer;
  :hover {
    background-color: rgba(62, 101, 255, 0.1);
  }
`;

const LeadName = styled.div`
  grid-column-start: 1;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const BusinessName = styled.div`
  grid-column-start: 2;
  color: ${theme.NEUTRAL500};
  font-size: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ArrowIcon = styled.div`
  grid-column-start: 3;
  align-self: center;
  justify-self: center;
`;

export { LeadPoolSearchItem };
