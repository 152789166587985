import React, { useContext } from "react";
import { FaPlayCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { PhoenixStyledTooltip } from "src/Components/Dumb/PhoenixStyledTooltip";

import { ModalContext } from "src/context/ModalContext";
import { iconDownload, iconSave } from "src/images";
import { formatCallDuration } from "src/utils/format";
import { downloadResourceCors } from "src/utils/misc";

import { theme } from "src/utils/theme";
import styled from "styled-components";

export const CallReportActionCell = ({
  duration,
  recordingUrl,
  conferenceId,
  marginLeft = "12px",
}: {
  duration: number;
  recordingUrl: string;
  conferenceId: string;
  marginLeft?: string;
}) => {
  const { setSaveCallToLibraryModal, setCurrentConferenceID } = useContext(ModalContext);

  if (!duration || !conferenceId) {
    return null;
  }

  return (
    <FlexDiv>
      <ListItemDuration marginLeft={marginLeft}>
        <PhoenixStyledTooltip id="call-report-action-cell-tooltip" />

        <StyledLink to={`/call-report/${conferenceId}`}>
          <div style={{ display: "flex", height: "100%", paddingRight: "4px" }}>
            <CenterDiv>
              <FaPlayCircle size={10} color={theme.PRIMARY500} style={{ marginRight: "6px", marginLeft: "4px" }} />
            </CenterDiv>
            <CenterDiv>{formatCallDuration(duration ?? 0)}</CenterDiv>
          </div>
        </StyledLink>
      </ListItemDuration>
      <IconDiv>
        <img
          data-tip="Save"
          data-for="call-report-action-cell-tooltip"
          onClick={() => {
            setCurrentConferenceID(conferenceId);
            setSaveCallToLibraryModal(true);
          }}
          src={iconSave}
          alt="Save to Call Library"
        />
        {!!recordingUrl && (
          <div style={{ marginLeft: "2px" }} data-tip="Download" data-for="call-report-action-cell-tooltip">
            <img src={iconDownload} alt="Download" onClick={() => downloadResourceCors(recordingUrl)} />
          </div>
        )}
      </IconDiv>
    </FlexDiv>
  );
};

const IconDiv = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-end;
  align-items: center;
  font-size: 8px;
  line-height: 13px;
  color: ${theme.TERTIARY500};
  margin-left: 8px;
  /* cursor: pointer; */
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const ListItemDuration = styled.div<{ marginLeft?: string }>`
  border: solid 0.7px ${theme.PRIMARY500};
  border-radius: 2.1px;
  width: 74px;
  height: 17px;
  font-size: 10px;
  place-self: center;
  margin-left: ${(props) => props.marginLeft};
`;

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
