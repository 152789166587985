import styled from "styled-components";
import * as React from "react";
import { AppButton, AppText, AppInput, AppSelect, AppErrorText, Loading } from "../UI";
import { Modal } from "./Modal";
import { theme } from "../../utils/theme";
import * as Yup from "yup";
import * as Sentry from "@sentry/react";
import { useState, useContext } from "react";
import { NewAppButton } from "../UI/NewAppButton";
import { ModalContext } from "../../context";
import { newCloseModal } from "../../images";
import { useMutation, useQuery, useLazyQuery, gql } from "@apollo/client";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { appToast } from "../../utils/toast";
import { DateRangePicker } from "react-dates";
import { ReactDatesWrapper } from "../UI/ReactDatesWrapper";
import { downloadResourceAWS } from "../../utils/misc";
import { useFlags } from "launchdarkly-react-client-sdk";
interface DisappearingDivProps {
  selectedIntegration: string;
}

const optionsReason = [
  { value: "", label: "Select" },
  { value: "Bad Vertical", label: "Bad Vertical" },
  { value: "Not a Good Time to Call", label: "Not a Good Time to Call" },
  { value: "Missing Key Data", label: "Missing Key Data" },
  { value: "Bad Lead History", label: "Bad Lead History" },
  { value: "Other", label: "Other" },
];

const tableData = [
  { date: "3/21/2312", reason: "Unable to establish connection Unable to establish connection" },
  { date: "2/22/2012", reason: "Unable to establish connection" },
  { date: "12/21/2000", reason: "Unable to establish connection" },
];

const FETCH_INTEGRATION_ERROR = gql`
  query fetchIntegrationError($skip: Int!, $take: Int!, $integration_type: INTEGRATION_TYPE!) {
    fetchIntegrationError(skip: $skip, take: $take, integration_type: $integration_type) {
      id
      created_at
      integration_type
      error_message
    }
  }
`;

const REMOVE_INTEGRATION_ERROR_LOG = gql`
  mutation removeIntegrationErrorLog($error_id: String!) {
    removeIntegrationErrorLog(error_id: $error_id)
  }
`;

const CREATE_ERROR_LOG_CUSTOM_DATES = gql`
  mutation createIntegrationErrorReportCustomDates(
    $integration_type: INTEGRATION_TYPE!
    $start_date: DateTime
    $end_date: DateTime
    $send_url: Boolean!
    $send_email: Boolean!
  ) {
    createIntegrationErrorReportCustomDates(
      integration_type: $integration_type
      start_date: $start_date
      end_date: $end_date
      send_url: $send_url
      send_email: $send_email
    )
  }
`;

const ErrorLogReportModal: React.FC = () => {
  const { selectedIntegration } = useContext(ModalContext);
  const [skip, setSkip] = useState(0);
  const takeNumber = 13;
  const [errorsLog, setErrorsLog] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);

  // For react-dates
  const [dateStart, setDateStart] = useState(undefined as any);
  const [dateEnd, setDateEnd] = useState(undefined as any);
  const [focusedInput, setFocusedInput] = useState(null as any);

  const { data: dataErrors, loading: loadingErrors, error: errorErrors, refetch: fetchMoreErrors } = useQuery(
    FETCH_INTEGRATION_ERROR,
    {
      variables: {
        integration_type: selectedIntegration,
        skip: skip,
        take: takeNumber,
      },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
      onCompleted() {
        const newList = [...errorsLog, ...dataErrors.fetchIntegrationError].reduce(
          (acc, cv) => (acc?.map((a: any) => a?.id)?.includes(cv?.id) ? acc : [...acc, cv]),
          [],
        );
        if (dataErrors.fetchIntegrationError.length < takeNumber) {
          setHasMore(false);
        } else setHasMore(true);
        setErrorsLog(newList);
      },
    },
  );
  const [removeIntegrationErrorLog, { loading: loadingRemove, error: errorRemove }] = useMutation(
    REMOVE_INTEGRATION_ERROR_LOG,
    {
      async onCompleted({ removeIntegrationErrorLog }) {
        console.log("removeIntegrationErrorLog: ", removeIntegrationErrorLog);
        if (!removeIntegrationErrorLog) {
          appToast("Error deleting log item. Something went wrong.");
          return;
        }
        appToast("Error log item removed successfully!");
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `removeIntegrationErrorLog GraphQL Error: ${message}`,
        });
        console.log("Error in removeIntegrationErrorLog: ", message);
      },
      refetchQueries: ["fetchIntegrationError"],
    },
  );

  const [exportErrorLog, { loading: loadingExport, error: errorExxport }] = useMutation(CREATE_ERROR_LOG_CUSTOM_DATES, {
    async onCompleted({ createIntegrationErrorReportCustomDates }) {
      console.log("createIntegrationErrorReportCustomDates: ", createIntegrationErrorReportCustomDates);
      if (!createIntegrationErrorReportCustomDates) {
        appToast("Error exporting log item. Something went wrong.");
        return;
      }
      downloadResourceAWS(createIntegrationErrorReportCustomDates);
      appToast("Error log item exported successfully!");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `createIntegrationErrorReportCustomDates GraphQL Error: ${message}`,
      });
      console.log("Error in createIntegrationErrorReportCustomDates: ", message);
    },
  });

  const handleShowMore = () => {
    setSkip(skip + takeNumber);
    fetchMoreErrors({
      integration_type: selectedIntegration,
      skip: skip,
      take: takeNumber,
    });
  };

  return (
    <Sentry.ErrorBoundary fallback={"An error has in skip lead modal"}>
      <Modal open={false} onClose={() => {}} closeButtonSize={16}>
        <TitleDiv>
          <TitleText>Error Log</TitleText>
        </TitleDiv>
        <PaddingDiv>
          <ScrollDiv id="error-log-div">
            <InfiniteScroll
              dataLength={errorsLog.length}
              next={handleShowMore}
              hasMore={hasMore}
              loader={<Loading />}
              scrollableTarget="error-log-div"
            >
              <GridTable>
                <thead>
                  <tr>
                    <GridTH>
                      <GridTitleText>Date</GridTitleText>
                    </GridTH>
                    <GridTH>
                      <GridTitleText>Reason</GridTitleText>
                    </GridTH>
                    <GridTH>
                      <GridTitleText>Action</GridTitleText>
                    </GridTH>
                  </tr>
                </thead>
                <tbody>
                  {errorErrors ? (
                    <AppErrorText>Error loading log</AppErrorText>
                  ) : (
                    // !!dataErrors.fetchIntegrationError &&
                    // !!dataErrors.fetchIntegrationError.length &&
                    errorsLog?.map((item: any) => (
                      <tr>
                        <GridTD style={{ width: "140px" }}>
                          <GridCellText>
                            {!!item.created_at && moment(item.created_at).format("MM/DD/YYYY")}
                          </GridCellText>
                        </GridTD>
                        <GridTD>
                          <GridCellText>{item.error_message}</GridCellText>
                        </GridTD>
                        <GridTD style={{ width: "120px" }}>
                          <GridCellClickText
                            onClick={async () => {
                              await removeIntegrationErrorLog({
                                variables: {
                                  error_id: item?.id,
                                },
                              });
                              const newList = errorsLog.slice().filter((e: any) => e?.id !== item?.id);
                              setErrorsLog(newList);
                            }}
                          >
                            Clear Error
                          </GridCellClickText>
                        </GridTD>
                      </tr>
                    ))
                  )}
                </tbody>
              </GridTable>
            </InfiniteScroll>
          </ScrollDiv>
        </PaddingDiv>

        <SubmitDiv>
          <ConfirmButton size="sm" variant={"primary"} onClick={() => {}}>
            Done
          </ConfirmButton>
        </SubmitDiv>
      </Modal>
    </Sentry.ErrorBoundary>
  );
};

const ScrollDiv = styled.div`
  overflow: auto;
  /* height: 520px; */
  height: 520px;
  max-height: 100%;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 25px;
  top: 7px;
  cursor: pointer;
  z-index: 5;
`;

const GridTitleText = styled(AppText)`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
`;

const GridCellText = styled(AppText)`
  /* font-weight: 600; */
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  max-width: 368px;
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
`;
const GridCellClickText = styled(GridCellText)`
  font-weight: 600;
  color: ${theme.ATTENTION700};
  border-bottom: none;
  cursor: pointer;
`;

const GridTable = styled.table`
  cursor: auto;
  font-size: 10px;
  font-weight: 500;
  border-collapse: collapse;
  padding: 5px;
  /* min-width: 1040px; */
  width: 100%;
  /* :first-child {
    min-width: 240px;
    max-width: 240px;
  } */
`;

const GridTD = styled.td`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid ${theme.NEUTRAL200};
  line-height: 30px;
  font-size: 8px;
  padding: 11px 16px;
  /* cursor: pointer; */
  text-align: center;
  background: ${theme.WHITE_COLOR};
`;

const GridTH = styled.th`
  border: 1px solid ${theme.NEUTRAL300};
  padding: 11px 16px;
  /* height: 160px; */
  white-space: nowrap;
  /* transform: translate(2px, 55px) rotate(270deg); */
  min-width: 40px;
  max-width: 40px;
  background: ${theme.NEUTRAL200};
  /* :first-child {
    transform: translate(0px, 0px) rotate(0deg);
    min-width: 232px;
    border: 0px solid ${theme.NEUTRAL200};
  } */
`;

const SubmitDiv = styled.div`
  padding: 24px;
  border-top: 1px solid ${theme.NEUTRAL200};
  background-color: ${theme.NEUTRAL100};
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  /* grid-template-columns: 1fr 1fr; */
  /* grid-gap: 12px; */
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const CustomSelect = styled(AppSelect)`
  text-align: left;
  border-color: ${theme.NEUTRAL100};
  :focus {
    border-color: ${theme.BLACK_COLOR};
  }
`;

const PaddingDiv = styled.div`
  padding: 24px;
  height: 572px;
  max-height: 60vh;
  overflow: auto;
`;

const ConfirmText = styled(AppText)`
  font-size: 14px;
  text-align: center;
  /* margin-bottom: 20px; */
  width: 100%;
`;

const LabelText = styled(AppText)`
  padding: 3px;
  text-align: left;
`;

const ConfirmButton = styled(NewAppButton)`
  /* margin-bottom: 16px;
  margin-top: 8px; */
  height: 40px;
  /* width: 120px; */
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
`;

const SkipButton = styled(NewAppButton)`
  height: 48px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
`;

const CardTitle = styled.div`
  min-width: 100%;
  max-height: 20px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  background-color: ${theme.NEUTRAL100};
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`;
export { ErrorLogReportModal };
