import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { AppText, FlexDiv } from "../UI";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { errorToast, successToast } from "../../utils/toast";
import { Modal } from "./Modal";
import { restAPI } from "../../apollo";
import { PhoenixInputField } from "../Field/Phoenix";
import { PhoenixAppButton } from "../UI/Phoenix";

interface DisappearingDivProps {
  visible: boolean;
  close: () => void;
  refetch: (key?: any) => Promise<any>;
}

interface MyFormikProps {
  csv_link: string;
}

const CreateCsvLink: React.FC<DisappearingDivProps> = ({ visible, close, refetch }) => {
  const csvLinkSchema = Yup.object().shape({
    csv_link: Yup.string()
      .required("CSV Link is required")
      .test("is-url", "Must be a valid URL", (value) => {
        if (!value) return false;
        try {
          new URL(value);
          return true;
        } catch {
          return false;
        }
      }),
  });

  const handleSubmit = async (values: MyFormikProps) => {
    try {
      const response = await restAPI.post("/upload/savegooglesheetlink", {
        google_sheet_link: values.csv_link,
      });

      if (response.status === 200) {
        successToast("CSV link added successfully!");
        await refetch();
        close();
      }
    } catch (error: any) {
      errorToast(error?.response?.data?.error || "Error saving CSV link");
      Sentry.captureException(error);
      console.error("Error saving CSV link:", error);
    }
  };

  return (
    <Formik
      initialValues={{
        csv_link: "",
      }}
      validationSchema={csvLinkSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, values, setFieldValue, isSubmitting }: FormikProps<MyFormikProps>) => {
        return (
          <Modal open={visible} onClose={() => close()} popupAnimation>
            <Main>
              <div>
                <FlexDiv align="center" justify="center">
                  <PopupTitle>Add CSV Link</PopupTitle>
                </FlexDiv>

                <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                  Enter Sheet Link <span style={{ color: "red" }}>*</span>
                </AppText>
                <PhoenixInputField
                  name="csv_link"
                  height={40}
                  value={values.csv_link}
                  onChange={(e) => setFieldValue("csv_link", e.target.value)}
                  placeholder="Enter Google Sheet link here"
                />
              </div>

              <FlexDiv justify="space-between">
                <PhoenixAppButton variant="danger-outline" onClick={close}>
                  Cancel
                </PhoenixAppButton>
                <PhoenixAppButton variant="brand" disabled={isSubmitting} onClick={() => handleSubmit()}>
                  {isSubmitting ? "Adding..." : "Add CSV Link"}
                </PhoenixAppButton>
              </FlexDiv>
            </Main>
          </Modal>
        );
      }}
    </Formik>
  );
};

const PopupTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 27px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 476px;
  height: 300px;
  padding: 40px;
`;

export { CreateCsvLink };
