import React, { useState } from "react";
import styled from "styled-components";
import { PhoenixIcon, PhoenixProgressBar } from "../../UI/Phoenix";
import { AppText } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { ai, chevron_down, chevron_up, info } from "../../../images/NewDesign";
import { theme } from "../../../utils/theme";
import { getCallScoreDescription, getScoreColor } from "../../../utils/misc";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import { AIProcessingCall } from "./AIProcessingCall";

interface AnalysisTabProps {
  aiProcessing: boolean;
  aiData: any;
  recording_url: string;
}

export const AnalysisTab: React.FC<AnalysisTabProps> = ({ aiProcessing, aiData, recording_url }) => {
  if (!recording_url) {
    return null;
  }

  if (aiProcessing) {
    return <AIProcessingCall />;
  }

  const formatScriptAdherenceScore = (score: number | null) => {
    if (!score) return 0;
    return Math.round(score * 100);
  };

  return (
    <>
      <PhoenixStyledTooltip
        id="call-score-tooltip"
        getContent={(dataTip) => (
          <>
            <AppText
              fontSize={14}
              lineHeight={20}
              fontWeight={500}
              color={theme.text.neutral.inverse}
              style={{ paddingBottom: "8px" }}
            >
              {dataTip?.split("|")?.[0]}
            </AppText>
            <AppText fontSize={12} lineHeight={18} fontWeight={400} color={theme.text.neutral.inverse} textAlign="left">
              {dataTip?.split("|")?.[1]}
            </AppText>
          </>
        )}
      />
      <FlexDiv gap={40} height="100%" padding="24px 40px">
        <ScoreContainer gap={16} direction="column">
          {/* <FlexDiv gap={8}>
            <PhoenixIcon
              svg={ai}
              size={16}
              color={theme.icon.brand.default}
              hoverColor={theme.icon.brand.default}
              style={{ paddingTop: "3px" }}
            />
            <div>
              <AppText fontSize={16} fontWeight={600} lineHeight={22}>
                Sellfire Call Score
              </AppText>
              <AppText fontSize={12} fontWeight={400} lineHeight={18} color={theme.text.brand.primary}>
                Powered by AI
              </AppText>
            </div>
            <AppText
              fontSize={18}
              fontWeight={600}
              lineHeight={24}
              color={theme.text.success.primary}
              style={{ paddingLeft: "8px" }}
            >
              {formatScriptAdherenceScore(aiData?.adherence_score)}
            </AppText>
          </FlexDiv> */}

          <FlexDiv gap={16}>
            <FlexDiv direction="column" gap={16} style={{ width: "100%" }} wrap="wrap">
              <CallScoreCard
                title="Gatekeeper Intro"
                score={formatScriptAdherenceScore(aiData?.adherence_gatekeeper_intro_score)}
                tip={getCallScoreDescription("Communication & Engagement")}
                description={aiData?.callScore?.communicationAndEngagement?.summary}
              />
              <CallScoreCard
                title="DM Intro"
                score={formatScriptAdherenceScore(aiData?.adherence_dm_intro_score)}
                tip={getCallScoreDescription("Forward Motion")}
                description={aiData?.callScore?.forwardMotion?.summary}
              />

              <CallScoreCard
                title="30 Second Pitch"
                score={formatScriptAdherenceScore(aiData?.adherence_thirty_second_pitch_score)}
                tip={getCallScoreDescription("Question Handling")}
                description={aiData?.callScore?.questionHandling?.summary}
              />
              <CallScoreCard
                title="Setting Demo"
                score={formatScriptAdherenceScore(aiData?.adherence_setting_demo_score)}
                tip={getCallScoreDescription("Sale Process")}
                description={aiData?.callScore?.salesProcess?.summary}
              />
              <CallScoreCard
                title="Confirming Demo"
                score={formatScriptAdherenceScore(aiData?.adherence_confirming_demo_score)}
                tip={getCallScoreDescription("Sale Process")}
                description={aiData?.callScore?.salesProcess?.summary}
              />
            </FlexDiv>
          </FlexDiv>
        </ScoreContainer>

        {/* <FlexDiv direction="column" gap={8}>
            <FlexDiv align="center" gap={8}>
              <Dot color={theme.fill.success.primary} />
              <AppText fontSize={14} fontWeight={500} lineHeight={20}>
                Positive
              </AppText>
            </FlexDiv>

            <AppText></AppText>
          </FlexDiv>
          <FlexDiv direction="column" gap={8}>
            <FlexDiv align="center" gap={8}>
              <Dot color={theme.fill.danger.primary} />
              <AppText fontSize={14} fontWeight={500} lineHeight={20}>
                Opportunities
              </AppText>
            </FlexDiv>
          </FlexDiv> */}
      </FlexDiv>
    </>
  );
};

const ScoreContainer = styled(FlexDiv)`
  max-height: 650px;
  overflow-y: auto;
  width: 100%;
  @media (min-height: 1000px) {
    max-height: 800px;
  }
`;

interface CallScoreCardProps {
  title: string;
  score: number;
  tip: string;
  description: string;
}

const CallScoreCard: React.FC<CallScoreCardProps> = ({ title, score, tip, description }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <CallScoreCardContainer style={{ maxHeight: expanded ? "400px" : "76px" }}>
      <FlexDiv justify="space-between">
        <FlexDiv align="center" gap={8}>
          <AppText fontSize={14} fontWeight={600} lineHeight={20}>
            {title}
          </AppText>
          <PhoenixIcon
            svg={info}
            size={16}
            variant="neutral"
            data-tip={`${title}|${tip}`}
            data-for="call-score-tooltip"
          />
        </FlexDiv>

        <FlexDiv align="center" gap={16}>
          <AppText fontSize={14} fontWeight={600} lineHeight={20}>
            {score}/100
          </AppText>

          <PhoenixIcon
            svg={expanded ? chevron_up : chevron_down}
            size={24}
            color={theme.icon.brand.default}
            pointer
            onClick={() => setExpanded(!expanded)}
          />
        </FlexDiv>
      </FlexDiv>

      <PhoenixProgressBar step={score} maxStep={100} height={4} color={getScoreColor(score)} />

      <AppText>{description}</AppText>
    </CallScoreCardContainer>
  );
};

const CallScoreCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  overflow-y: hidden;
  flex-shrink: 0;

  padding: 12px;
  border: 1px solid ${theme.border.neutral.secondary};
  border-radius: 8px;
  background: ${theme.fill.neutral.primary};

  transition: max-height 0.25s ease-in-out;
`;

const FeedbackContainer = styled(FlexDiv)`
  max-width: 50%;
  min-width: 50%;
`;

const Dot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;
