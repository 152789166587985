import React, { useRef, Dispatch, FC, SetStateAction } from "react";
import { useClickOutside } from "src/utils/hooks";
import { theme } from "src/utils/theme";
import styled from "styled-components";

interface ISearchMenuV2 {
  businessName?: string;
  fullName?: string;
  city?: string;
  state?: string;
  setIsSearchClicked: Dispatch<SetStateAction<boolean>>;
}

export const SearchMenuV2: FC<ISearchMenuV2> = ({ setIsSearchClicked, businessName, city, state, fullName }) => {
  const containerRef = useRef(null);

  useClickOutside(containerRef, () => setIsSearchClicked(false));

  return (
    <OptionsContainer ref={containerRef}>
      {!!businessName && (
        <Option
          onClick={() => {
            let businessNameSearch = encodeURIComponent(businessName);
            let citySearch = encodeURIComponent(`${city || ""} ${state || ""}`);
            window.open(
              `https://google.com/search?q=${businessNameSearch || ""} ${citySearch || ""}`,
              "_blank",
              "noreferrer",
            );
          }}
        >
          Business Name
        </Option>
      )}
      {!!fullName && fullName.length > 3 && (
        <Option
          onClick={() => {
            let nameSearch = encodeURIComponent(fullName);
            let citySearch = encodeURIComponent(`${city || ""} ${state || ""}`);
            window.open(`https://google.com/search?q=${nameSearch || ""} ${citySearch || ""}`, "_blank", "noreferrer");
          }}
        >
          Lead Name
        </Option>
      )}
    </OptionsContainer>
  );
};

const OptionsContainer = styled.div`
  position: absolute;
  top: 32px;
  right: 72px;

  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 4px;
  overflow: hidden;

  animation: ${theme.popup} 0.2s ease;
  z-index: 3;
`;

const Option = styled.div`
  display: flex;
  align-items: center;

  width: 168px;
  height: 32px;
  padding: 8px;

  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  color: ${theme.BLACK_COLOR};

  transition: background-color 0.15s ease, color 0.15s ease;

  :hover {
    background-color: ${theme.PRIMARY100};
    color: ${theme.PRIMARY500};
  }
`;
