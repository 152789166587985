export const OPT_IN_MESSAGES = [
  "Thanks for opting in to text messages from us.",
  "Thanks for choosing to receive text messages from us.",
  "Thanks for agreeing to receive text messages from us.",
  "You are opted in to text messages from us.",
  "You're all set to receive text messages from us.",
  "You've successfully subscribed to text messages from us.",
  "You're officially signed up for text messages from us!",
];

export const OPT_OUT_MESSAGES = [
  "Reply STOP to stop receiving these messages.",
  "Reply STOP to no longer receive these messages.",
  "If you no longer wish to receive these messages, reply STOP.",
  "If you'd like to opt out of texts, reply STOP.",
  "To unsubscribe from our messages, simply reply STOP.",
  "To stop receiving messages from us, reply STOP.",
  "To unsubscribe from our texts, reply STOP.",
];

export const SMS_SECTION_ORDER = [
  {
    label: "Opt-in Message",
    value: "OptInMessage",
  },
  { label: "Message Content", value: "MessageContent" },
  { label: "Opt-out Message", value: "OptOutMessage" },
];
