import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { theme } from "src/utils/theme";
import { edit, plus } from "src/images/NewDesign";
import { AppErrorText, AppText, SkeletonBlock } from "src/Components/UI";
import { FlexDiv } from "src/Components/UI/FlexDiv";
import { PhoenixAppButton, PhoenixIcon } from "src/Components/UI/Phoenix";
import { ModalContext } from "src/context";
import useFetchLabels from "src/query-hooks/useFetchLabels";
import { LabelT } from "src/types";
import { formatDate } from "src/utils/date";
import { AddOrEditLabelModal } from "../modal/AddOrEditLabelModal";
import { TableList } from "../Segments";
import { useFlags } from "launchdarkly-react-client-sdk";

export const ManageLabels = () => {
  const history = useHistory();
  const [selectedLabel, setSelectedLabel] = useState<LabelT | null>(null);
  const { showAddOrEditLabelModal, setShowAddOrEditLabelModal } = useContext(ModalContext);

  const { data, loading, error } = useFetchLabels();

  const handleCreateLabel = () => {
    setSelectedLabel(null);
    setShowAddOrEditLabelModal(true);
  };

  const handleEditLabel = (label: LabelT) => {
    setSelectedLabel(label);
    setShowAddOrEditLabelModal(true);
  };

  const onRowClick = (label: LabelT) => {
    history.push(`/system-config/manage-labels/${label.id}`);
  };

  return (
    <Main>
      {showAddOrEditLabelModal && <AddOrEditLabelModal selectedLabel={selectedLabel} />}

      <TopOptions>
        <AppText fontSize={22} fontWeight={500}>
          Manage Labels
        </AppText>

        <RightOptions>
          <PhoenixAppButton buttonType="secondary" variant="brand" onClick={handleCreateLabel}>
            <FlexDiv justify="center" align="center" gap={16}>
              <PhoenixIcon svg={plus} size={16} color={theme.WHITE_COLOR} hoverColor={theme.WHITE_COLOR} pointer />
              <AppText fontSize={10} fontWeight={600} variant="white" uppercase>
                Create New
              </AppText>
            </FlexDiv>
          </PhoenixAppButton>
        </RightOptions>
      </TopOptions>

      <Body>
        {!!error ? (
          <AppErrorText>Something went wrong</AppErrorText>
        ) : !!loading ? (
          <LoadingComponent />
        ) : !data?.length ? (
          <FlexDiv direction="column" align="center">
            <AppText color={theme.text.neutral.secondary} fontSize={12} style={{ marginBottom: "8px" }}>
              Let’s get started.
            </AppText>

            <AppText fontSize={16} fontWeight={400} style={{ marginBottom: "32px", textAlign: "center" }}>
              Create a label to assign to the reps.
            </AppText>

            <PhoenixAppButton buttonType="secondary" variant="brand" onClick={handleCreateLabel}>
              <FlexDiv justify="center" align="center" gap={16}>
                <PhoenixIcon svg={plus} size={16} color={theme.WHITE_COLOR} hoverColor={theme.WHITE_COLOR} pointer />
                <AppText fontSize={10} fontWeight={600} variant="white" uppercase>
                  Create New
                </AppText>
              </FlexDiv>
            </PhoenixAppButton>
          </FlexDiv>
        ) : (
          <TableList<LabelT>
            data={data}
            headers={["Label Name", "Created By", "Last Updated", ""]}
            onRowClick={(item) => onRowClick(item)}
            renderItem={(item) => {
              return (
                <>
                  <FlexDiv align="center" gap={8}>
                    <LabelColor color={item.color} />

                    <AppText fontSize={12}>{item.name}</AppText>
                  </FlexDiv>
                  <AppText fontSize={12} color={theme.text.neutral.secondary}>
                    {formatDate(item.created_at)} by {item.created_by?.full_name}
                  </AppText>
                  <AppText fontSize={12} color={theme.text.neutral.secondary}>
                    {formatDate(item.updated_at)} by {item.last_updated_by?.full_name}
                  </AppText>
                  <FlexDiv
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditLabel(item);
                    }}
                  >
                    <PhoenixIcon svg={edit} size={16} pointer />
                  </FlexDiv>
                </>
              );
            }}
          />
        )}
      </Body>
    </Main>
  );
};

const LoadingComponent = () => {
  return (
    <FlexDiv align="center" direction="column" gap={8}>
      {[...Array(6)].map((_, i) => (
        <SkeletonBlock
          key={i}
          opacity={1 - i * 0.16}
          delayNumber={i + 1}
          height={73}
          width="calc(100% - 32px)"
          borderRadius={8}
        />
      ))}
    </FlexDiv>
  );
};

const Main = styled.div`
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  margin-left: 24px;
  border-radius: 8px;
  overflow: hidden;
  transition: margin-left 0.22s ease-in-out;
  min-width: 1200px;
`;

const TopOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 89px;
  padding: 0px 40px;
`;

const RightOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  width: 100%;
  height: fit-content;
  max-height: 80vh;
  min-height: 80vh;

  margin: 0px;
  padding: 48px 112px 0px 112px;

  background-color: ${theme.PRIMARY50};
  border-top: 1px solid ${theme.NEUTRAL200};
  overflow-y: auto;
`;

const LabelColor = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  width: 10px;
  height: 10px;
  border-radius: 100%;
`;
