import React from "react";
import styled from "styled-components";
import { AppText } from "..";
import { theme } from "../../../utils/theme";

interface DispositionReasonPillProps {
  reason: string;
  disposition: string;
}

type DispositionType = "NotInterested" | "NotQualified" | "N/A";

const findDispositionReasonType = (disposition: string): DispositionType => {
  switch (true) {
    case disposition.toLowerCase().includes("not") && disposition.toLowerCase().includes("interested"):
      return "NotInterested";
    case disposition.toLowerCase().includes("not") && disposition.toLowerCase().includes("qualified"):
      return "NotQualified";
    default:
      return "N/A";
  }
};

export const DispositionReasonPill: React.FC<DispositionReasonPillProps> = ({ reason, disposition }) => {
  const dispositionReasonType: DispositionType = findDispositionReasonType(disposition);
  const color = (() => {
    switch (dispositionReasonType) {
      case "NotInterested":
        return theme.text.brand.primary;
      case "NotQualified":
        return theme.text.neutral.secondary;
      default:
        return theme.text.neutral.secondary;
    }
  })();

  const borderColor = (() => {
    switch (dispositionReasonType) {
      case "NotInterested":
        return theme.border.brand.primary;
      case "NotQualified":
        return theme.border.neutral.primary;
      default:
        return theme.border.neutral.primary;
    }
  })();

  return (
    <PillContainer borderColor={borderColor}>
      <AppText fontSize={8} fontWeight={600} lineHeight={12} uppercase color={color} noWrap>
        {reason || "N/A"}
      </AppText>
    </PillContainer>
  );
};

const PillContainer = styled.div<{ borderColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24px;
  height: max-content;
  padding: 4px 8px;
  background-color: ${theme.WHITE_COLOR};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 4px;
  width: max-content;
  max-width: 100%;
`;
