import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { theme } from "../../../utils/theme";
import { AppText, FlexDiv, Loading } from "../../UI";
import styled from "styled-components";
import { PhoenixAppButton, PhoenixIcon, PhoenixMultiSelect } from "../../UI/Phoenix";
import { download, eye, eye_off, pause, play, rotate, volume_2 } from "../../../images/NewDesign";
import { useDebounce, useSub } from "../../../utils/hooks";
import { MyPriorCallNotes } from "../DashboardSideBarSegments";
import { CoachingNotes } from "./CoachingNotes";
import { appToast, successToast } from "../../../utils/toast";
import { FetchResult, MutationFunctionOptions, from, gql, useMutation } from "@apollo/client";
// @ts-ignore
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions";
// @ts-ignore
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline";
import { loggedInUser } from "../../../apollo/cache";
import { useFlags } from "launchdarkly-react-client-sdk";
import moment from "moment";
import { throttle } from "lodash";
import { ModalContext } from "../../../context";
import { downloadResourceCors } from "src/utils/misc";
import { iconSave } from "src/images";

import { AITimeline } from "./AITimeline";
import { OptionItem } from "src/types";
import VideoPlayer from "../CallReportV2Segments/VideoPlayer";
import { AIProvider } from "src/utils/ai";
import { formatRelativeCallDurationShortenedWithSeconds } from "src/utils/format";

const SEND_REP_FEEDBACK = gql`
  mutation sendReviewCallReportToRep($conference_id: String!) {
    sendReviewCallReportToRep(conference_id: $conference_id)
  }
`;

interface Lead {
  id: string;
  business_name: string;
  channel: string;
  first_name: string;
  last_name: string;
  industry_label: string;
  lead_source_label: string;
  current_lead_type: string;
  call_notes: { created_at: string; notes: string; id: string }[];
}

interface Region {
  id: string;
  start: number;
  end: number;
  data: {
    note: string;
  };
}

interface WaveFormProps {
  url?: string;
  rendered_video_url?: string;
  peakData?: number[];
  showCoachingNotes: boolean;
  lead_data: Lead;
  regions?: Region[];
  conference_id?: string;
  refetch_call_report?: () => void;
  addCoachingNote?: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  editCoachingNote?: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  deleteCoachingNote?: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  aiProcessing?: boolean;
  callDuration?: number;
  showTimeline?: boolean;

  aiData?: any;
  conferenceData?: any;
  setHandleTimestampSet?: React.Dispatch<React.SetStateAction<() => void>>;

  onWaveformRef?: (waveform: WaveSurfer | null) => void;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
}

const regionColor = "rgba(255,179,56,0.6)";

const PLAYBACK_SPEED_OPTIONS = [
  {
    label: "0.75x",
    value: 0.75,
  },
  {
    label: "1.0x",
    value: 1,
  },
  {
    label: "1.25x",
    value: 1.25,
  },
  {
    label: "1.5x",
    value: 1.5,
  },
  {
    label: "1.75x",
    value: 1.75,
  },
  {
    label: "2.0x",
    value: 2,
  },
];

export const formatTimeCallback = (seconds: number) => {
  return moment.utc(seconds * 1000).format("mm:ss");
};

const WaveformV2: React.FC<WaveFormProps> = ({
  url,
  rendered_video_url,
  peakData,
  showCoachingNotes,
  lead_data,
  regions,
  conference_id,
  refetch_call_report,
  addCoachingNote,
  editCoachingNote,
  deleteCoachingNote,
  aiProcessing,
  callDuration,
  showTimeline,

  aiData,
  conferenceData,
  setHandleTimestampSet,
  isPlaying,
  setIsPlaying,
  onWaveformRef,
}) => {
  const {
    setShowCoachingNotesModal,
    setCurrentConferenceID,
    setSaveCallToLibraryModal,
    setCoachingNoteFormattedTimestamp,
    setTempRegionId,
  } = useContext(ModalContext);

  const waveformContainer = useRef<HTMLDivElement | null>(null);
  const waveform = useRef<WaveSurfer | null>(null);
  const [videoVolume, setVideoVolume] = useState(1);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [showSpeakers, setShowSpeakers] = useState(false);

  const [loadingDownload, setLoadingDownload] = useState(false);

  const { aiPhase1, googleMeetIntegration, zoomIntegration } = useFlags();

  const [isReady, setIsReady] = useState(false);

  const currentTimeRef = useRef(0);
  const setCurrentTimeThrottled = useRef(
    throttle((time: number) => {
      currentTimeRef.current = time;
    }, 250),
  );
  const currentTime = currentTimeRef.current;

  useEffect(() => {
    const updateCurrentTimeDisplay = () => {
      const currentTimeElement = document.getElementById("current-time-display");
      if (currentTimeElement) {
        currentTimeElement.textContent = formatTime(currentTimeRef.current, (callDuration ?? 0) > 3600);
      }
    };

    const intervalId = setInterval(updateCurrentTimeDisplay, 250);
    return () => clearInterval(intervalId);
  }, [callDuration]);

  useEffect(() => {
    if (setHandleTimestampSet) {
      setHandleTimestampSet(() => () => {
        const percentage = (currentTimeRef.current / (callDuration ?? 1)) * 100;
        setCoachingNoteFormattedTimestamp({
          start: formatRelativeCallDurationShortenedWithSeconds(percentage, callDuration ?? 0),
          end: formatRelativeCallDurationShortenedWithSeconds(percentage, callDuration ?? 0),
        });
      });
    }
  }, [setHandleTimestampSet, setCoachingNoteFormattedTimestamp, callDuration]);

  useEffect(() => {
    if (!url || !waveformContainer.current) return;

    waveform.current = WaveSurfer.create({
      container: waveformContainer.current as HTMLElement,
      responsive: true,
      normalize: true,

      height: 48,

      barWidth: 2,
      barMinHeight: 2,
      barRadius: 1.5,
      barGap: 4,
      cursorWidth: 1,

      backgroundColor: theme.fill.brand.disabled,

      waveColor: theme.fill.brand.primary,
      progressColor: theme.fill.brand.primary,
      backend: "MediaElement",
      duration: callDuration,
      plugins: [
        RegionsPlugin.create({}),
        TimelinePlugin.create({
          container: "#wave-timeline",
          height: 24,
          notchPercentHeight: 50,
          primaryColor: theme.text.brand.primary,
          secondaryColor: theme.text.brand.primary,
          primaryFontColor: theme.text.brand.primary,
          secondaryFontColor: theme.text.brand.primary,
          fontFamily: "Inter",
          fontSize: 10,
          clickable: true,
          clickableColor: theme.text.brand.primary,
        }),
      ],
    });

    if (onWaveformRef) onWaveformRef(waveform.current);

    waveform.current.load(url, peakData);

    // this is required to trigger the audio process event.
    waveform.current?.seekTo(0);

    waveform.current.on("ready", waveformOnReady);

    waveform.current.on("audioprocess", (time) => {
      setCurrentTimeThrottled.current(time);
    });

    waveform.current.on("seek", (time) => {
      const actualSeconds = time * (callDuration ?? 0);
      setCurrentTimeThrottled.current(actualSeconds);
    });

    waveform.current.on("region-updated", () => {
      saveRegions();
    });

    waveform.current.on("region-removed", () => {
      saveRegions();
    });

    waveform.current.on("region-update-end", async (region: any) => {
      if (!addCoachingNote || !editCoachingNote) return;

      if (region.attributes.wavesurferShouldCreate) {
        const startPercentage = (region.start / (callDuration ?? 1)) * 100;
        const endPercentage = (region.end / (callDuration ?? 1)) * 100;

        setCoachingNoteFormattedTimestamp({
          start: formatRelativeCallDurationShortenedWithSeconds(startPercentage, callDuration ?? 0),
          end: formatRelativeCallDurationShortenedWithSeconds(endPercentage, callDuration ?? 0),
        });
        setShowCoachingNotesModal(true);
        setTempRegionId(region.id);

        saveRegions();
        region.update({
          attributes: {
            wavesurferShouldCreate: false,
          },
        });
      } else {
        await editCoachingNote({
          variables: {
            noteItemId: region.id,
            text: region.data.note,
            start: Math.floor(region.start),
            end: Math.floor(region.end),
          },
        });
        refetch_call_report && refetch_call_report();
        saveRegions();
      }
    });

    waveform.current.on("finish", () => {
      setIsPlaying(false);
    });

    return () => {
      waveform.current?.destroy();
      if (onWaveformRef) onWaveformRef(null);
    };
  }, [waveformContainer, url, callDuration]);

  useEffect(() => {
    const timelineElement = document.querySelector("#wave-timeline");

    if (!timelineElement) return;

    const handleTimelineClick = (event: MouseEvent) => {
      if (!waveform.current) return;

      const leftPadding = 24;
      const rightPadding = 24;

      const { left, width } = timelineElement.getBoundingClientRect();
      const clickX = event.clientX - left - leftPadding;
      const percentage = clickX / (width - (leftPadding + rightPadding));
      const time = percentage * waveform.current.getDuration();
      const newTime = Math.max(0, time / waveform.current.getDuration());
      waveform.current.seekTo(newTime);
    };

    timelineElement?.addEventListener("click", handleTimelineClick as EventListener);

    return () => {
      timelineElement?.removeEventListener("click", handleTimelineClick as EventListener);
    };
  }, []);

  // handles the custom cursor head placement.
  useEffect(() => {
    const waveContainer = waveformContainer.current?.querySelector("wave");
    const cursorContainer = waveformContainer.current?.querySelector("wave > wave");

    if (!waveContainer || !cursorContainer) return;

    const cursorHead = document.createElement("div");
    Object.assign(cursorHead.style, {
      position: "absolute",
      width: "20px",
      height: "12px",
      border: `1px solid ${theme.border.brand.inverse}`,
      borderRadius: "360px",
      backgroundColor: theme.fill.neutral.primary,
      boxShadow: "0px 0px 4px 0px rgba(112, 145, 255, 0.4)",
      top: "-12px",
    });
    waveContainer.appendChild(cursorHead);

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === cursorContainer) {
          cursorHead.style.left = `${(cursorContainer as HTMLElement).offsetWidth - 10}px`;
        }
      });
    });

    resizeObserver.observe(cursorContainer as HTMLElement);

    return () => resizeObserver.disconnect();
  }, [waveformContainer]);

  const waveformOnReady = () => {
    setIsReady(true);

    if (rendered_video_url) {
      // mute the waveform when video is playing
      // this is unfortunate, but it's a workaround for now.
      // Issue : BE video file and waveform audio are not in sync.
      // when video is playing, the waveform audio is muted so that the two audio sources don't conflict.
      // idealy waveform file = audio and mp4 file = video and they are in sync so we don't have to juggle audio sources.
      waveform.current?.setVolume(0);
    }

    if (regions?.length) {
      regions.forEach((region) => {
        waveform.current?.addRegion({
          ...region,
          color: regionColor,
        });
      });
    }

    if (loggedInUser().role === "ADMIN" || loggedInUser().role === "SM") {
      waveform.current?.enableDragSelection({
        color: regionColor,
        attributes: {
          wavesurferShouldCreate: true,
        },
        data: {
          note: "",
        },
      });
    }
  };

  const saveRegions = () => {
    //save region/annotation state locally to waveform state
    const newRegions = Object.keys(waveform.current?.regions.list)?.map((id) => {
      const region = waveform.current?.regions.list[id];
      return {
        id: region.id,
        start: region.start,
        end: region.end,
        color: region.color,
        attributes: region.attributes,
        data: region.data,
      };
    });

    console.log("New Regions updated", newRegions);
  };

  const [sendReviewCallReportToRep, { loading: repLoading }] = useMutation(SEND_REP_FEEDBACK, {
    onCompleted({ sendReviewCallReportToRep }) {
      console.log("sendReviewCallReportToRep: ", sendReviewCallReportToRep);
      successToast("Feedback sent!");
    },
  });

  const handlePlay = () => {
    if (!waveform.current) return;
    waveform.current.playPause();
    setIsPlaying((prev) => !prev);
  };

  const handleSkipForward = useCallback(() => {
    if (!waveform.current) return;
    const curPos = waveform.current.getCurrentTime();
    const audioDur = waveform.current.getDuration();
    const seekToPos = Math.min(curPos + 30, audioDur) / audioDur;
    if (seekToPos === 1) {
      waveform.current.pause();
      setIsPlaying(false);
    }
    waveform.current.seekTo(seekToPos);
  }, [waveform]);

  const handleSkipBack = useCallback(() => {
    if (!waveform.current) return;
    const curPos = waveform.current.getCurrentTime();
    const audioDur = waveform.current.getDuration();
    const seekToPos = Math.max(0, curPos - 15) / audioDur;
    waveform.current.seekTo(seekToPos);
  }, [waveform]);

  const handleVolumeChange = useCallback(
    (val: number) => {
      if (!waveform.current) return;

      // BE limitation if video is present we must use video audio because it's always slighly out of sync with the waveform audio.
      // idealy we would have a unified video and audio and wavform would load audio/peaks/etc.. and video would be muted
      // this is a workaround for now.
      if (rendered_video_url) {
        setVideoVolume(val / 100);
      } else {
        waveform.current.setVolume(val / 100);
      }
    },
    [waveform],
  );

  const handleSpeedChange = useCallback(
    (val: number) => {
      if (!waveform.current) return;
      waveform.current.setPlaybackRate(val);
      setPlaybackSpeed(val);
    },
    [waveform],
  );

  const playRegion = useCallback(
    (regionId: string) => {
      if (!waveform.current) return;
      const region = waveform.current.regions.list[regionId];
      region.play();
      setIsPlaying(true);
    },
    [waveform],
  );

  const addRegion = async (text?: string) => {
    if (!waveform.current || !addCoachingNote) return;

    const results = await addCoachingNote({
      variables: {
        conference_id: conference_id,
        text: !!text ? text : "",
        start: Math.max(Math.floor(waveform.current.getCurrentTime()), 0),
        end: Math.floor(waveform.current.getCurrentTime() + 1),
      },
    });

    waveform.current.addRegion({
      id: results.data.addCoachingNote.id,
      start: Math.max(Math.floor(waveform.current.getCurrentTime()), 0),
      end: Math.floor(waveform.current.getCurrentTime() + 1),
      color: regionColor,
      attributes: {},
      data: {
        note: results.data.addCoachingNote.text ?? "",
      },
    });
    refetch_call_report && refetch_call_report();
    successToast("New note added!");
  };

  const updateNotes = async (regionId: string, note: string) => {
    if (!waveform.current || !editCoachingNote) return;

    await editCoachingNote({
      variables: {
        noteItemId: regionId,
        text: note,
      },
    });
    refetch_call_report && refetch_call_report();

    const region = waveform.current.regions.list[regionId];

    region.update({
      data: {
        note: note,
      },
    });
    successToast("Note updated!");
  };

  const removeRegion = async (regionId: string) => {
    if (!waveform.current || !deleteCoachingNote) return;

    await deleteCoachingNote({
      variables: {
        noteItemId: regionId,
      },
    });
    const region = waveform.current.regions.list[regionId];
    region.remove();
    refetch_call_report && refetch_call_report();
    successToast("Note deleted!");
  };

  useSub("COACHING_NOTE_DELETED", (id) => {
    if (!id || !waveform.current) return;

    const region = waveform.current.regions.list[id];
    region.remove();
  });

  return (
    <FlexDiv direction="column">
      {!!rendered_video_url && (
        <FlexDiv height={"100%"} width={"100%"}>
          <VideoPlayer
            videoVolume={videoVolume}
            currentWaveformTime={currentTime}
            waveformPlaying={isPlaying}
            playbackSpeed={playbackSpeed}
            rendered_video_url={rendered_video_url}
          />
        </FlexDiv>
      )}
      <FlexDiv gap={0} width={"100%"} style={{ backgroundColor: theme.fill.brand.secondary }}>
        <FlexDiv direction="column" width={"100%"}>
          <ControlContainer>
            {/* {!!aiPhase1 && aiData.provider === AIProvider.SYMBOL ? (
              <FlexDiv direction="column" gap={4}>
                <PhoenixAppButton
                  variant="brand"
                  buttonType="ghost-large"
                  onClick={() => setShowSpeakers(!showSpeakers)}
                >
                  <PhoenixIcon svg={showSpeakers ? eye_off : eye} size={16} color={theme.icon.brand.default} />
                  <ButtonText>{showSpeakers ? "Hide Speakers" : "Show Speakers"}</ButtonText>
                </PhoenixAppButton>
              </FlexDiv>
            ) : (
              <div style={{ width: "100px" }} />
            )} */}

            {isReady && (
              <AppText style={{ paddingLeft: "16px" }}>
                {formatTime(currentTime, (callDuration ?? 0) > 3600)} /{" "}
                {formatTime(callDuration ?? 0, (callDuration ?? 0) > 3600)}
              </AppText>
            )}
            <FlexDiv align="center" style={{ marginRight: "auto", marginLeft: "auto" }}>
              <PlayController
                handlePlay={handlePlay}
                isPlaying={isPlaying}
                handleSkipForward={handleSkipForward}
                handleSkipBack={handleSkipBack}
                aiPhase1={aiPhase1}
                disabled={!isReady}
              />
              {!isReady && (
                <div style={{ marginLeft: "32px" }}>
                  <Loading />
                </div>
              )}
            </FlexDiv>

            <FlexDiv direction="column" justify="center" align="flex-end">
              <FlexDiv gap={8} align="center">
                <PhoenixIcon
                  svg={volume_2}
                  size={16}
                  color={theme.icon.brand.default}
                  hoverColor={theme.icon.brand.default}
                />
                <CustomRangeSlider handleVolumeChange={handleVolumeChange} />
              </FlexDiv>

              <FlexDiv align="center" gap={8}>
                {!!url && (
                  <>
                    <PhoenixIcon
                      svg={iconSave}
                      alt="Save"
                      pointer
                      size={24}
                      color={theme.icon.brand.default}
                      hoverColor={theme.icon.brand.default}
                      style={{ paddingTop: "2px" }}
                      onClick={() => {
                        if (!!conference_id) {
                          setCurrentConferenceID(conference_id);
                          setSaveCallToLibraryModal(true);
                        }
                      }}
                    />
                    <PhoenixIcon
                      svg={download}
                      size={16}
                      pointer
                      color={loadingDownload ? theme.icon.brand.disabled : theme.icon.brand.default}
                      hoverColor={loadingDownload ? theme.icon.brand.disabled : theme.icon.brand.default}
                      onClick={() => {
                        if (loadingDownload) return;
                        setLoadingDownload(true);
                        downloadResourceCors(url, undefined, () => setLoadingDownload(false));
                      }}
                    />
                  </>
                )}
                <PhoenixMultiSelect
                  marginBottom={false}
                  width={60}
                  pointer
                  ghostSelect
                  isMulti={false}
                  isClearable={false}
                  value={PLAYBACK_SPEED_OPTIONS.find((option) => option.value === playbackSpeed)}
                  options={PLAYBACK_SPEED_OPTIONS}
                  name="playbackSpeed"
                  onChange={(val: OptionItem) => handleSpeedChange(Number(val.value))}
                  menuPortal
                />
              </FlexDiv>
            </FlexDiv>
          </ControlContainer>

          <WaveformContainer>
            <div
              style={{
                width: "100%",
                height: "50%",
                boxShadow: `0px 0.5px 0px 0px ${theme.border.neutral.primary}`,
                position: "absolute",
              }}
            />
            <Waveform ref={waveformContainer} showSpeakers={showSpeakers} />
          </WaveformContainer>

          <TimelineContainer id="wave-timeline" padding="0px 24px 8px 24px"></TimelineContainer>
          {showSpeakers && <AITimeline aiProcessing={aiProcessing} aiData={aiData} conferenceData={conferenceData} />}
        </FlexDiv>
      </FlexDiv>

      {showCoachingNotes && (
        <NotesContainer>
          <MyPriorCallNotes
            isInCallReport
            callNotes={lead_data.call_notes}
            color={"#D8D8D8"}
            textcolor={theme.BLACK_COLOR}
          />
          <CoachingNotes
            coachingNotes={regions ? regions : []}
            playRegion={playRegion}
            addRegion={addRegion}
            updateNotes={updateNotes}
            removeRegion={removeRegion}
            repLoading={repLoading}
            sendFeedbackFunction={() => sendReviewCallReportToRep({ variables: { conference_id } })}
          />
        </NotesContainer>
      )}
    </FlexDiv>
  );
};

export const TimelineContainer = styled.div<{ padding: string }>`
  width: 100%;
  height: fit-content;
  padding: ${({ padding }) => padding};
  background-color: ${theme.fill.neutral.primary};
`;

const WaveformContainer = styled.div`
  position: relative;
  background-color: ${theme.fill.brand.disabled};
`;

const Waveform = styled.div<{ showSpeakers?: boolean }>`
  margin: 0px 24px;
  padding: 8px 0px;
  z-index: 0;
  & > wave {
    overflow: unset !important;
    z-index: 0 !important;
    cursor: pointer !important;
  }
  & > wave wave {
    height: ${({ showSpeakers }) => (showSpeakers ? "420px" : "56px")};
  }
`;

const ControlContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 88px;

  padding: 16px 24px;

  background-color: ${theme.fill.brand.secondary};
  border-top: 1px solid ${theme.border.brand.tertiary};
`;

interface PlayControllerProps {
  handlePlay: () => void;
  handleSkipForward: () => void;
  handleSkipBack: () => void;
  isPlaying: boolean;
  aiPhase1: boolean;
  disabled: boolean;
}

const PlayController: React.FC<PlayControllerProps> = ({
  handlePlay,
  isPlaying,
  handleSkipForward,
  handleSkipBack,
  aiPhase1,
  disabled,
}) => {
  return (
    <PlayControllerContainer aiPhase1={aiPhase1}>
      <FlexDiv align="center" gap={4} style={{ cursor: "pointer", padding: "8px" }} onClick={handleSkipBack}>
        <PhoenixIcon
          svg={rotate}
          size={16}
          color={theme.icon.neutral.default}
          hoverColor={theme.icon.neutral.default}
          pointer
          style={{ transform: "scaleX(-1)" }}
        />
        <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.text.neutral.secondary}>
          15
        </AppText>
      </FlexDiv>

      <PlayPauseButton onClick={handlePlay} disabled={disabled}>
        <PhoenixIcon
          svg={isPlaying ? pause : play}
          size={16}
          variant="white"
          hoverColor="white"
          pointer
          fillIcon
          style={{ paddingLeft: isPlaying ? "0px" : "3px" }}
        />
      </PlayPauseButton>

      <FlexDiv align="center" gap={4} style={{ cursor: "pointer", padding: "8px" }} onClick={handleSkipForward}>
        <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.text.neutral.secondary}>
          30
        </AppText>
        <PhoenixIcon
          svg={rotate}
          size={16}
          color={theme.icon.neutral.default}
          hoverColor={theme.icon.neutral.default}
          pointer
        />
      </FlexDiv>
    </PlayControllerContainer>
  );
};

const PlayControllerContainer = styled.div<{ aiPhase1: boolean }>`
  display: flex;
  gap: 4px;
  align-items: center;

  /* width: 152px; */
  height: 56px;
  padding: 8px;

  margin-left: ${(props) => (props.aiPhase1 ? "auto" : 0)};
  margin-right: auto;

  border: 1px solid ${theme.border.brand.tertiary};
  border-radius: 360px;
  background-color: ${theme.fill.neutral.primary};
`;

const PlayPauseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  cursor: pointer;
  border: none;
  border-radius: 100px;
  background-color: ${theme.buttonfill.brand.default};

  :hover {
    background-color: ${theme.buttonfill.brand.hover};
  }

  :focus {
    border: 1px solid white;
    box-shadow: 0 0 0 1px ${theme.border.brand.primary};
  }

  transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const RangeSliderInput = styled.input`
  appearance: none;
  outline: none;

  width: 88px;
  height: 4px;

  border-radius: 100px;
  background-color: ${theme.fill.neutral.tertiary};

  ::-webkit-slider-thumb {
    appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: ${theme.fill.neutral.primary};
  }
  ::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  ::-moz-range-thumb {
    appearance: none;
    width: 4px;
    height: 4px;
    border-radius: 100px;
    background-color: ${theme.fill.brand.primary};

    box-shadow: -125px 0 0 124px ${theme.fill.brand.primary};
  }
`;

interface CustomRangeSliderProps {
  handleVolumeChange: (val: number) => void;
}

const CustomRangeSlider: React.FC<CustomRangeSliderProps> = ({ handleVolumeChange }) => {
  const [volume, setVolume] = useState(80);

  useDebounce(() => handleVolumeChange(volume), [volume], 50);

  return (
    <RangeSliderInput
      type="range"
      min={0}
      max={100}
      value={volume}
      onChange={(e) => setVolume(parseInt(e.target.value))}
      style={{
        background: `linear-gradient(to right, ${theme.fill.brand.primary} ${volume}%, ${theme.fill.neutral.tertiary} ${volume}%)`,
      }}
    />
  );
};

const NotesContainer = styled.div`
  margin-top: 13px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonText = styled.span`
  @media (max-width: 1200px) {
    display: none;
  }
`;

const formatTime = (time: number, includeHours: boolean = false) => {
  const rounded = Math.floor(time);
  const hours = Math.floor(rounded / 3600);
  const minutes = Math.floor((rounded % 3600) / 60);
  const seconds = rounded % 60;

  if (includeHours) {
    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    } else {
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    }
  } else {
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  }
};

// only need to rerender waveform when isPlaying changes
const MemoizedWaveformV2 = React.memo(WaveformV2, (oldProps, newProps) => oldProps.isPlaying === newProps.isPlaying);

export { MemoizedWaveformV2 as WaveformV2 };
