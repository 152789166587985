import styled from "styled-components";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { theme } from "../../utils/theme";
import { gql, useQuery } from "@apollo/client";
import { AppErrorText, AppText, Loading } from "../UI";
import { useState } from "react";
import { CallHistoryItem } from "./CallHistoryItem";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

const FETCH_LEAD_CALL_HISTORY = gql`
  query fetchLeadCallHistory($lead_id: String!) {
    fetchLeadCallHistory(lead_id: $lead_id) {
      id
      created_at
      conference {
        id
        start_time
        duration
        recording_url_computed
      }
      related_disposition {
        id
        label
      }
      call_completed_with
      lead {
        id
        first_name
        last_name
      }
      user {
        id
        full_name
      }
      schedule_item {
        id
        start_time
      }
    }
  }
`;

interface CallReportProps {
  leadID: string;
}

const CallHistoryList: React.FC<CallReportProps> = ({ leadID }) => {
  const [sortAscending, setSortAscending] = useState(false);
  const { data: listData, loading: listLoading, error: listError } = useQuery(FETCH_LEAD_CALL_HISTORY, {
    variables: {
      lead_id: leadID,
    },
    skip: !leadID,
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  if (listLoading)
    return (
      <ListContainer>
        <Loading />
      </ListContainer>
    );
  if (listError)
    return (
      <ListContainer>
        <AppErrorText>Error Loading Items</AppErrorText>
      </ListContainer>
    );

  // If no leads available.
  if (listData.fetchLeadCallHistory.length === 0)
    return (
      <ListContainer style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <AppText>No leads to display.</AppText>
      </ListContainer>
    );

  const leadName = `${listData.fetchLeadCallHistory[0]?.lead?.first_name ?? ""} ${
    listData.fetchLeadCallHistory[0]?.lead?.last_name ?? ""
  }`;

  return (
    <>
      <TableTitle>
        <span style={{ fontWeight: 500, paddingRight: "6px" }}>Call History </span> {leadName}
      </TableTitle>
      <ListContainer>
        <HeaderDiv>
          <HeaderText onClick={() => setSortAscending(!sortAscending)}>
            Time of Call {sortAscending ? <FaCaretUp /> : <FaCaretDown />}
          </HeaderText>
          <HeaderText>Sales Rep</HeaderText>
          <HeaderText>Call Result</HeaderText>
          <HeaderText>Event Start Time</HeaderText>
          <HeaderText>Call Recording</HeaderText>
        </HeaderDiv>
        {listData.fetchLeadCallHistory
          .slice()
          .sort(function (a: { created_at: Date }, b: { created_at: Date }) {
            if (a.created_at < b.created_at) {
              return sortAscending ? -1 : 1;
            }
            if (a.created_at > b.created_at) {
              return sortAscending ? 1 : -1;
            }
          })
          ?.map((item: any) => {
            return (
              <CallHistoryItem
                id={item.conference?.id}
                time={
                  item?.communication_type === "NonCall"
                    ? item?.created_at
                    : item?.conference?.start_time ?? item?.created_at
                }
                salesperson={item.user?.full_name ?? ""}
                result={item.related_disposition?.label ?? "None"}
                duration={item.conference?.duration ?? 0}
                event_start_time={item.schedule_item?.start_time ?? "None"}
              />
            );
          })}
      </ListContainer>
    </>
  );
};

const HeaderDiv = styled.div`
  display: grid;
  grid-template-columns: 200px 200px 250px 150px 1fr;
  align-items: center;
  justify-items: start;
  margin-top: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  top: 0px;
  height: 30px;
  background-color: ${theme.WHITE_COLOR};
  border-bottom: 1px solid ${theme.NEUTRAL100};
`;

const TableTitle = styled(AppText)`
  padding-left: 14px;
  font-size: 12px;
  margin-bottom: 15px;
`;

const HeaderText = styled(AppText)`
  font-size: 8px;
  font-weight: 500;
  border-bottom: none;
`;

const ListContainer = styled.div`
  width: 1064px;
  height: 299px;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(224, 224, 224, 0.5);
  background-color: ${theme.WHITE_COLOR};
  margin-bottom: 20px;
  overflow: auto;
  padding-left: 14px;
  padding-right: 14px;
`;

export { CallHistoryList };
