import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import styled from "styled-components";
import { theme } from "src/utils/theme";
import moment from "moment";
import { AppText } from "src/Components/UI";
import { PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import ReactDOM from "react-dom";
import { MiniCalendar } from "./MiniCalendar";

export const Calendar: React.FC = () => {
  const calendarRef = useRef(null);

  useEffect(() => {
    // manually resize after 0.5s. This will allow the calendar to adjust column sizing after queue animation plays
    const timer = setTimeout(() => {
      const calendarApi = (calendarRef.current as any)?.getApi();
      if (calendarApi) {
        calendarApi.render();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (calendarRef.current) {
      // Inject month menu component into the fc-timegrid-axis cell
      const axisCell = document.querySelector(".fc-timegrid-axis");
      if (axisCell) {
        axisCell.innerHTML = ""; // Clear any existing content
        const container = document.createElement("div");
        ReactDOM.render(<AxisCell calendarRef={calendarRef} />, container);
        axisCell.appendChild(container);
      }

      // There isnt enough customization for the time column labels in FullCalendar.
      // We need to manually add our own label.
      const slotLabelFrame = document.querySelectorAll(".fc-timegrid-slot");
      slotLabelFrame.forEach((el) => {
        const p = document.createElement("p");
        const slotTime = el.getAttribute("data-time");
        if (slotTime) {
          const formattedTime = moment(slotTime, "HH:mm:ss").format("h:mm A");
          p.textContent = formattedTime;
          el.firstElementChild?.appendChild(p);
        }
      });
    }
  }, [calendarRef]);

  return (
    <CalendarWrap id="cal-wrap">
      <FullCalendar
        ref={calendarRef}
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        selectable={false}
        editable={true}
        nowIndicator={true} // Shows a red line for the current time
        // events={[]}
        // dateClick={(info) => alert(`Clicked on ${info.dateStr}`)}
        // eventClick={(info) => alert(`Clicked event: ${info.event.title}`)}
        height="100%"
        headerToolbar={false}
        dayHeaderContent={DayHeaderContent}
        allDayText="All Day"
        slotLabelFormat={{
          hour: "numeric",
          minute: "2-digit",
          meridiem: "short",
        }}
      />
    </CalendarWrap>
  );
};

const DayHeaderContent = (arg: any) => {
  const today = moment().format("YYYY-MM-DD");
  const isToday = moment(arg.date).format("YYYY-MM-DD") === today;

  return (
    <HeaderCell>
      <AppText
        fontSize={12}
        fontWeight={600}
        uppercase
        letterSpacing={1}
        lineHeight={18}
        color={theme.text.neutral.secondary}
      >
        {moment(arg.date).format("ddd")}
      </AppText>
      <AppText
        fontSize={16}
        lineHeight={22}
        color={isToday ? theme.text.brand.primary : theme.text.neutral.primary}
        fontWeight={500}
      >
        {moment(arg.date).format("D")}
      </AppText>
    </HeaderCell>
  );
};

const AxisCell = ({ calendarRef }: { calendarRef: any }) => {
  const curMonth = useMemo(() => moment().format("MMM"), []);
  const [showMiniCalendar, setShowMiniCalendar] = useState(false);

  const onDropdownClick = useCallback(() => {
    setShowMiniCalendar(true);
  }, []);
  const onClickOutside = useCallback(() => {
    setShowMiniCalendar(false);
  }, []);

  const handleTimeframeSwitch = useCallback(() => {
    const curView = calendarRef.current?.getApi()?.view?.type;
    if (!curView) return;
    if (curView === "timeGridWeek") {
      calendarRef.current?.getApi()?.changeView("timeGridDay");
    }
    if (curView === "timeGridDay") {
      calendarRef.current?.getApi()?.changeView("timeGridWeek");
    }
  }, [calendarRef]);

  return (
    <div style={{ width: "64px", padding: "6px 0px 0px 16px" }}>
      {showMiniCalendar && (
        <MiniCalendar
          onClickOutside={onClickOutside}
          handleTimeframeSwitch={handleTimeframeSwitch}
          curView={calendarRef.current?.getApi()?.view?.type}
        />
      )}
      <PhoenixMultiSelect
        isMulti={false}
        isClearable={false}
        name="month"
        options={[]}
        value={[{ label: curMonth, value: null }]}
        menuPortal
        marginBottom={false}
        ghostSelect
        fontSize="18px"
        width={64}
        height={32}
        minHeight={32}
        disableHoverColor
        pointer
        onClick={onDropdownClick}
        isDisabled
      />
    </div>
  );
};

const CalendarWrap = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  background-color: ${theme.fill.brand.tertiary};
  border: 1px solid ${theme.border.neutral.primary};
  border-radius: 8px;

  overflow: hidden;

  table {
    border: none !important;
  }

  // Calendar Columns
  .fc-col-header col,
  .fc-scrollgrid-sync-table col,
  .fc-timegrid-slots col,
  .fc-timegrid-cols col {
    width: 80px !important;
  }

  // Header
  .fc-col-header {
    width: 100% !important;
  }
  thead .fc-scroller,
  tbody .fc-scrollgrid-section:first-child .fc-scroller {
    overflow: hidden !important;
  }
  .fc-scrollgrid-section-body > td {
    border: none !important;
  }
  .fc-scrollgrid-section-header > th {
    border: none;
  }
  .fc-col-header-cell,
  thead .fc-timegrid-axis {
    padding-top: 4px;
    border-width: 0px 0px 1px 0px;
    border-bottom: 1px solid ${theme.border.neutral.secondary};
  }
  .fc thead tr {
    background-color: ${theme.WHITE_COLOR};
    height: 58px;
  }
  thead .fc-timegrid-axis-frame {
    display: none !important;
  }

  // Calendar Body
  .fc-timegrid-divider {
    display: none !important;
  }
  .fc .fc-col-header-cell-cushion {
    padding: 0px;
  }
  .fc-timegrid-body tr {
    height: 28px;
  }
  .fc-timegrid-body .fc-timegrid-slots tr td:first-child {
    border: none;
  }
  .fc .fc-timegrid-now-indicator-container:first-child {
    border-right: 1px solid ${theme.border.neutral.secondary};
  }
  .fc-timegrid-col:first-child {
    border: none;
  }
  .fc-timegrid-cols .fc-timegrid-axis {
    background-color: ${theme.fill.neutral.primary};
  }
  .fc-timegrid-cols .fc-day {
    background-color: ${theme.fill.brand.tertiary};
  }

  // white border on all cells
  .fc-timegrid-cols td {
    border: 1px solid ${theme.WHITE_COLOR};
    border-width: 0px 1px 1px 1px;
  }
  .fc-timegrid-slot {
    border: 1px solid ${theme.WHITE_COLOR};
    border-width: 0px 1px 1px 1px;
  }

  // All-Day Row
  .fc-daygrid-body tr {
    height: 56px;
    background-color: ${theme.fill.brand.tertiary};
  }
  .fc-daygrid-body tr td {
    border-width: 0px 1px 1px 1px;
    border: 1px solid ${theme.WHITE_COLOR};
  }
  .fc .fc-timegrid-axis-frame {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 4px;

    background-color: ${theme.fill.neutral.primary};
    border-right: 1px solid ${theme.border.neutral.secondary};
  }
  .fc .fc-timegrid-axis-frame span {
    font-size: 8px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 12px;
    text-align: right;
    color: ${theme.text.neutral.secondary};

    width: 25px;
  }

  // Time Column Overrides
  .fc .fc-timegrid-slot-label-cushion {
    display: none !important;
  }
  .fc-timegrid-slot-label-frame > p {
    width: 23px;
    margin-left: auto;
    margin-right: 4px;

    font-size: 8px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 12px;
    text-align: right;
    color: ${theme.text.neutral.secondary};

    word-break: break-all;
    white-space: normal;
  }

  // Current Day Cells
  .fc-timegrid-cols .fc-day-today,
  .fc-daygrid-body .fc-day-today {
    background-color: ${theme.fill.brand.secondary};
  }
`;

const HeaderCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-weight: bold;
`;
