import React from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { FlexDiv } from "../FlexDiv";
import { PhoenixIcon, PhoenixRadio } from "../Phoenix";
import { AppText } from "../AppText";
import { calendar } from "../../../images/NewDesign";

export const ScheduleEventOptions = ({ ...props }) => {
  const { setFieldValue, values, entryCriteriaPhase } = props;
  return (
    <ScheduleEventContainer>
      <ScheduleEventTop gap={8} align="center">
        <IconWrap>
          <PhoenixIcon svg={calendar} size="small" variant="white" hoverColor="white" />
        </IconWrap>
        <FlexDiv direction="column" gap={4}>
          <AppText fontSize={12} fontWeight={500}>
            Scheduled Event Options
          </AppText>
          <AppText color={theme.NEUTRAL300} fontSize={12} fontWeight={500}>
            Configure how specific time events affect this step.
          </AppText>
        </FlexDiv>
      </ScheduleEventTop>
      <ScheduleEventBottom direction="column">
        <ScheduleEventOption gap={8} style={{ borderBottom: `1px solid ${theme.NEUTRAL200}` }}>
          <PhoenixRadio
            selected={
              values?.tasks?.[0]?.scheduled_event_behavior === "Neutral" ||
              !values?.tasks?.[0]?.scheduled_event_behavior
            }
            onClick={() => setFieldValue("tasks", [{ ...values?.tasks?.[0], scheduled_event_behavior: "Neutral" }])}
          />
          <AppText fontSize={12} fontWeight={500}>
            Do not delay this step based on specific time event times
          </AppText>
        </ScheduleEventOption>
        <ScheduleEventOption gap={8} style={{ borderBottom: `1px solid ${theme.NEUTRAL200}` }}>
          <PhoenixRadio
            selected={values?.tasks?.[0]?.scheduled_event_behavior === "Replacement"}
            onClick={() =>
              entryCriteriaPhase !== "HeldPhase" &&
              setFieldValue("tasks", [{ ...values?.tasks?.[0], scheduled_event_behavior: "Replacement" }])
            }
          />
          <AppText
            fontSize={12}
            fontWeight={500}
            color={entryCriteriaPhase !== "HeldPhase" ? theme.text.neutral.primary : theme.text.neutral.secondary}
          >
            Replace step with specific time event
          </AppText>
        </ScheduleEventOption>
        <ScheduleEventOption gap={8}>
          <PhoenixRadio
            selected={values?.tasks?.[0]?.scheduled_event_behavior === "Exit"}
            onClick={() =>
              entryCriteriaPhase !== "HeldPhase" &&
              setFieldValue("tasks", [{ ...values?.tasks?.[0], scheduled_event_behavior: "Exit" }])
            }
          />
          <AppText
            fontSize={12}
            fontWeight={500}
            color={entryCriteriaPhase !== "HeldPhase" ? theme.text.neutral.primary : theme.text.neutral.secondary}
          >
            Remove lead from sequence if specific time event is scheduled
          </AppText>
        </ScheduleEventOption>
      </ScheduleEventBottom>
    </ScheduleEventContainer>
  );
};

const ScheduleEventContainer = styled.div`
  margin-top: auto;
  border: 1px solid ${theme.NEUTRAL450};
  border-radius: 8px;
  box-shadow: 0px 0px 1px 0px ${theme.NEUTRAL450};
`;

const IconWrap = styled.div`
  width: fit-content;
  height: fit-content;
  margin-bottom: auto;
  padding: 4px;
  border-radius: 50%;
  background-color: ${theme.NEUTRAL450};
`;

const ScheduleEventTop = styled(FlexDiv)`
  padding: 16px 0px 16px 12px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;
const ScheduleEventBottom = styled(FlexDiv)`
  padding: 0px 16px 8px 16px;
`;

const ScheduleEventOption = styled(FlexDiv)`
  padding: 8px 0px;
`;
