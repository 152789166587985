import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { AppErrorText, AppText, FlexDiv, SkeletonBlock } from "../../UI";
import { PhoenixAppButton, PhoenixIcon } from "../../UI/Phoenix";

import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom";
import {
  caret_down,
  check,
  chevron_down,
  chevron_up,
  edit,
  info,
  more_vertical,
  plus,
  trash,
  xIcon,
} from "src/images/NewDesign";
import { OrgBrands } from "src/types";
import { useClickOutside, useFirstRender, useModalContext } from "src/utils/hooks";
import { loggedInUser } from "../../../apollo/cache";
import { EmailSettingsTab, OrganizationTokensTab, RegisterDomainTab, SMSSettingsTab } from "./BrandManagementTabs";
import { appToast } from "src/utils/toast";
import { useReactiveVar } from "@apollo/client";
import moment from "moment";
import { PhoenixStyledTooltip } from "src/Components/Dumb/PhoenixStyledTooltip";
import ReactTooltip from "react-tooltip";
import { useMultiBrands } from "src/cache-hooks/useIsMultiBrand";
import { checkIfBrandIsOnboarded, checkIfBrandIsOnboardedPerTab, getUrlSearchParam } from "src/utils/misc";

export const BRAND_MANAGEMENT_TABS = [
  {
    label: "Organization Tokens",
    value: "organization-tokens",
    component: OrganizationTokensTab,
  },
  {
    label: "Email Settings",
    value: "email-settings",
    component: EmailSettingsTab,
  },
  {
    label: "SMS Settings",
    value: "sms-settings",
    component: SMSSettingsTab,
  },
  {
    label: "Register Domain",
    value: "register-domain",
    component: RegisterDomainTab,
  },
];

export const goToNextTab = (history: ReturnType<typeof useHistory>) => {
  // const get the current tab from url
  const currentTab = window.location.pathname.split("/").pop();
  const currentIndex = BRAND_MANAGEMENT_TABS.findIndex((tab) => tab.value === currentTab);
  if (currentIndex < BRAND_MANAGEMENT_TABS.length - 1) {
    const nextTab = BRAND_MANAGEMENT_TABS[currentIndex + 1].value;
    history.push(`/system-config/brand-management/${nextTab}`);
  } else {
    history.push(`/system-config/brand-management/${BRAND_MANAGEMENT_TABS[0].value}`);
  }
};

export const goToPreviousTab = (history: ReturnType<typeof useHistory>) => {
  // const get the current tab from url
  const currentTab = history.location.pathname.split("/").pop();
  const currentIndex = BRAND_MANAGEMENT_TABS.findIndex((tab) => tab.value === currentTab);

  if (currentIndex > 0) {
    const previousTab = BRAND_MANAGEMENT_TABS[currentIndex - 1].value;
    history.push(`/system-config/brand-management/${previousTab}`);
  } else {
    history.push(`/system-config/brand-management/${BRAND_MANAGEMENT_TABS[BRAND_MANAGEMENT_TABS.length - 1].value}`);
  }
};

const DEFAULT_TAB = "organization-tokens";

export const StepBrandManagement = () => {
  const { ORG_BRANDS, isMultiBrandOrg, showBrandManagementPage, ORG_BRANDS_WITH_DELETED } = useMultiBrands(useFlags);

  const {
    showAddOrEditBrandModal,
    setShowAddOrEditBrandModal,
    addOrEditBrandModalData,
    setAddOrEditBrandModalData,
    showDeleteBrandModal,
    setShowDeleteBrandModal,
    deleteBrandModalData,
    setDeleteBrandModalData,
  } = useModalContext();

  const history = useHistory();

  // use url params
  const { tab_type_id: activeTab } = useParams<{ tab_type_id?: string }>();
  const [activeBrandId, setActiveBrandId] = useState<string | undefined>(undefined);

  // Update the active brand by navigating with the brand ID in the URL
  const updateActiveBrand = useCallback(
    (brandId: string) => {
      if (isMultiBrandOrg) {
        setActiveBrandId(brandId);
        // Update URL with the brand ID while preserving the current tab
        history.push(
          `/system-config/brand-management/${activeTab || DEFAULT_TAB}${isMultiBrandOrg ? `?brandId=${brandId}` : ""}`,
        );
      }
    },
    [activeTab, history, isMultiBrandOrg],
  );

  // Extract brandId from URL query parameters on component mount and tab changes
  useEffect(() => {
    const brandIdFromUrl = getUrlSearchParam("brandId");

    if (brandIdFromUrl) {
      setActiveBrandId(brandIdFromUrl);
    } else if (!activeBrandId) {
      // If no brand ID in URL, use the first brand if available
      if (isMultiBrandOrg) {
        updateActiveBrand(ORG_BRANDS[0].id);
      }
    }
  }, [loggedInUser()?.organization?.Brands, activeBrandId, updateActiveBrand, window.location.search]);

  const [upToDate, setUpToDate] = useState(true);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [selectedMoreOptionBrandId, setSelectedMoreOptionBrandId] = useState<string | undefined>(undefined);
  const moreOptionsRef = useRef<HTMLDivElement>(null);
  // Close more options menu when clicking outside
  useClickOutside(moreOptionsRef, () => setShowMoreOptions(false), ["more-options-menu"]);

  const handleDeleteBrand = useCallback(
    (brandId: string | undefined) => {
      if (brandId) {
        setShowDeleteBrandModal(true);
        setDeleteBrandModalData({
          brand_id: brandId,
        });
      } else {
        appToast("No brand selected");
      }
    },
    [setShowDeleteBrandModal, setDeleteBrandModalData],
  );

  const handleRenameBrand = useCallback(
    (brandId: string | undefined) => {
      if (brandId) {
        setShowAddOrEditBrandModal(true);
        setAddOrEditBrandModalData({
          brand_id: brandId,
        });
      } else {
        appToast("No brand selected");
      }
    },
    [setShowAddOrEditBrandModal, setAddOrEditBrandModalData],
  );

  const handleAddBrand = useCallback(() => {
    setShowAddOrEditBrandModal(true);
    setAddOrEditBrandModalData({
      brand_id: undefined,
    });
  }, [setShowAddOrEditBrandModal, setAddOrEditBrandModalData]);

  const currentBrandIsDeleted = useMemo(
    () => !!ORG_BRANDS?.find((brand: OrgBrands) => brand.id === activeBrandId)?.deleted_at,
    [ORG_BRANDS, activeBrandId],
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [currentBrandIsDeleted, activeBrandId]);

  const orgHasOneBrandOnly = useMemo(() => {
    return ORG_BRANDS?.length === 1;
  }, [ORG_BRANDS]);

  const brandDoesNotExistUnderOrganization = useCallback(
    (brandId: string | undefined) => {
      // default org or stil loading
      if (!brandId) return true;
      return !ORG_BRANDS_WITH_DELETED?.find((brand: OrgBrands) => brand.id === brandId);
    },
    [ORG_BRANDS_WITH_DELETED],
  );

  if (!showBrandManagementPage || showBrandManagementPage === undefined) return null;

  if (isMultiBrandOrg && brandDoesNotExistUnderOrganization(activeBrandId)) {
    return (
      <Main>
        <FlexDiv direction="column" gap={16} align="center" justify="center" height="100%">
          <AppText>Brand does not exist under organization</AppText>
          <PhoenixAppButton
            variant="brand-outline"
            buttonType="ghost-large"
            onClick={() => {
              history.push(`/system-config/brand-management/${DEFAULT_TAB}`);
            }}
          >
            Go to first brand
          </PhoenixAppButton>
        </FlexDiv>
      </Main>
    );
  }

  return (
    <Main>
      <PhoenixStyledTooltip id="brand-management-tooltip" delayShow={300} />
      <Header>
        <HeaderTop>
          <FlexDiv direction="column" gap={4}>
            <AppText fontSize={22} fontWeight={500} lineHeight={28}>
              Brand Management
            </AppText>
            <AppText fontSize={12} fontWeight={400} lineHeight={18} color={theme.text.neutral.secondary}>
              Configure these settings to set up your brand.
            </AppText>
          </FlexDiv>
          {isMultiBrandOrg && (
            <FlexDiv gap={8} align="center">
              {ORG_BRANDS_WITH_DELETED?.map((brand: OrgBrands) => (
                <div key={brand.id} style={{ position: "relative", width: "fit-content" }}>
                  <BrandPillContainer
                    selected={brand.id === activeBrandId}
                    deleted={!!brand.deleted_at}
                    data-tip={
                      !!brand.deleted_at ? "This brand was deleted. All information is reference only." : undefined
                    }
                    data-for="brand-management-tooltip"
                  >
                    <BrandNameSection onClick={() => updateActiveBrand(brand.id)} selected={brand.id === activeBrandId}>
                      {brand.name}
                    </BrandNameSection>
                    <MoreIconSection
                      className="more-options-menu"
                      onClick={(e) => {
                        if (!!brand.deleted_at) return;
                        e.stopPropagation();
                        setShowMoreOptions(!showMoreOptions);
                        setSelectedMoreOptionBrandId(brand.id);
                      }}
                      deleted={!!brand.deleted_at}
                      selected={brand.id === activeBrandId}
                    >
                      <PhoenixIcon
                        svg={caret_down}
                        size={12}
                        variant={brand.id === activeBrandId ? "white" : "brand"}
                        fillIcon
                        pointer={!brand.deleted_at}
                      />
                    </MoreIconSection>
                  </BrandPillContainer>

                  {showMoreOptions && isMultiBrandOrg && brand.id === selectedMoreOptionBrandId && (
                    <MoreOptionsMenu ref={moreOptionsRef}>
                      <MoreOptionItem
                        onClick={() => {
                          if (brand.id) {
                            handleRenameBrand(brand.id);
                            setShowMoreOptions(false);
                          } else {
                            appToast("No brand selected");
                          }
                        }}
                        disabled={false}
                      >
                        <PhoenixIcon svg={edit} size={16} pointer variant="brand" />
                        Rename Brand
                      </MoreOptionItem>
                      <MoreOptionItem
                        disabled={orgHasOneBrandOnly}
                        onClick={() => {
                          if (brand.id) {
                            handleDeleteBrand(brand.id);
                            setShowMoreOptions(false);
                          } else {
                            appToast("No brand selected");
                          }
                        }}
                      >
                        <PhoenixIcon svg={trash} size={16} pointer variant="danger" />
                        Delete Brand
                      </MoreOptionItem>
                    </MoreOptionsMenu>
                  )}
                </div>
              ))}

              <PhoenixAppButton variant="brand-outline" buttonType="ghost-large" onClick={handleAddBrand}>
                <FlexDiv gap={8} align="center">
                  <PhoenixIcon svg={plus} variant="brand" size={16} pointer />
                  Add Brand
                </FlexDiv>
              </PhoenixAppButton>
            </FlexDiv>
          )}
        </HeaderTop>
        <NavTabs>
          {BRAND_MANAGEMENT_TABS.map((tab) => (
            <NavTab
              key={tab.value}
              active={tab.value === activeTab}
              onClick={() => {
                history.push(
                  `/system-config/brand-management/${tab.value}${isMultiBrandOrg ? `?brandId=${activeBrandId}` : ""}`,
                );
              }}
            >
              <FlexDiv align="center" gap={8} style={{ cursor: "pointer" }}>
                {isMultiBrandOrg && (
                  <PhoenixIcon
                    svg={checkIfBrandIsOnboardedPerTab(activeBrandId, tab.value) ? check : xIcon}
                    variant={checkIfBrandIsOnboardedPerTab(activeBrandId, tab.value) ? "success" : "danger"}
                    size={16}
                    pointer
                  />
                )}
                {tab.label}
              </FlexDiv>
            </NavTab>
          ))}
        </NavTabs>
      </Header>

      <Body>
        <BodyContent>
          {currentBrandIsDeleted && (
            <AppText
              color={theme.NEUTRAL300}
              fontSize={10}
              fontWeight={500}
              style={{
                marginBottom: 16,
              }}
            >
              This brand was deleted. All information is reference only.
            </AppText>
          )}
          <Switch>
            <Route path="/system-config/brand-management/organization-tokens" exact>
              <OrganizationTokensTab
                brandId={activeBrandId}
                upToDate={upToDate}
                setUpToDate={setUpToDate}
                brandDeleted={
                  !!ORG_BRANDS_WITH_DELETED?.find((brand: OrgBrands) => brand.id === activeBrandId)?.deleted_at
                }
              />
            </Route>
            <Route path="/system-config/brand-management/email-settings" exact>
              <EmailSettingsTab
                brandId={activeBrandId}
                upToDate={upToDate}
                setUpToDate={setUpToDate}
                brandDeleted={
                  !!ORG_BRANDS_WITH_DELETED?.find((brand: OrgBrands) => brand.id === activeBrandId)?.deleted_at
                }
              />
            </Route>
            <Route path="/system-config/brand-management/sms-settings" exact>
              <SMSSettingsTab
                brandId={activeBrandId}
                upToDate={upToDate}
                setUpToDate={setUpToDate}
                brandDeleted={
                  !!ORG_BRANDS_WITH_DELETED?.find((brand: OrgBrands) => brand.id === activeBrandId)?.deleted_at
                }
              />
            </Route>
            <Route path="/system-config/brand-management/register-domain" exact>
              <RegisterDomainTab
                brandId={activeBrandId}
                upToDate={upToDate}
                setUpToDate={setUpToDate}
                brandDeleted={
                  !!ORG_BRANDS_WITH_DELETED?.find((brand: OrgBrands) => brand.id === activeBrandId)?.deleted_at
                }
              />
            </Route>
            <Redirect to={`/system-config/brand-management/${DEFAULT_TAB}`} />
          </Switch>
        </BodyContent>
      </Body>
    </Main>
  );
};

export const TabBodyHeader = ({
  handleSave,
  upToDate,
  setUpToDate,
  saveDisabled,
  handlePageUpdates = true,
}: {
  handleSave: () => void;
  upToDate: boolean;
  setUpToDate: (upToDate: boolean) => void;
  saveDisabled?: boolean;
  handlePageUpdates?: boolean;
}) => {
  const history = useHistory();

  return (
    <BodyHeader>
      <PhoenixAppButton
        variant="brand-outline"
        buttonType="secondary"
        onClick={() => {
          goToPreviousTab(history);
        }}
      >
        Previous
      </PhoenixAppButton>

      <FlexDiv gap={16} align="center">
        {handlePageUpdates && !saveDisabled && (
          <>
            {upToDate ? (
              <FlexDiv gap={8} align="center">
                <PhoenixIcon svg={check} variant="neutral" size={16} />
                <AppText color={theme.text.neutral.secondary} fontSize={12} fontWeight={500}>
                  Up to date
                </AppText>
              </FlexDiv>
            ) : (
              <FlexDiv gap={8} align="center">
                <PhoenixIcon svg={info} variant="neutral" size={16} />

                <AppText color={theme.text.neutral.secondary} fontSize={12} fontWeight={500}>
                  You have unsaved changes
                </AppText>
              </FlexDiv>
            )}
          </>
        )}
        <FlexDiv gap={8} align="center">
          {handlePageUpdates && (
            <PhoenixAppButton
              variant="brand"
              buttonType="secondary"
              onClick={async () => {
                await handleSave();
                setUpToDate(true);
              }}
              disabled={saveDisabled}
            >
              Save
            </PhoenixAppButton>
          )}
          <PhoenixAppButton
            variant="brand-outline"
            buttonType="secondary"
            onClick={() => {
              goToNextTab(history);
            }}
          >
            Next
          </PhoenixAppButton>
        </FlexDiv>
      </FlexDiv>
    </BodyHeader>
  );
};

const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  padding: 0px 16px;
`;

const BrandPillContainer = styled.div<{ selected: boolean; deleted: boolean }>`
  display: flex;
  align-items: center;
  max-width: 200px;
  border-radius: 20px;
  background-color: ${(props) => (props.selected ? theme.fill.brand.primary : theme.WHITE_COLOR)};
  overflow: hidden;
  cursor: pointer;
  border: 1px solid ${(props) => (props.selected ? theme.WHITE_COLOR : theme.border.brand.primary)};
  opacity: ${(props) => (props.deleted ? 0.2 : 1)};
`;

const BrandNameSection = styled.div<{ selected: boolean }>`
  padding: 6px 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  font-size: 10px;
  font-weight: 600;
  color: ${(props) => (props.selected ? theme.WHITE_COLOR : theme.text.brand.primary)};
`;

const MoreIconSection = styled.div<{ selected: boolean; deleted: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 6px;
  cursor: pointer;
  border-left: 1px solid ${(props) => (props.selected ? theme.WHITE_COLOR : theme.border.brand.primary)};
  height: 100%;
`;

const BodyHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.border.neutral.secondary};
  background-color: ${theme.WHITE_COLOR};
`;

const Main = styled.div`
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  border-radius: 8px;
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const HeaderTop = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 40px;
  gap: 16px;
`;

const Body = styled.div`
  flex: 1;
  overflow: hidden;
  padding: 24px;
`;

const NavTab = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  color: ${({ active }) => (active ? theme.text.neutral.primary : theme.text.neutral.secondary)};
  border-bottom: 1px solid ${({ active }) => (active ? theme.border.brand.primary : "transparent")};
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
`;

const NavTabs = styled.div`
  display: flex;
  padding: 0px 24px;
`;

const MoreOptionsMenu = styled.div`
  position: absolute;
  background-color: ${theme.WHITE_COLOR};
  border-radius: 4px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 10;
  min-width: 202px;
  overflow: hidden;
  top: 110%;
  left: 0;
  border: 1px solid ${theme.border.neutral.primary};
  animation: ${theme.lightPopup} 0.2s ease-in-out;
`;

const MoreOptionItem = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  font-size: 12px;
  font-weight: 400;
  color: ${theme.text.neutral.primary};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  &:hover {
    background-color: ${theme.PRIMARY50};
  }
`;
