import { gql, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import moment from "moment";
import * as React from "react";
import { useState, useMemo } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { BiCalendar } from "react-icons/bi";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { sanitizeURL } from "../../utils/misc";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { CalendarToolbar } from "../Segments/CalendarToolbar";
import { EventList } from "../Smart";
import { EventDetails } from "../Smart/";
import { AppErrorText, AppText, Loading } from "../UI";
import { RequestTimeOff } from "../modal/RequestTimeoff";
import { OptionItem } from "../../types/genericTypes";
import { FETCH_HISTORICAL_ITEM } from "../Smart/MyScheduleCal";
// Data for both views.
const FETCH_EVENTS = gql`
  query {
    fetchEventManagerEvents
  }
`;

const FETCH_SCHEDULE_ITEM = gql`
  query scheduleItem($id: String!) {
    scheduleItem(where: { id: $id }) {
      id
      start_time
      deleted_at
      lead_intent {
        id
        lead {
          id
          full_name
        }
      }
    }
  }
`;

interface ParamTypes {
  event_id: string;
}

const EventManager: React.FC = () => {
  const { event_id } = useParams<ParamTypes>();
  const history = useHistory();

  const [activeView, setActiveView] = useState("Calendar");
  const [timeOffData, setTimeOffData] = useState({
    id: "",
    startTime: new Date(0),
    endTime: new Date(0),
    userFullName: "",
  });

  const [editTimeOffOn, setEditTimeoffOn] = useState(false);
  const [blinds, setBlinds] = useState(false);
  const [userFilter, setUserFilter] = useState("");
  const [eventFilter, setEventFilter] = useState<OptionItem[]>([]);

  const localizer = momentLocalizer(moment);

  const { data: eventData, loading: eventLoading, error: eventError, refetch: refetchEventData } = useQuery(
    FETCH_EVENTS,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
        appToast(message);
      },
    },
  );

  const { data: dataEvent, loading: loadingEvent, error: errorEvent, refetch: refetchEvent } = useQuery(
    FETCH_SCHEDULE_ITEM,
    {
      fetchPolicy: "network-only",
      skip: !event_id,
      variables: { id: event_id },
      onCompleted({ scheduleItem }) {
        setEditTimeoffOn(false);
        setActiveView("Event");
      },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
        appToast(message);
      },
    },
  );

  const { data: dataHistoricalItem, loading: loadingHistoricalItem, error: errorHistoricalItem } = useQuery(
    FETCH_HISTORICAL_ITEM,
    {
      fetchPolicy: "network-only",
      skip: !event_id && !dataEvent?.scheduleItem?.deleted_at,
      variables: { id: event_id },
      onCompleted() {
        setEditTimeoffOn(false);
        setActiveView("Event");
      },
    },
  );

  const handleEditClick = (id: string, time: string, lead_id: string) => {
    const santizedURL = sanitizeURL(`/event-manager/${id}`);
    history.push(santizedURL);
  };

  const handleTimeoffClick = (id: string, startTime: Date, endTime: Date, userFullName?: string) => {
    setTimeOffData({ id: id, startTime: startTime, endTime: endTime, userFullName: userFullName ? userFullName : "" });

    setEditTimeoffOn(true);
    setBlinds(true);
  };

  const handleOutsideModalClick = () => {
    setEditTimeoffOn(false);
    setBlinds(false);
  };

  const calendarData = useMemo(() => {
    let events = eventData?.fetchEventManagerEvents
      ?.slice()
      ?.filter((item: any) => !!!userFilter || item?.lead_intent?.user?.id === userFilter)
      ?.map((item: any) => {
        return {
          title: item.timeoff_request
            ? "Time-Off"
            : `${item.lead_intent?.lead?.business_name} - ${item.lead_intent?.label ?? "Event"}`,
          start: moment(item.start_time).toDate(),
          end: moment(item.end_time).toDate(),
          color: item.timeoff_request
            ? theme.TERTIARY500
            : item.lead_intent?.associated_action === "Follow Up Demo"
            ? "#4EA1E0"
            : item.lead_intent?.associated_action === "Decision Call"
            ? "#085C34"
            : item.lead_intent?.type === "Demo"
            ? "#0A183A"
            : item.lead_intent?.type === "ScheduledCallBack"
            ? "#3D0E72"
            : theme.PRIMARY500,
          timeoff_request: item.timeoff_request,
          id: item?.id,
          full_name: item.lead_intent?.user?.full_name ?? "",
          lead_id: item.lead_intent?.lead?.id,
          intent: item?.lead_intent,
        };
      });

    const eventFilterValues = eventFilter?.map((item) => item.value);

    events = events?.filter((item: any) => {
      if (!eventFilterValues.length) return true;
      if (eventFilterValues.includes("Decision Call") && item.intent?.associated_action === "Decision Call") {
        return true;
      }
      if (eventFilterValues.includes("Follow Up Demo") && item.intent?.associated_action === "Follow Up Demo") {
        return true;
      }
      if (eventFilterValues.includes("Demo") && item.intent?.type === "Demo") {
        return true;
      }
      if (eventFilterValues.includes("ScheduledCallBack") && item.intent?.type === "ScheduledCallBack") {
        return true;
      }
      return false;
    });

    return events;
  }, [eventData, eventFilter, userFilter]);

  if (eventLoading || loadingEvent || loadingHistoricalItem) {
    return <Loading />;
  }

  if (eventError) {
    return (
      <PaddingDiv>
        <AppErrorText>Error loading event</AppErrorText>
      </PaddingDiv>
    );
  }

  const oneLevelListData = eventData.fetchEventManagerEvents
    .filter((item: any) => item.lead_intent) // BE data is a little rough. I'd like for this to check the boolean of item.timeoff_request.result instead.
    ?.map((item: any) => {
      return {
        id: item?.id,
        time: item?.start_time,
        salesperson: item?.lead_intent?.user?.full_name,
        role: item?.lead_intent?.user?.role,
        contact: item?.lead_intent?.lead?.full_name,
        event: item?.lead_intent?.type,
        event_label: item?.lead_intent.label,
        lead_id: item?.lead_intent?.lead?.id,
      };
    });

  const repsData = eventData.fetchEventManagerEvents?.map((item: any) => {
    return {
      label: item.lead_intent?.user?.full_name ?? "",
      value: item.lead_intent?.user?.id ?? "",
    };
  });

  // This is where you style the calendar events.
  function eventStyleGetter(event: any) {
    const style = {
      backgroundColor: event.color,
    };
    return {
      style: style,
    };
  }

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured on the Event Manager page."}>
      <PaddingDiv>
        {blinds && <DarkDiv onClick={() => handleOutsideModalClick()} />}
        {activeView === "List" && (
          <Main>
            <HeaderDiv>
              <TitleDiv>
                <CenterDiv>
                  <TitleText>Event Manager</TitleText>
                </CenterDiv>
              </TitleDiv>
            </HeaderDiv>
            <div>
              <CalendarViewButton onClick={() => setActiveView("Calendar")}>
                <BiCalendar style={{ marginRight: "10px" }} />
                Back to Calendar View
              </CalendarViewButton>
              <ListContainer>
                <EventList data={oneLevelListData} handleEditClick={handleEditClick} />
              </ListContainer>
            </div>
          </Main>
        )}

        {activeView === "Calendar" && (
          <CalendarContainer>
            <Calendar
              //@ts-ignore
              components={{
                toolbar: (props) => (
                  <CalendarToolbar
                    //@ts-ignore
                    // optionsReps={repsData}
                    value={userFilter}
                    setUserFilter={setUserFilter}
                    setActiveView={setActiveView}
                    eventFilter={eventFilter}
                    handleEventFilterChange={(e: OptionItem[]) => setEventFilter(e)}
                    {...props}
                  />
                ),
              }}
              popup={true}
              selectable={true}
              localizer={localizer}
              events={calendarData}
              eventPropGetter={eventStyleGetter}
              views={["day", "week", "month"]}
              defaultView="month"
              onSelectEvent={(e) => {
                if (e.timeoff_request) {
                  handleTimeoffClick(e.id, e.start, e.end, e.full_name);
                }
                if (!e.timeoff_request) {
                  handleEditClick(e.id, e.start, e.lead_id);
                }
              }}
            />
          </CalendarContainer>
        )}

        {activeView === "Event" && (
          <EventDetails
            eventEditData={
              dataEvent?.scheduleItem?.deleted_at
                ? {
                    id: dataHistoricalItem?.fetchHistoricalEvent?.schedule_item?.id,
                    time: dataHistoricalItem?.fetchHistoricalEvent?.schedule_item?.start_time,
                    lead_id: dataHistoricalItem?.fetchHistoricalEvent?.lead?.id,
                  }
                : {
                    id: dataEvent?.scheduleItem?.id,
                    time: dataEvent?.scheduleItem?.start_time,
                    lead_id: dataEvent?.scheduleItem?.lead_intent?.lead?.id,
                  }
            }
          />
        )}

        {editTimeOffOn && (
          <RequestTimeOff
            id={timeOffData.id}
            full_name={timeOffData.userFullName ? timeOffData.userFullName : ""}
            start={timeOffData.startTime}
            end={timeOffData.endTime}
            onClinkingClose={() => {
              setEditTimeoffOn(false);
              setBlinds(false);
            }}
            refetchData={refetchEventData}
          />
        )}
      </PaddingDiv>
    </Sentry.ErrorBoundary>
  );
};

const PaddingDiv = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  height: 100%;
  overflow-y: auto;
`;

const CalendarContainer = styled.div`
  width: 100%;
  height: 94vh;
  background: ${theme.WHITE_COLOR};
  border-radius: 3px;

  .rbc-calendar {
    background-color: ${theme.NEUTRAL100};
  }

  .rbc-header {
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 50px;
    background: ${theme.PRIMARY50};
    z-index: 1;

    font-weight: 600;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .rbc-month-view,
  .rbc-time-view {
    border: 1px solid ${theme.NEUTRAL200};
    border-radius: 8px;
    overflow: hidden;
  }

  .rbc-time-slot,
  .rbc-day-bg {
    background-color: ${theme.WHITE_COLOR};
  }
`;

const CalendarViewButton = styled.div`
  border-radius: 4px;
  font-size: 12px;
  padding: 10px 10px;
  border: solid 1.5px #16151a;
  background-color: #ffffff;
  height: 36px;
  width: 183px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 19px;
  margin-left: auto;
`;

const CenterDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CenterPopupDiv = styled.div`
  position: absolute;
  top: 10%;
  left: 30%;
`;

const DarkDiv = styled.div`
  background-color: rgba(22, 21, 26, 0.5);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 5;
`;

const HeaderDiv = styled.div`
  width: 100%;
  height: 139px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListContainer = styled.div`
  margin: auto;
`;

const Main = styled.div`
  min-width: 1250px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: calc(100vw - 72px);
  min-height: calc(100vh);
  height: auto;
  background: ${theme.surface.neutral.tertiary};
  padding: 20px;
`;

const TitleDiv = styled.div`
  position: absolute;
  top: 40px;
  width: 246px;
  height: 64px;
`;

const TitleText = styled(AppText)`
  font-size: 15px;
  font-weight: bold;
`;

export { EventManager };
