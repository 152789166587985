import styled from "styled-components";
import React from "react";
import { cloudinary } from "../../../services/cloudinary";
import { AdvancedImage } from "@cloudinary/react";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import { computeNameFromFirstAndLast } from "../../../utils/format";
import { FlexDiv } from "src/Components/UI";

interface ProfileImageProps {
  profile_image?: string;
  first_name?: string;
  last_name?: string;
  flipName?: boolean;
  // overrides
  width?: number;
  height?: number;
  textSize?: number | string;
  hideTooltip?: boolean;
  border?: boolean;
}

const ProfileImageWithText: React.FC<ProfileImageProps> = ({
  profile_image,
  first_name,
  last_name,
  flipName = false,
  width,
  height,
  textSize,
  border = true,
  hideTooltip,
}) => {
  const computedName = computeNameFromFirstAndLast({ first_name, last_name, flipName });

  const ableToComputeFullName = computedName !== "NA";

  const firstInitial = first_name?.charAt(0)?.toUpperCase() || "";

  const lastInitial = last_name?.charAt(0)?.toUpperCase() || "";

  return (
    <>
      {hideTooltip ? null : (
        <PhoenixStyledTooltip multiline place="top" effect="solid" id="name" width={"max-content"} />
      )}
      <ImageOuterCircle width={width} height={height}>
        <FlexDiv data-tip={computedName} data-for="name">
          {/* inside circle */}
          {border ? (
            <ImageInnerCircle width={width ? width - 4 : undefined} height={height ? height - 4 : undefined}>
              {/* had to use this layout rather than the ProfileImg as you can not use a gradiant on a border with a border radius */}
              {!!profile_image ? (
                <AdvancedImage
                  cldImg={cloudinary.image(profile_image).quality("auto:low")}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                />
              ) : (
                <PersonsInitials textsize={textSize}>
                  {ableToComputeFullName ? `${firstInitial}${lastInitial}` : "NA"}
                </PersonsInitials>
              )}
            </ImageInnerCircle>
          ) : !!profile_image ? (
            <AdvancedImage
              cldImg={cloudinary.image(profile_image).quality("auto:low")}
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}
            />
          ) : (
            <PersonsInitials textsize={textSize}>
              {ableToComputeFullName ? `${firstInitial}${lastInitial}` : "NA"}
            </PersonsInitials>
          )}
        </FlexDiv>
      </ImageOuterCircle>
    </>
  );
};

const ImageOuterCircle = styled.div<{
  width?: number;
  height?: number;
  border?: boolean;
}>`
  width: ${(props) => (props.width ? `${props.width}px` : "40px")};
  height: ${(props) => (props.height ? `${props.height}px` : "40px")};
  background-image: ${(props) =>
    props.border
      ? `
  linear-gradient(to bottom right, #3e65ff, #669aff)`
      : "none"};
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  cursor: default;
  overflow: hidden;
`;

const ImageInnerCircle = styled.div<{
  width?: number;
  height?: number;
}>`
  width: ${(props) => (props.width ? `${props.width}px` : "36px")};
  height: ${(props) => (props.height ? `${props.height}px` : "36px")};
  border: 2px solid #fbfbfb;
  border-radius: 100%;
  background-color: #fbfbfb;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const PersonsInitials = styled.span<{ textsize?: number | string }>`
  font-family: Inter;
  font-style: normal;
  font-size: ${(props) =>
    props.textsize ? (typeof props.textsize === "number" ? `${props.textsize}px` : props.textsize) : "14px"};
  line-height: 21px;
  color: #949ab1;
  letter-spacing: 0.2px;
  font-weight: 600;
`;

export { ProfileImageWithText };
