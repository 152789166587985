import { Dispatch, useCallback, useContext, useEffect, useRef, useState } from "react";
import { hasValue } from "./misc";
import { EventEmitter } from "events";
import { ModalContext } from "src/context";
import { throttle } from "lodash";

/**
 * triggers a callback function onChange of the dependencies after the specified delay
 *  */
export const useDebounce = (effect: () => void, dependencies: any[], delay: number) => {
  const callback = useCallback(effect, dependencies);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
};

/** This hook takes a useRef and callback as params and runs the callback when clicking outside of the ref DOM element
 * : Useful for popup menu's
 *
 * @param ref useRef hook reference - Checks the element we're clicking outside of
 * @param callback callback function that runs when clicking outside of the 'ref'
 */

export const useClickOutside = (
  refs: React.RefObject<HTMLElement> | Array<React.RefObject<HTMLElement>>,
  callback: () => void,
  ignoreClassNames: string[] = [],
) => {
  const callbackRef = useRef(callback);
  const isClosingRef = useRef(false);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (isClosingRef.current) return;

      const refsArray = Array.isArray(refs) ? refs : [refs];

      const isWithinIgnoredElement = ignoreClassNames.some((className) => {
        const elements = document.getElementsByClassName(className);
        return Array.from(elements).some((element) => element.contains(e.target as Node));
      });

      if (isWithinIgnoredElement) return;

      const clickedOutside = refsArray.every((ref) => ref.current && !ref.current.contains(e.target as Node));

      if (clickedOutside) {
        isClosingRef.current = true;
        callbackRef.current();
        setTimeout(() => {
          isClosingRef.current = false;
        }, 100);
      }
    },
    [refs, ignoreClassNames],
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClick, true);
    document.addEventListener("touchstart", handleClick as EventListener, true);

    return () => {
      document.removeEventListener("mousedown", handleClick, true);
      document.removeEventListener("touchstart", handleClick as EventListener, true);
    };
  }, [handleClick]);
};

type UseTabFocusOptions = {
  buffer?: number;
  waitTime?: number;
  cleanup?: () => void;
};

/** Custom React hook that adds a visibility change event listener to the document.
 *
 * Invokes the provided callback when the tab comes into focus (becomes visible).
 *
 * Optionally accepts an args object for more customization:
 * - buffer: Optional buffer between callback executions.
 * - waitTime: Optional wait time. If the tab is still visible by the end of the wait time, the callback will be invoked.
 * - cleanup: Optional cleanup callback to be executed on component unmount.
 *
 * @param {() => void} callback - Callback function to be invoked on tab focus.
 * @param {UseTabFocusOptions} [args] - Optional args object for customization.
 * @returns {void}
 */
export const useTabFocus = (callback: () => void, args?: UseTabFocusOptions): void => {
  useEffect(() => {
    let bufferTimeout: NodeJS.Timeout | null = null;
    let waitTimeout: NodeJS.Timeout | null = null;

    let { buffer, waitTime, cleanup } = args || {};

    const handleBuffer = () => {
      if (!!buffer) {
        if (!bufferTimeout) {
          callback();
          bufferTimeout = setTimeout(() => {
            bufferTimeout = null;
          }, buffer);
        }
      } else {
        callback();
      }
    };

    const handleVisibilityChange = () => {
      if (!!waitTime) {
        if (!waitTimeout) {
          waitTimeout = setTimeout(() => {
            if (document.visibilityState === "visible") {
              handleBuffer();
            }
            waitTimeout = null;
          }, waitTime);
        }
      } else {
        if (document.visibilityState === "visible") {
          handleBuffer();
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (cleanup) {
        cleanup();
      }
      if (bufferTimeout) {
        clearTimeout(bufferTimeout);
      }
      if (waitTimeout) {
        clearTimeout(waitTimeout);
      }
    };
  }, []);
};
type PersitableStateOptions<V extends any> = {
  onGet?: (persistedValue: any) => V;
  onSet?: (persistedValue: V) => any;
};

export const useSessionStoragePersistedState = <V extends any>(
  key: string,
  defaultValue: V,
  options?: PersitableStateOptions<V>,
): [V, Dispatch<V>] => {
  const { onGet, onSet } = options || {};

  const [state, setState] = useState(() => {
    let persistedState = sessionStorage.getItem(key)!;
    let final = persistedState ? (onGet ? onGet(persistedState) : (persistedState as V)) : defaultValue;
    return final;
  });

  useEffect(() => {
    const value = onSet ? onSet(state) : state;
    sessionStorage.setItem(key, value);
  }, [key, state]);

  return [state, setState];
};

export const useLocalStoragePersistedState = <V extends any>(
  key: string,
  defaultValue: V,
  options?: PersitableStateOptions<V>,
): [V, Dispatch<V>] => {
  const { onGet, onSet } = options || {};

  const [state, setState] = useState(() => {
    let persistedState = localStorage.getItem(key)!;
    let final = persistedState ? (onGet ? onGet(persistedState) : (persistedState as V)) : defaultValue;
    return final;
  });

  useEffect(() => {
    const value = onSet ? onSet(state) : state;
    localStorage.setItem(key, value);
  }, [key, state]);

  return [state, setState];
};

/** This hook tracks if the component is rendering for the first time
 *
 * @returns {boolean} - Returns true if the component is rendering for the first time, otherwise false
 */
export const useFirstRender = () => {
  const isFirstRender = useRef(true);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  return isFirstRender.current;
};

/**
 * A custom React hook to provide a debounced version of a state value.
 * @param {*} initialValue - The initial value of the state.
 * @param {number} delay - The delay (in milliseconds) after which the state should be updated with the latest value.
 * @returns {Array} An array containing the debounced state value and a function to update the state.
 */

export const useDebouncedState = <V extends any>(initialValue: V, delay: number): [V, Dispatch<V>] => {
  const [state, setState] = useState(initialValue);
  const [debouncedState, setDebouncedState] = useState(initialValue);

  useDebounce(() => setDebouncedState(state), [state], delay);

  return [debouncedState, setState];
};

/**
 * Custom hook that sets a component's open state based on filter conditions.
 *
 * This hook checks if the `isMoreFilter` flag is true and if the `value` is empty.
 * If both conditions are met, it sets the `isOpen` state to true.
 *
 * @param {boolean} isMoreFilter - A flag indicating whether the "more filter" condition is active.
 * @param {any} value - The value to be checked for emptiness.
 * @param {(isOpen: boolean) => void} setIsOpen - A function to set the open state of the component.
 */
export const useOpenOnMoreFilter = (value: any, setIsOpen: (isOpen: boolean) => void, isMoreFilter?: boolean) => {
  const hasOpenedRef = useRef(false);
  useEffect(() => {
    if (!hasOpenedRef.current && isMoreFilter && !hasValue(value)) {
      setIsOpen(true);
      hasOpenedRef.current = true;
    }
  }, [isMoreFilter, value, setIsOpen]);
};

interface DraggableOptions {
  onDragStart?: () => void;
  onDragEnd?: () => void;
}

/**
 * A custom React hook that provides drag functionality for a DOM element.
 *
 * This hook allows any fixed DOM element to be draggable within the viewport. It manages
 * the drag state and provides handlers to initiate and end the drag operation. The hook
 * also ensures that the element stays within the bounds of the document.
 *
 * @param {DraggableOptions} [options] - Optional configuration for the draggable behavior.
 * @param {Function} [options.onDragStart] - Callback function to be executed when dragging starts.
 * @param {Function} [options.onDragEnd] - Callback function to be executed when dragging ends.
 *
 * @returns {Object} - An object containing the following properties:
 * @returns {React.RefObject<HTMLDivElement>} draggableRef - A ref to be attached to the DOM element that should be draggable.
 * @returns {Function} handleDragStart - A function to be used as an event handler for the `onMouseDown` event to initiate dragging.
 * @returns {boolean} dragState - A boolean indicating whether the element is currently being dragged.
 *
 * @example
 * // Usage in a React component
 * const MyDraggableComponent = () => {
 *   const { draggableRef, handleDragStart, dragState } = useDraggable({
 *     onDragStart: () => console.log('Drag started'),
 *     onDragEnd: () => console.log('Drag ended'),
 *   });
 *
 *   return (
 *     <div
 *       ref={draggableRef}
 *       onMouseDown={handleDragStart}
 *       style={{ position: 'fixed', cursor: dragState ? 'grabbing' : 'grab' }}
 *     >
 *       Drag me!
 *     </div>
 *   );
 * };
 */
export const useDraggable = (options?: DraggableOptions) => {
  const draggableRef = useRef<HTMLDivElement>(null);
  const isDragging = useRef(false);
  const initialOffset = useRef({ x: 0, y: 0 });
  const [dragState, setDragState] = useState<boolean>(false);

  const handleDragStart = (e: React.MouseEvent) => {
    e.preventDefault();

    if ((e.target as HTMLElement).closest("[data-ignore-drag]")) return;

    if (draggableRef.current) {
      const ele = draggableRef.current;
      const boundingRect = ele.getBoundingClientRect();
      initialOffset.current = {
        x: e.clientX - boundingRect.left,
        y: e.clientY - boundingRect.top,
      };
      isDragging.current = true;
      setDragState(true);

      document.addEventListener("mousemove", handleDrag);
      document.addEventListener("mouseup", handleDragEnd);

      options?.onDragStart?.();
    }
  };

  const handleDrag = (e: MouseEvent) => {
    e.preventDefault();

    if (!isDragging.current) return;

    if (draggableRef.current) {
      const ele = draggableRef.current;
      const mousePosition = {
        x: e.clientX,
        y: e.clientY,
      };

      const newLeft = mousePosition.x - initialOffset.current.x;
      const newTop = mousePosition.y - initialOffset.current.y;

      const docWidth = document.body.clientWidth;
      const docHeight = document.body.clientHeight;

      const maxLeft = docWidth - ele.offsetWidth;
      const maxTop = docHeight - ele.offsetHeight;

      ele.style.left = `${Math.max(0, Math.min(newLeft, maxLeft))}px`;
      ele.style.top = `${Math.max(0, Math.min(newTop, maxTop))}px`;
    }
  };

  const handleDragEnd = () => {
    isDragging.current = false;
    setDragState(false);
    document.removeEventListener("mousemove", handleDrag);
    document.removeEventListener("mouseup", handleDragEnd);

    options?.onDragEnd?.();
  };

  return { draggableRef, handleDragStart, dragState };
};

const emitter = new EventEmitter();

export const PUB_SUB_EVENTS = {
  SAVED_VIEW_UPDATED: "saved_view_updated",
  COACHING_NOTE_DELETED: "coaching_note_deleted",
};

export const useSub = (event: keyof typeof PUB_SUB_EVENTS, callback: (...args: any[]) => void) => {
  const unsubscribe = () => {
    emitter.off(event, callback);
  };

  useEffect(() => {
    emitter.on(event, callback);
    return unsubscribe;
  }, []);

  return unsubscribe;
};

export const usePub = () => {
  return (event: keyof typeof PUB_SUB_EVENTS, data: any) => {
    emitter.emit(event, data);
  };
};

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("useModalContext must be used within a ModalContextProvider");
  }

  return context;
};

const compareDeployTimestamps = ({
  lastBreakingDeployTime,
  currentTabTime,
}: {
  lastBreakingDeployTime: Date;
  currentTabTime: Date;
}) => {
  const timeDiffInMilliseconds = currentTabTime.getTime() - lastBreakingDeployTime.getTime();

  const timeDiffInHours = timeDiffInMilliseconds / (1000 * 60 * 60);

  const timeDiffInMinutes = timeDiffInMilliseconds / (1000 * 60);

  const timeDiffInSeconds = timeDiffInMilliseconds / 1000;

  return {
    timeDiffInMilliseconds,
    timeDiffInHours,
    timeDiffInMinutes,
    timeDiffInSeconds,
  };
};

/**
 * Custom hook to handle breaking deploy timestamps and reload the app if necessary.
 */
export const useBreakingDeployTimestamp = () => {
  useEffect(() => {
    const currentTabTimestamp = new Date().toISOString();
    sessionStorage.setItem("CurrentTabTimestamp", currentTabTimestamp);
  }, []);

  useTabFocus(() => {
    if (process.env.REACT_APP_PUBLISH_DEPLOY_NOTIFICATION !== "true") return;

    const lastBreakingDeployTimestamp = localStorage.getItem("LatestBreakingDeploy");
    if (!lastBreakingDeployTimestamp) return;

    const currentTabTimestamp = sessionStorage.getItem("CurrentTabTimestamp");
    if (!currentTabTimestamp) return;

    const timeDiffInSeconds = compareDeployTimestamps({
      lastBreakingDeployTime: new Date(lastBreakingDeployTimestamp),
      currentTabTime: new Date(currentTabTimestamp),
    })?.timeDiffInSeconds;

    if (timeDiffInSeconds <= 3 && !sessionStorage.getItem("BreakingDeployTimestampReloaded")) {
      sessionStorage.setItem("BreakingDeployTimestampReloaded", "true");
      window.location.reload();
    }
  });
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = throttle(() => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }, 200);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      handleResize.cancel();
    };
  }, []);

  return windowSize;
};
