import React, { useMemo, useRef, useState } from "react";
import { loggedInUser } from "src/apollo/cache";
import { useFlags } from "launchdarkly-react-client-sdk";
import { AppText, FlexDiv } from "src/Components/UI";
import { PhoenixIcon, PhoenixInput } from "src/Components/UI/Phoenix";
import { check } from "src/images/NewDesign";
import { OptionItem } from "src/types";
import { useClickOutside } from "src/utils/hooks";
import { CustomField, generateLeadFieldOptions } from "src/utils/misc";
import { theme } from "src/utils/theme";
import styled, { css } from "styled-components";
import useMultiBrands from "src/cache-hooks/useIsMultiBrand";

interface IFieldMenu {
  index: number;
  customFieldData: CustomField[];
  leadCardFields: OptionItem[];
  setLeadCardFields: React.Dispatch<React.SetStateAction<OptionItem[]>>;
  setShowMenu?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FieldMenu: React.FC<IFieldMenu> = ({
  index,
  customFieldData,
  setShowMenu,
  leadCardFields,
  setLeadCardFields,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [fieldType, setFieldType] = useState<"System Fields" | "Custom Fields">("System Fields");
  const [search, setSearch] = useState<string | undefined>(undefined);

  const { showMultiBrandUI } = useMultiBrands(useFlags);
  const shouldShowBrandSystemField = useMemo(() => {
    return showMultiBrandUI;
  }, [showMultiBrandUI]);

  const optionList = useMemo(() => {
    const options =
      fieldType === "System Fields"
        ? generateLeadFieldOptions(shouldShowBrandSystemField)
        : customFieldData.map((e) => ({ label: e.key, value: e.key }));

    return options.filter((e) => (search ? e.label.toLowerCase().includes(search.toLowerCase()) : true));
  }, [fieldType, customFieldData, search]);

  useClickOutside(ref, () => {
    setShowMenu && setShowMenu(false);
  });

  return (
    <FieldMenuContainer ref={ref}>
      <PhoenixInput
        value={search}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
        height={40}
        placeholder="Search field"
        noFocusBorder
        displayNoContextText
        style={{
          borderWidth: "0px 0px 1px 0px",
          borderRadius: "0px",
          borderColor: theme.border.neutral.secondary,
          paddingLeft: "8px",
          paddingRight: "8px",
        }}
      />
      <AppText fontSize={12} color={theme.text.brand.primary} lineHeight={18} style={{ padding: "10px 8px" }}>
        {fieldType}
      </AppText>
      <div style={{ overflowY: "auto", maxHeight: "200px" }}>
        {optionList.map((e) => {
          const selected = leadCardFields.find((sf) => sf.value === e.value);
          return (
            <FieldOption
              key={`option[${e.value}]`}
              selected={!!selected}
              onClick={() => {
                if (selected) return;

                setLeadCardFields((prev) => {
                  const newFields = [...prev];
                  newFields[index] = e;
                  return newFields;
                });
                setShowMenu && setShowMenu(false);
              }}
            >
              {selected && (
                <PhoenixIcon
                  svg={check}
                  size={16}
                  color={theme.icon.brand.disabled}
                  hoverColor={theme.icon.brand.disabled}
                />
              )}
              <AppText
                fontSize={12}
                lineHeight={18}
                color={selected ? theme.text.neutral.disabled : theme.text.neutral.primary}
              >
                {e.label}
              </AppText>
            </FieldOption>
          );
        })}
      </div>
      <FlexDiv align="center" justify="space-between" height={40} padding="0px 12px" style={{ marginTop: "auto" }}>
        <AppText
          fontSize={10}
          fontWeight={600}
          color={theme.text.brand.primary}
          style={{ borderBottom: "0px", cursor: "pointer", userSelect: "none" }}
          onClick={() => {
            setLeadCardFields((prev) => {
              const newFields = [...prev];
              newFields.splice(index, 1);
              return newFields;
            });
            setShowMenu && setShowMenu(false);
          }}
        >
          Remove
        </AppText>
        <AppText
          fontSize={10}
          fontWeight={600}
          color={theme.text.brand.primary}
          onClick={() => setFieldType(fieldType === "Custom Fields" ? "System Fields" : "Custom Fields")}
          style={{ borderBottom: "0px", cursor: "pointer", userSelect: "none" }}
        >
          View {fieldType === "System Fields" ? "Custom Fields" : "System Fields"}
        </AppText>
      </FlexDiv>
    </FieldMenuContainer>
  );
};

const FieldMenuContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 44px;
  left: 24px;

  display: flex;
  flex-direction: column;

  width: 220px;
  height: 318px;

  overflow: hidden;

  border: 1px solid ${theme.border.neutral.primary};
  border-radius: 4px;
  background-color: ${theme.fill.neutral.primary};

  animation: ${theme.lightPopup} 0.2s ease forwards;
`;

const FieldOption = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 10px 8px;

  ${({ selected }) =>
    !selected &&
    css`
      cursor: pointer;

      :hover {
        background-color: ${theme.fill.brand.secondary};
        & ${AppText} {
          color: ${theme.text.brand.primary};
        }
      }

      & ${AppText} {
        transition: color 0.15s ease;
      }
    `}

  transition: background-color 0.1s ease;
`;
