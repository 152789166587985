import styled from "styled-components";
import GreenCheck from "../../../images/icons-ic-notifications-accept.svg";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { theme } from "../../../utils/theme";
import { gql, useQuery } from "@apollo/client";
import { AppErrorText, AppText, Loading } from "../../UI";
import { useState, useContext, useEffect, useMemo } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { GridFilterContext } from "../../../context";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { iconGreenCheck } from "../../../images/";
import { formatBusinessName, formatDateTime, formatImportDate, formatUSD } from "../../../utils/format";
import { appToast } from "../../../utils/toast";
import { AppTable, TableRow, TableTD } from "../../UI/AppTable";
import { contractOptions } from "../../../static";
import { isUserRep, loggedInUser } from "./../../../apollo/cache";

const FETCH_PIPELINE_LIST = gql`
  query fetchPipelineList($dashboard_detail_args: LeadDetailArgument!) {
    fetchPipelineListV2(dashboard_detail_args: $dashboard_detail_args) {
      id
      business_name
      first_name
      last_name
      set
      held
      close
      custom_field_json
      next_scheduled_event_time
      computed_mrr
      next_scheduled_event {
        id
        type
        action_label
      }
      last_call_result_time
      most_recent_sale_cycle {
        set_user {
          id
          full_name
        }
        close_user {
          id
          full_name
        }
        set
        hold
        close
        time_of_first_set
        time_of_first_hold
        time_of_first_sale
      }
      rep {
        id
        first_name
        last_name
      }
      phase_label
      created_at
      updated_at
      industry
      sub_industry
      lead_source
      # dials
      last_call_result
      last_call_result_time
      day_set_out
      current_close_date
      time_in_pipeline
      day_set_out
      last_disposition_label
    }
  }
`;

const FETCH_PIPELINE_AVERAGE_FIELD = gql`
  query fetchPipelineAverageField($pipelineArg: PipelineAvgFieldInput!) {
    fetchPipelineAverageField(pipeline_arg: $pipelineArg) {
      avg_time_in_pipeline
      avg_day_set_out
    }
  }
`;

const FETCH_USER_CALL_REPORT_PIPELINE_COLUMNS = gql`
  query fetchUserCallReportEventColumns {
    fetchUser {
      id
      visible_call_report_pipeline_columns_computed
    }
  }
`;

const FETCH_TOP_FUNNEL_LEADS = gql`
  query fetchTopFunnelLeads($pipelineManagementLeadArgs: PipelineManagementLeadArg!, $organization_id: String) {
    fetchTopFunnelLeads(pipeline_management_lead_args: $pipelineManagementLeadArgs, organization_id: $organization_id) {
      id
      business_name
      first_name
      last_name
      set
      held
      close
      custom_field_json
      next_scheduled_event_time
      computed_mrr
      next_scheduled_event {
        id
        type
        action_label
      }
      last_call_result_time
      most_recent_sale_cycle {
        set_user {
          id
          full_name
        }
        close_user {
          id
          full_name
        }
        set
        hold
        close
        time_of_first_set
        time_of_first_hold
        time_of_first_sale
      }
      rep {
        id
        first_name
        last_name
      }
      last_call_result_rep {
        full_name
      }
      phase_label
      created_at
      updated_at
      industry
      sub_industry
      lead_source
      last_call_result
      last_call_result_time
      day_set_out
      current_close_date
      time_in_pipeline
      day_set_out
      last_disposition_label
    }
  }
`;

const FETCH_BOTTOM_FUNNEL_LEADS = gql`
  query fetchBottomFunnelLeads($pipelineManagementLeadArgs: PipelineManagementLeadArg!, $organization_id: String) {
    fetchBottomFunnelLeads(
      pipeline_management_lead_args: $pipelineManagementLeadArgs
      organization_id: $organization_id
    ) {
      id
      business_name
      first_name
      last_name
      set
      held
      close
      custom_field_json
      next_scheduled_event_time
      computed_mrr
      next_scheduled_event {
        id
        type
        action_label
      }
      last_call_result_time
      most_recent_sale_cycle {
        set_user {
          id
          full_name
        }
        close_user {
          id
          full_name
        }
        set
        hold
        close
        time_of_first_set
        time_of_first_hold
        time_of_first_sale
      }
      rep {
        id
        first_name
        last_name
      }
      last_call_result_rep {
        full_name
      }
      phase_label
      created_at
      updated_at
      industry
      sub_industry
      lead_source
      last_call_result
      last_call_result_time
      day_set_out
      current_close_date
      time_in_pipeline
      day_set_out
    }
  }
`;

const DRILLDOWN_METRIC_CELL_WIDTHS = {
  CallTime: 110,
  LeadName: 140,
  CurrentPhase: 200,
  BusinessName: 180,
  SetUser: 140,
  SetByUser: 140,
  CloseUser: 160,
  ClosedByUser: 160,
  RepName: 140,
  CallResult: 120,
  AssociatedAction: 120,
  EventTime: 140,
  EventType: 140,
  Industry: 140,
  SubIndustry: 140,
  LeadSource: 140,
  nextScheduledEvent: 130,
  TypeOfNextScheduledEvent: 150,
  TimeOfFirstSet: 100,
  TimeOfFirstHold: 110,
  TimeOfFirstSale: 120,
  Set: 40,
  Held: 40,
  Close: 50,
  MostRecentCallTime: 130,
  CloseDate: 110,
  TimeInPipeline: 110,
  DaysSetOut: 100,
  MRR: 80,
  LastCallResult: 180,

};

interface MetricColumn {
  metric_label: string;
  metric: string;
}

interface CallReportProps {
  userID?: string;
  teamID?: string;
  siteID?: string;
  leadID?: string;
  orgID?: string;
  computedID?: string;
  metric?: string;
  gridFilter: boolean;
  callReportPage?: boolean;
  dataAllColumns: MetricColumn[];
}

const PipelineListTableV2: React.FC<CallReportProps> = (props) => {
  const [sortAscending, setSortAscending] = useState(false);
  const {
    dateStart,
    dateEnd,
    products,
    channel,
    gridFilter,
    dateLabel,
    repFilter,
    funnelSegment,
    selectedCell,
    pipelineIgnoreCloseDatesComputed: pipelineIgnoreCloseDates,
    leadFilterArgs,
    repFilterArgs,
    dateFilterArgs,
    sharedViewOrgId,
    leadActivityChannel,
  } = useContext(GridFilterContext);

  const [reportsList, setReportsList] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const [orderBy, setOrderBy] = useState("MostRecentCallTime");

  const takeNumber = 7;

  useEffect(() => {
    if (localStorage.getItem("funnel_segment") !== funnelSegment) {
      setReportsList([]);
      setSkip(0);
    }
    console.log(
      "pipeline funnel segment is now",
      funnelSegment,
      "selected cell is",
      selectedCell?.id,
      "reports list is",
      reportsList,
    );
  }, [funnelSegment, selectedCell?.id, pipelineIgnoreCloseDates, gridFilter, repFilter]);

  const calculatePosition = () => {
    if (!!props?.leadID) {
      return "Lead";
    }
    if (!!props?.userID) {
      return "User";
    }
    if (!!props?.siteID) {
      return "Site";
    }
    if (!!props?.teamID) {
      return "Team";
    }
    if (!!props?.orgID) {
      return "Organization";
    }
  };

  const handleColHeaderClick = (metric: string) => {
    // When order is changed, clear list
    setReportsList([]);
    if (orderBy === metric) {
      setSortAscending(!sortAscending);
    } else {
      setOrderBy(metric);
    }
  };

  const { data: dataColumns, loading: loadingColumns, error: errorColumns } = useQuery(
    FETCH_USER_CALL_REPORT_PIPELINE_COLUMNS,
    {
      fetchPolicy: "network-only",
      onError({ message }) {
        // Sentry.captureEvent({
        //   message: `fetchUser for Call Report Columns GraphQL Error: ${message}`,
        // });
        console.log(`Error in fetchUser for Call Report Columns: `, message);
        appToast(message);
      },
    },
  );
  const metric = () => {
    if (funnelSegment === "Top") {
      return ["NDMContact", "NDMContactInterested", "DMContact", "DMContactInterested"];
    } else if (funnelSegment === "Bottom") {
      return ["DemoSet", "DemoSetFlaked", "DemoHeld", "FollowUpDemoHeld", "DecisionCallHeld", "Sale"];
    } else {
      return [selectedCell?.metric];
    }
  };

  const { data: dataAvg, loading: loadingAvg, error: errorAvg } = useQuery(FETCH_PIPELINE_AVERAGE_FIELD, {
    fetchPolicy: "network-only",
    variables: {
      pipelineArg: {
        rep_filter: repFilterArgs,
        lead_filter: leadFilterArgs,
        METRICS: metric(),
        date_filter: dateFilterArgs,
      },
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: listData, loading: listLoading, error: listError, refetch, called } = useQuery(FETCH_PIPELINE_LIST, {
    skip: !selectedCell?.id,
    variables: {
      dashboard_detail_args: {
        lead_activity_channel: leadActivityChannel,
        computed_id: !!props.computedID ? props.computedID : undefined,
        position: calculatePosition(),
        metric: !!props.metric ? props.metric : undefined,
        skip: skip,
        take: takeNumber,
        order_by: orderBy,
        desc: !sortAscending,
        date_filter: dateFilterArgs,
        rep_filter: repFilterArgs,
        lead_filter: leadFilterArgs,
      },
    },
    fetchPolicy: "network-only",
    onCompleted({ fetchPipelineListV2 }) {
      console.log("fetchPipelineListV2 data generating", funnelSegment, selectedCell?.id);
      const newList = [...reportsList, ...fetchPipelineListV2].reduce(
        (acc, cv) => (acc?.map((a: any) => a.id)?.includes(cv.id) ? acc : [...acc, cv]),
        [],
      );
      setReportsList(newList);
      if (fetchPipelineListV2.length < takeNumber) setHasMore(false);
      else setHasMore(true);
    },
  });

  const {
    data: topFunnelData,
    loading: topFunnelLoading,
    error: topFunnelError,
    refetch: topFunnelRefetch,
    called: topFunnelCalled,
  } = useQuery(FETCH_TOP_FUNNEL_LEADS, {
    skip: funnelSegment !== "Top",
    variables: {
      pipelineManagementLeadArgs: {
        lead_filter: leadFilterArgs,
        rep_filter: repFilterArgs,
        date_filter: dateFilterArgs,
        take: takeNumber,
        skip: skip,
        desc: !sortAscending,
        order_by: orderBy,
        // order_by: orderBy,
      },
      organization_id: sharedViewOrgId,
    },

    fetchPolicy: "network-only",
    onCompleted({ fetchTopFunnelLeads }) {
      console.log("top funnel data generating", funnelSegment, fetchTopFunnelLeads);
      const newList = [...reportsList, ...fetchTopFunnelLeads].reduce(
        (acc, cv) => (acc?.map((a: any) => a.id)?.includes(cv.id) ? acc : [...acc, cv]),
        [],
      );
      setReportsList(newList);
      if (fetchTopFunnelLeads?.length < takeNumber) setHasMore(false);
      else setHasMore(true);
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const {
    data: bottomFunnelData,
    loading: bottomFunnelLoading,
    error: bottomFunnelError,
    refetch: bottomFunnelRefetch,
    called: bottomFunnelCalled,
  } = useQuery(FETCH_BOTTOM_FUNNEL_LEADS, {
    skip: funnelSegment !== "Bottom",
    variables: {
      pipelineManagementLeadArgs: {
        lead_filter: leadFilterArgs,
        rep_filter: repFilterArgs,
        date_filter: dateFilterArgs,
        take: takeNumber,
        skip: skip,
        desc: !sortAscending,
        order_by: orderBy,
      },
      organization_id: sharedViewOrgId,
    },
    fetchPolicy: "network-only",
    onCompleted({ fetchBottomFunnelLeads }) {
      console.log("botttom funnel data generating", funnelSegment, selectedCell?.id);
      const newList = [...reportsList, ...fetchBottomFunnelLeads].reduce(
        (acc, cv) => (acc?.map((a: any) => a.id)?.includes(cv.id) ? acc : [...acc, cv]),
        [],
      );
      setReportsList(newList);
      if (fetchBottomFunnelLeads?.length < takeNumber) setHasMore(false);
      else setHasMore(true);
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const handleShowMore = () => {
    console.log("handleShowMore", skip + takeNumber);
    setSkip(skip + takeNumber);
  };

  useEffect(() => {
    if (!called) {
      return;
    }
    setReportsList([]);
    setSkip(0);
  }, [
    props.gridFilter,
    props.leadID,
    props.metric,
    props.orgID,
    props.teamID,
    props.userID,
    channel,
    products,
    dateStart,
    dateEnd,
    sortAscending,
  ]);

  const visibleColumns = useMemo(() => {
    return dataColumns?.fetchUser?.visible_call_report_pipeline_columns_computed ?? [];
  }, [dataColumns]);

  if (listError)
    return (
      <ListContainer callReportPage={props.callReportPage}>
        <AppErrorText>Error Loading Items</AppErrorText>
        <AppErrorText>{listError.message}</AppErrorText>
      </ListContainer>
    );

  // If no call reports available.
  if (
    (!!selectedCell?.id && !listLoading && reportsList.length === 0 && listData?.fetchPipelineListV2.length === 0) ||
    (funnelSegment === "Top" &&
      !topFunnelLoading &&
      reportsList.length === 0 &&
      topFunnelData?.fetchTopFunnelLeads.length === 0) ||
    (funnelSegment === "Bottom" &&
      !bottomFunnelLoading &&
      reportsList.length === 0 &&
      bottomFunnelData?.fetchBottomFunnelLeads.length === 0)
  )
    return (
      <ListContainer
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        callReportPage={props.callReportPage}
      >
        <AppText>No reports to display.</AppText>
      </ListContainer>
    );

  const getDrilldownColumnWidth = (metric: string) => {
    const DEFAULT_WIDTH = 140;
    //@ts-ignore
    return !!DRILLDOWN_METRIC_CELL_WIDTHS[metric] //@ts-ignore
      ? DRILLDOWN_METRIC_CELL_WIDTHS[metric]
      : DEFAULT_WIDTH;
  };

  const RenderTableHeaderFor = (metric: string) => {
    const METRIC_LABELS = props?.dataAllColumns?.reduce((acc: any, cur: any) => {
      if (cur?.metric) {
        acc[cur?.metric] = cur?.metric_label;
      }
      return acc;
    }, {});

    // console.log("dataallcolumns", METRIC_LABELS);
    const METRIC_TOOLTIPS = {
      TimeInPipeline: {
        dataTip: `Time in Pipeline Avg: ${
          Math.round(dataAvg?.fetchPipelineAverageField?.avg_time_in_pipeline) || 0
        } days`,
        // dataFor: "top-funnel",
      },
      DaysSetOut: {
        dataTip: `Days Set Out Avg: ${Math.round(dataAvg?.fetchPipelineAverageField?.avg_day_set_out) || 0} days`,
        // dataFor: "top-funnel",
      },
    };

    //@ts-ignore
    let label = METRIC_LABELS[metric];
    //@ts-ignore
    let metricDataTip = METRIC_TOOLTIPS[metric]?.dataTip;

    return {
      label: label,
      onClick: () => handleColHeaderClick(metric),
      isAscending: sortAscending,
      showArrow: orderBy === metric,
      dataTip: metricDataTip,
      //@ts-ignore
      style: { minWidth: getDrilldownColumnWidth(metric) + 24 }, // 24 = TableTD padding-left
    };
  };

  const RenderTableDataFor = (metric: string, item: any) => {
    switch (metric) {
      case "CallTime":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>
              {item?.schedule_item?.lead_activity?.created_at
                ? formatImportDate(item?.schedule_item?.lead_activity?.created_at)
                : `-`}
            </ListItemText>
          </TableTD>
        );
      case "LeadName":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>{`${item.first_name ?? ""} ${
              item.last_name ?? ""
            }`}</ListItemText>
          </TableTD>
        );
      case "CurrentPhase":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>{`${
              item.phase_label ?? ""
            }`}</ListItemText>
          </TableTD>
        );
      case "EventType":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>{`${
              item.event_type_label ?? "-"
            }`}</ListItemText>
          </TableTD>
        );
      case "BusinessName":
        return (
          <TableTD>
            <WrapAnchor target="_blank" rel="noopener noreferrer" href={`/lead-detail/${item?.id ?? ""}`}>
              <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>
                {formatBusinessName(item.business_name)}
              </ListItemText>
            </WrapAnchor>
          </TableTD>
        );
      case "SetUser":
        return (
          <TableTD>
            <WrapAnchor target="_blank" rel="noopener noreferrer" href={`/lead-detail/${item?.id ?? ""}`}>
              <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>
                {item.most_recent_sale_cycle?.set_user?.full_name ?? ""}
              </ListItemText>
            </WrapAnchor>
          </TableTD>
        );
      case "CloseUser":
        return (
          <TableTD>
            <WrapAnchor target="_blank" rel="noopener noreferrer" href={`/lead-detail/${item?.id ?? ""}`}>
              <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>
                {item.most_recent_sale_cycle?.close_user?.full_name ?? ""}
              </ListItemText>
            </WrapAnchor>
          </TableTD>
        );
      case "RepName":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>{`${item.rep?.first_name ?? ""} ${
              item.rep?.last_name ?? ""
            }`}</ListItemText>
          </TableTD>
        );
      case "CallResult":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>
              {props.metric && ["Pipeline", "ClosingOpportunitiesPipeline"].includes(props.metric)
                ? item.related_disposition?.associated_action ?? "No Action"
                : item.related_disposition?.label ?? "No Result"}
            </ListItemText>
          </TableTD>
        );
      case "EventTime":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>
              {!!item.schedule_item?.start_time ? formatImportDate(item.schedule_item?.start_time) : `-`}
            </ListItemText>
          </TableTD>
        );
      case "Industry":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>{`${item.industry ?? ""} `}</ListItemText>
          </TableTD>
        );
      case "SubIndustry":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>{`${
              item.sub_industry ?? ""
            } `}</ListItemText>
          </TableTD>
        );
      case "LeadSource":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>{`${
              item?.lead_source ?? ""
            }`}</ListItemText>
          </TableTD>
        );
      case "nextScheduledEvent":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>
              {!!item?.next_scheduled_event_time
                ? `${moment(item?.next_scheduled_event_time).format("MM/DD/YYYY h:mm A")}`
                : ""}
            </ListItemText>
          </TableTD>
        );
      case "TimeOfFirstSet":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>
              {!!item?.most_recent_sale_cycle?.time_of_first_set
                ? `${moment(item?.most_recent_sale_cycle?.time_of_first_set).format("MM/DD/YYYY h:mm A")}`
                : ""}
            </ListItemText>
          </TableTD>
        );
      case "TimeOfFirstHold":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>
              {!!item?.most_recent_sale_cycle?.time_of_first_hold
                ? `${moment(item?.most_recent_sale_cycle?.time_of_first_hold).format("MM/DD/YYYY h:mm A")}`
                : ""}
            </ListItemText>
          </TableTD>
        );
      case "TimeOfFirstSale":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>
              {!!item?.most_recent_sale_cycle?.time_of_first_sale
                ? `${moment(item?.most_recent_sale_cycle?.time_of_first_sale).format("MM/DD/YYYY h:mm A")}`
                : ""}
            </ListItemText>
          </TableTD>
        );
      case "TypeOfNextScheduledEvent":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>{`${
              item?.next_scheduled_event?.action_label ?? ""
            }`}</ListItemText>
          </TableTD>
        );
      case "Set":
        return (
          <TableTD style={{ width: "40px", minWidth: "40px" }}>
            <ListItemIcon>
              {item?.most_recent_sale_cycle?.set ? <img src={GreenCheck} height="10px" /> : "-"}
            </ListItemIcon>
          </TableTD>
        );
      case "Held":
        return (
          <TableTD style={{ width: "40px", minWidth: "40px" }}>
            <ListItemIcon>
              {item?.most_recent_sale_cycle?.hold ? <img src={GreenCheck} height="10px" /> : "-"}
            </ListItemIcon>
          </TableTD>
        );
      case "Close":
        return (
          <TableTD style={{ width: "40px", minWidth: "40px" }}>
            <ListItemIcon>
              {item?.most_recent_sale_cycle?.close ? <img src={GreenCheck} height="10px" /> : "-"}
            </ListItemIcon>
          </TableTD>
        );
      case "MostRecentCallTime":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>
              {!!item?.last_call_result_time ? formatImportDate(item.last_call_result_time) : ""}
            </ListItemText>
          </TableTD>
        );
      case "CloseDate":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>
              {item?.current_close_date && formatImportDate(item?.current_close_date)}
            </ListItemText>
          </TableTD>
        );
      case "TimeInPipeline":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric), cursor: "help" }}>
              {item?.time_in_pipeline && `${item?.time_in_pipeline} days`}
            </ListItemText>
          </TableTD>
        );
      case "DaysSetOut":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric), cursor: "help" }}>
              {item?.day_set_out && `${item?.day_set_out} days`}
            </ListItemText>
          </TableTD>
        );
      case "MRR":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric), cursor: "help" }}>{`${formatUSD(
              item?.computed_mrr || 0,
            )}`}</ListItemText>
          </TableTD>
        );
      case "MRR":
        return (
          <TableTD>
            <ListItemText style={{ cursor: "help" }}>{`${formatUSD(item?.computed_mrr || 0)}`}</ListItemText>
          </TableTD>
        );
      // case "DaysSinceCycle":
      //   return (
      //     <TableData colNum={numberOfAdjustableColumns} >
      //       <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>{item?.day_set_out && `${formatImportDate(item?.day_set_out)} days`}</ListItemText>
      //     </TableData>
      //   );
      case "LastCallResult":
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>{`${
              item.last_disposition_label ?? ""
            } `}</ListItemText>
          </TableTD>
        );
      default:
        return (
          <TableTD>
            <ListItemText style={{ width: getDrilldownColumnWidth(metric) }}>
              {!!item?.custom_field_json?.[metric] && `${item?.custom_field_json[metric]}`}
            </ListItemText>
          </TableTD>
        );
    }
  };
  return (
    <>
      {props.callReportPage && <TitleText>Call History</TitleText>}
      <ListContainer callReportPage={props.callReportPage} id="call-report-list">
        <InfiniteScroll
          dataLength={reportsList.length}
          next={handleShowMore}
          hasMore={hasMore}
          loader={<Loading />}
          scrollableTarget="call-report-list"
          style={{ overflow: "default" }}
        >
          <AppTable
            sticky
            columns={
              props?.dataAllColumns?.length > 0
                ? visibleColumns?.map((metric: string) => RenderTableHeaderFor(metric))
                : []
            }
            headerStyle={{ fontSize: 10 }}
            dataFor="top-funnel"
          >
            {!!reportsList.length &&
              reportsList.slice()?.map((item: any, index: number) => {
                return (
                  <TableRow key={`index${index}-conference${item?.id}`}>
                    {visibleColumns?.map((metric: string) => RenderTableDataFor(metric, item))}
                  </TableRow>
                );
              })}
          </AppTable>
        </InfiniteScroll>
      </ListContainer>
    </>
  );
};

const WrapAnchor = styled.a`
  max-width: 100px;
  text-overflow: ellipsis;
`;

const ListItemText = styled.div`
  font-size: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;
`;

const ListItemIcon = styled.div`
  place-self: center;
  font-size: 10px;
`;

const TitleText = styled(AppText)`
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: ${theme.NEUTRAL500};
  margin-top: 14px;
  margin-bottom: 5px;
`;

interface CallReportPageProps {
  callReportPage?: boolean;
}

const ListContainer = styled.div<CallReportPageProps>`
  width: ${(props) => (props.callReportPage ? "947px" : "100%")};
  height: 299px;
  border-radius: ${(props) => (props.callReportPage ? "3px" : "16px")};
  background-color: ${theme.WHITE_COLOR};
  margin-left: ${(props) => (props.callReportPage ? "0px" : "0px")};
  margin-bottom: ${(props) => (props.callReportPage ? "50px" : "20px")};
  overflow: auto;
  /* padding: 0px 25px 0px 25px; */
`;

interface TDProps {
  width?: number;
}

export { PipelineListTableV2 };
