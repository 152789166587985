import styled from "styled-components";
import * as React from "react";
import { theme } from "../../utils/theme";
import { QueryLazyOptions } from "@apollo/client";
import { useState } from "react";
import { SelectedContactType, CurrentLeadType } from "../../types";
import { LeadCardV2 } from "../Segments/DashboardSideBarSegments";
import { OutsideEvent } from "../Dumb";
import { ModalContext } from "../../context";
interface SearchItemProps {
  index?: number;
  setSelectedContact: (selectedContact: SelectedContactType) => void;
  data: CurrentLeadType;

  disableOutsideEvent: boolean;
  getLead?: (options?: QueryLazyOptions<Record<string, any>> | undefined) => void;
}

const SearchItem: React.FC<SearchItemProps> = ({
  index,
  setSelectedContact,
  data,

  disableOutsideEvent,
  getLead,
}) => {
  const [showCurrentLead, setShowCurrentLead] = useState(false);

  const closeLead = () => {
    setShowCurrentLead(false);
  };

  return (
    <SearchItemContainer index={index ?? 0}>
      <OutsideEvent onOutsideClick={closeLead} disabled={disableOutsideEvent}>
        <div style={{ width: "441px" }}>
          <CallbackCard>
            <LeadCardV2
              hideActionButtons
              leadData={data}
              showLogNonCallActivity
              closeOtherModals={() => {
                closeLead();
              }}
              showEventTypeCard
            />
          </CallbackCard>
        </div>
      </OutsideEvent>
    </SearchItemContainer>
  );
};

const CallbackCard = styled.div`
  width: 400px;
  height: auto;
  margin: 0px;
  padding: 0px;
  background-color: ${theme.WHITE_COLOR};
  border-radius: 4px;
  border: 1px solid ${theme.NEUTRAL200};
  overflow: hidden;
  :hover {
    cursor: pointer;
  }
`;

interface ISearchItemContainer {
  index: number;
}

const SearchItemContainer = styled.div<ISearchItemContainer>`
  opacity: 0;
  animation: ${theme.fadeIn} 0.5s ease-in-out forwards;
  animation-delay: ${(props) => `${props.index * 0.05}s`};
  z-index: 4;
`;

export { SearchItem };
