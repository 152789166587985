import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { gql, useMutation, useQuery } from "@apollo/client";
import { theme } from "../../utils/theme";
import { AppErrorText, AppText, Loading } from "../UI";
import { FlexDiv } from "../UI/FlexDiv";
import { PhoenixAppButton, PhoenixExpandButton } from "../UI/Phoenix";
import { ModalContext } from "../../context";
import { loggedInUser } from "../../apollo/cache";
import { errorToast, successToast } from "../../utils/toast";
import { SequenceNav } from "../UI/Sequences/SequenceNav";
import Switch from "react-switch";
import { APP_ROLES } from "../../utils/variables";

const FETCH_ORG = gql`
  query fetchOrgSequenceSettings {
    fetchOrganization {
      id
      allow_remove_step_action
      allow_snooze_step_action
    }
  }
`;

const UPDATE_SEQUENCE_SETTINGS = gql`
  mutation updateSequenceDialerNotifications($allowRemoveStepAction: Boolean!, $allowSnoozeStepAction: Boolean!) {
    updateSequenceDialerNotifications(
      allow_remove_step_action: $allowRemoveStepAction
      allow_snooze_step_action: $allowSnoozeStepAction
    )
  }
`;

export const SequenceSettings: React.FC = () => {
  const { sequenceNavExpanded, setSequenceNavExpanded } = useContext(ModalContext);

  const [snooze, setSnooze] = useState<boolean>(false);
  const [remove, setRemove] = useState<boolean>(false);

  const { data: dataOrg, loading: loadingOrg, error: errorOrg } = useQuery(FETCH_ORG, {
    fetchPolicy: "network-only",
    onCompleted({ fetchOrganization }) {
      console.log("org sequence settings:", fetchOrganization);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const [updateSettings, { loading: loadingUpdateSettings }] = useMutation(UPDATE_SEQUENCE_SETTINGS, {
    variables: {
      allowRemoveStepAction: remove,
      allowSnoozeStepAction: snooze,
    },
    async onCompleted({ updateSequenceDialerNotifications }) {
      console.log("updateSequenceDialerNotifications:", updateSequenceDialerNotifications);
      successToast("Settings successfully changed!");
    },
    onError({ message }) {
      errorToast(`${message}`);
      console.log(`updateSequenceDialerNotifications GraphQL Error: ${message}`);
    },
  });

  useEffect(() => {
    setSnooze(dataOrg?.fetchOrganization?.allow_snooze_step_action);
    setRemove(dataOrg?.fetchOrganization?.allow_remove_step_action);
  }, [dataOrg]);

  if (loggedInUser()?.role !== APP_ROLES.ADMIN) return null;

  return (
    <Main expanded={sequenceNavExpanded}>
      <TopOptions>
        <AppText fontSize={22} fontWeight={500}>
          Settings
        </AppText>
        {false && (
          <div style={{ margin: "4px auto 0px 16px" }}>
            <Loading />
          </div>
        )}

        <RightOptions>
          <PhoenixAppButton
            buttonType="secondary"
            variant="brand"
            onClick={() => updateSettings()}
            disabled={loadingOrg || loadingUpdateSettings}
          >
            <FlexDiv justify="center" align="center" gap={16}>
              <AppText
                fontSize={10}
                fontWeight={600}
                variant="white"
                style={{ textTransform: "uppercase", letterSpacing: "1px", lineHeight: "16px" }}
              >
                Save Updates
              </AppText>
            </FlexDiv>
          </PhoenixAppButton>
        </RightOptions>
      </TopOptions>

      <Body>
        <SettingsGroupContainer>
          <SettingsHeader>
            <AppText fontSize={20} fontWeight={500}>
              Dialer Notifications
            </AppText>
            <AppText fontSize={12} fontWeight={400} color={theme.NEUTRAL400}>
              Choose which actions reps can take under Suggested Action.
            </AppText>
          </SettingsHeader>

          {!!errorOrg && <AppErrorText>{errorOrg.message}</AppErrorText>}

          <SettingsBody>
            <FlexDiv
              gap={8}
              style={{ borderBottom: `1px solid ${theme.NEUTRAL200}`, paddingBottom: "24px", marginBottom: "24px" }}
            >
              <Switch
                onChange={(checked) => setSnooze(checked)}
                onColor={theme.PRIMARY500}
                checked={snooze}
                height={16}
                width={32}
                checkedIcon={false}
                uncheckedIcon={false}
                handleDiameter={12}
                disabled={loadingOrg || !!errorOrg}
              />
              <FlexDiv direction="column" gap={3}>
                <AppText fontSize={12} fontWeight={500}>
                  Snooze
                </AppText>
                <AppText fontSize={10} fontWeight={400} color={theme.NEUTRAL400}>
                  Allow reps to snooze an Sellfire suggested action
                </AppText>
              </FlexDiv>
            </FlexDiv>
            <FlexDiv gap={8}>
              <Switch
                onChange={(checked) => setRemove(checked)}
                onColor={theme.PRIMARY500}
                checked={remove}
                height={16}
                width={32}
                checkedIcon={false}
                uncheckedIcon={false}
                handleDiameter={12}
                disabled={loadingOrg || !!errorOrg}
              />
              <FlexDiv direction="column" gap={3}>
                <AppText fontSize={12} fontWeight={500}>
                  Remove
                </AppText>
                <AppText fontSize={10} fontWeight={400} color={theme.NEUTRAL400}>
                  Allow reps to remove an Sellfire suggested action
                </AppText>
              </FlexDiv>
            </FlexDiv>
          </SettingsBody>
        </SettingsGroupContainer>
      </Body>
    </Main>
  );
};

interface MainProps {
  expanded: boolean;
}

const Main = styled.div<MainProps>`
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  margin-left: 24px;
  border-radius: 8px;
  overflow: hidden;
  transition: margin-left 0.22s ease-in-out;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const TopOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 105px;
  padding: 0px 24px 0px 48px;
`;

const RightOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex: 1 1 auto;
  margin: 0px;
  padding: 24px;

  background-color: ${theme.PRIMARY50};
  border-top: 1px solid ${theme.NEUTRAL200};
  overflow-y: auto;
`;

const SettingsGroupContainer = styled.div`
  width: 100%;
  height: fit-content;
  padding: 24px;

  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 8px;
  background-color: ${theme.WHITE_COLOR};
`;

const SettingsHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SettingsBody = styled.div`
  margin-top: 24px;
`;
