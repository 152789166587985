import React, { useState, useEffect, memo } from "react";
import { SkeletonBlock } from "src/Components/UI";
import { FlexDiv } from "src/Components/UI/FlexDiv";
import { VoicemailDrop } from "src/types/VoicemailTypes";
import styled from "styled-components";
import WaveSurfer from "wavesurfer.js";
const CardWaveformRaw = ({
  voicemailDrop,
  isPlaying,
  setIsPlaying,
  currentTime,
  setCurrentTime,
  waveform,
  waveformRef,
}: {
  voicemailDrop: VoicemailDrop;
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  currentTime: number;
  setCurrentTime: (currentTime: number) => void;
  waveform: React.MutableRefObject<WaveSurfer | null>;
  waveformRef: React.RefObject<HTMLDivElement>;
}) => {
  return (
    <TimeSection>
      <WaveformContainer ref={waveformRef} />
    </TimeSection>
  );
};

const CardWaveform = memo(CardWaveformRaw, (prevProps, nextProps) => {
  return prevProps.voicemailDrop === nextProps.voicemailDrop;
});

const TimeSection = styled(FlexDiv)`
  width: 100%;
  flex-direction: column;
  height: max-content;
`;

const WaveformContainer = styled.div`
  width: 100%;

  // decendent
  > wave {
    overflow: hidden !important;
  }
`;

export { CardWaveform };
