import React from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { AppText, FlexDiv } from "..";
import { cloudinary } from "../../../services/cloudinary";
import { AdvancedImage } from "@cloudinary/react";
import { DEFAULT_CLOUDINARY_IMAGE } from "../../../utils/variables";

interface UserPillProps {
  userName: string;
  cloudinaryId?: string;
}

export const UserPill: React.FC<UserPillProps> = ({ userName, cloudinaryId }) => {
  // Use the provided cloudinaryId or fallback to default image
  const imageId = cloudinaryId || DEFAULT_CLOUDINARY_IMAGE;
  const myImage = cloudinary.image(imageId).quality("auto:low");

  return (
    <PillContainer>
      <UserImageContainer>
        <AdvancedImage
          cldImg={myImage}
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
        />
      </UserImageContainer>
      <AppText fontSize={8} fontWeight={600} lineHeight={12} uppercase color={theme.text.brand.primary}>
        {userName || "N/A"}
      </AppText>
    </PillContainer>
  );
};

const PillContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 24px;
  padding: 4px 8px;
  background-color: ${theme.fill.brand.secondary};
  border-radius: 4px;
  width: max-content;
  max-width: 100%;
  gap: 8px;
`;

const UserImageContainer = styled.div`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  border-radius: 50%;
  overflow: hidden;
`;
