import { useReactiveVar } from "@apollo/client";
import { OptionItem } from "src/types";
import { loggedInUser } from "../apollo/cache";
import { OrgBrands, UserBrand } from "../types/StateTypes";
import { useMemo } from "react";

/**
 * Custom hook to access the isMultiBrand flag and brands
 *
 * This rarely changes and is cached on the organization object
 *
 * @param showBrandManagement - flag from LaunchDarkly that controls UI visibility
 * @returns {
 *   isMultiBrandOrg: boolean
 *   showMultiBrandUI: boolean - Indicates if the multi-brand UI should be displayed
 *   ORG_BRAND_OPTIONS: OptionItem[]
 *   ORG_BRANDS: OrgBrands[]
 *   ALL_CREATED_ORGANIZATION_BRANDS: OrgBrands[]
 *   USER_ASSIGNED_BRANDS: OrgBrands[]
 *   USER_ASSIGNED_BRAND_OPTIONS: OptionItem[]
 * }
 *
 * @example
 * // In a component
 * const { showBrandManagement } = useFlags();
 * const { isMultiBrandOrg, showMultiBrandUI, ORG_BRAND_OPTIONS, ORG_BRANDS, USER_ASSIGNED_BRANDS, USER_ASSIGNED_BRAND_OPTIONS } = useMultiBrands(showBrandManagement);
 *
 * if (showMultiBrandUI) {
 *   // Handle multi-brand UI logic
 * }
 */

export const useMultiBrands = (
  useFlags: () => { showBrandManagement: boolean },
): {
  isMultiBrandOrg: boolean; // true if the org is multi-brand
  showMultiBrandUI: boolean; // true if the org is multi-brand and the feature flag is enabled
  showBrandManagementPage: boolean; // true if the org is single or multi-brand and the feature flag is enabled
  ORG_BRAND_OPTIONS: OptionItem[]; // list of brands for the org
  ORG_BRANDS: OrgBrands[]; // list of active brands for the org
  ORG_BRANDS_WITH_DELETED: OrgBrands[]; // list of all brands for the org
  USER_ASSIGNED_BRANDS: UserBrand[]; // list of brands for the current user
  USER_ASSIGNED_BRAND_OPTIONS: OptionItem[]; // list of brands for the current user
} => {
  const userAssignedBrands: UserBrand[] = useReactiveVar(loggedInUser)?.user_brands || [];

  const { showBrandManagement } = useFlags();

  const isMultiBrandOrg = useReactiveVar(loggedInUser)?.organization?.is_multi_brand || false;

  const ALL_BRANDS = useReactiveVar(loggedInUser)?.organization?.Brands || [];

  const ACTIVE_BRANDS = ALL_BRANDS.filter((brand) => !brand.deleted_at);

  const SORTED_ACTIVE_BRANDS = ACTIVE_BRANDS.sort(
    (a, b) => new Date(a?.created_at || "").getTime() - new Date(b?.created_at || "").getTime(),
  );

  const SORTED_ALL_BRANDS = useMemo(
    () =>
      [...ALL_BRANDS].sort((a, b) => new Date(a?.created_at || "").getTime() - new Date(b?.created_at || "").getTime()),
    [ALL_BRANDS],
  );

  const validBrandOptions = SORTED_ACTIVE_BRANDS.map((brand) => ({
    label: brand?.name || "N/A",
    value: brand?.id || "",
  }));

  const userAssignedBrandOptions = userAssignedBrands.map((brand) => ({
    label: brand?.brand?.name || "N/A",
    value: brand?.brand?.id || "",
  }));

  return {
    isMultiBrandOrg,
    showMultiBrandUI: showBrandManagement && isMultiBrandOrg,
    showBrandManagementPage: showBrandManagement,
    ORG_BRAND_OPTIONS: validBrandOptions,
    ORG_BRANDS: SORTED_ACTIVE_BRANDS,
    ORG_BRANDS_WITH_DELETED: SORTED_ALL_BRANDS,
    USER_ASSIGNED_BRANDS: userAssignedBrands.filter((brand) => brand !== undefined) as UserBrand[],
    USER_ASSIGNED_BRAND_OPTIONS: userAssignedBrandOptions,
  };
};

export default useMultiBrands;
