import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { AppButton, AppText, AppInput, AppSelect, Loading, AppErrorText } from "../UI";
import { theme } from "../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { InputField } from "../Field";
import { toast } from "react-toastify";
import { appToast } from "../../utils/toast";
import { Modal } from "./Modal";
interface DisappearingDivProps {
  visible: boolean;
  close: () => void;
  /**
   * Only pass in the id if it is an edit operation
   */
  id?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
}

const ADD_USER = gql`
  mutation addUser($user: AddUserInput) {
    addUser(user: $user) {
      id
      email
      first_name
      last_name
      full_name
      phone_number
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($user_id: String!, $user_input: AddUserInput!) {
    updateUser(user_id: $user_id, user_input: $user_input) {
      id
      email
      first_name
      last_name
      phone_number
      full_name
    }
  }
`;

const UpdateAdmin: React.FC<DisappearingDivProps> = ({ visible, close, id, ...userProps }) => {
  interface MyFormikProps {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
  }

  const stepAdminsSchema = Yup.object().shape({
    old_email: Yup.string(),
    email: Yup.string().email("Please enter a valid email address!").required("Please enter an email address!"),
    first_name: Yup.string().notRequired(),
    last_name: Yup.string().notRequired(),
  });

  const [updateUser, { loading: updateLoading, error: errorLoading }] = useMutation(UPDATE_USER, {
    onCompleted({ updateUser }) {
      console.log("Update admin: ", updateUser);
      // window.location.reload(false);
      if (!updateUser) {
        return;
      }
      close();
    },
    onError({ message }) {
      console.log("Error in addAdmin: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateUser GraphQL Error: ${message}`,
      });
    },
  });

  const [addUser, { loading: addLoading, error: addError }] = useMutation(ADD_USER, {
    onCompleted({ addUser }) {
      console.log("Add admin: ", addUser);
      // window.location.reload(false);
      if (!addUser) {
        appToast("Error: Something went wrong!");
        return;
      }
      appToast("User created!");
      close();
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `addUser GraphQL Error: ${message}`,
      });
      console.log("Error in addUser: ", message);
    },
    update(cache, { data: { addUser } }) {
      cache.modify({
        fields: {
          fetchAdmins(existingAdmins, { readField }) {
            const newAdminRef = cache.writeFragment({
              data: addUser,
              fragment: gql`
                fragment NewAdmin on User {
                  id
                  email
                  first_name
                  last_name
                  full_name
                  phone_number
                }
              `,
            });
            return [...existingAdmins, newAdminRef];
          },
        },
      });
    },
  });

  return (
    <Formik
      initialValues={{
        first_name: userProps.first_name || "",
        last_name: userProps.last_name || "",
        phone_number: userProps.phone_number || "",
        email: userProps.email || "",
      }}
      validationSchema={stepAdminsSchema}
      onSubmit={async (values) => {
        if (id) {
          await updateUser({
            variables: {
              user_id: id,
              user_input: {
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                phone_number: values.phone_number,
              },
            },
          });
          appToast("User updated!");
        } else {
          await addUser({
            variables: {
              user: {
                ...values,
                role: "ADMIN",
              },
            },
          });
        }
      }}
    >
      {({ submitForm, isSubmitting, values }: FormikProps<MyFormikProps>) => {
        return (
          <Modal open={visible} onClose={close} closeButtonSize={16}>
            <ScrollingDiv>
              <CenterDiv>
                <PopupTitle>{id ? `Edit ` : `Create New `}Administrator</PopupTitle>
              </CenterDiv>
              <PopupInputLabel>
                Admin First Name <span style={{ color: "red" }}>*</span>
              </PopupInputLabel>
              <EmailInput name="first_name" />
              <PopupInputLabel>
                Admin Last Name <span style={{ color: "red" }}>*</span>
              </PopupInputLabel>
              <EmailInput name="last_name" />
              <PopupInputLabel>
                Admin Email <span style={{ color: "red" }}>*</span>
              </PopupInputLabel>
              <EmailInput disabled={!!id} name="email" type="email" />
              <PopupInputLabel>
                Admin Phone Number <span style={{ color: "red" }}>*</span>
              </PopupInputLabel>
              <EmailInput name="phone_number" disabled placeholder={"Number will be assigned automatically."} />
              <CenterDiv>
                {isSubmitting ? (
                  <Loading />
                ) : (
                  <AddEmailButton type="submit" onClick={submitForm}>
                    {id ? `Update Administrator` : `Save Changes`}
                  </AddEmailButton>
                )}
              </CenterDiv>
              <CenterDiv>
                <AddEmailCancelButton cancel onClick={close}>
                  Cancel
                </AddEmailCancelButton>
              </CenterDiv>
            </ScrollingDiv>
          </Modal>
        );
      }}
    </Formik>
  );
};

interface ContainerDivProps {
  blinds: boolean;
}

const PopupContainerDiv = styled.div<ContainerDivProps>`
  position: fixed;
  display: ${(props) => (props.blinds ? "block" : "none")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
`;

const PopupInputLabel = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 13px;
  margin-left: 5px;
`;

const EmailInput = styled(InputField)`
  margin: 0;
  text-align: left;
  border-radius: 2.9px;
  height: 45px;
  border: solid 1px ${theme.BLACK_COLOR};
  font-size: 13px;
  ::placeholder {
    text-align: left;
  }
`;

const AddEmailCancelButton = styled(AppButton)`
  font-size: 10px;
  font-weight: 600;
  width: 136px;
  height: 32px;
  border-radius: 2px;
  text-transform: uppercase;
  margin: auto;
`;

const AddEmailButton = styled(AppButton)`
  width: 264px;
  height: 45px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  margin: 25px auto;
`;

const PopupTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 27px;
`;

interface ModalProps {
  width?: number;
  stickBottom?: boolean;
}

const ScrollingDiv = styled.div`
  /* position: relative; */
  padding: 40px 66px;
  overflow: auto;
  width: 100%;
  max-height: calc(100vh - 104px);
  overflow: auto;
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { UpdateAdmin };
