/* eslint-disable @typescript-eslint/no-non-null-assertion */
import styled, { keyframes } from "styled-components";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useState, useRef, useCallback, useEffect, useContext } from "react";
import { PhoenixAppButton, PhoenixIcon, PhoenixInput } from "../../UI/Phoenix";
import { AppText, Loading } from "../../UI";
import { mail } from "../../../images/NewDesign";
import { theme } from "../../../utils/theme";
import TemplateRow from "./templates/TemplateRow";
import { ModalContext } from "../../../context";
import { EmailTemplateModal } from "../../modal";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { AppErrorText } from "../../UI";
import { FiArrowLeft } from "react-icons/fi";
import { useDebounce } from "../../../utils/hooks";

interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
}

interface TemplateFilter {
  template_id?: string;
  template_name?: string;
  team_ids?: string[];
  user_ids?: string[];
  roles?: string[];
  type?: "SMS" | "EMAIL";
  skip?: number;
  take?: number;
}

const FETCH_TEMPLATES = gql`
  query fetchTemplates($TemplateFilter: TemplateFilter!) {
    fetchTemplates(TemplateFilter: $TemplateFilter) {
      id
      name
      subject
      content
      type
      opened_perc
      clicked_perc
      replied_perc
      bounced_perc
    }
  }
`;

const FETCH_TEMPLATES_COUNT = gql`
  query fetchTemplatesCount($TemplateFilter: TemplateFilter!) {
    fetchTemplatesCount(TemplateFilter: $TemplateFilter)
  }
`;

const StepEmailTemplates: React.FC<DisappearingDivProps> = () => {
  const [search, setSearch] = useState("");
  const [userHasTemplates, setUserHasTemplates] = useState(false);
  const [take, setTake] = useState(25);
  const [skip, setSkip] = useState(0);
  const tableRef = useRef<HTMLDivElement>(null);

  const { emailTemplateModal, setEmailTemplateModal } = useContext(ModalContext);

  const { data: templates, loading: templatesLoading, error: templatesError, refetch: refetchTemplates } = useQuery(
    FETCH_TEMPLATES,
    {
      variables: {
        TemplateFilter: {
          type: "EMAIL",
          skip: skip * take,
          take,
        },
      } as TemplateFilter,
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        console.log("fetchEmailTemplates", data);
        if (data?.fetchTemplates?.length > 0) {
          setUserHasTemplates(true);
        }
      },
    },
  );

  const { data: templatesCount, loading: templatesCountLoading, error: templatesCountError } = useQuery(
    FETCH_TEMPLATES_COUNT,
    {
      variables: {
        TemplateFilter: {
          type: "EMAIL",
        },
      } as TemplateFilter,
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        console.log("fetchTemplatesCount:", data);
      },
    },
  );

  useDebounce(
    () => {
      refetchTemplates({
        TemplateFilter: {
          type: "EMAIL",
          template_name: search,
          skip: 0,
          take,
        },
      } as TemplateFilter);
    },
    [search],
    500,
  );

  return (
    <>
      {emailTemplateModal && <EmailTemplateModal />}
      <Main>
        <TopAlignDiv>
          <OptionsDiv>
            <OptionsLeft>
              <AppText fontSize={22} fontWeight={500} noWrap>
                Email Templates
              </AppText>
            </OptionsLeft>
            <OptionsRight>
              <PhoenixInput
                placeholder="Search"
                variant="primary"
                type="text"
                name="search"
                id="search"
                searchInput
                fontSize={10}
                fontWeight={400}
                displayNoContextText
                width={243}
                value={search}
                onChange={(e: any) => setSearch(e?.target?.value)}
              />

              <PhoenixAppButton
                buttonType="secondary"
                variant="brand"
                centered
                onClick={() => setEmailTemplateModal(true)}
              >
                <PhoenixIcon
                  svg={mail}
                  size={16}
                  color={theme.WHITE_COLOR}
                  hoverColor={theme.WHITE_COLOR}
                  pointer
                  style={{ marginRight: "8px" }}
                />
                <AppText fontSize={10} fontWeight={600} noWrap color={theme.WHITE_COLOR}>
                  CREATE EMAIL
                </AppText>
              </PhoenixAppButton>
            </OptionsRight>
          </OptionsDiv>
        </TopAlignDiv>
        <TemplatesDiv ref={tableRef}>
          {templatesLoading ? (
            <Loading />
          ) : templatesError ? (
            <AppText>Something went wrong</AppText>
          ) : // if there are no templates and user has not searched
          templates?.fetchTemplates?.length === 0 && !userHasTemplates ? (
            <IntroDiv>
              <AppText color={theme.NEUTRAL300} fontSize={12} noWrap fontWeight={500}>
                Let's get started.
              </AppText>
              <AppText fontSize={16} fontWeight={400} noWrap>
                Create your first Email Template.
              </AppText>
              <div
                style={{
                  marginTop: "16px",
                }}
              >
                <PhoenixAppButton
                  buttonType="secondary"
                  variant="brand"
                  centered
                  onClick={() => setEmailTemplateModal(true)}
                >
                  <PhoenixIcon
                    svg={mail}
                    size={16}
                    color={theme.WHITE_COLOR}
                    hoverColor={theme.WHITE_COLOR}
                    pointer
                    style={{ marginRight: "8px" }}
                  />

                  <AppText fontSize={10} fontWeight={600} noWrap color={theme.WHITE_COLOR}>
                    CREATE EMAIL
                  </AppText>
                </PhoenixAppButton>
              </div>
            </IntroDiv>
          ) : templates?.fetchTemplates?.length === 0 && search ? (
            // if there are no templates and the user has searched
            <IntroDiv>
              <AppText fontSize={16} fontWeight={400} noWrap>
                No templates found.
              </AppText>
            </IntroDiv>
          ) : (
            templates?.fetchTemplates?.map((template: any) => (
              <TemplateRow
                templateId={template.id ?? ""}
                name={template.name ?? "Untitled Template"}
                subject={template.subject ?? ""}
                content={template.content ?? ""}
                opened={template.opened_perc ?? 0}
                clicked={template.clicked_perc ?? 0}
                replied={template.replied_perc ?? 0}
                bounced={template.bounced_perc ?? 0}
                templateType="email"
              />
            ))
          )}
        </TemplatesDiv>

        <FlexDiv>
          <CornerDiv>
            <LeadsPerPageText>Templates Per Page</LeadsPerPageText>
            <LeadsNumberText
              onClick={() => {
                setTake(25);
                setSkip(0);
              }}
              selected={take === 25}
            >
              25
            </LeadsNumberText>

            <LeadsNumberText
              onClick={() => {
                setTake(50);
                setSkip(0);
              }}
              selected={take === 50}
            >
              50
            </LeadsNumberText>

            <LeadsNumberText
              onClick={() => {
                setTake(75);
                setSkip(0);
              }}
              selected={take === 75}
            >
              75
            </LeadsNumberText>

            <LeadsNumberText
              onClick={() => {
                setTake(100);
                setSkip(0);
              }}
              selected={take === 100}
            >
              100
            </LeadsNumberText>
          </CornerDiv>
          <CornerDiv
            style={{
              marginRight: "100px",
            }}
          >
            {templatesCountLoading ? (
              <AppText>Loading count...</AppText>
            ) : templatesCountError ? (
              <AppErrorText>Error loading count.</AppErrorText>
            ) : (
              <>
                <LeadsPerPageText style={{ marginLeft: "40px" }}>
                  Page <span style={{ fontWeight: 600 }}>{skip + 1}</span> of{" "}
                  {Math.ceil(Number(templatesCount?.fetchTemplatesCount) / take).toLocaleString()}
                </LeadsPerPageText>
                <div style={{ display: "flex" }}>
                  <NavIcon
                    disabled={templatesCountLoading || templatesLoading || skip <= 0}
                    onClick={() => {
                      tableRef?.current?.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      setSkip(skip - 1);
                    }}
                  >
                    <FiArrowLeft
                      size={22}
                      color={
                        templatesCountLoading || templatesLoading || skip <= 0 ? theme.NEUTRAL400 : theme.PRIMARY600
                      }
                      style={{ transform: "rotate(0deg)" }}
                    />
                  </NavIcon>
                  <NavIcon
                    style={{ marginLeft: "10px" }}
                    disabled={
                      templatesLoading ||
                      templatesCountLoading ||
                      skip * take + take >= Number(templatesCount?.fetchTemplatesCount)
                    }
                    onClick={() => {
                      tableRef?.current?.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      setSkip(skip + 1);
                    }}
                  >
                    <FiArrowLeft
                      size={22}
                      color={
                        templatesLoading ||
                        templatesCountLoading ||
                        skip * take + take >= Number(templatesCount.fetchTemplatesCount)
                          ? theme.NEUTRAL400
                          : theme.PRIMARY600
                      }
                      style={{ transform: "rotate(180deg)" }}
                    />
                  </NavIcon>
                </div>
              </>
            )}
          </CornerDiv>
        </FlexDiv>
      </Main>
    </>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  border-radius: 8px;
`;

const TemplatesDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${theme.PRIMARY50};
  width: 100%;
  flex: 1 1 auto;
  overflow: auto;
  border-top: 1px solid ${theme.NEUTRAL200};
  gap: 16px;
  padding: 48px;
  margin: 0px;
`;

const TopAlignDiv = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-items: center;
  margin: 0px 24px;
  padding: 24px 0;
`;

const OptionsDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
`;

const OptionsLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const OptionsRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const FlexDiv = styled.div`
  flex: 0 0 auto;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.LIGHT_BLUE};
  padding: 24px 48px;
`;

const CornerDiv = styled.div`
  display: flex;
  align-items: center;
`;

const LeadsPerPageText = styled(AppText)`
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.2px;
  margin-right: 16px;
`;

interface SelectedProps {
  selected?: boolean;
}
const LeadsNumberText = styled(AppText)<SelectedProps>`
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.2px;
  padding: 0px 8px;
  color: ${(props) => (props.selected ? theme.PRIMARY600 : theme.BLACK_COLOR)};
  border-bottom: none;
  font-weight: ${(props) => (props.selected ? 600 : 400)};
  cursor: pointer;
  border-right: 1px solid #c4c4c4;
  :last-child {
    border-right: none;
  }
`;

interface DisabledProps {
  disabled: boolean;
}

const NavIcon = styled.div<DisabledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  background-color: transparent;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const IntroDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 16px;
`;

export { StepEmailTemplates };
