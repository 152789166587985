import React, { useState, useContext } from "react";
import { PhoenixAppButton } from "src/Components/UI/Phoenix/PhoenixAppButton";
import { COMPACT_CALL_VIEW_BREAKPOINT, DEFAULT_CLOUDINARY_IMAGE } from "src/utils/variables";
import styled, { css } from "styled-components";
import { FlexDiv, AppText } from "src/Components/UI";
import { formatPhoneNumber } from "src/utils/format";
import { theme } from "src/utils/theme";
import { PhoenixIcon } from "src/Components/UI/Phoenix/PhoenixIcon";
import {
  calendar,
  clock,
  voicemail,
  keypad,
  mic,
  mic_off,
  phone_end,
  user_white,
  more_horizontal,
  record,
} from "src/images/NewDesign";
import { formatTime } from "src/utils/format";
import { TwilioWarnings } from "src/Components/Dumb/TwilioWarnings";
import { currentCallState, loggedInUser } from "src/apollo/cache";
import { ModalContext } from "src/context/ModalContext";
import CallMoreMenu from "./CallMoreMenu";
import { useFlags } from "launchdarkly-react-client-sdk";
import { CloudinaryImage } from "@cloudinary/url-gen";
import { UserPill } from "src/Components/UI/Pills/UserPill";
import { AdvancedImage } from "@cloudinary/react";
import { cloudinary } from "src/services/cloudinary";
import { CallContext } from "src/context/CallContext";
import { CurrentLeadType } from "src/types/StateTypes";

interface FetchLeadResponse {
  fetchLead: CurrentLeadType;
}

interface Participant {
  identity?: string;
  sid?: string;
}

interface ParticipantsData {
  participants: Participant[];
}

interface CallStatus {
  isConnected: boolean;
  isConnecting: boolean;
  isOnCall: boolean;
}

interface CallModuleProps {
  data: FetchLeadResponse;
  timeOnCallRef: React.RefObject<HTMLDivElement>;
  callQualityIcons: boolean;
  connectionWarnings: boolean;
  phoneNumber: string | undefined;
  toggleDialer: () => void;
  toggleMute: () => void;
  showDialer: boolean;
  isTwilioMuted: boolean;
  isOnCallLoading: boolean;
  isOnCall: CallStatus;
  recording: boolean;
  participants: ParticipantsData;
  refetchParticipants: () => void;
  handleRecordingClick: () => void;
  hangup: () => void;
  showVoicemailDropsModule: boolean;
  setShowVoicemailDropsModule: (show: boolean) => void;
  showJoinDetailsModal: boolean;
  setShowJoinDetailsModal: (show: boolean) => void;
  setCallSettingsModal: (show: boolean) => void;
  repProfileImage?: string;
}

const CallModule: React.FC<CallModuleProps> = ({
  timeOnCallRef,
  callQualityIcons,
  connectionWarnings,
  phoneNumber,
  toggleDialer,
  toggleMute,
  showDialer,
  isTwilioMuted,
  isOnCallLoading,
  isOnCall,
  recording,
  participants,
  refetchParticipants,
  handleRecordingClick,
  hangup,
  showVoicemailDropsModule,
  setShowVoicemailDropsModule,
  showJoinDetailsModal,
  setShowJoinDetailsModal,
  setCallSettingsModal,
  data,
  repProfileImage,
}) => {
  const { repPhoneNumber } = useContext(CallContext);
  const { voicemailDrops } = useFlags();
  const imageId = repProfileImage || DEFAULT_CLOUDINARY_IMAGE;
  const myImage = cloudinary.image(imageId).quality("auto:low");

  return (
    <FooterContainer>
      <FooterInner>
        <LeftContainer>
          <FlexDiv gap={8} align="center">
            {(callQualityIcons || connectionWarnings) && <TwilioWarnings />}
            <RecordingIcon
              src={record}
              alt="record"
              onClick={currentCallState().onCall ? handleRecordingClick : () => {}}
              recording={recording}
              disabled={!currentCallState().onCall}
            />
            <FlexDiv gap={4} align="center">
              <PhoenixIcon svg={clock} color={theme.PILL_DARK_GRAY} size={16} hoverColor={theme.PILL_DARK_GRAY} />
              <AppText
                fontSize={10}
                fontWeight={400}
                color={theme.text.neutral.inverse}
                ref={timeOnCallRef}
                style={{
                  paddingTop: 1,
                }}
              >
                {formatTime(0)}
              </AppText>
            </FlexDiv>
          </FlexDiv>
          <FlexDiv gap={4} align="center">
            <ContactIconContainer>
              <PhoenixIcon svg={user_white} color={theme.BLACK_COLOR} hoverColor={theme.BLACK_COLOR} size={10} />
            </ContactIconContainer>
            <AppText color={theme.text.neutral.inverse} fontSize={10}>
              {phoneNumber ? formatPhoneNumber(phoneNumber) : ""}
            </AppText>
          </FlexDiv>
        </LeftContainer>
        <MiddleContainer>
          {currentCallState().onCall ? (
            <FlexDiv gap={4} align="center">
              <ActionButton
                buttonType={"primary"}
                variant={!showDialer ? "brand-outline" : "brand"}
                {...(!showDialer && { backgroundColor: "transparent" })}
                onClick={toggleDialer}
              >
                <PhoenixIcon size={18} fillIcon variant={!showDialer ? "brand" : "white"} svg={keypad} pointer />
              </ActionButton>
              <ActionButton
                buttonType="primary"
                variant={!isTwilioMuted ? "brand-outline" : "brand"}
                {...(!isTwilioMuted && { backgroundColor: "transparent" })}
                onClick={toggleMute}
              >
                <PhoenixIcon
                  size={18}
                  svg={isTwilioMuted ? mic_off : mic}
                  color={isTwilioMuted ? theme.text.neutral.inverse : theme.text.brand.primary}
                  pointer
                  {...(isTwilioMuted ? { hoverColor: theme.text.neutral.inverse } : {})}
                />
              </ActionButton>
              {!!voicemailDrops && (
                <ActionButton
                  buttonType={"primary"}
                  variant={!showVoicemailDropsModule ? "brand-outline" : "brand"}
                  {...(!showVoicemailDropsModule && { backgroundColor: "transparent" })}
                  onClick={() => setShowVoicemailDropsModule(!showVoicemailDropsModule)}
                >
                  <PhoenixIcon
                    pointer
                    size={18}
                    svg={voicemail}
                    variant={!showVoicemailDropsModule ? "brand" : "white"}
                  />
                </ActionButton>
              )}
              <ActionButton
                buttonType="primary"
                variant="danger-outline"
                backgroundColor="transparent"
                onClick={() => hangup()}
                disabled={!(!isOnCallLoading && isOnCall.isOnCall)}
              >
                <PhoenixIcon
                  pointer
                  size={18}
                  svg={phone_end}
                  color={theme.icon.danger.default}
                  hoverColor={theme.icon.danger.hover}
                />
              </ActionButton>
            </FlexDiv>
          ) : (
            <>
              <TextColumn>
                <AppText fontSize={12} color={theme.text.neutral.inverse}>
                  Call has ended. Please submit
                </AppText>
                <AppText fontSize={12} color={theme.text.neutral.inverse}>
                  a call result to continue.
                </AppText>
              </TextColumn>
            </>
          )}
        </MiddleContainer>
        <RightContainer>
          <RightContentRow>
            <FlexDiv gap={4} align="center">
              <PhoenixIcon
                svg={calendar}
                color={theme.text.dataviz1.primary}
                hoverColor={theme.text.dataviz1.primary}
                size={12}
              />
              <AppText
                fontSize={12}
                fontWeight={400}
                color={theme.text.dataviz1.primary}
                style={{ whiteSpace: "nowrap" }}
              >
                {data?.fetchLead?.can_record_without_permission ? "On Call" : "Scheduled"}
              </AppText>
            </FlexDiv>
            <CallMoreMenu
              participants={participants?.participants.map((p) => p.identity || p.sid || "")}
              businessName={data?.fetchLead?.business_name}
              refetchParticipants={refetchParticipants}
              onAudioSettings={() => setCallSettingsModal(true)}
              onInviteLink={() => setShowJoinDetailsModal(!showJoinDetailsModal)}
            />
          </RightContentRow>

          <RightContentRow>
            <FlexDiv gap={4} align="center">
              <UserImageContainer>
                <AdvancedImage
                  cldImg={myImage}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                />
              </UserImageContainer>
              <AppText fontSize={10} fontWeight={400} color={theme.text.neutral.inverse}>
                {formatPhoneNumber(repPhoneNumber || "")}
                {/* {formatPhoneNumber(loggedInUser().phone_number)} */}
              </AppText>
            </FlexDiv>
            <div />
          </RightContentRow>
        </RightContainer>
      </FooterInner>
    </FooterContainer>
  );
};

const TextColumn = styled(FlexDiv)`
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UserImageContainer = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

const LeftContainer = styled(FlexDiv)`
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const MiddleContainer = styled(FlexDiv)`
  flex-direction: row;

  @media (min-width: ${COMPACT_CALL_VIEW_BREAKPOINT}px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: auto;
  width: fit-content;
`;

const RightContentRow = styled(FlexDiv)`
  width: 100%;
  min-width: max-content;
  justify-content: space-between;
`;

const FooterContainer = styled.div`
  background-color: #08122e;
  border-radius: 12px;
  padding: 8px;
`;

const FooterInner = styled.div`
  align-items: center;
  display: flex;
  gap: 6px;
  height: 40px;

  justify-content: space-around;

  width: 100%;
`;

const ContactIconContainer = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 1.6px;
  background-color: ${theme.fill.brand.primary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionButton = styled(PhoenixAppButton)`
  align-items: center;
  border-radius: 8px;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 8px;
  width: 40px;
`;

const RecordingIcon = styled.img<{ recording: boolean; disabled: boolean }>`
  width: 16px;
  height: 16px;
  cursor: pointer;
  filter: ${(props) => (props.recording || props.disabled ? "opacity(1)" : "opacity(0.5)")};
  transition: filter 0.2s ease-in-out;
  &:hover {
    filter: opacity(1);
  }
`;

export default CallModule;
