import { theme } from "src/utils/theme";

import { gql, useMutation } from "@apollo/client";
import React, { useEffect, useMemo, useState } from "react";
import useFetchVoicemailDrops from "src/query-hooks/useFetchVoicemailDrops";
import { VoicemailDrop } from "src/types/VoicemailTypes";
import { useModalContext } from "src/utils/hooks";
import styled from "styled-components";
import { Modal } from ".";
import { AppErrorText, AppText, SkeletonBlock } from "../UI";
import { FlexDiv } from "../UI/FlexDiv";
import { PhoenixAppButton, PhoenixInput } from "../UI/Phoenix";

const UPDATE_VOICEMAIL_DROP = gql`
  mutation UpdateVoicemailDrop($title: String!, $description: String!, $id: String!) {
    updateVoicemailDrop(title: $title, description: $description, id: $id) {
      id
      title
      description
    }
  }
`;
const RenameVoicemailDropModal = () => {
  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const {
    showRenameVoicemailDropModal,
    setShowRenameVoicemailDropModal,
    renameVoicemailDropModalData,
  } = useModalContext();
  const { data, loading: loadingFetchVoicemailDrops, error: errorFetchVoicemailDrops } = useFetchVoicemailDrops();
  const { id } = renameVoicemailDropModalData;

  const [updateVoicemailDrop, { loading: updateVoicemailDropLoading, error: errorUpdateVoicemailDrop }] = useMutation(
    UPDATE_VOICEMAIL_DROP,
    {
      onCompleted: () => {
        setShowRenameVoicemailDropModal(false);
      },
      refetchQueries: ["FetchVoicemailDrops"],
    },
  );

  const handleRenameVoicemailDrop = () => {
    updateVoicemailDrop({
      variables: {
        id,
        title: newName,
        description: newDescription,
      },
    });
  };

  const selectedVoicemailDrop = useMemo(() => {
    return data?.fetchVoicemailDrops.find((voicemailDrop: VoicemailDrop) => voicemailDrop.id === id);
  }, [data?.fetchVoicemailDrops, id]);

  useEffect(() => {
    if (selectedVoicemailDrop) {
      setNewName(selectedVoicemailDrop.title);
      setNewDescription(selectedVoicemailDrop.description);
    }
  }, [selectedVoicemailDrop]);

  const loading = loadingFetchVoicemailDrops || updateVoicemailDropLoading;
  const error = errorFetchVoicemailDrops || errorUpdateVoicemailDrop;

  return (
    <Modal open={showRenameVoicemailDropModal} onClose={() => setShowRenameVoicemailDropModal(false)}>
      <ModalMain>
        {error ? (
          <AppErrorText>Something went wrong</AppErrorText>
        ) : loading ? (
          <SkeletonBlock height={322} width={476} borderRadius={8} />
        ) : (
          <>
            <Top>
              <AppText fontSize={16} fontWeight={600} color={theme.BLACK_COLOR}>
                Rename Voicemail Recording
              </AppText>
              <FlexDiv direction="column" width="100%">
                <PhoenixInput
                  titleTextSpacing={8}
                  placeholder="Enter new name"
                  value={newName}
                  titleText="Name"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewName(e.target.value)}
                />
                <PhoenixInput
                  titleTextSpacing={8}
                  placeholder="Enter new description"
                  value={newDescription}
                  titleText="Description"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewDescription(e.target.value)}
                />
              </FlexDiv>
            </Top>

            <SubmitButtonDiv>
              <PhoenixAppButton
                variant="danger-outline"
                buttonType="secondary"
                onClick={() => setShowRenameVoicemailDropModal(false)}
              >
                Cancel
              </PhoenixAppButton>
              <PhoenixAppButton
                disabled={loading}
                variant="brand"
                buttonType="secondary"
                onClick={handleRenameVoicemailDrop}
              >
                Continue
              </PhoenixAppButton>
            </SubmitButtonDiv>
          </>
        )}
      </ModalMain>
    </Modal>
  );
};

const Top = styled(FlexDiv)`
  align-items: center;
  padding: 40px;
  width: 100%;
  flex-direction: column;
  gap: 40px;
  min-height: 222px;
`;

const SubmitButtonDiv = styled(FlexDiv)`
  border-top: 1px solid ${theme.NEUTRAL200};
  padding: 16px 40px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const ModalMain = styled(FlexDiv)`
  width: 476px;
  height: max-content;
  flex-direction: column;
  align-items: center;
`;

export default RenameVoicemailDropModal;
