import React from "react";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { AppErrorText, AppText, SkeletonBlock } from "../../../UI";

import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik, useFormikContext } from "formik";
import { PhoenixStyledTooltip } from "src/Components/Dumb/PhoenixStyledTooltip";
import { PhoenixInputField } from "src/Components/Field/Phoenix";
import { appToast, errorToast } from "src/utils/toast";
import * as Yup from "yup";
import { TabBodyHeader } from "../StepBrandManagement";
import { FormChangeTracker } from "./FormChangeTracker";
import { updateBrandInCache } from "src/utils/misc";
import { OrganizationTokensTabSkeleton } from "./OrganizationTokensTabSkeleton";
const UPDATE_ORGANIZATION_SETTINGS = gql`
  mutation CreateOrUpdateOrganizationTokens($id: String, $address: String!, $name: String!, $brandId: String) {
    createOrUpdateOrganizationTokens(id: $id, address: $address, name: $name, brand_id: $brandId) {
      address
      name
    }
  }
`;

const FETCH_ORGANIZATION_SETTINGS = gql`
  query fetchOrganizationSettings($brandId: String) {
    fetchOrganizationSettings(brand_id: $brandId) {
      address
      name
      id
    }
  }
`;

export const OrganizationTokensTab: React.FC<{
  brandId?: string;
  upToDate: boolean;
  setUpToDate: (upToDate: boolean) => void;
  brandDeleted: boolean;
}> = ({ brandId, upToDate, setUpToDate, brandDeleted }) => {
  const {
    data: organizationSettings,
    loading: organizationSettingsLoading,
    error: organizationSettingsError,
  } = useQuery(FETCH_ORGANIZATION_SETTINGS, {
    fetchPolicy: "network-only",
    variables: {
      brandId: brandId || undefined,
    },
    onCompleted: (data) => {},
    onError: (error) => {
      console.error(error);
      appToast("Unable to fetch organization settings");
    },
  });

  const [updateOrganizationSettings, { loading: updateOrganizationSettingsLoading }] = useMutation(
    UPDATE_ORGANIZATION_SETTINGS,
    {
      onError: (error) => {
        console.error(error);
        errorToast("Unable to update organization settings");
      },
      onCompleted: () => {
        appToast("Organization settings updated");
        if (brandId) {
          updateBrandInCache(brandId, { is_organization_tokens_complete: true });
        }
      },
    },
  );

  const globalLoading = organizationSettingsLoading;

  const globalError = organizationSettingsError;

  if (globalLoading) return <OrganizationTokensTabSkeleton />;

  if (globalError) return <AppErrorText>Unable to fetch organization settings</AppErrorText>;

  const initialValues = {
    address: organizationSettings?.fetchOrganizationSettings?.address || "",
    name: organizationSettings?.fetchOrganizationSettings?.name || "",
    id: organizationSettings?.fetchOrganizationSettings?.id || "",
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          address: Yup.string().notRequired(),
          name: Yup.string().notRequired(),
        })}
        onSubmit={(values) => {
          if (brandDeleted) return;
          updateOrganizationSettings({
            variables: {
              address: values.address,
              name: values.name,
              brandId: brandId || undefined,
              id: organizationSettings?.fetchOrganizationSettings?.id || undefined,
            },
          }).then(() => {
            // Set upToDate to true after successful submission
            setUpToDate(true);
          });
        }}
      >
        {({ submitForm }) => (
          <>
            {/* This component will track form changes and update the upToDate state */}
            <FormChangeTracker setUpToDate={setUpToDate} />

            <PhoenixStyledTooltip id="organization-tokens-tooltip" />
            <Container>
              <TabBodyHeader
                handleSave={submitForm}
                upToDate={upToDate}
                setUpToDate={setUpToDate}
                saveDisabled={brandDeleted}
              />
              <ContainerTitle>Organization Settings</ContainerTitle>
              <AppText fontSize={12} fontWeight={400} color={theme.text.neutral.secondary}>
                Update the tokens used in email, sms, and voicemail script templates.
              </AppText>
              <SpacerDiv height={24} />
              <FlexColumn>
                <TokenInput name="name" label="Organization Name" placeholder="Organization Name" />
                <TokenInput name="address" label="Organization Address" placeholder="Organization Address" />
              </FlexColumn>
            </Container>
          </>
        )}
      </Formik>
    </>
  );
};

const FlexColumn = styled.div`
  display: flex;
  gap: 16px;
`;

const TokenInput = ({
  name,
  label,
  placeholder,
  disabled,
}: {
  name: string;
  label: string;
  placeholder: string;
  disabled?: boolean;
}) => {
  return (
    <PhoenixInputField
      name={name}
      label={label}
      placeholder={placeholder}
      width={376}
      titleText={label}
      titleTextSpacing={8}
      titleTextFontSize={12}
      titleTextFontWeight={500}
      disabled={disabled}
    />
  );
};

const Container = styled.div`
  width: 100%;
  background-color: ${theme.WHITE_COLOR};

  display: flex;
  flex-direction: column;
  border-radius: 8px;

  margin-bottom: 16px;
`;

const ContainerTitle = styled(AppText)`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 4px;
`;

const SpacerDiv = styled.div<{ height?: number | string }>`
  height: ${(props) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "16px"};
  min-height: ${(props) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "16px"};
`;
