import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import moment from "moment";

import { AppText } from "src/Components/UI";
import { PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import { OptionItem } from "src/types";
import { theme } from "src/utils/theme";

import DynamicDropdown, { DropdownMoreBadge } from "./DynamicDropdown";
import DateRangePicker from "./DateRangePicker";
import { hasValue } from "src/utils/misc";
import { useOpenOnMoreFilter } from "src/utils/hooks";

type RepActivityFilterProps = {
  isMoreFilter?: boolean;
  onChange: (value: any) => void;
  options: OptionItem[];
  removeFilter?: () => void;
  value: any;
};

const RepActivityFilter = ({ isMoreFilter, removeFilter, options, value, onChange }: RepActivityFilterProps) => {
  const [localValue, setLocalValue] = useState<{
    user_ids: string[];
    rep_lowerbound_date: string | undefined;
    rep_upperbound_date: string | undefined;
  }>(
    value || {
      user_ids: [],
      rep_lowerbound_date: undefined,
      rep_upperbound_date: undefined,
    },
  );
  const [isOpen, setIsOpen] = useState(false);
  const hasChangesRef = useRef(false);

  useOpenOnMoreFilter(value, setIsOpen, isMoreFilter);

  useEffect(() => {
    if (!isOpen) {
      setLocalValue(value);
      hasChangesRef.current = false;
    }
  }, [value, isOpen]);

  useEffect(() => {
    hasChangesRef.current = JSON.stringify(localValue) !== JSON.stringify(value);
  }, [localValue, value]);

  const selectedOptions = useMemo(() => options?.filter((o) => localValue?.user_ids?.includes(o.value.toString())), [
    options,
    localValue?.user_ids,
  ]);

  const moreItems = localValue?.user_ids?.length - 1;

  const handleIsOpenChange = useCallback(
    (newIsOpen: boolean) => {
      if (!newIsOpen && hasChangesRef.current) {
        onChange?.(localValue);
        hasChangesRef.current = false;
      }
      setIsOpen(newIsOpen);
    },
    [localValue, onChange],
  );

  const handleClear = useCallback(() => {
    setLocalValue({
      user_ids: [],
      rep_lowerbound_date: undefined,
      rep_upperbound_date: undefined,
    });
    hasChangesRef.current = true;
  }, []);

  const handleValueChange = useCallback((updates: any) => {
    setLocalValue((prev) => ({ ...prev, ...updates }));
    hasChangesRef.current = true;
  }, []);

  const headerContent = useMemo(
    () => (
      <>
        <AppText fontSize={12} fontWeight={500} color={theme.text.brand.primary}>
          {selectedOptions?.[0]?.label}
        </AppText>
        {moreItems ? (
          <DropdownMoreBadge>
            <AppText fontSize={12} fontWeight={500} color={theme.text.neutral.inverse}>
              +{moreItems}
            </AppText>
          </DropdownMoreBadge>
        ) : null}

        {localValue?.rep_lowerbound_date && localValue?.rep_upperbound_date ? (
          <AppText fontSize={12} fontWeight={500} color={theme.text.brand.primary}>
            {moment(localValue.rep_lowerbound_date).format("MM/DD/YYYY")} -{" "}
            {moment(localValue.rep_upperbound_date).format("MM/DD/YYYY")}
          </AppText>
        ) : null}
      </>
    ),
    [selectedOptions, moreItems, localValue?.rep_lowerbound_date, localValue?.rep_upperbound_date],
  );

  return (
    <DynamicDropdown<string[]>
      label="Rep Activity"
      onClear={handleClear}
      onRemove={hasValue(localValue) || isMoreFilter ? removeFilter : undefined}
      isOpen={isOpen}
      setIsOpen={handleIsOpenChange}
      value={localValue?.user_ids}
      renderHeader={headerContent}
    >
      <PhoenixMultiSelect
        titleText="Reps"
        titleTextSpacing={8}
        marginBottom={false}
        menuPosition="fixed"
        menuShouldBlockScroll
        name="rep_activity_user_ids"
        options={options}
        placeholder="Select Reps"
        value={selectedOptions}
        onChange={(items: OptionItem[]) => {
          handleValueChange({
            user_ids: items?.map((i) => i.value),
          });
        }}
      />
      <DateRangePicker
        endDateId="rep_activity_end_date"
        lowerboundKey="rep_lowerbound_date"
        onChange={handleValueChange}
        startDateId="rep_activity_start_date"
        upperboundKey="rep_upperbound_date"
        value={localValue}
      />
    </DynamicDropdown>
  );
};

export default RepActivityFilter;
