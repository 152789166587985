import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { PhoenixIcon, PhoenixMultiSelect } from "../../UI/Phoenix";
import { reorder } from "src/images/NewDesign";
import { OptionItem } from "src/types";
import { CustomField, generateLeadFieldOptions } from "src/utils/misc";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FieldMenu } from "./FieldMenu";
import { useFlags } from "launchdarkly-react-client-sdk";
import useMultiBrands from "src/cache-hooks/useIsMultiBrand";

interface IFieldSelect {
  e: OptionItem;
  i: number;
  listLen: number;
  customFieldData: CustomField[];
  leadCardFields: OptionItem[];
  setLeadCardFields: React.Dispatch<React.SetStateAction<OptionItem[]>>;
  doAnimation: boolean;
}

export const FieldSelect: React.FC<IFieldSelect> = ({
  e,
  i,
  listLen,
  customFieldData,
  leadCardFields,
  setLeadCardFields,
  doAnimation,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: e.value });

  const dragStyle = {
    transform: CSS.Transform.toString(transform),
    transition: doAnimation ? transition : undefined,
    /* ensures dragged item AND FieldSelect menu is always on top -- 
    when dragging, set to 20 to ensure it is higher than the rest, when not set zIndex to mapping order 
    (items mapped first will have higher zIndex) */
    zIndex: isDragging ? 20 : Math.max(0, listLen - i),
  };

  const { showMultiBrandUI } = useMultiBrands(useFlags);
  const shouldShowBrandSystemField = useMemo(() => {
    return showMultiBrandUI;
  }, [showMultiBrandUI]);

  return (
    <DragContainer ref={setNodeRef} key={`field-${i}`} style={dragStyle}>
      <div {...attributes} {...listeners}>
        <PhoenixIcon size={16} svg={reorder} style={{ cursor: isDragging ? "grabbing" : "grab" }} />
      </div>
      <SelectField
        isMulti={false}
        isClearable={false}
        name={e.label || ""}
        options={generateLeadFieldOptions(shouldShowBrandSystemField)}
        value={!e.value ? undefined : e}
        placeholder="Select a field"
        width={220}
        marginBottom={false}
        onClick={() => setShowMenu(true)}
        isDisabled
        pointer
      />
      {showMenu && (
        <FieldMenu
          index={i}
          leadCardFields={leadCardFields}
          setLeadCardFields={setLeadCardFields}
          customFieldData={customFieldData}
          setShowMenu={setShowMenu}
        />
      )}
    </DragContainer>
  );
};

const DragContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  gap: 8px;
`;

const SelectField = styled(PhoenixMultiSelect)`
  animation: ${theme.fadeIn} 0.2s ease forwards;
`;
