import styled from "styled-components";
import * as React from "react";
import { useContext, useState } from "react";
import Switch from "react-switch";
import { AppButton, AppText, Loading, AppErrorText } from "../../UI";
import { theme } from "../../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { FieldArray, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import { FiArrowLeft } from "react-icons/fi";
import { formatUSDRaw, formatTypeName } from "../../../utils/format";
import { CallContext, CartContext } from "../../../context/";
import { FormAreaInputField, FormSelectField, InputField } from "../../Field";
import { contractOptions, terms } from "../../../static";
import { IoMdClose } from "react-icons/io";
import { selectQuantityOptions } from "../../../utils/misc";
import { restAPI } from "../../../apollo";
import { BACKEND_URL } from "../../../utils/variables";
import { loggedInUser } from "../../../apollo/cache";
import { appToast, errorToast } from "../../../utils/toast";
import { CHECK_INTEGRATION_STATUS } from "..";

interface DisappearingDivProps {
  setCallOptions: any;
  callOptions: any;
  lead_id: string;
  /**
   * id of the lead intent
   */
  intent_id?: string;
  dispositionType?: string;
  isPandaDocEnabled: boolean;
}

export const FETCH_PRODUCTS = gql`
  query fetchProducts($lead_id: String) {
    fetchProducts(lead_id: $lead_id) {
      id
      product_title
      pricing
      available_concessions {
        id
        label
        amount
      }
    }
  }
`;

const FETCH_LEAD = gql`
  query fetchLead($id: String) {
    fetchLead(id: $id) {
      id
      show_sale_flow_custom_fields
    }
  }
`;

const FETCH_ORG_OPTIONS = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      show_payment_terms_sales
      show_contract_duration_sales
      require_sale_notes
    }
  }
`;

const START_CLOSING_SCRIPT = gql`
  mutation startClosingScript {
    startClosingScript {
      id
    }
  }
`;

const FETCH_PERSON_SPOKE_TO = gql`
  query fetchPersonSpokeToOptions($lead_id: String!, $associated_action: String!) {
    fetchPersonSpokeToOptions(lead_id: $lead_id, associated_action: $associated_action)
  }
`;

const START_SALE_SCRIPT = gql`
  mutation startSale(
    $lead_id: String!
    $contract_duration: String
    $person_spoke_to: CALLRESULT!
    $products: [ProductInputType!]!
  ) {
    startSale(
      lead_id: $lead_id
      contract_duration: $contract_duration
      person_spoke_to: $person_spoke_to
      products: $products
    )
  }
`;

const productsSchema = Yup.object().shape({
  lead_id: Yup.string().required(),
  person_spoke_to: Yup.string().required("This field is required."),
  products: Yup.array()
    .min(1)
    .of(
      Yup.object().shape({
        product_id: Yup.string().required("This field is required."),
        quantity: Yup.string().required("Please select a quantity."),
        price_override: Yup.string().notRequired(),
        concession_id: Yup.string().notRequired(),
        use_custom_price: Yup.boolean().notRequired(),
      }),
    ),
});

interface MyFormikProps {
  lead_id: string;
  intent_id?: string;
  person_spoke_to: string;
  products: any;
  sale_notes: string;
  contract_duration: string;
  payment_terms: string;
}

const MakeSaleComponent: React.FC<DisappearingDivProps> = ({
  setCallOptions,
  callOptions,
  lead_id,
  intent_id,
  isPandaDocEnabled,
}) => {
  const { data: dataProducts, loading: loadingProducts, error: errorProducts } = useQuery(FETCH_PRODUCTS, {
    variables: {
      lead_id: lead_id || undefined,
    },
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: leadData, loading: leadLoading, error: leadError, refetch } = useQuery(FETCH_LEAD, {
    fetchPolicy: "network-only",
    variables: { id: lead_id },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: orgData, loading: orgLoading, error: orgError } = useQuery(FETCH_ORG_OPTIONS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [startClosingScript, { loading, error }] = useMutation(START_CLOSING_SCRIPT, {
    async onCompleted() {
      if (isPandaDocEnabled) {
        setCallOptions("create-pandadoc");
      } else {
        if (leadData?.show_sale_flow_custom_fields) {
          setCallOptions("confirm-details-custom");
        } else {
          setCallOptions("closing-script");
        }
      }
    },
  });

  const [startSale, { loading: loadingSale, error: errorSale }] = useMutation(START_SALE_SCRIPT, {
    async onCompleted({ startSale }) {
      if (!startSale) {
        console.log("Error in startSale.");
        return;
      }
      console.log("startSale mutation ran successfully");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `startSale GraphQL Error: ${message}`,
      });
      console.log("Error in startSale: ", message);
    },
  });

  const { data: dataCallResult, loading: loadingCallResult, error: errorCallResult } = useQuery(FETCH_PERSON_SPOKE_TO, {
    variables: {
      lead_id: lead_id,
      associated_action: callOptions,
    },
    skip: !callOptions,
    fetchPolicy: "no-cache",
  });

  const { handleRevertClosingScript } = useContext(CallContext);
  const { submitClosingScript, person_spoke_to, products, sale_notes, contract_duration, payment_terms } = useContext(
    CartContext,
  );

  if (loadingProducts || orgLoading) return <Loading />;
  if (errorProducts) return <AppErrorText>Error fetching products!</AppErrorText>;

  const productTitleOptions = dataProducts.fetchProducts?.map((item: any) => {
    return { label: `${item.product_title} ($${item.pricing})`, value: item?.id, price: item.price };
  });

  const returnItemPrice = (id: string) => {
    if (!dataProducts.fetchProducts.length) {
      return 0;
    }
    return dataProducts.fetchProducts.filter((item: any) => item.id === id)[0].pricing;
  };

  const returnItemDiscount = (discountID: string, item_id: string) => {
    if (discountID === "0") return 0;
    return dataProducts.fetchProducts
      .filter((item: any) => item.id === item_id)[0]
      .available_concessions.filter((item: any) => item.id === discountID)[0].amount;
  };

  const createDiscountOptions = (data: any, id: string) => {
    const product = data.filter((item: any) => item.id === id);
    if (!!!product[0]) {
      return [];
    }
    return product[0].available_concessions
      .sort((a: any, b: any) => (a.amount > b.amount ? 1 : -1))
      ?.map((item: any) => {
        return {
          label: item.label,
          value: item.id,
        };
      });
  };

  const reduceTotalCost = (prev: any, curr: any) => {
    const current_price = curr.use_custom_price ? curr.price_override || 0 : curr.item_price ? curr.item_price : 0;
    const current_quantity = curr.quantity ? curr.quantity : 0;
    const current_discount = curr.discount_amount ? curr.discount_amount : 0;
    return prev + current_price * current_quantity - current_discount;
  };

  const calculateTotalCost = (products: any) => {
    return formatUSDRaw(Math.max(products.reduce(reduceTotalCost, 0), 0));
  };

  const resumeRecording = async () => {
    const api_call = await restAPI.request({ method: "POST", baseURL: `${BACKEND_URL}/twilio/resumeRecording` });
    return api_call;
  };

  return (
    <Sentry.ErrorBoundary fallback={"Error occurred in Make Sale component"}>
      <Formik
        validateOnMount
        // Check context for values. This helps cart persist is rep needs to go back/forward. It's cleared when makeSale fires.
        initialValues={{
          lead_id: lead_id,
          intent_id: intent_id,
          person_spoke_to: person_spoke_to ? person_spoke_to : "",
          products: products
            ? products
            : [
                {
                  product_id: undefined,
                  quantity: undefined,
                  concession_id: undefined,
                  use_custom_price: false,
                  price_override: 0,
                },
              ],
          contract_duration: contract_duration ? contract_duration : "0",
          payment_terms: payment_terms ? payment_terms : "0",
          sale_notes: sale_notes ? sale_notes : "",
        }}
        validationSchema={productsSchema}
        initialStatus={{ generalError: "" }}
        onSubmit={async (values, { setStatus, setFieldError }) => {
          console.log("makeSale submit values: ", values);
          // check payment terms and contract duration
          if (
            orgData?.fetchOrganization?.show_payment_terms_sales &&
            (!values.payment_terms || values.payment_terms === "0")
          ) {
            setFieldError("payment_terms", "Payment terms are required");
            errorToast("Payment terms are required");
            return;
          }
          if (
            orgData?.fetchOrganization?.show_contract_duration_sales &&
            (!values.contract_duration || values.contract_duration === "0")
          ) {
            setFieldError("contract_duration", "Contract duration is required");
            errorToast("Contract duration is required");
            return;
          }
          if (orgData?.fetchOrganization?.require_sale_notes && !values.sale_notes) {
            setFieldError("sale_notes", "Sale notes are required");
            errorToast("Sale notes are required");
            return;
          }
          startSale({
            variables: {
              lead_id: values.lead_id,
              contract_duration: values.contract_duration,
              person_spoke_to: values.person_spoke_to,
              products: values.products?.map((item: any) => ({
                quantity: Number(item.quantity),
                product_id: item.product_id,
                concession_id: item.concession_id,
                price_override: item.use_custom_price ? Number(item.price_override) : undefined,
              })),
            },
          });

          setStatus({ generalError: "" });
          if (values.products.length) {
            setStatus({ generalError: "You must select at least one product" });
          }
          console.log("values on makeSale: ", values);
          submitClosingScript(values);
          handleRevertClosingScript(true, true);
          resumeRecording();
          startClosingScript();
          setStatus({ generalError: "" });
        }}
      >
        {({
          submitForm,
          values,
          errors,
          setFieldValue,
          isSubmitting,
          isValid,
          dirty,
          handleChange,
          status,
        }: FormikProps<MyFormikProps>) => {
          return (
            <>
              <TopContainer>
                <HeaderDiv>
                  <FiArrowLeft
                    style={{ cursor: "pointer" }}
                    onClick={() => setCallOptions("confirm-details")}
                    size={25}
                    color={theme.BLACK_COLOR}
                  />
                  <AppText fontSize={17}>Who did you speak with?</AppText>
                  <SpacerDiv />
                </HeaderDiv>
                <div>
                  <CallTwoButtonsFlex>
                    {dataCallResult?.fetchPersonSpokeToOptions?.map((item: any) => (
                      <TransferDemoSecondButton
                        transferDemoOptions={values.person_spoke_to}
                        option={item}
                        onClick={() => {
                          setFieldValue("person_spoke_to", values.person_spoke_to === item ? "" : item);
                        }}
                      >
                        {formatTypeName(item)}
                      </TransferDemoSecondButton>
                    ))}
                    {/* <TransferDemoSecondButton
                      transferDemoOptions={values.person_spoke_to}
                      option="DM"
                      onClick={() => {
                        setFieldValue("person_spoke_to", values.person_spoke_to === "DM" ? "" : "DM");
                      }}
                    >
                      DM
                    </TransferDemoSecondButton>
                    <TransferDemoSecondButton
                      transferDemoOptions={values.person_spoke_to}
                      option="DMviaNDM"
                      onClick={() => {
                        setFieldValue("person_spoke_to", values.person_spoke_to === "DMviaNDM" ? "" : "DMviaNDM");
                      }}
                    >
                      DM via NDM
                    </TransferDemoSecondButton> */}
                  </CallTwoButtonsFlex>
                </div>
                <HeaderContainer>
                  <MakeSaleText>Make Sale</MakeSaleText>
                  <DropdownDiv>
                    {orgData.fetchOrganization.show_contract_duration_sales && (
                      <FieldSelect
                        id="contract-duration"
                        name="contract_duration"
                        width={200}
                        placeholder="Contract Duration"
                        allowSelectPlaceholder
                        options={contractOptions}
                        customArrowLocation={18}
                      />
                    )}
                    {orgData.fetchOrganization.show_payment_terms_sales && (
                      <FieldSelect
                        id="payment_terms"
                        name="payment_terms"
                        width={200}
                        placeholder="Payment Terms"
                        allowSelectPlaceholder
                        options={terms}
                        customArrowLocation={18}
                      />
                    )}
                  </DropdownDiv>
                </HeaderContainer>
              </TopContainer>
              {/* Field Array handles adding/removing products section*/}
              <FieldArray name="products">
                {({ remove, push }) => (
                  <>
                    <AddProductButton
                      onClick={() =>
                        push({
                          product_id: undefined,
                          quantity: undefined,
                          concession_id: undefined,
                        })
                      }
                    >
                      + Add Product
                    </AddProductButton>
                    <ProductListDiv>
                      {values.products &&
                        values.products.length > 0 &&
                        values.products?.map((product: any, index: any) => (
                          <ProductItemContainer key={index}>
                            <ProductNumberDiv>{`Product ${index + 1}`}</ProductNumberDiv>
                            <ProductContainer>
                              <CloseButton onClick={() => remove(index)}>
                                <IoMdClose size={22} color={theme.PRIMARY500} />
                              </CloseButton>
                              <ProductSelectDiv>
                                <FieldSelect
                                  name={`products[${index}].product_id`}
                                  placeholder="Select Product"
                                  options={productTitleOptions}
                                  fullWidth={true}
                                  textAlign={"left"}
                                  customArrowLocation={17}
                                  // When product changes the discount and quantity is set back to zero.
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFieldValue(`products[${index}.concession_id]`, "0");
                                    setFieldValue(`products[${index}].discount_amount`, 0);
                                    setFieldValue(`products[${index}.item_price]`, returnItemPrice(e.target.value));
                                    setFieldValue(`products[${index}.use_custom_price]`, false);
                                    setFieldValue(`products[${index}.price_override]`, 0);
                                  }}
                                />
                                <QuantityDiscountDiv>
                                  {" "}
                                  <FieldSelect
                                    name={`products[${index}].quantity`}
                                    placeholder="Qty."
                                    options={selectQuantityOptions(10)}
                                    width={90}
                                    textAlign={"left"}
                                    customArrowLocation={17}
                                    disabled={values.products[index].product_id ? false : true}
                                  />
                                  <FieldSelect
                                    style={{ flexGrow: 1, marginLeft: "10px" }}
                                    name={`products[${index}.concession_id`}
                                    placeholder="No Discount"
                                    options={createDiscountOptions(
                                      dataProducts.fetchProducts,
                                      values.products[index].product_id,
                                    )}
                                    disabled={values.products[index].product_id ? false : true}
                                    fullWidth={true}
                                    textAlign={"left"}
                                    customArrowLocation={17}
                                    allowSelectPlaceholder
                                    onChange={(e) => {
                                      handleChange(e);
                                      setFieldValue(
                                        `products[${index}].discount_amount`,
                                        returnItemDiscount(e.target.value, values.products[index].product_id),
                                      );
                                    }}
                                  />
                                </QuantityDiscountDiv>
                                {orgData?.fetchOrganization?.id &&
                                  orgData?.fetchOrganization?.id !== "1236a64a-c7cc-48e5-9282-aa049373e1b9" && (
                                    <Vertical>
                                      <CustomText>Override Product Price?</CustomText>
                                      <CustomPriceDiv>
                                        <Switch
                                          onChange={(checked: any) =>
                                            setFieldValue(`products[${index}].use_custom_price`, checked)
                                          }
                                          checked={product.use_custom_price}
                                          onColor={theme.PRIMARY500}
                                          offColor={theme.NEUTRAL200}
                                          height={32}
                                          width={54}
                                          handleDiameter={24}
                                          checkedIcon={false}
                                          uncheckedIcon={false}
                                        />
                                        {!!product?.use_custom_price && (
                                          <InputField
                                            name={`products[${index}].price_override`}
                                            // placeholder="Custom Price"
                                            noTopMargin
                                            displayNoContext
                                            disabled={!product.use_custom_price}
                                            width={150}
                                            type="number"
                                          />
                                        )}
                                      </CustomPriceDiv>
                                    </Vertical>
                                  )}
                              </ProductSelectDiv>
                            </ProductContainer>
                          </ProductItemContainer>
                        ))}
                    </ProductListDiv>
                  </>
                )}
              </FieldArray>
              {/* Reducer for total. */}
              <TotalCostDiv>
                <div>Total Cost:</div>
                <div style={{ marginRight: "10px" }}>{calculateTotalCost(values.products)}</div>{" "}
              </TotalCostDiv>
              {(loggedInUser().organization_id === "1236a64a-c7cc-48e5-9282-aa049373e1b9" ||
                process.env.REACT_APP_ENVIRONMENT === "Development") && (
                <ExternalPaymentDiv>
                  <a
                    style={{ border: "none", textDecoration: "none" }}
                    href="https://www.surefireplatform.com/paynow"
                    target="_blank"
                  >
                    <ExternalPaymentButton>Payment App</ExternalPaymentButton>
                  </a>
                </ExternalPaymentDiv>
              )}
              <StopRecordingWarning>
                If Lead is providing payment details, make sure to Stop Recording
              </StopRecordingWarning>

              <MakeSaleText>Sale Notes</MakeSaleText>
              <FormAreaInputField fontSize={13} name="sale_notes" />
              <MakeSaleButton disabled={!!loading || !!loadingSale} onClick={submitForm}>
                {isPandaDocEnabled ? "Continue" : "Begin Closing Script"}
              </MakeSaleButton>
              {status && status.generalError && <AppErrorText>{status.generalError}</AppErrorText>}
              <AppText>{`Lead ID: ${lead_id}`}</AppText>
            </>
          );
        }}
      </Formik>
    </Sentry.ErrorBoundary>
  );
};

const CallTwoButtonsFlex = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const DropdownDiv = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const ExternalPaymentButton = styled.div`
  height: 40px;
  border-radius: 1.8px;
  background-color: ${theme.BLACK_COLOR};
  font-size: 11px;
  color: ${theme.WHITE_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  padding: 0px 14px;
`;
const ExternalPaymentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 26px;
`;

const FieldSelect = styled(FormSelectField)`
  /* height: 25px; */
  font-size: 12px;
  border-radius: 4.4px;
  text-align: center;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin-bottom: 15px;
  margin-top: 40px;
`;

const MakeSaleText = styled(AppText)`
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
`;

const CustomText = styled(AppText)`
  font-size: 10px;
  font-weight: bold;
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border: solid 1px ${theme.NEUTRAL200};
  padding-bottom: 20px;
`;

const ProductItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  margin-bottom: 16px;
`;

const MakeSaleButton = styled(AppButton)`
  width: 262px;
  height: 47px;
  min-height: 47px;
  border-radius: 1.8px;
  font-size: 11px;
  margin-bottom: 32px;
  background-color: ${theme.TERTIARY500};
  color: ${theme.WHITE_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  cursor: pointer;
  font-weight: 600;
`;

interface TransferDemoSecondButtonProps {
  transferDemoOptions: string;
  option: string;
}

const TransferDemoSecondButton = styled(AppButton)<TransferDemoSecondButtonProps>`
  width: 136px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px ${(props) => (props.transferDemoOptions === props.option ? theme.NEUTRAL400 : theme.NEUTRAL200)};
  background-color: ${(props) => (props.transferDemoOptions === props.option ? theme.NEUTRAL100 : theme.WHITE_COLOR)};
  color: ${theme.PRIMARY500};
  font-size: 12px;
  font-weight: 600;
  :focus {
    outline: none;
  }
`;

const AddProductButton = styled.div`
  width: 240px;
  min-height: 52px;
  border-radius: 1.8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.SUCCESS500};
  font-size: 15px;
  color: ${theme.WHITE_COLOR};
  cursor: pointer;
  margin: 0px auto 15px auto;
`;

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomPriceDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.div`
  position: relative;
  left: 50%;
  top: -15px;
  height: 28px;
  width: 28px;
  background: ${theme.NEUTRAL100};
  border-radius: 50%;
  cursor: pointer;
  padding: 3px;
`;

const ProductNumberDiv = styled.div`
  font-size: 11px;
  align-self: flex-start;
  margin-bottom: 10px;
`;

const ProductSelectDiv = styled.div`
  width: 80%;
  margin: auto;
`;

const ProductListDiv = styled.div`
  border-bottom: solid 1px ${theme.NEUTRAL200};
  min-height: 200px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const QuantityDiscountDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const TopContainer = styled.div`
  max-height: 228px;
`;
const TotalCostDiv = styled.div`
  height: 84px;
  font-size: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const SpacerDiv = styled.div`
  width: 25px;
  height: 25px;
`;

const StopRecordingWarning = styled.div`
  width: 100%;
  color: ${theme.ATTENTION700};
  font-size: 11px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 21px;
`;

export { MakeSaleComponent };
