import * as React from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { useState } from "react";

interface AppTextAreaProps {
  currency?: boolean;
  error?: string | false;
  width?: number;
  fontSize?: number;
  noBorder?: boolean;
  fixedSize?: boolean;
  height?: number;
  borderColor?: string;
  backgroundColor?: string;
}

export const StyledAppTextArea = styled.textarea<AppTextAreaProps>`
  width: ${(props) => (!!props.width ? `${props.width}px` : "100%")};
  height: ${(props) => (!!props.height ? `${props.height}px` : "150px")};
  padding: ${(props) => (!!props.borderColor ? "8px 16px" : "5px 10px")};
  border-width: 0.5;
  border-radius: 2.9px;
  border-color: ${(props) =>
    props.noBorder ? "transparent" : !!props.borderColor ? props.borderColor : theme.BLACK_COLOR};
  resize: ${(props) => (props.fixedSize ? "none" : "both")};
  /* border-bottom-color: ${(props) => (props.error ? theme.ATTENTION700 : "rgba(26, 26, 26, 0.2)")}; */
  /* border-bottom-width: 2px; */
  font-size: ${(props) => (props.fontSize ? props.fontSize : 14)}px;
  line-height: 1.6;
  font-family: ${theme.PRIMARY_FONT};
  box-sizing: border-box;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : "transparent")};
  color: ${theme.BLACK_COLOR};
  caret-color: ${theme.PRIMARY500};

  :focus {
    /* border-bottom-color: ${theme.PRIMARY500}; */
    outline: none;
  }
  ::placeholder {
    opacity: 0.7;
  }
`;

export const AppTextArea = ({
  currency = false,
  title,
  error,
  ...props
}: AppTextAreaProps & React.HTMLProps<HTMLTextAreaElement>) => {
  const [isFocused, setFocus] = useState(false);
  const toggleFocus = () => setFocus(!isFocused);
  return (
    <Div>
      {!!title && <InputTitle>{title.toUpperCase()}</InputTitle>}
      {currency && (
        <LeftText // error={!!errors[field.name] && !!touched[field.name]}
          error={!!error}
          focused={isFocused}
        >
          $
        </LeftText>
      )}
      {currency && (
        <RightText // error={!!errors[field.name] && !!touched[field.name]}
          error={!!error}
          focused={isFocused}
        >
          M
        </RightText>
      )}
      <StyledAppTextArea
        {...(props as any)}
        onFocus={toggleFocus}
        onBlur={(e) => {
          toggleFocus();
          !!props.onBlur && props.onBlur(e);
        }}
        currency={currency}
        title={title}
        error={error}
      />
      {!!error ? <InputErrorText>{error}</InputErrorText> : <InputErrorText>&nbsp;</InputErrorText>}
    </Div>
  );
};

const Div = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

interface TextFocus {
  focused?: boolean;
  error?: boolean;
}

const LeftText = styled.p<TextFocus>`
  position: absolute;
  left: 0;
  bottom: 15px;
  font-size: 36px;
  line-height: 1.39;
  letter-spacing: -0.8px;
  font-family: ${theme.PRIMARY_FONT};
  color: ${(props) => (props.focused ? theme.PRIMARY500 : props.error ? theme.ATTENTION700 : "rgba(26, 26, 26, 0.2)")};
  margin: 0;
`;

const RightText = styled.p<TextFocus>`
  position: absolute;
  right: 0;
  bottom: 15px;
  font-size: 36px;
  line-height: 1.39;
  letter-spacing: -0.8px;
  font-family: ${theme.PRIMARY_FONT};
  color: ${(props) => (props.focused ? theme.PRIMARY500 : props.error ? theme.ATTENTION700 : "rgba(26, 26, 26, 0.2)")};
  margin: 0;
`;

RightText.defaultProps = {
  focused: false,
  error: false,
};

LeftText.defaultProps = {
  focused: false,
  error: false,
};

const InputTitle = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  letter-spacing: 2px;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  margin-bottom: 5px;
`;

const InputErrorText = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  letter-spacing: 2px;
  color: ${theme.ATTENTION700};
  margin: 0;
`;
