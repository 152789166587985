import styled from "styled-components";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useState, useEffect, useContext } from "react";
import {
  AppInput,
  AppText,
  AppSidebarCard,
  AppTextArea,
  AppErrorText,
  AppSelect,
  AppTabDiv,
  SkeletonBlock,
} from "../../UI";
import { theme } from "../../../utils/theme";
import { FiArrowLeft } from "react-icons/fi";
import phoneIcon from "../../../images/icons-ic-phone.png";
import iconReportCall from "../../../images/ic_report_call.png";
import { useMutation, gql, useQuery } from "@apollo/client";
import { loggedInUser } from "../../../apollo/cache";
import { toast } from "react-toastify";
import { BiPhone } from "react-icons/bi";
import { MakeSaleComponent, ConfirmLeadDetails, ClosingScript } from "./";
import { CallContext } from "../../../context";
import { FETCH_PERFORMANCE_CHART, SimpleGrid, STACK_RANK_LIST } from "../DashboardSegments/SimpleGrid";
import { formatCellData, formatUSD, calculatePercentage } from "../../../utils/format";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import moment from "moment";
import { ScheduleContext } from "../../../context/ScheduleContext";
import { MixpanelActions } from "./../../../services/mixpanel";
import { AppTable, AppTableItems } from "../../UI/AppTable";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import { PhoenixMultiSelect } from "src/Components/UI/Phoenix";

const TABLE_METRIC_OPTIONS = [
  { label: "Win/Rep/Day", value: "WinPerRepPerDay" },
  { label: "Dials", value: "Dials" },
  { label: "Cold Calls", value: "ColdCalls" },
  { label: "Connects", value: "Connects" },
  { label: "NDM Cold Calls", value: "NDMColdCalls" },
  { label: "DM Cold Calls", value: "DMColdCalls" },
  { label: "Initial Sets", value: "InitialSets" },
  { label: "Rescheduled Sets", value: "RescheduledSets" },
  { label: "All Sets", value: "AllSets" },
  { label: "Pipeline", value: "Pipeline" },
  { label: "OTFs", value: "OTFS" },
  { label: "Initial Holds", value: "InitialHolds" },
  { label: "Rescheduled Holds", value: "RescheduledHolds" },
  { label: "All Holds", value: "AllHolds" },
  { label: "Follow Up Holds", value: "FollowUpHolds" },
  { label: "All Sales", value: "AllSales" },
  { label: "Total Recurring Revenue", value: "TotalRecurringRevenue" },
  { label: "Total Revenue", value: "TotalRevenue" },
  { label: "Total Revenue Value", value: "TotalRevenueValue" },
];

const CURRENCY_METRICS = ["TotalRevenue", "TotalRevenueValue", "TotalRecurringRevenue"];

const FETCH_ORG_GOALS = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      win_rep_day_goal
      set_goal
      hold_goal
    }
  }
`;

const FETCH_TEAM_GOALS = gql`
  query team($team_id: String!) {
    team(where: { id: $team_id }) {
      id
      win_rep_day_goal
      set_goal
      hold_goal
    }
  }
`;

const GET_PROGRESS = gql`
  query getProgress {
    getProgress
  }
`;

const GET_COMPANY_BEST = gql`
  query getCompanyBestAllTime($metric: METRIC!, $record_type: BEST_METRIC_RECORD_TYPE!, $position: String!) {
    getCompanyBestAllTime(metric: $metric, record_type: $record_type, position: $position)
  }
`;

const InactiveRightSidebar: React.FC = () => {
  const [sortTableAscending, setSortTableAscending] = useState(true);
  const [companyBestAllTime, setCompanyBestAllTime] = useState<{ value: number; name: string } | null>(null);

  const {
    monthlyRank,
    setMonthlyRank,
    stackMetric,
    setStackMetric,
    stackRankTeamView,
    setStackRankTeamView,
  } = useContext(ScheduleContext);

  const { data: dataStack, loading: loadingStack, error: errorStack } = useQuery(STACK_RANK_LIST, {
    fetchPolicy: "no-cache",
    partialRefetch: false,
    nextFetchPolicy: "no-cache",
    skip: !loggedInUser()?.id,
    onCompleted: () => {
      console.log("from inactive sidebar grid");
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
    variables: {
      computed_id: loggedInUser()?.id,
      metric: stackMetric,
      position: "Organization",
      only_team: stackRankTeamView ? true : undefined,
      lowerbound_date: monthlyRank ? moment.utc().startOf("month").format() : moment.utc().startOf("day").format(),
      upperbound_date: moment.utc().endOf("day").format(),
    },
  });

  const { data: dataOrgGoals, loading: loadingOrgGoals, error: errorOrgGoals } = useQuery(FETCH_ORG_GOALS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataProgress, loading: loadingProgress, error: errorProgress } = useQuery(GET_PROGRESS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataTeamGoals, loading: loadingTeamGoals, error: errorTeamGoals } = useQuery(FETCH_TEAM_GOALS, {
    variables: {
      team_id: loggedInUser().team_id,
    },
    skip: !loggedInUser().team_id,
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataBest, loading: loadingBest, error: errorBest } = useQuery(GET_COMPANY_BEST, {
    variables: {
      metric: stackMetric,
      record_type: monthlyRank ? "Monthly" : "Daily",
      position: stackRankTeamView ? "Organization" : "Team",
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!dataBest?.getCompanyBestAllTime) return;
    if (!dataStack?.stackRankList) {
      setCompanyBestAllTime({ value: dataBest.getCompanyBestAllTime.value, name: dataBest.getCompanyBestAllTime.name });
      return;
    } else {
      // compare against stack rank data
      const stackRankCurrentMapped =
        (dataStack &&
          dataStack.stackRankList?.map((item: any) => ({ name: item?.row_label, value: item?.best_all_time_value }))) ||
        [];
      const stackRankCurrentBest = stackRankCurrentMapped.sort((a: any, b: any) => b.value - a.value)[0];
      const companyBestAllTime =
        dataBest.getCompanyBestAllTime.value < stackRankCurrentBest.value
          ? stackRankCurrentBest
          : dataBest.getCompanyBestAllTime;
      setCompanyBestAllTime(companyBestAllTime);
    }
  }, [dataBest, dataStack]);

  const parseDataStackData = () => {
    return dataStack.stackRankList
      ?.map((item: any) => item)
      .sort((a: any, b: any) => (sortTableAscending ? a.list_rank - b.list_rank : b.list_rank - a.list_rank))
      ?.map((item: any) => {
        let rank = item.list_rank;
        switch (item.list_rank) {
          case 1:
            rank = "🥇"; // font-size: 26px;
            break;
          case 2:
            rank = "🥈";
            break;
          case 3:
            rank = "🥉";
            break;
          default:
            break;
        }
        return [
          rank,
          item.row_label,
          item.metric_type === "Dollar" ? formatUSD(item.list_value) : Math.round(item.list_value * 10) / 10,
          item.metric_type === "Dollar"
            ? formatUSD(item.best_all_time_value)
            : Math.round(item.best_all_time_value * 10) / 10,
        ];
      });
  };

  const StackRankLoader = () => {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: 6,
          padding: "0 16px",
        }}
      >
        <SkeletonBlock height={60} width={"95%"} borderRadius={6} />
        {new Array(10).fill(0)?.map((_, i) => (
          <div style={{ display: "flex", alignItems: "center", width: "100%", gap: 4 }}>
            <SkeletonBlock height={40} width={"20%"} borderRadius={6} delayNumber={i} />
            <SkeletonBlock height={40} width={"60%"} borderRadius={6} delayNumber={i} />
            <SkeletonBlock height={40} width={"10%"} borderRadius={6} delayNumber={i} />
            <SkeletonBlock height={40} width={"10%"} borderRadius={6} delayNumber={i} />
          </div>
        ))}
      </div>
    );
  };

  const GoalsLoader = () => {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: 10,
          padding: "20px",
        }}
      >
        {new Array(3).fill(0)?.map((_, i) => (
          <div style={{ display: "flex", alignItems: "center", width: "100%", gap: 16 }}>
            <SkeletonBlock height={20} width={80} borderRadius={6} delayNumber={i} />
            <SkeletonBlock height={20} width={220} borderRadius={6} delayNumber={i} />
            <SkeletonBlock height={20} width={40} borderRadius={6} delayNumber={i} />
          </div>
        ))}
      </div>
    );
  };

  return (
    <SideBarContainer>
      <PhoenixStyledTooltip id="goals-tooltip" />
      <AppSidebarCard title="Daily Goals" width="432px">
        {loadingOrgGoals || loadingTeamGoals || loadingProgress ? (
          <div style={{ maxHeight: "110px" }}>
            <GoalsLoader />
          </div>
        ) : errorOrgGoals || errorTeamGoals || errorProgress ? (
          <AppErrorText>Error loading goals</AppErrorText>
        ) : !loggedInUser()?.team_id ? (
          <AppText style={{ padding: "10px" }}>You are not currently assigned to a team.</AppText>
        ) : (
          <PaddingDiv>
            <BarGrid>
              <AppText style={{ fontWeight: 600 }}>Win/Rep/Day</AppText>
              <PercentIndicatorBar
                percentage={calculatePercentage(
                  dataProgress?.getProgress?.user?.win_rep_day,
                  dataOrgGoals?.fetchOrganization?.win_rep_day_goal,
                )}
              />
              <NumberText
                data-tip={`${Math.round(dataProgress?.getProgress?.user?.win_rep_day)}/${
                  dataOrgGoals?.fetchOrganization?.win_rep_day_goal
                }`}
                data-for="goals-tooltip"
              >
                {Math.round(dataProgress?.getProgress?.user?.win_rep_day)}/
                {dataOrgGoals?.fetchOrganization?.win_rep_day_goal}
              </NumberText>
            </BarGrid>
            <BarGrid>
              <AppText style={{ fontWeight: 600 }}>Sets</AppText>
              <PercentIndicatorBar
                percentage={calculatePercentage(
                  dataProgress?.getProgress?.user?.set,
                  dataOrgGoals?.fetchOrganization?.set_goal,
                )}
              />
              <NumberText
                data-tip={`${Math.round(dataProgress?.getProgress?.user?.set)}/${
                  dataOrgGoals?.fetchOrganization?.set_goal
                }`}
                data-for="goals-tooltip"
              >
                {Math.round(dataProgress?.getProgress?.user?.set)}/{dataOrgGoals?.fetchOrganization?.set_goal}
              </NumberText>
            </BarGrid>
            <BarGrid>
              <AppText style={{ fontWeight: 600 }}>Holds</AppText>
              <PercentIndicatorBar
                percentage={calculatePercentage(
                  dataProgress?.getProgress?.user?.hold,
                  dataOrgGoals?.fetchOrganization?.hold_goal,
                )}
              />
              <NumberText
                data-tip={`${Math.round(dataProgress?.getProgress?.user?.hold)}/${
                  dataOrgGoals?.fetchOrganization?.hold_goal
                }`}
                data-for="goals-tooltip"
              >
                {Math.round(dataProgress?.getProgress?.user?.hold)}/{dataOrgGoals?.fetchOrganization?.hold_goal}
              </NumberText>
            </BarGrid>
          </PaddingDiv>
        )}
      </AppSidebarCard>
      <AppSidebarCard title="Team Goals" width="432px">
        {loadingOrgGoals || loadingTeamGoals || loadingProgress ? (
          <div style={{ maxHeight: "110px" }}>
            <GoalsLoader />
          </div>
        ) : errorOrgGoals || errorTeamGoals || errorProgress ? (
          <AppErrorText>Error loading goals</AppErrorText>
        ) : !loggedInUser()?.team_id ? (
          <AppText style={{ padding: "10px" }}>You are not currently assigned to a team.</AppText>
        ) : (
          <PaddingDiv>
            <BarGrid>
              <AppText style={{ fontWeight: 600 }}>Win/Rep/Day</AppText>
              <PercentIndicatorBar
                percentage={calculatePercentage(
                  dataProgress?.getProgress?.team?.win_rep_day,
                  dataTeamGoals?.team?.win_rep_day_goal,
                )}
              />
              <NumberText
                data-tip={`${Math.round(dataProgress?.getProgress?.team?.win_rep_day)}/${
                  dataTeamGoals?.team?.win_rep_day_goal
                }`}
                data-for="goals-tooltip"
              >
                {Math.round(dataProgress?.getProgress?.team?.win_rep_day)}/{dataTeamGoals?.team?.win_rep_day_goal}
              </NumberText>
            </BarGrid>
            <BarGrid>
              <AppText style={{ fontWeight: 600 }}>Sets</AppText>
              <PercentIndicatorBar
                percentage={calculatePercentage(dataProgress?.getProgress?.team?.set, dataTeamGoals?.team?.set_goal)}
              />
              <NumberText
                data-tip={`${Math.round(dataProgress?.getProgress?.team?.set)}/${dataTeamGoals?.team?.set_goal}`}
                data-for="goals-tooltip"
              >
                {Math.round(dataProgress?.getProgress?.team?.set)}/{dataTeamGoals?.team?.set_goal}
              </NumberText>
            </BarGrid>
            <BarGrid>
              <AppText style={{ fontWeight: 600 }}>Holds</AppText>
              <PercentIndicatorBar
                percentage={calculatePercentage(dataProgress?.getProgress?.team?.hold, dataTeamGoals?.team?.hold_goal)}
              />
              <NumberText
                data-tip={`${Math.round(dataProgress?.getProgress?.team?.hold)}/${dataTeamGoals?.team?.hold_goal}`}
                data-for="goals-tooltip"
              >
                {Math.round(dataProgress?.getProgress?.team?.hold)}/{dataTeamGoals?.team?.hold_goal}
              </NumberText>
            </BarGrid>
          </PaddingDiv>
        )}
      </AppSidebarCard>

      <AppSidebarCard title="Stack Rank" width="432px" stretchHeight>
        <TabContainer>
          <AppTabDiv
            selected={!stackRankTeamView}
            onClick={() => {
              MixpanelActions.track("Stack Rank Event", {
                Stack_Metric: `${stackMetric}`,
                Date_Range: `${!!monthlyRank ? "Monthly" : "Daily"}`,
                View: `${!!stackRankTeamView ? "Team" : "Organization"}`,
              });
              setStackRankTeamView(false);
            }}
          >
            ORGANIZATION
          </AppTabDiv>
          <AppTabDiv
            selected={stackRankTeamView}
            onClick={() => {
              MixpanelActions.track("Stack Rank Event", {
                Stack_Metric: `${stackMetric}`,
                Date_Range: `${!!monthlyRank ? "Monthly" : "Daily"}`,
                View: `${!!stackRankTeamView ? "Team" : "Organization"}`,
              });
              setStackRankTeamView(true);
            }}
          >
            TEAM
          </AppTabDiv>
        </TabContainer>

        <DropdownsGrid>
          <PhoenixMultiSelect
            name="stack-rank-1"
            isMulti={false}
            isClearable={false}
            width={185}
            value={{ label: monthlyRank ? "Monthly" : "Daily", value: monthlyRank ? "monthly" : "daily" }}
            options={[
              { label: "Daily", value: "daily" },
              { label: "Monthly", value: "monthly" },
            ]}
            onChange={(e: any) => {
              MixpanelActions.track("Stack Rank Event", {
                Stack_Metric: `${stackMetric}`,
                Date_Range: `${!!monthlyRank ? "Monthly" : "Daily"}`,
                View: `${!!stackRankTeamView ? "Team" : "Organization"}`,
              });
              setMonthlyRank(e.value === "monthly");
            }}
          />
          <PhoenixMultiSelect
            name="stack-rank-2"
            isMulti={false}
            isClearable={false}
            width={185}
            value={{ label: TABLE_METRIC_OPTIONS.find((e) => e.value === stackMetric)?.label, value: stackMetric }}
            options={TABLE_METRIC_OPTIONS}
            onChange={(e: any) => {
              MixpanelActions.track("Stack Rank Event", {
                Stack_Metric: `${stackMetric}`,
                Date_Range: `${!!monthlyRank ? "Monthly" : "Daily"}`,
                View: `${!!stackRankTeamView ? "Team" : "Organization"}`,
              });
              setStackMetric(e.value);
            }}
          />
        </DropdownsGrid>

        {!!!dataStack || loadingStack ? (
          <>
            <StackRankLoader />
          </>
        ) : errorStack ? (
          <AppErrorText>Error loading stack rank data!</AppErrorText>
        ) : (
          <>
            {loadingBest ? (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                <SkeletonBlock height={40} width={"90%"} borderRadius={6} />
              </div>
            ) : errorBest ? (
              <AppErrorText></AppErrorText>
            ) : (
              <FlexCenterDiv>
                <AppText>
                  Company All-Time Record:{" "}
                  {CURRENCY_METRICS.includes(stackMetric)
                    ? `${formatUSD(companyBestAllTime?.value || 0)}`
                    : companyBestAllTime?.value}
                </AppText>
                <DividerText>|</DividerText>
                <AppText>{companyBestAllTime?.name}</AppText>
              </FlexCenterDiv>
            )}
            <TableContainer>
              <AppTable
                sticky
                columns={[
                  { label: "Rank" },
                  { label: "Team Member" },
                  {
                    label: "My Metric",
                    onClick: () => setSortTableAscending(!sortTableAscending),
                    isAscending: sortTableAscending,
                  },
                  { label: "Best All Time" },
                ]}
              >
                {dataStack && dataStack.stackRankList && <AppTableItems data={parseDataStackData()} />}
              </AppTable>
            </TableContainer>
          </>
        )}
      </AppSidebarCard>
    </SideBarContainer>
  );
};

const NumberText = styled(AppText)`
  white-space: nowrap;
  text-overflow: ellipsis;
  width: max-content;
  max-width: 100%;
  overflow: hidden;
  cursor: default;
`;

const SideBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100vh;
  margin-right: 24px;
  /* overflow: hidden; */
`;

const PaddingDiv = styled.div`
  padding: 16px 24px;
`;

const BarGrid = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr 55px;
  grid-gap: 16px;
  align-items: center;
  margin-bottom: 12px;
  :last-child {
    margin-bottom: 0px;
  }
`;

const DividerText = styled(AppText)`
  padding-left: 8px;
  padding-right: 8px;
  color: ${theme.NEUTRAL200};
`;

const FlexCenterDiv = styled.div`
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface PercentageProp {
  percentage: number;
}

const PercentIndicatorBar = styled.div<PercentageProp>`
  position: relative;
  height: 6px;
  width: 100%;
  border-radius: 9px;
  background: ${theme.NEUTRAL200};
  ::after {
    position: absolute;
    content: "";
    top: 0px;
    left: 0px;
    bottom: 0px;
    border-radius: 9px;
    background: ${theme.PRIMARY500};
    width: ${(props) => (!!props.percentage ? `${props.percentage * 100}%` : "0%")};
  }
`;

const DropdownsGrid = styled.div`
  display: grid;

  grid-template-columns: 185px 185px;

  grid-gap: 12px;
  margin: 0px auto;
  padding: 0px 24px;
`;

const CustomSelect = styled(AppSelect)`
  display: inline;
  line-height: 1.6;
`;

const TableContainer = styled.div`
  /* min-height: 400px; */
  height: auto;
  max-height: 350px;
  overflow-y: scroll;
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;
export { InactiveRightSidebar };
