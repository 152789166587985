import styled from "styled-components";
import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { AppButton, AppText, AppInput, Loading, AppErrorText, FlexDiv } from "../../UI";
import { theme } from "../../../utils/theme";
import { mobileSize } from "../../../utils/breakpoints";
import { useMutation, useQuery, gql } from "@apollo/client";
import { isLoggedInVar, loggedInUser } from "../../../apollo/cache";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { FormMultiSelectField, InputField } from "../../Field";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineLeft } from "react-icons/ai";
import { AiFillPlusCircle } from "react-icons/ai";
import Switch from "react-switch";
import { Link, useLocation, useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import { appToast } from "../../../utils/toast";
import { trash } from "../../../images/NewDesign";
import { PhoenixIcon } from "../../UI/Phoenix";
import { useFlags } from "launchdarkly-react-client-sdk";
import useMultiBrands from "src/cache-hooks/useIsMultiBrand";

interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
}

const productsSchema = Yup.object().shape({
  products: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        product_title: Yup.string().required("Field is required"),
        pricing: Yup.number().required("Field is required"),
        ae_commission: Yup.number().required("Field is required"),
        sdr_commission: Yup.number().required("Field is required"),
      }),
    )
    .required("Please add products!")
    .min(1, "Please add a minimum of one product!"),
});

interface ProductObject {
  id?: string;
  product_title: string;
  pricing: number;
  ae_commission: number;
  sdr_commission: number;
}

interface MyFormikProps {
  products: ProductObject[];
  brand_id?: string;
}

const ADD_OR_UPDATE_PRODUCTS = gql`
  mutation AddOrUpdateProducts($products: [ProductsInput!]!, $brand_id: String) {
    addOrUpdateProducts(products: $products, brand_id: $brand_id) {
      id
      product_title
      pricing
      ae_commission
      sdr_commission
    }
  }
`;

const GET_ALL_PRODUCTS = gql`
  query GetAllProducts {
    products {
      id
      product_title
      pricing
      ae_commission
      sdr_commission
    }
  }
`;

const DELETE_ONE_PRODUCT = gql`
  mutation DeleteOneProduct($productId: String!) {
    deleteOneProduct(product_id: $productId) {
      id
    }
  }
`;

const StepProducts: React.FC<DisappearingDivProps> = ({ step, blinds, setBlinds, isOnboarding }) => {
  const history = useHistory();

  const [addOrUpdateProducts, { loading, error }] = useMutation(ADD_OR_UPDATE_PRODUCTS, {
    onCompleted({ addOrUpdateProducts }) {
      console.log("Submitted addOrUpdateProducts: ", addOrUpdateProducts);
      appToast("Products added!");
      if (isOnboarding) {
        history.push(`/onboarding?step=${step + 1}`);
      } else {
        appToast("Updated!");
      }
      if (!addOrUpdateProducts) {
        return;
      }
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `addOrUpdateProducts GraphQL Error: ${message}`,
      });
      console.log("Error in addOrUpdateProducts: ", message);
    },
  });

  const [deleteOneProduct] = useMutation(DELETE_ONE_PRODUCT, {
    onCompleted({ deleteOneProduct }) {
      console.log("Add product: ", deleteOneProduct);
      if (!deleteOneProduct) {
        return;
      }
      appToast("Product Deleted");
    },
    onError({ message }) {
      console.log("Error in deleteOneProduct: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `deleteOneProduct GraphQL Error: ${message}`,
      });
    },
  });

  const { data, loading: loadingFetch, error: errorFetch } = useQuery(GET_ALL_PRODUCTS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { showMultiBrandUI } = useMultiBrands(useFlags);
  return (
    <Sentry.ErrorBoundary fallback={"An error has occured on the Products page"}>
      {loadingFetch ? (
        <Loading />
      ) : errorFetch ? (
        <TopAlignDiv>
          <AppText>Error fetching products!</AppText>
        </TopAlignDiv>
      ) : (
        <Formik
          initialValues={{
            products: data.products.slice()?.map(({ __typename, ...p }: any) => ({ ...p })),
            brand_id: "",
          }}
          validationSchema={productsSchema}
          onSubmit={async ({ products, brand_id }) => {
            console.log(products);
            await addOrUpdateProducts({
              variables: {
                products: products,
                brand_id: brand_id || undefined,
              },
            });
          }}
        >
          {({
            submitForm,
            values,
            errors,
            setFieldValue,
            isSubmitting,
            isValid,
            dirty,
          }: FormikProps<MyFormikProps>) => {
            console.log("values: ", values);
            console.log("errors: ", errors);
            return (
              <DisappearingDiv>
                <TopAlignDiv>
                  <SegmentTitle style={{ marginBottom: "20px" }}>Products</SegmentTitle>
                  {values.products && values.products.length > 0 ? (
                    <>
                      <ProductsDiv>
                        <AppText fontSize={14}>Product Title</AppText>
                        <CenterDiv>
                          <AppText fontSize={14}>Price</AppText>
                        </CenterDiv>
                        <CenterDiv style={{ marginLeft: "30px" }}>
                          <AvergeCommissionEarnedText>Average Commission Earned</AvergeCommissionEarnedText>
                        </CenterDiv>
                      </ProductsDiv>
                      {values.products?.map((item, index) => (
                        <ProductsDiv key={index}>
                          <ProductsInput
                            name={`products[${index}].product_title`}
                            width={300}
                            leftAlign
                            value={values.products[index].product_title}
                            onChange={(e: any) => {
                              // const index = products.findIndex((x) => x.id === item.id);
                              // setProducts([
                              //   ...products.slice(0, index),
                              //   { ...products[index], title: e.target.value },
                              //   ...products.slice(index + 1),
                              // ]);
                              setFieldValue(`products[${index}].product_title`, e.target.value);
                            }}
                          />
                          <ProductsInput name={`products[${index}].pricing`} currency type="number" />
                          <ProductsDivLastColumn>
                            <InputLabelBold style={{ marginLeft: 0 }}>Account Executive</InputLabelBold>
                            <ProductsInput name={`products[${index}].ae_commission`} currency type="number" />
                            <InputLabelBold>SDR</InputLabelBold>
                            <ProductsInput name={`products[${index}].sdr_commission`} currency type="number" />
                            <div>
                              <PhoenixIcon
                                svg={trash}
                                style={{ marginLeft: "20px", cursor: "pointer" }}
                                size={22}
                                color={theme.NEUTRAL500}
                                hoverColor={theme.NEUTRAL500}
                                onClick={async () => {
                                  if (item?.id) {
                                    await deleteOneProduct({
                                      variables: {
                                        productId: item?.id,
                                      },
                                    });
                                  }
                                  setFieldValue(
                                    "products",
                                    values.products.filter((val, i) => i !== index),
                                  );
                                }}
                              />
                            </div>
                            <div>
                              <AiFillPlusCircle
                                style={{ marginLeft: "10px", cursor: "pointer" }}
                                size={22}
                                color={theme.SUCCESS500}
                                onClick={() => {
                                  setFieldValue("products", [
                                    ...values.products,
                                    {
                                      id: "",
                                      product_title: "",
                                      pricing: 0,
                                      ae_commission: 0,
                                      sdr_commission: 0,
                                    },
                                  ]);
                                }}
                              />
                            </div>
                          </ProductsDivLastColumn>
                        </ProductsDiv>
                      ))}
                    </>
                  ) : (
                    <EmptyStateDiv>
                      <EmptyStateText>Add A New Product</EmptyStateText>
                      <CenterDiv>
                        <AiFillPlusCircle
                          style={{ cursor: "pointer" }}
                          size={48}
                          color={theme.SUCCESS500}
                          onClick={() => {
                            setFieldValue("products", [
                              {
                                product_title: "",
                                pricing: 0,
                                ae_commission: 0,
                                sdr_commission: 0,
                              },
                            ]);
                          }}
                        />
                      </CenterDiv>
                    </EmptyStateDiv>
                  )}
                </TopAlignDiv>
                <CenterDiv>
                  {values.products && values.products.length === 0 ? (
                    <SkipStepButton
                      onClick={() => {
                        if (isOnboarding) {
                          history.push(`/onboarding?step=${step + 1}`);
                        } else {
                          appToast("Updated!");
                        }
                      }}
                    >
                      Skip This Step
                    </SkipStepButton>
                  ) : isSubmitting ? (
                    <Loading />
                  ) : (
                    <SaveAndContinueButton type="submit" onClick={submitForm}>
                      {isOnboarding === true ? "Save & Continue" : "Save Changes"}
                    </SaveAndContinueButton>
                  )}
                </CenterDiv>
              </DisappearingDiv>
            );
          }}
        </Formik>
      )}
    </Sentry.ErrorBoundary>
  );
};

const SkipStepButton = styled(AppButton)`
  width: 221px;
  height: 47px;
  border-radius: 2px;
  border: solid 2px ${theme.NEUTRAL100};
  background-color: #d0d0d0;
  font-size: 15px;
  font-weight: 500;
  color: ${theme.BLACK_COLOR};
`;

const EmptyStateText = styled(AppText)`
  font-size: 14px;
  margin-bottom: 23px;
`;

const EmptyStateDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
`;

interface ProductsInputProps {
  width?: number;
  leftAlign?: boolean;
}

const ProductsInput = styled(InputField)<ProductsInputProps>`
  text-align: ${(props) => (props.leftAlign ? "left" : "center")};
  height: 45px;
  width: ${(props) => (props.width ? `${props.width}px` : "100px")};
  border: solid 1px ${theme.NEUTRAL200};
  font-size: 13px;
  :focus {
    border: solid 1px ${theme.BLACK_COLOR};
  }
`;

const InputLabelBold = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
  margin-left: 48px;
  white-space: nowrap;
`;

const AvergeCommissionEarnedText = styled(AppText)`
  font-size: 10px;
  line-height: 20px;
  position: relative;
  :before {
    position: absolute;
    content: " ";
    left: -28px;
    top: 10px;
    width: 22px;
    height: 13.5px;
    border-top: solid 1px ${theme.BLACK_COLOR};
    border-left: solid 1px ${theme.BLACK_COLOR};
  }
  :after {
    position: absolute;
    content: " ";
    right: -28px;
    top: 10px;
    width: 22px;
    height: 13.5px;
    border-top: solid 1px ${theme.BLACK_COLOR};
    border-right: solid 1px ${theme.BLACK_COLOR};
  }
`;

const ProductsDivLastColumn = styled.div`
  display: flex;
  align-items: center;
`;

const ProductsDiv = styled.div`
  display: grid;
  grid-template-columns: 300px 100px 500px;
  grid-gap: 30px;
  width: 960px;
  margin: auto;
`;

const SourcesTable = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr) 200px;
  grid-gap: 10px;
  justify-self: center;
  align-self: center;
`;

const SegmentTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
  margin: auto;
`;

const ContentSegment = styled.div``;

const SourcesRow = styled.div`
  display: grid;
  grid-template-rows: 50px 50px 50px;
  justify-self: center;
  align-self: center;
  break-inside: avoid;
  /* flex-direction: column;
  height: 250px;
  justify-content: center;
  align-items: center; */
`;

const AlgoParamSegment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  break-inside: avoid;
  height: 50px;
`;

const TwoSegments = styled.div`
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const TopAlignDiv = styled.div`
  min-height: 400px;
  max-height: 450px;
  overflow: auto;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SaveAndContinueButton = styled(AppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
`;

export { StepProducts };
