import { gql, useQuery } from "@apollo/client";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useContext } from "react";
import { loggedInUser } from "src/apollo/cache";
import { CallContext, OTFVideoMeeting } from "src/context";

const FETCH_CACHED_MEETING = gql`
  query fetchCachedInstantMeeting($lead_id: String!) {
    fetchCachedInstantMeeting(lead_id: $lead_id)
  }
`;

const useFetchCachedMeeting = ({ leadId }: { leadId: string }) => {
  const { zoomIntegration, googleMeetIntegration } = useFlags();
  const { existingOTFVideoMeetings } = useContext(CallContext);

  const existingLeadOTFVideoMeeting = existingOTFVideoMeetings[leadId] ?? null;

  const zoomIsEnabledOnUser = loggedInUser().checkUserCalendarStatus?.zoom_status === "active";
  const googleMeetIsEnabledOnUser = loggedInUser().checkUserCalendarStatus?.google_status === "active";

  const hasZoomMeeting = zoomIntegration && zoomIsEnabledOnUser;
  const hasGoogleMeeting = googleMeetIntegration && googleMeetIsEnabledOnUser;

  const tryZoom = useQuery(FETCH_CACHED_MEETING, {
    fetchPolicy: "cache-and-network",
    variables: {
      lead_id: leadId,
    },
    skip: Boolean(existingLeadOTFVideoMeeting) || !hasZoomMeeting,
    onError: () => {
      if (hasGoogleMeeting) {
        tryGoogle.refetch();
      }
    },
    onCompleted: (data) => {
      if (!data?.fetchCachedInstantMeeting && hasGoogleMeeting) {
        tryGoogle.refetch();
      }
    },
  });

  const tryGoogle = useQuery(FETCH_CACHED_MEETING, {
    fetchPolicy: "cache-and-network",
    variables: {
      lead_id: leadId,
    },
    skip:
      Boolean(existingLeadOTFVideoMeeting) ||
      !hasGoogleMeeting ||
      (!tryZoom.error && tryZoom.data?.fetchCachedInstantMeeting),
    onError: () => {
      console.log("Both meeting providers failed");
    },
  });

  const shouldUseGoogle =
    hasGoogleMeeting &&
    (tryZoom.error || !tryZoom.data?.fetchCachedInstantMeeting) &&
    tryGoogle.data?.fetchCachedInstantMeeting;

  const rawData = shouldUseGoogle ? tryGoogle.data : tryZoom.data;

  const data = rawData?.fetchCachedInstantMeeting?.[0];

  return {
    data:
      existingLeadOTFVideoMeeting ?? data
        ? ({
            meeting_id: data?.meeting_id,
            meeting_url: data?.meeting_url,
            meeting_code: data?.meeting_code,
            meeting_type: data?.google_space_sellfire_id ? "Google" : "Zoom",
            sellfire_meeting_id: data?.google_space_sellfire_id
              ? data?.google_space_sellfire_id
              : data?.zoom_meeting_sellfire_id,
          } as OTFVideoMeeting)
        : null,
    loading: (hasZoomMeeting && tryZoom.loading) || (hasGoogleMeeting && tryGoogle.loading),
    error:
      (hasZoomMeeting && tryZoom.error && hasGoogleMeeting && tryGoogle.error) ||
      (!hasZoomMeeting && !hasGoogleMeeting ? new Error("No meeting providers available") : undefined),
    meetingType: shouldUseGoogle ? "google" : "zoom",
  };
};

export default useFetchCachedMeeting;
