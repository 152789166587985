import React from "react";
import styled from "styled-components";
import { SkeletonBlock } from "src/Components/UI";
import { theme } from "src/utils/theme";

export const OrganizationTokensTabSkeleton: React.FC = () => {
  return (
    <Container>
      {/* Header skeleton */}
      <HeaderContainer>
        <SkeletonBlock width={150} height={32} borderRadius={4} />
        <ButtonsContainer>
          <SkeletonBlock width={80} height={36} borderRadius={4} delayNumber={1} />
          <SkeletonBlock width={80} height={36} borderRadius={4} delayNumber={2} />
        </ButtonsContainer>
      </HeaderContainer>

      {/* Title and description skeleton */}
      <SkeletonBlock width={200} height={24} borderRadius={4} delayNumber={3} />
      <SpacerDiv height={8} />
      <SkeletonBlock width={400} height={16} borderRadius={4} delayNumber={4} />

      <SpacerDiv height={24} />

      {/* Form fields skeleton */}
      <FlexColumn>
        <FieldSkeleton delayNumber={5} />
        <FieldSkeleton delayNumber={6} />
      </FlexColumn>
    </Container>
  );
};

const FieldSkeleton: React.FC<{ delayNumber: number }> = ({ delayNumber }) => {
  return (
    <FieldContainer>
      <SkeletonBlock width={100} height={16} borderRadius={4} delayNumber={delayNumber} />
      <SpacerDiv height={8} />
      <SkeletonBlock width={376} height={40} borderRadius={4} delayNumber={delayNumber + 1} />
    </FieldContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  background-color: ${theme.WHITE_COLOR};

  display: flex;
  flex-direction: column;
  border-radius: 8px;

  margin-bottom: 16px;
`;

const SpacerDiv = styled.div<{ height?: number | string }>`
  height: ${(props) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "16px"};
  min-height: ${(props) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "16px"};
`;

const FlexColumn = styled.div`
  display: flex;
  gap: 16px;
`;
