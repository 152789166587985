import React, { useEffect } from "react";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { AppErrorText, AppText, FlexDiv } from "../../../UI";

import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { OPT_IN_MESSAGES, OPT_OUT_MESSAGES, SMS_SECTION_ORDER } from "src/static";
import { updateBrandInCache } from "src/utils/misc";
import * as Yup from "yup";
import { reorder } from "../../../../images/NewDesign";
import { appToast } from "../../../../utils/toast";
import { PhoenixIcon, PhoenixRadio } from "../../../UI/Phoenix";
import { TabBodyHeader } from "../StepBrandManagement";
import { FormChangeTracker } from "./FormChangeTracker";
import { SMSSettingsTabSkeleton } from "./SMSSettingsTabSkeleton";

interface DisappearingDivProps {
  brandId?: string;
  togglePageTitle?: () => void;
  upToDate: boolean;
  setUpToDate: (upToDate: boolean) => void;
  brandDeleted: boolean;
}

interface SMSTemplateData {
  id: string;
  sms_opt_in: string;
  sms_opt_out: string;
  sms_text_order: Array<SMSTextOrderItem>;
}

interface SMSTextOrderItem {
  value: string;
  label?: string;
}

interface DragEndResult {
  draggableId: string;
  type: string;
  source: {
    droppableId: string;
    index: number;
  };
  destination: {
    droppableId: string;
    index: number;
  } | null;
}

const validationSchema = Yup.object().shape({
  optInMessage: Yup.string().required("Please select an opt-in message"),
  optOutMessage: Yup.string().required("Please select an opt-out message"),
  order: Yup.array().of(Yup.string()).required("Please select an order of messaging"),
});

const FETCH_SMS_GLOBAL_TEMPLATE = gql`
  query fetchSMSComplianceTemplate($brandId: String) {
    fetchSMSComplianceTemplate(brand_id: $brandId)
  }
`;

const UPDATE_SMS_GLOBAL_TEMPLATE = gql`
  mutation CreateOrUpdateSMSComplianceTemplate(
    $smsOptIn: String!
    $smsOptOut: String!
    $smsTextOrder: [SMSTextOrder!]!
    $brandId: String
    $id: String
  ) {
    createOrUpdateSMSComplianceTemplate(
      sms_opt_in: $smsOptIn
      sms_opt_out: $smsOptOut
      sms_text_order: $smsTextOrder
      brand_id: $brandId
      id: $id
    ) {
      sms_opt_in
      sms_opt_out
      sms_text_order
      id
    }
  }
`;

const SMSSettingsTab: React.FC<DisappearingDivProps> = ({
  brandId,
  upToDate,
  setUpToDate,
  togglePageTitle,
  brandDeleted,
}) => {
  useEffect(() => {
    if (togglePageTitle) {
      togglePageTitle();
    }
  }, []);

  // Helper function to get a human-readable label for a value
  const getLabelForValue = (value: string): string => {
    // Try to find in SMS_SECTION_ORDER first
    const sectionItem = SMS_SECTION_ORDER.find((item) => item.value === value);
    if (sectionItem?.label) return sectionItem.label;

    // If not found, create a formatted label from the value
    return value
      .replace(/([A-Z])/g, " $1") // Add spaces before capital letters
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
  };

  const {
    data: fetchSMSGlobalTemplateData,
    loading: fetchSMSGlobalTemplateLoading,
    error: fetchSMSGlobalTemplateError,
  } = useQuery(FETCH_SMS_GLOBAL_TEMPLATE, {
    fetchPolicy: "network-only",
    skip: !brandId,
    variables: {
      brandId: brandId || undefined,
    },
    onCompleted: (data) => {
      console.log("Fetched SMS template data:", data);
    },
    onError: (error) => {
      console.error(error);
      appToast("Unable to fetch SMS settings");
    },
  });

  const [updateSMSGlobalTemplate, { loading: updateSMSGlobalTemplateLoading }] = useMutation(
    UPDATE_SMS_GLOBAL_TEMPLATE,
    {
      onError: (error) => {
        console.error(error);
        appToast("Unable to update SMS settings");
      },
      onCompleted: () => {
        // update the brands to onboarded
        if (brandId) {
          updateBrandInCache(brandId, { is_sms_settings_complete: true });
        }
        appToast("SMS settings updated");
        setUpToDate(true);
      },
    },
  );

  const globalLoading = fetchSMSGlobalTemplateLoading;

  const globalError = fetchSMSGlobalTemplateError;

  if (globalLoading) return <SMSSettingsTabSkeleton />;

  if (globalError) return <AppErrorText>Unable to fetch SMS settings</AppErrorText>;

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          optInMessage: fetchSMSGlobalTemplateData?.fetchSMSComplianceTemplate?.sms_opt_in || OPT_IN_MESSAGES[0],
          optOutMessage: fetchSMSGlobalTemplateData?.fetchSMSComplianceTemplate?.sms_opt_out || OPT_OUT_MESSAGES[0],
          order: (() => {
            const textOrder = fetchSMSGlobalTemplateData?.fetchSMSComplianceTemplate?.sms_text_order;

            if (!textOrder) {
              return SMS_SECTION_ORDER.map((item) => item.value);
            }

            return textOrder.map((item: SMSTextOrderItem | string) => {
              if (typeof item === "string") {
                return item;
              }

              if (item && typeof item === "object" && "value" in item) {
                return item.value;
              }

              return String(item);
            });
          })(),
          id: fetchSMSGlobalTemplateData?.fetchSMSComplianceTemplate?.id,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (brandDeleted) return;
          updateSMSGlobalTemplate({
            variables: {
              smsOptIn: values.optInMessage,
              smsOptOut: values.optOutMessage,
              smsTextOrder: values.order,
              brandId: brandId || undefined,
              id: values?.id,
            },
          });
        }}
      >
        {({ values, setFieldValue, submitForm, errors, isValid }) => {
          const reorderOrder = (list: string[], startIndex: number, endIndex: number) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            setFieldValue("order", result);
          };

          const onDragEndOrder = (result: DropResult, provided: ResponderProvided) => {
            // dropped outside the list
            if (!result.destination) {
              return;
            }

            reorderOrder(values.order, result.source.index, result.destination.index);
          };

          return (
            <>
              <FormChangeTracker setUpToDate={setUpToDate} />

              <TabBodyHeader
                handleSave={submitForm}
                upToDate={upToDate}
                setUpToDate={setUpToDate}
                saveDisabled={!isValid || brandDeleted}
              />
              <PageContainer>
                <SubTextDiv>
                  <StepLocationText>SMS Settings</StepLocationText>
                  <AppText fontWeight={400} fontSize={12}>
                    Configure opt-in and opt-out messaging for the first SMS each unique rep sends to phone numbers.
                  </AppText>
                </SubTextDiv>
                <SettingsSection>
                  <AppText fontWeight={600} fontSize={12}>
                    Select an Opt-In Message
                  </AppText>
                  {OPT_IN_MESSAGES.map((message, index) => (
                    <RadioRow key={message}>
                      <PhoenixRadio
                        selected={values.optInMessage === message}
                        onClick={() => {
                          setFieldValue("optInMessage", message);
                        }}
                      />
                      <AppText fontSize={12} fontWeight={500}>
                        {message}
                      </AppText>
                    </RadioRow>
                  ))}
                </SettingsSection>
                <SettingsSection>
                  <AppText fontWeight={600} fontSize={12}>
                    Select an Opt-Out Message
                  </AppText>
                  {OPT_OUT_MESSAGES.map((message, index) => (
                    <RadioRow key={message}>
                      <PhoenixRadio
                        selected={values.optOutMessage === message}
                        onClick={() => {
                          setFieldValue("optOutMessage", message);
                        }}
                      />
                      <AppText fontSize={12} fontWeight={500}>
                        {message}
                      </AppText>
                    </RadioRow>
                  ))}
                </SettingsSection>
                <SettingsSection>
                  <AppText fontWeight={600} fontSize={12}>
                    Configure Order of Messaging
                  </AppText>

                  <DragDropContext onDragEnd={onDragEndOrder}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <FlexDiv direction="column" ref={provided.innerRef} {...provided.droppableProps}>
                          {values?.order?.map((itemValue: string, index: number) => {
                            const sectionItem = SMS_SECTION_ORDER.find(
                              (item: SMSTextOrderItem) => item.value === itemValue,
                            );
                            const displayLabel = sectionItem?.label || getLabelForValue(itemValue);

                            return (
                              <Draggable key={`table_${itemValue}`} draggableId={itemValue} index={index}>
                                {(provided, snapshot) => (
                                  <DraggableDiv
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <PhoenixIcon svg={reorder} variant="brand" size={16} />
                                    <AppText fontSize={12} fontWeight={500}>
                                      {displayLabel}
                                    </AppText>
                                  </DraggableDiv>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </FlexDiv>
                      )}
                    </Droppable>
                  </DragDropContext>
                </SettingsSection>
              </PageContainer>
            </>
          );
        }}
      </Formik>
    </>
  );
};

// Refactored styled components
const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 24px;
`;

const StepLocationText = styled(AppText)`
  color: ${theme.text.neutral.primary};
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
`;

const SubTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SettingsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: auto;
  margin-bottom: 16px;
`;

const RadioRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DraggableDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

const BodyHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 16px;
  background: ${theme.WHITE_COLOR};
  position: sticky;
  top: 0;
  z-index: 10;
  margin-bottom: 16px;
`;

export { SMSSettingsTab };
