import React from "react";
import { components } from "react-select";
import { PhoenixCheckbox, PhoenixIcon, PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import { OptionItem } from "src/types";
import { AppText, FlexDiv } from "src/Components/UI";
import { xIcon } from "src/images/NewDesign";
import { theme } from "src/utils/theme";
import { CALL_TYPE_FILTERS } from "src/utils/variables";
import { CustomMultiValue } from "./shared";

type CallTypeFilterProps = {
  value: any;
  handleChange: (key: string, value: any) => void;
};

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <FlexDiv align="center" gap={8}>
        <PhoenixCheckbox checked={props.isSelected} />
        <img src={props?.data?.icon} alt={props?.data?.label} />
        {props?.data?.label}
      </FlexDiv>
    </components.Option>
  );
};

const MultiValueContainer = (props: any) => {
  return (
    <components.MultiValueContainer {...props}>
      <FlexDiv
        align="center"
        style={{ backgroundColor: props?.data?.backgroundColor, padding: "3px 8px", borderRadius: "4px" }}
        gap={8}
      >
        <img src={props?.data?.icon} alt={props?.data?.label} width={12} height={12} />
        <AppText color={props?.data?.color} fontSize={12}>
          {props?.data?.label}
        </AppText>
        {props?.children?.[1]}
      </FlexDiv>
    </components.MultiValueContainer>
  );
};

const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <PhoenixIcon svg={xIcon} color={props?.data?.color} hoverColor={theme.DANGER600} size={12} fillIcon />
    </components.MultiValueRemove>
  );
};

const MultiValue = (props: any) => {
  return <CustomMultiValue {...props} containerClassName="call-type-vc" />;
};

const ValueContainer = (props: any) => {
  return <components.ValueContainer className="call-type-vc" {...props} />;
};

const CallTypeFilter = ({ value, handleChange }: CallTypeFilterProps) => {
  return (
    <PhoenixMultiSelect
      closeMenuOnSelect={false}
      components={{ MultiValue, MultiValueContainer, MultiValueRemove, Option, ValueContainer }}
      hideErrorMessage
      hideSelectedOptions={false}
      isClearable={false}
      itemBackgroundColor="transparent"
      marginBottom={false}
      maxHeight={40}
      name="Call Types"
      onChange={(e: OptionItem[]) => handleChange("statuses", e)}
      options={CALL_TYPE_FILTERS}
      optionStyles={{ height: "auto", backgroundColor: "transparent", color: "black" }}
      placeholder="Filter by Call Type"
      titleText="Call Type"
      value={value}
      width={320}
    />
  );
};

export default CallTypeFilter;
