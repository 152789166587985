import { ApolloClient, NormalizedCacheObject, gql, createHttpLink, from } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { BACKEND_URL, environment, TOKEN_PERSIST_KEY, version } from "../utils/variables";
import { cache } from "./cache";
import Axios from "axios";
import { persistCacheSync } from "apollo3-cache-persist";
import { onError } from "@apollo/client/link/error";
import { checkForExpiredSession, checkForLoggedOutFromAnotherTab } from "../utils/auth";

// Used this strategy for rebuilding the client upon login
// -------
// https://github.com/apollographql/subscriptions-transport-ws/issues/171#issuecomment-895691114

// @ts-ignore
const fetcher = (...args) => {
  // @ts-ignore
  return window.fetch(...args);
};

/**
 * Axios singleton with auth token attached via headers
 */
const restAPI = Axios.create({
  baseURL: BACKEND_URL,
  headers: {
    authorization: localStorage.getItem(TOKEN_PERSIST_KEY) || "",
  },
});

// Add a response interceptor
restAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    checkForExpiredSession({
      message: error?.response?.data?.error ?? error?.response?.data,
      locations: ["response"],
      path: error?.response?.config?.url,
    });
    return Promise.reject(error);
  },
);

const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
  }
`;

const httpLink = createHttpLink({ uri: BACKEND_URL, fetch: fetcher });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(TOKEN_PERSIST_KEY);
  return {
    headers: {
      ...headers,
      authorization: token || "",
    },
  };
});

const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error, operation) => {
      const definition = getMainDefinition(operation.query);
      const isQueryOperation = definition.kind === "OperationDefinition" && definition.operation === "query";
      const isFetchError = error?.message?.toLowerCase() === "failed to fetch";
      const isFetchLeadQuery = definition?.name?.value === "fetchLead";
      return isQueryOperation && isFetchError && !isFetchLeadQuery;
    },
  },
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      checkForExpiredSession({ message, locations, path });
      checkForLoggedOutFromAnotherTab({ message, locations, path });
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const APOLLO_LINK = from([retryLink, errorLink, authLink, httpLink]);

persistCacheSync({ cache, storage: localStorage });

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: APOLLO_LINK,
  cache,
  typeDefs,
  resolvers: {},
  version: version,
  name: `Web:${environment}`,
});

export { client, typeDefs, restAPI };
