import styled from "styled-components";
import React, { useState, useMemo, useEffect } from "react";
import { AppText, FlexDiv, TemplateEditor, Tokens } from "../../../UI";
import { theme } from "../../../../utils/theme";
import { gql, useMutation, useQuery } from "@apollo/client";
import { AllTokens, OrgBrands } from "src/types";
import { PhoenixAppButton, PhoenixInput, PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import { appToast, errorToast } from "src/utils/toast";
import { handleLineHeights } from "src/Components/modal";
import { PhoenixInputField, PhoenixMultiSelectField } from "src/Components/Field/Phoenix";
import { TabBodyHeader } from "../StepBrandManagement";
import { EmailSettingsTabSkeleton } from "./EmailSettingsTabSkeleton";
import { loggedInUser } from "src/apollo/cache";
import { updateLocalOrg } from "src/Components/Smart/DataWrapper";
import { FormChangeTracker } from "./FormChangeTracker";
import { Formik } from "formik";
import * as Yup from "yup";
import { updateBrandInCache } from "src/utils/misc";
import useMultiBrands from "src/cache-hooks/useIsMultiBrand";
import { useFlags } from "launchdarkly-react-client-sdk";

interface DisappearingDivProps {
  brandId?: string;
  upToDate: boolean;
  setUpToDate: (upToDate: boolean) => void;
  brandDeleted: boolean;
}

const FETCH_ALL_TOKENS = gql`
  query fetchTokensForTemplate {
    fetchTokensForTemplate
  }
`;
const FETCH_CUSTOM_FIELDS = gql`
  query fetchCustomFields {
    fetchCustomFields {
      id
      key
    }
  }
`;
const CREATE_UPDATE_EMAIL_TEMPLATE = gql`
  mutation createOrUpdateTemplate($templateInput: TemplateInput!) {
    createOrUpdateTemplate(TemplateInput: $templateInput) {
      id
      content
    }
  }
`;
const FETCH_SIGNATURE_TEMPLATE = gql`
  query getEmailSignatureTemplate($brandId: String) {
    getEmailSignatureTemplate(brand_id: $brandId) {
      id
      content
    }
  }
`;

const FETCH_ORG_DOMAIN = gql`
  query FetchDomain($brandId: String) {
    fetchDomain(brand_id: $brandId)
  }
`;

const EmailSettingsTab: React.FC<DisappearingDivProps> = ({ brandId, upToDate, setUpToDate, brandDeleted }) => {
  const { showMultiBrandUI } = useMultiBrands(useFlags);
  const [currentDomain, setCurrentDomain] = useState("");

  const { data: dataDomain, loading: loadingDomain, error: errorDomain } = useQuery(FETCH_ORG_DOMAIN, {
    fetchPolicy: "network-only",
    skip: !showMultiBrandUI && !brandId,
    variables: {
      brandId: brandId || undefined,
    },
    onCompleted({ fetchDomain }) {
      if (!!fetchDomain?.domain) {
        !!fetchDomain?.valid && setCurrentDomain(fetchDomain.domain);
      }
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      if (message === "Invalid domain") {
        console.log("Invalid domain");
      } else {
        errorToast("Error fetching domain");
      }
    },
  });

  const { data: dataTokens, loading: loadingTokens } = useQuery(FETCH_ALL_TOKENS, {
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataCustomFields, loading: loadingCustomFields } = useQuery(FETCH_CUSTOM_FIELDS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataSignatureTemplate, loading: loadingSignatureTemplate } = useQuery(FETCH_SIGNATURE_TEMPLATE, {
    fetchPolicy: "network-only",
    variables: {
      brandId: brandId,
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const tokens = useMemo(() => {
    const customFieldTokens = dataCustomFields?.fetchCustomFields?.map((field: { id: string; key: string }) => ({
      token: `contact_customField.${field.key}`,
      token_formatted: `[contact_customField.${field.key}]`,
      fallback: "",
    }));
    return { ...dataTokens?.fetchTokensForTemplate, "Custom Field": customFieldTokens };
  }, [dataTokens, dataCustomFields]);

  console.log("dataTokens: ", dataTokens, tokens);

  const repTokens = useMemo(() => {
    return {
      Rep: dataTokens?.fetchTokensForTemplate?.["Rep"],
    };
  }, [dataTokens]);

  const [createOrEditEmailTemplate, { loading: createOrEditEmailTemplateLoading }] = useMutation(
    CREATE_UPDATE_EMAIL_TEMPLATE,
    {
      async onCompleted({ createOrUpdateTemplate }) {
        console.log("createOrUpdateTemplate: ", createOrUpdateTemplate);
        if (!createOrUpdateTemplate) {
          errorToast("Email Signature error. Something went wrong.");
          return;
        }

        if (brandId) {
          updateBrandInCache(brandId, { is_email_settings_complete: true });
        }

        appToast("Updated email template!");
        setUpToDate(true);
      },
      onError({ message }) {
        console.log("Error in createOrUpdateTemplate: ", message);
        errorToast("Failed to update email template");
      },
    },
  );
  const [formatedContent, setFormatedContent] = useState(false);

  // Show skeleton loader during any loading state
  if (loadingTokens || loadingCustomFields || loadingSignatureTemplate) {
    return <EmailSettingsTabSkeleton />;
  }

  const initialContent = dataSignatureTemplate?.getEmailSignatureTemplate?.content || "";
  const templateId = dataSignatureTemplate?.getEmailSignatureTemplate?.id;

  return (
    <Body>
      <Formik
        enableReinitialize
        initialValues={{
          content: initialContent,
        }}
        validationSchema={Yup.object().shape({
          content: Yup.string().required("Content is required"),
        })}
        onSubmit={(values) => {
          const submitObj = {
            type: "EMAIL",
            content: values.content,
            template_name: "Email Signature",
            visibility: {
              user_ids: [],
              team_ids: [],
              roles: [],
            },
            template_id: templateId,
            is_signature: true,
            brand_id: brandId,
          };

          submitObj.content = handleLineHeights(submitObj.content).innerHTML;

          createOrEditEmailTemplate({
            variables: {
              templateInput: submitObj,
            },
          });
        }}
      >
        {({ values, setFieldValue, submitForm }) => {
          return (
            <>
              <TabBodyHeader
                handleSave={submitForm}
                upToDate={upToDate}
                setUpToDate={setUpToDate}
                saveDisabled={createOrEditEmailTemplateLoading || loadingSignatureTemplate || brandDeleted}
              />
              {showMultiBrandUI && (
                <FlexDiv direction="column" gap={40} style={{ minHeight: "150px" }}>
                  <FlexDiv direction="column" gap={16}>
                    <AppText fontSize={22} fontWeight={500} lineHeight={28} noWrap>
                      Rep Email Address Format
                    </AppText>
                  </FlexDiv>

                  <FlexDiv gap={40} align="center">
                    <InputDomainContainer width={525} justify="flex-start">
                      <PhoenixInputField
                        // width={"100%"}
                        width={350}
                        label="Email Address"
                        name="user_email_format"
                        placeholder={
                          currentDomain
                            ? "enter the format of the email address reps use"
                            : "[REP_EMAIL].[BRAND_NAME]@sellfire.com"
                        }
                        disabled={!currentDomain}
                        displayNoContextText
                        style={{ width: "100%" }}
                      />
                      {currentDomain && (
                        <DomainText fontSize={12} fontWeight={600} lineHeight={18}>
                          @{currentDomain}
                        </DomainText>
                      )}
                    </InputDomainContainer>
                    {currentDomain && (
                      <TokenContainer tokens={repTokens} loading={loadingTokens || loadingCustomFields} />
                    )}
                  </FlexDiv>
                </FlexDiv>
              )}
              <FlexDiv direction="column" gap={40}>
                <FlexDiv direction="column" gap={16}>
                  <AppText fontSize={22} fontWeight={500} lineHeight={28} noWrap>
                    Email Settings
                  </AppText>
                  <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                    Configure the text and styling of your organization's email signatures.
                  </AppText>
                </FlexDiv>
                <FlexDiv gap={40}>
                  <FlexDiv direction="column" gap={16}>
                    <TemplateEditor
                      width={520}
                      initialContent={initialContent}
                      deliver
                      noMargin
                      emojiPickerRight={20}
                      emojiPickerTop={-160}
                      displayNoContextText
                      onContentChange={(content) => {
                        setFieldValue("content", content);
                        if (content !== initialContent && !formatedContent) {
                          setFormatedContent(true);
                          return;
                        }
                        if (formatedContent && JSON.stringify(content) !== JSON.stringify(initialContent)) {
                          setUpToDate(false);
                        }
                      }}
                    />
                  </FlexDiv>
                  <TokenContainer tokens={tokens} loading={loadingTokens || loadingCustomFields} />
                </FlexDiv>
              </FlexDiv>
            </>
          );
        }}
      </Formik>
    </Body>
  );
};

interface ITokenContainer {
  tokens: AllTokens;
  loading: boolean;
}

const TokenContainer: React.FC<ITokenContainer> = ({ tokens, loading }) => {
  return (
    <TokenContainerWrap>
      <TokenWrap>
        <Tokens loading={loading} tokens={tokens} compressed ignoreOverflow />
      </TokenWrap>
    </TokenContainerWrap>
  );
};

const TokenContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 16px;
  border: 1px solid ${theme.border.neutral.secondary};
  border-radius: 8px;
`;

const TokenWrap = styled.div`
  max-height: 270px;
  overflow-y: auto;
`;

const Main = styled.div`
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  border-radius: 8px;

  height: 100%;
  overflow-y: auto;
`;

const TopAlignDiv = styled.div`
  display: flex;
  justify-items: center;

  height: 108px;
  padding: 24px 40px;

  border-bottom: 1px solid ${theme.border.neutral.primary};
`;

const Body = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const DomainText = styled(AppText)``;

const InputDomainContainer = styled(FlexDiv)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 4px;
  min-width: 350px;
`;

export { EmailSettingsTab };
