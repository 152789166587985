import { gql, useMutation } from "@apollo/client";
import { appToast } from "src/utils/toast";

interface CreateInstantMeetingResponse {
  createInstantMeeting: {
    google_space_sellfire_id: string;
    zoom_meeting_sellfire_id: string;
    meeting_url: string;
    meeting_code?: string;
    meeting_id?: string;
  };
}

const CREATE_OTF_MEETING = gql`
  mutation CreateInstantMeeting($scheduleItemId: String, $meetingType: MeetingType!, $lead_id: String!) {
    createInstantMeeting(schedule_item_id: $scheduleItemId, meeting_type: $meetingType, lead_id: $lead_id)
  }
`;

type CreateOFTMeetingProps = {
  onCompleted?: (data: CreateInstantMeetingResponse) => void;
};

const useCreateOTFMeeting = ({ onCompleted }: CreateOFTMeetingProps) => {
  const [createInstantMeeting, { data, loading, error }] = useMutation<CreateInstantMeetingResponse>(
    CREATE_OTF_MEETING,
    {
      onCompleted,
      onError: (error) => {
        console.log("error", error);
        appToast("Unable to create video meeting for this lead.");
      },
      refetchQueries: ["fetchCachedInstantMeeting"],
    },
  );

  return {
    createInstantMeeting,
    data,
    loading,
    error,
  };
};

export default useCreateOTFMeeting;
