import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Switch from "react-switch";
import { theme } from "src/utils/theme";
import { AppText } from "src/Components/UI/AppText";
import { FlexDiv } from "src/Components/UI/FlexDiv";
import { info } from "src/images/NewDesign";
import { PhoenixIcon, PhoenixMultiSelect, PhoenixRadio } from "src/Components/UI/Phoenix";
import { CustomField, SaleConfigSection, FieldItem as FieldItemType, getSystemFieldOptions } from "src/utils/misc";
import { OptionItem } from "src/types";
import { loggedInUser } from "src/apollo/cache";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMultiBrands } from "src/cache-hooks/useIsMultiBrand";

type FieldItem = FieldItemType<SaleConfigSection>;

interface IField {
  data: SaleConfigSection;
  allFields: FieldItem[] | undefined;
  customFieldData: CustomField[];
  customFieldOptions: OptionItem[];
  onFieldChange: (id: string, newOption: { label: string; value: string }, radio: "system" | "custom") => void;
  onFieldRadioChange: (id: string, newType: "system" | "custom") => void;
  onFieldRequiredChange: (id: string, newRequired: boolean) => void;
}

export const Field: React.FC<IField> = ({
  data,
  allFields,
  customFieldData,
  customFieldOptions,
  onFieldChange,
  onFieldRequiredChange,
  onFieldRadioChange,
}) => {
  const { showMultiBrandUI } = useMultiBrands(useFlags);
  const [radio, setRadio] = useState<"system" | "custom">(data.type === "SYSTEM_FIELD" ? "system" : "custom");
  const [required, setRequired] = useState(data.required || data.required_field || false);

  const customFieldItem = useMemo(() => customFieldData?.find((cf: any) => cf.id === data.custom_field_id), [
    data,
    customFieldData,
  ]);

  const options = (radio === "system"
    ? getSystemFieldOptions(showMultiBrandUI).filter(
        (oi: OptionItem) =>
          !allFields?.find((f: SaleConfigSection) => f.system_field === oi.value) && oi.value !== "CHANNEL",
      )
    : customFieldOptions
  )?.sort((a, b) => a.label.localeCompare(b.label));

  const [value, setValue] = useState<OptionItem | null>(
    data.type === "SYSTEM_FIELD"
      ? getSystemFieldOptions(showMultiBrandUI).find((sfo: OptionItem) => sfo.value === data.system_field) || null
      : !!customFieldItem
      ? { label: customFieldItem?.key, value: customFieldItem?.id }
      : null,
  );

  useEffect(() => {
    onFieldRequiredChange(data.id, required);
  }, [required]);

  return (
    <FieldContent>
      <FieldConfigContainer>
        <FlexDiv align="center" gap={24}>
          <FlexDiv align="center" gap={8}>
            <PhoenixRadio
              selected={radio === "system"}
              onClick={() => {
                setRadio("system");
                setValue(null);
                onFieldRadioChange(data.id, "system");
              }}
            />
            <FieldText>System Field</FieldText>
          </FlexDiv>
          <FlexDiv align="center" gap={8}>
            <PhoenixRadio
              selected={radio === "custom"}
              onClick={() => {
                setRadio("custom");
                setValue(null);
                onFieldRadioChange(data.id, "custom");
              }}
            />
            <FieldText>Custom Field</FieldText>
          </FlexDiv>
        </FlexDiv>

        {value?.value !== "LEAD_ID_LABEL" && (
          <FlexDiv align="center" gap={8}>
            <Switch
              checked={required}
              onChange={() => setRequired(!required)}
              onColor={theme.PRIMARY500}
              height={16}
              width={32}
              checkedIcon={false}
              uncheckedIcon={false}
              handleDiameter={12}
            />
            <FieldText>Required</FieldText>
          </FlexDiv>
        )}
      </FieldConfigContainer>

      <FlexDiv direction="column" gap={8}>
        <FieldText>Select Field</FieldText>
        <FlexDiv align="center" gap={8}>
          <PhoenixMultiSelect
            name={`field-${data.id}`}
            isMulti={false}
            isClearable={false}
            isDisabled={false}
            options={options}
            value={value}
            onChange={(oi: { label: string; value: string }) => {
              setValue(oi);
              onFieldChange(data.id, oi, radio);
            }}
            marginBottom={false}
            width={352}
            menuPortal
            maxMenuHeight={250}
            menuPosition="fixed"
            menuShouldBlockScroll
          />

          <PhoenixIcon
            svg={info}
            size={16}
            variant="brand"
            data-tip="Only visible fields will be displayed."
            data-for="field-tooltip"
          />
        </FlexDiv>
      </FlexDiv>
    </FieldContent>
  );
};

const FieldConfigContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FieldContent = styled(FlexDiv)`
  flex-direction: column;
  gap: 40px;
`;

const FieldText = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;
