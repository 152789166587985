import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTabFocus } from "src/utils/hooks";
import { appToast } from "src/utils/toast";
import styled from "styled-components";
import { ModalContext } from "../../context";
import zoom_dial_in from "../../images/screenshots/zoom_dial_in.png";
import zoom_recording_reminder from "../../images/screenshots/zoom_recording_reminder.png";
import zoom_no_extension from "../../images/screenshots/zoom_no_extension.png";
import { theme } from "../../utils/theme";
import { AppErrorText, AppText, FlexDiv } from "../UI";
import { PhoenixAppButton, PhoenixIcon, PhoenixInput } from "../UI/Phoenix";
import { Modal } from "./Modal";
import { info } from "src/images/NewDesign";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PhoenixInputField } from "../Field/Phoenix";
import { Formik } from "formik";
import { PHONE_REGEX, COUNTRY_CODE_REGEX } from "src/utils/regex";
import { onlyDigits } from "src/utils/format";
const formatZoomLink = (link: string) => {
  // start meeting

  // link  example: https://zoom.us/j/95297815926?pwd=CHX7DVCUiJGH7fNHROOcElXFRx8QYT.1

  // format desired:
  //https://app.zoom.us/wc/97818469200/start?fromPWA=1&pwd=blhYXannGtslIGow8Nte3AkH7DWV2L

  const base = "https://app.zoom.us/wc/";
  const id = link.split("/j/")[1].split("?")[0];
  const pwd = link.split("?pwd=")[1];

  return `${base}${id}/start?fromPWA=1&pwd=${pwd}`;
};

const JoinZoomModal: React.FC = () => {
  const { showJoinZoomModal, setShowJoinZoomModal, joinZoomModalData } = useContext(ModalContext);
  const [phoneNumberFromExtension, setPhoneNumberFromExtension] = useState("");
  const [participantIdFromExtension, setParticipantIdFromExtension] = useState("");

  const [extensionExplainerStep, setExtensionExplainerStep] = useState<boolean>(false);
  const [recordingExplainerStep, setRecordingExplainerStep] = useState<boolean>(false);
  const [hasLaunchedZoomWindow, setHasLaunchedZoomWindow] = useState<boolean>(false);

  const parseClipboardData = (data: string) => {
    const phoneRegex = /ZOOM-PHONE:(.*?)PIN:/;
    const pinRegex = /PIN:(.*)/;
    const phoneMatch = data.match(phoneRegex);
    const pinMatch = data.match(pinRegex);
    const phone = phoneMatch?.[1]?.trim()?.replace(/\s/g, "")?.replace(/-/g, "");
    const participantId = pinMatch?.[1]?.trim()?.replace("#", "")?.replace(/\s/g, "");
    return { phone, participantId };
  };

  const clearClipboardData = async () => {
    try {
      await navigator.clipboard.writeText("");
    } catch (err) {
      console.error("Failed to clear clipboard contents: ", err);
    }
  };

  const readClipboardData = useCallback(async () => {
    if (phoneNumberFromExtension?.length) return;
    try {
      const clipboardData = await navigator.clipboard.readText();
      const clipboardDataIsFromExtension = clipboardData?.includes("ZOOM-PHONE:") && clipboardData?.includes("PIN:");

      if (clipboardDataIsFromExtension) {
        const { phone, participantId } = parseClipboardData(clipboardData);

        phone && setPhoneNumberFromExtension(phone);
        participantId && setParticipantIdFromExtension(participantId);
        appToast("Autofilled phone number and participant id");
        await clearClipboardData();
      }
    } catch (err) {
      console.error("Failed to read clipboard contents: ", err);
    }
  }, [phoneNumberFromExtension, participantIdFromExtension]);

  const clearAnyExistingClipboardMeetingData = async () => {
    const clipboardData = await navigator.clipboard.readText();
    const clipboardDataIsFromExtension = clipboardData?.includes("MEET-PHONE:") && clipboardData?.includes("PIN:");

    if (clipboardDataIsFromExtension) {
      await clearClipboardData();
    }
  };

  useEffect(() => {
    if (document.hasFocus()) {
      clearAnyExistingClipboardMeetingData();
    }
  }, []);

  useTabFocus(
    async () => {
      if (document.hasFocus()) {
        await readClipboardData();
      }
    },
    {
      waitTime: 1000,
    },
  );

  useEffect(() => {
    const interval = setInterval(async () => {
      if (document.hasFocus()) {
        await readClipboardData();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Modal open={showJoinZoomModal} onClose={() => setShowJoinZoomModal(false)}>
      <EditCardDiv>
        {extensionExplainerStep ? (
          <NoExtensionStep setExtensionExplainerStep={setExtensionExplainerStep} />
        ) : recordingExplainerStep ? (
          <HowToRecordMeetingStep setRecordingExplainerStep={setRecordingExplainerStep} />
        ) : (
          <MainStep
            phoneNumberFromExtension={phoneNumberFromExtension}
            participantIdFromExtension={participantIdFromExtension}
            setRecordingExplainerStep={setRecordingExplainerStep}
            setExtensionExplainerStep={setExtensionExplainerStep}
            setShowJoinZoomModal={setShowJoinZoomModal}
            joinZoomModalData={joinZoomModalData}
            hasLaunchedZoomWindow={hasLaunchedZoomWindow}
            setHasLaunchedZoomWindow={setHasLaunchedZoomWindow}
          />
        )}
      </EditCardDiv>
    </Modal>
  );
};

const MainStep = ({
  phoneNumberFromExtension,
  participantIdFromExtension,
  setExtensionExplainerStep,
  setRecordingExplainerStep,
  setShowJoinZoomModal,
  joinZoomModalData,
  hasLaunchedZoomWindow,
  setHasLaunchedZoomWindow,
}: {
  phoneNumberFromExtension: string;
  participantIdFromExtension: string;
  setExtensionExplainerStep: (extensionExplainerStep: boolean) => void;
  setRecordingExplainerStep: (recordingExplainerStep: boolean) => void;
  setShowJoinZoomModal: (showJoinZoomModal: boolean) => void;
  joinZoomModalData: {
    dialInToMeeting: ({
      newPhoneNumber,
      newParticipantId,
    }: {
      newPhoneNumber: string;
      newParticipantId: string;
    }) => void;
    meetingLink: string;
  };
  hasLaunchedZoomWindow: boolean;
  setHasLaunchedZoomWindow: (hasLaunchedZoomWindow: boolean) => void;
}) => {
  const SELLFIRE_EXTENSION_LINK =
    "https://chromewebstore.google.com/detail/ljfjngncicimgjjcjigboilfocmlcajg?utm_source=item-share-cb";

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          phoneNumber: phoneNumberFromExtension,
          participantId: participantIdFromExtension,
        }}
        validationSchema={Yup.object({
          phoneNumber: Yup.string()
            .required("Field is required")
            .max(15, "Must be valid phone number")
            .min(7, "Must be valid phone number")
            .matches(PHONE_REGEX, "Must be a valid phone number")
            .matches(COUNTRY_CODE_REGEX, "Must include the country code."),

          participantId: Yup.string().required("Participant ID is required"),
        })}
        onSubmit={(values) => {
          joinZoomModalData?.dialInToMeeting({
            newPhoneNumber: `+${onlyDigits(values.phoneNumber)}`,
            newParticipantId: values.participantId,
          });
          setShowJoinZoomModal(false);
        }}
      >
        {({ handleSubmit, handleChange, values, errors, touched, isValid }) => (
          <>
            <TitleDiv>
              <TitleText>Join a Zoom Meeting</TitleText>
            </TitleDiv>
            <Main>
              <>
                <img src={zoom_dial_in} alt="Zoom Dial In" height="100%" width="100%" />
                <FlexDiv direction="column" gap={8}>
                  <AppText fontSize={14} fontWeight={500}>
                    Fill in the generated phone number and participant id.
                  </AppText>
                  <InstructionsList>
                    <ListItem>
                      Make sure you've installed the{" "}
                      <Link onClick={() => window.open(SELLFIRE_EXTENSION_LINK, "_blank")}>
                        Sellfire Extension for Chrome.
                      </Link>
                    </ListItem>
                    <ListItem>
                      Click the <Bold>Launch Window</Bold> button.
                    </ListItem>
                    <ListItem>
                      Select <Bold>"Join Audio."</Bold>
                    </ListItem>
                    <ListItem>Come back to this tab to auto-fill the number and participant id.</ListItem>
                  </InstructionsList>
                  <AppText fontSize={12} fontWeight={400} color={theme.text.neutral.tertiary} lineHeight={18}>
                    If you've already joined audio, click the up arrow next to the audio mic icon, then choose "Leave
                    Computer Audio" to reveal the Phone Call tab.
                  </AppText>
                </FlexDiv>
                <PhoenixInputField name="phoneNumber" titleText="Phone Number" requiredStar titleTextSpacing={8} />
                <PhoenixInputField name="participantId" requiredStar titleText="Participant ID" titleTextSpacing={8} />
                <FlexDiv direction="column" gap={0}>
                  <PhoenixAppButton
                    variant="brand-outline"
                    buttonType="ghost-small"
                    onClick={() => setExtensionExplainerStep(true)}
                  >
                    Haven't installed the Sellfire Extension yet?
                  </PhoenixAppButton>
                  <PhoenixAppButton
                    variant="brand-outline"
                    buttonType="ghost-small"
                    onClick={() => setRecordingExplainerStep(true)}
                  >
                    How to record a Zoom meeting?
                  </PhoenixAppButton>
                </FlexDiv>
              </>
            </Main>
            <SubmitDiv>
              <FlexDiv gap={8} justify="space-between" width="100%">
                <PhoenixAppButton
                  buttonType="secondary"
                  variant="brand-outline"
                  onClick={() => {
                    setHasLaunchedZoomWindow(true);
                    window.open(formatZoomLink(joinZoomModalData?.meetingLink), "_blank");
                  }}
                >
                  Launch Window
                </PhoenixAppButton>

                <PhoenixAppButton
                  disabled={!isValid}
                  buttonType="secondary"
                  variant="brand"
                  onClick={() => handleSubmit()}
                >
                  Join Meeting
                </PhoenixAppButton>
              </FlexDiv>
              <FlexDiv gap={8} align="center">
                <PhoenixIcon svg={info} variant="danger" size={16} />
                <AppText fontSize={12} fontWeight={400} color={theme.text.danger.primary}>
                  Record this meeting for future reference.
                </AppText>
              </FlexDiv>
            </SubmitDiv>
          </>
        )}
      </Formik>
    </>
  );
};

const NoExtensionStep = ({
  setExtensionExplainerStep,
}: {
  setExtensionExplainerStep: (extensionExplainerStep: boolean) => void;
}) => {
  const SELLFIRE_EXTENSION_LINK = "https://chromewebstore.google.com/detail/sellfire/ljfjngncicimgjjcjigboilfocmlcajg";

  return (
    <>
      <TitleDiv>
        <TitleText>Haven't installed the Sellfire Extension yet?</TitleText>
      </TitleDiv>
      <Main>
        <img
          src={zoom_no_extension}
          alt="Zoom No Extension"
          height="100%"
          width="100%"
          style={{ objectFit: "contain" }}
        />
        <FlexDiv direction="column" gap={8}>
          <AppText fontSize={12} fontWeight={400}>
            No worries! You can install it{" "}
            <Link onClick={() => window.open(SELLFIRE_EXTENSION_LINK, "_blank")}>here</Link> or enter the info manually.
          </AppText>
          <AppText fontSize={12} fontWeight={400}>
            To find your dial in number and participant id.
          </AppText>
          <InstructionsList>
            <ListItem>
              Click the <Bold>Launch Window</Bold> button on the prevous modal step.
            </ListItem>
            <ListItem>
              Select <Bold>"Join Audio."</Bold>
            </ListItem>
            <ListItem>
              Note the <Bold>Phone Number</Bold> and <Bold>Participant ID</Bold> in the Phone Call tab.
            </ListItem>
          </InstructionsList>
          <AppText fontSize={12} fontWeight={400} color={theme.text.neutral.tertiary}>
            Each meeting generates unique numbers. Do not copy the above.
          </AppText>
          <AppText fontSize={12} fontWeight={400} color={theme.text.neutral.tertiary}>
            If you've already joined audio, click the up arrow next to the audio mic icon, then choose "Leave Computer
            Audio" to reveal the Phone Call tab.
          </AppText>
        </FlexDiv>
      </Main>
      <SubmitDiv>
        <PhoenixAppButton buttonType="secondary" variant="brand" onClick={() => setExtensionExplainerStep(false)}>
          Got it
        </PhoenixAppButton>
      </SubmitDiv>
    </>
  );
};

const HowToRecordMeetingStep = ({
  setRecordingExplainerStep,
}: {
  setRecordingExplainerStep: (recordingExplainerStep: boolean) => void;
}) => {
  return (
    <>
      <TitleDiv>
        <TitleText>How to record a Zoom meeting?</TitleText>
      </TitleDiv>
      <Main>
        <>
          <img src={zoom_recording_reminder} alt="Zoom Recording Reminder" height="100%" width="100%" />
          <InstructionsList>
            <ListItem>
              At the bottom right, click <Bold>“Record”.</Bold>
            </ListItem>
            <ListItem>
              Choose <Bold>“Record to the cloud”</Bold>.
            </ListItem>
            <ListItem>You will hear a notification when the recording starts.</ListItem>
          </InstructionsList>
          <AppText fontSize={12} fontWeight={400} color={theme.text.neutral.tertiary} lineHeight={18}>
            Recording is only available with a Zoom pro or business account. If you have a free Zoom account, you won't
            be able to access the recording in Sellfire.
          </AppText>
        </>
      </Main>
      <SubmitDiv>
        <PhoenixAppButton buttonType="secondary" variant="brand" onClick={() => setRecordingExplainerStep(false)}>
          Got it
        </PhoenixAppButton>
      </SubmitDiv>
    </>
  );
};

const InstructionsList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 20px;
`;

const ListItem = styled.li`
  font-weight: 400;
  font-size: 12px;
  padding-left: 2px;
`;

const Link = styled.span`
  color: ${theme.text.brand.primary};
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  width: 100%;
  padding: 0px 40px 0px 40px;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 16px;
`;

const TitleDiv = styled.div`
  position: relative;
  min-height: 56px;
  max-height: 56px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const EditCardDiv = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 460px;
  max-width: 70vw;
  gap: 24px;
  max-height: 90vh;

  overflow: auto;
`;

const SubmitDiv = styled.div`
  display: flex;
  border-top: 1px solid ${theme.border.neutral.secondary};
  align-items: center;
  padding: 16px 40px;
  flex-direction: column;
  gap: 12px;
  height: max-content;
`;

export { JoinZoomModal };
