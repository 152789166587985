import { gql, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { loggedInUser } from "src/apollo/cache";
import { theme } from "src/utils/theme";
import styled from "styled-components";
import { AppText, FlexDiv } from "../../UI";

import { LiveUserStatusContext } from "src/context";
import { chevron_down, chevron_up, phone_call, refresh } from "src/images/NewDesign";
import { OptionItem } from "src/types";
import { PhoenixIcon } from "../../UI/Phoenix";
import { CallerCard } from "../CallMonitorV2/CallerCard";

import Switch from "react-switch";
import CallTypeFilterV2 from "./CallTypeFilterV2";
import RepFilterV2 from "./RepFilterV2";
import TeamFilterV2 from "./TeamFilterV2";
import { CallerCardV2 } from "./CallerCardV2";

interface fetchTeamsRepsFromOrgExpectedResponse {
  fetchOrganization: {
    Reps: Rep[];
    Teams: Team[];
  };
}

interface Rep {
  id: string;
  first_name: string;
  last_name: string;
  profile_image: string;
}

interface Team {
  id: string;
  name: string;
}

const FETCH_TEAMS_REPS_FROM_ORG = gql`
  query fetchOrganization {
    fetchOrganization {
      Reps {
        id
        first_name
        last_name
        profile_image
      }
      Teams {
        id
        name
      }
    }
  }
`;

const TRANSITION_TIME = 0.25;

export const extractConferenceId = (pathname: string): string | undefined => {
  const match = pathname.match(/^\/call-report\/([^\/]+)$/);
  if (!match) return undefined;

  const conferenceId = match[1];
  // Return undefined for null/empty values
  return conferenceId && conferenceId !== "null" ? conferenceId : undefined;
};

const CallMonitorV3Component: React.FC = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const currentConferenceId = extractConferenceId(pathname);

  const [showCallMonitor, setShowCallMonitor] = useState(false);

  const { callMonitorFilter, setCallMonitorFilter, liveUserStatus } = useContext(LiveUserStatusContext);

  const { data: dataOrg } = useQuery<fetchTeamsRepsFromOrgExpectedResponse>(FETCH_TEAMS_REPS_FROM_ORG, {
    variables: {
      org_id: loggedInUser().organization_id,
    },
    fetchPolicy: "network-only",
  });

  const repList = useMemo(
    () =>
      dataOrg?.fetchOrganization?.Reps?.map((rep: Rep) => {
        return {
          label: `${rep.first_name} ${rep.last_name}`,
          value: rep.id,
          profile_image: rep.profile_image,
        } as OptionItem;
      }).sort((a: OptionItem, b: OptionItem) => a?.label.localeCompare(b?.label)) ?? [],
    [dataOrg],
  );

  const teamList = useMemo(
    () =>
      dataOrg?.fetchOrganization?.Teams?.map((team: Team) => {
        return { label: team?.name, value: team?.id } as OptionItem;
      }).sort((a: OptionItem, b: OptionItem) => a?.label.localeCompare(b?.label)) ?? [],
    [dataOrg],
  );

  const handleChange = (type: string, values: OptionItem[]) => {
    setCallMonitorFilter({ ...callMonitorFilter, [type]: values });
  };

  const toggleCallMonitor = () => {
    setShowCallMonitor(!showCallMonitor);
  };

  const resetFilters = () => {
    setCallMonitorFilter({
      statuses: [],
      team_ids: [],
      user_ids: [],
    });
  };

  const canResetFilters =
    callMonitorFilter.statuses.length > 0 ||
    callMonitorFilter.team_ids.length > 0 ||
    callMonitorFilter.user_ids.length > 0;

  const [onlyActiveCalls, setOnlyActiveCalls] = useState(false);

  const filteredUsers = useMemo(() => {
    if (onlyActiveCalls) {
      return liveUserStatus.filter((item) => item.status === "CALL");
    }
    return liveUserStatus;
  }, [liveUserStatus, onlyActiveCalls]);

  return (
    <div style={{ position: "relative" }}>
      <ActiveCallToggle onClick={toggleCallMonitor} isModalOpen={showCallMonitor}>
        <AppText
          fontSize={10}
          fontWeight={600}
          variant="white"
          style={{ letterSpacing: "1px", textTransform: "uppercase", marginTop: "1px" }}
        >
          Rep Call Status
        </AppText>
        {showCallMonitor ? (
          <PhoenixIcon svg={chevron_down} variant="white" pointer />
        ) : (
          <PhoenixIcon svg={chevron_up} variant="white" pointer />
        )}
      </ActiveCallToggle>
      <FullBottomSection isModalOpen={showCallMonitor}>
        <CallMonitorWrapper isModalOpen={showCallMonitor}>
          <CallMonitorDiv isModalOpen={showCallMonitor}>
            <DropdownFiltersDiv>
              <FlexDiv align="center" gap={8}>
                <CallTypeFilterV2
                  value={callMonitorFilter.statuses}
                  handleChange={(key: string, value: OptionItem[]) => handleChange(key, value)}
                />

                <TeamFilterV2
                  onChange={(e: OptionItem[]) => handleChange("team_ids", e)}
                  options={teamList}
                  value={callMonitorFilter.team_ids}
                />

                <RepFilterV2
                  onChange={(e: OptionItem[]) => handleChange("user_ids", e)}
                  options={repList}
                  value={callMonitorFilter.user_ids}
                />

                <FlexDiv
                  align="center"
                  gap={4}
                  onClick={resetFilters}
                  style={{
                    marginLeft: "8px",
                    cursor: canResetFilters ? "pointer" : "not-allowed",
                    opacity: canResetFilters ? 1 : 0.5,
                  }}
                >
                  <PhoenixIcon pointer={true} size={12} svg={refresh} />
                  <AppText color={theme.text.brand.primary} fontSize={10} fontWeight={600} lineHeight={14}>
                    Reset Filters
                  </AppText>
                </FlexDiv>
              </FlexDiv>
              <FlexDiv align="center" gap={8}>
                <Switch
                  onChange={(checked: boolean) => setOnlyActiveCalls(checked)}
                  checked={onlyActiveCalls}
                  onColor={theme.PRIMARY500}
                  offColor={theme.NEUTRAL200}
                  height={16}
                  width={32}
                  handleDiameter={12}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
                <AppText fontSize={12} fontWeight={500}>
                  Only Active Calls
                </AppText>
              </FlexDiv>
            </DropdownFiltersDiv>

            <CallMonitorScrollDiv id="call-monitor-div">
              {filteredUsers?.length ? (
                <HorizontalScrollDiv>
                  {filteredUsers.map((item) => {
                    return (
                      <CallerCardV2
                        key={item.user_id}
                        item={item}
                        isCurrentCall={item.conference_id === currentConferenceId}
                      />
                    );
                  })}
                </HorizontalScrollDiv>
              ) : (
                <NoCallsComponent />
              )}
            </CallMonitorScrollDiv>
          </CallMonitorDiv>
        </CallMonitorWrapper>
      </FullBottomSection>
    </div>
  );
};

const NoCallsComponent = React.memo(
  () => {
    return (
      <FlexDiv direction="row" gap={24} justify="center" align="center" style={{ height: 83 }}>
        <PhoenixIcon svg={phone_call} size={40} variant="neutral" />

        <AppText fontSize={14} color={theme.NEUTRAL400}>
          No calls match your filters.
        </AppText>
      </FlexDiv>
    );
  },
  () => true,
);

const FullBottomSection = styled.div<{ isModalOpen: boolean }>`
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: transform ${TRANSITION_TIME}s ease-in-out;
  transform: ${({ isModalOpen }) => (isModalOpen ? "translateY(0)" : "translateY(0%)")};
  z-index: 1000;
  overflow: hidden;
`;

const HorizontalScrollDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: overlay !important;
  padding: 4px 0;
`;

const CallMonitorWrapper = styled.div<{ isModalOpen: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: ${({ isModalOpen }) => (isModalOpen ? "800px" : "0")};
  overflow: hidden;
  transition: max-height ${TRANSITION_TIME}s ease-in-out;
`;

const ActiveCallToggle = styled.button<{ isModalOpen: boolean }>`
  position: absolute;
  top: -48px;
  right: 24px;
  align-items: center;
  background-color: ${theme.PRIMARY500};
  border-radius: 8px 8px 0px 0px;
  border: 1px solid ${theme.PRIMARY500};
  color: ${theme.PRIMARY200};
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: space-between;
  padding: 16px;
  width: 180px;

  :hover {
    background-color: ${theme.PRIMARY600};
  }
  :focus {
    outline: 1px solid ${theme.PRIMARY500};
    outline-offset: 1px;
  }
`;

const CallMonitorDiv = styled.div<{ isModalOpen: boolean }>`
  background-color: ${theme.PRIMARY100};
  border-top: 1px solid ${theme.border.neutral.primary};
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: auto;
  padding: 16px 40px;
  position: relative;
`;

const DropdownFiltersDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
`;

const CallMonitorScrollDiv = styled.div`
  height: 100%;
  overflow: overlay;
`;

export { CallMonitorV3Component };
