import moment from "moment";

export const DATE_FORMAT = "MM/DD/YYYY";

export const formatTimesToStringFormat = (start_time: string | number, format: string = "YYYY-MM-DD") => {
  if (typeof start_time === "number") {
    return moment.unix(start_time / 1000).format(format);
  }

  return moment(start_time).format(format);
};

export const formatDate = (date?: Date | string, format = DATE_FORMAT) => {
  if (!date) return "";
  return moment(date).format(format);
};
