import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { AppText } from "../../../UI";
import { CustomObjectManager } from "./CustomObject";
import { LeadObjectManager } from "./LeadObject";
interface DisappearingDivProps {
  togglePageTitle?: () => void;
}

const ObjectManager: React.FC<DisappearingDivProps> = ({ togglePageTitle }) => {
  const [selectedTab, setSelectedTab] = useState("Lead");
  const [blinds, setBlinds] = useState(false);
  const history = useHistory();

  const location = useLocation();

  const renderObjects = () => {
    if (selectedTab === "Lead") {
      return <LeadObjectManager blinds={blinds} setBlinds={setBlinds} />;
    }
    if (selectedTab === "CustomObject") {
      return <CustomObjectManager blinds={blinds} setBlinds={setBlinds} togglePageTitle={togglePageTitle} />;
    }
  };

  useEffect(() => {
    if (togglePageTitle) {
      togglePageTitle();
    }

    const queryParams = new URLSearchParams(location.search);

    // Access a specific query parameter
    const object_name = queryParams.get("object");
    if (object_name === "Lead" || object_name === "CustomObject") {
      setSelectedTab(object_name);
    }
  }, [location]);

  return (
    <>
      <FlexColumn>
        <SimpleFlexDiv>
          <StepLocationText>Object Manager</StepLocationText>
        </SimpleFlexDiv>
      </FlexColumn>
      <Page>
        <TopAlignDiv>
          <OptionsDiv>
            <OptionsLeft>
              <Tabs>
                <Tab
                  selected={selectedTab === "Lead"}
                  onClick={() => {
                    history.push("/system-config/object-manager?object=Lead");
                  }}
                >
                  LEAD OBJECT
                </Tab>
                <Tab
                  selected={selectedTab === "CustomObject"}
                  onClick={() => {
                    history.push("/system-config/object-manager?object=CustomObject");
                  }}
                >
                  CUSTOM OBJECTS
                </Tab>
              </Tabs>
            </OptionsLeft>
            <OptionsRight />
          </OptionsDiv>
        </TopAlignDiv>

        {renderObjects()}
      </Page>
    </>
  );
};

interface SelectedProps {
  selected?: boolean;
}

const Tab = styled(AppText)<SelectedProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  border-bottom: solid 2px ${(props) => (props.selected ? "#3E65FF" : "transparent")};
  padding-bottom: 6px;
  margin-top: 2px;
  color: ${(props) => (props.selected ? theme.BLACK_COLOR : theme.NEUTRAL300)};
  text-transform: uppercase;
`;

const Tabs = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
`;

const Page = styled.div`
  background: ${theme.WHITE_COLOR};
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const TopAlignDiv = styled.div`
  display: flex;
  justify-items: flex-start;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 16px 16px 0px 0px;
`;

const OptionsDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
`;

const OptionsLeft = styled.div`
  display: flex;
  height: 32px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const OptionsRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const SimpleFlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  gap: 6px;
`;

const StepLocationText = styled(AppText)`
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
`;

export { ObjectManager };
