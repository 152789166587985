import { useFlags } from "launchdarkly-react-client-sdk";
import React, { memo, useContext, useMemo, useState } from "react";
import { Modal } from "src/Components/modal";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { AppErrorText, AppText, SkeletonBlock, TemplateEditor } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { PhoenixAppButton, PhoenixCheckbox } from "../../UI/Phoenix";

import { ScriptTemplateModal } from "src/Components/modal";

import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment";
import { ModalContext } from "src/context";
import { FetchVoiceMailScriptsExpectedResponse, VoicemailDropScript } from "src/types/VoicemailTypes";
import { useFirstRender } from "src/utils/hooks";
import { appToast } from "src/utils/toast";
import ScriptPreviewCard from "./script-management/ScriptPreview";

const FETCH_VOICEMAIL_SCRIPTS = gql`
  query FetchVoicemailScripts($fetchVoiceMailScriptsId: String) {
    fetchVoiceMailScripts(id: $fetchVoiceMailScriptsId)
  }
`;

const DELETE_VOICEMAIL_SCRIPT = gql`
  mutation DeleteVoiceMailScript($deleteVoiceMailScriptId: String!, $deleteAllAssociatedVoicemailDrops: Boolean) {
    deleteVoiceMailScript(
      id: $deleteVoiceMailScriptId
      deleteAllAssociatedVoicemailDrops: $deleteAllAssociatedVoicemailDrops
    ) {
      id
    }
  }
`;

export const StepScriptManager: React.FC<{ brandId?: string; isSubTab?: boolean }> = ({ brandId, isSubTab }) => {
  const {
    data: voiceMailScriptsData,
    loading: voiceMailScriptsLoading,
    error: voiceMailScriptsError,
  } = useQuery<FetchVoiceMailScriptsExpectedResponse>(FETCH_VOICEMAIL_SCRIPTS, {
    fetchPolicy: "network-only",
    onError: (error) => {
      appToast("Unable to fetch voicemail scripts");
    },
  });

  const { voicemailDrops } = useFlags();
  const { setShowScriptModal, setScriptModalData, showScriptModal } = useContext(ModalContext);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showPreviewScriptID, setShowPreviewScriptID] = useState("");
  const [showDeleteScriptModal, setShowDeleteScriptModal] = useState(false);
  const [showDeleteScriptID, setShowDeleteScriptID] = useState("");

  const handleShowPreviewModal = (scriptID: string) => {
    setShowPreviewModal(true);
    setShowPreviewScriptID(scriptID);
  };

  const handleShowDeleteScriptModal = (scriptID: string) => {
    setShowDeleteScriptModal(true);
    setShowDeleteScriptID(scriptID);
  };

  const handleCreateScript = () => {
    setShowScriptModal(true);
    setScriptModalData({
      script_id: undefined,
    });
  };

  const handleEditScript = (scriptID: string) => {
    setShowScriptModal(true);
    setScriptModalData({
      script_id: scriptID,
    });
  };

  const globalLoading = voiceMailScriptsLoading;

  const globalError = voiceMailScriptsError;

  const noSavedScripts = useMemo(() => {
    return voiceMailScriptsData?.fetchVoiceMailScripts?.length === 0;
  }, [voiceMailScriptsData]);

  const FORMATED_SCRIPTS = useMemo(() => {
    return voiceMailScriptsData?.fetchVoiceMailScripts?.map((script) => ({
      ...script,
      createdAt: moment(script.created_at).format("MM/dd/yyyy"),
    }));
  }, [voiceMailScriptsData]);

  const isFirstRender = useFirstRender();

  if (!voicemailDrops || voicemailDrops === undefined) return null;

  if (globalLoading && isFirstRender) return <SkeletonBlock height="600px" width="100%" borderRadius={8} />;

  if (globalError) return <AppErrorText>Unable to fetch voicemail scripts</AppErrorText>;

  return (
    <Main>
      {showScriptModal ? <ScriptTemplateModal /> : null}
      {showPreviewModal ? (
        <ScriptPreviewModal
          scriptID={showPreviewScriptID}
          show={showPreviewModal}
          onClose={() => setShowPreviewModal(false)}
        />
      ) : null}
      {showDeleteScriptModal ? (
        <DeleteScriptModal
          scriptID={showDeleteScriptID}
          show={showDeleteScriptModal}
          onClose={() => setShowDeleteScriptModal(false)}
        />
      ) : null}
      <Header>
        <AppText fontSize={22} fontWeight={500} lineHeight={28}>
          Script Management
        </AppText>
        <PhoenixAppButton variant="brand" buttonType="secondary" onClick={handleCreateScript}>
          Create A Script
        </PhoenixAppButton>
      </Header>

      <Body>
        {noSavedScripts ? (
          <NoSavedScriptComponent handleCreateScript={handleCreateScript} />
        ) : (
          FORMATED_SCRIPTS?.map((script) => (
            <ScriptPreviewCard
              key={script.id}
              script={script}
              handleShowPreviewModal={handleShowPreviewModal}
              handleEditScript={handleEditScript}
              handleShowDeleteScriptModal={handleShowDeleteScriptModal}
            />
          ))
        )}
      </Body>
    </Main>
  );
};

const NoSavedScriptComponent = memo(
  ({ handleCreateScript }: { handleCreateScript: () => void }) => {
    return (
      <FlexDiv direction="column" gap={40} align="center" height="70vh" width="100%">
        <FlexDiv direction="column" gap={8} align="center">
          <AppText fontSize={12} fontWeight={500} lineHeight={24} color={theme.text.neutral.secondary}>
            Let's get started.
          </AppText>
          <AppText fontSize={16} fontWeight={400} lineHeight={24} color={theme.text.neutral.primary}>
            Create or upload your first script.
          </AppText>
        </FlexDiv>
        <PhoenixAppButton variant="brand" buttonType="secondary" onClick={handleCreateScript}>
          Create A Script
        </PhoenixAppButton>
      </FlexDiv>
    );
  },
  (prevProps, nextProps) => prevProps.handleCreateScript === nextProps.handleCreateScript,
);

const DeleteScriptModalRaw = ({
  scriptID,
  show,
  onClose,
}: {
  scriptID: string;
  show: boolean;
  onClose: () => void;
}) => {
  const [deleteAllAssociatedVoicemailDrops, setDeleteAllAssociatedVoicemailDrops] = useState(false);
  const [deleteScript, { loading: deleteScriptLoading, error: deleteScriptError }] = useMutation(
    DELETE_VOICEMAIL_SCRIPT,
    {
      onCompleted: (data) => {
        onClose();
        appToast("Script deleted successfully");
      },
      onError: (error) => {
        console.log("error", error);
        appToast("Unable to delete voicemail script");
      },
      refetchQueries: ["FetchVoicemailScripts"],
    },
  );

  const handleDeleteScript = () => {
    deleteScript({
      variables: {
        deleteVoiceMailScriptId: scriptID,
        deleteAllAssociatedVoicemailDrops: deleteAllAssociatedVoicemailDrops,
      },
    });
  };

  return (
    <Modal open={show} onClose={onClose}>
      <ModalMain>
        <AppText fontSize={16} fontWeight={600} color={theme.BLACK_COLOR}>
          Delete Script
        </AppText>
        <AppText fontSize={16} fontWeight={400} color={theme.BLACK_COLOR}>
          Are you sure you want to delete this script?
        </AppText>
        <FlexDiv gap={4} width="100%" align="center" justify="center">
          <PhoenixCheckbox
            checked={deleteAllAssociatedVoicemailDrops}
            onChange={() => setDeleteAllAssociatedVoicemailDrops(!deleteAllAssociatedVoicemailDrops)}
          />
          <AppText fontSize={12} fontWeight={400} color={theme.NEUTRAL400}>
            Remove all voicemail drops that used this script
          </AppText>
        </FlexDiv>

        <FlexDiv direction="row" gap={10} align="center" justify="space-between" width="100%" padding="0px 40px">
          <PhoenixAppButton variant="brand-outline" buttonType="secondary" onClick={onClose}>
            Cancel
          </PhoenixAppButton>
          <PhoenixAppButton variant="danger-outline" buttonType="secondary" onClick={handleDeleteScript}>
            Delete
          </PhoenixAppButton>
        </FlexDiv>
      </ModalMain>
    </Modal>
  );
};
const DeleteScriptModal = memo(DeleteScriptModalRaw, (prevProps, nextProps) => {
  return prevProps.show === nextProps.show && prevProps.scriptID === nextProps.scriptID;
});

const Main = styled.div`
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  border-radius: 8px;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${theme.surface.brand.secondary};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 90px;
  padding: 24px 40px;

  border-bottom: 1px solid ${theme.border.neutral.secondary};
  background-color: ${theme.WHITE_COLOR};
`;

const Body = styled.div`
  display: flex;

  height: max-content;
  overflow-y: auto;
  gap: 24px;
  flex-wrap: wrap;

  padding: 24px;
`;

const ScriptPreviewModalRaw = ({
  scriptID,
  show,
  onClose,
}: {
  scriptID: string;
  show: boolean;
  onClose: () => void;
}) => {
  const [script, setScript] = useState<VoicemailDropScript | null>(null);
  const {
    data: scriptData,
    loading: scriptLoading,
    error: scriptError,
  } = useQuery<FetchVoiceMailScriptsExpectedResponse>(FETCH_VOICEMAIL_SCRIPTS, {
    fetchPolicy: "network-only",
    variables: {
      fetchVoiceMailScriptsId: scriptID,
    },
    onCompleted: (data) => {
      setScript(data.fetchVoiceMailScripts[0]);
    },
    onError: (error) => {
      console.log("error", error);
      appToast("Unable to fetch voicemail script");
    },
  });

  const ScriptLoadingComponent = () => {
    return (
      <>
        <SkeletonBlock height="30px" width="200px" borderRadius={8} />
        <ScriptPreviewBody>
          <SkeletonBlock height="175px" width="100%" borderRadius={8} />
        </ScriptPreviewBody>
      </>
    );
  };

  return (
    <Modal open={show} onClose={onClose}>
      <ModalMain>
        {scriptError ? (
          <AppErrorText>Unable to fetch voicemail script</AppErrorText>
        ) : scriptLoading ? (
          <ScriptLoadingComponent />
        ) : (
          <>
            <AppText fontSize={16} fontWeight={600} color={theme.BLACK_COLOR}>
              {script?.title}
            </AppText>
            <ScriptPreviewBody>
              {script?.preview_script ? (
                <TemplateEditor ableToEdit={false} initialContent={script?.preview_script} border={false} />
              ) : (
                <AppText fontSize={14} fontWeight={400} color={theme.BLACK_COLOR}>
                  Unable to preview script
                </AppText>
              )}
            </ScriptPreviewBody>
          </>
        )}
      </ModalMain>
    </Modal>
  );
};

const ScriptPreviewModal = memo(ScriptPreviewModalRaw, (prevProps, nextProps) => {
  return prevProps.show === nextProps.show && prevProps.scriptID === nextProps.scriptID;
});

const ScriptPreviewBody = styled.div`
  width: 100%;
  overflow: auto;
  padding: 24px;
`;

const ModalMain = styled(FlexDiv)`
  width: 480px;
  height: max-content;
  max-height: 80vh;
  overflow: auto;
  margin: 40px 0px;
  gap: 48px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
`;
