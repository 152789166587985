import React, { memo, useContext, useMemo } from "react";
import { PhoenixStyledTooltip } from "src/Components/Dumb/PhoenixStyledTooltip";
import { AppErrorText, SkeletonBlock } from "src/Components/UI";
import { PhoenixAppButton, PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import { CallContext } from "src/context";
import { voicemail } from "src/images/NewDesign";
import useFetchVoicemailDrops from "src/query-hooks/useFetchVoicemailDrops";
import { OptionItem } from "src/types";
import { VoicemailDrop } from "src/types/VoicemailTypes";
import { theme } from "src/utils/theme";
import { appToast } from "src/utils/toast";
import styled from "styled-components";
import { FlexDiv } from "../../UI/FlexDiv";
import { useFlags } from "launchdarkly-react-client-sdk";

interface VoicemailDropsModuleProps {
  leadGroupHasMultipleNumbersToCall: boolean;
  showVoicemailDrops: boolean;
}

const VoicemailDropsModuleRaw = ({
  leadGroupHasMultipleNumbersToCall,
  showVoicemailDrops,
}: VoicemailDropsModuleProps) => {
  const { hangup, selectVoicemailDrop, selectedVoicemailDropId, loadingPlayVoicemailDrop } = useContext(CallContext);
  const { voicemailDrops } = useFlags();
  const { data, loading, error } = useFetchVoicemailDrops();

  const FORMATED_VOCIEMAIL_DROP_OPTIONS = useMemo(
    () =>
      data?.fetchVoicemailDrops.map((voicemailDrop: VoicemailDrop) => ({
        label: voicemailDrop.title,
        value: voicemailDrop.id,
        customIcon: {
          type: "svg" as "svg" | "img",
          src: voicemail,
          inputIconColor: theme.PRIMARY500,
        },
      })) || [],
    [data?.fetchVoicemailDrops],
  );

  const globalLoading = loading || loadingPlayVoicemailDrop;

  if (!showVoicemailDrops || !voicemailDrops) {
    return null;
  }

  if (error) {
    return (
      <MainDiv>
        <AppErrorText>Unable to fetch voicemail drops</AppErrorText>
      </MainDiv>
    );
  }

  if (globalLoading) {
    return <SkeletonBlock width={432} height={100} borderRadius={8} />;
  }

  return (
    <MainDiv>
      <PhoenixStyledTooltip id="voicemail-drop-tooltip" />
      <PhoenixMultiSelect
        titleTextSpacing={8}
        options={FORMATED_VOCIEMAIL_DROP_OPTIONS}
        placeholder={FORMATED_VOCIEMAIL_DROP_OPTIONS.length > 0 ? "Select Voicemail Recording" : "No Recordings"}
        onChange={(e: { label: string; value: string }) => selectVoicemailDrop(e?.value || undefined)}
        value={FORMATED_VOCIEMAIL_DROP_OPTIONS.find(
          (voicemailDrop: OptionItem) => voicemailDrop.value === selectedVoicemailDropId,
        )}
        titleText="Voicemail Drops"
        isMulti={false}
        isClearable={true}
        name="voicemail-drops"
        marginBottom={false}
        width={240}
        optionsContainerLocation="top"
      />

      <PhoenixAppButton
        variant="brand"
        buttonType="secondary"
        onClick={() => {
          if (!selectedVoicemailDropId) {
            appToast("Please select a voicemail drop first");
            return;
          }

          appToast("Queing up voicemail drop");

          if (leadGroupHasMultipleNumbersToCall) {
            hangup(true, true);
          } else {
            hangup(true, false);
          }
        }}
        disabled={!selectedVoicemailDropId || loadingPlayVoicemailDrop}
        data-tip="Selecting a Voicemail Drop will auto disposition your call to 'no answer, left voicemail'. This action will end the call."
        data-for="voicemail-drop-tooltip"
      >
        Leave Message
      </PhoenixAppButton>
    </MainDiv>
  );
};

export const VoicemailDropsModule = memo(VoicemailDropsModuleRaw, (prevProps, nextProps) => {
  return prevProps === nextProps;
});

const MainDiv = styled(FlexDiv)`
  width: 432px;
  height: 100px;
  padding: 16px;
  gap: 8px;
  justify-content: space-between;
  background-color: ${theme.fill.brand.secondary};
  border-radius: 8px;
  align-items: flex-end;
  border: 1px solid ${theme.border.neutral.primary};
  animation: ${theme.fadeIn} 0.2s ease;
`;
