import React from "react";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { AppText } from "../../../UI";
import moment from "moment";
import { formatUSDRaw } from "../../../../utils/format";
import { loggedInUser } from "../../../../apollo/cache";
import { functions, isEqual, omit } from "lodash";
import { formatDate } from "src/utils/date";
import { useFlags } from "launchdarkly-react-client-sdk";
import useMultiBrands from "src/cache-hooks/useIsMultiBrand";

interface LeadCardTopInfoProps {
  computedLeadData: any;
  isInMyScheduleComponent: boolean;
  showLeadSourceToUser: boolean;
  currentTime: string;
  mrrLabel: any;
  scheduledEvent: any;
}

const LeadCardTopInfoComponent: React.FC<LeadCardTopInfoProps> = ({
  computedLeadData,
  isInMyScheduleComponent,
  showLeadSourceToUser,
  currentTime,
  mrrLabel,
  scheduledEvent,
}) => {
  const { showMultiBrandUI } = useMultiBrands(useFlags);
  return (
    <CardGridMiddle>
      <Horizontal>
        <CallbackPersonName fontWeight={400} fontSize={12}>
          {computedLeadData?.first_name && computedLeadData?.last_name
            ? `${computedLeadData?.last_name}, ${computedLeadData?.first_name}`
            : computedLeadData?.full_name}
        </CallbackPersonName>
      </Horizontal>
      <Horizontal>
        <CallbackCityStateDiv>
          {computedLeadData?.state && (
            <CallbackCityStateText fontWeight={400} fontSize={12}>
              {computedLeadData?.city}
              {computedLeadData?.city && computedLeadData?.state ? `, ` : ``}
              {computedLeadData?.state}
              {computedLeadData?.state && computedLeadData?.country ? `, ` : ``}
              {computedLeadData?.country}
            </CallbackCityStateText>
          )}
          {!!computedLeadData?.timezone_by_state && (
            <CallbackCityStateText style={{ fontSize: "12px" }}>
              {` (${currentTime}${
                computedLeadData?.timezone_by_state
                  ?.split(" ")
                  ?.pop()
                  ?.replace(/[{()}]/g, "") ?? ""
              })`}
            </CallbackCityStateText>
          )}
        </CallbackCityStateDiv>
      </Horizontal>
      {isInMyScheduleComponent && !!scheduledEvent ? (
        <CallbackCityStateText fontWeight={400} fontSize={12}>
          {!!scheduledEvent.start_time && moment(scheduledEvent.start_time).format("h:mm A")}
          {!!scheduledEvent.end_time && !!scheduledEvent.start_time && " - "}
          {!!scheduledEvent.end_time && moment(scheduledEvent.end_time).format("h:mm A")}
          {!!scheduledEvent.event_status && returnAcceptanceStatus(scheduledEvent.event_status)}
        </CallbackCityStateText>
      ) : (
        <>
          {!!computedLeadData?.industry && (
            <CallbackIndustryDiv>
              <AppText fontWeight={400} fontSize={12}>
                {computedLeadData?.industry}
                {!!computedLeadData?.sub_industry && ` (${computedLeadData?.sub_industry})`}
              </AppText>
            </CallbackIndustryDiv>
          )}
        </>
      )}
      {showLeadSourceToUser && !!computedLeadData?.lead_source && (
        <div>
          <AppText fontWeight={400} fontSize={12} style={{}}>
            {computedLeadData?.lead_source}
          </AppText>
        </div>
      )}

      {!!computedLeadData?.computed_mrr && (
        <div>
          <AppText style={{}} fontWeight={400} fontSize={12}>
            <span>{mrrLabel?.getMrrLabel}:&nbsp;</span>
            {formatUSDRaw(computedLeadData?.computed_mrr)}
          </AppText>
        </div>
      )}
      {!!computedLeadData?.current_close_date && (
        <div>
          <AppText style={{}} fontWeight={400} fontSize={12}>
            <span>Close Date:&nbsp;</span>
            {formatDate(computedLeadData?.current_close_date)}
          </AppText>
        </div>
      )}
      {!!computedLeadData?.channel &&
        // hardcoded for Contra by client request. TODO: remove hardcoded org check when lead card modularization effort is complete.
        (loggedInUser().organization_id === "8506808f-62af-4174-a703-6e995e365011" ||
          ["Development", "Beta"].includes(process.env.REACT_APP_ENVIRONMENT as string)) && (
          <div>
            <AppText fontWeight={400} fontSize={12}>
              Channel: {computedLeadData?.channel}
            </AppText>
          </div>
        )}
      {!!computedLeadData?.brand_name && showMultiBrandUI && (
        <div>
          <AppText style={{}} fontWeight={400} fontSize={12}>
            <span>Brand: {computedLeadData?.brand_name} </span>
          </AppText>
        </div>
      )}
    </CardGridMiddle>
  );
};

const CardGridMiddle = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  flex-grow: 2;
  gap: 4px;
  width: 300px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CallbackPersonName = styled(AppText)`
  /* font-size: 12px; */
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const CallbackCityStateDiv = styled.div`
  /* padding: 0px 0px 0px 0px; */
  display: flex;
  flex-direction: row;
  /* align-items: center; */
`;

const CallbackCityStateText = styled(AppText)`
  align-items: center;
  max-width: 240px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const CallbackIndustryDiv = styled.div`
  display: flex;
`;

const statusColor = (status: string) => {
  switch (status) {
    case "Accepted":
      return theme.SUCCESS500;
    case "Pending":
      return theme.TERTIARY500;
    case "Declined":
      return theme.ATTENTION700;
    default:
      return theme.BLACK_COLOR;
  }
};

const returnAcceptanceStatus = (status: string) => {
  return <span style={{ fontWeight: 600, color: statusColor(status) }}>&nbsp; &nbsp; {status}</span>;
};

const MemoizedLeadCardTopInfo = React.memo(LeadCardTopInfoComponent, (oldProps, newProps) => {
  const oldP = omit(oldProps, functions(oldProps));
  const newP = omit(newProps, functions(newProps));
  return isEqual(oldP, newP);
});

export { MemoizedLeadCardTopInfo as LeadCardTopInfo };
