import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { AppButton, AppText, Loading } from "../../UI";
import { theme } from "../../../utils/theme";
import gql from "graphql-tag";
import { QueryLazyOptions, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { FormMultiSelectField, FormSelectField, InputField } from "../../Field";
import { appToast } from "../../../utils/toast";
import { PHONE_REGEX } from "../../../utils/regex";
import { SHOW_LEAD_SOURCE_TO_USER } from "../../../apollo/query";
import { SYSTEM_FIELD_CHAR_LIMIT, SYSTEM_FIELD_EMAIL_CHAR_LIMIT } from "../../../utils/format";
import { isValidEmail } from "../../../utils/format";
import { recursiveObjectTrim } from "../../../utils/misc";
/**
 * Used on the dialer for an incoming call in which the lead is not known
 */

interface NewLeadFormProps {
  refetch: (options?: QueryLazyOptions<Record<string, any>> | undefined) => void;
  lead_id?: string;
}

export const ADD_LEAD = gql`
  mutation addLead(
    $first_name: String
    $last_name: String
    $business_name: String
    $phone_number: String!
    $email: String
    $industry: String
    $sub_industry: String
    $lead_source: String
    $channel: CHANNEL
    $status: String
    $title: String
    $content: String
    $city: String
    $state: String
    $brand_id: String
  ) {
    addLead(
      first_name: $first_name
      last_name: $last_name
      business_name: $business_name
      phone_number: $phone_number
      email: $email
      industry: $industry
      sub_industry: $sub_industry
      lead_source: $lead_source
      channel: $channel
      status: $status
      title: $title
      content: $content
      city: $city
      state: $state
      brand_id: $brand_id
    ) {
      id
      lead {
        id
        first_name
        last_name
        full_name
        business_name
        primary_email
        primary_phone_number
        industry
        sub_industry
        lead_source
        channel
        status
        title
        content
        city
        state
        timezone_by_state
      }
    }
  }
`;

const UPDATE_LEAD = gql`
  mutation updateOneLead(
    $id: String!
    $first_name: String
    $last_name: String
    $business_name: String
    $primary_phone_number: String
    $primary_email: String
    $industry: String
    $sub_industry: String
    $lead_source: String
    $channel: CHANNEL
    $city: String
    $state: String
    $newIncoming: Boolean
    $brand_id: String
  ) {
    updateOneLead(
      id: $id
      first_name: $first_name
      last_name: $last_name
      business_name: $business_name
      primary_phone_number: $primary_phone_number
      primary_email: $primary_email
      industry: $industry
      sub_industry: $sub_industry
      lead_source: $lead_source
      channel: $channel
      city: $city
      state: $state
      newIncoming: $newIncoming
      brand_id: $brand_id
    ) {
      id
      newIncoming
      first_name
      last_name
      business_name
      primary_email
      primary_phone_number
      industry
      sub_industry
      lead_source
      channel
      city
      state
    }
  }
`;

const newLeadSchema = Yup.object().shape({
  first_name: Yup.string()
    .notRequired()
    .nullable()
    .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
  last_name: Yup.string()
    .notRequired()
    .nullable()
    .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
  business_name: Yup.string()
    .required("Field is required")
    .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
  phone_number: Yup.string()
    .required("Field is required")
    .max(15, "Must be valid phone number")
    .min(7, "Must be valid phone number")
    .matches(PHONE_REGEX, "Must be a valid phone number"),
  // .matches(/^[0-9]*$/, "Phone number must be numeric")
  // .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
  channel: Yup.string().required("Field is required"),
  email: Yup.string()
    .notRequired()
    .email()
    .nullable()
    .max(SYSTEM_FIELD_EMAIL_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_EMAIL_CHAR_LIMIT} characters or less`)
    .test("email", "Must be a valid email", (value) => {
      if (!!value) {
        return isValidEmail(value) ?? true;
      }
      return true;
    }),
  lead_source: Yup.string().notRequired().nullable(),
  industry: Yup.string().notRequired().nullable(),
  sub_industry: Yup.string().notRequired().nullable(),
  city: Yup.string().required(),
  state: Yup.string().required("State is required").trim(),
});
interface MyFormikProps {
  first_name?: string;
  last_name?: string;
  business_name: string;
  phone_number: string;
  email: string;
  lead_source: string;
  industry: string;
  sub_industry: string;
  channel: string;
  city: string;
  state: string;
}

const channel_options = [
  {
    label: "Inbound",
    value: "Inbound",
  },
  {
    label: "Outbound",
    value: "Outbound",
  },
];

const optionsLoading = [
  {
    label: "Loading...",
    value: "",
  },
];

const GET_POSSIBLE_INDUSTRIES_DATA = gql`
  query fetchIndustryOptions {
    fetchIndustryOptions {
      id
      label
      sub_industries
    }
  }
`;

const GET_POSSIBLE_LEAD_SOURCES_DATA = gql`
  query fetchLeadSourceOptions {
    fetchLeadSourceOptions {
      id
      label
    }
  }
`;

const NewLeadForm: React.FC<NewLeadFormProps> = ({ refetch, lead_id }) => {
  const { data: showLeadSourceToUser } = useQuery(SHOW_LEAD_SOURCE_TO_USER);
  const [addLead, { loading, error }] = useMutation(ADD_LEAD, {
    async onCompleted({ addLead }) {
      if (!addLead) {
        appToast("Error adding lead. Something went wrong.");
        return;
      }
      appToast("Lead created!");
      !!refetch &&
        (await refetch({
          variables: {
            id: addLead?.lead?.id,
          },
        }));
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `addLead GraphQL Error: ${message}`,
      });
    },
  });

  const [updateOneLead, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_LEAD, {
    async onCompleted({ updateOneLead }) {
      if (!updateOneLead) {
        appToast("Error adding lead. Something went wrong.");
        return;
      }
      appToast("Lead created!");
      !!refetch &&
        (await refetch({
          variables: {
            id: updateOneLead?.id,
          },
        }));
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `updateOneLead GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchLead", "FetchAssociateContact"],
  });
  const { data: leadsourceData, loading: leadsourceLoading, error: leadsourceError } = useQuery(
    GET_POSSIBLE_LEAD_SOURCES_DATA,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );
  const { data: industriesData, loading: industriesLoading, error: industriesError } = useQuery(
    GET_POSSIBLE_INDUSTRIES_DATA,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  if (leadsourceError) return <p>Error loading Lead Sources</p>;
  if (industriesError) return <p>Error loading Lead Sources</p>;

  const returnSubIndustryOptions = (industry: string) => {
    if (industriesLoading) {
      return optionsLoading;
    }
    if (!!industry) {
      const found = industriesData.fetchIndustryOptions.slice().filter((item: any) => item.label === industry);
      if (!!found.length) {
        return found[0].sub_industries
          ?.map((ls: string) => ({ label: ls, value: ls }))
          ?.sort((a: any, b: any) => (a?.label < b?.label ? -1 : 1));
      } else {
        return [{ label: "Please select a valid primary industry", value: "" }];
      }
    } else {
      return [{ label: "Select Primary Industry", value: "" }];
    }
  };

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        business_name: "",
        phone_number: "",
        email: "",
        lead_source: "",
        channel: "",
        industry: "",
        sub_industry: "",
        city: "",
        state: "",
      }}
      validationSchema={newLeadSchema}
      onSubmit={async (values) => {
        recursiveObjectTrim(values);

        if (!lead_id) {
          await addLead({
            variables: {
              ...values,
            },
          });
        } else {
          // update lead
          await updateOneLead({
            variables: {
              id: lead_id,
              first_name: values.first_name,
              last_name: values.last_name,
              business_name: values.business_name,
              primary_phone_number: values.phone_number,
              primary_email: values.email,
              lead_source: values.lead_source,
              channel: values.channel,
              industry: values.industry,
              sub_industry: values.sub_industry,
              city: values.city,
              state: values.state,
              newIncoming: false,
            },
          });
        }
      }}
    >
      {({ submitForm, values, setFieldValue, isSubmitting, errors }: FormikProps<MyFormikProps>) => {
        console.log("errors: ", errors);
        return (
          <PopupContainerDiv>
            <PopupInputLabel>Contact First Name</PopupInputLabel>
            <LeadInput name="first_name" />
            <PopupInputLabel>Contact Last Name</PopupInputLabel>
            <LeadInput name="last_name" />
            <PopupInputLabel>
              Business Name<span style={{ color: "red" }}>*</span>
            </PopupInputLabel>
            <LeadInput name="business_name" />
            <PopupInputLabel>
              Primary Phone Number<span style={{ color: "red" }}>*</span>
            </PopupInputLabel>
            <LeadInput name="phone_number" type="tel" />
            <PopupInputLabel>Primary Email</PopupInputLabel>
            <LeadInput name="email" />
            {showLeadSourceToUser?.showLeadSourceToUser && (
              <>
                <PopupInputLabel>Lead Source</PopupInputLabel>
                <LeadSelect
                  name="lead_source"
                  allowSelectPlaceholder
                  width={210}
                  options={
                    leadsourceLoading
                      ? optionsLoading
                      : leadsourceData.fetchLeadSourceOptions?.map((ls: any) => ({ label: ls.label, value: ls.label }))
                  }
                  placeholder="Select"
                />
              </>
            )}
            <PopupInputLabel>Channel</PopupInputLabel>
            <LeadSelect
              width={210}
              name="channel"
              allowSelectPlaceholder
              options={channel_options}
              placeholder="Select"
            />
            <PopupInputLabel>Industry</PopupInputLabel>
            <div style={{ width: "210px" }}>
              <FormMultiSelectField
                isMulti={false}
                name="industry"
                value={!!values.industry ? { label: values.industry, value: values.industry } : {}}
                options={
                  industriesLoading
                    ? optionsLoading
                    : industriesData.fetchIndustryOptions?.map((ls: any) => ({ label: ls.label, value: ls.label }))
                }
                onChange={(e: any) => {
                  setFieldValue(`industry`, !!e?.value ? e?.value : "");
                  setFieldValue(`sub_industry`, "");
                }}
              />
            </div>

            <PopupInputLabel>Sub-Industry</PopupInputLabel>
            <div style={{ width: "210px" }}>
              <FormMultiSelectField
                isMulti={false}
                name="sub_industry"
                value={!!values.sub_industry ? { label: values.sub_industry, value: values.sub_industry } : {}}
                isDisabled={!values.industry}
                options={returnSubIndustryOptions(values.industry)}
                onChange={(e: any) => {
                  setFieldValue(`sub_industry`, !!e?.value ? e?.value : "");
                }}
              />
            </div>

            <PopupInputLabel>City</PopupInputLabel>
            <LeadInput name="city" />
            <PopupInputLabel>State</PopupInputLabel>
            <LeadInput name="state" />

            <CenterDiv>
              {isSubmitting ? (
                <Loading />
              ) : (
                <AddEmailButton type="submit" onClick={submitForm}>
                  Add Lead
                </AddEmailButton>
              )}
            </CenterDiv>
            <WhitespaceDiv />
          </PopupContainerDiv>
        );
      }}
    </Formik>
  );
};

const PopupContainerDiv = styled.div`
  position: relative;
  width: 100%;
  padding: 20px 80px 20px 80px;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LeadSelect = styled(FormSelectField)`
  /* height: 45px; */
  border-radius: 2.9px;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
  width: 100%;
`;

const WhitespaceDiv = styled.div`
  height: 50px;
`;

const PopupInputLabel = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 13px;
  margin-left: 5px;
`;

const LeadInput = styled(InputField)`
  margin: 0;
  text-align: left;
  font-size: 13px;
  border-radius: 2.9px;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
  ::placeholder {
    text-align: left;
  }
`;

const AddEmailButton = styled(AppButton)`
  width: 264px;
  height: 45px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  margin: 25px auto;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { NewLeadForm };
