import styled from "styled-components";
import React, { useMemo } from "react";
import { Loading, AppText, FlexDiv } from "../UI";
import { theme } from "../../utils/theme";
import { ApolloError, gql, QueryLazyOptions, useLazyQuery } from "@apollo/client";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CurrentLeadType, SelectedContactType } from "../../types";
import { SearchItem } from ".";
import { NewAppButton } from "../UI/NewAppButton";
import { CORE_LEAD_CARD_DATA } from "../../apollo/fragments";
import InfiniteScroll from "react-infinite-scroll-component";

interface SearchListProps {
  isSearchDashboard?: boolean;
  setNonScheduledCallBack: Dispatch<SetStateAction<boolean>>;

  setSelectedContact: (selectedContact: SelectedContactType) => void;
  onCancelClick?: (key?: any) => any;

  filteredLeads?: CurrentLeadType[];
  loading: boolean;
  error?: ApolloError;
  disableOutsideEvent: boolean;
  searchSortValue?: boolean;
  onClickEvfetent?: (key?: any) => any;
  getLead?: (options?: QueryLazyOptions<Record<string, any>> | undefined) => void;

  hasMore: boolean;
  handleFetchMore: () => void;
  allFetchedLeads: CurrentLeadType[];
  called: boolean;
  handleResetLeadState: () => void;
}

const SearchList: React.FC<SearchListProps> = ({
  setNonScheduledCallBack,
  hasMore,
  handleFetchMore,
  setSelectedContact,
  loading,
  error,
  called,
  allFetchedLeads,
  disableOutsideEvent,
  searchSortValue,
  onCancelClick,
  getLead,
  isSearchDashboard,
  handleResetLeadState,
}) => {
  useEffect(() => {
    handleResetLeadState();
    return () => {
      handleResetLeadState();
    };
  }, []);

  const noLeadsFound = useMemo(() => {
    if (!!loading) return false;
    if (!called) return false;
    return allFetchedLeads?.length === 0;
  }, [allFetchedLeads, loading]);

  if (error) return <ErrorDisplay />;

  return (
    <>
      {isSearchDashboard && (
        <BackgroundDiv onClick={() => (onCancelClick ? onCancelClick() : setNonScheduledCallBack(false))} />
      )}
      <SearchListDiv dashboard={!!isSearchDashboard}>
        <GridDiv id="merge-contact-modal-scroll">
          <InfiniteScroll
            dataLength={allFetchedLeads?.length || 0}
            next={() => {
              handleFetchMore();
            }}
            hasMore={hasMore}
            loader={<Loading />}
            scrollableTarget="merge-contact-modal-scroll"
            height={"90vh"}
            style={{ display: "flex", flexDirection: "column", gap: "18px", overflow: "auto" }}
          >
            {noLeadsFound ? (
              <FlexDiv justify="center" align="center" height="100%">
                <AppText fontSize={14} fontWeight={600} color={theme.text.brand.primary}>
                  No leads found.
                </AppText>
              </FlexDiv>
            ) : (
              allFetchedLeads?.map((lead: CurrentLeadType, i: number) => {
                return (
                  <SearchItem
                    key={lead?.id}
                    setSelectedContact={setSelectedContact}
                    data={lead}
                    disableOutsideEvent={disableOutsideEvent}
                    getLead={getLead}
                    index={i}
                  />
                );
              })
            )}
          </InfiniteScroll>
        </GridDiv>
      </SearchListDiv>
    </>
  );
};

export { SearchList };

const ErrorDisplay: React.FC = () => {
  return (
    <ErrorContainer>
      <AppText fontSize={12} variant="error">
        Error loading searched leads. Please refresh and try again.
      </AppText>
    </ErrorContainer>
  );
};

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 64px;

  margin: 62px 0px;
  padding: 24px;

  background-color: ${theme.surface.neutral.primary};
  border: 1px solid ${theme.border.neutral.primary};
  border-radius: 8px;

  animation: ${theme.fadeIn} 0.3s ease-in-out;
`;

const GridDiv = styled.div`
  padding-left: 32px;
  z-index: 5;
`;

interface Dashboard {
  dashboard: boolean;
}

const SearchListDiv = styled.div<Dashboard>`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 7px;
  border: ${(props) => (props.dashboard ? `` : `solid 4px ${theme.NEUTRAL100}`)};
  padding: 17px 0px 70px 0px;
  padding-left: 28px;
  padding-right: 20px;
  z-index: 5;
  position: absolute;
  width: 420px;
  max-width: 440px;
  height: 100%;
`;

const BackgroundDiv = styled.div`
  background-color: white;
  opacity: 1;
  position: fixed;
  width: 552px;
  height: 100vh;
  overflow: hidden;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 4;
`;
