import * as React from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";

interface AgGridTooltipProps {
  value: string; // this will be passed in from the ag-grid cell or the 'headerTooltip' collumn definition
  // the following are passed in through AG Grid's 'tooltipComponentParams' prop
  header?: string;
  showList?: boolean; // if true, will split the value by ', ' and display as a list
  hideBlankValues?: boolean; //
  ignoreValueArray?: string[];
  minCharLength?: number;
}

export const AgGridTooltip = ({
  value,
  header = "",
  showList = false,
  hideBlankValues = true,
  ignoreValueArray = ["", "Unassigned", "NA", "-"],
  minCharLength = 0, // Default value is 0 (always show tooltip)
  ...props
}: AgGridTooltipProps) => {
  if (!!hideBlankValues && ignoreValueArray?.includes(value ?? "")) return null;
  if (!value && !header) return null;
  if (value?.length < minCharLength) return null;

  return (
    <TooltipContainer>
      {header && <TooltipHeader>{`${header}`}</TooltipHeader>}
      {value && (
        <TooltipBody>
          {showList ? (
            <ul style={{ listStyle: "none" }}>
              {value?.split(", ")?.map((ele: string) => (
                <li>{`${ele}`}</li>
              ))}
            </ul>
          ) : (
            <span>{`${value}`}</span>
          )}
        </TooltipBody>
      )}
    </TooltipContainer>
  );
};

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 200px;
  margin-left: 20px;
  padding: 10px;

  font-family: ${theme.PRIMARY_FONT};
  background-color: #08144ce0;
  color: #fff;
  border-radius: 3px;
`;

const TooltipHeader = styled.div`
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: 600;
`;

const TooltipBody = styled.div`
  font-size: 12px;
  font-weight: 400;
`;
