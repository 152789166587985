import React, { useMemo, useRef, useState, useCallback } from "react";
import styled from "styled-components";
import { caret_down, xIcon } from "src/images/NewDesign";
import { theme } from "src/utils/theme";
import { PhoenixAppButton, PhoenixIcon } from "src/Components/UI/Phoenix";
import { useClickOutside } from "src/utils/hooks";
import { AppText } from "src/Components/UI";

const removeIconStyle = { cursor: "pointer" };

interface DynamicDropdownProps<T> {
  applyChanges?: () => void;
  children: React.ReactNode | React.ReactNode[];
  icon?: any;
  isOpen?: boolean;
  label: string;
  menuWidth?: string;
  headerStyle?: React.CSSProperties;
  onClear?: () => void;
  onClose?: () => void;
  onRemove?: () => void;
  position?: "left" | "right";
  renderFooter?: React.ReactNode | ((value: T) => React.ReactNode);
  renderHeader: React.ReactNode | ((value: T) => React.ReactNode);
  setIsOpen?: (isOpen: boolean) => void;
  value?: T | T[];
  disabled?: boolean;
}

export const DropdownComparisonBadge = ({ value }: { value: string }) => (
  <ComparisonBadgeWrapper>
    <AppText fontSize={12} color={theme.text.neutral.primary}>
      {value}
    </AppText>
    <PhoenixIcon svg={caret_down} color={theme.text.brand.primary} hoverColor={theme.PRIMARY600} size={16} fillIcon />
  </ComparisonBadgeWrapper>
);

const DynamicDropdown = <T,>({
  children,
  icon = caret_down,
  isOpen: controlledIsOpen,
  label,
  menuWidth,
  onClear,
  onClose,
  onRemove,
  headerStyle,
  position = "left",
  renderFooter,
  renderHeader,
  setIsOpen: setControlledIsOpen,
  value,
  disabled,
}: DynamicDropdownProps<T>) => {
  const wrapperRef = useRef(null);
  const [localIsOpen, setLocalIsOpen] = useState(false);

  const isOpen = controlledIsOpen ?? localIsOpen;
  const setIsOpen = setControlledIsOpen ?? setLocalIsOpen;

  const handleClickOutside = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
      onClose?.();
    }
  }, [isOpen, setIsOpen, onClose]);

  useClickOutside(wrapperRef, handleClickOutside, ["date-picker-portal"]);

  const toggleDropdown = useCallback(() => {
    setIsOpen(!isOpen);
    if (isOpen) {
      onClose?.();
    }
  }, [isOpen, setIsOpen, onClose]);

  const hasValue = useMemo(() => {
    return Array.isArray(value) ? value.length > 0 : !!value;
  }, [value]);

  const renderHeaderValue = useMemo(() => {
    return hasValue ? (
      renderHeader ? (
        <>
          <AppText fontSize={12} fontWeight={500} color={theme.text.brand.primary}>
            {label}
            {":"}
          </AppText>
          {renderHeader}
        </>
      ) : (
        <>
          <TruncatedText fontSize={12} fontWeight={500} color={theme.text.brand.primary}>
            {label}
          </TruncatedText>
        </>
      )
    ) : (
      <TruncatedText fontSize={12}>{label}</TruncatedText>
    );
  }, [hasValue, renderHeader, value, label]);

  return (
    <DropdownWrapper ref={wrapperRef}>
      <DropdownHeader onClick={toggleDropdown} style={headerStyle}>
        <HeaderValue>{renderHeaderValue}</HeaderValue>
        {onRemove ? (
          <PhoenixIcon
            color={theme.text.brand.primary}
            fillIcon
            hoverColor={theme.PRIMARY600}
            onClick={(e) => {
              e.stopPropagation();
              onRemove?.();
            }}
            size={16}
            style={removeIconStyle}
            svg={xIcon}
          />
        ) : (
          <PhoenixIcon svg={icon} color={theme.text.brand.primary} hoverColor={theme.PRIMARY600} size={16} fillIcon />
        )}
      </DropdownHeader>
      {isOpen && (
        <DropdownMenu position={position} width={menuWidth}>
          <DropdownMenuContainer>{children}</DropdownMenuContainer>
          {renderFooter ?? (
            <DropdownFooter>
              <PhoenixAppButton
                disabled={!hasValue}
                onClick={onClear}
                buttonType="ghost-small"
                variant="danger-outline"
              >
                Clear selection
              </PhoenixAppButton>

              <PhoenixAppButton onClick={handleClickOutside} buttonType="ghost-small" disabled={disabled}>
                Apply
              </PhoenixAppButton>
            </DropdownFooter>
          )}
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
};

export default DynamicDropdown;

const ComparisonBadgeWrapper = styled.div`
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-radius: 4px;
  display: flex;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 500;
  gap: 8px;
  padding: 2px 8px;
`;

const DropdownWrapper = styled.div`
  position: relative;
`;

const DropdownHeader = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  gap: 8px;
  height: 32px;
  justify-content: space-between;
  padding: 4px 8px;
`;

const DropdownMenu = styled.div<{ position?: "left" | "right"; width?: string }>`
  ${({ position }) => position === "left" && "left: 0;"}
  ${({ position }) => position === "right" && "right: 0;"}
  animation: ${theme.lightPopup} 0.2s ease-in-out;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  max-height: 350px;
  overflow: hidden;
  position: absolute;
  top: calc(100% + 8px);
  width: clamp(${({ width = "380px" }) => width}, 100vw, 100%);
  z-index: 3;
`;

const DropdownMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
  height: 100%;
  overflow-y: auto;
`;

export const DropdownOptionList = styled.ul`
  list-style: none;
  margin: 0;
  overflow-y: auto;
  padding: 0;
`;

export const DropdownOptionItem = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  gap: 8px;
  padding: 10px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const DropdownFooter = styled.div<{ disabled?: boolean }>`
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #ccc;
  color: red;
  cursor: pointer;
  display: flex;
  font-size: 10px;
  font-weight: semibold;
  justify-content: space-between;
  padding: 12px;
  width: 100%;
`;

const HeaderValue = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  width: calc(100% - 22px);
`;

export const DropdownMoreBadge = styled.div`
  background-color: ${theme.text.brand.primary};
  border-radius: 4px;
  padding: 2px 4px;
`;

const TruncatedText = styled(AppText)`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;
