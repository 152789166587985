import React, { useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import styled from "styled-components";
import { useQuery, gql } from "@apollo/client";
import { AppErrorText, AppText, Loading, NewAppButton } from "../UI";
import { Modal } from "./Modal";
import { newCloseModal } from "../../images";
import { theme } from "../../utils/theme";
import { formatPhoneNumber } from "../../utils/format";
import { FETCH_CONFERENCE_PARTICIPANTS_POLL_INTERVAL } from "../../utils/variables";
import { AppTitle2 } from "../UI";
import { AiOutlineClockCircle } from "react-icons/ai";
import { CallContext } from "../../context/CallContext";
import { ModalContext } from "../../context";
import { useMutation } from "@apollo/client";
import moment from "moment";

// audio
import { Howl, Howler } from "howler";

const RESPOND_TO_CONCIERGE = gql`
  mutation respondToConcierge($callMeNowId: String!, $called: Boolean!) {
    respondToConcierge(callMeNowId: $callMeNowId, called: $called)
  }
`;

const InboundConciergeModal: React.FC = () => {
  const audio = require("../../sounds/inbound.mp3");
  const backupAudio = require("../../sounds/notification.wav");

  const [inboundAudio, setInboundAudio] = useState(
    new Howl({
      src: [audio, backupAudio],
      loop: true,
      volume: 0.5,
      rate: 0.8,

      onplayerror: function () {
        console.log("howler playback error");
      },

      onloaderror: function () {
        console.log("howler load error");
      },
    }),
  );
  const { goToCall } = useContext(CallContext);

  const [respondToConcierge, { loading: loadingRespondToConcierge, error: errorRespondToConcierge }] = useMutation(
    RESPOND_TO_CONCIERGE,
    {
      onError({ message, name }: { message: string; name: string }) {
        Sentry.captureEvent({
          message: `${name} GraphQL Error: ${message}`,
        });
        console.log(`Error in ${name}: `, message);
      },

      onCompleted(data: any) {
        console.log("Completed", data);
      },
    },
  );

  const { showInboundConciergeModal, setShowInboundConciergeModal, inboundConciergeModalData } = useContext(
    ModalContext,
  );

  const [missedCall, setMissedCall] = useState<boolean>(false);

  const now = moment().unix();

  const startTime = moment(inboundConciergeModalData?.timer_start_time).unix();
  const timeDiff = now - startTime;

  const [secondsLeft, setSecondsLeft] = useState<number>((inboundConciergeModalData?.response_time ?? 15) - timeDiff);

  // the seconds left is  the time left till the startTime + the rep response time - the current time in seconds

  useEffect(() => {
    console.log("inbound concierge time data", {
      BEstartTime: startTime,
      FEstartTime: now,
      "time difference. current time - start time": timeDiff,
      "seconds left for inbound concierge widget": secondsLeft,
    });
    if (secondsLeft <= 0) {
      setMissedCall(true);
      inboundAudio.stop();
      return;
    }

    if (secondsLeft > 0) {
      const interval = setInterval(() => {
        setSecondsLeft(secondsLeft - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [secondsLeft]);

  useEffect(() => {
    inboundAudio.play();
    return () => {
      inboundAudio.stop();
    };
  }, []);

  return (
    <Modal open={showInboundConciergeModal} onClose={() => setShowInboundConciergeModal(false)} closeButtonSize={16}>
      {loadingRespondToConcierge ? (
        <Loading />
      ) : errorRespondToConcierge ? (
        <AppErrorText>Something went wrong</AppErrorText>
      ) : (
        <ModalContent>
          <TitleDiv>
            <TitleText>
              {inboundConciergeModalData?.existingLead
                ? "Your Lead is Requesting a Demo!"
                : "Inbound Demo Request from Website!"}
            </TitleText>
          </TitleDiv>
          <Main>
            <InfoText>
              {inboundConciergeModalData?.existingLead
                ? "Your lead has requested a live demo via our website and is waiting for your call. Lead details below."
                : "A lead has requested a live demo via our website and is waiting for a call. Lead details below."}
            </InfoText>
            <LeadInfo>
              <BusinessName>{inboundConciergeModalData?.business_name}</BusinessName>
              <Address fontWeight={400}>{inboundConciergeModalData?.lead_name}</Address>
              {inboundConciergeModalData?.existingLead && (
                <Address fontWeight={400} fontSize={14}>
                  {inboundConciergeModalData?.address}
                </Address>
              )}
            </LeadInfo>
            {inboundConciergeModalData?.existingLead && (
              <div
                style={{
                  marginBottom: 20,
                }}
              >
                <AppErrorText>Choosing to “ignore” the call will prompt the lead to schedule a meeting.</AppErrorText>
              </div>
            )}
            <TimerDiv>
              <TimerInfoText fontSize={20} fontWeight={600}>
                Lead will be released in
              </TimerInfoText>
              <TimerText>
                <AiOutlineClockCircle color={theme.PRIMARY500} />
                <span>{secondsLeft}</span>
                <span>seconds</span>
              </TimerText>
            </TimerDiv>
          </Main>
          <Buttons>
            {missedCall ? (
              <NewAppButton
                width={188}
                height={50}
                onClick={() => {
                  setShowInboundConciergeModal(false);
                  inboundAudio.stop();
                }}
                variant="attention"
                style={{ margin: 16 }}
              >
                Close
              </NewAppButton>
            ) : (
              <>
                <NewAppButton
                  // small
                  // borderless
                  width={188}
                  height={50}
                  onClick={() => {
                    respondToConcierge({
                      variables: {
                        callMeNowId: inboundConciergeModalData.callMeNowId,
                        called: false,
                      },
                    });

                    setShowInboundConciergeModal(false);
                    inboundAudio.stop();
                  }}
                  variant="attention"
                  style={{ margin: 16 }}
                >
                  {inboundConciergeModalData?.existingLead ? "Ignore" : "Skip Me"}
                </NewAppButton>
                <NewAppButton
                  width={188}
                  height={50}
                  onClick={() => {
                    respondToConcierge({
                      variables: {
                        callMeNowId: inboundConciergeModalData.callMeNowId,
                        called: true,
                      },
                    });

                    setShowInboundConciergeModal(false);
                    inboundAudio.stop();

                    goToCall({
                      lead_id: inboundConciergeModalData.lead_id,
                      phoneNumber: inboundConciergeModalData.phone_number,
                      lead_brand_id: inboundConciergeModalData.brand_id || undefined,
                    });
                  }}
                  variant="primary"
                  style={{ margin: 16 }}
                >
                  Call Now
                </NewAppButton>
              </>
            )}
          </Buttons>
        </ModalContent>
      )}
    </Modal>
  );
};

const ModalContent = styled.div`
  width: 600px;
`;

const TimerInfoText = styled(AppText)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${theme.NEUTRAL300};
  margin-bottom: 11px;
`;

const TimerText = styled(AppText)`
  font-family: "Inter";
  font-style: normal;
  line-height: 26px;
  text-align: center;
  color: ${theme.PRIMARY500};
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 78px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.PRIMARY500};
  border-bottom: 1px solid ${theme.NEUTRAL200};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  button {
    display: inline;
    position: absolute;
    right: 0;
  }
`;

const Address = styled(AppText)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  text-align: center;

  color: ${theme.NEUTRAL500};
`;

const TitleText = styled(AppText)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${theme.WHITE_COLOR};

  text-align: center;
`;

const BusinessName = styled(AppText)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
`;

const InfoText = styled(AppText)`
  padding: 0 75px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 40px 0px;
`;

const Main = styled.div`
  text-align: center;
  padding: 0 70px;
`;

const LeadInfo = styled.div`
  padding: 0 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
`;

const TimerDiv = styled.div`
  padding: 0 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  border-top: 1px solid ${theme.NEUTRAL100};
`;
export { InboundConciergeModal };
