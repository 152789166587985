import React, { useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { AppText } from "src/Components/UI";
import { useClickOutside } from "src/utils/hooks";
import { theme } from "src/utils/theme";
import styled from "styled-components";

export const MiniCalendar: React.FC<{
  onClickOutside: () => void;
  handleTimeframeSwitch: () => void;
  curView: any;
}> = ({ onClickOutside, handleTimeframeSwitch, curView }) => {
  const ref = useRef(null);
  useClickOutside(ref, onClickOutside);

  const calEle = useMemo(() => document.getElementById("cal-wrap"), []);
  if (!calEle) return null;

  return createPortal(
    <MiniCalendarWrap ref={ref}>
      <TimeframeController handleTimeframeSwitch={handleTimeframeSwitch} curView={curView} />
    </MiniCalendarWrap>,
    calEle,
  );
};

const TimeframeController: React.FC<{ handleTimeframeSwitch: () => void; curView: string }> = ({
  handleTimeframeSwitch,
  curView,
}) => {
  const [isWeek, setIsWeek] = useState(curView === "timeGridWeek");
  return (
    <TimeframeContainer>
      <TimeframeButtonBacking isWeek={isWeek} />
      <TimeframeButton
        onClick={() => {
          handleTimeframeSwitch();
          setIsWeek(true);
        }}
      >
        <AppText
          fontSize={8}
          fontWeight={600}
          lineHeight={12}
          uppercase
          color={isWeek ? theme.text.neutral.inverse : theme.text.brand.primary}
        >
          Week
        </AppText>
      </TimeframeButton>
      <TimeframeButton
        onClick={() => {
          handleTimeframeSwitch();
          setIsWeek(false);
        }}
      >
        <AppText
          fontSize={8}
          fontWeight={600}
          lineHeight={12}
          uppercase
          color={!isWeek ? theme.text.neutral.inverse : theme.text.brand.primary}
        >
          Day
        </AppText>
      </TimeframeButton>
    </TimeframeContainer>
  );
};

const MiniCalendarWrap = styled.div`
  position: absolute;
  z-index: 10;
  top: 50px;
  left: 20px;

  width: 280px;
  height: 212px;
  padding: 8px;

  background-color: ${theme.fill.neutral.primary};
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  animation: ${theme.lightPopup} 0.2s ease-in-out forwards;
`;

const TimeframeContainer = styled.div`
  position: relative;
  display: flex;
  padding: 2px;
  background-color: ${theme.fill.brand.secondary};
  width: fit-content;
  border-radius: 6px;
`;

const TimeframeButton = styled.button`
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 58px;
  height: 20px;

  cursor: pointer;

  background: transparent;
  border: none;
`;

const TimeframeButtonBacking = styled.div<{ isWeek: boolean }>`
  position: absolute;
  left: ${(props) => (props.isWeek ? "2px" : "50%")};

  width: 58px;
  height: 20px;

  border-radius: 4px;
  border: 1px solid #204fc7;
  background-color: #005fec;

  transition: left 0.15s ease-in-out;
`;
