import { gql, useMutation } from "@apollo/client";
import React from "react";
import { appToast, errorToast } from "src/utils/toast";

const DELETE_ZOOM_MEETING = gql`
  mutation deleteZoomMeeting($meetingId: String!) {
    deleteZoomMeeting(meeting_id: $meetingId)
  }
`;

const DELETE_GOOGLE_MEETING = gql`
  mutation deleteGoogleInstantMeeting($meetingId: String!) {
    deleteGoogleInstantMeeting(meeting_id: $meetingId)
  }
`;

const useDeleteOTFMeeting = () => {
  const [deleteZoomMeeting, { loading: deleteZoomMeetingLoading, error: deleteZoomMeetingError }] = useMutation(
    DELETE_ZOOM_MEETING,
    {
      onCompleted: () => {
        appToast("On-the-fly meeting deleted successfully.");
      },
      onError: (error) => {
        errorToast(error.message);
      },
      refetchQueries: ["fetchCachedInstantMeeting"],
    },
  );

  const [deleteGoogleMeeting, { loading: deleteGoogleMeetingLoading, error: deleteGoogleMeetingError }] = useMutation(
    DELETE_GOOGLE_MEETING,
    {
      onCompleted: () => {
        appToast("On-the-fly meeting deleted successfully.");
      },
      onError: (error) => {
        errorToast(error.message);
      },
      refetchQueries: ["fetchCachedInstantMeeting"],
    },
  );

  const onDeleteMeeting = ({ meetingType, meetingId }: { meetingType: string; meetingId: string }) => {
    if (meetingType === "Zoom") {
      deleteZoomMeeting({
        variables: {
          meetingId: meetingId,
        },
      });
    }

    if (meetingType === "Google") {
      deleteGoogleMeeting({
        variables: {
          meetingId: meetingId,
        },
      });
    }
  };

  return {
    onDeleteMeeting,
    loading: deleteZoomMeetingLoading || deleteGoogleMeetingLoading,
    error: deleteZoomMeetingError || deleteGoogleMeetingError,
  };
};

export default useDeleteOTFMeeting;
