import { gql, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { AppText, Loading } from "../../../UI";
import { LeadCustomEmptyPage } from "./LeadCustomFieldEmptyTable";
import { UpdateCustomObject } from "../../../modal/UpdateCustomObject";
import { ModalContext } from "../../../../context";
import { PhoenixAppButton } from "../../../UI/Phoenix";

interface DisappearingDivProps {
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  togglePageTitle?: () => void;
}

const FETCH_ALL_CUSTOM_OBJECT = gql`
  query fetchAllCustomObject {
    fetchAllCustomObject {
      id
      name
    }
  }
`;

const FETCH_CUSTOM_OBJECT_ROW_COUNT = gql`
  query fetchCustomObjectRowCount {
    fetchCustomObjectRowCount {
      custom_object_id
      count
    }
  }
`;

const CustomObjectManager: React.FC<DisappearingDivProps> = ({ blinds, setBlinds, togglePageTitle }) => {
  const { customObjectModalData, setCustomObjectModalData } = useContext(ModalContext);

  const [customObjects, setCustomObjects] = useState<{ id: string; name: string }[]>([]);
  const history = useHistory();

  const { data: dataCustomObjects, loading: loadingCustomObjects, error: errorCustomObjects } = useQuery(
    FETCH_ALL_CUSTOM_OBJECT,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const {
    data: dataCustomObjectsRowCount,
    loading: loadingCustomObjectsRowCount,
    error: errorCustomObjectsRowCount,
  } = useQuery(FETCH_CUSTOM_OBJECT_ROW_COUNT, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const fetchObjectFields = () => {
    return customObjects?.map((field, idx) => {
      const rowCountData = dataCustomObjectsRowCount?.fetchCustomObjectRowCount.find(
        (row: { custom_object_id: string }) => row.custom_object_id === field.id,
      );
      const displayCount = rowCountData ? `(${rowCountData.count})` : loadingCustomObjectsRowCount ? <Loading /> : null;
      return (
        <React.Fragment key={`${field.id}-idx`}>
          <ButtonContainerAbs>
            <PhoenixAppButton
              buttonType="secondary"
              onClick={() => {
                setCustomObjectModalData({
                  id: "",
                  delete_modal_on: false,
                  name_modal_on: true,
                  label: "",
                });
              }}
              style={{ alignSelf: "center" }}
            >
              ADD CUSTOM OBJECT
            </PhoenixAppButton>
          </ButtonContainerAbs>

          <CustomObjectContainer>
            <CustomObjectDiv>
              {field.name} {displayCount}
            </CustomObjectDiv>
            <CustomObjectDiv
              onClick={() => {
                history.push(`/system-config/object-manager/${field.id}`);
              }}
            >
              <ManageText>MANAGE</ManageText>
            </CustomObjectDiv>
          </CustomObjectContainer>
        </React.Fragment>
      );
    });
  };

  useEffect(() => {
    if (dataCustomObjects?.fetchAllCustomObject) {
      setCustomObjects(dataCustomObjects?.fetchAllCustomObject);
    }
  }, [dataCustomObjects]);

  const renderModal = () => {
    if (customObjectModalData.name_modal_on) {
      return (
        <UpdateCustomObject
          blinds={customObjectModalData.name_modal_on}
          close={() => {
            setCustomObjectModalData({
              id: "",
              delete_modal_on: false,
              name_modal_on: false,
              label: "",
            });
          }}
        />
      );
    }
  };
  const renderEntities = () => {
    if (loadingCustomObjects) {
      return <Loading />;
    }

    if (!customObjects.length) {
      return (
        <LeadCustomEmptyPage
          blinds={blinds}
          defaultField={"Custom Object"}
          setBlinds={setBlinds}
          type={"Custom Object"}
        />
      );
    } else {
      return fetchObjectFields();
    }
  };
  return (
    <>
      <Container>
        {renderModal()}
        <LeadObjectFieldData>{renderEntities()}</LeadObjectFieldData>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  align-self: stretch;
  background: ${theme.surface.brand.secondary};
  min-height: 888px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-top: px solid ${theme.NEUTRAL200};
`;

const ButtonContainerAbs = styled.div`
  position: absolute;
  width: 176px;
  right: 48px;
  top: 48px;
`;

const LeadObjectFieldData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  margin: 24px;
`;

const CustomObjectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  margin: 8px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--border-neutral-secondary, #e1e2e7);
  width: 33%;
  align-self: flex-start;
  background: ${theme.WHITE_COLOR};
`;
const CustomObjectDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const ManageText = styled(AppText)`
  font-weight: 600;
  color: ${theme.PRIMARY500};
  border-bottom: none;
  cursor: pointer;
`;

export { CustomObjectManager };
