import React, { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import ReactTooltip from "react-tooltip";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { loggedInUser } from "../../../../apollo/cache";
import { AppText } from "../../../UI";
import { formatBusinessName, formatDateTime } from "../../../../utils/format";
import { LeadsTableContext, LeadsTableContextType } from "./LeadsTableContext";
import { LeadsTableCustomHeader } from "./LeadsTableCustomHeader";
import { ACTIONS_LABEL } from "./shared";
import { PhoenixCheckbox } from "../../../UI/Phoenix";

export const LEAD_DATA_TO_NEVER_DISPLAY = ["id", "hidden_status", "hidden_rep_id"];

const getHeaderWidth = (header: string) => {
  switch (header) {
    case ACTIONS_LABEL:
      return 40;
    case "Dials: Lifetime":
      return 110;
    case "Dials: Current Sales Cycle":
      return 160;
    default:
      return 140;
  }
};

const highlightText = (text: string, highlight: string) => {
  if (!text || !highlight) {
    return null;
  }

  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const truncateLength = 25;
  const regex = new RegExp(`(${highlight})`, "gi");
  const matchIndex = text?.toLowerCase()?.indexOf(highlight?.toLowerCase());

  if (matchIndex === -1) {
    return <span>{text}</span>;
  }

  const start = Math.max(0, matchIndex - truncateLength);
  const end = Math.min(text.length, matchIndex + highlight.length + truncateLength);
  const truncatedText = text.slice(start, end);
  const parts = truncatedText.split(regex);

  return (
    <span>
      {start > 0 && "..."}
      {parts.map((part, i) =>
        part?.toLowerCase() === highlight?.toLowerCase() ? (
          <strong key={i} style={{ color: theme.PRIMARY500, fontWeight: "bold" }}>
            {part}
          </strong>
        ) : (
          <span key={i}>{part}</span>
        ),
      )}
      {end < text.length && "..."}
    </span>
  );
};

const renderNotes = (value: string, highlight: string) => {
  return <GridTDText>{highlightText(value, highlight)}</GridTDText>;
};

const renderItem = ({
  key,
  value,
  id,
  ITEMS_TO_FILTER,
}: {
  key: string;
  value: string;
  id?: string;
  ITEMS_TO_FILTER: string[];
}) => {
  if (ITEMS_TO_FILTER.includes(key)) {
    return null;
  } else {
    switch (key) {
      case "Dials: Lifetime":
      case "Dials: Current Sales Cycle":
        return <GridTDText style={{ color: theme.PRIMARY500, fontWeight: 600 }}>{value}</GridTDText>;

      case "Business Name":
        return (
          <a target="_blank" href={`/lead-detail/${id}`}>
            <GridTDText>{formatBusinessName(value)}</GridTDText>
          </a>
        );
      case "Rep":
        return <GridTDText>{value || "Not Assigned"}</GridTDText>;
      case "Last Call Result Time":
      case "Lead Creation Date":
      case "Last Lead Assignment Origin Time":
        return <GridTDText>{!!value ? formatDateTime(value) : ""}</GridTDText>;
      case "Next Scheduled Event":
        return <GridTDText>{!!value ? formatDateTime(value) : ""}</GridTDText>;
      case "Remaining days of ownership":
        return <GridTDText>{value || "N/A"}</GridTDText>;

      case "Status":
      case "First Name":
      case "Last Name":
      case "City":
      case "State":
      case "Industry":
      case "Sub_industry":
      case "Lead Source":
      case "Last Call Result":
      case "Last Call Result Rep":
      case "Timezone":
      case "Phase":
      case "Lead Creation Source":
      case "Primary Email":
      case "Primary Phone Number":
      case "Zip":
      case "Country":
      default:
        const isLink = new RegExp(
          "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?",
        ).test(value);

        return !!isLink ? (
          <GridTDText>
            <a href={value.startsWith("http") ? value : `http://${value}`} target="_blank">
              {value}
            </a>
          </GridTDText>
        ) : (
          <GridTDText>
            {typeof value === "boolean" ? (value ? "true" : "false") : Array.isArray(value) ? value.join(",") : value}
          </GridTDText>
        );
    }
  }
};

const LeadsTable = ({
  getHeaderLabel,
  getTitle,
  handleAllSelected,
  inactionableStatuses,
  isAllSelected,
  items,
  leadSort,
  leadSortAscending,
  loadingTableAndHeaders,
  mrrLabel,
  nonSortableColumns,
  selectedLeadIds,
  setLeadSort,
  setLeadSortAscending,
  setSelectedLeadIds,
  userSavedHeaders,
  notesFilter = "",
}: {
  inactionableStatuses: string[];
  items: any[];
  selectedLeadIds: string[];
  setSelectedLeadIds: (selectedLeadIds: string[]) => void;
  userSavedHeaders: any[];
  notesFilter?: string;
} & LeadsTableContextType) => {
  const columnDefs = [ACTIONS_LABEL, ...userSavedHeaders]?.map((header: any) => {
    const isActionsHeader = ACTIONS_LABEL === header;

    return {
      headerName: header,
      field: header,
      tooltipField: header,
      resizable: !isActionsHeader,
      maxWidth: isActionsHeader ? 50 : 450,
      minWidth: isActionsHeader ? 50 : getHeaderWidth(header),
      suppressSizeToFit: true,
      sortable: false,
      pinned: isActionsHeader,
      cellRendererFramework: (params: any) => {
        const item = params.data;

        if (isActionsHeader) {
          const isRoleAEorSDR = loggedInUser().role === "AE" || loggedInUser().role === "SDR";
          const isOwnedByOther = item?.hidden_status === "Owned" && item?.hidden_rep_id !== loggedInUser().id;
          const isVisibleAllLeadsSM = loggedInUser()?.visible_all_leads_sm;

          const isCheckboxDisabled =
            (isRoleAEorSDR && (inactionableStatuses.includes(item?.hidden_status) || isOwnedByOther)) ||
            isVisibleAllLeadsSM;
          return (
            <div>
              {!!isCheckboxDisabled && (
                <ReactTooltip
                  multiline
                  place="right"
                  effect="solid"
                  backgroundColor={theme.PRIMARY800}
                  getContent={(dataTip) => (
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "10px",
                        lineHeight: "14px",
                      }}
                    >
                      {dataTip}
                    </span>
                  )}
                />
              )}

              <PhoenixCheckbox
                data-tip={
                  isCheckboxDisabled
                    ? loggedInUser()?.visible_all_leads_sm
                      ? `Sales Managers cannot perform this action`
                      : item?.hidden_status === "Owned"
                      ? `Cannot perform this action because this lead is owned by another rep`
                      : `Cannot perform an action on a lead in ${item?.hidden_status} status`
                    : null
                }
                checked={selectedLeadIds.includes(item.id)}
                onChange={() => {
                  selectedLeadIds.includes(item.id)
                    ? setSelectedLeadIds(selectedLeadIds.filter((val: string) => val !== item.id))
                    : setSelectedLeadIds([...selectedLeadIds, item.id]);
                }}
                disabled={isCheckboxDisabled}
              />
            </div>
          );
        }

        const key = getTitle(header, mrrLabel);
        const data = item?.[getTitle(header, mrrLabel)] || item?.[header];

        if (key === "Notes") {
          return renderNotes(data, notesFilter);
        }

        return renderItem({
          key: getTitle(header, mrrLabel),
          value: data,
          id: item.id,
          ITEMS_TO_FILTER: LEAD_DATA_TO_NEVER_DISPLAY,
        });
      },
    };
  });

  const tableRows = items?.map((item: any) => {
    return {
      ...item,
    };
  });

  const components = useMemo(() => {
    return {
      agColumnHeader: LeadsTableCustomHeader,
    };
  }, []);

  return (
    <LeadsTableContext.Provider
      value={{
        isAllSelected,
        loadingTableAndHeaders,
        handleAllSelected,
        leadSort,
        leadSortAscending,
        setLeadSort,
        setLeadSortAscending,
        nonSortableColumns,
        getHeaderLabel,
        getTitle,
        mrrLabel,
      }}
    >
      <div className="ag-theme-leads-table" style={{ width: "100%", height: "100%" }}>
        <AgGridReact
          defaultColDef={{
            cellStyle: {
              alignItems: "center",
              display: "inline-flex",
              height: "40px",
            },
          }}
          rowData={tableRows}
          enableCellTextSelection
          ensureDomOrder
          enableRangeSelection
          columnDefs={columnDefs}
          animateRows={true}
          components={components}
          onGridSizeChanged={(params: any) => {
            params.api.sizeColumnsToFit();
          }}
        />
      </div>
    </LeadsTableContext.Provider>
  );
};

export default LeadsTable;

const GridTDText = styled(AppText)`
  cursor: pointer;
  font-size: 10px;
  letter-spacing: 0.2px;
  line-height: 15px;
  overflow: hidden;
  padding: 0;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  word-break: break-all;
`;
