import { gql, useQuery } from "@apollo/client";
import * as React from "react";
import { useContext, useState } from "react";
import styled from "styled-components";
import { GridFilterContext, LeadFilterContext, ModalContext, LeadCardContext } from "../../context";
import { newCloseModal, showMoreArrow } from "../../images";
import { countryCodes } from "../../static/countries";
import { theme } from "../../utils/theme";
import { AppText, Loading } from "../UI";
import { AppCheckbox } from "../UI/AppCheckbox";
import { NewAppButton } from "../UI/NewAppButton";
import { info } from "../../images/NewDesign";
import { PhoenixIcon } from "../UI/Phoenix";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";
import { cloneDeep } from "lodash";
import { PHASES_OPTIONS, PIPELINE_OPTIONS } from "../Segments/LeadsFilterV2/shared";
import { formatPipelineStageType } from "src/utils/misc";

// Queries
const GET_LEAD_SOURCE_OPTIONS = gql`
  query getUniqueLeadSources {
    getUniqueLeadSources
  }
`;

const GET_STATE_OPTIONS = gql`
  query getStateOptions {
    getStateOptions
  }
`;

const GET_CITY_OPTIONS = gql`
  query getCityOptions {
    getCityOptions
  }
`;

const GET_TIMEZONE_OPTIONS = gql`
  query getTZOptions {
    getTZOptions
  }
`;

const GET_INDUSTRY_OPTIONS = gql`
  query getUniqueIndustries {
    getUniqueIndustries
  }
`;

const FETCH_SEQUENCES = gql`
  query fetchSequences($sequencesFilter: SequencesFilter!) {
    fetchSequences(SequencesFilter: $sequencesFilter) {
      id
      name
      sequence_entry_criteria {
        id
        current_phase
      }
    }
  }
`;

const LeadsFiltersComponent: React.FC = () => {
  // States for whether each filer is expanded
  const [expanded, setExpanded] = useState([] as string[]);

  // Context for filters
  const { leadSources, setLeadSources } = useContext(GridFilterContext);
  const { showFiltersModal, setShowFiltersModal } = useContext(ModalContext);
  const { upcomingDials } = useContext(LeadCardContext);
  const { leadFilter, setLeadFilter, setSelected, selected, resetLeadQueueFilters } = useContext(LeadFilterContext);

  const [tempLeadFilter, setTempLeadFilter] = useState(leadFilter);
  const [tempSelected, setTempSelected] = useState(selected);

  //Queries
  const { data: leadSourceOptions, error: leadSourceError, loading: leadSourceLoading } = useQuery(
    GET_LEAD_SOURCE_OPTIONS,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );
  const { data: industryOptions, error: industryError, loading: industryLoading } = useQuery(GET_INDUSTRY_OPTIONS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });
  //const { data: cityOptions, error: cityErroy, loading: cityLoading } = useQuery(GET_CITY_OPTIONS);
  const { data: stateOptions, error: stateError, loading: stateLoading } = useQuery(GET_STATE_OPTIONS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });
  const { data: tzOptions, error: tzError, loading: tzLoading } = useQuery(GET_TIMEZONE_OPTIONS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataSequences, loading: loadingSequences, error: errorSequences } = useQuery(FETCH_SEQUENCES, {
    fetchPolicy: "cache-and-network",
    // sequenceData can be passed into modal. If no sequenceData is passed we will fetch it
    skip: false,
    variables: {
      sequencesFilter: {},
    },
    onCompleted({ fetchSequences }) {
      console.log("fetchSequences:", fetchSequences);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  //TODO: Add city loading back in.
  const loading = leadSourceLoading || industryLoading || stateLoading || tzLoading || loadingSequences;
  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );

  // Custom Data

  const leadSourceOptionsArray = leadSourceOptions.getUniqueLeadSources?.map((item: any) => item.label);
  const industryOptionsArray = industryOptions.getUniqueIndustries?.map((item: any) => item.label);
  const countryOptionsArray = countryCodes?.map((item: any) => item.iso2);

  const sequencesTasksInActiveQueue = new Set();
  const sequencesInActiveQueue = new Set();

  upcomingDials.forEach((item) => {
    if (item.type === "SequenceAction") {
      sequencesTasksInActiveQueue.add(item?.lead?.sequence_step?.actions[0]?.task);
      sequencesInActiveQueue.add(item?.lead?.sequence_id);
    }
  });

  const sequencesOptionsArray = dataSequences?.fetchSequences
    ?.filter((item: any) => sequencesInActiveQueue.has(item.id))
    .map((item: any) => item.name);

  const sequenceTaskTypesArray = [
    { value: "customTask", label: "Custom Task" },
    { value: "manualCall", label: "Manual Call" },
    { value: "manualEmail", label: "Manual Email" },
    { value: "manualSms", label: "Manual SMS" },
    // { value: "automatedEmail", label: "Automated Email" },
    // { value: "automatedSms", label: "Automated SMS" },
    { value: "requestManagerFeedback", label: "Request Manager Feedback" },
  ].filter((item) => sequencesTasksInActiveQueue.has(item.value));

  const sequencesToDisplay = dataSequences?.fetchSequences?.filter((item: any) => sequencesInActiveQueue.has(item.id));

  // Custom Functions
  const mapOptionsToKey = (key: string) => {
    switch (key) {
      case "types":
        return ["ColdCall", "CallBack", "DemoNotHeldCallBack", "DemoHeldCallBack", "AutomaticCallBack", "HotLead"];
      case "lead_sources":
        return leadSourceOptionsArray;
      case "lead_assignment_origin":
        return ["OpsiqAssigned", "SelfSourced", "Claimed", "EnablementAssigned", "LeadRoutingRule", "Other"];
      case "channels":
        return ["Inbound", "Outbound"];
      case "industries":
        return industryOptionsArray;
      // case "cities":
      //   return cityOptions.getCityOptions;
      case "states":
        return stateOptions.getStateOptions;
      case "countries":
        return countryOptionsArray;
      case "timezones":
        return tzOptions.getTZOptions;
      case "sequences":
        return sequencesOptionsArray;
      case "sequence_tasks_types":
        return sequenceTaskTypesArray;
      case "PDV":
        return ["$0.00-$0.99", "$1.00-$1.99", "$2.00-$2.99", "$3.00+"];
      case "phases":
        return PHASES_OPTIONS.map((v) => v.value);
      case "pipeline_stages":
        return PIPELINE_OPTIONS.map((v) => v.value).filter((v) => v !== "DoNotContact");
      default:
        break;
    }
  };

  const mapLabelToKey = (key: string) => {
    switch (key) {
      case "types":
        return "Types";
      case "lead_sources":
        return "Lead Sources";
      case "lead_assignment_origin":
        return "Lead Origin";
      case "channels":
        return "Channel (Source Type)";
      case "industries":
        return "Industry";
      // case "cities":
      //   return cityOptions.getCityOptions;
      case "states":
        return "State";
      case "countries":
        return "Country";
      case "sequences":
        return "Sequences";
      case "sequence_tasks_types":
        return "Sequence Task Types";
      case "phone_country_codes":
        return "Country Code Primary Phone";
      case "timezones":
        return "Timezone";
      case "phases":
        return "Phases";
      case "pipeline_stages":
        return "Pipeline Stages";
      // case "PDV":
      //   return ["$0.00-$0.99", "$1.00-$1.99", "$2.00-$2.99", "$3.00+"];
      default:
        break;
    }
  };

  const formatLeadOriginType = (name: string) => {
    switch (name) {
      case "OpsiqAssigned":
        return "Sellfire Assigned";

      case "SelfSourced":
        return "Self-Sourced";

      case "Claimed":
        return "Claimed";

      case "EnablementAssigned":
        return "Enablement Assigned";

      case "LeadRoutingRule":
        return "Lead Routing Rule";

      case "Other":
        return "Other";

      default:
        return name;
    }
  };

  const formatFilterName = (key: string, name: string) => {
    if (key === "lead_assignment_origin") return formatLeadOriginType(name);
    if (key === "pipeline_stages") return formatPipelineStageType(name);
    if (key !== "types") return name;
    const array = name.match(/[A-Z][a-z]+/g);
    return array?.join(" ") ?? name;
  };

  const handleCheckboxClick = (key: string, item: string | number) => {
    if (item === "HotLead" && tempLeadFilter.hot_leads) {
      setTempLeadFilter({
        ...tempLeadFilter,
        hot_leads: false,
      });
      const newSelected = tempSelected.filter((filter: any) => filter.item !== item);
      setTempSelected(newSelected);
      return;
    }
    if (tempLeadFilter[key]?.includes(item)) {
      const filterKeyArray = tempLeadFilter[key]?.filter((selected: string) => {
        return selected !== item;
      });
      const newSelected = tempSelected.filter((filter: any) => filter.item !== item);
      setTempSelected(newSelected);
      setTempLeadFilter({
        ...tempLeadFilter,
        [key]: filterKeyArray,
      });
    } else {
      setTempSelected([...tempSelected, { key: key, item: item }]);
      setTempLeadFilter({
        ...tempLeadFilter,
        [key]: [...(tempLeadFilter[key] ?? []), item],
      });
    }
  };

  const handleCheckboxClickSequences = (key: string, item: { id: string; name: string }) => {
    if (tempLeadFilter[key].map((o: any) => o.value)?.includes(item.id)) {
      const filterKeyArray = tempLeadFilter[key]?.filter((selected: { value: string; label: string }) => {
        return selected.value !== item.id;
      });
      const newSelected = tempSelected.filter((filter: { item: { value: string } }) => filter.item.value !== item.id);
      setTempSelected(newSelected);
      setTempLeadFilter({
        ...tempLeadFilter,
        [key]: filterKeyArray,
      });
    } else {
      setTempSelected([...tempSelected, { key: key, item: { label: item?.name, value: item?.id } }]);
      setTempLeadFilter({
        ...tempLeadFilter,
        [key]: [...(tempLeadFilter[key] ?? []), { value: item?.id, label: item?.name }],
      });
    }
  };

  // Custom Data
  const filterOptionsArray = Object.keys(tempLeadFilter).filter((key) => key !== "phases");

  return (
    <EditCardDiv>
      <PhoenixStyledTooltip id="lead-filter-timezone-tooltip" place="right" effect="solid" />
      <TitleDiv>
        <TitleText>Filters</TitleText>
        <CloseButton>
          <NewAppButton
            // small
            // borderless
            onClick={() => {
              setShowFiltersModal(!showFiltersModal);
            }}
          >
            <img src={newCloseModal} alt="Close" />
          </NewAppButton>
        </CloseButton>
      </TitleDiv>
      <ScrollDiv>
        {filterOptionsArray?.map((key: string) => {
          const optionsArray = mapOptionsToKey(key);
          return (
            <SectionContainerDiv key={key}>
              <SectionRow>
                <SectionTitle>
                  <AppText style={{ borderBottom: "none" }}>
                    {mapLabelToKey(key)} &nbsp;
                    {!!leadSources && !!leadSources.length && (
                      <span style={{ fontWeight: 600 }}>({leadSources?.length})</span>
                    )}
                  </AppText>
                  {key === "timezones" && (
                    <PhoenixIcon
                      svg={info}
                      variant="brand"
                      size={14}
                      data-for="lead-filter-timezone-tooltip"
                      data-tip={`Leads are hidden when it's before 8 am or after 5 pm in their local timezone.
                        Apply filters to ignore timezone limits. `}
                    />
                  )}
                </SectionTitle>
                <div>
                  <NewAppButton
                    // small
                    // borderless
                    onClick={() =>
                      expanded?.includes(key)
                        ? setExpanded(expanded.filter((item) => item !== key))
                        : setExpanded([...expanded, key])
                    }
                  >
                    {expanded?.includes(key) ? (
                      <img src={showMoreArrow} style={{ transform: "rotate(180deg)" }} alt="Minimize" />
                    ) : (
                      <img src={showMoreArrow} alt="Expand" />
                    )}
                  </NewAppButton>
                </div>
              </SectionRow>

              <PaddingAndScrollDiv>
                {expanded?.includes(key) &&
                  (key === "sequences" ? (
                    <>
                      {sequencesToDisplay?.length > 0 ? (
                        sequencesToDisplay?.map((item: any) => {
                          return (
                            <FilterOptionsContainer>
                              <AppCheckbox
                                title={item.name}
                                checked={tempLeadFilter[key].map((o: any) => o.value)?.includes(item.id)}
                                onClick={() => {
                                  handleCheckboxClickSequences(key, item);
                                }}
                              />
                            </FilterOptionsContainer>
                          );
                        })
                      ) : (
                        <FilterOptionsContainer>
                          <AppText style={{ paddingBottom: 16 }}>
                            None of your leads are actively in a sequence.
                          </AppText>
                        </FilterOptionsContainer>
                      )}
                    </>
                  ) : key === "sequence_tasks_types" ? (
                    <>
                      {sequenceTaskTypesArray?.length > 0 ? (
                        sequenceTaskTypesArray?.map((item: any) => {
                          return (
                            <FilterOptionsContainer>
                              <AppCheckbox
                                title={item.label}
                                checked={tempLeadFilter[key]?.includes(item.value)}
                                onClick={() => {
                                  handleCheckboxClick(key, item.value);
                                }}
                              />
                            </FilterOptionsContainer>
                          );
                        })
                      ) : (
                        <FilterOptionsContainer>
                          <AppText style={{ paddingBottom: 16 }}>
                            None of your leads actively have a sequence task type.
                          </AppText>
                        </FilterOptionsContainer>
                      )}
                    </>
                  ) : (
                    optionsArray?.map((item: string) => {
                      return (
                        <FilterOptionsContainer>
                          <AppCheckbox
                            title={formatFilterName(key, item)}
                            checked={
                              tempLeadFilter[key]?.includes(item) || (item === "HotLead" && tempLeadFilter.hot_leads)
                            }
                            onClick={() => {
                              handleCheckboxClick(key, item);
                            }}
                          />
                        </FilterOptionsContainer>
                      );
                    })
                  ))}
              </PaddingAndScrollDiv>
            </SectionContainerDiv>
          );
        })}
      </ScrollDiv>

      <SubmitDiv>
        <NewAppButton
          onClick={() => {
            resetLeadQueueFilters();
            // setTempLeadFilter(leadFilter);
            // setSelected(selected);
            setShowFiltersModal(!showFiltersModal);
          }}
        >
          Reset Filters
        </NewAppButton>
        <NewAppButton
          variant={"primary"}
          onClick={() => {
            // HotLeads filter is placed in the types list in the UI but is not an actual type so it needs to be seperated.
            let newTempLead = cloneDeep(tempLeadFilter);
            if (newTempLead.types?.includes("HotLead")) {
              newTempLead = {
                ...newTempLead,
                types: tempLeadFilter.types.filter((type: string) => type !== "HotLead"),
                hot_leads: true,
              };
            }
            setLeadFilter(newTempLead);
            setSelected(tempSelected);
            setShowFiltersModal(!showFiltersModal);
          }}
          disabled={JSON.stringify(tempLeadFilter) === JSON.stringify(leadFilter)}
        >
          Apply {!!tempSelected.length && `(${tempSelected.length})`} Filters
        </NewAppButton>
      </SubmitDiv>
    </EditCardDiv>
  );
};

const ScrollDiv = styled.div`
  max-height: calc(100vh - 156px);
  height: calc(100vh - 156px);
  overflow-y: auto;
`;

const SubmitDiv = styled.div`
  position: absolute;
  height: 80px;
  bottom: 20px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const PaddingAndScrollDiv = styled.div`
  /* padding-bottom: 20px; */
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 240px;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const FilterOptionsContainer = styled.div``;

const SectionTitle = styled.div`
  display: flex;
  margin: 0px;
  height: 56px;
  align-items: center;
`;

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SectionContainerDiv = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  max-height: 300px;
`;

const EditCardDiv = styled.div`
  z-index: 15;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 25px;
  top: 7px;
  /* background: ${theme.NEUTRAL100}; */
  /* border-radius: 50%; */
  cursor: pointer;
  /* padding: 3px; */
  z-index: 5;
`;

export { LeadsFiltersComponent };
