import React from "react";
import styled from "styled-components";
import { FlexDiv, SkeletonBlock, AppText } from "../../../UI";
import { theme } from "../../../../utils/theme";

export const EmailSettingsTabSkeleton: React.FC = () => {
  return (
    <Body>
      {/* Header skeleton */}
      <HeaderContainer>
        <SkeletonBlock width={150} height={32} borderRadius={4} />
        <ButtonsContainer>
          <SkeletonBlock width={80} height={36} borderRadius={4} delayNumber={1} />
          <SkeletonBlock width={80} height={36} borderRadius={4} delayNumber={2} />
        </ButtonsContainer>
      </HeaderContainer>

      <FlexDiv direction="column" gap={40} style={{ minHeight: "150px" }}>
        <FlexDiv direction="column" gap={16}>
          <AppText fontSize={22} fontWeight={500} lineHeight={28} noWrap>
            Rep Email Address Format
          </AppText>
        </FlexDiv>

        <FlexDiv gap={40} align="center">
          <InputDomainContainer width={525} justify="flex-start">
            <SkeletonBlock width={350} height={48} borderRadius={4} />
            <SkeletonBlock width={120} height={24} borderRadius={4} />
          </InputDomainContainer>
          <SkeletonBlock width={200} height={270} borderRadius={4} />
        </FlexDiv>
      </FlexDiv>

      <FlexDiv direction="column" gap={40}>
        {/* Title and description skeleton */}
        <FlexDiv direction="column" gap={16}>
          <AppText fontSize={22} fontWeight={500} lineHeight={28} noWrap>
            Email Settings
          </AppText>
          <AppText fontSize={12} fontWeight={400} lineHeight={18}>
            Configure the text and styling of your organization's email signatures.
          </AppText>
        </FlexDiv>

        {/* Main content area */}
        <FlexDiv gap={40}>
          {/* Template editor skeleton */}
          <FlexDiv direction="column" gap={16}>
            <EditorSkeleton />
          </FlexDiv>

          {/* Tokens container skeleton */}
          <TokenContainerSkeleton />
        </FlexDiv>
      </FlexDiv>
    </Body>
  );
};

const EditorSkeleton: React.FC = () => {
  return (
    <EditorContainer>
      {/* Toolbar skeleton */}
      <ToolbarSkeleton>
        <FlexDiv gap={8}>
          {[...Array(6)].map((_, index) => (
            <SkeletonBlock
              key={`toolbar-item-${index}`}
              width={24}
              height={24}
              borderRadius={4}
              delayNumber={5 + index}
            />
          ))}
        </FlexDiv>
      </ToolbarSkeleton>

      {/* Editor content skeleton */}
      <EditorContentSkeleton>
        <SkeletonBlock width="90%" height={16} borderRadius={4} delayNumber={11} />
        <SkeletonBlock width="70%" height={16} borderRadius={4} delayNumber={12} />
        <SkeletonBlock width="85%" height={16} borderRadius={4} delayNumber={13} />
        <SkeletonBlock width="40%" height={16} borderRadius={4} delayNumber={14} />
      </EditorContentSkeleton>
    </EditorContainer>
  );
};

const TokenContainerSkeleton: React.FC = () => {
  return (
    <TokenContainerWrap>
      <TokenWrap>
        {/* Token category skeletons */}
        {[...Array(4)].map((_, categoryIndex) => (
          <TokenCategorySkeleton key={`token-category-${categoryIndex}`} delayNumber={15 + categoryIndex * 3} />
        ))}
      </TokenWrap>
    </TokenContainerWrap>
  );
};

const TokenCategorySkeleton: React.FC<{ delayNumber: number }> = ({ delayNumber }) => {
  return (
    <TokenCategoryContainer>
      <SkeletonBlock width={120} height={20} borderRadius={4} delayNumber={delayNumber} />
      <FlexDiv gap={8} style={{ marginTop: "8px" }}>
        <SkeletonBlock width={100} height={28} borderRadius={4} delayNumber={delayNumber + 1} />
        <SkeletonBlock width={120} height={28} borderRadius={4} delayNumber={delayNumber + 2} />
      </FlexDiv>
    </TokenCategoryContainer>
  );
};

const Body = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const EditorContainer = styled.div`
  width: 520px;
  border: 1px solid ${theme.border.neutral.secondary};
  border-radius: 8px;
  overflow: hidden;
`;

const ToolbarSkeleton = styled.div`
  padding: 8px;
  border-bottom: 1px solid ${theme.border.neutral.secondary};
  background-color: ${theme.NEUTRAL100};
`;

const EditorContentSkeleton = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 200px;
`;

const TokenContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 280px;
  padding: 16px;
  border: 1px solid ${theme.border.neutral.secondary};
  border-radius: 8px;
`;

const TokenWrap = styled.div`
  max-height: 270px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TokenCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputDomainContainer = styled(FlexDiv)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 4px;
  min-width: 350px;
`;
