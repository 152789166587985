import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { client } from "./apollo";
import { loggedInUser } from "./apollo/cache";
import "./App.css";
import { StyledCloseToast } from "./Components/Dumb/StyledToast";
import { LDWrapper } from "./Components/Segments/LDWrapper";
import GlobalFonts from "./fonts/Fonts";
import AppRouter from "./routers/AppRouter";
import { initMixpanel } from "./services/mixpanel";
import { setSentry } from "./services/sentry";
import { LAUNCH_DARKLY_KEY } from "./utils/variables";
import { useBreakingDeployTimestamp } from "./utils/hooks";

function App() {
  // ldClient?.alias({anonymous: false, country: loggedInUser()., email: loggedInUser().email, firstName: loggedInUser().first_name, lastName: loggedInUser().last_name, name: `${loggedInUser().first_name} ${loggedInUser().last_name}` }, old_user)

  useBreakingDeployTimestamp();

  useEffect(() => {
    // Add sentry user and context
    initMixpanel();
    setSentry();
  }, [loggedInUser()?.id]);

  return (
    <Sentry.ErrorBoundary>
      <ApolloProvider client={client}>
        <LDWrapper>
          <StyledContainer closeButton={({ closeToast }) => <StyledCloseToast closeToast={closeToast} />} />
          <GlobalFonts />
          <AppRouter />
        </LDWrapper>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}

const StyledContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  right: 1em;
  .Toastify__toast--default {
    padding: 0;
    min-height: 64px;
    // not relevant to the toast itself but necessary to ensure the toast isn't cut off
    width: 312px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }
`;

export default withLDProvider({ clientSideID: LAUNCH_DARKLY_KEY, options: { bootstrap: "localStorage" } })(App);
