import React, { useContext, useCallback, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import debounce from "lodash/debounce";
import { AppText, FlexDiv } from "src/Components/UI";
import { PhoenixCheckbox, PhoenixIcon } from "src/Components/UI/Phoenix";
import { LeadFilterContext } from "src/context";
import { useModalContext } from "src/utils/hooks";
import { edit, star, star_filled, trash } from "src/images/NewDesign";
import { MixpanelActions } from "src/services/mixpanel";
import { theme } from "src/utils/theme";
import { appToast, errorToast } from "src/utils/toast";

const UPDATE_SAVED_VIEW = gql`
  mutation createOrUpdateAllLeadsSavedViewFilter($allLeadsSavedViewFilterInput: AllLeadsSavedViewFilterInput!) {
    createOrUpdateAllLeadsSavedViewFilter(allLeadsSavedViewFilterInput: $allLeadsSavedViewFilterInput) {
      id
      is_default
      is_favorite
    }
  }
`;

const SavedViewDetails = ({ defaultView }: { defaultView: any }) => {
  const { savedView } = useContext(LeadFilterContext);
  const {
    setLeadFilterSavedViewModalData,
    setShowAddOrEditLeadFilterSavedViewModal,
    setShowDeleteLeadFilterSavedView,
    setShowSetLeadFilterSavedViewAsDefaultModal,
  } = useModalContext();
  const [localOverrides, setLocalOverrides] = useState<{ is_default: boolean | null; is_favorite: boolean | null }>({
    is_default: null,
    is_favorite: null,
  });

  const [updateAllLeadsSavedViewFilter, { loading }] = useMutation(UPDATE_SAVED_VIEW, {
    onCompleted({ createOrUpdateAllLeadsSavedViewFilter }) {
      const hasFavoriteChanged = localOverrides?.is_favorite !== null;
      const hasDefaultChanged = localOverrides?.is_default !== null;

      if (hasFavoriteChanged) {
        const newIsFavorite = createOrUpdateAllLeadsSavedViewFilter?.is_favorite;
        appToast(newIsFavorite ? "Saved view favorited" : "Saved view unfavorited");
        MixpanelActions.track(
          newIsFavorite ? "Lead filter saved view favorited" : "Lead filter saved view unfavorited",
        );
      }

      if (hasDefaultChanged) {
        const newIsDefault = createOrUpdateAllLeadsSavedViewFilter?.is_default;
        appToast(newIsDefault ? "Saved view marked as default" : "Saved view unmarked as default");
        MixpanelActions.track(
          newIsDefault ? "Lead filter saved view marked as default" : "Lead filter saved view unmarked as default",
        );
      }
      setLocalOverrides({ is_default: null, is_favorite: null });
    },
    onError({ message }) {
      errorToast(message);
      setLocalOverrides({ is_default: null, is_favorite: null });
      console.log("Error in updateAllLeadsSavedViewFilter: ", message);
    },
    refetchQueries: ["fetchAllLeadsSavedViewFilters"],
  });

  const debouncedUpdateView = useCallback(
    debounce((input: { id: string; name: string; is_default?: boolean; is_favorite?: boolean }) => {
      updateAllLeadsSavedViewFilter({
        variables: {
          allLeadsSavedViewFilterInput: input,
        },
      });
    }, 600),
    [updateAllLeadsSavedViewFilter],
  );

  const effectiveIsDefault = localOverrides?.is_default ?? savedView?.is_default;
  const effectiveIsFavorite = localOverrides?.is_favorite ?? savedView?.is_favorite;

  const toggleDefault = useCallback(() => {
    if (!effectiveIsDefault && defaultView) {
      setLeadFilterSavedViewModalData(savedView);
      setShowSetLeadFilterSavedViewAsDefaultModal(true);
      return;
    }

    setLocalOverrides((prev) => ({ ...prev, is_default: !(prev.is_default ?? savedView.is_default) }));
    debouncedUpdateView({
      id: savedView.id,
      name: savedView.name,
      is_default: !effectiveIsDefault,
    });
  }, [savedView, debouncedUpdateView, effectiveIsDefault]);

  const toggleFavorite = useCallback(() => {
    setLocalOverrides((prev) => ({ ...prev, is_favorite: !(prev.is_favorite ?? savedView.is_favorite) }));
    debouncedUpdateView({
      id: savedView.id,
      name: savedView.name,
      is_favorite: !effectiveIsFavorite,
    });
  }, [savedView, debouncedUpdateView, effectiveIsFavorite]);

  const handleEdit = () => {
    setLeadFilterSavedViewModalData(savedView);
    setShowAddOrEditLeadFilterSavedViewModal(true);
  };

  const handleDelete = () => {
    setShowDeleteLeadFilterSavedView(true);
    setLeadFilterSavedViewModalData(savedView);
  };

  if (!savedView) return null;

  return (
    <FlexDiv direction="column" gap={8}>
      <AppText color={theme.text.neutral.secondary} fontSize={10} letterSpacing={1} fontWeight={600}>
        SAVED VIEW
      </AppText>

      <FlexDiv align="center" gap={24}>
        <FlexDiv align="center" gap={8}>
          <PhoenixIcon
            color={theme.text.brand.primary}
            disabled={loading}
            fillIcon={effectiveIsFavorite}
            hoverColor={theme.text.brand.primary}
            onClick={toggleFavorite}
            size={16}
            svg={effectiveIsFavorite ? star_filled : star}
            variant="brand"
            pointer
          />

          <AppText fontSize={20} fontWeight={600}>
            {savedView.name}
          </AppText>
        </FlexDiv>
        <FlexDiv align="center" gap={8}>
          <PhoenixIcon onClick={handleEdit} svg={edit} size={16} pointer />
          <PhoenixIcon
            onClick={handleDelete}
            color={theme.PILL_DARK_RED}
            hoverColor={theme.PILL_DARK_RED}
            size={16}
            svg={trash}
            pointer
          />
          <FlexDiv align="center">
            <PhoenixCheckbox checked={effectiveIsDefault} disabled={loading} onChange={toggleDefault} />
            <AppText fontSize={12} fontWeight={500} lineHeight={18}>
              Set as default
            </AppText>
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    </FlexDiv>
  );
};

export default SavedViewDetails;
