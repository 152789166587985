import React, { useState, useRef, useCallback, useMemo, useEffect, useContext } from "react";
import { AppText } from "src/Components/UI";
import { PhoenixCheckbox, PhoenixMultiSelect, PhoenixRadio } from "src/Components/UI/Phoenix";
import { OptionItem, OrgBrands } from "src/types";
import { theme } from "src/utils/theme";
import DynamicDropdown, { DropdownMoreBadge } from "./DynamicDropdown";
import { checkIfBrandIsOnboarded, hasValue } from "src/utils/misc";
import { useOpenOnMoreFilter } from "src/utils/hooks";
import { gql, useQuery } from "@apollo/client";
import { FILTER_OPERATIONS_ARRAY } from "../SystemView/LeadSearchFIltersModal";
import { LeadFilterContext, ModalContext } from "src/context";
import styled from "styled-components";
import { useMultiBrands } from "src/cache-hooks/useIsMultiBrand";
import { useFlags } from "launchdarkly-react-client-sdk";

export type BrandDashFilter = { value: string; label: string };

type BrandFilterProps = {
  isMoreFilter?: boolean;
  onChange: (value: BrandDashFilter[]) => void;
  removeFilter?: () => void;
  value: BrandDashFilter[];
  onOperatorChange?: (operator: string) => void;
  operator?: string;
};

const BrandsFilter = ({
  isMoreFilter,
  onChange,
  removeFilter,
  value,
  onOperatorChange,
  operator,
}: BrandFilterProps) => {
  const [localValue, setLocalValue] = useState<BrandDashFilter[]>(value ?? ([] as BrandDashFilter[]));
  const [localOperator, setLocalOperator] = useState<string>(operator || "");
  const [isOpen, setIsOpen] = useState(false);
  const hasChangesRef = useRef(false);
  const hasOperatorChangesRef = useRef(false);

  useOpenOnMoreFilter(value, setIsOpen, isMoreFilter);

  const {
    tempLeadSystemOperators,
    setTempLeadSystemOperators,
    tempLeadFilter,
    setTempLeadFilter,
    leadSystemFilter,
  } = useContext(LeadFilterContext);

  const { ORG_BRAND_OPTIONS: availableBrands, showMultiBrandUI } = useMultiBrands(useFlags);

  useEffect(() => {
    if (!isOpen) {
      setLocalValue(value ?? []);
      setLocalOperator(operator || "");
      hasChangesRef.current = false;
      hasOperatorChangesRef.current = false;
    }
  }, [value, operator, isOpen]);

  useEffect(() => {
    hasChangesRef.current = JSON.stringify(localValue) !== JSON.stringify(value);
    hasOperatorChangesRef.current = localOperator !== operator;
  }, [localValue, value, localOperator, operator]);

  const selectedOptions = useMemo(
    () => tempLeadFilter.brands.filter((o: any) => localValue?.some((v: any) => v.value === o.value)),
    [tempLeadFilter.brands, localValue],
  );

  const moreItems = localValue?.length - 1;

  const handleIsOpenChange = useCallback(
    (newIsOpen: boolean) => {
      if (!newIsOpen && (hasChangesRef.current || hasOperatorChangesRef.current)) {
        onChange?.(localValue);
        if (hasOperatorChangesRef.current && onOperatorChange) {
          onOperatorChange(localOperator);
        }
        hasChangesRef.current = false;
        hasOperatorChangesRef.current = false;
      }
      setIsOpen(newIsOpen);
    },
    [localValue, onChange, localOperator, onOperatorChange],
  );

  const handleClear = useCallback(() => {
    setLocalValue([]);
    hasChangesRef.current = true;
  }, []);

  const handleChange = useCallback((items: BrandDashFilter[]) => {
    setTempLeadFilter({ ...tempLeadFilter, brands: items });
    setLocalValue(items);
    hasChangesRef.current = true;
  }, []);

  const handleOperatorChange = useCallback((newOperator: string) => {
    setLocalOperator(newOperator);
    hasOperatorChangesRef.current = true;
  }, []);

  const headerContent = useMemo(
    () => (
      <>
        <AppText fontSize={12} fontWeight={500} color={theme.text.brand.primary}>
          {selectedOptions?.[0]?.label}
        </AppText>
        {moreItems ? (
          <DropdownMoreBadge>
            <AppText fontSize={12} fontWeight={500} color={theme.text.neutral.inverse}>
              +{moreItems}
            </AppText>
          </DropdownMoreBadge>
        ) : null}
      </>
    ),
    [selectedOptions, moreItems],
  );

  return (
    <DynamicDropdown<BrandDashFilter[]>
      isOpen={isOpen}
      label="Brands"
      onClear={handleClear}
      onRemove={hasValue(localValue) || isMoreFilter ? removeFilter : undefined}
      renderHeader={headerContent}
      setIsOpen={handleIsOpenChange}
      value={localValue}
    >
      <RadioSection>
        {FILTER_OPERATIONS_ARRAY.map((item: OptionItem) => {
          return (
            <RadioDiv key={item.value}>
              <PhoenixRadio
                selected={localOperator === item.value}
                onClick={() => handleOperatorChange(item.value as string)}
              />
              <AppText fontSize={12} fontWeight={500}>
                {item.label}
              </AppText>
            </RadioDiv>
          );
        })}
      </RadioSection>

      {tempLeadSystemOperators.brands_operator !== "EmptyField" && (
        <PhoenixMultiSelect
          isMulti
          isClearable
          marginBottom={false}
          menuPosition="fixed"
          menuShouldBlockScroll
          name="brands"
          onChange={handleChange}
          // onCreateOption={handleCreate}
          options={availableBrands}
          placeholder="Type Brand Name"
          value={tempLeadFilter.brands}
        />
      )}
    </DynamicDropdown>
  );
};

const RadioDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 24px;
`;

const RadioSection = styled.div`
  display: flex;
  gap: 3px;
  margin-bottom: 24px;
`;

export default BrandsFilter;
