import * as React from "react";
import gql from "graphql-tag";
import { useState, useContext } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { ModalContext } from "../../context";
import styled from "styled-components";
import { AppText, AppModalPopup, AppInput, NewAppButton, FlexDiv, Loading, NewAppMultiSelect } from "../UI";
import { theme } from "../../utils/theme";
import * as Sentry from "@sentry/react";
import { appToast } from "../../utils/toast";
import { newCloseModal, carrotDropdown } from "../../images";
import { timezone_list_items } from "../../static";
import { NewFormMultiSelectField } from "../Field";
import { PhoenixIcon, PhoenixMultiSelect } from "../UI/Phoenix";
import { xIcon } from "../../images/NewDesign";
import { loggedInUser } from "src/apollo/cache";
import { checkIfBrandIsOnboarded } from "src/utils/misc";
import useMultiBrands from "src/cache-hooks/useIsMultiBrand";
import { useFlags } from "launchdarkly-react-client-sdk";
import { OptionItem } from "src/types";

interface SalesTeamsBulkEditModalProps {
  selectedUsers: any;
  setListSelected: any;
  allUsers: any;
  setAllUsers: any;
}

const BULK_EDIT_USERS = gql`
  mutation bulkEditUsers($user_ids: [String!]!, $param: UserBatchEditInput!) {
    bulkEditUsers(user_ids: $user_ids, param: $param)
  }
`;

const BULK_DELETE_USERS = gql`
  mutation bulkDeleteUsers($user_ids: [String!]!) {
    bulkDeleteUsers(user_ids: $user_ids)
  }
`;

const FETCH_TEAMS = gql`
  query fetchTeams {
    fetchTeams {
      id
      name
    }
  }
`;

const GET_POSSIBLE_INDUSTRIES_DATA = gql`
  query fetchIndustryOptions {
    fetchIndustryOptions {
      id
      label
      sub_industries
    }
  }
`;

const GET_UNIQUE_LEAD_SOURCES_OPTIONS = gql`
  query getUniqueLeadSources {
    getUniqueLeadSources
  }
`;

const SalesTeamsBulkEditModal: React.FC<SalesTeamsBulkEditModalProps> = ({
  selectedUsers,
  setListSelected,
  allUsers,
  setAllUsers,
}) => {
  const [roleSelect, setRoleSelect] = useState("SDR");
  const [teamSelect, setTeamSelect] = useState("");
  const [brandSelect, setBrandSelect] = useState<OptionItem[]>([]);
  const [timezoneSelect, setTimezoneSelect] = useState("");
  const [channelSelect, setChannelSelect] = useState("Both");
  const [filterRadio, setFilterRadio] = useState(true);
  const [subIndustryFilterRadio, setSubIndustryFilterRadio] = useState(true);
  const [industrySelect, setIndustrySelect] = useState([]);
  const [subIndustrySelect, setSubIndustrySelect] = useState([]);
  const [leadSourceSelect, setLeadSourceSelect] = useState([]);

  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);

  const { showSalesTeamsBulkEditModal, setShowSalesTeamsBulkEditModal } = useContext(ModalContext);

  const { data: teamsData, loading: teamsLoading, error: teamsError, refetch: teamRefetch } = useQuery(FETCH_TEAMS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: subindustryUniqueData, loading: subindustryUniqueLoading, error: subindustryUniqueError } = useQuery(
    GET_POSSIBLE_INDUSTRIES_DATA,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: leadSourceUniqueData, loading: leadSourceUniqueLoading, error: leadSourceUniqueError } = useQuery(
    GET_UNIQUE_LEAD_SOURCES_OPTIONS,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const [bulkEditUsers, { loading: loadingBulkUnassign }] = useMutation(BULK_EDIT_USERS, {
    async onCompleted({ bulkEditUsers }) {
      if (!bulkEditUsers) {
        appToast("Error editing users. Something went wrong.");
        return;
      }
      setShowSalesTeamsBulkEditModal(false);
      setListSelected({});
      appToast("Users updated sucessfully");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `bulkEditUsers GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchUnassignedUsers", "fetchTeams"],
  });

  const [bulkDeleteUsers, { loading: loadingBulkDelete }] = useMutation(BULK_DELETE_USERS, {
    async onCompleted({ bulkDeleteUsers }) {
      if (!bulkDeleteUsers) {
        appToast("Error deleting users. Something went wrong.");
        return;
      }
      setShowSalesTeamsBulkEditModal(false);
      setListSelected({});
      handleDeleteUser();
      appToast("Users deleted sucessfully");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `bulkDeleteUsers GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchUnassignedUsers", "fetchTeams"],
  });

  const subIndustriesOptions = (vals: any) => {
    return subindustryUniqueData.fetchIndustryOptions
      ?.slice()
      ?.filter((item: any) => !!item.label && !!item.sub_industries.length)
      ?.map((ls: any) => ({
        label: ls.label,
        options: ls.sub_industries?.map((si: string) => ({
          label: si,
          value: si,
          industry: ls.label,
        })),
      }))
      ?.filter((item: any) => vals?.map((item: any) => item.value).includes(item.label));
  };

  const handleDeleteUser = () => {
    let allUsersCopy = [...allUsers];
    allUsersCopy = allUsersCopy.filter((user) => !Object.keys(selectedUsers).includes(user.id));
    setAllUsers(allUsersCopy);
  };

  const handleTitle = (modalType: string | boolean) => {
    switch (modalType) {
      case "Role Type":
        return "User Role Type";
      case "Team":
        return "Team & Sales Manager";
      case "Industry":
        return "Select Industries";
      case "Lead Sources":
        return "Assign Lead Sources";
      case "Time Zone":
        return "Edit Time Zone";
      case "Channel":
        return "Edit Channel";
      case "Brand":
        return "Edit Brands";
      default:
        return "";
    }
  };

  const { ORG_BRAND_OPTIONS } = useMultiBrands(useFlags);

  const handleBody = (modalType: string | boolean) => {
    switch (modalType) {
      case "Role Type":
        return (
          <FlexDiv justify="left" direction="column">
            <AppText fontSize={12}>
              Role<span style={{ color: theme.ATTENTION700 }}>*</span>
            </AppText>
            <SelectContainer>
              <Select value={roleSelect} onChange={(e) => setRoleSelect(e.target.value)}>
                <option value={"SDR"}>Sales Development Representative</option>
                <option value={"AE"}>Account Executive</option>
              </Select>
            </SelectContainer>
          </FlexDiv>
        );
      case "Team":
        return (
          <FlexDiv justify="left" direction="column">
            {teamsLoading ? (
              <Loading />
            ) : (
              <>
                <AppText fontSize={12}>
                  Team<span style={{ color: theme.ATTENTION700 }}>*</span>
                </AppText>
                <SelectContainer>
                  <Select value={teamSelect} onChange={(e) => setTeamSelect(e.target.value)}>
                    {teamsData?.fetchTeams?.map((team: any) => (
                      <option key={team.id} value={team.id}>
                        {team.name}
                      </option>
                    ))}
                  </Select>
                </SelectContainer>
              </>
            )}
          </FlexDiv>
        );
      case "Industry":
        return (
          <FlexDiv justify="left" direction="column" style={{ height: "fit-content" }}>
            <SelectWrap>
              <RadioWrap onChange={() => setFilterRadio(!filterRadio)}>
                <FlexDiv align="center" gap={8}>
                  <input
                    type="radio"
                    name="no-filter"
                    checked={!filterRadio}
                    style={{ width: "16px", height: "16px", cursor: "pointer" }}
                    onClick={() => {
                      setFilterRadio(false);
                      setSubIndustryFilterRadio(false);
                    }}
                  ></input>
                  <AppText fontSize={12}>Do Not Assign</AppText>
                </FlexDiv>
                <FlexDiv align="center" gap={8}>
                  <input
                    type="radio"
                    name="apply filter"
                    checked={filterRadio}
                    style={{ width: "16px", height: "16px", cursor: "pointer" }}
                    onClick={() => {
                      setFilterRadio(true);
                    }}
                  ></input>
                  <AppText>Assign</AppText>
                </FlexDiv>
              </RadioWrap>
              <AppText fontSize={12}>Primary Industry</AppText>
              <SelectContainer>
                <NewAppMultiSelect
                  isMulti={true}
                  marginBottom={false}
                  maxHeight={300}
                  maxValueWidth={245}
                  maxMenuHeight={110}
                  name="industry_white_list_array"
                  value={industrySelect}
                  itemBackgroundColor={filterRadio ? theme.PRIMARY500 : theme.DANGER600}
                  options={
                    subindustryUniqueLoading
                      ? ["Loading..."]
                      : subindustryUniqueData.fetchIndustryOptions
                          .slice()
                          .filter((item: any) => !!item.label)
                          ?.map((ls: any) => ({
                            label: ls.label,
                            value: ls.label,
                          }))
                  }
                  onChange={(e: any) => {
                    const removed: any = industrySelect
                      .filter((industry) => !e.includes(industry))
                      ?.map((ele: any) => ele.label);
                    const subIndustryCopy: any = subIndustrySelect.slice();
                    const filteredSubIndustry = subIndustryCopy.filter(
                      (subIndustry: any) => !removed.includes(subIndustry.industry),
                    );
                    setIndustrySelect(e);
                    setSubIndustrySelect(filteredSubIndustry);
                  }}
                />
              </SelectContainer>
              <RadioWrap onChange={() => filterRadio && setSubIndustryFilterRadio(!subIndustryFilterRadio)}>
                <FlexDiv align="center" gap={8}>
                  <input
                    type="radio"
                    name="sub-no-filter"
                    checked={!subIndustryFilterRadio}
                    style={{ width: "16px", height: "16px", cursor: "pointer" }}
                    onClick={() => setSubIndustryFilterRadio(false)}
                  ></input>
                  <AppText fontSize={12}>Do Not Assign</AppText>
                </FlexDiv>
                <FlexDiv align="center" gap={8}>
                  <input
                    type="radio"
                    name="sub apply filter"
                    checked={subIndustryFilterRadio}
                    style={{ width: "16px", height: "16px", cursor: "pointer" }}
                    onClick={() => filterRadio && setSubIndustryFilterRadio(true)}
                  ></input>
                  <AppText>Assign</AppText>
                </FlexDiv>
              </RadioWrap>
              <AppText fontSize={12}>Sub-Industry</AppText>
              <SelectContainer>
                <NewAppMultiSelect
                  isMulti={true}
                  marginBottom={false}
                  maxHeight={300}
                  maxValueWidth={245}
                  maxMenuHeight={110}
                  name="industry_white_list_array"
                  value={subIndustrySelect}
                  itemBackgroundColor={subIndustryFilterRadio ? theme.PRIMARY500 : theme.ATTENTION700}
                  options={subindustryUniqueLoading ? ["Loading..."] : subIndustriesOptions(industrySelect)}
                  onChange={(e: any) => {
                    setSubIndustrySelect(e);
                  }}
                />
              </SelectContainer>
            </SelectWrap>
          </FlexDiv>
        );
      case "Lead Sources":
        return (
          <FlexDiv justify="left" direction="column" style={{ height: "fit-content" }}>
            <SelectWrap>
              <RadioWrap onChange={() => setFilterRadio(!filterRadio)}>
                <FlexDiv align="center" gap={8}>
                  <input
                    type="radio"
                    name="no-filter"
                    checked={!filterRadio}
                    style={{ width: "16px", height: "16px", cursor: "pointer" }}
                    onClick={() => {
                      setFilterRadio(false);
                    }}
                  ></input>
                  <AppText fontSize={12}>Do Not Assign</AppText>
                </FlexDiv>
                <FlexDiv align="center" gap={8}>
                  <input
                    type="radio"
                    name="apply filter"
                    checked={filterRadio}
                    style={{ width: "16px", height: "16px", cursor: "pointer" }}
                    onClick={() => {
                      setFilterRadio(true);
                    }}
                  ></input>
                  <AppText>Assign</AppText>
                </FlexDiv>
              </RadioWrap>
              <AppText fontSize={12}>Lead Source</AppText>
              <SelectContainer>
                <NewAppMultiSelect
                  isMulti={true}
                  marginBottom={false}
                  maxHeight={300}
                  maxValueWidth={245}
                  maxMenuHeight={140}
                  name="lead_source_white_list_array"
                  value={leadSourceSelect}
                  itemBackgroundColor={filterRadio ? theme.PRIMARY500 : theme.DANGER600}
                  options={
                    leadSourceUniqueLoading
                      ? ["Loading..."]
                      : leadSourceUniqueData.getUniqueLeadSources
                          .slice()
                          .filter((item: any) => !!item.label)
                          ?.map((ls: any) => ({
                            label: ls.label,
                            value: ls.label,
                          }))
                  }
                  onChange={(e: any) => {
                    setLeadSourceSelect(e);
                  }}
                />
              </SelectContainer>
            </SelectWrap>
          </FlexDiv>
        );
      case "Time Zone":
        return (
          <FlexDiv justify="left" direction="column">
            <AppText fontSize={12}>
              Time Zone<span style={{ color: theme.ATTENTION700 }}>*</span>
            </AppText>
            <SelectContainer>
              <Select value={timezoneSelect} onChange={(e) => setTimezoneSelect(e.target.value)}>
                {timezone_list_items?.map((timezone: any) => (
                  <option key={timezone.value} value={timezone.value}>
                    {timezone.label}
                  </option>
                ))}
              </Select>
            </SelectContainer>
          </FlexDiv>
        );
      case "Channel":
        return (
          <FlexDiv justify="left" direction="column">
            <AppText fontSize={12}>
              Channel<span style={{ color: theme.ATTENTION700 }}>*</span>
            </AppText>
            <SelectContainer>
              <Select value={channelSelect} onChange={(e) => setChannelSelect(e.target.value)}>
                <option value={"Both"}>Both</option>
                <option value={"Inbound"}>Inbound</option>
                <option value={"Outbound"}>Outbound</option>
              </Select>
            </SelectContainer>
          </FlexDiv>
        );
      case "Delete":
        return (
          <AppText fontSize={14}>
            Are you sure you want to delete these sales representatives? This action cannot be undone.
          </AppText>
        );
      case "Brand":
        return (
          <FlexDiv justify="left" direction="column">
            <SelectContainer>
              <PhoenixMultiSelect
                name="brand_ids"
                options={ORG_BRAND_OPTIONS}
                titleText="Brands"
                required
                onChange={(e: OptionItem[]) => setBrandSelect(e)}
                isMulti
                value={brandSelect}
                placeholder="Select Brands"
                width={336}
                menuPortal
              />
            </SelectContainer>
          </FlexDiv>
        );
      default:
        return <></>;
    }
  };

  const handleSubmit = async (modalType: string | boolean) => {
    switch (modalType) {
      case "Role Type":
        await bulkEditUsers({
          variables: {
            user_ids: Object.keys(selectedUsers),
            param: { role: roleSelect },
          },
        });
        break;
      case "Team":
        if (teamSelect) {
          await bulkEditUsers({
            variables: {
              user_ids: Object.keys(selectedUsers),
              param: { team_id: teamSelect },
            },
          });
        } else {
          await bulkEditUsers({
            variables: {
              user_ids: Object.keys(selectedUsers),
              param: { team_id: teamsData?.fetchTeams[0].id },
            },
          });
        }
        break;
      case "Industry":
        const industryValues = industrySelect?.map((ele: any) => ele.value);
        const subIndustryValues = subIndustrySelect?.map((ele: any) => ele.value);
        await bulkEditUsers({
          variables: {
            user_ids: Object.keys(selectedUsers),
            param: {
              industry_white_list_assign_type: filterRadio ? "OnlyAssign" : "DoNotAssign",
              industry_white_list_array: industryValues,
              sub_industry_white_list_assign_type: subIndustryFilterRadio ? "OnlyAssign" : "DoNotAssign",
              sub_industry_white_list_array: subIndustryValues,
            },
          },
        });
        break;
      case "Lead Sources":
        const leadSourceValues = leadSourceSelect?.map((ele: any) => ele.value);
        await bulkEditUsers({
          variables: {
            user_ids: Object.keys(selectedUsers),
            param: {
              lead_source_white_list_assign_type: filterRadio ? "OnlyAssign" : "DoNotAssign",
              lead_source_white_list_array: leadSourceValues,
            },
          },
        });
        break;
      case "Time Zone":
        await bulkEditUsers({
          variables: {
            user_ids: Object.keys(selectedUsers),
            param: { timezone_raw: timezoneSelect },
          },
        });
        break;
      case "Channel":
        await bulkEditUsers({
          variables: {
            user_ids: Object.keys(selectedUsers),
            param: { channel: channelSelect },
          },
        });
        break;
      case "Delete":
        await bulkDeleteUsers({
          variables: {
            user_ids: Object.keys(selectedUsers),
          },
        });
        break;
      case "Brand":
        await bulkEditUsers({
          variables: {
            user_ids: Object.keys(selectedUsers),
            param: {
              brand_ids: brandSelect?.map((brand: OptionItem) => brand.value),
            },
          },
        });
        break;
    }
  };

  return (
    <AppModalPopup
      blinds={showSalesTeamsBulkEditModal}
      setBlinds={setShowSalesTeamsBulkEditModal}
      unbordered
      width={confirmationModal ? 476 : showSalesTeamsBulkEditModal !== "Delete" ? 640 : 424}
      height={confirmationModal ? 320 : 512}
      animate
    >
      <EditCardDiv style={{ height: "auto" }}>
        <CloseButton
          onClick={() => {
            setShowSalesTeamsBulkEditModal(false);
          }}
        >
          <PhoenixIcon svg={xIcon} size={22} pointer />
        </CloseButton>
        <TitleDiv>
          <TitleText>
            {showSalesTeamsBulkEditModal !== "Delete" ? "Bulk Edit Users" : "Delete Sales Representatives"}
          </TitleText>
          <AppText fontSize={14} fontWeight={400}>
            {handleTitle(showSalesTeamsBulkEditModal)}
          </AppText>
        </TitleDiv>
        {showSalesTeamsBulkEditModal !== "Delete" ? (
          <ModalBody minHeight={confirmationModal ? "150px" : null} maxHeight={confirmationModal ? "150px" : null}>
            {!confirmationModal ? (
              handleBody(showSalesTeamsBulkEditModal)
            ) : (
              <>
                <FinalStepTitle>Are You Sure?</FinalStepTitle>
                <FinalStepSubTitle>
                  Are you sure you want to update {Object.keys(selectedUsers).length > 1 ? "these users" : "this user"}?
                  Changes will be applied immediately.
                </FinalStepSubTitle>
              </>
            )}
          </ModalBody>
        ) : (
          <DeleteModalBody>{handleBody(showSalesTeamsBulkEditModal)}</DeleteModalBody>
        )}
        <ButtonContainer>
          <ActionButton
            variant="attention"
            width={188}
            onClick={() => (!confirmationModal ? setShowSalesTeamsBulkEditModal(false) : setConfirmationModal(false))}
          >
            {confirmationModal ? "NO, CANCEL" : "Cancel"}
          </ActionButton>
          <ActionButton
            variant="primary"
            width={188}
            style={{ fontSize: "10px", letterSpacing: "1px", textTransform: "uppercase" }}
            onClick={() =>
              confirmationModal || showSalesTeamsBulkEditModal === "Delete"
                ? handleSubmit(showSalesTeamsBulkEditModal)
                : setConfirmationModal(true)
            }
          >
            {confirmationModal
              ? "YES, UPDATE"
              : showSalesTeamsBulkEditModal === "Delete"
              ? "Yes, Delete"
              : "Save Changes"}
          </ActionButton>
        </ButtonContainer>
      </EditCardDiv>
    </AppModalPopup>
  );
};

const EditCardDiv = styled.div`
  z-index: 15;
`;

const TitleDiv = styled.div`
  position: relative;
  width: 100%;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
  z-index: 5;
`;

interface ModalBodyProps {
  minHeight: string | null;
  maxHeight: string | null;
}

const ModalBody = styled.div<ModalBodyProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: ${(props) => props.minHeight ?? "328px"};
  max-height: ${(props) => props.maxHeight ?? "380px"};
  overflow: auto;
`;
const DeleteModalBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  padding: 20px 42px 100px 42px;
  text-align: center;
`;

const SelectContainer = styled.div`
  position: relative;
  background: url(${carrotDropdown}) no-repeat;
  background-position: right 5% bottom 50%;
  margin-bottom: 32px;
`;

const Select = styled.select`
  position: relative;
  width: 336px;
  height: 40px;

  border-radius: 4.4px;
  border: solid 0.9px ${theme.PILL_GRAY};
  padding: 8px;

  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 80px;
  border-top: 1px solid ${theme.NEUTRAL200};
`;

const ActionButton = styled((props) => <NewAppButton {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  font-size: 12px;
`;

const RadioWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 16px;
`;

const SelectWrap = styled.div`
  height: fit-content;
  width: 300px;
`;

const FinalStepTitle = styled(AppText)`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px;
  margin-top: 40px;
`;

const FinalStepSubTitle = styled(AppText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin: 0px 16px 24px 16px;
`;

export { SalesTeamsBulkEditModal };
