import React from "react";
import { components } from "react-select";
import { PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import { OptionItem } from "src/types";
import { CustomMultiValue } from "./shared";

type TeamFilterProps = {
  onChange: (value: OptionItem[]) => void;
  options: OptionItem[];
  value: any;
};

const MultiValue = (props: any) => {
  return <CustomMultiValue {...props} containerClassName="team-vc" />;
};

const ValueContainer = (props: any) => {
  return <components.ValueContainer className="team-vc" {...props} />;
};

const TeamFilter = ({ value, onChange, options }: TeamFilterProps) => {
  return (
    <PhoenixMultiSelect
      closeMenuOnSelect={false}
      components={{ MultiValue, ValueContainer }}
      hideSelectedOptions={false}
      isClearable={false}
      marginBottom={false}
      maxHeight={40}
      name="Teams"
      onChange={(e: OptionItem[]) => onChange(e)}
      options={options}
      optionStyles={{ height: "auto", backgroundColor: "transparent", color: "black" }}
      placeholder="Filter by Team"
      showCheckbox={true}
      titleText="Teams"
      value={value}
      width={320}
    />
  );
};

export default TeamFilter;
