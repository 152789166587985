import React, { useEffect } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { AppErrorText, AppText, FlexDiv, SkeletonBlock } from "../../UI";

import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { PhoenixAppButton, PhoenixIcon, PhoenixRadio } from "../../UI/Phoenix";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { reorder } from "../../../images/NewDesign";
import { gql, useQuery, useMutation } from "@apollo/client";
import { appToast, errorToast, notificationToast, successToast } from "../../../utils/toast";
import { OPT_IN_MESSAGES, OPT_OUT_MESSAGES, SMS_SECTION_ORDER } from "src/static";
interface DisappearingDivProps {
  togglePageTitle?: () => void;
}

const validationSchema = Yup.object().shape({
  optInMessage: Yup.string().required("Please select an opt-in message"),
  optOutMessage: Yup.string().required("Please select an opt-out message"),
  order: Yup.array().of(Yup.string()).required("Please select an order of messaging"),
});

const FETCH_SMS_GLOBAL_TEMPLATE = gql`
  query fetchSMSComplianceTemplate($brandId: String) {
    fetchSMSComplianceTemplate(brand_id: $brandId)
  }
`;

const UPDATE_SMS_GLOBAL_TEMPLATE = gql`
  mutation CreateOrUpdateSMSComplianceTemplate(
    $smsOptOut: String!
    $smsTextOrder: [SMSTextOrder!]!
    $smsOptIn: String!
    $brandId: String
    $id: String
  ) {
    createOrUpdateSMSComplianceTemplate(
      sms_opt_out: $smsOptOut
      sms_text_order: $smsTextOrder
      sms_opt_in: $smsOptIn
      brand_id: $brandId
      id: $id
    ) {
      sms_opt_out
      sms_text_order
      sms_opt_in
      id
    }
  }
`;

const StepSMSSettings: React.FC<DisappearingDivProps> = ({ togglePageTitle }) => {
  useEffect(() => {
    if (togglePageTitle) {
      togglePageTitle();
    }
  }, []);

  // fetch settings
  const {
    data: fetchSMSGlobalTemplateData,
    loading: fetchSMSGlobalTemplateLoading,
    error: fetchSMSGlobalTemplateError,
  } = useQuery(FETCH_SMS_GLOBAL_TEMPLATE, {
    fetchPolicy: "cache-and-network",
    variables: {
      brandId: undefined,
    },
    onCompleted: (data) => {
      // update formik values with returned data
      console.log("Successfully fetched SMS global template: ", data);
      formikRef.current?.setValues({
        optInMessage: data?.fetchSMSComplianceTemplate?.sms_opt_in,
        optOutMessage: data?.fetchSMSComplianceTemplate?.sms_opt_out,
        order:
          data?.fetchSMSComplianceTemplate?.sms_text_order?.map((item: string) => {
            return SMS_SECTION_ORDER?.find((section) => section?.value === item);
          }) || SMS_SECTION_ORDER,
        id: data?.fetchSMSComplianceTemplate?.id,
      });
    },
    onError: (error) => {
      console.error("Error fetching SMS global template: ", error);
    },
  });

  // update settings
  const [
    updateSMSGlobalTemplate,
    { data: updateSMSGlobalTemplateData, loading: updateSMSGlobalTemplateLoading, error: updateSMSGlobalTemplateError },
  ] = useMutation(UPDATE_SMS_GLOBAL_TEMPLATE, {
    onCompleted: (data) => {
      // update formik values with returned data
      console.log("Successfully updated SMS global template: ", data);
      formikRef.current?.setValues({
        optInMessage: data?.createOrUpdateSMSComplianceTemplate?.sms_opt_in,
        optOutMessage: data?.createOrUpdateSMSComplianceTemplate?.sms_opt_out,
        order: data?.createOrUpdateSMSComplianceTemplate?.sms_text_order?.map((item: string) => {
          return SMS_SECTION_ORDER?.find((section) => section?.value === item);
        }),
        id: data?.createOrUpdateSMSComplianceTemplate?.id,
      });

      // show success message
      appToast("Successfully updated SMS Settings");
    },

    onError: (error) => {
      console.error("Error updating SMS global template: ", error);
    },
  });

  const globalError = fetchSMSGlobalTemplateError || updateSMSGlobalTemplateError;
  const globalLoading = fetchSMSGlobalTemplateLoading;
  const formikRef = React.useRef<FormikProps<any>>(null);

  const GlobalErrorComponent = () => {
    return (
      <FlexDiv direction="column" gap={8}>
        <AppErrorText>Error loading data. Please try again later.</AppErrorText>
        <AppErrorText>{globalError?.message}</AppErrorText>
      </FlexDiv>
    );
  };

  const GlobalLoadingComponent = () => {
    return <SkeletonBlock height={1000} width={"100%"} borderRadius={8} />;
  };

  if (globalError) {
    return <GlobalErrorComponent />;
  }

  if (globalLoading) {
    return <GlobalLoadingComponent />;
  }

  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          id: undefined,
          optInMessage: "",
          optOutMessage: "",
          order: SMS_SECTION_ORDER,
        }}
        onSubmit={(values) => {
          console.log("Submitting form with values: ", values);
          updateSMSGlobalTemplate({
            variables: {
              smsOptIn: values?.optInMessage,
              smsOptOut: values?.optOutMessage,
              smsTextOrder: values?.order?.map((item: { label: string; value: string }) => {
                return item.value;
              }),
              // old page is only on for single brand orgs
              brandId: undefined,
              id: values?.id,
            },
          });
        }}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, submitForm, errors, isValid }) => {
          const reorderOrder = (list: string[], startIndex: number, endIndex: number) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            setFieldValue("order", result);
          };

          const onDragEndOrder = (result: any) => {
            // dropped outside the list
            if (!result.destination) {
              return;
            }

            reorderOrder(values.order, result.source.index, result.destination.index);
          };

          return (
            <PageContainer>
              <Page>
                <Header>
                  <StepLocationText>SMS Settings</StepLocationText>
                  <PhoenixAppButton buttonType="secondary" variant="brand" onClick={submitForm} disabled={!isValid}>
                    Save
                  </PhoenixAppButton>
                </Header>
                <Main>
                  <SubTextDiv>
                    <AppText fontWeight={400} fontSize={12}>
                      Configure opt-in and opt-out messaging for the first SMS each unique rep sends to phone numbers.
                    </AppText>
                  </SubTextDiv>
                  <SettingsSection>
                    <AppText fontWeight={600} fontSize={12}>
                      Select an Opt-In Message
                    </AppText>
                    {OPT_IN_MESSAGES.map((message, index) => (
                      <RadioRow key={message}>
                        <PhoenixRadio
                          selected={values.optInMessage === message}
                          onClick={() => {
                            setFieldValue("optInMessage", message);
                          }}
                        />
                        <AppText fontSize={12} fontWeight={500}>
                          {message}
                        </AppText>
                      </RadioRow>
                    ))}
                  </SettingsSection>
                  <SettingsSection>
                    <AppText fontWeight={600} fontSize={12}>
                      Select an Opt-Out Message
                    </AppText>
                    {OPT_OUT_MESSAGES.map((message, index) => (
                      <RadioRow key={message}>
                        <PhoenixRadio
                          selected={values.optOutMessage === message}
                          onClick={() => {
                            setFieldValue("optOutMessage", message);
                          }}
                        />
                        <AppText fontSize={12} fontWeight={500}>
                          {message}
                        </AppText>
                      </RadioRow>
                    ))}
                  </SettingsSection>
                  <SettingsSection>
                    <AppText fontWeight={600} fontSize={12}>
                      Configure Order of Messaging
                    </AppText>

                    <DragDropContext onDragEnd={onDragEndOrder}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <FlexDiv direction="column" ref={provided.innerRef} {...provided.droppableProps}>
                            {values?.order?.map((item: { label: string; value: string }, index: number) => (
                              <Draggable key={`table_${item.value}`} draggableId={item.value} index={index}>
                                {(provided, snapshot) => (
                                  <DraggableDiv
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <PhoenixIcon svg={reorder} variant="brand" size={16} />
                                    <AppText fontSize={12} fontWeight={500}>
                                      {item.label}
                                    </AppText>
                                  </DraggableDiv>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </FlexDiv>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </SettingsSection>
                </Main>
              </Page>
            </PageContainer>
          );
        }}
      </Formik>
    </>
  );
};

// Refactored styled components
const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 24px;
`;

const Page = styled.div`
  min-height: inherit;
  width: 100%;
  min-width: 1200px;
  box-sizing: border-box;
  background-color: ${theme.fill.neutral.primary};
  border: 1px solid ${theme.NEUTRAL100};
  border-radius: 16px;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px 40px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const StepLocationText = styled(AppText)`
  color: ${theme.text.neutral.primary};
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  padding: 40px;
`;

const SubTextDiv = styled.div`
  width: 511px;
`;

const SettingsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 120px;
`;

const RadioRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DraggableDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

export { StepSMSSettings };
