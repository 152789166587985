import React from "react";
import moment, { Moment } from "moment";
import { AppText, FlexDiv } from "src/Components/UI";
import { PhoenixDateRange } from "src/Components/UI/Phoenix";

const DateRangePicker = ({
  lowerboundKey = "lowerbound_date",
  onChange,
  upperboundKey = "upperbound_date",
  value,
  min_date,
  max_date,
}: {
  endDateId: string;
  lowerboundKey?: string;
  onChange?: (value: any) => void;
  startDateId: string;
  upperboundKey?: string;
  value?: any;
  min_date?: moment.Moment | null;
  max_date?: moment.Moment | null;
}) => {
  const isDayBlocked = (date: Moment) => {
    if (max_date) {
      return date.unix() > max_date.unix();
    }
    if (min_date) {
      return date.unix() < min_date.unix();
    }
    return false;
  };

  return (
    <FlexDiv direction="column" gap={8}>
      <AppText>Between</AppText>
      <PhoenixDateRange
        isOutsideRange={() => false}
        isDayBlocked={isDayBlocked}
        lowerbound_date={value?.[lowerboundKey] ? moment(value?.[lowerboundKey]) : null}
        upperbound_date={value?.[upperboundKey] ? moment(value?.[upperboundKey]) : null}
        onDateChange={({ startDate, endDate }) => {
          onChange?.({
            [lowerboundKey]: startDate,
            [upperboundKey]: endDate,
          });
        }}
      />
    </FlexDiv>
  );
};

export default DateRangePicker;
