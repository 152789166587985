import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { AppText, FlexDiv } from "src/Components/UI";
import { PhoenixIcon } from "src/Components/UI/Phoenix";
import { edit, more_vertical, pause_circle_new, play_circle_new, trash, voicemail } from "src/images/NewDesign";
import { VoicemailDrop } from "src/types/VoicemailTypes";
import { useClickOutside, useModalContext } from "src/utils/hooks";
import { theme } from "src/utils/theme";
import styled from "styled-components";
import WaveSurfer from "wavesurfer.js";
import { CardWaveform } from "./CardWaveform";
import { formatDate } from "src/utils/date";

const RecordingCard = ({ voicemailDrop }: { voicemailDrop: VoicemailDrop }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const waveform = useRef<WaveSurfer | null>(null);
  const waveformRef = useRef<HTMLDivElement>(null);

  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const moreIconWrapperRef = useRef<HTMLDivElement>(null);

  const {
    showRenameVoicemailDropModal,
    setShowRenameVoicemailDropModal,
    renameVoicemailDropModalData,
    setRenameVoicemailDropModalData,
    showDeleteVoicemailDropModal,
    setShowDeleteVoicemailDropModal,
    deleteVoicemailDropModalData,
    setDeleteVoicemailDropModalData,
  } = useModalContext();

  useEffect(() => {
    if (!voicemailDrop.audio_url || !waveformRef.current) return;

    waveform.current = WaveSurfer.create({
      container: waveformRef.current as HTMLElement,
      responsive: true,
      normalize: true,
      height: 20,
      barWidth: 1,
      barMinHeight: 2,
      barRadius: 1.5,
      barGap: 4,
      cursorWidth: 2,
      backgroundColor: theme.fill.brand.tertiary,
      waveColor: theme.fill.brand.primary,
      progressColor: theme.fill.brand.primary,
      backend: "MediaElement",
    });

    waveform.current.load(voicemailDrop.audio_url);

    waveform.current.on("ready", () => {
      waveform.current?.seekTo(0);
      console.log("waveform.current", waveform.current);
    });

    waveform.current.on("audioprocess", (time) => {
      setCurrentTime(time);
    });

    waveform.current.on("finish", () => {
      setIsPlaying(false);
    });

    return () => waveform.current?.destroy();
  }, [waveformRef, voicemailDrop.audio_url]);

  const handlePlayPause = () => {
    if (isPlaying) {
      setIsPlaying(false);
      waveform.current?.pause();
    } else {
      setIsPlaying(true);
      waveform.current?.play();
    }
  };

  const handleMore = () => {
    setShowMoreMenu(!showMoreMenu);
  };

  const handleRename = useCallback(() => {
    setShowRenameVoicemailDropModal(true);
    setRenameVoicemailDropModalData({ id: voicemailDrop.id });
    setShowMoreMenu(false);
  }, [voicemailDrop.id, setShowRenameVoicemailDropModal, setRenameVoicemailDropModalData]);

  const handleDelete = useCallback(() => {
    setShowDeleteVoicemailDropModal(true);
    setDeleteVoicemailDropModalData({ id: voicemailDrop.id });
    setShowMoreMenu(false);
  }, [voicemailDrop.id, setShowDeleteVoicemailDropModal, setDeleteVoicemailDropModalData]);

  useClickOutside(moreIconWrapperRef, () => {
    setShowMoreMenu(false);
  });

  return (
    <>
      <Card>
        {showMoreMenu && (
          <AbsoluteMoreMenu ref={moreIconWrapperRef}>
            <MenuItem onClick={handleRename}>
              <PhoenixIcon svg={edit} size={16} variant="brand" />
              <AppText fontSize={12} fontWeight={400}>
                Rename
              </AppText>
            </MenuItem>
            <MenuItem onClick={handleDelete}>
              <PhoenixIcon svg={trash} size={16} variant="danger" />
              <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                Delete
              </AppText>
            </MenuItem>
          </AbsoluteMoreMenu>
        )}
        <MemoizedHeader voicemailDrop={voicemailDrop} handleMore={handleMore} />
        <CardMain>
          <TextSection>
            <FlexDiv direction="column">
              <AppText fontSize={14} fontWeight={500} lineHeight={20}>
                {voicemailDrop.title}
              </AppText>
              <AppText fontSize={10} fontWeight={400} lineHeight={14} color={theme.text.neutral.secondary}>
                {voicemailDrop.description}
              </AppText>
            </FlexDiv>
            <img
              // no fill effects needed
              src={isPlaying ? pause_circle_new : play_circle_new}
              onClick={handlePlayPause}
              style={{ width: "40px", height: "40px" }}
            />
          </TextSection>
          <FlexDiv direction="column" gap={4}>
            <CardWaveform
              voicemailDrop={voicemailDrop}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              currentTime={currentTime}
              setCurrentTime={setCurrentTime}
              waveform={waveform}
              waveformRef={waveformRef}
            />
            <FlexDiv justify="space-between">
              <AppText fontSize={10} fontWeight={400} lineHeight={14} color={theme.text.neutral.secondary}>
                {formatTime(currentTime)}
              </AppText>
              <AppText fontSize={10} fontWeight={400} lineHeight={14} color={theme.text.neutral.secondary}>
                {formatTime(voicemailDrop.duration_seconds)}
              </AppText>
            </FlexDiv>
          </FlexDiv>

          <FlexDiv gap={16}>
            <FlexDiv gap={8}>
              <AppText
                fontSize={10}
                fontWeight={500}
                color={theme.NEUTRAL300}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                Times Used
              </AppText>
              <AppText fontSize={10} fontWeight={500}>
                {voicemailDrop?.times_played || `00`}
              </AppText>
            </FlexDiv>
            <FlexDiv gap={8}>
              <AppText
                fontSize={10}
                fontWeight={500}
                color={theme.NEUTRAL300}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                Call Back Rate
              </AppText>
              <AppText fontSize={10} fontWeight={500}>
                {`${
                  // 0 is a valid return call rate
                  voicemailDrop?.return_call_rate === undefined || voicemailDrop?.return_call_rate === null
                    ? `N/A`
                    : `${voicemailDrop?.return_call_rate}%`
                }`}
              </AppText>
            </FlexDiv>
          </FlexDiv>
        </CardMain>
      </Card>
    </>
  );
};

const Header = ({ voicemailDrop, handleMore }: { voicemailDrop: VoicemailDrop; handleMore: () => void }) => {
  return (
    <CardHeader>
      <FlexDiv gap={8}>
        <PhoenixIcon svg={voicemail} size={16} variant="brand" />
        <AppText fontSize={14} fontWeight={500} lineHeight={16}>
          {formatTime(voicemailDrop.duration_seconds)}
        </AppText>
      </FlexDiv>
      <MoreIconWrapper>
        <PhoenixIcon pointer svg={more_vertical} size={16} variant="brand" onClick={handleMore} />
      </MoreIconWrapper>
    </CardHeader>
  );
};

const MemoizedHeader = memo(Header, (prevProps, nextProps) => {
  return prevProps === nextProps;
});

const AbsoluteMoreMenu = styled.div`
  position: absolute;
  top: 0px;
  right: 24px;

  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 4px;

  animation: ${theme.popup} 0.2s ease;
  overflow: hidden;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 152px;
  height: 32px;
  padding: 8px;

  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  color: ${theme.BLACK_COLOR};

  transition: background-color 0.15s ease, color 0.15s ease;
  z-index: 1;

  :hover {
    background-color: ${theme.PRIMARY100};
  }

  // first and last child should have border radius
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

const Card = styled(FlexDiv)`
  position: relative;
  width: 340px;
  background-color: ${theme.fill.brand.secondary};
  border-radius: 8px;
  flex-direction: column;
  border: 1px solid ${theme.border.neutral.primary};
  animation: ${theme.fadeIn} 0.2s ease;
`;

const CardHeader = styled(FlexDiv)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px 0px 16px;
  height: 32px;
`;

const CardMain = styled(FlexDiv)`
  width: 100%;
  height: 100%;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  background-color: ${theme.WHITE_COLOR};
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

const TextSection = styled(FlexDiv)`
  justify-content: space-between;
  align-items: center;
`;

const MoreIconWrapper = styled.div`
  position: relative;
`;

export default RecordingCard;
