import styled from "styled-components";
import * as React from "react";
import { Loading, AppSidebarCard, AppErrorText } from "../../UI";
import { theme } from "../../../utils/theme";
import { gql, useQuery } from "@apollo/client";
import { LeadCardV2 } from ".";
import { CORE_LEAD_CARD_DATA } from "../../../apollo/fragments";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Lead } from "../../../__generated__/graphql";
import { ModalContext } from "../../../context";
import { MergeCOLeadsModal } from "../../modal/MergeCOLeadsModal";
import { Modal, UpdateLeadDataComponent } from "../../modal";
import { PhoenixAppButton } from "../../UI/Phoenix";

const FETCH_LEADS_FROM_CUSTOM_OBJECT_ROW = gql`
  ${CORE_LEAD_CARD_DATA}
  query fetchLeadsFromCustomObjectRow($row_id: String!, $skip: Int, $take: Int) {
    fetchLeadsFromCustomObjectRow(row_id: $row_id, skip: $skip, take: $take) {
      id
      ...CoreLeadCardData
      brand_name
      brand_id
    }
  }
`;
interface CurrentLeadProps {
  row_id: string;
}

const CustomObjecetAssociatedLead: React.FC<CurrentLeadProps> = ({ row_id }) => {
  //Local State
  const [skip, setSkip] = useState(0);
  const [leads, setLeads] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const take = 10;
  const {
    showMergeCOLeadModal,
    setShowMergeCOLeadModal,
    editModal,
    setEditModal,
    editModalLeadId,
    setEditModalLeadId,
  } = useContext(ModalContext);

  //Queries
  const { data, loading, error, fetchMore } = useQuery(FETCH_LEADS_FROM_CUSTOM_OBJECT_ROW, {
    fetchPolicy: "network-only",
    variables: {
      skip: skip,
      // If you change the take value, you must change the length check below to match as well as setSkip in handleShowMore.
      take: take,
      row_id,
    },
    onCompleted() {
      const newList = [...leads, ...data.fetchLeadsFromCustomObjectRow].reduce(
        (acc, cv) => (acc?.map((a: Lead) => a.id)?.includes(cv.id) ? acc : [...acc, cv]),
        [],
      );
      if (data.fetchLeadsFromCustomObjectRow.length < take) {
        setHasMore(false);
      }
      setLeads(newList);
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  useEffect(() => {
    if (data && skip === 0) {
      setLeads(data.fetchLeadsFromCustomObjectRow);
    }
  }, [data]);

  //Custom Functions
  const handleShowMore = () => {
    setSkip(skip + take);
    fetchMore({
      variables: {
        skip: skip,
        row_id,
      },
    });
  };

  if (loading && !!!leads.length) return <Loading />;
  if (error) return <AppErrorText>Error loading associated leads</AppErrorText>;

  return (
    <>
      {showMergeCOLeadModal && (
        <MergeContactModalWrap>
          <MergeCOLeadsModal />
        </MergeContactModalWrap>
      )}

      <AppSidebarCard
        trackMixpanel
        title={"Associated Leads"}
        width={430}
        render={
          <PhoenixAppButton
            variant="brand"
            buttonType="ghost-small"
            onClick={() => {
              setShowMergeCOLeadModal(true);
            }}
          >
            Add Associated Lead
          </PhoenixAppButton>
        }
      >
        <ScrollDiv id="recent-scroll-div">
          {editModal && (
            <Modal open={editModal} onClose={() => setEditModal(false)} closeButtonSize={16}>
              <UpdateLeadDataComponent
                customHeight
                visible={editModal}
                close={() => {
                  setEditModal(false);
                  setEditModalLeadId("");
                }}
                lead_id={editModalLeadId}
              />
            </Modal>
          )}
          <InfiniteScroll
            dataLength={leads.length}
            next={handleShowMore}
            hasMore={hasMore}
            loader={<Loading />}
            scrollableTarget="recent-scroll-div"
          >
            {leads.length > 0 ? (
              leads?.map((item: any, index: number) => (
                <BorderDiv borderTop={index > 0} key={`recent-${index}-${item.id}`}>
                  <LeadCardV2
                    leadData={{ ...item, lead_id: item.id }}
                    parentType={"recentDials"}
                    // intent={item.lead.next_lead_intent}
                  />
                </BorderDiv>
              ))
            ) : (
              <AppErrorDiv>
                <AppErrorText>record doesn't have any associated leads</AppErrorText>
              </AppErrorDiv>
            )}
          </InfiniteScroll>
        </ScrollDiv>
      </AppSidebarCard>
    </>
  );
};

interface BorderProps {
  borderTop?: boolean;
}

const BorderDiv = styled.div<BorderProps>`
  border-top: 1px solid ${(props) => (props.borderTop ? theme.NEUTRAL200 : "transparent")};
  padding: 0px;
  margin: 0px;
`;

const ScrollDiv = styled.div`
  height: fit-content;
  max-height: 60vh;
  overflow-y: auto;
`;

const MergeContactModalWrap = styled.div`
  padding: 24px;
  grid-column: 2 / -1;
  height: 100vh;
`;

const AppErrorDiv = styled.div`
  padding: 16px;
`;
export { CustomObjecetAssociatedLead };
