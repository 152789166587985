import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useModalContext } from "src/utils/hooks";
import { updateBrandInCache } from "src/utils/misc";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { errorToast, successToast } from "../../utils/toast";
import { AppText } from "../UI";
import { PhoenixAppButton } from "../UI/Phoenix";
import { Modal } from "./Modal";
const FETCH_BRAND_INFO = gql`
  query FetchBrand($fetchBrandId: String!) {
    fetchBrand(id: $fetchBrandId) {
      id
      name
    }
  }
`;

const FETCH_ORG_BRANDS = gql`
  query FetchBrands {
    fetchBrands {
      created_at
      name
      id
    }
  }
`;

const DELETE_BRAND = gql`
  mutation DeleteBrand($deleteBrandId: String!) {
    deleteBrand(id: $deleteBrandId)
  }
`;

const DeleteBrandModal = () => {
  const { showDeleteBrandModal, setShowDeleteBrandModal, deleteBrandModalData } = useModalContext();
  const [brandName, setBrandName] = useState("");

  const [fetchBrand, { loading: fetchBrandLoading }] = useLazyQuery(FETCH_BRAND_INFO, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.fetchBrand) {
        setBrandName(data.fetchBrand.name);
      }
    },
    onError: (error) => {
      console.error("Error fetching brand", error);
      Sentry.captureException(error);
    },
  });

  useEffect(() => {
    if (deleteBrandModalData?.brand_id) {
      fetchBrand({ variables: { fetchBrandId: deleteBrandModalData.brand_id } });
    }
  }, [deleteBrandModalData?.brand_id, fetchBrand]);

  const [deleteBrand, { loading: deleteBrandLoading }] = useMutation(DELETE_BRAND, {
    onCompleted: (data) => {
      successToast("Brand deleted successfully");
      // update brand in local storage
      if (deleteBrandModalData?.brand_id) {
        updateBrandInCache(deleteBrandModalData.brand_id, { deleted_at: new Date().toISOString() });
      }
      setShowDeleteBrandModal(false);
    },
    onError: (error) => {
      console.error("Error deleting brand", error);

      errorToast("Failed to delete brand");
      errorToast(error?.message);
    },
    refetchQueries: ["fetchOrganization"],
  });

  const handleDelete = () => {
    if (deleteBrandModalData?.brand_id) {
      deleteBrand({
        variables: {
          deleteBrandId: deleteBrandModalData.brand_id,
        },
      });
    }
  };

  return (
    <Modal open={showDeleteBrandModal} onClose={() => setShowDeleteBrandModal(false)} popupAnimation>
      <ContentDiv>
        <PopupText1>Delete Brand</PopupText1>
        <PopupText2>Are you sure you want to delete the brand "{brandName}"? This action cannot be undone.</PopupText2>
        <WarningText>Deleting this brand will move all associated leads to retired.</WarningText>
        <WarningText>This will also unverify the domain for this brand.</WarningText>
      </ContentDiv>
      <ButtonDiv>
        <PhoenixAppButton
          buttonType="secondary"
          onClick={() => setShowDeleteBrandModal(false)}
          uppercase
          buttonTextFontSize={10}
          style={{ letterSpacing: "1px" }}
        >
          Cancel
        </PhoenixAppButton>
        <PhoenixAppButton
          buttonType="secondary"
          variant="danger-outline"
          uppercase
          buttonTextFontSize={10}
          style={{ letterSpacing: "1px" }}
          onClick={handleDelete}
          disabled={deleteBrandLoading || fetchBrandLoading}
        >
          Delete
        </PhoenixAppButton>
      </ButtonDiv>
    </Modal>
  );
};

const PopupText1 = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 16px;
`;

const PopupText2 = styled(AppText)`
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
  text-align: center;
`;

const WarningText = styled(AppText)`
  font-size: 14px;
  line-height: 20px;
  color: ${theme.DANGER500};
  margin-top: 16px;
  text-align: center;
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 476px;
  padding: 40px;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 40px;
  border-top: 1px solid ${theme.border.neutral.secondary};
`;

export { DeleteBrandModal };
