import { gql, useQuery } from "@apollo/client";
import React from "react";
import { OptionItem } from "src/types";

const FETCH_PINNED_LEAD_FIELDS = gql`
  query fetchPinnedLeadFields {
    fetchOrganization {
      id
      title_is_business_name
      pinned_lead_fields {
        key
        label
        order
        type
      }
    }
  }
`;

interface IusePinnedLeadFields {
  setLeadCardFields?: React.Dispatch<React.SetStateAction<OptionItem[]>>;
}

const useFetchPinnedLeadFields = ({ setLeadCardFields }: IusePinnedLeadFields) => {
  const { data, loading: loadingPinnedLeadFields } = useQuery(FETCH_PINNED_LEAD_FIELDS, {
    fetchPolicy: "network-only",
    onCompleted({ fetchOrganization }) {
      setLeadCardFields &&
        setLeadCardFields(fetchOrganization.pinned_lead_fields.map((e: any) => ({ label: e.label, value: e.key })));
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  return {
    data: data?.fetchOrganization,
    loading: loadingPinnedLeadFields,
    includesCustomFields: data?.fetchOrganization?.pinned_lead_fields.some((e: any) => e.type === "Custom"),
  };
};

export default useFetchPinnedLeadFields;
