/* eslint-disable @typescript-eslint/no-non-null-assertion */
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import * as React from "react";
import moment from "moment";
import { useState, useMemo, useEffect, useRef, useCallback } from "react";
import { AppButton, AppText, Loading, NewAppButton, FlexDiv, AgGridTooltip } from "../../UI";
import { theme } from "../../../utils/theme";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./SalesTeamsList.css";

interface SalesTeamsListProps {
  step?: any;
  unassignedUsersLoading: any;
  teamsLoading: any;
  usersData: any;
  teamsData: any;
  searchText: string;
  listSelected: any;
  setListSelected: any;
  gridRef: any;
  allUsers: any;
  columns: any;
}

const SalesTeamsList: React.FC<SalesTeamsListProps> = ({
  unassignedUsersLoading,
  teamsLoading,
  usersData,
  teamsData,
  searchText,
  allUsers,
  listSelected,
  setListSelected,
  gridRef,
  columns,
}) => {
  const [selected, setSelected] = useState<any>({});

  if (unassignedUsersLoading || teamsLoading || !usersData) return <Loading />;

  const handleCheck = (id: string, role: string) => {
    const selectedCopy = { ...listSelected };
    if (listSelected[id]) {
      delete selectedCopy[id];
      if (!id) {
        // 'all' checkbox
        delete selectedCopy["undefined"]; // uncheck the 'all' checkbox
        allUsers.forEach((user: any) => delete selectedCopy[user.id]); // uncheck all user checkboxes
      }
    } else {
      selectedCopy[id] = { checked: true, role };
      if (!id) {
        // 'all' checkbox
        allUsers.forEach((user: any) => (selectedCopy[user.id] = { checked: true, role: user.role })); // check all user checkboxes
      }
    }
    setListSelected(selectedCopy);
  };

  const CheckboxComp = (props: any) => {
    return (
      <CheckBox
        type="checkbox"
        checked={listSelected[props.data?.Id]?.checked}
        onChange={() => {}}
        onClick={() => handleCheck(props.data?.Id, props.data?.Role)}
      />
    );
  };

  const handleColumnDef = (column: any) => {
    switch (column.value) {
      case "Id":
        return { headerName: "User Id", field: "Id", headerClass: "ag-salesteam-header", maxWidth: 200 };
      case "Email":
        return { headerName: "Email", field: "Email", headerClass: "ag-salesteam-header", maxWidth: 200 };
      case "SalesPerson":
        return { headerName: "Sales Person", field: "SalesPerson", headerClass: "ag-salesteam-header", maxWidth: 200 };
      case "Role":
        return { field: "Role", headerClass: "ag-salesteam-header", cellRenderer: ColorCardComp };
      case "PhoneNumber":
        return { headerName: "Phone Number", field: "PhoneNumber", headerClass: "ag-salesteam-header" };
      case "Team":
        return { field: "Team", headerClass: "ag-salesteam-header" };
      case "Timezone":
        return { field: "Timezone", headerClass: "ag-salesteam-header" };
      case "PrimaryIndustry":
        return {
          headerName: "Primary Industry",
          field: "PrimaryIndustry",
          headerClass: "ag-salesteam-header",
          tooltipField: "PrimaryIndustry",
          tooltipComponent: AgGridTooltip,
          tooltipComponentParams: { header: "Primary Industry:" },
          maxWidth: 200,
        };
      case "SubIndustry":
        return {
          headerName: "Sub Industry",
          field: "SubIndustry",
          headerClass: "ag-salesteam-header",
          tooltipField: "SubIndustry",
          tooltipComponent: AgGridTooltip,
          tooltipComponentParams: { header: "Sub Industry:" },
          maxWidth: 200,
        };
      case "LeadSource":
        return {
          headerName: "Lead Source",
          field: "LeadSource",
          headerClass: "ag-salesteam-header",
          tooltipField: "LeadSource",
          tooltipComponent: AgGridTooltip,
          tooltipComponentParams: { header: "Lead Source:" },
          maxWidth: 200,
        };
      case "State":
        return {
          field: "State",
          headerClass: "ag-salesteam-header",
          maxWidth: 100,
          tooltipField: "State",
          tooltipComponent: AgGridTooltip,
          tooltipComponentParams: { header: "States:" },
        };
      case "Channel":
        return { field: "Channel", headerClass: "ag-salesteam-header", maxWidth: 200 };
      case "TeamStartDate":
        return { field: "TeamStartDate", headerClass: "ag-salesteam-header" };
    }
  };

  const columnDefs: any = [
    {
      // checkbox column
      headerClass: "ag-salesteam-header",
      headerComponent: CheckboxComp, // handles rendering component in column header
      cellRenderer: CheckboxComp, // handles rendering component in rows
      maxWidth: 40,
      pinned: "left",
      lockPosition: true,
    },
    ...columns?.map((ele: any) => handleColumnDef(ele)),
  ];

  const rowData: any = allUsers?.map((user: any, index: any) => ({
    Id: user?.id,
    SalesPerson: user?.full_name,
    Email: user?.email,
    Role: user?.role,
    PhoneNumber: user?.phone_number ? user.phone_number : user.role !== "SM" ? "-" : "NA",
    Team: user?.team_name ? user.team_name : "Unassigned",
    Timezone: user?.timezone ? user.timezone : "-",
    PrimaryIndustry: user?.industry_white_list_array?.length
      ? user.industry_white_list_array.join(", ")
      : user.role !== "SM"
      ? "Unassigned"
      : "NA",
    SubIndustry: user?.sub_industry_white_list_array?.length
      ? user.sub_industry_white_list_array.join(", ")
      : user.role !== "SM"
      ? "Unassigned"
      : "NA",
    LeadSource: user?.lead_source_white_list_array?.length
      ? user.lead_source_white_list_array.join(", ")
      : user.role !== "SM"
      ? "Unassigned"
      : "NA",
    State: user?.regions_white_list_array?.length
      ? user.regions_white_list_array.join(", ")
      : user.role !== "SM"
      ? "-"
      : "NA",
    Channel: user?.channel?.length ? (user.role !== "SM" ? user.channel : "NA") : user.role !== "SM" ? "-" : "NA",
    TeamStartDate: user?.current_team_start_date ? moment(user.current_team_start_date).format("YYYY-MM-DD") : "NA",
  }));

  return (
    <div className={"ag-theme-salesteam-list"} style={{ height: "600px", width: "100%" }}>
      <AgGridReact
        ref={gridRef} // Ref for accessing Grid's API3
        rowData={rowData}
        columnDefs={columnDefs}
        suppressMovableColumns={true} // enable/disable column re-ordering
        suppressDragLeaveHidesColumns={true} // stops columns from being removed when dragged out
        tooltipMouseTrack={true}
        tooltipShowDelay={800} // delay before showing tooltip while hovering
        tooltipHideDelay={30000} // delay before hiding tooltip while hovering
        enableCellTextSelection={true}
        onGridSizeChanged={(params: any) => {
          // auto size columns
          params.columnApi.autoSizeAllColumns();
        }}
      />
    </div>
  );
};

interface ColorCardProps {
  variant?: string;
}

const ColorCard = styled.div<ColorCardProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 27px;
  max-width: fit-content;
  max-height: 22px;
  margin-top: 8px;

  text-align: center;
  color: ${(props) => (props.variant === "primary" ? theme.BLACK_COLOR : theme.WHITE_COLOR)};
  background-color: ${(props) =>
    props.variant === "primary"
      ? theme.TERTIARY500
      : props.variant === "secondary"
      ? theme.PRIMARY500
      : theme.PILL_GRAY};
  border-radius: 4px;

  font-weight: 500;
  font-size: 8px;
  padding: 6px 8px 6px 8px;
`;

interface CheckboxProps {
  checked: boolean;
}

const CheckBox = styled.input<CheckboxProps>`
  width: 16px;
  height: 16px;
  border: 1px solid ${theme.PILL_GRAY};
  border-radius: 4px;
  background-color: ${theme.WHITE_COLOR};
  cursor: pointer;

  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;

  :checked {
    border-color: ${theme.PRIMARY500};
    background-color: ${theme.PRIMARY500};
  }

  :after {
    position: relative;
    left: 1px;
    bottom: 1px;
    content: "✓";

    color: ${(props) => (props.checked ? theme.WHITE_COLOR : "transparent")};
    font-family: ${theme.PRIMARY_FONT};
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
  }
`;

const ColorCardComp = (props: any) => {
  let variant = "primary";
  switch (props.value) {
    case "AE":
      variant = "primary";
      break;
    case "SDR":
      variant = "secondary";
      break;
    case "SM":
      variant = "tertiary";
      break;
    case "ADMIN":
      variant = "tertiary";
      break;
    default:
      variant = "primary";
  }
  return <ColorCard variant={variant}>{props.value}</ColorCard>;
};

export { SalesTeamsList };
