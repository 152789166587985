import React, { useState } from "react";
import { useFormikContext } from "formik";
import { handleCustomObjectRecordDataType } from "src/utils/other-functions";

/**
 * FormChangeTracker - A component that tracks form changes and updates the upToDate state
 *
 * This component can be used inside any Formik form to automatically track when
 * form values change from their initial values and update a parent component's state.
 *
 * @param {Function} setUpToDate - Function to update the upToDate state in the parent component
 * @param {boolean} ignoreFirstUpdate - Whether to ignore the first update
 * @returns {null} - This component doesn't render anything
 */
export const FormChangeTracker: React.FC<{
  setUpToDate: (upToDate: boolean) => void;
  ignoreFirstUpdate?: boolean;
}> = ({ setUpToDate, ignoreFirstUpdate = false }) => {
  const { dirty } = useFormikContext();
  const [initialUpdateCompleted, setInitialUpdateCompleted] = useState(false);

  React.useEffect(() => {
    if (!dirty) return;

    const shouldIgnoreCurentUpdate = ignoreFirstUpdate && !initialUpdateCompleted;

    if (!initialUpdateCompleted) {
      setInitialUpdateCompleted(true);
    }

    if (shouldIgnoreCurentUpdate) return;

    setUpToDate(false);
  }, [dirty, setUpToDate, ignoreFirstUpdate]);

  return null;
};
