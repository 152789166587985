import React, { memo } from "react";
import { AppErrorText, AppText, FlexDiv, Loading } from "src/Components/UI";
import { theme } from "src/utils/theme";
import styled from "styled-components";
import RecordingCard from "./RecordingCard";
import { VoicemailDrop } from "src/types/VoicemailTypes";
import useFetchVoicemailDrops from "src/query-hooks/useFetchVoicemailDrops";
import { useFirstRender } from "src/utils/hooks";

const SavedRecordingsRaw = () => {
  const { data, loading, error } = useFetchVoicemailDrops();
  const hasNoRecordings = data?.fetchVoicemailDrops.length === 0;

  const isFirstLoad = useFirstRender();
  if (isFirstLoad && loading) return <Loading />;

  if (error) return <AppErrorText>Unable to fetch voicemail recordings</AppErrorText>;

  return (
    <FlexDiv gap={24} direction="column">
      <Header>
        <AppText fontSize={18} fontWeight={500} lineHeight={24}>
          Saved Recordings
        </AppText>
      </Header>
      {hasNoRecordings ? (
        <NoRecordings />
      ) : (
        <FlexDiv gap={16} style={{ width: "100%", flexWrap: "wrap" }}>
          {data?.fetchVoicemailDrops
            .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
            .map((recording: VoicemailDrop) => (
              <RecordingCard key={recording.id} voicemailDrop={recording} />
            ))}
        </FlexDiv>
      )}
    </FlexDiv>
  );
};

const NoRecordings = () => {
  return (
    <FlexDiv direction="column" gap={8} align="center" width="364px" style={{ margin: "0 auto" }}>
      <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.NEUTRAL300} textAlign="center">
        Start recording
      </AppText>
      <AppText
        fontSize={14}
        fontWeight={400}
        lineHeight={20}
        textAlign="center"
        style={{
          width: "387px",
        }}
      >
        You don’t have any saved voicemail recordings. Create your first voicemail in the recording area above.
      </AppText>
    </FlexDiv>
  );
};

// important if removed rerenders will happen on inputs from parent state updates
const SavedRecordings = memo(SavedRecordingsRaw);

const Header = styled.div``;
export default SavedRecordings;
