import React, { FC, useContext, useState } from "react";
import { Calendar, Queue } from "./";
import styled, { keyframes } from "styled-components";
import { LeadsFiltersComponent, SkipOrReleaseLeadModal } from "src/Components/modal";
import { CallContext, ModalContext } from "src/context";
import { theme } from "src/utils/theme";
import { CallResultV2 } from "src/Components/Segments/CallSegments";
import { useQuery } from "@apollo/client";
import { FETCH_LEAD } from "src/Components/Segments";
import CallV2 from "src/Components/Segments/CallV2";
import { FlexDiv } from "src/Components/UI";

export const DashboardV2: FC = () => {
  const { showFiltersModal, releaseLeadModal, setReleaseLeadModal, skipLeadModal, setSkipLeadModal } = useContext(
    ModalContext,
  );
  const { callState, isNonCallActivity, callLeadId, callStartTime, phoneNumber } = useContext(CallContext);

  const [selectedQueueIndex, setSelectedQueueIndex] = useState(0);

  const { data } = useQuery(FETCH_LEAD, {
    fetchPolicy: "no-cache",
    variables: { id: callLeadId },
    skip: !callLeadId,
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  return (
    <BodyWrap>
      {releaseLeadModal && (
        <SkipOrReleaseLeadModal
          release
          onCancel={() => setReleaseLeadModal(false)}
          blinds={releaseLeadModal}
          setBlinds={setReleaseLeadModal}
        />
      )}
      {skipLeadModal && (
        <SkipOrReleaseLeadModal
          onCancel={() => setSkipLeadModal(false)}
          blinds={skipLeadModal}
          setBlinds={setSkipLeadModal}
        />
      )}
      {showFiltersModal && (
        <DarkDiv2>
          <SlideInDiv>
            <LeadsFiltersComponent />
          </SlideInDiv>
        </DarkDiv2>
      )}

      {!callState && <Queue selectedQueueIndex={selectedQueueIndex} setSelectedQueueIndex={setSelectedQueueIndex} />}

      <ContentContainer>
        <div style={{ height: "500px" }} />
        <Calendar />
      </ContentContainer>

      {(!!callState || !!isNonCallActivity) && (
        <FlexDiv>
          <CallV2 callState={callState} callStartTime={callStartTime} phoneNumber={phoneNumber} superDialer />
          <CallResultContainer>
            <CallResultV2
              // Displays a title for the non-call activity section
              logNonCallTitle={
                isNonCallActivity
                  ? data?.fetchLead?.business_name
                    ? `${data?.fetchLead?.business_name}`
                    : data?.fetchLead?.first_name || data?.fetchLead?.last_name
                    ? `${data?.fetchLead?.first_name} ${data?.fetchLead?.last_name}`
                    : ""
                  : ""
              }
              isInHeldPhase={data?.fetchLead?.phase === "HeldPhase"}
              lead_primary_email={data?.fetchLead?.primary_email ?? ""}
              lead_full_name={`${data?.fetchLead?.first_name} ${data?.fetchLead?.last_name}`}
              scheduled_event_behavior={data?.fetchLead?.sequence_step?.actions?.[0]?.scheduled_event_behavior}
            />
          </CallResultContainer>
        </FlexDiv>
      )}
    </BodyWrap>
  );
};

// TODO: cleanup the modal wrapper styled components. Currently taken from dash V1
const slideInAnimation = keyframes`
  0% { margin-left: 0px; width: 0px; }
  100% { margin-left: 0px; width: 552px; }
`;
const DarkDiv2 = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: -72px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;
const SlideInDiv = styled.div`
  position: absolute;
  left: 72px;
  width: calc(478px + 72px);
  min-height: 100vh;
  overflow: hidden;
  animation-name: ${slideInAnimation};
  animation-duration: 700ms;
  z-index: 8;
  background-color: ${theme.WHITE_COLOR};
`;

const CallResultContainer = styled.div`
  position: absolute;
  right: 0;

  width: 448px;
  height: 100vh;

  padding: 16px;

  background-color: ${theme.fill.neutral.primary};
`;

const BodyWrap = styled.div`
  display: flex;
  gap: 24px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
  height: 100%;

  padding: 16px 24px 24px 24px;
`;
