import React, { useState } from "react";
import styled from "styled-components";
import { AppText } from "src/Components/UI";
import { theme } from "src/utils/theme";
import { PhoenixStyledTooltip } from "src/Components/Dumb/PhoenixStyledTooltip";
import { info } from "src/images/NewDesign";
import { PhoenixIcon } from "src/Components/UI/Phoenix";
import InboundCallFlowTab from "./inbound-call-flow-tab/InboundCallFlowTab";
import InboundCallFlowSettings from "./inbound-call-flow-tab/InboundCallFlowSettings";
import "./inbound-call-flow-tab/InboundCallFlowTable.css";

const InboundCallFlow = ({ tooltipText }: { tooltipText?: string }) => {
  const [selectedTab, setSelectedTab] = useState<"InboundCallRules" | "Settings">("InboundCallRules");

  return (
    <>
      <Page>
        <PhoenixStyledTooltip id="inbound-call-flow-tooltip" fontSize={10} />
        <TopAlignDiv>
          <OptionsDiv>
            <OptionsLeft>
              <RowDiv>
                <AppText fontSize={22} fontWeight={500} noWrap>
                  Inbound Call Flow
                </AppText>
                <PhoenixIcon
                  data-tip={tooltipText || ""}
                  data-for="inbound-call-flow-tooltip"
                  variant="brand"
                  svg={info}
                  size={16}
                />
              </RowDiv>
              <Tabs>
                <Tab
                  selected={selectedTab === "InboundCallRules"}
                  onClick={() => {
                    setSelectedTab("InboundCallRules");
                  }}
                >
                  Inbound Call Rules
                </Tab>
                <Tab selected={selectedTab === "Settings"} onClick={() => setSelectedTab("Settings")}>
                  Settings
                </Tab>
              </Tabs>
            </OptionsLeft>
            <OptionsRight />
          </OptionsDiv>
        </TopAlignDiv>

        <Main>
          {selectedTab === "InboundCallRules" ? (
            <InboundCallFlowTab selectedTab={selectedTab} />
          ) : (
            <InboundCallFlowSettings />
          )}
        </Main>
      </Page>
    </>
  );
};

interface SelectedProps {
  selected?: boolean;
}

const Tab = styled(AppText)<SelectedProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  border-bottom: solid 2px ${(props) => (props.selected ? theme.BLACK_COLOR : "transparent")};
  padding-bottom: 6px;
  margin-top: 2px;
  color: ${(props) => (props.selected ? theme.BLACK_COLOR : theme.NEUTRAL300)};
  text-transform: uppercase;
`;
const RowDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-left: 24px;
`;

const Page = styled.div`
  height: 100%;
  overflow-y: auto;
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  border-radius: 8px;
  padding-top: 36px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${theme.LIGHT_BLUE};
  width: 100%;
  height: fit-content;

  border-top: 1px solid ${theme.NEUTRAL200};
  gap: 16px;
  padding: 48px;
  margin: 0px;
  height: 100%;
  overflow-y: hidden;
`;

const TopAlignDiv = styled.div`
  height: 90px;
  display: flex;
  justify-items: center;
  margin: 0px 24px;
  flex: 0 0 auto;
  min-height: 0px;
`;

const OptionsDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
`;

const OptionsLeft = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const OptionsRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export { InboundCallFlow };
