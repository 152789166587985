import styled from "styled-components";

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { AgGridTooltip, AppText, NewAppSelect } from "../../../UI";

import { theme } from "../../../../utils/theme";
import { refresh, trash, reorder } from "../../../../images/NewDesign";
import { PhoenixAppButton, PhoenixCheckbox, PhoenixIcon, PhoenixInput, PhoenixRadio } from "../../../UI/Phoenix";
import { debounce } from "lodash";
import InboundCallFlowTable from "./InboundCallFlowTable";
import { useQuery, gql, useMutation } from "@apollo/client";
import { AppErrorText } from "../../../UI";
import { AgGridReact } from "ag-grid-react";

import { SkeletonBlock } from "../../../UI/SkeletonBlock";
import { appToast } from "../../../../utils/toast";
import { info } from "../../../../images/NewDesign";
import { PhoenixStyledTooltip } from "../../../Dumb/PhoenixStyledTooltip";
import { METRIC_TYPES, DATE_RANGES } from "../../../../utils/format";
import "./InboundCallFlowTable.css";

const FETCH_ALL_REPS = gql`
  query {
    fetchOrganization {
      Reps {
        id
        full_name
        close_rate
      }
    }
  }
`;

const FETCH_SETTINGS = gql`
  query fetchCustomInboundCallRuleSettings {
    fetchCustomInboundCallRuleSettings {
      id
      created_at
      distribution_method
      date_range
      metric
      rank_sort_type
      backup_rule
      GlobalRepRouting: GlobalRepInboundCallRouting {
        rep_id
        close_rate
        __typename
      }
    }
  }
`;

const UPDATE_SETTINGS = gql`
  mutation addOrUpdateGlobalInboundCallRoutingRule($data: GlobalInboundCallRoutingRuleInput!, $globalRuleId: String) {
    addOrUpdateGlobalInboundCallRoutingRule(data: $data, global_rule_id: $globalRuleId) {
      created_at
    }
  }
`;

const handleRowDragEnd = async ({
  event,
  settingsData,
  setSettingsData,
}: {
  event: any;
  settingsData: any;
  setSettingsData: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const invalidRowsPresent =
    event?.overIndex === undefined ||
    event?.node?.data === undefined ||
    event.nodes.some((item: any) => item.data === undefined);

  const invalidState =
    !settingsData || !settingsData.rep_ids || !event || !event.node || !event.node.data || invalidRowsPresent;
  if (invalidState) {
    console.log("Invalid state");
    return;
  }

  const currentRepIds = [...settingsData.rep_ids];
  const draggedRowData = event.node.data;

  if (!draggedRowData || !draggedRowData.rep_id) {
    console.log("Invalid row data");
    console.log("Dragged row data: ", draggedRowData);
    return;
  }

  const draggedRowIndex = currentRepIds.indexOf(draggedRowData.rep_id);

  if (draggedRowIndex === -1) {
    console.log("Invalid row index");
    return;
  }

  const newRepIds = [...currentRepIds];
  newRepIds.splice(draggedRowIndex, 1);
  newRepIds.splice(event.overIndex, 0, draggedRowData.rep_id ?? draggedRowData.delete);

  console.log("New rep ids: ", newRepIds);

  setSettingsData({
    ...settingsData,
    rep_ids: newRepIds,
    rank_sort_type: "Manually",
  });
};

const InboundCallFlowTab = ({ selectedTab }: { selectedTab: "InboundCallRules" | "Settings" }) => {
  const history = useHistory();
  const [selectedRepsSearch, setSelectedRepsSearch] = React.useState<string>("");

  const { data: orgData, error: errorOrgInfo, loading: loadingOrgInfo } = useQuery(FETCH_ALL_REPS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  // for the settings we have to have to include of the variables for the mutation
  // I have decided to keep local state which we will optomistically update
  // and then debounce the update to the server

  const [settingsData, setSettingsData] = React.useState<any>({}); // this is the local state for the settings

  useEffect(() => {
    console.log("Settings data: ", settingsData);
  }, [settingsData]);

  const { data: rawSettingsData, error: errorSettings, loading: loadingSettings, refetch: refetchSettings } = useQuery(
    FETCH_SETTINGS,
    {
      variables: {
        transferRuleType: "InboundCall",
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
      onCompleted(data) {
        console.log("Settings data: ", data);
        setSettingsData({
          ...data.fetchCustomInboundCallRuleSettings,
          rep_ids: data.fetchCustomInboundCallRuleSettings.GlobalRepRouting?.map((rep: any) => rep.rep_id),
        });
      },
    },
  );

  // debounce setting updates
  const debouncedUpdateSettings = React.useCallback(
    debounce((data: any) => {
      updateSettings({
        variables: {
          data: {
            backup_rule: data.backup_rule,
            date_range: data.date_range,
            distribution_method: data.distribution_method,
            rank_sort_type: data.rank_sort_type,
            metric: data.metric,
            rep_ids: data.rep_ids,
          },
          globalRuleId: data.id,
        },
      });
    }, 1000),
    [],
  );

  // update the settings if they have changed
  useEffect(() => {
    if (Object.keys(settingsData).length === 0) return;
    // if the data is the same as the raw data returned from the query, then don't update
    if (
      rawSettingsData &&
      settingsData &&
      JSON.stringify(settingsData) ===
        JSON.stringify({
          ...rawSettingsData?.fetchCustomInboundCallRuleSettings,
          rep_ids: rawSettingsData.fetchCustomInboundCallRuleSettings.GlobalRepRouting?.map((rep: any) => rep.rep_id),
        })
    )
      return;

    debouncedUpdateSettings(settingsData);
  }, [settingsData]);

  const [updateSettings] = useMutation(UPDATE_SETTINGS, {
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },

    onCompleted() {
      appToast("Updated settings");
      refetchSettings();
    },
  });

  const PriorityCellRenderer = (props: any) => {
    return (
      <div style={{ display: "flex", height: "100%", width: "100%", alignItems: "center", cursor: "pointer" }}>
        <PhoenixIcon svg={reorder} size={16} />
        <div style={{ marginLeft: "8px" }}>{props.value}</div>
      </div>
    );
  };

  const DeleteRepCellRenderer = (props: any) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          width: "100%",
          cursor: "pointer",
        }}
        onClick={() => {
          setSettingsData({
            ...settingsData,
            rep_ids: settingsData.rep_ids.filter((rep_id: string) => rep_id !== props.data.delete),
          });
        }}
      >
        <PhoenixIcon svg={trash} size={14} color={theme.DANGER600} />
      </div>
    );
  };

  const onEditRule = (rule_id: string) => {
    history.push(`/system-config/inbound-call-flow/${rule_id}`);
  };

  const loadingPage = loadingOrgInfo;

  const loading = loadingOrgInfo || loadingSettings;

  const error = errorOrgInfo || errorSettings;

  if (error) {
    return <AppErrorText>{error.message}</AppErrorText>;
  }

  if (loadingPage) {
    return (
      <>
        <SkeletonBlock width={"100%"} height={400} borderRadius={4} />
        <SkeletonBlock width={"100%"} height={275} borderRadius={4} />
        <SkeletonBlock width={"100%"} height={650} borderRadius={4} />
      </>
    );
  }

  return (
    <Tab>
      <PhoenixStyledTooltip id="inbound-call-rule-tab" place="right" lineHeight={16} />

      <Container>
        <ContainerHeader>
          <ContainerHeaderLeft>
            <AppText fontSize={16} fontWeight={500}>
              Custom Rules
            </AppText>
          </ContainerHeaderLeft>
          <ContainerHeaderRight>
            <PhoenixAppButton
              variant="brand"
              buttonType="secondary"
              onClick={() => {
                history.push(`/system-config/inbound-call-flow/new`);
              }}
            >
              Add New Rule
            </PhoenixAppButton>
          </ContainerHeaderRight>
        </ContainerHeader>
        <ContainerBody>
          <InboundCallFlowTable onEditRule={onEditRule} />
        </ContainerBody>
      </Container>
      <Container>
        <ContainerHeader>
          <ContainerHeaderLeft>
            <ContainerTitle>Custom Rules Settings</ContainerTitle>
          </ContainerHeaderLeft>
          <ContainerHeaderRight />
        </ContainerHeader>
        <ContainerBody>
          <ContainerSubTitle>Rep Availability</ContainerSubTitle>
          <ContainerBoldText>
            If none of the reps in a rule are available, what would you like Sellfire to do?
          </ContainerBoldText>
          <RadioSection>
            <RadioDiv>
              <PhoenixRadio
                selected={settingsData?.backup_rule === "FetchAllAE"}
                onClick={() => {
                  setSettingsData({
                    ...settingsData,
                    backup_rule: "FetchAllAE",
                  });
                }}
              />
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                }}
              >
                <AppText fontSize={12} fontWeight={500}>
                  Expand list of reps to include all permissible reps in your organization
                </AppText>
                <PhoenixIcon
                  svg={info}
                  size={16}
                  data-for="inbound-call-rule-tab"
                  data-tip="by default this is only AEs but can be changed to include other roles in the settings tab."
                />
              </div>
            </RadioDiv>
            <RadioDiv>
              <PhoenixRadio
                selected={settingsData?.backup_rule === "FetchSelectedAE"}
                onClick={() => {
                  setSettingsData({
                    ...settingsData,
                    backup_rule: "FetchSelectedAE",
                  });
                }}
              />
              <AppText fontSize={12} fontWeight={500}>
                Expand list of reps to include your organization's backup pool
              </AppText>
            </RadioDiv>
          </RadioSection>
        </ContainerBody>
      </Container>
      {settingsData?.backup_rule === "FetchSelectedAE" && (
        <Container>
          <ContainerHeader>
            <ContainerHeaderLeft>
              <ContainerTitle>
                {selectedTab === "InboundCallRules" ? "Inbound Call Rule" : "Future Booking"} Backup Pool
              </ContainerTitle>
            </ContainerHeaderLeft>
            <ContainerHeaderRight />
          </ContainerHeader>
          <ContainerBody>
            <ContainerSubTitle>Rep Selection</ContainerSubTitle>
            <RepContainer>
              <RepsDiv>
                <PhoenixInput
                  displayNoContextText
                  placeholder="Search for Reps"
                  searchInput
                  value={selectedRepsSearch}
                  onChange={(e: any) => {
                    setSelectedRepsSearch(e?.target?.value);
                  }}
                />
                <SpacerDiv height={16} />
                <div
                  style={{ display: "flex", gap: "8px", cursor: "pointer" }}
                  onClick={() => setSelectedRepsSearch("")}
                >
                  <PhoenixIcon variant="brand" svg={refresh} size={14} />
                  <AppText
                    fontSize={12}
                    fontWeight={500}
                    style={{
                      color: theme.PRIMARY600,
                    }}
                  >
                    Reset Filters
                  </AppText>
                </div>
                <SpacerDiv height={16} />

                <RepSelectionDiv>
                  {errorOrgInfo ? (
                    <AppErrorText>Error loading reps</AppErrorText>
                  ) : (
                    orgData?.fetchOrganization?.Reps?.map((rep: any) => {
                      if (
                        !selectedRepsSearch ||
                        rep.full_name.toLowerCase().includes(selectedRepsSearch.toLowerCase())
                      ) {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                              <PhoenixCheckbox
                                checked={settingsData?.rep_ids?.includes(rep.id)}
                                onChange={() => {
                                  if (settingsData?.rep_ids?.includes(rep.id)) {
                                    setSettingsData({
                                      ...settingsData,
                                      rep_ids: settingsData?.rep_ids?.filter((id: string) => id !== rep.id),
                                    });
                                  } else {
                                    setSettingsData({
                                      ...settingsData,
                                      rep_ids: [...settingsData?.rep_ids, rep.id],
                                    });
                                  }
                                }}
                              />
                              <AppText fontSize={12} fontWeight={500}>
                                {rep.full_name}
                              </AppText>
                            </div>
                          </div>
                        );
                      }
                    })
                  )}
                </RepSelectionDiv>
              </RepsDiv>

              <div
                className={"ag-theme-inbound-call-flow-list"}
                style={{
                  height: "400px",
                  width: "500px",
                }}
              >
                <AgGridReact
                  rowDragEntireRow={loading ? false : true}
                  rowDragManaged={loading ? false : true}
                  onRowDragEnter={() => {
                    if (loading) return;
                  }}
                  onRowDragLeave={() => {
                    if (loading) return;
                  }}
                  onRowDragMove={() => {
                    if (loading) return;
                  }}
                  onRowDragEnd={async (event: any) => {
                    if (loading) return;

                    try {
                      await handleRowDragEnd({
                        event: event,
                        settingsData: settingsData,
                        setSettingsData: setSettingsData,
                      });
                    } catch (e) {
                      console.log("Error in handleRowDragEnd: ", e);
                    }
                  }}
                  rowData={
                    settingsData?.rep_ids?.map((repId: string, index: number) => {
                      const repInfoFromSettingsFetch = settingsData?.GlobalRepRouting?.find(
                        (rep: any) => rep.rep_id === repId,
                      );

                      const repInfoFromOrgFetch = orgData?.fetchOrganization?.Reps?.find(
                        (rep: any) => rep.id === repId,
                      );

                      console.log("Rep info from org fetch: ", repInfoFromOrgFetch);
                      console.log("Rep info from settings fetch: ", repInfoFromSettingsFetch);

                      console.log("Rep id: ", repId);

                      return {
                        ...repInfoFromSettingsFetch,
                        full_name: repInfoFromOrgFetch?.full_name ?? "N/A",
                        priority: settingsData?.rep_ids?.indexOf(repId) + 1,
                        close_rate: repInfoFromSettingsFetch?.close_rate ?? "...loading",
                        delete: repId,
                      };
                    }) || []
                  }
                  columnDefs={[
                    {
                      headerName: "Priority",
                      field: "priority",
                      width: 100,
                      headerClass: "ag-inbound-call-flow-header",
                      rowDrag: true,
                      cellRendererFramework: PriorityCellRenderer,
                    },
                    {
                      headerName: "Rep Name",
                      field: "full_name",
                      flex: 1,
                      headerClass: `ag-inbound-call-flow-header`,
                    },
                    {
                      headerName: `Close Rate (${
                        DATE_RANGES.find((dateRange) => dateRange.value === settingsData?.date_range)?.label ??
                        DATE_RANGES[0].label
                      })`,
                      field: "close_rate",
                      width: 200,
                      headerClass: `ag-inbound-call-flow-header`,
                      headerTooltip: "Timeframe is configured in the below section under 'Dynamically'.",
                      tooltipComponent: AgGridTooltip,
                      tooltipComponentParams: {
                        showList: false,
                      },
                      headerComponentFramework: (props: any) => {
                        return (
                          <div
                            style={{
                              overflow: "show",
                            }}
                          >
                            <PhoenixStyledTooltip id="close-rate-tooltip" effect="solid" place="top" />
                            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                              {props.displayName}
                              <PhoenixIcon svg={info} size={12} />
                            </div>
                          </div>
                        );
                      },
                    },
                    {
                      headerName: "Delete",
                      field: "delete",
                      cellRendererFramework: DeleteRepCellRenderer,
                      width: 60,
                      headerClass: `ag-inbound-call-flow-header`,
                    },
                  ]}
                />
              </div>
            </RepContainer>
            <SpacerDiv height={24} />

            <ContainerSubTitle>Priority Order of Reps</ContainerSubTitle>
            <ContainerBoldText>
              How would you like to determine the order in which the above Reps are prioritized?{" "}
            </ContainerBoldText>
            <RadioSection
              style={{
                marginBottom: "16px",
              }}
            >
              <RadioDiv>
                <PhoenixRadio
                  selected={settingsData?.rank_sort_type === "Manually"}
                  onClick={() => {
                    // update the settings
                    setSettingsData({
                      ...settingsData,
                      rank_sort_type: "Manually",
                    });
                  }}
                />
                <AppText fontSize={12} fontWeight={500}>
                  Manually (by Priority number)
                </AppText>
              </RadioDiv>
              <RadioDiv>
                <PhoenixRadio
                  selected={settingsData?.rank_sort_type === "Dynamically"}
                  onClick={() => {
                    // update the settings
                    setSettingsData({
                      ...settingsData,
                      rank_sort_type: "Dynamically",
                    });
                  }}
                />
                <AppText fontSize={12} fontWeight={500}>
                  Dynamically
                </AppText>
              </RadioDiv>
            </RadioSection>
            {settingsData?.rank_sort_type === "Dynamically" && (
              <>
                <div
                  style={{
                    width: "336px",
                  }}
                >
                  <NewAppSelect
                    options={METRIC_TYPES}
                    placeholder="Select Metric"
                    onChange={(option) => {
                      setSettingsData({
                        ...settingsData,
                        metric: option?.value,
                      });
                    }}
                    value={settingsData?.metric ?? METRIC_TYPES[0].value}
                    title="Metric"
                  />
                </div>
                <div
                  style={{
                    width: "336px",
                  }}
                >
                  <NewAppSelect
                    options={DATE_RANGES}
                    placeholder="Select Date Range"
                    onChange={(option) => {
                      setSettingsData({
                        ...settingsData,
                        date_range: option?.value,
                      });
                    }}
                    title="Date Range"
                    value={settingsData?.date_range ?? DATE_RANGES[0].value}
                  />
                </div>
              </>
            )}
          </ContainerBody>
        </Container>
      )}
    </Tab>
  );
};

const Tab = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const RepContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 120px;
  border-bottom: 1px solid ${theme.NEUTRAL300};
`;
const RepsDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContainerTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const ContainerSubTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const RadioDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RadioSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const RepSelectionDiv = styled.div`
  gap: 8px;
  height: 300px;
  overflow: auto;
  padding: 8px;
  width: 336px;
  display: flex;
  flex-direction: column;
`;
const SpacerDiv = styled.div<{ height?: number | string }>`
  height: ${(props) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "16px"};
  min-height: ${(props) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "16px"};
`;

const ContainerBoldText = styled(AppText)`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const Container = styled.div`
  width: 100%;
  /* height: 100%; */
  background-color: ${theme.WHITE_COLOR};
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${theme.NEUTRAL300};
  margin-bottom: 16px;
`;

const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 16px;
`;

const ContainerHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;
const ContainerHeaderRight = styled.div`
  display: flex;
  align-items: center;
`;
const ContainerBody = styled.div``;

export default InboundCallFlowTab;
