import React, { useState, useRef, useCallback, useMemo, useEffect } from "react";
import { AppText } from "src/Components/UI";
import { PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import { OptionItem } from "src/types";
import { theme } from "src/utils/theme";
import DynamicDropdown, { DropdownMoreBadge } from "./DynamicDropdown";
import { hasValue } from "src/utils/misc";
import { useOpenOnMoreFilter } from "src/utils/hooks";

type ZipCodeFilterProps = {
  isMoreFilter?: boolean;
  onChange: (value: string[]) => void;
  removeFilter?: () => void;
  value: string[];
};

const ZipCodeFilter = ({ isMoreFilter, onChange, removeFilter, value }: ZipCodeFilterProps) => {
  const [localOptions, setLocalOptions] = useState<OptionItem[]>([]);
  const [localValue, setLocalValue] = useState<string[]>(value ?? []);
  const [isOpen, setIsOpen] = useState(false);
  const hasChangesRef = useRef(false);

  useOpenOnMoreFilter(value, setIsOpen, isMoreFilter);

  useEffect(() => {
    if (!isOpen) {
      setLocalValue(value ?? []);
      hasChangesRef.current = false;
    }
  }, [value, isOpen]);

  useEffect(() => {
    hasChangesRef.current = JSON.stringify(localValue) !== JSON.stringify(value);
  }, [localValue, value]);

  useEffect(() => {
    setLocalOptions(
      localValue.map((zipCode) => ({
        label: zipCode,
        value: zipCode,
      })),
    );
  }, []);

  const selectedOptions = useMemo(() => localOptions.filter((o) => localValue?.includes(o.value.toString())), [
    localOptions,
    localValue,
  ]);

  const moreItems = localValue?.length - 1;

  const handleIsOpenChange = useCallback(
    (newIsOpen: boolean) => {
      if (!newIsOpen && hasChangesRef.current) {
        onChange?.(localValue);
        hasChangesRef.current = false;
      }
      setIsOpen(newIsOpen);
    },
    [localValue, onChange],
  );

  const handleClear = useCallback(() => {
    setLocalValue([]);
    setLocalOptions([]);
    hasChangesRef.current = true;
  }, []);

  const handleCreate = useCallback((input: string) => {
    if (!input) return;
    const newOption = {
      label: input,
      value: input,
    };
    setLocalOptions((prev) => [...prev, newOption]);
    setLocalValue((prev) => [...(prev || []), input]);
    hasChangesRef.current = true;
  }, []);

  const handleChange = useCallback((items: OptionItem[]) => {
    const newValues = items.map((item) => item.value.toString());
    setLocalValue(newValues);
    hasChangesRef.current = true;
  }, []);

  const headerContent = useMemo(
    () => (
      <>
        <AppText fontSize={12} fontWeight={500} color={theme.text.brand.primary}>
          {selectedOptions?.[0]?.label}
        </AppText>
        {moreItems ? (
          <DropdownMoreBadge>
            <AppText fontSize={12} fontWeight={500} color={theme.text.neutral.inverse}>
              +{moreItems}
            </AppText>
          </DropdownMoreBadge>
        ) : null}
      </>
    ),
    [selectedOptions, moreItems],
  );

  return (
    <DynamicDropdown<string[]>
      isOpen={isOpen}
      label="Zip Code"
      onClear={handleClear}
      onRemove={hasValue(localValue) || isMoreFilter ? removeFilter : undefined}
      renderHeader={headerContent}
      setIsOpen={handleIsOpenChange}
      value={localValue}
    >
      <PhoenixMultiSelect
        creatableOptions
        isClearable={false}
        marginBottom={false}
        menuPosition="fixed"
        menuShouldBlockScroll
        name="zip_code"
        onChange={handleChange}
        onCreateOption={handleCreate}
        options={localOptions}
        placeholder="Type Zip Code"
        value={selectedOptions}
      />
    </DynamicDropdown>
  );
};

export default ZipCodeFilter;
