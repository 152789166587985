import React, { useContext, useState, useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import { gql, useMutation, useQuery } from "@apollo/client";
import { theme } from "../../../utils/theme";
import { AppText, FlexDiv, Loading } from "../../UI";
import { PageSection } from "./PageSection";
import { FieldSection } from "./FieldSection";
import { PreviewSection } from "./PreviewSection";
import { ModalContext } from "../../../context";
import { AddSalePageModal, DeleteFieldModal, DeleteSalePageModal } from "../../modal";
import { DropResult } from "react-beautiful-dnd";
import { SaleConfigPage } from "../../../utils/misc";
import { cloneDeep } from "lodash";
import { PhoenixAppButton, PhoenixIcon } from "../../UI/Phoenix";
import { ChoosePageModal } from "../../modal/MakeSaleConfig/ChoosePageModal";
import { errorToast, successToast } from "../../../utils/toast";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import { info } from "../../../images/NewDesign";

const FETCH_SALE_CONFIG = gql`
  query fetchSaleConfig {
    fetchOrganization {
      id
      SaleConfig {
        id
        SaleConfigPages {
          id
          order
          title
          sections {
            id
            type
            required
            content
            system_field
            custom_field_id
          }
        }
        saleConfigPagesVisibleOnly {
          id
          order
          title
          sections {
            id
            type
            required
            content
            system_field
            custom_field_id
          }
        }
      }
      custom_fields {
        id
        type
        key
        value
        allow_reps_to_edit
      }
    }
  }
`;

const FETCH_REQUIRED_FIELDS = gql`
  query fetchRequiredFields {
    fetchRequiredFieldsMakeSaleConfig
  }
`;

const CREATE_OR_UPDATE_SALE_CONFIG = gql`
  mutation createOrUpdateSaleConfigPages($input: PagesInput!) {
    createOrUpdateSaleConfigPages(input: $input) {
      id
      updated_at
    }
  }
`;

export type CustomField = {
  id: string;
  type: string;
  key: string;
  value: any;
  allow_reps_to_edit: boolean;
};

export const MakeSaleConfiguration: React.FC = () => {
  const { showAddSalePageModal, showDeleteSalePageModal, showDeleteFieldModal, showChoosePageModal } = useContext(
    ModalContext,
  );

  const [pages, setPages] = useState<SaleConfigPage[]>([]);

  const [selectedPage, setSelectedPage] = useState<SaleConfigPage | undefined>(undefined);
  const [selectedFieldID, setSelectedFieldID] = useState<string>("");

  const [pageTitle, setPageTitle] = useState<string>(selectedPage?.title || "");

  const [hiddenPages, setHiddenPages] = useState<SaleConfigPage[]>([]);

  const isLastPage: boolean = useMemo(() => selectedPage?.id === pages[pages.length - 1]?.id && !!pages.length, [
    pages,
    selectedPage,
  ]);

  const handlePageDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      const newPages = reorder(pages, result.source.index, result.destination.index);
      !!newPages && setPages(newPages);
    },
    [pages],
  );
  const handleFieldDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      const newFields = reorder(selectedPage?.sections, result.source.index, result.destination.index);
      const newSelectedPage = cloneDeep(selectedPage);
      if (!!newSelectedPage?.sections && !!newFields) {
        newSelectedPage.sections = newFields;
        setSelectedPage(newSelectedPage);
      }

      return result;
    },
    [pages, selectedPage],
  );

  const handleHidePage = useCallback(
    (id: string) => {
      let newPages = cloneDeep(pages);
      const curPage = newPages.find((p) => p.id === id);
      if (!!curPage) {
        newPages = newPages.filter((p) => p.id !== id);

        if (newPages.length === 1) {
          newPages[0].required = true;
        }

        // if there are required fields, move them to the last page
        const requiredFields =
          curPage?.sections?.filter((s) =>
            requiredFieldData?.fetchRequiredFieldsMakeSaleConfig.find((f: string) => f === s.system_field),
          ) || [];
        if (!!requiredFields.length) {
          const lastPage = newPages[newPages.length - 1];
          const lastPageSections = lastPage.sections || [];
          lastPage.sections = lastPageSections.concat(requiredFields);
          curPage.sections = curPage.sections.filter(
            (s) => !requiredFieldData?.fetchRequiredFieldsMakeSaleConfig.find((f: string) => f === s.system_field),
          );
        }

        // filter out empty sections
        curPage.sections = curPage.sections.filter((s) => s.content);

        setPages(newPages);
        setSelectedPage(newPages[0]);
        setPageTitle(newPages[0]?.title);
        setHiddenPages([curPage, ...hiddenPages]);
      }
    },
    [pages, hiddenPages],
  );
  const handleShowPage = useCallback(
    (id: string) => {
      let newHiddenPages = cloneDeep(hiddenPages);
      const curPage = newHiddenPages.find((p) => p.id === id);
      let newPages = cloneDeep(pages);

      if (!!curPage) {
        newHiddenPages = newHiddenPages.filter((p) => p.id !== id);

        // handle edgecase of duplicate section coming from hidden page.
        curPage.sections = curPage.sections.filter(
          (s) =>
            (s.system_field === undefined && !s.custom_field_id) ||
            (s.system_field === null && !s.custom_field_id) ||
            !newPages.find((p) => p.sections?.find((s2) => s2.system_field === s.system_field)) ||
            !newPages.find((p) => p.sections?.find((s2) => s2.custom_field_id === s.custom_field_id)),
        );

        newPages = [...newPages, curPage];

        if (newPages.length > 1) {
          const requiredPage = newPages.findIndex((p) => p.required === true);
          if (requiredPage !== -1) {
            newPages[requiredPage].required = false;
          }
        }

        setPages(newPages);
        setSelectedPage(curPage);
        setPageTitle(curPage?.title);
        setHiddenPages(newHiddenPages);
      }
    },
    [pages, hiddenPages],
  );

  useEffect(() => {
    const newPages: SaleConfigPage[] = cloneDeep(pages);
    const curPage = newPages.find((p) => p.id === selectedPage?.id);

    if (!!newPages.length && !!curPage?.sections) {
      curPage.sections = selectedPage?.sections || [];
    }

    setPages(newPages);
  }, [selectedPage]);

  useEffect(() => {
    const newPages: SaleConfigPage[] = cloneDeep(pages);
    const curPage = newPages.find((p) => p.id === selectedPage?.id);
    const newSelectedPage = cloneDeep(selectedPage);

    if (!!curPage && !!newSelectedPage) {
      curPage.title = pageTitle;
      newSelectedPage.title = pageTitle;
    }

    setPages(newPages);
  }, [pageTitle]);

  const { data, loading } = useQuery(FETCH_SALE_CONFIG, {
    fetchPolicy: "network-only",
    onCompleted: ({ fetchOrganization }) => {
      const pageData = cloneDeep(fetchOrganization?.SaleConfig?.saleConfigPagesVisibleOnly || []);
      pageData.sort((a: SaleConfigPage, b: SaleConfigPage) => a.order - b.order);

      // must have atleast 1 page
      if (pageData.length === 1) {
        pageData[0].required = true;
      }

      setPages(pageData);
      setSelectedPage(pageData[0]);
      setPageTitle(pageData[0]?.title);

      let hiddenPages = cloneDeep(fetchOrganization?.SaleConfig?.SaleConfigPages || []);
      hiddenPages = hiddenPages.filter((p: SaleConfigPage) => !pageData.find((pd: SaleConfigPage) => pd.id === p.id));

      setHiddenPages(hiddenPages);
    },
    onError: ({ message, name }) => {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: requiredFieldData, loading: requiredFieldLoading } = useQuery(FETCH_REQUIRED_FIELDS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      console.log("requiredFieldData", data);
    },
    onError: ({ message, name }) => {
      console.log(`Error in ${name}: `, message);
    },
  });

  const [createOrUpdateSaleConfigPages, { loading: loadingCreateOrUpdateSaleConfig }] = useMutation(
    CREATE_OR_UPDATE_SALE_CONFIG,
    {
      onCompleted: (data) => {
        console.log("createOrUpdateSaleConfigPages:", data);
        successToast("Sale Configuration Saved!");
      },
      onError: ({ message, name }) => {
        console.log(`Error in ${name}: `, message);
        errorToast(`Error saving Sale Configuration: ${message}`);
      },
    },
  );

  const handleSubmit = async () => {
    interface iDefinedPage extends SaleConfigPage {
      visible?: boolean;
    }
    const definedHiddenPages = hiddenPages?.map((p) => ({ ...p, visible: false }));
    await createOrUpdateSaleConfigPages({
      variables: {
        input: {
          pages: [...pages, ...definedHiddenPages]?.map((p: iDefinedPage, i) => ({
            order: i,
            title: p.title,
            sections: p.sections?.map((s, j) => ({
              content: s.content,
              required: s.required || s.required_field || false,
              system_field: s.system_field,
              type: s.type,
              order: j,
              custom_field_id: s.custom_field_id,
            })),
            visible: p.visible !== undefined ? p.visible : true,
          })),
        },
      },
    });
  };

  const handleDeleteField = (id: string) => {
    const newSelectedPage = cloneDeep(selectedPage);
    if (newSelectedPage) {
      newSelectedPage.sections = newSelectedPage?.sections?.filter((section) => section.id !== id) || [];
      setSelectedPage(newSelectedPage);
    }
  };

  const isLoading = loading || requiredFieldLoading;

  const invalidPandaDocPosition = useMemo(() => {
    const productPagePos = pages.findIndex((p) => p.sections.find((s) => s.system_field === "PRODUCT_SELECTION"));
    const pandaDocPagePos = pages.findIndex((p) => p.sections.find((s) => s.type === "PANDADOC"));
    const hiddenPagesWithPandaDoc = hiddenPages.find((p) => p.sections.find((s) => s.type === "PANDADOC"));
    return productPagePos > pandaDocPagePos && !hiddenPagesWithPandaDoc && pandaDocPagePos !== -1;
  }, [pages]);

  const disableSave = useMemo(() => {
    const hasEmptyFields = pages.some((p) => p.sections.some((s) => !s.content));
    const hasNoFields = pages.some((p) => !p.sections.length);
    const hasNoTitle = pages.some((p) => !p.title);
    return (
      hasEmptyFields ||
      hasNoFields ||
      hasNoTitle ||
      loading ||
      loadingCreateOrUpdateSaleConfig ||
      invalidPandaDocPosition
    );
  }, [pages, loading, loadingCreateOrUpdateSaleConfig]);

  return (
    <Wrapper>
      {!!showAddSalePageModal && <AddSalePageModal pageState={{ pages, setPages }} />}
      {!!showDeleteSalePageModal && (
        <DeleteSalePageModal
          pageState={{ pages, setPages, setSelectedPage, setPageTitle, hiddenPages, setHiddenPages }}
          id={selectedPage?.id || ""}
          requiredFieldData={requiredFieldData?.fetchRequiredFieldsMakeSaleConfig || []}
          handleHidePage={handleHidePage}
        />
      )}
      {!!showDeleteFieldModal && <DeleteFieldModal id={selectedFieldID} onDelete={handleDeleteField} />}
      {!!showChoosePageModal && (
        <ChoosePageModal
          selectedFieldID={selectedFieldID}
          pageState={{ pages, setPages, selectedPage, setSelectedPage }}
        />
      )}

      <Main>
        {disableSave && <PhoenixStyledTooltip id="disable-save" />}

        <Header>
          <AppText fontSize={22} fontWeight={500} lineHeight={28}>
            Make Sale Configuration
          </AppText>

          <FlexDiv gap={24} align="center">
            {loadingCreateOrUpdateSaleConfig && (
              <div style={{ height: "32px" }}>
                <Loading />
              </div>
            )}
            {invalidPandaDocPosition && (
              <PhoenixIcon
                size={16}
                svg={info}
                variant="danger"
                data-tip="PandaDoc integration page must come after the product selection page."
                data-for={disableSave ? "disable-save" : undefined}
              />
            )}
            <PhoenixAppButton
              buttonType="secondary"
              variant="brand"
              buttonTextFontSize={10}
              uppercase
              disabled={disableSave}
              onClick={() => handleSubmit()}
              data-tip="Please fill in all required information to save your sale configuration."
              data-for={disableSave ? "disable-save" : undefined}
            >
              Save
            </PhoenixAppButton>
          </FlexDiv>
        </Header>

        <Body>
          <PageSection
            pageState={{ pages, setPages, selectedPage, setSelectedPage, setPageTitle, hiddenPages, setHiddenPages }}
            handlePageDragEnd={handlePageDragEnd}
            handleHidePage={handleHidePage}
            handleShowPage={handleShowPage}
            loading={isLoading}
          />
          <FieldSection
            pageState={{ pages, setPages, selectedPage, setSelectedPage, pageTitle, setPageTitle, setSelectedFieldID }}
            handleFieldDragEnd={handleFieldDragEnd}
            loading={isLoading}
            requiredFieldData={requiredFieldData?.fetchRequiredFieldsMakeSaleConfig || []}
            customFieldData={data?.fetchOrganization?.custom_fields || []}
          />
          <PreviewSection
            sectionData={selectedPage?.sections}
            pageTitle={pageTitle}
            loading={isLoading}
            requiredFieldData={requiredFieldData?.fetchRequiredFieldsMakeSaleConfig || []}
            customFieldData={data?.fetchOrganization?.custom_fields || []}
            isLastPage={isLastPage}
          />
        </Body>
      </Main>
    </Wrapper>
  );
};

const reorder = (list: any[] | undefined, startIndex: number, endIndex: number) => {
  if (!!list?.length) {
    const result = cloneDeep(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  }
  return list;
};

const Wrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const Main = styled.div`
  height: 100%;
  background-color: ${theme.WHITE_COLOR};
  overflow: hidden;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 24px 40px 24px 80px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  overflow-y: auto;

  width: 100%;
  height: 100%;

  padding-left: 40px;

  background-color: ${theme.PRIMARY50};
`;
