import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import React, { useContext, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { loggedInUser } from "src/apollo/cache";
import { ModalContext } from "src/context/ModalContext";
import { chevron_left, edit, eye, link, settings, info } from "src/images/NewDesign";
import { generateOPSIQFormsEmbeddableHTML } from "src/utils/format";
import {
  FormField,
  SaleConfigSection,
  cleanUpFormFieldRows,
  convertHiddenCustomFieldValueToValue,
  copyTextToClipboard,
  fieldItemToFormFieldInput,
} from "src/utils/misc";
import { theme } from "src/utils/theme";
import { APP_ROLES, DEFAULT_SYSTEM_FIELDS_FOR_OPSIQ_FORMS } from "src/utils/variables";
import { AppErrorText, AppText, SkeletonBlock } from "src/Components/UI";
import { FlexDiv } from "src/Components/UI/FlexDiv";
import { PhoenixAppButton, PhoenixIcon } from "src/Components/UI/Phoenix";
import { appToast, errorToast } from "src/utils/toast";
import { OPSIQFormFields, OPSIQFormFieldsRef } from "./OPSIQFormFields";
import { OPSIQFormSettings } from "./OPSIQFormSettings";
import { IFetchOPSIQForms } from "./OPSIQFormsPage";
import { DeleteFieldModal } from "src/Components/modal";
import useCustomFields from "src/query-hooks/useCustomFields";

export interface FormExpectedResponse {
  id?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  session_token?: string;
  // need to add to BE model
  form_lead_source?: FormField | null;
  // need to add to BE model
  lead_settings_fields?: SaleConfigSection[];
  organization?: {
    id?: string;
  };
  organization_id?: string;
  template?: {
    id?: string;
  };
  template_id?: string;
  form_name?: string;
  send_email_verification?: boolean;
  is_IBC?: boolean;
  form_field_label_font?: string;
  form_field_label_text_size?: number;
  form_field_label_color?: string;
  form_field_text_font?: string;
  form_field_text_size?: number;
  form_field_text_color?: string;
  button_font?: string;
  button_content?: string;
  button_text_size?: number;
  button_text_color?: string;
  button_fill_color?: string;
  show_button_border?: boolean;
  button_border_color?: string;
  button_corner_radius?: number;
  button_alignment?: string;
  form_background_color?: string;
  show_form_border?: boolean;
  form_border_color?: string;
  form_corner_radius?: number;
  updated_by_user_id?: string;
  last_updated_by?: {
    id?: string;
  };
  created_by_user_id?: string;
  created_by?: {
    id?: string;
  };

  // thank you page

  thank_you_message_font?: string;
  thank_you_message_text_size?: number;
  thank_you_message_text_color?: string;
  thank_you_message_content?: string;
  is_redirection?: boolean;
  redirection_url?: string;

  // fields
  form_fields?: FormField[];
  hidden_fields?: FormField[];
  referrer_fields?: FormField[];

  // form submission
  send_form_submission_email?: boolean;
  form_submission_email_list?: string[];
}

export interface OPSIQFormsFormikProps {
  is_IBC: boolean;
  send_email_verification: boolean;
  template_id?: string;
  form_field_label_font: string;
  form_field_label_text_size: number;
  form_field_label_color: string;
  form_field_text_font: string;
  form_field_text_size: number;
  form_field_text_color: string;
  button_font: string;
  button_content: string;
  button_text_size: number;
  button_text_color: string;
  button_fill_color: string;
  show_button_border: boolean;
  button_border_color: string;
  button_corner_radius: number;
  button_alignment: string;
  form_background_color: string;
  show_form_border: boolean;
  form_border_color: string;
  form_corner_radius: number;
  thank_you_message_font: string;
  thank_you_message_text_size: number;
  thank_you_message_text_color: string;
  thank_you_message_content: string;
  form_fields: FormField[];
  hidden_fields: FormField[];
  referrer_fields: FormField[];
  form_lead_source?: FormField | null;
  is_redirection: boolean;
  redirection_url?: string;
  send_form_submission_email: boolean;
  form_submission_email_list: string[];
}

// main fetch for all form data

const FETCH_FORM = gql`
  query FetchOPSIQForm($opsiqFormId: String) {
    fetchOPSIQForms(opsiq_form_id: $opsiqFormId) {
      id
      is_IBC
      created_by {
        id
        full_name
      }
      session_token
      send_email_verification
      template_id
      form_fields {
        id
        order
        hidden_field
        hidden_value
        system_field
        custom_field_id
        custom_field {
          id
          key
          type
        }
        required
        required_by_IBC
        required_by_form
        row
      }
      hidden_fields {
        id
        order
        hidden_field
        hidden_value
        system_field
        custom_field_id
        custom_field {
          id
          key
          type
        }
        required_by_IBC
        required_by_form
        is_referrer
      }

      referrer_fields {
        id
        order
        hidden_field
        hidden_value
        system_field
        custom_field_id
        custom_field {
          id
          key
          type
        }
        required_by_IBC
        required_by_form
        is_referrer
      }
      form_lead_source {
        id
        order
        hidden_field
        hidden_value
        system_field
        custom_field_id
        custom_field {
          id
          key
          type
        }
        required_by_IBC
        required_by_form
        is_referrer
      }
      button_border_color
      button_corner_radius
      button_fill_color
      button_font
      button_content
      button_text_color
      button_text_size
      button_alignment
      form_background_color
      form_border_color
      form_corner_radius
      form_field_label_color
      form_field_label_font
      form_field_label_text_size
      form_field_text_color
      form_field_text_font
      form_field_text_size
      form_name
      thank_you_message_font
      thank_you_message_text_color
      thank_you_message_text_size
      thank_you_message_content
      last_updated_by {
        id
        full_name
      }
      show_button_border
      show_form_border
      is_redirection
      redirection_url
      send_form_submission_email
      form_submission_email_list
    }

    getInboundConciergeWidgetSettings {
      session_token
    }
  }
`;

const UPDATE_FORM = gql`
  mutation CreateOrUpdateOPSIQForm($opsiqFormInput: OpsiqFormInput!) {
    createOrUpdateOPSIQForm(OpsiqFormInput: $opsiqFormInput) {
      id
    }
  }
`;

const DELETE_FIELD = gql`
  mutation DeleteOpsiqFormField($id: String!) {
    deleteOpsiqFormField(opsiq_form_field_id: $id)
  }
`;

const MAX_FONT_SIZE = 24;
const MIN_FONT_SIZE = 10;

const urlRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?(\?[^?#]*)?(#.*)?$/;

const formValidationSchema = Yup.object().shape({
  is_IBC: Yup.boolean(),
  form_lead_source: Yup.object()
    .shape({
      custom_field_id: Yup.string().nullable(),
      hidden_field: Yup.boolean().nullable(),
      hidden_value: Yup.string().nullable(),
    })
    .required("Please select a lead source")
    .typeError("Please select a lead source"),
  send_email_verification: Yup.boolean(),
  template_id: Yup.string()
    .nullable()
    .when("send_email_verification", {
      is: true,
      then: Yup.string().required("Please select a template").typeError("Please select a template"),
      otherwise: Yup.string().nullable(),
    }),
  form_field_label_font: Yup.string(),
  form_field_label_text_size: Yup.number()
    .min(MIN_FONT_SIZE, `Minimum font size is ${MIN_FONT_SIZE}`)
    .max(MAX_FONT_SIZE, `Maximum font size is ${MAX_FONT_SIZE}`),
  form_field_label_color: Yup.string(),
  form_field_text_font: Yup.string(),
  form_field_text_size: Yup.number()
    .min(MIN_FONT_SIZE, `Minimum font size is ${MIN_FONT_SIZE}`)
    .max(MAX_FONT_SIZE, `Maximum font size is ${MAX_FONT_SIZE}`),
  form_field_text_color: Yup.string(),
  button_font: Yup.string(),
  button_content: Yup.string(),
  button_alignment: Yup.string(),
  button_text_size: Yup.number()
    .min(MIN_FONT_SIZE, `Minimum font size is ${MIN_FONT_SIZE}`)
    .max(MAX_FONT_SIZE, `Maximum font size is ${MAX_FONT_SIZE}`),
  button_text_color: Yup.string(),
  button_fill_color: Yup.string(),
  show_button_border: Yup.boolean(),
  button_border_color: Yup.string(),
  button_corner_radius: Yup.number()
    .min(0, `Minimum corner radius is 0`)
    .max(MAX_FONT_SIZE, `Maximum corner radius is ${MAX_FONT_SIZE}`),
  form_background_color: Yup.string(),
  show_form_border: Yup.boolean(),
  form_border_color: Yup.string(),
  form_corner_radius: Yup.number()
    .min(0, `Minimum corner radius is 0`)
    .max(MAX_FONT_SIZE, `Maximum corner radius is ${MAX_FONT_SIZE}`),
  thank_you_message_font: Yup.string(),
  thank_you_message_text_size: Yup.number()
    .min(MIN_FONT_SIZE, `Minimum font size is ${MIN_FONT_SIZE}`)
    .max(MAX_FONT_SIZE, `Maximum font size is ${MAX_FONT_SIZE}`),
  thank_you_message_text_color: Yup.string(),
  form_fields: Yup.array().of(
    Yup.object()
      .shape({
        custom_field_id: Yup.string().nullable(),
        system_field: Yup.string().nullable(),
        hidden_field: Yup.boolean().nullable(),
        hidden_value: Yup.string().nullable(),
        id: Yup.string().nullable(),
        order: Yup.number().nullable(),
        required: Yup.boolean().nullable(),
      })
      .test("custom-or-system-field", "Either system field or custom field must be provided", function (value) {
        return !!(value?.custom_field_id || value?.system_field);
      }),
  ),
  hidden_fields: Yup.array().of(
    Yup.object().shape({
      custom_field_id: Yup.string().nullable(),
      hidden_field: Yup.boolean().nullable(),
      hidden_value: Yup.string().nullable(),
      id: Yup.string().nullable(),
      order: Yup.number().nullable(),
      required: Yup.boolean().nullable(),
      system_field: Yup.string().nullable(),
    }),
  ),
  referrer_fields: Yup.array().of(
    Yup.object()
      .shape({
        custom_field_id: Yup.string().nullable(),
        hidden_field: Yup.boolean().nullable(),
        hidden_value: Yup.string().nullable(),
      })
      .nullable(),
  ),
  is_redirection: Yup.boolean(),
  redirection_url: Yup.string().when("is_redirection", {
    is: true,
    then: Yup.string()
      .matches(urlRegex, "Please enter a valid URL")
      .required("Redirection URL is required when redirection is enabled"),
    otherwise: Yup.string().nullable(),
  }),

  send_form_submission_email: Yup.boolean(),
  form_submission_email_list: Yup.array().when("send_form_submission_email", {
    is: true,
    then: Yup.array()
      .of(Yup.string())
      .min(1, "At least one email address is required")
      .required("Email list is required when form submission email is enabled"),
    otherwise: Yup.array().nullable(),
  }),
});

interface OPSIQFormDetailProps {
  id: string;
}

export const OPSIQFormDetail = ({ id }: OPSIQFormDetailProps) => {
  const formikRef = useRef<null | FormikProps<OPSIQFormsFormikProps>>(null);
  const formFieldsRef = useRef<OPSIQFormFieldsRef>(null);
  const {
    setShowUpdateFormModal,
    setUpdateFormModalData,
    setShowFormPreviewModal,
    setFormPreviewModalData,
    setShowDeleteFieldModal,
  } = useContext(ModalContext);

  const [selectedFieldID, setSelectedFieldID] = useState<string>("");
  const [selectedFieldType, setSelectedFieldType] = useState<"normal" | "hidden" | "referrer">("normal");

  const { data: customFields = [] } = useCustomFields();

  const { data: formData, error: formError, loading: formLoading } = useQuery<IFetchOPSIQForms>(FETCH_FORM, {
    variables: {
      opsiqFormId: id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      console.log("fetchOPSIQForms", data);
    },
    onError: (error) => {
      console.log("fetchOPSIQForms error", error);
    },
  });

  const [updateForm, { error: updateFormError, loading: updateFormLoading }] = useMutation(UPDATE_FORM, {
    onCompleted: () => {
      appToast("Form updated successfully");
    },
    onError: (error) => {
      appToast("Something went wrong");
      console.log("createOrUpdateOPSIQForm error", error);
    },
    refetchQueries: ["FetchOPSIQForm"],
  });

  const [deleteField] = useMutation(DELETE_FIELD, {
    onCompleted: () => {
      appToast("Field deleted successfully");
    },
  });

  const Form: FormExpectedResponse = useMemo(() => {
    // response is an array but when passing in id we should only get one
    return formData?.fetchOPSIQForms?.[0] || {};
  }, [formData]);

  const session_token = Form?.session_token || "N/A";

  const ibc_session_token = formData?.getInboundConciergeWidgetSettings?.session_token || "N/A";

  const html = generateOPSIQFormsEmbeddableHTML(session_token);

  const error = formError || updateFormError;

  const loading = formLoading || updateFormLoading;

  // const customFields = customFieldsData.filter((i) => i.visible);

  const generatePreview = async () => {
    try {
      if (formikRef.current) {
        if (!formikRef.current.isValid) {
          errorToast("Please fix the form errors before you can proceed");
          return;
        }
        await formikRef.current.submitForm();
      }
      const isIBC = Form?.is_IBC;
      let params = new URLSearchParams();

      params.set("forms_session_token", session_token);
      if (isIBC) {
        params.set("ibc_session_token", ibc_session_token);
      }

      const url = `${process.env.REACT_APP_FORMS_PREVIEW_BASE_URL}/custom-forms?${params.toString()}`;
      window.open(url, "_blank");
    } catch (e) {
      errorToast("Please fix the form errors before you can proceed");
    }
  };

  if (loggedInUser()?.role !== APP_ROLES.ADMIN) return null;

  return (
    <Main>
      <BackButton />
      <TopOptions>
        <FlexDiv gap={8} align="center">
          <AppText fontSize={22} fontWeight={500}>
            {Form?.form_name}
          </AppText>
          <PhoenixIcon
            alt="Edit"
            svg={edit}
            size={16}
            variant="brand"
            onClick={() => {
              setUpdateFormModalData({
                id: Form?.id,
                name: Form?.form_name,
              });
              setShowUpdateFormModal(true);
            }}
          />
        </FlexDiv>

        <RightOptions>
          <PhoenixAppButton
            buttonType="secondary"
            variant="brand-outline"
            onClick={() => {
              copyTextToClipboard(html);
            }}
          >
            <FlexDiv gap={8} align="center">
              <PhoenixIcon svg={link} size={16} variant="brand" pointer />
              Copy HTML
            </FlexDiv>
          </PhoenixAppButton>
          <PhoenixAppButton buttonType="secondary" variant="brand-outline" onClick={generatePreview}>
            <FlexDiv gap={8} align="center">
              <PhoenixIcon
                svg={info}
                size={16}
                variant="brand"
                pointer
                data-for="form-settings-tooltip"
                data-tip={
                  "Generate a test form that you can fill out and submit to create a test lead in Sellfire and validate how the data from that form appears in Sellfire and how that lead routes through any applicable Routing Rules that you have configured."
                }
              />
              <PhoenixIcon svg={settings} size={16} variant="brand" pointer />
              Generate Test Form
            </FlexDiv>
          </PhoenixAppButton>
          <PhoenixAppButton
            buttonType="secondary"
            variant="brand-outline"
            onClick={() => {
              if (formikRef.current) {
                setFormPreviewModalData({
                  values: {
                    ...formikRef.current.values,
                  },
                });
                setShowFormPreviewModal(true);
              }
            }}
          >
            <FlexDiv gap={8} align="center">
              <PhoenixIcon svg={eye} size={16} variant="brand" pointer />
              Preview
            </FlexDiv>
          </PhoenixAppButton>

          <PhoenixAppButton
            buttonType="secondary"
            variant="brand"
            // let's move formik up to include this so we can check things like isValid easier
            disabled={loading}
            onClick={() => {
              if (formikRef.current) {
                formikRef.current.submitForm();
              }
            }}
          >
            Save
          </PhoenixAppButton>
        </RightOptions>
      </TopOptions>

      <Body>
        <FormsListContainer>
          <FormsList>
            {!!error ? (
              <AppErrorText>Something went wrong</AppErrorText>
            ) : !!loading ? (
              <LoadingComponent />
            ) : (
              <Formik<OPSIQFormsFormikProps>
                innerRef={formikRef}
                validationSchema={formValidationSchema}
                enableReinitialize
                validateOnMount
                initialValues={{
                  // settings
                  is_IBC: Form?.is_IBC ?? false,
                  send_email_verification: Form?.send_email_verification ?? false,
                  template_id: Form?.template_id,
                  form_field_label_font: Form?.form_field_label_font ?? "",
                  form_field_label_text_size: Form?.form_field_label_text_size ?? 12,
                  form_field_label_color: Form?.form_field_label_color ?? "",
                  form_field_text_font: Form?.form_field_text_font ?? "",
                  form_field_text_size: Form?.form_field_text_size ?? 12,
                  form_field_text_color: Form?.form_field_text_color ?? "",
                  button_font: Form?.button_font ?? "",
                  button_content: Form?.button_content ?? "",
                  button_text_size: Form?.button_text_size ?? 12,
                  button_text_color: Form?.button_text_color ?? "",
                  button_fill_color: Form?.button_fill_color ?? "",
                  button_alignment: Form?.button_alignment ?? "Left",
                  show_button_border: Form?.show_button_border ?? false,
                  button_border_color: Form?.button_border_color ?? "",
                  button_corner_radius: Form?.button_corner_radius ?? 12,
                  form_background_color: Form?.form_background_color ?? "",
                  show_form_border: Form?.show_form_border ?? false,
                  form_border_color: Form?.form_border_color ?? "",
                  form_corner_radius: Form?.form_corner_radius ?? 0,

                  // thank you message (not implemented on BE yet)
                  thank_you_message_font: Form?.thank_you_message_font ?? "",
                  thank_you_message_text_size: Form?.thank_you_message_text_size ?? 0,
                  thank_you_message_text_color: Form?.thank_you_message_text_color ?? "",
                  thank_you_message_content: Form?.thank_you_message_content ?? "",

                  // fields auto populated on lead
                  hidden_fields: Form?.hidden_fields ?? [],
                  // fields for form for lead to fill out
                  form_fields: Form?.form_fields ?? [],
                  referrer_fields: Form?.referrer_fields ?? [],
                  form_lead_source: Form?.form_lead_source,
                  is_redirection: Form?.is_redirection ?? false,
                  redirection_url: Form?.redirection_url ?? "",
                  send_form_submission_email: Form?.send_form_submission_email ?? false,
                  form_submission_email_list: Form?.form_submission_email_list ?? [],
                }}
                onSubmit={(values, { setSubmitting }) => {
                  updateForm({
                    variables: {
                      opsiqFormInput: {
                        opsiq_form_id: Form?.id,
                        form_name: Form?.form_name,
                        is_IBC: values.is_IBC,
                        // form_lead_source: values.form_lead_source,
                        send_email_verification: values.send_email_verification,
                        template_id: values.template_id ?? null,
                        form_field_label_font: values.form_field_label_font,
                        form_field_label_text_size: +values.form_field_label_text_size,
                        form_field_label_color: values.form_field_label_color,
                        form_field_text_font: values.form_field_text_font,
                        form_field_text_size: +values.form_field_text_size,
                        form_field_text_color: values.form_field_text_color,
                        button_font: values.button_font,
                        button_content: values.button_content,
                        button_text_size: +values.button_text_size,
                        button_text_color: values.button_text_color,
                        button_fill_color: values.button_fill_color,
                        button_alignment: values.button_alignment,
                        show_button_border: values.show_button_border,
                        button_border_color: values.button_border_color,
                        button_corner_radius: +values.button_corner_radius,
                        form_background_color: values.form_background_color,
                        show_form_border: values.show_form_border,
                        form_border_color: values.form_border_color,
                        form_corner_radius: +values.form_corner_radius,
                        thank_you_message_font: values.thank_you_message_font,
                        thank_you_message_text_color: values.thank_you_message_text_color,
                        thank_you_message_text_size: +values.thank_you_message_text_size,
                        thank_you_message_content: values.thank_you_message_content,
                        is_redirection: values.is_redirection,
                        redirection_url: values.redirection_url,
                        send_form_submission_email: values.send_form_submission_email,
                        form_submission_email_list: values.form_submission_email_list,
                        form_fields: cleanUpFormFieldRows(values.form_fields?.map(fieldItemToFormFieldInput)),
                        hidden_fields: [
                          ...values.hidden_fields?.map((v) =>
                            fieldItemToFormFieldInput({
                              ...v,
                              hidden_value: convertHiddenCustomFieldValueToValue(v),
                            }),
                          ),
                          ...values.referrer_fields?.map((v, index) =>
                            fieldItemToFormFieldInput({
                              ...v,
                              order: values.hidden_fields.length + index,
                            }),
                          ),
                          ...(values.form_lead_source
                            ? [
                                fieldItemToFormFieldInput({
                                  ...values.form_lead_source,
                                  order: values.hidden_fields.length + (values.referrer_fields?.length || 0),
                                }),
                              ]
                            : []),
                        ],
                      },
                    },
                  });

                  setSubmitting(false);
                }}
              >
                {({ errors, setFieldValue, values }) => {
                  const handleDeleteField = (id: string) => {
                    const allFields = [...values.form_fields, ...values.hidden_fields, ...values.referrer_fields];

                    const field = allFields.find((field) => field.id === id) as FormField & { custom_id?: boolean };

                    if (!field) return;

                    if (selectedFieldType === "normal") {
                      const newFields = values.form_fields
                        .filter((field) => field.id !== id)
                        ?.map((x, i) => ({ ...x, order: i }));
                      setFieldValue("form_fields", newFields);
                      formFieldsRef.current?.regenerateRows(newFields);
                    } else if (selectedFieldType === "hidden") {
                      const newFields = values.hidden_fields
                        .filter((field) => field.id !== id)
                        ?.map((x, i) => ({ ...x, order: i }));
                      setFieldValue("hidden_fields", newFields);
                    } else if (selectedFieldType === "referrer") {
                      const newFields = values.referrer_fields
                        ?.filter((field) => field.id !== id)
                        ?.map((x, i) => ({ ...x, order: i }));
                      setFieldValue("referrer_fields", newFields);
                    }

                    if (!field?.custom_id) {
                      deleteField({ variables: { id: field.id } });
                    }
                  };

                  return (
                    <Container>
                      <SettingsContainer>
                        <OPSIQFormSettings
                          formID={Form?.id}
                          formName={Form?.form_name}
                          values={values}
                          setFieldValue={setFieldValue}
                          loading={loading}
                          errors={errors}
                          allCustomFields={customFields}
                          allSystemFields={DEFAULT_SYSTEM_FIELDS_FOR_OPSIQ_FORMS}
                          setSelectedFieldID={(id, type) => {
                            setSelectedFieldType(type);
                            setSelectedFieldID(id);
                            setShowDeleteFieldModal(true);
                          }}
                        />
                      </SettingsContainer>
                      <FieldsContainer>
                        <OPSIQFormFields
                          ref={formFieldsRef}
                          values={values}
                          setFieldValue={setFieldValue}
                          loading={loading}
                          allCustomFields={customFields}
                          allSystemFields={DEFAULT_SYSTEM_FIELDS_FOR_OPSIQ_FORMS}
                          setSelectedFieldID={(id) => {
                            setSelectedFieldType("normal");
                            setSelectedFieldID(id);
                            setShowDeleteFieldModal(true);
                          }}
                        />
                      </FieldsContainer>

                      <DeleteFieldModal id={selectedFieldID} onDelete={handleDeleteField} />
                    </Container>
                  );
                }}
              </Formik>
            )}
          </FormsList>
        </FormsListContainer>
      </Body>
    </Main>
  );
};

const BackButton = () => {
  return (
    <Link to={`/system-config/sellfire-forms`} style={{ textDecoration: "none" }}>
      <FlexDiv align="center" style={{ padding: "24px 24px 0px 24px" }}>
        <PhoenixIcon svg={chevron_left} size={16} pointer variant="brand" />
        <AppText
          fontSize={10}
          fontWeight={600}
          uppercase
          style={{ marginLeft: "8px" }}
          color={theme.text.brand.primary}
        >
          Back
        </AppText>
      </FlexDiv>
    </Link>
  );
};

const Main = styled.div`
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  margin-left: 24px;
  border-radius: 8px;
  overflow: hidden;
  transition: margin-left 0.22s ease-in-out;
  min-width: 1200px;
`;

const TopOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 75px;
  padding: 0px 24px 0px 48px;
`;

const RightOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  width: 100%;
  margin: 0px;
  padding: 48px 112px 0px 112px;

  background-color: ${theme.PRIMARY50};
  border-top: 1px solid ${theme.NEUTRAL200};
  overflow-y: auto;
`;

const FormsListContainer = styled.div`
  width: 100%;
`;
const FormsList = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90vh;
  height: 100%;
  padding-bottom: 60px;
`;

const LoadingComponent = () => {
  return (
    <FlexDiv align="center" direction="column" gap={8}>
      {[...Array(6)]?.map((_, i) => (
        <SkeletonBlock
          key={i}
          opacity={1 - i * 0.16}
          delayNumber={i + 1}
          height={73}
          width="calc(100% - 32px)"
          borderRadius={8}
        />
      ))}
    </FlexDiv>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
`;

const SettingsContainer = styled.div`
  height: 70vh;
  overflow-y: auto;
`;

const FieldsContainer = styled.div`
  height: 70vh;
  overflow-y: auto;
`;
