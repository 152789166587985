import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { AppText, FlexDiv } from "../UI";
import { ModalContext } from "../../context";
import { PhoenixIcon, PhoenixInput, PhoenixTextArea, PhoenixAppButton } from "../UI/Phoenix";
import { xIcon } from "../../images/NewDesign";
import { FetchResult, MutationFunctionOptions } from "@apollo/client";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";
import ReactTooltip from "react-tooltip";
import { CoachNote } from "src/types";
import { useDraggable } from "src/utils/hooks";

interface CoachingNoteModalProps {
  conference_id: string;
  addCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  duration: string;
  addCoachingNoteLoading: boolean;
  removeTempRegion: () => void;
  selectedCoachNote?: CoachNote | null;
}

const convertTimeToSeconds = (time: string): number => {
  const [minutes, seconds] = time.split(":")?.map(Number);
  return (minutes || 0) * 60 + (seconds || 0);
};

export const DraggableUpdateCoachingNote: React.FC<CoachingNoteModalProps> = ({
  conference_id,
  addCoachingNote,
  duration,
  addCoachingNoteLoading,
  removeTempRegion,
  selectedCoachNote,
}) => {
  const { setShowCoachingNotesModal, coachingNoteFormattedTimestamp, setCoachingNoteFormattedTimestamp } = useContext(
    ModalContext,
  );

  const { draggableRef, handleDragStart, dragState } = useDraggable();

  const [text, setText] = useState<string>(selectedCoachNote?.text || "");
  const [timestamp, setTimestamp] = useState<{ start: string; end: string }>(
    selectedCoachNote ? { start: selectedCoachNote.start, end: selectedCoachNote.end } : coachingNoteFormattedTimestamp,
  );

  const isOverOneHour = useMemo(() => parseInt(duration) > 3600, [duration]);

  const timestampValid = useMemo(() => {
    const validateTime = (time: string) => {
      const split = time?.split(":");
      return (
        (split?.length === 2 && !!split[0]?.length && !!split[1]?.length) ||
        (split?.length === 3 && !!split[0]?.length && !!split[1]?.length && !!split[2]?.length)
      );
    };

    const validStart = !timestamp?.start || validateTime(timestamp.start);
    const validEnd = !timestamp?.end || validateTime(timestamp.end);

    const startSeconds = convertTimeToSeconds(timestamp.start);
    const endSeconds = convertTimeToSeconds(timestamp.end);
    const maxSeconds = Number(duration);

    return validStart && validEnd && startSeconds <= endSeconds && endSeconds <= maxSeconds;
  }, [timestamp, duration]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [timestampValid, isOverOneHour]);

  useEffect(() => {
    return () => {
      setCoachingNoteFormattedTimestamp({ start: "", end: "" });
    };
  }, []);

  const handleClose = () => {
    setShowCoachingNotesModal(false);
    removeTempRegion();
  };

  const disabled = useMemo(() => !timestampValid || !text, [timestampValid, text]);

  const convertTimestampToSeconds = (
    timestamp: { start: string; end: string },
    isOverOneHour: boolean,
  ): { start: number; end: number } => {
    const convert = (time: string) => {
      const parts = time.split(":")?.map(Number);
      if (isOverOneHour) {
        // HH:MM:SS format
        const [hours, minutes, seconds] = parts;
        return hours * 3600 + minutes * 60 + (seconds || 0);
      } else {
        // MM:SS format
        const [minutes, seconds] = parts;
        return minutes * 60 + (seconds || 0);
      }
    };

    return {
      start: convert(timestamp.start),
      end: convert(timestamp.end),
    };
  };

  const formattedTimestamp = useMemo(() => {
    if (!timestampValid || !timestamp) return 0;
    return convertTimestampToSeconds(timestamp, isOverOneHour);
  }, [timestampValid, timestamp, isOverOneHour]);

  const handleTimestampChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, start: boolean) => {
      let newValue = e.target.value;
      let parts = newValue.split(":");
      let isValid = true;

      if (newValue === "") return;

      // Check and adjust parts based on hour format
      if (isOverOneHour) {
        // HH:MM:SS format
        if (parts[0]) {
          const hours = Number(parts[0]);
          if (isNaN(hours) || hours > 23) isValid = false;
        }
        if (parts[1] && (parts[1].length > 2 || !/^\d*$/.test(parts[1]) || Number(parts[1]) > 59)) isValid = false;
        if (parts[2] && (parts[2].length > 2 || !/^\d*$/.test(parts[2]) || Number(parts[2]) > 59)) isValid = false;
        if (newValue.length > 8) isValid = false;
      } else {
        // MM:SS format
        if (parts[0]) {
          const minutes = Number(parts[0]);
          if (isNaN(minutes) || minutes > 720) {
            parts[0] = "720";
            newValue = parts.join(":");
          }
        }
        if (parts[1] && (parts[1].length > 2 || !/^\d*$/.test(parts[1]) || Number(parts[1]) > 59)) isValid = false;
        if (newValue.length > 5) isValid = false;
      }

      if (isValid) {
        start ? setTimestamp({ ...timestamp, start: newValue }) : setTimestamp({ ...timestamp, end: newValue });
      }
    },
    [timestamp, isOverOneHour],
  );

  return (
    <>
      <PhoenixStyledTooltip id="call-library-tooltip" />
      <WindowContainer ref={draggableRef}>
        <HeaderDiv>
          <FlexDiv gap={8} align="center" justify="space-between" width="100%">
            <DraggableHeaderText onMouseDown={handleDragStart} dragState={dragState}>
              {!!selectedCoachNote?.id ? "Edit" : "Add"} Coaching Note
            </DraggableHeaderText>
            <PhoenixIcon
              svg={xIcon}
              size={16}
              variant="white"
              hoverColor="white"
              pointer
              onClick={() => handleClose()}
            />
          </FlexDiv>
        </HeaderDiv>

        <ModalBody>
          <FlexDiv direction="column" gap={8} padding="16px">
            <AppText fontSize={12} fontWeight={500} lineHeight={18}>
              Timestamp
            </AppText>

            <FlexDiv direction="row" gap={8} align="center" justify="flex-start">
              <PhoenixInput
                // titleText="Start Timestamp"
                // titleTextSpacing={8}
                width={80}
                height={40}
                displayNoContextText
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTimestampChange(e, true)}
                value={timestamp.start}
                variant={timestampValid ? "primary" : "error"}
                style={{ boxShadow: timestampValid ? "none" : `0px 0px 3px 1px ${theme.DANGER600}` }}
                data-tip={
                  isOverOneHour ? "Duration must be formatted 'HH:MM:SS'" : "Duration must be formatted 'MM:SS'"
                }
                data-for={!timestampValid ? "call-library-tooltip" : ""}
              />
              <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                to
              </AppText>
              <PhoenixInput
                // titleText="End Timestamp"
                // titleTextSpacing={8}
                width={80}
                height={40}
                displayNoContextText
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTimestampChange(e, false)}
                value={timestamp.end}
                variant={timestampValid ? "primary" : "error"}
                style={{ boxShadow: timestampValid ? "none" : `0px 0px 3px 1px ${theme.DANGER600}` }}
                data-tip={
                  isOverOneHour ? "Duration must be formatted 'HH:MM:SS'" : "Duration must be formatted 'MM:SS'"
                }
                data-for={!timestampValid ? "call-library-tooltip" : ""}
              />
            </FlexDiv>
          </FlexDiv>

          <WritingArea>
            <StyledPhoenixTextArea
              name="note"
              placeholder="Provide feedback on this part of the call. How are the rep's script adherence, tone, speed, and active listening skills."
              value={text}
              onChange={(e) => setText(e.target.value)}
              fixedSize
            />
            <FlexDiv gap={8} align="center" justify="flex-end" padding="0px 16px 16px 0px">
              <PhoenixAppButton
                buttonType="ghost-large"
                variant="danger-outline"
                uppercase
                onClick={() => handleClose()}
              >
                Cancel
              </PhoenixAppButton>
              <PhoenixAppButton
                buttonType="ghost-large"
                uppercase
                variant="brand"
                onClick={() => {
                  addCoachingNote({
                    variables: {
                      note_id: selectedCoachNote?.id,
                      conference_id,
                      text,
                      start: formattedTimestamp !== 0 ? formattedTimestamp.start : 0,
                      end: formattedTimestamp !== 0 ? formattedTimestamp.end : 0,
                    },
                  });
                  removeTempRegion();
                }}
                disabled={disabled || addCoachingNoteLoading}
              >
                Save
              </PhoenixAppButton>
            </FlexDiv>
          </WritingArea>
        </ModalBody>
      </WindowContainer>
    </>
  );
};

const StyledPhoenixTextArea = styled(PhoenixTextArea)`
  border-radius: 0px;
  border: none;
  box-shadow: none;
  padding: 16px;
  height: 280px;
`;

const WritingArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 420%;
  height: 320px;
  border-left: 1px solid ${theme.NEUTRAL200};
`;

const WindowContainer = styled.div`
  position: fixed;
  top: 270px;
  left: 140px;
  z-index: 1000;

  max-height: 80vh;
  width: 680px;
  height: 354px;

  overflow: hidden;

  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);

  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 8px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

interface HeaderProps {
  dragState?: boolean;
}

const HeaderDiv = styled.div<HeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px;

  background-color: ${theme.PRIMARY900};

  cursor: ${(props) => (!!props.dragState ? "grabbing" : props.dragState === false ? "grab" : "default")};
`;

const DraggableHeaderText = styled(AppText)<HeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: ${theme.WHITE_COLOR};
  padding-left: 8px;
  width: 100%;

  cursor: ${(props) => (!!props.dragState ? "grabbing" : props.dragState === false ? "grab" : "default")};
`;

export const formatTime = (value: string): string => {
  const [hours, minutes, seconds] = value.split(":")?.map(Number);

  if (isNaN(minutes) || isNaN(seconds)) {
    return "0:00";
  }

  let totalSeconds = (hours || 0) * 3600 + minutes * 60 + seconds;

  const formattedHours = Math.floor(totalSeconds / 3600);
  const formattedMinutes = Math.floor((totalSeconds % 3600) / 60);
  const formattedSeconds = totalSeconds % 60;

  if (formattedHours > 0) {
    return `${formattedHours}:${formattedMinutes.toString().padStart(2, "0")}:${formattedSeconds
      .toString()
      .padStart(2, "0")}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds.toString().padStart(2, "0")}`;
  }
};

export const incrementTime = (time: string): string => {
  const [hours, minutes, seconds] = time.split(":")?.map(Number);
  let totalSeconds = (hours || 0) * 3600 + minutes * 60 + seconds + 1;
  return formatTime(totalSeconds.toString());
};

export const decrementTime = (time: string): string => {
  const [hours, minutes, seconds] = time.split(":")?.map(Number);
  let totalSeconds = Math.max((hours || 0) * 3600 + minutes * 60 + seconds - 1, 0);
  return formatTime(totalSeconds.toString());
};
