import { functions, isEqual, isUndefined, omit } from "lodash";
import React, {
  Dispatch,
  FC,
  memo,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { loggedInUser } from "src/apollo/cache";
import { LeadCardTopInfoV2 } from "src/Components/Segments/DashboardSideBarSegments/LeadCardV2/LeadCardTopInfoV2";
import { AppText, FlexDiv } from "src/Components/UI";
import { PhoenixAppButton, PhoenixIcon } from "src/Components/UI/Phoenix";
import { CallContext, LeadCardContext, ModalContext, UserStatusContext } from "src/context";
import {
  chat,
  edit,
  google,
  mail,
  plain_phone_white,
  resting,
  sequences,
  settings,
  user_remove,
  xIcon,
} from "src/images/NewDesign";
import { returnLocalTime } from "src/static";
import { formatBusinessName } from "src/utils/format";
import { theme } from "src/utils/theme";
import styled from "styled-components";
import { SearchMenuV2 } from "./SearchMenuV2";
import moment from "moment";
import { MixpanelActions } from "src/services/mixpanel";
import { SnoozeMenu, RemoveMenu } from "src/Components/Segments/DashboardSideBarSegments/LeadCardV2";

type ActionType = "Release Lead" | "Snooze" | "Remove Task";

interface ICompactLeadCard {
  index?: number;
  expanded?: boolean;
  selectedQueueIndex?: number;
  setSelectedQueueIndex?: Dispatch<SetStateAction<number>>;
  intentData: any; // TODO: define intent shape
  titleIsBusinessName?: boolean;
  maxCardContentHeight?: MutableRefObject<number | undefined>;
  disableAnimations?: boolean;
  customTaskAck: (args: any) => void;
  selectedIntentId?: string;
}

const CompactLeadCard: FC<ICompactLeadCard> = ({
  index,
  expanded,
  selectedQueueIndex,
  setSelectedQueueIndex,
  intentData,
  titleIsBusinessName,
  maxCardContentHeight,
  disableAnimations,
  customTaskAck,
  selectedIntentId,
}) => {
  const { selectedTabDialQueue } = useContext(LeadCardContext);
  const { updateUserToIdle } = useContext(UserStatusContext);
  const { setEditModal, setEditModalLeadId, openSMSChat, setSelfSource } = useContext(ModalContext);
  const { resetNextDial, goToCall } = useContext(CallContext);

  const [collapsed, setCollapsed] = useState(
    expanded || (!isUndefined(index) && !isUndefined(selectedQueueIndex) && index !== selectedQueueIndex),
  );

  const [showSearchMenu, setShowSearchMenu] = useState(false);
  const [showSnoozeMenu, setShowSnoozeMenu] = useState(false);
  const [showRemoveMenu, setShowRemoveMenu] = useState(false);

  const bodyWrapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      bodyWrapRef.current &&
      maxCardContentHeight &&
      bodyWrapRef.current.clientHeight > (maxCardContentHeight?.current || 0)
    ) {
      maxCardContentHeight.current = bodyWrapRef.current.clientHeight;
    }
  }, [bodyWrapRef, maxCardContentHeight]);

  useEffect(() => {
    !isUndefined(selectedQueueIndex) && !isUndefined(index) && setCollapsed(selectedQueueIndex !== index);
  }, [selectedQueueIndex, selectedIntentId]);

  const leadData = useMemo(() => intentData?.lead, [intentData]);
  const isSequenceAction = useMemo(() => intentData?.type === "SequenceAction", [intentData]);

  const leadCardTitle = useMemo(() => {
    if (titleIsBusinessName || isUndefined(titleIsBusinessName)) {
      return formatBusinessName(leadData?.business_name);
    }
    return leadData?.full_name || leadData?.address || "NA";
  }, [leadData, titleIsBusinessName]);

  const expectedAction = useMemo(() => {
    /* TODO: consider zoom and google meet
      (intent?.schedule_item?.zoom_meeting_sellfire_id?.length ? "zoomMeeting" : "") ||
      (intent?.schedule_item?.google_space_sellfire_id?.length ? "googleMeetMeeting" : "")
     */

    const sequenceStep = intentData?.lead?.sequence_step;
    const task = sequenceStep?.actions?.[0]?.task;
    return task || "manualCall";
  }, [intentData]);

  // action button controls
  const showReleaseButton = useMemo(() => isSequenceAction || leadData?.rep_id === loggedInUser()?.id, [
    isSequenceAction,
    leadData,
  ]);
  const showSnoozeButton = useMemo(
    () =>
      isSequenceAction
        ? loggedInUser()?.organization?.allow_snooze_step_action
        : selectedTabDialQueue === "active" && !intentData?.is_scheduled_item,
    [isSequenceAction, intentData],
  );

  const goToCallObjectForPrimary = useMemo(
    () => ({
      lead_id: leadData?.id,
      intentId: leadData?.next_intent_scheduled_or_unscheduled?.id
        ? leadData?.next_intent_scheduled_or_unscheduled?.id
        : undefined,
      intentReplacementId: leadData?.next_intent_scheduled_or_unscheduled?.replacement_lead_intent_id
        ? leadData?.next_intent_scheduled_or_unscheduled?.replacement_lead_intent_id
        : undefined,
      // dialAsNumber: isNextDial ? dialAsNumber : undefined,
    }),
    [leadData],
  );

  const handleAction = useCallback(() => {
    switch (expectedAction) {
      case "manualCall":
        goToCall({
          phoneNumber: leadData?.primary_phone_number,
          ...goToCallObjectForPrimary,
        });
        setSelfSource(false);
        console.log("goToCall: ", {
          phoneNumber: leadData?.primary_phone_number,
          ...goToCallObjectForPrimary,
        });

        resetNextDial();
        return;
      case "manualSms":
        openSMSChat({
          lead_id: leadData?.id ?? "",
          phone_number: leadData?.primary_phone_number ?? "",
          intent: intentData,
        });
        return;
      case "manualEmail":
        return;
      case "customTask":
        return;
      default:
        return;
    }
  }, [expectedAction]);

  return (
    <Container margin={collapsed ? "0px 0px 16px 0px" : "48px 0px 16px 0px"} disableAnimations={disableAnimations}>
      {showSnoozeMenu && (
        <SnoozeMenu
          setShowSnoozeMenu={setShowSnoozeMenu}
          customTaskAck={customTaskAck}
          leadData={leadData}
          intentData={intentData}
          scheduledEventBehavior={leadData?.sequence_step?.actions?.[0]?.scheduled_event_behavior}
          leadIsNextDialOverride={false}
          top="4px"
          right="40px"
          width="170"
        />
      )}
      {showRemoveMenu && (
        <RemoveMenu
          setShowRemoveMenu={setShowRemoveMenu}
          customTaskAck={customTaskAck}
          leadData={leadData}
          intentData={intentData}
          leadIsNextDialOverride={false}
          top="4px"
          right="40px"
          width="200"
        />
      )}
      {showSearchMenu && (
        <SearchMenuV2
          setIsSearchClicked={setShowSearchMenu}
          businessName={leadData?.business_name}
          fullName={leadData?.full_name}
          city={leadData?.city}
        />
      )}

      <ActionsContainer collapsed={collapsed} disableAnimations={disableAnimations}>
        {showReleaseButton && (
          <Action actionType="Release Lead" intent={intentData} isSequenceAction={isSequenceAction} />
        )}
        {showSnoozeButton && (
          <Action
            actionType="Snooze"
            intent={intentData}
            isSequenceAction={isSequenceAction}
            setShowSnoozeMenu={setShowSnoozeMenu}
          />
        )}
        {isSequenceAction && (
          <Action
            actionType="Remove Task"
            intent={intentData}
            isSequenceAction={isSequenceAction}
            setShowRemoveMenu={setShowRemoveMenu}
          />
        )}
      </ActionsContainer>

      <Content collapsed={collapsed}>
        <BodyWrap
          collapsed={collapsed}
          ref={bodyWrapRef}
          height={collapsed ? 0 : (maxCardContentHeight?.current as number)}
          disableAnimations={disableAnimations}
        >
          <ContentHeader>
            <LeadCardTitle
              fontSize={16}
              fontWeight={500}
              lineHeight={22}
              onClick={() => window.open(`/lead-detail/${leadData?.id}`, "_blank", "noreferrer")}
            >
              {leadCardTitle}
            </LeadCardTitle>
            <FlexDiv align="center" gap={8}>
              <PhoenixIcon
                svg={edit}
                size={16}
                variant="brand"
                pointer
                onClick={() => {
                  updateUserToIdle({ do_not_update_if_on_call: true });
                  MixpanelActions.track("Lead Card", {
                    type: "edit form clicked",
                    id: leadData?.id,
                  });
                  setEditModal(true);
                  setEditModalLeadId(leadData?.id);
                }}
              />
              <img
                src={google}
                style={{ width: "22px", height: "22px", cursor: "pointer" }}
                loading="lazy"
                alt={`google ${leadData?.business_name || "NA"}`}
                onClick={() => setShowSearchMenu(true)}
              />
              {isSequenceAction && <SequenceTag />}
            </FlexDiv>
          </ContentHeader>

          <TopInfoWrapper>
            <LeadCardTopInfoV2
              computedLeadData={leadData as any}
              customFieldData={leadData?.visible_custom_fields || []}
              currentTime={returnLocalTime("Eastern Time (EST)")}
            />
          </TopInfoWrapper>
        </BodyWrap>

        <ActionContainer
          collapsed={collapsed}
          onClick={() => {
            !isUndefined(index) && setSelectedQueueIndex && setSelectedQueueIndex(index);
          }}
        >
          <ActionIndicator expectedAction={expectedAction} />

          {!collapsed ? (
            <>
              <FlexDiv direction="column">
                <AppText fontSize={14} fontWeight={500} lineHeight={20} color={theme.text.neutral.primary}>
                  {expectedAction === "manualCall"
                    ? "Phone Call"
                    : expectedAction === "manualSms"
                    ? "SMS"
                    : expectedAction === "manualEmail"
                    ? "Email"
                    : "Custom Task"}
                </AppText>
              </FlexDiv>

              <PhoenixAppButton
                variant="brand"
                buttonType="secondary"
                onClick={() => handleAction()}
                width={196}
                disabled={
                  // !dataLead?.fetchLead?.primary_phone_number ||
                  // !isOwnedByRep ||
                  // !inCallableStatus ||
                  // !!callState ||
                  // !!activeCall
                  false
                }
                style={{ marginLeft: "auto" }}
              >
                {expectedAction === "manualCall"
                  ? "Call Primary"
                  : expectedAction === "manualSms"
                  ? "Compose SMS"
                  : expectedAction === "manualEmail"
                  ? "Compose Email"
                  : "Mark As Complete"}
              </PhoenixAppButton>
            </>
          ) : (
            <FlexDiv>
              <FlexDiv direction="column" justify="space-between" style={{ width: "160px" }}>
                <AppText
                  fontSize={14}
                  fontWeight={500}
                  lineHeight={20}
                  noWrap
                  style={{ maxWidth: "160px", textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  {leadData?.business_name || "NA"}
                </AppText>
                <AppText
                  fontSize={10}
                  fontWeight={400}
                  lineHeight={14}
                  noWrap
                  style={{ maxWidth: "160px", textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  {!leadData?.full_name ? "NA" : leadData?.first_name + " " + leadData?.last_name}
                </AppText>
              </FlexDiv>

              <FlexDiv direction="column" justify="space-between">
                <AppText fontSize={10} fontWeight={400} lineHeight={14} color={theme.text.neutral.secondary}>
                  Last Activity
                </AppText>
                <FlexDiv gap={4} align="center">
                  <AppText fontSize={10} fontWeight={400} lineHeight={14}>
                    {leadData?.last_lead_activity?.type || "NA"}
                  </AppText>
                  {leadData?.last_lead_activity?.type && (
                    <AppText fontSize={10} fontWeight={400} lineHeight={14} color={theme.text.neutral.secondary}>
                      {moment(leadData?.last_lead_activity?.created_at).format("MM/DD/YYYY")}
                    </AppText>
                  )}
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          )}
        </ActionContainer>
      </Content>
    </Container>
  );
};

const Container = styled.div<{ margin?: string; disableAnimations?: boolean }>`
  position: relative;

  width: 100%;
  max-width: 424px;

  margin: ${({ margin }) => margin || "0px"};

  // opacity 0 to start, animation will adjust opacity
  opacity: 0;

  transition: ${({ disableAnimations }) =>
    disableAnimations === undefined || disableAnimations === false ? "margin 0.35s ease-in-out" : "none"};
  animation: ${theme.lightPopup} 0.5s 0.25s ease-in-out forwards;
`;

const ActionsContainer = styled.div<{ collapsed: boolean; disableAnimations?: boolean }>`
  position: absolute;
  top: ${({ collapsed }) => (collapsed ? "0px" : "-32px")};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  width: 100%;
  height: 38px;
  padding: 4px 8px 8px 8px;

  border: 1px solid ${theme.border.brand.tertiary};
  border-radius: 8px 8px 0px 0px;
  background-color: ${theme.fill.brand.secondary};

  & > button:nth-child(1) {
    animation-delay: 0.4s;
  }
  & > button:nth-child(2) {
    animation-delay: 0.6s;
  }
  & > button:nth-child(3) {
    animation-delay: 0.8s;
  }

  transition: ${({ disableAnimations }) =>
    disableAnimations === undefined || disableAnimations === false ? "top 0.35s ease-in-out" : "none"};
`;

interface IAction {
  actionType: ActionType;
  intent: any; // TODO: define intent shape
  isSequenceAction: boolean;
  setShowSnoozeMenu?: Dispatch<SetStateAction<boolean>>;
  setShowRemoveMenu?: Dispatch<SetStateAction<boolean>>;
}

const Action: FC<IAction> = ({ actionType, intent, isSequenceAction, setShowSnoozeMenu, setShowRemoveMenu }) => {
  const { setSkipOrReleaseLeadModalData, setReleaseLeadModal, setSkipLeadModal } = useContext(ModalContext);

  const getIcon = useCallback(() => {
    switch (actionType) {
      case "Release Lead":
        return user_remove;
      case "Snooze":
        return resting;
      case "Remove Task":
        return xIcon;
      default:
        return xIcon;
    }
  }, [actionType]);

  const color = useMemo(() => (actionType === "Remove Task" ? theme.icon.danger.default : theme.icon.brand.default), [
    actionType,
  ]);

  const handleClick = useCallback(() => {
    switch (actionType) {
      case "Release Lead":
        MixpanelActions.track("Release Lead");
        setSkipOrReleaseLeadModalData({
          lead_id: intent?.lead?.id ?? "",
          isNextDial: false,
          intent: intent,
        });
        setReleaseLeadModal(true);
        return;
      case "Snooze":
        if (isSequenceAction) {
          setShowSnoozeMenu && setShowSnoozeMenu(true);
        } else {
          MixpanelActions.track("Skip Lead");
          setSkipOrReleaseLeadModalData({
            lead_id: intent?.lead?.id ?? "",
            isNextDial: false,
            intent: intent,
          });
          setSkipLeadModal(true);
        }
        return;
      case "Remove Task":
        setShowRemoveMenu && setShowRemoveMenu(true);
        return;
      default:
        return;
    }
  }, [actionType, isSequenceAction]);

  return (
    <ActionButton onClick={() => handleClick()}>
      <PhoenixIcon svg={getIcon()} size={12} color={color} hoverColor={color} pointer />
      <AppText fontSize={12} fontWeight={600} lineHeight={18} color={color}>
        {actionType}
      </AppText>
    </ActionButton>
  );
};

const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  cursor: pointer;
  border: none;
  background-color: transparent;

  // opacity 0 to start, animation will adjust opacity
  opacity: 0;

  animation: ${theme.popup} 0.5s ease-in-out forwards;
`;

const Content = styled.div<{ collapsed: boolean }>`
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;

  width: 100%;

  background-color: ${theme.fill.neutral.primary};
  border-radius: 8px;
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px;
  border-bottom: 1px solid ${theme.border.neutral.secondary};
`;

const BodyWrap = styled.div<{ collapsed: boolean; height: number; disableAnimations?: boolean }>`
  display: flex;
  flex-direction: column;

  height: ${({ height }) => height}px;
  overflow: hidden;

  transition: ${({ disableAnimations }) =>
    disableAnimations === undefined || disableAnimations === false ? "height 0.4s ease-in-out" : "none"};
`;

const TopInfoWrapper = styled.div`
  padding: 0px 16px 8px 16px;
`;

const LeadCardTitle = styled(AppText)`
  border: none;
  :hover {
    color: ${theme.text.brand.primary};
    cursor: pointer;
  }
  transition: color 0.15s ease-in-out;
`;

const SequenceTag: FC = () => (
  <TagContainer backgroundColor={theme.fill.warning.secondary}>
    <PhoenixIcon
      svg={sequences}
      size={16}
      color={theme.icon.warning.default}
      hoverColor={theme.icon.warning.default}
      alt="sequence action"
    />
  </TagContainer>
);

const TagContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  padding: 4px;

  border-radius: 4px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const ActionContainer = styled.div<{ collapsed: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;

  width: 100%;
  min-height: 56px;
  max-height: 56px;

  padding-right: 8px;

  border: 1px solid ${theme.fill.neutral.secondary};
  background-color: ${theme.fill.neutral.secondary};
  border-radius: ${({ collapsed }) => (collapsed ? "8px" : "0px 0px 8px 8px")};

  overflow: hidden;
`;

const ActionIndicator: FC<{ expectedAction: string }> = ({ expectedAction }) => {
  const icon = useMemo(() => {
    switch (expectedAction) {
      case "manualCall":
        return plain_phone_white;
      case "manualSms":
        return chat;
      case "manualEmail":
        return mail;
      case "customTask":
        return settings;
      default:
        return plain_phone_white;
    }
  }, [expectedAction]);

  return (
    <ActionIndicatorContainer>
      <PhoenixIcon svg={icon} size={16} color={theme.icon.brand.default} hoverColor={theme.icon.brand.default} />
    </ActionIndicatorContainer>
  );
};

const ActionIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 56px;

  background-color: ${theme.fill.brand.disabled};
`;

const MemoizedCompactLeadCard = memo(CompactLeadCard, (oldProps, newProps) => {
  const omitProps = (props: any) => {
    // Conditionally omit selectedQueueIndex if index is not the same as selectedQueueIndex
    return props.index !== props.selectedQueueIndex && props.index !== oldProps.selectedQueueIndex
      ? omit(props, ["selectedQueueIndex", "selectedIntentId", "index", ...functions(props)])
      : omit(props, ["selectedIntentId", "index", ...functions(props)]);
  };

  const oldP = omitProps(oldProps);
  const newP = omitProps(newProps);

  return isEqual(oldP, newP);
});

export { MemoizedCompactLeadCard as CompactLeadCard };
