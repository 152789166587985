import { gql, useMutation } from "@apollo/client";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as React from "react";
import { useContext, useState } from "react";
import Switch from "react-switch";
import { ModalContext } from "src/context";
import { MixpanelActions } from "src/services/mixpanel";
import styled from "styled-components";
import { restAPI } from "../../apollo";
import { IIntegrations, IntegrationItem } from "../../types/StateTypes";
import { getIntegrationLabel } from "../../utils/format";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { BACKEND_URL } from "../../utils/variables";
import { OutsideEvent } from "../Dumb";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";
import { AppText, FlexDiv } from "../UI";
import { PhoenixAppButton } from "../UI/Phoenix";
import { CaretDownIcon, MenuSelectContainer, SelectTitle } from "../UI/Phoenix/PhoenixMenuSelect";

interface IntegrationCardProps {
  integration: IntegrationItem;
  isConnected: boolean;
  // zoom and google meet need to be enabled by admin for users then connected by the user
  // the other ones are just enabled by the user themselves or admin
  googleMeetIsEnabledForOrg: boolean;
  zoomIsEnabledForOrg: boolean;
  disabled?: boolean;
  optionsButton?: any;
  // whether the integraiton being toggled on and off is for the user or the org
  // currently the only places this is used is for the user in the settings page and the org in the admin integration page
  integrationLevel: "USER" | "ORG";
  handleGoToManageTemplates?: () => void;
  handleGoToIntegrationReporting?: () => void;
  handleGoToSettingsPage?: () => void;
  handleGoToFieldMapping?: () => void;

  // limits in place for video integrations
  selectedVideoIntegration: IIntegrations.Zoom | IIntegrations.GoogleMeet | undefined;
}

const UPDATE_ORG_VIDEO_INTEGRATION = gql`
  mutation UpdateOrgCalendarPreferences($type: CalendarIntegrationType!) {
    updateOrgCalendarPreferences(type: $type)
  }
`;

const getGmailLink = async () => {
  try {
    const api_call = await restAPI
      .get(`${BACKEND_URL}/gmail/getGmailUrl`)
      .then((response) => {
        if (!!response?.data) {
          const loc = response.data.split("OK. Redirecting to ").join("");
          window.open(loc, "_blank");
        }
      })
      .catch((error) => console.log("error here: ", JSON.stringify(error)));
    return api_call;
  } catch (error: any) {
    appToast("Error connecting to Gmail. Please try again later.");
  }
};

const linkZoomGlobal = async (link: string) => {
  try {
    const api_call = await restAPI
      .get(link)
      .then((response) => {
        if (!!response?.data) {
          const loc = response.data.split("OK. Redirecting to ").join("");
          window.open(loc, "_blank");
        }
      })
      .catch((error) => console.log("error here: ", JSON.stringify(error)));
    return api_call;
  } catch (error: any) {
    appToast("Error connecting to Zoom. Please try again later.");
  }
};

const sendHubSpotCode = async () => {
  try {
    const api_call = await restAPI
      .get(`${BACKEND_URL}/hubspot/redirect/`)
      .then((response) => {
        if (!!response?.data) {
          const loc = response.data.split("OK. Redirecting to ").join("");
          window.open(loc, "_blank");
        }
      })
      .catch((error) => console.log("error here: ", JSON.stringify(error)));

    return api_call;
  } catch (error: any) {
    appToast("Error connecting to HubSpot. Please try again later.");
  }
};
interface CreateOptionsProps {
  handleGoToManageTemplates?: () => void;
  handleGoToIntegrationReporting?: () => void;
  handleGoToSettingsPage?: () => void;
  handleGoToFieldMapping?: () => void;
  integration: IntegrationItem;
}

const CreateIntegrationCardOptions: React.FC<CreateOptionsProps> = ({
  handleGoToManageTemplates,
  handleGoToIntegrationReporting,
  handleGoToSettingsPage,
  handleGoToFieldMapping,
  integration,
}) => {
  const [isClicked, setIsClicked] = useState(false);

  const { hubspotBackfill } = useFlags();

  const options = integration?.options || ({} as any);

  return (
    <OutsideEvent onOutsideClick={() => setIsClicked(false)}>
      <ContainerDiv>
        <MenuSelectContainer
          height={32}
          width={128}
          onClick={() => setIsClicked(!isClicked)}
          style={{
            border: "none",
            backgroundColor: theme.NEUTRAL100,
          }}
        >
          <SelectTitle>More Actions</SelectTitle>
          <CaretDownIcon />
        </MenuSelectContainer>

        {isClicked && (
          <OptionsDiv>
            {options.fieldMapping && (
              <Option onClick={() => handleGoToFieldMapping && handleGoToFieldMapping()}>
                Configure Field Mapping
              </Option>
            )}
            {options.settings && (
              <Option onClick={() => handleGoToSettingsPage && handleGoToSettingsPage()}>Settings</Option>
            )}
            {options.manageTemplates && (
              <Option onClick={() => handleGoToManageTemplates && handleGoToManageTemplates()}>Manage Templates</Option>
            )}
            {options.importMissingLeads && hubspotBackfill && (
              <Option onClick={() => handleGoToManageTemplates && handleGoToManageTemplates()}>
                Import Missing Leads
              </Option>
            )}
            {options.integrationReports && (
              <Option onClick={() => handleGoToIntegrationReporting && handleGoToIntegrationReporting()}>
                Integration Reports
              </Option>
            )}
          </OptionsDiv>
        )}
      </ContainerDiv>
    </OutsideEvent>
  );
};

const IntegrationCardV2: React.FC<IntegrationCardProps> = ({
  integration,
  isConnected,
  googleMeetIsEnabledForOrg,
  zoomIsEnabledForOrg,
  integrationLevel,
  handleGoToManageTemplates,
  handleGoToIntegrationReporting,
  handleGoToSettingsPage,
  handleGoToFieldMapping,
  selectedVideoIntegration,
}) => {
  const hasAnyOption = Object.values(integration?.options || {}).some((value) => value);

  const [
    updateOrgVideoIntegration,
    { loading: loadingUpdateOrgVideoIntegration, error: errorUpdateOrgVideoIntegration },
  ] = useMutation(UPDATE_ORG_VIDEO_INTEGRATION, {
    async onCompleted({ updateOrgVideoIntegration }) {
      appToast("Integration enabled successfully!");
    },
    onError({ message }) {
      appToast(message);
    },
    refetchQueries: ["checkIntegrationStatus"],
  });
  const ToggleOrgMeetingIntegration = async (type: "Google" | "Zoom") => {
    await updateOrgVideoIntegration({
      variables: {
        type,
      },
    });
  };

  const linkGoogleMeet = async (link: string) => {
    try {
      const api_call = await restAPI
        .get(link)
        .then((response) => {
          if (!!response?.data) {
            const loc = response.data.split("OK. Redirecting to ").join("");
            window.open(loc, "_blank");
          }
        })
        .catch((error) => console.log("error here: ", JSON.stringify(error)));
      return api_call;
    } catch (error: any) {
      appToast("Error connecting to Google Meet. Please try again later.");
    }
  };

  const {
    showDisconnectIntegrationModal,
    setShowDisconnectIntegrationModal,
    selectedIntegrationDisconnect,
    setSelectedIntegrationDisconnect,
    joinGoogleMeetModalData,
    setJoinGoogleMeetModalData,
  } = useContext(ModalContext);

  const ConnectButtonDiv = ({
    integration,
    isConnected,
    integrationLevel,
  }: {
    integration: IntegrationItem;
    isConnected: boolean;
    integrationLevel: "USER" | "ORG";
  }) => {
    return (
      <ConnectDiv isConnected={isConnected}>
        <ConnectSwitch item={integration} isConnected={isConnected} integrationLevel={integrationLevel} />
        <AppText fontSize={12} fontWeight={500}>
          {isConnected ? "Connected" : "Connect"}
        </AppText>
      </ConnectDiv>
    );
  };

  const ConnectSwitch = ({
    item,
    isConnected,
    integrationLevel,
  }: {
    item: IntegrationItem;
    isConnected: boolean;
    integrationLevel: "USER" | "ORG";
  }) => {
    const organization_id = localStorage.getItem("organization_id");

    // we use a local state value since the connecting process is off site.

    // we are optomisticly updating the UI

    const [switchStateIsConnected, setSwitchStateIsConnected] = useState(isConnected);

    switch (item.name) {
      case "Gmail":
        return (
          <Switch
            onChange={async () => {
              setSwitchStateIsConnected(!switchStateIsConnected);
              await getGmailLink();

              MixpanelActions.track(!switchStateIsConnected ? "Integration Connected" : "Integration Disconnected", {
                integration: item.name,
              });
            }}
            checked={!!switchStateIsConnected}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            height={16}
            width={32}
            handleDiameter={12}
            checkedIcon={false}
            uncheckedIcon={false}
          />
        );
      case IIntegrations.HubSpot:
        return (
          <Switch
            onChange={async () => {
              if (!!switchStateIsConnected) {
                setSelectedIntegrationDisconnect(item.name);
                setShowDisconnectIntegrationModal(true);
              } else {
                await sendHubSpotCode();
                MixpanelActions.track("Integration Connected", { integration: item.name });
              }

              setSwitchStateIsConnected(!switchStateIsConnected);
            }}
            checked={!!switchStateIsConnected}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            height={16}
            width={32}
            handleDiameter={12}
            checkedIcon={false}
            uncheckedIcon={false}
          />
        );
      case IIntegrations.Zoom:
        return (
          <>
            <PhoenixStyledTooltip id="zoom-tooltip" />
            {integrationLevel === "USER" ? (
              <div
                data-tip={
                  switchStateIsConnected
                    ? // unlike google meet Zoom users are set up by admin on zoom site
                      "You have Zoom enabled on your account. Please contact an enablement user to disable this integration."
                    : zoomIsEnabledForOrg
                    ? "Zoom is not enabled on your account. Please contact an enablement user to enable this integration."
                    : "Please contact an enablement user to enable this integration."
                }
                data-for="zoom-tooltip"
              >
                <Switch
                  onChange={async () => {
                    console.log("Reps are unable to alter these settings");
                  }}
                  disabled={true}
                  checked={!!switchStateIsConnected}
                  onColor={theme.PRIMARY500}
                  offColor={theme.NEUTRAL200}
                  height={16}
                  width={32}
                  handleDiameter={12}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
            ) : (
              <div
                data-tip={
                  switchStateIsConnected
                    ? undefined
                    : selectedVideoIntegration === IIntegrations.GoogleMeet
                    ? "Zoom is not available when Google Meet is enabled."
                    : undefined
                }
                data-for="zoom-tooltip"
              >
                <Switch
                  onChange={async () => {
                    if (switchStateIsConnected) {
                      setSelectedIntegrationDisconnect(item.name);
                      setShowDisconnectIntegrationModal(true);
                    } else {
                      // BE needs to toggle the integration on success linking to zoom
                      linkZoomGlobal(item?.url || "");
                    }
                    setSwitchStateIsConnected(!switchStateIsConnected);
                  }}
                  disabled={loadingUpdateOrgVideoIntegration || selectedVideoIntegration === IIntegrations.GoogleMeet}
                  checked={!!switchStateIsConnected}
                  onColor={theme.PRIMARY500}
                  offColor={theme.NEUTRAL200}
                  height={16}
                  width={32}
                  handleDiameter={12}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
            )}
          </>
        );
      case IIntegrations.GoogleMeet:
        return (
          <>
            <PhoenixStyledTooltip id="google-meet-tooltip" />
            {integrationLevel === "USER" ? (
              <div
                data-tip={
                  !googleMeetIsEnabledForOrg
                    ? "Please contact an enablement user to enable this integration."
                    : undefined
                }
                data-for="google-meet-tooltip"
              >
                <Switch
                  onChange={async () => {
                    if (switchStateIsConnected) {
                      setSelectedIntegrationDisconnect(item.name);
                      setShowDisconnectIntegrationModal(true);
                    } else {
                      await linkGoogleMeet(item?.url || "");
                    }
                    setSwitchStateIsConnected(!switchStateIsConnected);
                  }}
                  disabled={!googleMeetIsEnabledForOrg}
                  checked={!!switchStateIsConnected}
                  onColor={theme.PRIMARY500}
                  offColor={theme.NEUTRAL200}
                  height={16}
                  width={32}
                  handleDiameter={12}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
            ) : (
              <div
                data-tip={
                  switchStateIsConnected
                    ? undefined
                    : selectedVideoIntegration === IIntegrations.Zoom
                    ? "You have Zoom enabled for your organization. Please disable Zoom to enable Google Meet."
                    : undefined
                }
                data-for="google-meet-tooltip"
              >
                <Switch
                  onChange={async () => {
                    if (switchStateIsConnected) {
                      setSelectedIntegrationDisconnect(item.name);
                      setShowDisconnectIntegrationModal(true);
                    } else {
                      await ToggleOrgMeetingIntegration("Google");
                    }
                    setSwitchStateIsConnected(!switchStateIsConnected);
                  }}
                  disabled={loadingUpdateOrgVideoIntegration || selectedVideoIntegration === IIntegrations.Zoom}
                  checked={!!switchStateIsConnected}
                  onColor={theme.PRIMARY500}
                  offColor={theme.NEUTRAL200}
                  height={16}
                  width={32}
                  handleDiameter={12}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
            )}
          </>
        );
      case IIntegrations.GoogleCalendar:
        return (
          <>
            <PhoenixStyledTooltip id="google-calendar-tooltip" />
            <div
              data-tip={
                switchStateIsConnected ? "Please contact an enablement user to disable this integration." : undefined
              }
              data-for="google-calendar-tooltip"
            >
              <Switch
                onChange={async () => {
                  if (!!switchStateIsConnected) {
                    // not currently supported by BE must be set through support requests
                    // setSelectedIntegrationDisconnect(item.name);
                    // setShowDisconnectIntegrationModal(true);
                  } else {
                    window.open(`${item.url}?organization_id=${organization_id}`);
                  }
                  setSwitchStateIsConnected(!switchStateIsConnected);
                }}
                checked={!!switchStateIsConnected}
                disabled={switchStateIsConnected}
                onColor={theme.PRIMARY500}
                offColor={theme.NEUTRAL200}
                height={16}
                width={32}
                handleDiameter={12}
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </div>
          </>
        );
      default:
        return (
          <Switch
            onChange={async () => {
              if (!!switchStateIsConnected) {
                setSelectedIntegrationDisconnect(item.name);
                setShowDisconnectIntegrationModal(true);
              } else {
                MixpanelActions.track("Integration Connected", { integration: item.name });
                window.open(`${item.url}?organization_id=${organization_id}`);
              }

              setSwitchStateIsConnected(!switchStateIsConnected);
            }}
            checked={!!switchStateIsConnected}
            disabled={item.disabled}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            height={16}
            width={32}
            handleDiameter={12}
            checkedIcon={false}
            uncheckedIcon={false}
          />
        );
    }
  };

  return (
    <Main isConnected={isConnected}>
      <Header>
        <IntegrationLogo src={integration.logo} width="32px" alt={`${getIntegrationLabel(integration.name)} logo`} />
        <ConnectButtonDiv integration={integration} isConnected={isConnected} integrationLevel={integrationLevel} />
      </Header>
      <AppText fontSize={16} style={{ fontWeight: 600 }}>
        {getIntegrationLabel(integration.name)}
      </AppText>
      <AppText fontSize={14} style={{ color: theme.NEUTRAL300 }}>
        {integration.description}
      </AppText>

      <FlexDiv
        gap={8}
        align="center"
        justify="space-between"
        style={{
          overflow: "visible",
          position: "relative",
          minHeight: 32,
        }}
      >
        {hasAnyOption ? (
          <CreateIntegrationCardOptions
            integration={integration}
            handleGoToManageTemplates={handleGoToManageTemplates}
            handleGoToIntegrationReporting={handleGoToIntegrationReporting}
            handleGoToSettingsPage={handleGoToSettingsPage}
            handleGoToFieldMapping={handleGoToFieldMapping}
          />
        ) : null}
        {integration.learnMoreUrl && (
          <PhoenixAppButton
            buttonType="ghost-small"
            variant="brand"
            onClick={() => {
              window.open(integration.learnMoreUrl, "_blank");
            }}
          >
            Learn More
          </PhoenixAppButton>
        )}
      </FlexDiv>
    </Main>
  );
};

const Main = styled.div<{ isConnected: boolean }>`
  width: 257px;
  border-radius: 8px;
  padding: 16px;
  /* border: 1px solid ${theme.NEUTRAL200}; */

  border: ${({ isConnected }) =>
    isConnected ? `1px solid ${theme.border.neutral.primary}` : `1px solid ${theme.border.neutral.secondary}`};
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const IntegrationLogo = styled.img`
  display: flex;
  flex-direction: row;
`;

const ConnectDiv = styled.div<{ isConnected: boolean }>`
  padding: 12px;
  background-color: ${({ isConnected }) => (isConnected ? theme.fill.brand.secondary : theme.fill.neutral.secondary)};
  border: ${({ isConnected }) =>
    isConnected ? `1px solid ${theme.border.brand.tertiary}` : `1px solid ${theme.border.neutral.secondary}`};
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 8px;
  border-radius: 8px;
`;

const OptionsDiv = styled.div`
  position: absolute;
  left: 0px;
  top: 40px;
  width: 171px;
  background-color: ${theme.WHITE_COLOR};
  /* box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15); */
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 4px;
  z-index: 3;
`;

const Option = styled.div`
  display: flex;

  align-items: center;
  height: 40px;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.2px;
  padding-left: 12px;
  background-color: ${theme.WHITE_COLOR};
  :first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  :hover {
    background-color: ${theme.NEUTRAL200};
  }
`;

const ContainerDiv = styled.div`
  cursor: pointer;
`;

export { IntegrationCardV2 };
