import styled, { keyframes, css, Keyframes } from "styled-components";
import { theme } from "../../utils/theme";

interface SkeletonBlockProps {
  width: string | number;
  height: string | number;
  borderRadius: string | number;
  // overrides
  darkPulseStateColor?: string;
  lightPulseStateColor?: string;

  /** Integer - Used to apply delay
   * @example
   * 1 = "0.1s" delay
   * 2 = "0.2s" delay
   * etc.
   */
  delayNumber?: number;

  /** Animation duration in seconds - controls pulse speed
   * @default 1
   */
  animationDuration?: number;

  opacity?: number;
}

/** Generate pulse animation keyframes */
const createPulseAnimation = (darkColor: string, lightColor: string): Keyframes =>
  keyframes`
    0% {
      background-color: ${lightColor};
    }
    50% {
      background-color: ${darkColor};
    }
    100% {
      background-color: ${lightColor};
    }
  `;

export const SkeletonBlock = styled.div<SkeletonBlockProps>((props) => {
  const {
    width,
    height,
    borderRadius,
    darkPulseStateColor,
    lightPulseStateColor,
    delayNumber,
    opacity,
    animationDuration = 1,
  } = props;
  const lightColor = lightPulseStateColor || theme.PRIMARY50;
  const darkColor = darkPulseStateColor || theme.PRIMARY300;
  const pulseAnimation = createPulseAnimation(darkColor, lightColor);

  return css`
    width: ${typeof width === "number" ? `${width}px` : width};
    height: ${typeof height === "number" ? `${height}px` : height};
    background-color: ${lightColor};
    animation: ${pulseAnimation} ${animationDuration}s ease infinite;
    animation-delay: ${delayNumber ? delayNumber / 10 : 0.2}s;

    opacity: ${opacity ? opacity : 1};

    border-radius: ${typeof borderRadius === "number" ? `${borderRadius}px` : borderRadius};
  `;
});
