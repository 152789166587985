import React from "react";
import styled from "styled-components";
import { AppText } from "..";
import { associated_white, user_white } from "../../../images/NewDesign";
import { theme } from "../../../utils/theme";
import { PhoenixIcon } from "../Phoenix";

interface LeadPillProps {
  leadName: string;
  isAssociatedLead: boolean;
}

export const LeadPill: React.FC<LeadPillProps> = ({ leadName, isAssociatedLead }) => {
  return (
    <PillContainer>
      {!!isAssociatedLead ? (
        <PhoenixIcon svg={associated_white} variant={"brand"} size={12} />
      ) : (
        <PhoenixIcon svg={user_white} variant={"brand"} size={12} />
      )}

      <AppText fontSize={8} fontWeight={600} lineHeight={12} uppercase color={theme.text.brand.primary}>
        {leadName || "N/A"}
      </AppText>
    </PillContainer>
  );
};

const PillContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 24px;
  padding: 4px 8px;
  background-color: ${theme.fill.brand.secondary};
  border-radius: 4px;
  width: max-content;
  max-width: 100%;
  gap: 8px;
`;
