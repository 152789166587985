import React from "react";
import styled from "styled-components";
import { FlexDiv, SkeletonBlock } from "../../../UI";
import { theme } from "../../../../utils/theme";

export const RegisterDomainTabSkeleton: React.FC = () => {
  return (
    <Container>
      {/* Header skeleton */}
      <HeaderContainer>
        <SkeletonBlock width={150} height={32} borderRadius={4} />
        <ButtonsContainer>
          <SkeletonBlock width={80} height={36} borderRadius={4} delayNumber={1} />
          <SkeletonBlock width={80} height={36} borderRadius={4} delayNumber={2} />
        </ButtonsContainer>
      </HeaderContainer>

      <PageContainer>
        {/* Title and description skeleton */}
        <SubTextDiv>
          <SkeletonBlock width={180} height={28} borderRadius={4} delayNumber={3} />
          <SkeletonBlock width={400} height={18} borderRadius={4} delayNumber={4} />
          {/* Current domain text - may or may not be visible */}
          <SkeletonBlock width={250} height={16} borderRadius={4} delayNumber={5} opacity={0.7} />
        </SubTextDiv>

        {/* Domain container */}
        <DomainContainer>
          {/* Domain header */}
          <DomainHeader direction="column" gap={8} style={{ marginBottom: "32px" }}>
            <SkeletonBlock width={60} height={14} borderRadius={4} delayNumber={6} />
            <SkeletonBlock width={300} height={24} borderRadius={4} delayNumber={7} />
          </DomainHeader>

          {/* Domain body - Step 1 view */}
          <DomainBody direction="column" gap={40}>
            {/* Domain input field */}
            <FlexDiv direction="column" gap={8}>
              <SkeletonBlock width={120} height={16} borderRadius={4} delayNumber={8} />
              <SkeletonBlock width={376} height={40} borderRadius={4} delayNumber={9} />
            </FlexDiv>

            {/* Preview section */}
            <FlexDiv direction="column" gap={8}>
              <SkeletonBlock width={150} height={16} borderRadius={4} delayNumber={10} />
              <SkeletonBlock width={200} height={32} borderRadius={4} delayNumber={11} />

              <SkeletonBlock width={150} height={16} borderRadius={4} delayNumber={12} style={{ marginTop: "8px" }} />
              <SkeletonBlock width={200} height={32} borderRadius={4} delayNumber={13} />
            </FlexDiv>
          </DomainBody>

          {/* Action buttons */}
          <FlexDiv gap={8} style={{ marginTop: "20px", float: "right" }}>
            <SkeletonBlock width={100} height={36} borderRadius={4} delayNumber={14} />
          </FlexDiv>
        </DomainContainer>
      </PageContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 24px;
`;

const SubTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 511px;
`;

const DomainContainer = styled.div`
  width: 720px;
  padding: 24px;
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 8px;
`;

const DomainHeader = styled(FlexDiv)``;
const DomainBody = styled(FlexDiv)``;
