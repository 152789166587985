import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { useState, useEffect, useMemo } from "react";
import { AppButton, AppText, AppInput, AppSelect, Loading, AppErrorText } from "../UI";
import { theme } from "../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { InputField, FormSelectField, FormMultiSelectField } from "../Field";
import { toast } from "react-toastify";
import { appToast } from "../../utils/toast";
import { VALIDATE_PHONE_NUMBER } from "../Segments/CallSegments";
import {
  getExtraCountryCodes,
  listBrazil,
  listMexico,
  sortedCountryCodesData,
  statesUSA,
} from "../../static/countries";
import { PHONE_REGEX } from "../../utils/regex";
import { extractNumberWithCountry } from "../../utils/format";
import { MixpanelActions } from "../../services/mixpanel";
import { Modal } from "./Modal";
import { StateDropdown } from "../Segments/LeadCard/StateDropdown";
import { SYSTEM_FIELD_CHAR_LIMIT, SYSTEM_FIELD_EMAIL_CHAR_LIMIT } from "./../../utils/format";
import { getLocalStorage, recursiveObjectTrim, checkIfBrandIsOnboarded } from "../../utils/misc";

import { loggedInUser } from "src/apollo/cache";
import { useFlags } from "launchdarkly-react-client-sdk";
import { OptionItem } from "src/types/genericTypes";
import useMultiBrands from "src/cache-hooks/useIsMultiBrand";
interface DisappearingDivProps {
  visible: boolean;
  close: () => void;
  refetch: (key?: any) => Promise<any>;
}

interface MyFormikProps {
  first_name?: string;
  last_name?: string;
  business_name: string;
  local_primary_phone_number: string;
  email: string;
  lead_source: string;
  industry: string;
  sub_industry: string;
  channel: string;
  city: string;
  state: string;
  country_code: { label: string; value: { iso2: string; code: string } };
  brand_id?: string;
}

const channel_options = [
  {
    label: "Inbound",
    value: "Inbound",
  },
  {
    label: "Outbound",
    value: "Outbound",
  },
];

const GET_POSSIBLE_INDUSTRIES_DATA = gql`
  query fetchIndustryOptions {
    fetchIndustryOptions {
      id
      label
      sub_industries
    }
  }
`;

const GET_POSSIBLE_LEAD_SOURCES_DATA = gql`
  query fetchLeadSourceOptions {
    fetchLeadSourceOptions {
      id
      label
    }
  }
`;

export const ADD_LEAD = gql`
  mutation addLead(
    $first_name: String
    $last_name: String
    $business_name: String
    $phone_number: String!
    $email: String
    $industry: String
    $sub_industry: String
    $lead_source: String
    $channel: CHANNEL
    $status: String
    $title: String
    $content: String
    $city: String
    $state: String
    $country: String
    $country_code: String
    $brand_id: String
  ) {
    addLead(
      first_name: $first_name
      last_name: $last_name
      business_name: $business_name
      phone_number: $phone_number
      email: $email
      industry: $industry
      sub_industry: $sub_industry
      lead_source: $lead_source
      channel: $channel
      status: $status
      title: $title
      content: $content
      city: $city
      state: $state
      country: $country
      country_code: $country_code
      brand_id: $brand_id
    ) {
      id
      lead {
        id
        first_name
        last_name
        full_name
        business_name
        primary_email
        local_primary_phone_number
        industry
        sub_industry
        lead_source
        channel
        status
        title
        content
        city
        state
        country
        primary_phone_number_country_code
        timezone_by_state
      }
    }
  }
`;

const optionsLoading = [
  {
    label: "Loading...",
    value: "",
  },
];

const CreateNewLead: React.FC<DisappearingDivProps> = ({ visible, close, refetch }) => {
  const { showMultiBrandUI, ORG_BRAND_OPTIONS } = useMultiBrands(useFlags);
  const [addLead, { loading, error }] = useMutation(ADD_LEAD, {
    async onCompleted({ addLead }) {
      console.log("addLead: ", addLead);
      if (!addLead) {
        appToast("Error importing lead. Something went wrong.");
        return;
      }
      appToast("Lead created!");
      await refetch();
      close();
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `addLead GraphQL Error: ${message}`,
      });
      console.log("Error in createNewLead: ", message);
    },
  });

  useEffect(() => {
    MixpanelActions.track("Create Lead", {
      type: "Page Load",
    });
  }, []);
  const { data: leadsourceData, loading: leadsourceLoading, error: leadsourceError } = useQuery(
    GET_POSSIBLE_LEAD_SOURCES_DATA,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );
  const { data: industriesData, loading: industriesLoading, error: industriesError } = useQuery(
    GET_POSSIBLE_INDUSTRIES_DATA,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const onboardedBrandOptions: OptionItem[] = useMemo(() => {
    const brands = loggedInUser()?.organization?.Brands || [];
    const onboardedBrands = brands.filter((brand: any) => !brand.deleted_at && checkIfBrandIsOnboarded(brand));
    return onboardedBrands.map((brand: any) => ({ label: brand.name, value: brand.id })) || [];
  }, [loggedInUser()?.organization?.Brands]);

  const [validatePhoneNumber] = useMutation(VALIDATE_PHONE_NUMBER);

  const [oldPhoneValue, setOldPhoneValue] = useState("");

  const newLeadSchema = Yup.object().shape({
    first_name: Yup.string()
      .notRequired()
      .nullable()
      .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
    last_name: Yup.string()
      .notRequired()
      .nullable()
      .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
    business_name: Yup.string()
      .required("Business Name is required")
      .trim()
      .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
    local_primary_phone_number: Yup.string()
      .required("Phone Number is required")
      .max(15, "Must be valid phone number")
      .min(7, "Must be valid phone number")
      .matches(PHONE_REGEX, "Must be a valid phone number")
      // .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      // .matches(/^[0-9]*$/, "Phone number must be numeric")
      .test("local_primary_phone_number", "This number is already in use", async (value: any) => {
        if (!!value && value.length === 10 && value !== oldPhoneValue) {
          if (value !== oldPhoneValue) {
            setOldPhoneValue(value);
          }
          const isPhoneValid = await validatePhoneNumber({
            variables: { phone_number: value || "", lead_id: "" },
          });
          return isPhoneValid.data.validatePhoneNumber;
        }
        return true;
      }),
    email: Yup.string()
      .notRequired()
      .email()
      .nullable()
      .max(SYSTEM_FIELD_EMAIL_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_EMAIL_CHAR_LIMIT} characters or less`),
    country_code: Yup.object()
      .required()
      .shape({
        label: Yup.string(),
        value: Yup.object().shape({ iso2: Yup.string(), code: Yup.string() }),
      }),
    lead_source: Yup.string().notRequired().nullable(),
    channel: Yup.string().required(),
    industry: Yup.string().notRequired().nullable(),
    sub_industry: Yup.string().notRequired().nullable(),
    city: Yup.string().notRequired().nullable(),
    state: Yup.string().required().trim(),
    brand_id: Yup.string().when("brand_required", {
      is: (brand_required: boolean) => brand_required,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
  });

  if (leadsourceError) return <p>Error loading Lead Sources</p>;
  if (industriesError) return <p>Error loading Lead Sources</p>;

  const returnSubIndustryOptions = (industry: string) => {
    if (industriesLoading) {
      return optionsLoading;
    }
    if (!!industry) {
      return industriesData.fetchIndustryOptions
        .slice()
        .filter((item: any) => item.label === industry)[0]
        .sub_industries?.map((ls: string) => ({ label: ls, value: ls }))
        .sort((a: any, b: any) => (a?.label < b?.label ? -1 : 1));
    }
    // if (!industry) {
    //   return industriesData.fetchIndustryOptions
    //     ?.map((item: any) => item.sub_industries)
    //     .flat()
    //     .sort()
    //     ?.map((ls: string) => ({ label: ls, value: ls }));
    // }
    else {
      return [{ label: "Select Primary Industry", value: "" }];
    }
  };

  const orgCountryCodeOptions = getLocalStorage("country_code_options", []);

  const defaultPlusExtraCountryOptions = [
    ...sortedCountryCodesData,
    ...getExtraCountryCodes(orgCountryCodeOptions),
  ].sort((a, b) => {
    if (a?.value?.iso2 === "US") return -1;
    if (b?.value?.iso2 === "US") return 1;
    return a?.label?.localeCompare(b?.label);
  });

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        business_name: "",
        local_primary_phone_number: "",
        email: "",
        lead_source: "",
        channel: "",
        industry: "",
        sub_industry: "",
        city: "",
        state: "",
        country_code: { label: "United States (1)", value: { iso2: "US", code: "1" } },
        brand_id: undefined,
        brand_required: showMultiBrandUI,
      }}
      validationSchema={newLeadSchema}
      onSubmit={async (values) => {
        console.log("Sent data: ", values);

        recursiveObjectTrim(values);

        await addLead({
          variables: {
            first_name: values.first_name,
            last_name: values.last_name,
            business_name: values.business_name,
            phone_number: extractNumberWithCountry(values.local_primary_phone_number, values.country_code?.value?.iso2),
            email: values.email,
            lead_source: values.lead_source,
            channel: values.channel,
            industry: values.industry,
            sub_industry: values.sub_industry,
            city: values.city,
            state: values.state ?? undefined,
            country: values.country_code?.value?.iso2 ?? undefined,
            country_code: values.country_code?.value?.code ?? undefined,
            brand_id: values.brand_id ?? undefined,
          },
        });
        MixpanelActions.track("Create Lead", {
          type: "Form Submit",
        });
      }}
    >
      {({ submitForm, values, setFieldValue, isSubmitting }: FormikProps<MyFormikProps>) => {
        return (
          <Modal open={visible} onClose={() => close()} closeButtonSize={16}>
            <ScrollingDiv>
              <CenterDiv>
                <PopupTitle>Add New Lead</PopupTitle>
              </CenterDiv>
              <PopupInputLabel>Contact First Name</PopupInputLabel>
              <LeadInput name="first_name" />
              <PopupInputLabel>Contact Last Name</PopupInputLabel>
              <LeadInput name="last_name" />
              <PopupInputLabel>
                Business Name <span style={{ color: "red" }}>*</span>
              </PopupInputLabel>
              <LeadInput name="business_name" />
              <PopupInputLabel>
                Country <span style={{ color: "red" }}>*</span>
              </PopupInputLabel>
              <FormMultiSelectField
                isMulti={false}
                name="country_code"
                options={defaultPlusExtraCountryOptions}
                onChange={(e: any) => {
                  setFieldValue("country_code", !!e?.value ? { label: e?.label, value: e?.value } : {});
                  setFieldValue("state", "");
                }}
              />
              <PopupInputLabel>
                Primary Phone Number <span style={{ color: "red" }}>*</span>
              </PopupInputLabel>
              <NumberExtensionDiv>
                {!!values.country_code?.value?.code && (
                  <ExtensionText>+{values.country_code?.value.code}</ExtensionText>
                )}
                <LeadInput name="local_primary_phone_number" type="tel" />
              </NumberExtensionDiv>
              <PopupInputLabel>Primary Email</PopupInputLabel>
              <LeadInput name="email" />
              <PopupInputLabel>Lead Source</PopupInputLabel>
              <LeadSelect
                name="lead_source"
                textAlign="left"
                allowSelectPlaceholder
                options={
                  leadsourceLoading
                    ? optionsLoading
                    : leadsourceData.fetchLeadSourceOptions?.map((ls: any) => ({ label: ls.label, value: ls.label }))
                }
                placeholder="Select"
              />
              {showMultiBrandUI && (
                <>
                  <PopupInputLabel>Brand</PopupInputLabel>
                  <FormMultiSelectField
                    name="brand_id"
                    options={ORG_BRAND_OPTIONS}
                    onChange={(e: any) => setFieldValue("brand_id", e?.target?.value)}
                    value={ORG_BRAND_OPTIONS.find((brand: any) => brand.value === values.brand_id)}
                  />
                </>
              )}
              <PopupInputLabel>
                Channel (Source Type) <span style={{ color: "red" }}>*</span>
              </PopupInputLabel>
              <LeadSelect
                name="channel"
                textAlign="left"
                allowSelectPlaceholder
                options={channel_options}
                placeholder="Select"
              />
              <PopupInputLabel>Industry</PopupInputLabel>
              <FormMultiSelectField
                isMulti={false}
                name="industry"
                value={!!values.industry ? { label: values.industry, value: values.industry } : {}}
                options={
                  industriesLoading
                    ? optionsLoading
                    : industriesData.fetchIndustryOptions?.map((ls: any) => ({ label: ls.label, value: ls.label }))
                }
                onChange={(e: any) => {
                  setFieldValue(`industry`, !!e?.value ? e?.value : "");
                  setFieldValue(`sub_industry`, "");
                }}
              />
              <PopupInputLabel>Sub-Industry</PopupInputLabel>
              <FormMultiSelectField
                isMulti={false}
                name="sub_industry"
                value={!!values.sub_industry ? { label: values.sub_industry, value: values.sub_industry } : {}}
                isDisabled={!values.industry}
                options={returnSubIndustryOptions(values.industry)}
                onChange={(e: any) => {
                  setFieldValue(`sub_industry`, !!e?.value ? e?.value : "");
                }}
              />
              <PopupInputLabel>City</PopupInputLabel>
              <LeadInput name="city" />
              <PopupInputLabel>
                State/Province <span style={{ color: "red" }}>*</span>
              </PopupInputLabel>
              <StateDropdown
                callback={setFieldValue}
                country_code={values.country_code?.value?.iso2}
                current_value={values.state}
              />
              <CenterDiv>
                {isSubmitting ? (
                  <Loading />
                ) : (
                  <AddEmailButton type="submit" onClick={submitForm}>
                    Add Lead
                  </AddEmailButton>
                )}
              </CenterDiv>
              <CenterDiv>
                <AddEmailCancelButton cancel onClick={close}>
                  Cancel
                </AddEmailCancelButton>
              </CenterDiv>
              <WhitespaceDiv />
            </ScrollingDiv>
          </Modal>
        );
      }}
    </Formik>
  );
};

const ExtensionText = styled(AppText)`
  margin: 15px;
  height: 40px;
  font-size: 13px;
`;

const NumberExtensionDiv = styled.div`
  display: flex;
`;

interface ModalOnProps {
  blinds?: boolean;
}

const PopupContainerDiv = styled.div<ModalOnProps>`
  position: fixed;
  display: ${(props) => (props.blinds ? "block" : "none")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
  z-index: 10;
`;

const LeadSelect = styled(FormSelectField)`
  /* height: 45px; */
  border-radius: 2.9px;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
`;

const WhitespaceDiv = styled.div`
  height: 50px;
`;

const PopupInputLabel = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 13px;
  margin-left: 5px;
`;

const LeadInput = styled(InputField)`
  margin: 0;
  text-align: left;
  font-size: 13px;
  border-radius: 2.9px;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
  ::placeholder {
    text-align: left;
  }
`;

const AddEmailCancelButton = styled(AppButton)`
  font-size: 10px;
  font-weight: 600;
  width: 136px;
  height: 32px;
  border-radius: 2px;
  text-transform: uppercase;
  margin: auto;
`;

const AddEmailButton = styled(AppButton)`
  width: 264px;
  height: 45px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  margin: 25px auto;
`;

const PopupTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 27px;
`;

interface ModalProps {
  width?: number;
  stickBottom?: boolean;
}

const PopupModal = styled.div<ModalProps>`
  position: absolute;
  top: 104px;
  bottom: ${(props) => (props.stickBottom ? 0 : "none")};
  left: ${(props) => `calc((100vw - ${props.width}px) / 2)` || "calc(50vw - (453px / 2))"};
  width: ${(props) => `${props.width}px` || "100%"};
  width: 453px;
  left: calc(50vw - 226.5px);
  border-radius: 7px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  height: auto;
  background: ${theme.WHITE_COLOR};
  border: 4px solid ${theme.NEUTRAL100};
  z-index: 3;
`;

const ScrollingDiv = styled.div`
  position: relative;
  padding: 40px 66px;
  overflow: auto;
  width: 100%;
  max-height: calc(100vh - 104px);
  overflow: auto;
`;

const LeadsButton = styled(AppButton)`
  width: 144px;
  height: 32px;
  border-radius: 2.9px;
  border: solid 1px ${theme.NEUTRAL200};
  margin: 11px;
  font-size: 11px;
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const TopAlignDiv = styled.div`
  min-height: 400px;
  max-height: 450px;
  overflow: auto;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SaveAndContinueButton = styled(AppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
`;

export { CreateNewLead };
