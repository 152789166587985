import React, { useMemo } from "react";
import styled from "styled-components";
import { AppText, FlexDiv } from "../../UI";
import { theme } from "../../../utils/theme";
import { CallReportSegment } from "../CallReportSegments";
import { Line, LineChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { AIProcessingCall } from "./AIProcessingCall";
import { FetchResult, MutationFunctionOptions } from "@apollo/client";

interface SummaryTabProps {
  aiProcessing: boolean;
  aiData: any;
  refetch_call_report: () => void;
  deleteCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  addCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  editCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  is_call_live: boolean;
  start_time: string;
  salesperson: string;
  recording_url: string;
  peakData: any;
  conference_id: string;
  lead: any;
  formattedCoachingNotes: any;
  conferenceData: any;
}

const lineChartHeight = 28;

export const SummaryTab: React.FC<SummaryTabProps> = ({
  aiProcessing,
  aiData,
  refetch_call_report,
  deleteCoachingNote,
  addCoachingNote,
  editCoachingNote,
  is_call_live,
  start_time,
  salesperson,
  recording_url,
  peakData,
  conference_id,
  lead,
  formattedCoachingNotes,
  conferenceData,
}) => {
  if (!recording_url) {
    return null;
  }

  return (
    <BodyContent>
      <FlexDiv direction="column" justify="space-between" height={aiProcessing ? "100%" : "initial"}>
        {!aiProcessing ? (
          <SummaryContainer>
            <FlexDiv gap={16} direction="column" style={{ maxWidth: "50%", overflowY: "auto", paddingBottom: "16px" }}>
              <AppText fontSize={14} fontWeight={500} lineHeight={20}>
                Summary
              </AppText>

              <SummaryList>
                {aiData?.summary?.length &&
                  aiData?.summary?.map((point: any, index: number) => (
                    <SummaryPoint key={`summary-${index}`}>{point.text}</SummaryPoint>
                  ))}
              </SummaryList>
            </FlexDiv>
            <FlexDiv gap={16} direction="column" style={{ maxWidth: "50%", overflowY: "auto", paddingBottom: "16px" }}>
              <AppText fontSize={14} fontWeight={500} lineHeight={20}>
                Next Steps
              </AppText>
              <NextStepsList>
                {!!aiData?.nextSteps?.length ? (
                  aiData?.nextSteps?.map((point: any, index: number) => (
                    <NextStepsListItem key={`next-steps-${index}`}>{point.text}</NextStepsListItem>
                  ))
                ) : (
                  <AppText fontSize={12} fontWeight={400} color={theme.text.neutral.secondary}>
                    No next steps identified
                  </AppText>
                )}
              </NextStepsList>
            </FlexDiv>
          </SummaryContainer>
        ) : (
          <AIProcessingCall />
        )}
      </FlexDiv>
    </BodyContent>
  );
};

const BodyContent = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const SummaryContainer = styled.div`
  display: flex;
  gap: 40px;

  min-height: 240px;

  padding: 24px 40px 0px 40px;
`;

const SummaryList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;

  list-style-position: outside;
  padding-left: 16px;
`;

const SummaryPoint = styled.li`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  ::marker {
    font-size: 12px;
  }
`;

const NextStepsList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 16px;

  list-style-position: outside;
  padding-left: 16px;
`;

const NextStepsListItem = styled.li`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  ::marker {
    font-size: 12px;
  }
`;
